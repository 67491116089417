import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Route, Switch, Redirect } from 'react-router-dom'
import ReduxBinder from 'alias-store/ReduxBinder'
import localAuthentication from 'alias-tools/localAuthentication'
import Random from './pages/components/Random'

// PAGES
import AuthenticatedPages from './pages'

import { Loading, Notifications } from 'alias-components'

class Authenticated extends Component {

    static propTypes = { //eslint-disable-line
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }


    componentDidMount() {
        if (localAuthentication.isAuthenticated()) {
            this.props.actions.user.load()
        }
        this.props.actions.notification.clear()
    }

    componentDidUpdate() {
        const { state, actions } = this.props
        const userState = state.user
        const user = userState.model
        if (!userState.isFetching && localAuthentication.isAuthenticated() && !user.id) {
            actions.user.load()
        }
    }

    render() {
        const { state, actions, location } = this.props
        const userState = state.user
        const user = userState.model

        if (!localAuthentication.isAuthenticated()) {
            /* eslint-disable */console.log("Redirecting to login") /* eslint-enable */
            return <Redirect push to={{
                pathname: '/auth',
                state: { from: location.pathname }
            }} />
        }

        if (userState.isFetching || !user.id) {
            return <Loading />
        }

        return (
            <div id="authenticated">

                <div className="wrapper">
                    <Switch>
                        <Route exact path='/' component={AuthenticatedPages.Home} />
                        <Route path='/profile' component={AuthenticatedPages.Profile} />
                        <Route path='/admin' component={AuthenticatedPages.Admin} />
                        <Route path='/courses/my' component={AuthenticatedPages.Courses} />
                        <Route path='/courses/instructor' component={AuthenticatedPages.InstructorCourses} />
                        <Route path='/courses/templates' component={AuthenticatedPages.InstructorTemplates} />
                        <Route path='/courses/market' component={AuthenticatedPages.InstructorMarket} />
                        <Route path='/course/my/:courseId' component={AuthenticatedPages.CourseGame} />
                        <Route path='/course/pick/:courseId' component={AuthenticatedPages.CoursePickRoles} />
                        <Route path='/course/picker/:courseId' component={AuthenticatedPages.Picker} />
                        <Route path='/course/instructor/:courseId' component={AuthenticatedPages.CourseRun} />
                        <Route path='/course/configuration/:courseId' component={AuthenticatedPages.CourseConfiguration} />
                        <Route path='/course/market/:courseId' component={AuthenticatedPages.InstructorMarketCourse} />
                        <Route path='/builder/:courseId' component={AuthenticatedPages.Builder} />
                        <Route path='/control' component={AuthenticatedPages.Control} />
                        <Route path='/form/:courseId/:formStaticId/set/:fromParticipantId/:toParticipantId?' component={AuthenticatedPages.Form} />
                        <Route path='/form/:courseId/:formStaticId/:logId?' component={AuthenticatedPages.Form} />
                    </Switch>
                </div>

                <Notifications notifications={state.notification.notifications} actions={actions} />

                <Random.ModalContact
                    user={state.local.modals.help.user}
                    course={state.local.modals.help.course}
                    isShown={state.local.modals.help.isShown}
                    close={actions.local.modalHelpClose.bind(this)} />
            </div>
        )
    }
}

export default ReduxBinder(Authenticated, {
    state: ['auth', 'user', 'notification', 'local']
})
