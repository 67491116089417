import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button } from 'alias-components'
import structures from 'alias-store/structures'



export default class BadgeModal extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func,
        isOpen: PropTypes.bool,
        badgeId: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }


    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let badge = { ...structures.badge }
        if (props.badgeId && props.badgeId !== 'new' && course.byId.badges[props.badgeId]) {
            badge = {
                ...badge,
                ...course.byId.badges[props.badgeId]
            }
        }
        this.state = {
            badge: badge,
            errorFields: [],
            isSaving: false,
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.badge.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.badgeId != this.state.badge.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (nextProps.badgeId && course.byId.badges[nextProps.badgeId]) {
                this.setState({
                    badge: course.byId.badges[nextProps.badgeId],
                    isSaving: false
                })
            } else {
                this.setState({ badge: { ...structures.badge }, isSaving: false })
            }
        }
    }

    render() {
        const { props, state } = this
        const { badge } = state

        let title = "New badges"
        let btnText = "Create"
        if (props.badgeId !== 'new') {
            title = badge.name
            btnText = "Update"
        }

        return <Modal show={props.isOpen} onHide={this._close.bind(this)}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {props.badge !== null && (
                <form onSubmit={this._onSubmit.bind(this)}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <Input value={badge.name}
                                onChange={this._onChange.bind(this)}
                                name="name"
                                label="Name"
                                placeholder="Ex: Communication..." />
                            <Input name="details"
                                label="Details (for authors)"
                                placeholder="Enter some details here..."
                                description="You can enter here what kind of personality the character has or any notes you deem important. This will only be seen by authors."
                                type="textarea"
                                value={badge.details}
                                onChange={this._onChange.bind(this)} />

                            <Input name="description"
                                label="Description (for participants)"
                                placeholder="Enter a description here..."
                                description="This will be seen by the participants. You can enter some notes or more details in what this role represents"
                                type="textarea"
                                value={badge.description}
                                onChange={this._onChange.bind(this)} />
                            <Input value={badge.isVisibleToParticipant}
                                type="bool"
                                name="isVisibleToParticipant"
                                label="Is badge visible to participants?"
                                onChange={this._onChange.bind(this)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} isLoading={this.state.isSaving} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(badge && badge.id) && <a onClick={this._delete.bind(this)} className="red margin-left-5">Delete</a>}
                    </Modal.Footer>
                </form>
            )}
        </Modal>
    }

    _onChange(name, value) {
        let { badge } = this.state
        badge[name] = value
        this.setState({
            badge: badge,
            isEdited: true
        })
    }

    _onSubmit(e) {
        e.preventDefault()
        let { badge } = this.state
        this.setState({
            isSaving: true
        })
        if (!badge.id) {
            const course = this.props.state.course.model
            badge.courseId = course.id
            this.props.actions.badge.create(badge, (badge) => {
                this.setState({
                    isSaving: false,
                    isEdited: false
                })
                if (this.props.openModal) {
                    this.props.openModal('badge', badge.id)
                } else {
                    this.setState({ badge: { ...structures.badge }, isSaving: false })
                    this.props.handleClose(badge)
                }
            })
        } else {
            this.props.actions.badge.update(badge, () => {
                // this.props.handleClose()
                this.setState({
                    isSaving: false,
                    isEdited: false
                })
            })
        }
    }

    _delete() {
        let { badge } = this.state
        if (confirm(`Are you sure you want to remove ${badge.name}?`)) {
            this.props.actions.badge.remove(badge, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }

}