import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import * as AuthActions from 'alias-store/modules/auth'
import * as UserActions from 'alias-store/modules/user'
import * as CourseActions from 'alias-store/modules/course'
import * as ModuleActions from 'alias-store/modules/module'
import * as CharacterActions from 'alias-store/modules/character'
import * as OrganizationActions from 'alias-store/modules/organization'
import * as RoleActions from 'alias-store/modules/role'
import * as TeamActions from 'alias-store/modules/team'
import * as ThreadActions from 'alias-store/modules/thread'
import * as NotificationActions from 'alias-store/modules/notification'
import * as ParticipantActions from 'alias-store/modules/participant'
import * as DocActions from 'alias-store/modules/doc'
import * as ActivityActions from 'alias-store/modules/activity'
import * as ActivityLogActions from 'alias-store/modules/activityLog'
import * as FormActions from 'alias-store/modules/form'
import * as VariableActions from 'alias-store/modules/variable'
import * as ValueActions from 'alias-store/modules/value'
import * as ScoringTagActions from 'alias-store/modules/scoringTag'
import * as BadgeActions from 'alias-store/modules/badge'
import * as LocalActions from 'alias-store/modules/local'
import * as ControlActions from 'alias-store/modules/control'
import * as ForumActions from 'alias-store/modules/forum'

const defaultOptions = {
    state: [
        'activity',
        'activityLog',
        'auth',
        'character',
        'course',
        'participant',
        'doc',
        'form',
        'module',
        'notification',
        'organization',
        'role',
        'team',
        'thread',
        'user',
        'variable',
        'value',
        'scoringTag',
        'badge',
        'local',
        'control'
    ]
}

const mapStateToProps = (options) => {
    return (state) => {
        // Map state to props
        let newState = {}
        if (options.state.length === defaultOptions.length) {
            newState = state
        } else {
            options.state.map(item => {
                newState[item] = state[item]
            })
        }
        return {
            state: newState
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    // Map dispatch to props
    return {
        actions: {
            auth: bindActionCreators(AuthActions, dispatch),
            user: bindActionCreators(UserActions, dispatch),
            course: bindActionCreators(CourseActions, dispatch),
            module: bindActionCreators(ModuleActions, dispatch),
            character: bindActionCreators(CharacterActions, dispatch),
            activity: bindActionCreators(ActivityActions, dispatch),
            activityLog: bindActionCreators(ActivityLogActions, dispatch),
            organization: bindActionCreators(OrganizationActions, dispatch),
            role: bindActionCreators(RoleActions, dispatch),
            notification: bindActionCreators(NotificationActions, dispatch),
            team: bindActionCreators(TeamActions, dispatch),
            thread: bindActionCreators(ThreadActions, dispatch),
            participant: bindActionCreators(ParticipantActions, dispatch),
            doc: bindActionCreators(DocActions, dispatch),
            form: bindActionCreators(FormActions, dispatch),
            scoringTag: bindActionCreators(ScoringTagActions, dispatch),
            badge: bindActionCreators(BadgeActions, dispatch),
            variable: bindActionCreators(VariableActions, dispatch),
            local: bindActionCreators(LocalActions, dispatch),
            control: bindActionCreators(ControlActions, dispatch),
            value: bindActionCreators(ValueActions, dispatch),
            forum: bindActionCreators(ForumActions, dispatch)
        }
    }
}



export default (component, options = {}) => {
    options = {
        ...defaultOptions,
        ...options
    }

    return connect(
        mapStateToProps(options),
        mapDispatchToProps
    )(component)
}
