import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class DocList extends Component {
    static propTypes = {}
    static defaultProps = {}
    constructor(props) {
        super(props)
    }
    render() {
        return <div></div>
    }
}


