import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Loading, Modal } from 'alias-components'
import Course from './components/Course'
import Messaging from './components/Messaging'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Route, Switch, NavLink, Link } from 'react-router-dom'
import CourseGameTabs from './courseGameTabs'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'


class PageCourseGameNew extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId

        // Connecting server live updates
        this._liveUpdateListen(courseId)

        this.state = {
            courseId: props.match.params.courseId,
            participantId: null
        }
    }

    UNSAFE_componentWillMount() {

        const course = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.match.params.courseId && !participant.isAdmin
        })
        if (course.length > 0) {
            if (this.isFetching !== true) {
                this.isFetching = true
                const participants = this.props.state.user.model.participants.filter(participant => {
                    return participant.course && participant.course.id === this.props.match.params.courseId && !participant.isAdmin
                })
                const participant = this._getParticipant(participants, this.props.state.local.game)
                this.props.actions.course.load(this.props.match.params.courseId, () => {
                    this.isFetching = false
                }, false, participant.id)
            }
        } else {
            this.props.history.push('/')
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.courseId !== newProps.match.params.courseId) {
            this.setState({ courseId: newProps.match.params.courseId })
            const participants = newProps.state.user.model.participants.filter(participant => {
                return participant.course && participant.course.id === newProps.match.params.courseId && !participant.isAdmin
            })
            const participant = this._getParticipant(participants, newProps.state.local.game)
            this.props.actions.course.load(newProps.match.params.courseId, null, false, participant.id)

            this._liveUpdateListen(newProps.match.params.courseId)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && !participant.isAdmin
        })
        const participant = this._getParticipant(participants, this.props.state.local.game)
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions), () => {
                if (participant) {
                    props.actions.course.load(courseId, null, true, participant.id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    _getParticipant(participants, game) {
        let participant = (game && game.participantId && participants.filter(p => p.id === game.participantId).length > 0) ?
            participants.filter(p => p.id === game.participantId)[0]
            : participants[0]
        return participant
    }

    render() {
        const { props, state } = this
        const { courseId } = state
        // COURSE LOADING
        if (!props.state || !props.actions) {
            return <Loading />
        }

        const courseState = props.state.course
        const course = courseState.model

        if (courseState.isFetching && !courseState.isLoaded) {
            return <Loading />
        }

        const participants = course.participants.filter(item => {
            return !item.isAdmin && item.userz.id === props.state.user.model.id
        })
        const participant = this._getParticipant(participants, props.state.local.game)
        if (!participant) {
            return <Loading />
        }

        const mails = Messaging.Tools.getThreadsForParticipant(course, 'email', participant, false, course.activities)
        const mailUnreadCount = mails ? mails.filter(thread => thread.isUnread && thread.latest).length : 0
        const chats = Messaging.Tools.getThreadsForParticipant(course, 'chat', participant, false, course.activities)
        const chatUnreadCount = chats ? chats.filter(thread => thread.isUnread && thread.latest).length : 0
        // Get unreads for forum
        let hasUnreadForum = false
        if (participant) {
            const logs = course.activityLogs.filter(l => l.type === "forum-read" && l.participantId === participant.id)
            const latestPost = (course.forums && course.forums.length > 0) ? course.forums[0] : null
            if (logs.length > 0 && latestPost) {
                if (new Date(latestPost.latestUpdate) - new Date(logs[0].updated) > 0) {
                    hasUnreadForum = true
                }
            }
            if (logs.length === 0 && latestPost) {
                hasUnreadForum = true
            }
        }

        const baseLink = `/course/my/${courseId}`


        const courseCheck = Course.Tools.validate(course)

        if (!courseCheck.success) {
            return <div className="course-wrapper">
                <div className="config-right">
                    <h1>Something isn't right</h1>
                    <p className="alert alert-warning">
                        <strong>There seems to be an issue with the course.</strong><br />
                        Please contact your course admins.<br />
                        <br />
                        <Link to="/">Back to course list</Link>
                    </p>
                </div>
            </div>
        }


        return <div className={`game`}>
            <div className="game-nav">
                {false && <div>
                    {props.state.local.isMenuOpen && <a className={`icon`} onClick={props.actions.local.toggleMenu}>
                        <i className="ion-android-close" />
                        <span>Close</span>
                    </a>}
                    {!props.state.local.isMenuOpen && <a className={`icon`} onClick={props.actions.local.toggleMenu}>
                        <i className="ion-arrow-round-back" />
                        <span>Your courses</span>
                    </a>}
                </div>}
                <Link className="icon" to="/">
                    <i className="ion-android-arrow-back" />
                    <span>Your Cases</span>
                </Link>

                <div className="spread"></div>
                <NavLink className={`icon`}
                    activeClassName="active"
                    exact
                    to={`${baseLink}`}>
                    <i className="ion-ios-home" />
                    <span>Home</span>
                </NavLink>
                {course.isLaunched && <div style={{ display: 'inline' }}>
                    {course.showDashboard && <NavLink className={`icon`}
                        activeClassName="active"
                        exact
                        to={`${baseLink}/dashboard`}>
                        <i className="ion-ribbon-a" />
                        <span>Dashboard</span>
                    </NavLink>}
                    {course.showEmail && <NavLink className={`icon`}
                        activeClassName="active"
                        to={`${baseLink}/messaging/email`}>
                        <i className="ion-ios-filing" />
                        {mailUnreadCount > 0 && <span className="counter active">{mailUnreadCount}</span>}
                        <span>Email</span>
                    </NavLink>}
                    {course.showChat && <NavLink className={`icon`}
                        activeClassName="active"
                        to={`${baseLink}/messaging/chat`}>
                        <i className="ion-ios-chatboxes" />
                        {chatUnreadCount > 0 && <span className="counter active">{chatUnreadCount}</span>}
                        <span>Chat</span>
                    </NavLink>}
                    {course.showLMS && <NavLink className={`icon`}
                        activeClassName="active"
                        to={`${baseLink}/modules`}>
                        <i className="ion-ios-bookmarks" />
                        {hasUnreadForum && <span className="counter active" style={{ width: 16 }}></span>}
                        <span>Learnings</span>
                    </NavLink>}
                </div>}
            </div>
            <div className="game-content">
                <Switch>
                    <Route exact path={`${baseLink}`} component={CourseGameTabs.Course} />
                    <Route path={`${baseLink}/course`} component={CourseGameTabs.Course} />
                    <Route path={`${baseLink}/dashboard`} component={CourseGameTabs.Dashboard} />
                    <Route path={`${baseLink}/messaging/:type/:threadId?`} component={CourseGameTabs.Messaging} />
                    <Route path={`${baseLink}/module/:moduleStaticId`} component={CourseGameTabs.Module} />
                    <Route path={`${baseLink}/role-plays/:activityId?`} component={CourseGameTabs.RolePlay} />
                    <Route path={`${baseLink}/modules/:moduleId?`} component={CourseGameTabs.Modules} />
                </Switch>
            </div>

            <Modal
                isShown={props.state.local.modals.formLog.isShown}
                title="Form log view"
                close={() => props.state.actions.local.modalFormLogClose()}
                showHeader={true}
                showFooter={true}>
                {props.state.local.modals.formLog.isShown && <FormResults
                    course={course}
                    form={props.state.local.modals.formLog.form}
                    activityLog={props.state.local.modals.formLog.log} />}
            </Modal>

        </div>
    }
}
export default ReduxBinder(PageCourseGameNew, {
    state: ['course', 'user', 'local']
})