import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Layout, Search, Onboarding } from 'alias-components'
import Course from './components/Course'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'

class PageCourses extends Component {
    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            search: ''
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.course.clearMessages()
    }

    render() {
        const self = this
        let courseList = []
        const participants = this.props.state.user.model.participants.filter(function (item) {
            if (!item.course) {
                // self.props.actions.user.load()
                return false
            }
            if (!item.isAdmin && !item.course.isTemplate) {
                if (courseList.indexOf(item.course.id) !== -1) {
                    return false
                }
                courseList.push(item.course.id)
                return true

            }
            return false
            // return !item.isAdmin && !item.course.isTemplate
        })

        const currentDateTime = moment()
        let runningCourses = []
        let finishedCourses = []
        let searchResults = []

        if (this.state.search === "") {
            runningCourses = participants.filter(function (item) {
                return (item.course.end == null || moment(item.course.end) > currentDateTime)
            }).map(function (item) {
                return item.course
            })

            finishedCourses = participants.filter(function (item) {
                return (item.course.end && moment(item.course.end) < currentDateTime)
            }).map(function (item) {
                return item.course
            })
        } else {
            searchResults = participants.filter(function (item) {
                return item.course.name.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
                    || item.course.description.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
            }).map(function (item) {
                return item.course
            })
        }


        return (
            <Layout title="Your courses">
                <Helmet title="Your courses" />
                <div className="container course-list">
                    <div className="page-header">
                        <Search search={this.state.search}
                            onChange={(search) => {
                                self.setState({
                                    search: search
                                })
                            }} />
                    </div>
                    {this.state.search !== "" && (
                        <div>
                            <p><em>Showing search results for <strong>{this.state.search}</strong></em></p>
                            {searchResults.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No results.</strong>
                                </div>
                            )}
                            {searchResults.map(function (course, i) {
                                return (
                                    <Course.Card course={course} isCourse={true} key={`searchmycourses-${i}`} />
                                )
                            })}
                        </div>
                    )}
                    {this.state.search === "" && (
                        <div>

                            {runningCourses.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No running courses.</strong>
                                    <p>You do not have any running courses for the moment. Please come and check again later or contact your instructor.</p>
                                </div>
                            )}
                            {runningCourses.map(function (course, i) {
                                return (
                                    <Course.Card course={course} isCourse={true} key={`mycourses-${i}`} />
                                )
                            })}

                            <br />
                            <br />
                            {finishedCourses.length > 0 && (
                                <div>
                                    <h2>Finished courses</h2>
                                    {/*{finishedCourses.length === 0 && (*/}
                                    {/*<div className="alert alert-info">*/}
                                    {/*<strong>No finished courses yet.</strong>*/}
                                    {/*<p>You do not have any running courses for the moment.</p>*/}
                                    {/*</div>*/}
                                    {/*)}*/}

                                    {finishedCourses.map(function (course, i) {
                                        return (
                                            <div className="col-xs-12 col-md-4" key={`finished-${i}`}>
                                                <Course.Card course={course} isCourse={true} />
                                            </div>
                                        )
                                    })}

                                </div>
                            )}
                            <br />
                            <br />
                            <Onboarding title="Want to be a teacher?">
                                <div>
                                    <p>You can create your own <Link to="/courses/instructor">courses</Link> and/or <Link to="/courses/templates">templates</Link> or even browse our <Link to="/courses/market">marketplace</Link> for some already made templates!</p>
                                </div>
                            </Onboarding>
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default ReduxBinder(PageCourses, {
    state: ['user', 'course']
})