import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, FileUploaderWithProgress, FileUpload, FileIcons } from 'alias-components'
import { Link } from 'react-router-dom'


const defaultLog = {
    courseId: "",
    participantId: "",
    type: 'form',
    formStaticId: "",
    fromParticipantId: "",
    data: {
        activityId: "",
        groupId: "",
        answers: []
    }
}



function participantListDisplay(participant, showRoles) {
    return {
        label: `${participant.displayName.bigTeamThenName}${(showRoles && participant.roleNames && participant.roleNames.length > 0) ? ` (${participant.roleNames.join(', ')})` : ''}`,
        value: participant.id,
        userzId: participant.userz.id
    }
}

export default class FormDisplay extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        log: PropTypes.object,
        activityId: PropTypes.any,
        groupId: PropTypes.any,
        participantFrom: PropTypes.object,
        participantTo: PropTypes.object,
        userz: PropTypes.object,
        course: PropTypes.object,
        actions: PropTypes.object,
        isDisabled: PropTypes.bool,
        type: PropTypes.string,  // page or anything else
        showQuestionState: PropTypes.bool,
        callback: PropTypes.func
    }

    static defaultProps = {
        isDisabled: false,
        showQuestionState: false
    }

    constructor(props) {
        super(props)
        let form = props.form
        const { course } = props
        // console.log(componentState.log, props.log, componentState.log.id === props.log.id, componentState && componentState.log && props.log && componentState.log.id === props.log.id)
        let log = props.log ? {
            ...JSON.parse(JSON.stringify(defaultLog)),
            ...props.log
        } : {
                ...JSON.parse(JSON.stringify(defaultLog)),
                courseId: course.id,
                data: {
                    activityId: props.activityId,
                    groupId: props.groupId
                },
                participantId: props.participantTo ? props.participantTo.id : "",
                fromParticipantId: props.participantFrom ? props.participantFrom.id : "",
                formStaticId: form.staticId
            }

        this.state = {
            form: form,
            log: log,
            isEdited: false,
            isSubmitted: false,
            isSubmitting: false,
            answerDocFiles: {}
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.isEdited && this.props.form && newProps.form && this.props.form.id !== newProps.form.id) {
            if (confirm("You edited the form but didn't submit it. Click `Ok` to save or `Cancel` to continue without saving.")) {
                this._onSubmit()
            }
        }
        let form = newProps.form
        const { course } = newProps

        if (
            (this.props.form && newProps.form && this.props.form.id !== newProps.form.id)
            || (this.props.log && newProps.log && this.props.log.id !== newProps.log.id)
        ) {
            // If the form changes or the log changes, refresh the log creation...
            let log = newProps.log ? {
                ...JSON.parse(JSON.stringify(defaultLog)),
                ...newProps.log
            } : {
                    ...JSON.parse(JSON.stringify(defaultLog)),
                    courseId: course.id,
                    data: {
                        activityId: newProps.activityId,
                        groupId: newProps.groupId
                    },
                    participantId: newProps.participantTo ? newProps.participantTo.id : "",
                    fromParticipantId: newProps.participantFrom ? newProps.participantFrom.id : "",
                    formStaticId: form.staticId
                }
            this.setState({
                form: form,
                log: log
            })
        }
    }

    componentWillUnmount() {
        if (this.state.isEdited) {
            if (confirm("You edited the form but didn't submit it. Click `Ok` to save or `Cancel` to continue without saving.")) {
                this._onSubmit()
            }
        }
    }

    render() {
        const { state, props } = this
        const { course, userz, type } = props
        let { isDisabled } = props
        const { log, form, isSubmitted } = state

        if (!log.id && isSubmitted) {
            if (type === 'page') {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <h2>Form has been submitted!!</h2>
                        <p>You can now go back to the course.</p>
                        <Link to={`/course/my/${course.id}`} className='btn btn-primary btn-lg'> Back to course</Link>
                        <br />
                        <br />
                    </div>
                )
            }
        }


        let participantList = [
            {
                label: "ADMINS",
                value: "ADMINS",
                disabled: true

            },
            ...course.participants.filter(part => part.isAdmin).map(part => participantListDisplay(part, false)),
            {
                label: "TEAMS",
                value: "TEAMS",
                disabled: true

            },
            ...course.teams.map(team => {
                return {
                    label: team.name,
                    value: team.id
                }
            }),
            {
                label: "PARTICIPANTS",
                value: "PARTICIPANTS",
                disabled: true

            },
            ...course.participants.filter(part => !part.isAdmin).map(participant => participantListDisplay(participant, true))
        ]

        let whoAmI = participantList
        if (userz && !log) {
            whoAmI = participantList.filter(participant => participant.userzId === userz.id)
        }

        if (state.answerDocFiles.length > 0) {
            isDisabled = true
        }

        return (
            <form onSubmit={this._onSubmit.bind(this)}>
                {(!log.fromParticipantId || !log.participantId) && (
                    <div>
                        <h3>Basics</h3>
                        {!log.participantFrom && <Input type='select'
                            label='Who are you?'
                            name='fromParticipantId'
                            value={log.fromParticipantId}
                            placeholder='Ex: John Smith'
                            options={whoAmI}
                            onChange={this._onChange.bind(this)} />}
                        {!log.participantTo && <Input type='select'
                            label='Who are you scoring?'
                            placeholder='Ex: John Smith'
                            name='participantId'
                            value={log.participantId}
                            options={participantList}
                            onChange={this._onChange.bind(this)} />}
                    </div>
                )}
                <div>
                    <h3>Questions</h3>
                    {form.questions.map(question => {
                        let answer = null
                        if (log && log.data && log.data.answers) {
                            let maybeAnswer = log.data.answers.filter(a => a.id === question.id)
                            if (maybeAnswer.length > 0) {
                                answer = maybeAnswer[0].answer
                            }
                        }
                        let options = JSON.parse(JSON.stringify(question.options))
                        if (question.type === 'range') {
                            options[0] = question.data.min
                            options[1] = question.data.max
                        }
                        if (props.showQuestionState) {
                            if (question.type === 'multiple-choice') {
                                question.description = ''
                                if (question.variableStaticId) {
                                    question.description += course.byStaticId.variables[question.variableStaticId] ? ' Variable => ' + course.byStaticId.variables[question.variableStaticId].name : ''
                                }
                                if (question.scoringTagStaticId) {
                                    question.description += course.byStaticId.scoringTags[question.scoringTagStaticId] ? ' & Scoring tag => ' + course.byStaticId.scoringTags[question.scoringTagStaticId].name : ''
                                }
                                options = options.map(option => {
                                    if (option.impact) {
                                        switch (option.impact) {
                                            case '-1':
                                                option.text += ' => Variable impact: Very negative'
                                                break
                                            case '-0.5':
                                                option.text += ' => Variable impact: Negative'
                                                break
                                            case '0':
                                                option.text += ' => Variable impact: None'
                                                break
                                            case '0.5':
                                                option.text += ' => Variable impact: Positive'
                                                break
                                            case '1':
                                                option.text += ' => Variable impact: Very positive'
                                                break
                                        }
                                    } else {
                                        option.text += ' => Variable impact: None'
                                    }
                                    if (option.score) {
                                        option.text += ' & Score: ' + option.score
                                    }
                                    return option
                                })
                            }
                        }
                        if (question.type === 'text') {
                            question.type = 'textarea'
                        }
                        if (question.type === 'scoring') {
                            question.type = 'number'
                        }

                        // Old doc system
                        let doc = false
                        if (question.type === 'doc') {
                            const docs = course.docs.filter(doc => doc.id === answer)
                            if (docs.length > 0) {
                                doc = docs[0]
                            }
                        }
                        // New doc system

                        return (
                            <div key={question.id}>
                                {['text', 'textarea', 'number', 'range', 'select', 'multiple-choice'].indexOf(question.type) !== -1 && (
                                    <Input type={question.type}
                                        label={question.question}
                                        options={options}
                                        placeholder='Your answer here...'
                                        description={question.description}
                                        value={answer}
                                        name={question.id}
                                        disabled={isDisabled}
                                        onChange={this._onChangeQuestion.bind(this)} />)}

                                {question.type === 'doc' && <div>
                                    <p className="special-input"><label>{question.question}</label>{question.description && <br />}{question.description}</p>
                                    {(!answer && !isDisabled) && (
                                        <FileUpload
                                            courseId={course.id}
                                            onComplete={(doc) => this._onDocUploadComplete(doc, question.id)} />
                                    )}
                                    {(answer && typeof answer !== 'string') && (
                                        <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                                            <li style={{ paddingBottom: '10px' }}>
                                                <a target="_blank" href={`/api/files?url=${answer.path}`}><FileIcons name={answer.name} height={80} isProcessing={true} /> {answer.name}</a>
                                                {!isDisabled && <a onClick={() => this._onChangeQuestion(question.id, null)} className="red margin-left-10"><i className="fa fa-trash"></i> Remove</a>}
                                            </li>
                                        </ul>
                                    )}
                                    {doc && (
                                        <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                                            <li style={{ paddingBottom: '10px' }}>
                                                <a href={doc.path} target="_blank"><i className="fa fa-file" /> {doc.name}</a>
                                            </li>
                                        </ul>
                                    )}
                                </div>}
                                {(false && question.type === 'doc') && <div>
                                    <h3>OLD</h3>
                                    <p className="special-input"><label>{question.question}</label>{question.description && <br />}{question.description}</p>
                                    {state.answerDocFiles[question.id] && <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                                        <li style={{ paddingBottom: '10px' }}>
                                            <i className="fa fa-file" /> {state.answerDocFiles[question.id].name}
                                            <FileUploaderWithProgress
                                                doc={state.answerDocFiles[question.id]}
                                                actions={this.props.actions}
                                                onComplete={(uploadedDoc) => this._onAnswerFileComplete(uploadedDoc, question.id)} />
                                        </li>
                                    </ul>}
                                    {doc && <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                                        <li style={{ paddingBottom: '10px' }}>
                                            <a href={doc.path} target="_blank"><i className="fa fa-file" /> {doc.name}</a>
                                        </li>
                                    </ul>}
                                    {(!state.answerDocFiles[question.id] && !doc) && <a onClick={() => this.refs["file" + question.id].click()} className="btn btn-secondary"><i className="fa fa-paperclip" /> Attach a file</a>}
                                    <input onChange={this._setAnswerDocFile.bind(this, question.id)} ref={"file" + question.id} type="file" />
                                </div>}
                                <br />
                            </div>
                        )
                    })}
                    {(!isDisabled) && <button type='submit' disabled={state.isSubmitting} className='btn btn-primary btn-lg'>{state.isSubmitting ? <i className="fa fa-spinner fa-pulse"></i> : "Submit"}</button>}
                    {(!isDisabled && state.isEdited) && <span className="help-block margin-left-5" style={{ display: 'inline-block' }}><i className="fa fa-arrow-left"> Don't forget to submit form</i></span>}
                    {(false && !isDisabled && log) && <a onClick={this._onRemove.bind(this)} className="btn btn-link red">Delete</a>}
                </div>
            </form>
        )
    }

    _onChange(attribute, value) {
        let log = this.state.log
        log[attribute] = value
        this.setState({
            log: log
        })
    }

    _onChangeQuestion(questionId, value) {
        let log = this.state.log
        log.data.answers = log.data.answers || []
        let alreadyHadAnAnswer = false
        log.data.answers = log.data.answers.map(answer => {
            if (answer.id === questionId) {
                alreadyHadAnAnswer = true
                answer.answer = value
            }
            return answer
        })
        if (!alreadyHadAnAnswer) {
            log.data.answers.push({
                id: questionId,
                answer: value
            })
        }
        this.setState({
            log: log,
            isEdited: true
        })
    }

    _setAnswerDocFile(questionId, e) {
        const { course } = this.props
        const { log, answerDocFiles } = this.state
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                var model = {
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: course.id,
                    file: e.target.files[k],
                    completed: false,
                    data: {
                        participantId: log.participantId,
                        activityId: log.data.activityId,
                        questionId: questionId
                    }
                }
                answerDocFiles[questionId] = model
            }
        }
        this.setState({
            answerDocFiles: answerDocFiles
        })
    }
    _onAnswerFileComplete(finishedUploadingDoc, questionId) {
        let { answerDocFiles } = this.state
        delete answerDocFiles[questionId]
        this.setState({
            answerDocFiles: answerDocFiles
        })
        this._onChangeQuestion(questionId, finishedUploadingDoc.id)
    }

    _onDocUploadComplete(doc, questionId) {
        this._onChangeQuestion(questionId, doc)
    }

    _onSubmit(e) {
        if (e && e.preventDefault) { e.preventDefault() }
        const { log, isSubmitting } = this.state

        if (isSubmitting) return
        if (!this._validateForm()) return

        this.setState({ isSubmitting: true })

        if (log.id) {
            this.props.actions.activityLog.update(log, () => {
                // Show notification here
                this.props.actions.notification.add({
                    type: 'success',
                    title: 'Form saved!'
                })
                this.setState({
                    isSubmitted: true,
                    isEdited: false,
                    isSubmitting: false
                })
                if (this.props.callback) {
                    this.props.callback()
                }

            })
        } else {
            this.props.actions.activityLog.create(log, () => {
                this.setState({
                    isSubmitted: true,
                    isEdited: false,
                    isSubmitting: false
                })
                if (this.props.callback) {
                    this.props.callback()
                }
            })
        }
    }

    _validateForm() {
        const { log, form } = this.state
        if (!log.participantId) {
            alert("Please select who is filling in this form (FROM)")
            return false
        }
        if (!log.fromParticipantId) {
            alert("Please select who is being scored (TO)")
            return false
        }

        let hasError = false

        if (log.data.answers && log.data.answers.length > 0) {
            // Check if an answer has been removed or blanked (remove file, deleted text)
            log.data.answers.map(answer => {
                if (!answer.answer && answer.answer !== 0) {
                    alert("Please answer all questions in the form.")
                    hasError = true
                }
            })
            // Check if answers length = questions length
            if (form.questions && log.data.answers.length !== form.questions.length) {
                alert("Please answer all questions in the form.")
                hasError = true
            }
        } else {
            // No answers at all
            alert("Please answer all questions in the form.")
            hasError = true
        }

        return !hasError
    }

    _onRemove() {
        const { log } = this.props
        if (confirm("Are you sure you want to remove the answers to this form?")) {
            this.props.actions.activityLog.remove(log)
        }
    }
}
