import Authors from './InfoTabsAuthors'
import Case from './InfoTabsCase'
import Intro from './InfoTabIntro'
import TeachersNotes from './InfoTabTeachersNotes'

export default {
    Authors,
    Case,
    Intro,
    TeachersNotes
}