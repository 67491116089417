import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import ParticipantReview from './ParticipantReview'
import scripts from 'alias-tools/scripts'
import Team from '../Team'

export default class ParticipantModalReview extends Component {

    static propTypes = {
        participant: PropTypes.object,
        team: PropTypes.object,
        state: PropTypes.object,
        isOpen: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool
    }

    render() {
        const { participant, team, handleClose, isOpen, state, actions } = this.props
        let name = ""
        if (participant) {
            name = scripts.getUserDisplayName(participant.userz)
        }
        if (team) {
            name = team.name
        }
        return (
            <Modal show={isOpen} onHide={handleClose} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {team && <Team.Card team={team}
                            state={state}
                            actions={actions} />}

                        <ParticipantReview {...this.props} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={handleClose} className="btn btn-default">Close</a>
                </Modal.Footer>
            </Modal>
        )
    }
}