import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Card, AvatarUploader, FormErrors, Button, Input } from "alias-components"
import IsValid from "alias-tools/validation"
import moment from "moment-timezone"
import ReduxBinder from "alias-store/ReduxBinder"

class InfoTabsCase extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            course: props.state.course.model,
            errorFields: [],
            introDocsBeingUploaded: []
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            course: newProps.state.course.model
        })
    }

    render() {
        const { course } = this.state

        const timezones = moment.tz
            .names()
            .filter(function (timezone) {
                return timezone.indexOf("GMT") === -1
            })
            .map(function (timezone) {
                return {
                    value: timezone,
                    label: timezone
                }
            })

        return (
            <div>
                <Helmet title={course.name + " introduction"} />
                <Card>
                    <form className="form-horizontal" onSubmit={this._saveCourse.bind(this)}>
                        <FormErrors fields={this.state.errorFields} />
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Name</label>
                            <div className="col-sm-9">
                                <Input
                                    type="text"
                                    name="name"
                                    value={course.name}
                                    placeholder="Enter course name..."
                                    onChange={this._onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Quick description</label>
                            <div className="col-sm-9">
                                <Input type="textarea"
                                    name="description"
                                    placeholder="Enter course quick description..."
                                    value={course.description}
                                    onChange={this._onChange.bind(this)} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 control-label">Timezone</label>
                            <div className="col-sm-9">
                                <Input type="select"
                                    name="timezone"
                                    placeholder="Select a timezone..."
                                    value={course.timezone}
                                    options={timezones}
                                    onChange={this._onChange.bind(this)} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 control-label">Start date/time</label>
                            <div className="col-sm-9">
                                <Input type="dateTime" name="start" placeholder="Course start date/time..."
                                    value={course.start} timezone={course.timezone} onChange={this._onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Avatar</label>
                            <div className="col-sm-9">
                                <AvatarUploader refName="course" fileType="course" object={course}
                                    actions={this.props.actions} />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-sm-offset-3 col-sm-10">
                                <Button text="Save" btnClass="btn btn-secondary" isLoading={this.props.state.course.isSaving} />
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
        )
    }

    _onChange(attributeName, value) {
        var course = this.state.course
        course[attributeName] = value
        this.setState({
            course: course
        })
    }

    _isFormValid() {
        var course = this.state.course
        var formIsValid = true
        var errorFields = []
        var errors
        // Name
        errors = IsValid(course.name, {
            required: null,
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Name",
                errors: errors
            })
        }
        // description
        errors = IsValid(course.description, {
            maxLength: 500
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Description",
                errors: errors
            })
        }

        errors = IsValid(course.timezone, {
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Timezone",
                errors: errors
            })
        }

        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _saveCourse(e) {
        if (e) {
            e.preventDefault()
        }
        const course = this.state.course
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.course.update(course)
    }
}

export default ReduxBinder(InfoTabsCase, {
    state: ["course", "user", "doc"]
})