import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Modal, Input, Card } from 'alias-components'
import Widgets from '../components/Widgets'
import Log from '../components/Log'
import Messaging from '../components/Messaging'
import moment from 'moment-timezone'
import Config from 'alias-config'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'


const activityTypes = [
    {
        label: 'Email',
        value: 'email'
    },
    {
        label: 'Chat',
        value: 'chat'
    },
    {
        label: 'Role play',
        value: 'rolePlay'
    },
    {
        label: 'Calendar event',
        value: 'calendar'
    }
]


class CourseRunNow extends Component {
    static propTypes = {}
    static defaultProps = {}
    constructor(props) {
        super(props)

        this.state = {
            showTimeline: false,
            timelineSearch: {
                search: '',
                roleStaticId: '',
                activityType: '',
                show: 'all'
            }
        }
    }
    render() {
        const { props, state } = this
        const course = props.state.course.model

        const activitiesMapping = this._mapActivities()




        return <div className="config-right-wrapper">
            <Helmet title={course.name + ' setup'} />
            <h1>Happening now</h1>

            <Card title="Ongoing activities">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th width={180}>Date/time</th>
                            <th width={30}></th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activitiesMapping.map(evt => {
                            return <tr key={evt.id} className={evt.type === 'now' ? 'info' : ''}>
                                <td>{evt.date.format(Config.app.time.activityDateTimeFormat)}</td>
                                <td>
                                    {evt.type === 'now' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Current time</Tooltip>}><i className="fa fa-arrow-right fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'course' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Start of live case</Tooltip>}><i className="fa fa-circle fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'end' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>End of live case</Tooltip>}><i className="fa fa-circle fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'forum' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Forum activity</Tooltip>}><i className="fa fa-th-list fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'email' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Email activity</Tooltip>}><i className="fa fa-envelope fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'chat' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Chat activity</Tooltip>}><i className="fa fa-comments fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'calendar' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Calendar activity</Tooltip>}><i className="fa fa-calendar fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'rolePlay' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Roleplay activity</Tooltip>}><i className="fa fa-users fa-fw"></i></OverlayTrigger>}
                                    {evt.type === 'module' && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${evt.id}`}>Module</Tooltip>}><i className="fa fa-calendar fa-fw"></i></OverlayTrigger>}
                                </td>
                                <td>{evt.text}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <a className="btn btn-secondary" onClick={() => this.setState({ showTimeline: true })}>View all</a>
            </Card>


            <Modal
                isShown={state.showTimeline}
                close={() => this.setState({ showTimeline: false })}
                showHeader={true}
                showFooter={true}
                title="Timeline">
                {state.showTimeline && <div>
                    <h3>Filters</h3>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <Input type="select"
                                value={state.timelineSearch.roleStaticId}
                                onChange={(name, value) => this.setState({ timelineSearch: { ...state.timelineSearch, roleStaticId: value } })}
                                options={course.roles.map(role => {
                                    return { label: role.name, value: role.staticId }
                                })}
                                placeholder="Role" />
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <Input type="select"
                                value={state.timelineSearch.activityType}
                                onChange={(name, value) => this.setState({ timelineSearch: { ...state.timelineSearch, activityType: value } })}
                                options={activityTypes}
                                placeholder="Activity type" />
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            {false && <Input type="text"
                                value={state.timelineSearch.search}
                                onChange={(name, value) => this.setState({ timelineSearch: { ...state.timelineSearch, search: value } })}
                                placeholder="Search..." />}
                            <Input type="select"
                                value={state.timelineSearch.show}
                                onChange={(name, value) => this.setState({ timelineSearch: { ...state.timelineSearch, show: value } })}
                                options={[
                                    {
                                        label: 'All',
                                        value: 'all'
                                    },
                                    {
                                        label: 'Passed',
                                        value: 'passed'
                                    },
                                    {
                                        label: 'Upcoming',
                                        value: 'upcoming'
                                    }
                                ]}
                                placeholder="View" />
                        </div>
                    </div>



                    <Widgets.Timeline
                        course={course}
                        search={state.timelineSearch.search}
                        roleStaticId={state.timelineSearch.roleStaticId}
                        activityType={state.timelineSearch.activityType}
                        show={state.timelineSearch.show} />
                </div>}
            </Modal>



            <br />
            <h3>Participants activities</h3>

            <ActivitiesFeed
                actions={props.actions}
                state={props.state} />

        </div>

    }



    _mapActivities() {
        const MAX_EVENTS_SHOWN = 4

        const course = this.props.state.course.model
        const courseStart = moment(course.start).tz(course.timezone)
        let calendarEvents = []
        const now = moment()

        calendarEvents.push({
            id: 'course',
            type: 'course',
            date: courseStart,
            text: `Case starts`
        })

        course.modules.map(module => {
            if (module.isDraft) { return }
            calendarEvents.push({
                id: module.id,
                type: 'module',
                date: courseStart.clone().add(module.offset || 0, 'm').add(1, 's'),
                text: `Module ${module.name} start`
            })
        })

        course.activities.map(activity => {
            if (activity.type === 'triggered') { return }
            if (!activity.parentStaticId) { return }
            let parentActivity = course.byStaticId.activities[activity.parentStaticId]
            if (!parentActivity) { return }
            let module = course.byStaticId.modules[parentActivity.moduleStaticId]
            if (!module) { return }
            if (module.isDraft) { return }

            let activityDate = courseStart.clone().add(module.offset || 0, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm').add(2, 's')
            if (activity.isDraft) { return }

            calendarEvents.push({
                id: activity.id,
                type: activity.type,
                date: activityDate,
                text: activity.name
            })
        })
        calendarEvents = calendarEvents.sort((a, b) => a.date.diff(b.date))
        calendarEvents.push({
            id: 'end',
            type: 'end',
            text: "Finished",
            date: calendarEvents[calendarEvents.length - 1].date.add(1, 'm')
        })

        let outputEvents = []
        let splitIndex = null
        const nowEvt = {
            id: 'now',
            type: 'now',
            date: now,
            text: 'NOW'
        }

        calendarEvents.map((evt, i) => {
            if (evt.date.isAfter(now)) {
                if (splitIndex === null) {
                    splitIndex = i
                }
            }
        })

        if (splitIndex === null) {
            // All event are passed, show last x items
            let spliceNumber = calendarEvents.length > MAX_EVENTS_SHOWN ? calendarEvents.length - MAX_EVENTS_SHOWN * 2 : 0
            outputEvents = calendarEvents.slice(spliceNumber)
            outputEvents.push(nowEvt)
        } else if (splitIndex === 0) {
            // It hasnt started, so show all the first x
            // let spliceNumber = calendarEvents.length > MAX_EVENTS_SHOWN * 2 ? -1 * (calendarEvents.length - MAX_EVENTS_SHOWN * 2) : 0
            if (calendarEvents.length > MAX_EVENTS_SHOWN * 2) {
                outputEvents = calendarEvents.slice(0, MAX_EVENTS_SHOWN * 2)
            } else {
                outputEvents = calendarEvents
            }
            outputEvents.unshift(nowEvt)
        } else {
            // Somewhere in the middle
            let startIndex = splitIndex - MAX_EVENTS_SHOWN
            let endIndex = splitIndex + MAX_EVENTS_SHOWN
            if (startIndex < 0) {
                // The split has less before than what we want to show
                endIndex = endIndex + (-1 * startIndex) + 1
                startIndex = 0
            }
            if (endIndex > calendarEvents.length) {
                // The split has less after than what we want to show
                startIndex = startIndex - (endIndex - calendarEvents.length)
                endIndex = calendarEvents.length - 1
            }
            // console.log("BEFORE", calendarEvents, startIndex, splitIndex, startIndex, calendarEvents.slice(startIndex, splitIndex - startIndex))
            // console.log("AFTER", calendarEvents.slice(splitIndex, endIndex - splitIndex))
            outputEvents = [
                ...calendarEvents.slice(startIndex, splitIndex),
                nowEvt,
                ...calendarEvents.slice(splitIndex, endIndex)
            ]
        }
        // console.log("=============")
        // outputEvents.map(evt => console.log(evt))

        return outputEvents
    }
}

export default ReduxBinder(CourseRunNow, {
    state: ['course', 'activityLog']
})


class ActivitiesFeed extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            showCount: 15,
            search: '',
            filterType: 'all',
            filterParticipant: '',
            emailPreview: {
                isShown: false,
                participant: ''
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.emailPreview.isShown && this.state.emailPreview.participant && this.state.emailPreview.log) {
            this._prepareThread(newProps, this.state.emailPreview.participant, this.state.emailPreview.log)
        }
    }

    _filterType(type) {
        this.setState({
            filterType: type
        })
    }

    _prepareThread(props, participant, log) {
        const course = props.state.course.model
        const activities = course.activities.filter(activity => activity.staticId === log.activityStaticId)

        if (activities.length === 0) {
            return alert("Activity not found")
        }
        const threads = Messaging.Tools.getThreadsForParticipant(course, 'email', participant, true, activities)
        if (threads.length === 0) {
            return alert("Sorry, we cannot find this conversation, it might have been drafted or deleted.")
        }
        this.setState({
            emailPreview: {
                isShown: true,
                participant: participant,
                log: log,
                threads: threads,
                threadId: threads[0].id
            }
        })
    }

    _selectEmailThread(participant, log) {
        const { props } = this
        this._prepareThread(props, participant, log)
    }
    render() {
        const { props, state } = this
        const course = props.state.course.model
        // For activity logs
        let participantsToFilter = []
        if (state.filterParticipant) {
            if (course.byId.teams[state.filterParticipant]) {
                course.participants.map(part => {
                    if (part.teamId === state.filterParticipant) {
                        participantsToFilter.push(part.id)
                    }
                })
            } else {
                participantsToFilter.push(state.filterParticipant)
            }
        }
        let activityLogs = course.activityLogs.sort((a, b) => new Date(b.updated) - new Date(a.updated)).filter(log => {
            // let logTypesToHide = ['read', 'messsage']
            let logTypesToShow = ['form', 'email']

            if (logTypesToShow.indexOf(log.type) === -1) { return false }
            if (state.filterType && state.filterType !== 'all') {
                if (log.type !== state.filterType) { return false }
            }
            if (participantsToFilter.length > 0 && participantsToFilter.indexOf(log.participantId) === -1) {
                return false
            }

            if (log.type === 'email' && log.data && log.data.characterStaticId && log.data.characterStaticId.length === 8) { return false }
            return true
        })

        let fullLogCount = activityLogs.length
        let hasMore = false
        // activityLogs = activityLogs.sort((a, b) => new Date(b.updated) - new Date(a.updated))
        if (activityLogs.length > state.showCount) {
            hasMore = true
            activityLogs = activityLogs.splice(0, state.showCount)
        }
        const baseLink = `/course/instructor/${course.id}`

        let filterParticipantList = [] // { label: "ALL", value: "" }
        if (course.teams && course.teams.length > 0) {
            filterParticipantList.push({
                label: "TEAMS",
                options: course.teams.map(team => {
                    return { label: team.name, value: team.id }
                })
            })

            filterParticipantList.push({
                label: "PARTICIPANTS",
                options: course.participants.map(part => {
                    if (part.isAdmin || part.isAuthor) { return null }
                    return { label: part.displayName.bigTeamThenName, value: part.id }
                }).filter(p => p !== null)
            })
        } else {
            course.participants.map(part => {
                if (part.isAdmin || part.isAuthro) { return }
                filterParticipantList.push({ label: part.displayName.bigTeamThenName, value: part.id })
            })
        }

        return <div>
            <div className="row">
                <div className="col-md-8">
                    <ul className="nav nav-pills">
                        <li className="disabled"><a>Filter by type:</a></li>
                        <li className={state.filterType === 'all' ? 'active' : ''}><a onClick={this._filterType.bind(this, 'all')}>All</a></li>
                        <li className={state.filterType === 'form' ? 'active' : ''}><a onClick={this._filterType.bind(this, 'form')}>Forms</a></li>
                        <li className={state.filterType === 'email' ? 'active' : ''}><a onClick={this._filterType.bind(this, 'email')}>Emails</a></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <Input type="select"
                        options={filterParticipantList}
                        value={state.filterParticipant}
                        placeholder="Filter for participant/team"
                        onChange={(name, value) => this.setState({ logs: { ...state.logs, filterParticipant: value } })}
                    />
                </div>
            </div>

            {state.search !== '' && (
                <p style={{ marginBottom: '15px' }}>
                    <em>Showing results for <strong>{state.search}</strong><a onClick={() => this.setState({ logs: { ...state.logs, search: '' } })} className="margin-left-10">Clear search</a></em>
                </p>
            )}

            {activityLogs.length === 0 && <p className="alert alert-info">No activities have been logged yet.</p>}


            {activityLogs.map(log => {
                let title = <span><i className="fa fa-question"></i> Not mapped</span>
                let content = <p key={log.id}>Not mapped</p>

                switch (log.type) {
                    case 'email':
                        title = <span><i className="fa fa-envelope"></i> Email</span>
                        content = <FeedMessage course={course} log={log} baseLink={baseLink}
                            selectEmailThread={this._selectEmailThread.bind(this)} />
                        break
                    case 'form':
                        title = <span><i className="fa fa-list"></i> Form</span>
                        content = <FeedForm course={course} log={log} baseLink={baseLink} />
                        break
                }
                return <div key={log.id}>
                    <h4>{title} <small className="pull-right">{moment(log.updated).format(Config.app.time.dateTimeShortFormat)}</small></h4>
                    {content}
                </div>
            })}
            {hasMore && <p>
                <a onClick={() => this.setState({ showCount: state.showCount + 20 })}
                    className="btn btn-secondary">Show more</a> Showing {state.showCount} of {fullLogCount}
            </p>}



            <Modal
                isShown={state.emailPreview.isShown}
                close={() => this.setState({ emailPreview: { isShown: false, threads: [], threadId: '' } })}
                showHeader={true}
                showFooter={true}
                title="Email">
                {state.emailPreview.isShown && <div className="messaging messaging-modal">
                    <Messaging.Convervation
                        type="email"
                        course={course}
                        participant={state.emailPreview.participant}
                        threadId={state.emailPreview.threadId}
                        threads={state.emailPreview.threads}
                        actions={props.actions}
                        isAdmin={true}
                        back={() => { }}
                    />
                </div>}
            </Modal>
        </div>
    }
}


class FeedMessage extends Component {
    static propTypes = {
        course: PropTypes.object.isRequired,
        log: PropTypes.object.isRequired,
        baseLink: PropTypes.string.isRequired,
        selectEmailThread: PropTypes.func
    }
    render() {
        const { course, log, baseLink, selectEmailThread } = this.props
        const activity = course.byStaticId.activities[log.activityStaticId]
        const participant = course.byId.participants[log.participantId]
        if (!activity || !participant) {
            return <p>Activity or participant not found</p>
        }
        const character = course.byStaticId.characters[activity.characterStaticId]
        if (!character) {
            return <p>Referenced character not found.</p>
        }
        const email = Messaging.Tools.template(activity.body, character, participant.userz)
        return <Card title={activity.name}>
            <p style={{ maxHeight: 50, overflow: 'hidden' }}>{email}</p>
            <strong>{participant.displayName.bigTeamThenName} said:</strong><br />
            <p>{log.data.message.split("\n").map((item, i) => <span key={i} dangerouslySetInnerHTML={{ __html: `${item}<br />` }} />)}</p>
            <a onClick={() => selectEmailThread(participant, log)} className="btn btn-link">Preview</a>
            <Link to={`${baseLink}/messaging/email/${participant.id}/${activity.id}${participant.id}`} className="btn btn-link">Go to</Link>
        </Card>
    }
}

class FeedForm extends Component {
    static propTypes = {
        course: PropTypes.object.isRequired,
        log: PropTypes.object.isRequired,
        baseLink: PropTypes.string.isRequired
    }
    render() {
        const { course, log } = this.props

        return <Card>
            <Log.FormListItem course={course} log={log} />
        </Card>
    }
}