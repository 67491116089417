import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Card, LMSDisplay, Avatar, LMSChapter } from 'alias-components'
import Widgets from '../components/Widgets'
import Participant from '../components/Participant'
import Team from '../components/Team'
import { Link } from 'react-router-dom'
import scripts from 'alias-tools/scripts'
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap'
import Messaging from '../components/Messaging'

class GameTabCourse extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            showAllParticipants: false
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.state.course.model)
    }

    render() {
        const course = this.props.state.course.model
        // Get participant
        let userParticipants = course.participants.filter((item) => {
            return !item.isAdmin && item.userz.id === this.props.state.user.model.id
        })
        let participant = userParticipants[0]
        if (this.props.state.local.game.participantId) {
            const hisParticipant = userParticipants.filter(part => part.id === this.props.state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
            }
        }
        if (!participant) {
            return <div>No participant found</div>
        }

        let team = (participant.teamId && course.byId.teams[participant.teamId]) ? course.byId.teams[participant.teamId] : null

        // Team and role picking
        let canChangeTeamsAndRoles = true
        const mails = Messaging.Tools.getThreadsForParticipant(course, 'email', participant, false, course.activities)
        const chats = Messaging.Tools.getThreadsForParticipant(course, 'chat', participant, false, course.activities)
        if (mails.length > 0 || chats.length > 0) {
            canChangeTeamsAndRoles = false
        }
        const linkToChangeTeams = `/course/picker/${course.id}/teams`
        const linkToChangeRoles = `/course/picker/${course.id}/roles` // `/course/pick/${course.id}`

        return <div className="config-right-wrapper">
            <Helmet title={course.name + " course"} />
            <h1>
                <Avatar user={course} size={100} /><br /><br />
                Welcome to {course.name}
            </h1>
            <br />
            {!course.isLaunched && <div className="alert alert-warning">
                <strong><i className="fa fa-bullhorn" /> COURSE NOT LAUNCHED</strong>
                <p>The course has not yet been launched by the author. Please come again soon to see if it has changed or inform your author.</p>
            </div>}
            <div className="row">
                <div className="col-xs-12 col-lg-6">
                    {(course.participantsCanChooseTeams && !participant.hasPickedTeam && !participant.teamId) && <div>
                        <div className="alert alert-warning">
                            <p style={{ marginBottom: 10 }}>Please choose your team before the case begins.</p>
                            <Link to={linkToChangeTeams} className="btn btn-warning">Pick your team</Link>
                        </div>
                    </div>}

                    {(course.participantsCanChooseRoles && !participant.hasPickedRoles && (!course.participantsCanChooseTeams || (course.participantsCanChooseTeams && participant.hasPickedTeam))) && <div>
                        <div className="alert alert-warning">
                            <p style={{ marginBottom: 10 }}>Please select your roles to start the course</p>
                            <Link to={linkToChangeRoles} className="btn btn-primary">Pick my roles</Link>
                        </div>
                    </div>}

                    <h2 style={{ paddingTop: 0 }}>You</h2>
                    <Participant.Card participant={participant} canEdit={false}>
                        <div>
                            <p>
                                <Link to={{ pathname: '/profile', state: { from: this.props.location.pathname } }} className="btn btn-secondary">Edit your profile</Link>
                            </p>
                        </div>
                    </Participant.Card>
                    {userParticipants.length > 1 && <div className="alert alert-info">
                        <p>You have been added more than once to the course. Feel free to switch between your different roles below:</p>
                        <ButtonToolbar>
                            <DropdownButton bsStyle="default" title={`Choose a different role`} id="dropdown-no-caret">
                                {userParticipants.map((part, index) => {
                                    let roleName = `${index + 1}) `
                                    if (part.roles && part.roles.length > 0) {
                                        let roleNames = []
                                        part.roles.map(roleStaticId => {
                                            if (course.byStaticId.roles[roleStaticId]) {
                                                roleNames.push(course.byStaticId.roles[roleStaticId].name)
                                            }
                                        })
                                        roleName += roleNames.toString()
                                    } else {
                                        roleName += "No roles"
                                    }
                                    return <MenuItem key={part.id} onClick={() => this.props.actions.local.save({
                                        game: {
                                            ...this.props.state.local.game,
                                            participantId: part.id
                                        }
                                    })}>{roleName}</MenuItem>
                                })}
                            </DropdownButton>
                        </ButtonToolbar>
                    </div>}


                    {(!course.participantsCanChooseRoles || (course.participantsCanChooseRoles && participant.hasPickedRoles)) && <div>
                        <h2>Your roles</h2>
                        {(((course.participantsCanChooseRoles && participant.hasPickedRoles) || !course.participantsCanChooseRoles) && participant.roles.length === 0) && <div className="alert alert-info">You currently do not have any roles</div>}
                        {participant.roles.length > 0 && <Card>
                            <ul style={{ marginBottom: 0 }}>
                                {participant.roles.map((roleStaticId, i) => {
                                    let role = course.byStaticId.roles[roleStaticId]
                                    if (!role) { return null }
                                    if (!role.isVisibleByParticipant) { return null }
                                    return <li key={roleStaticId + i}>
                                        <strong>{role.name}</strong><br />
                                        {role.description && <p className="help-block">{role.description}</p>}
                                    </li>
                                })}
                            </ul>
                        </Card>}
                        {(course.participantsCanChooseRoles && canChangeTeamsAndRoles) && <div>
                            <p className="help-block"><Link to={linkToChangeRoles}>Change roles</Link> (You can pick/change your role(s) until the case starts, thereafter you will need to contact one of your instructors)</p>
                        </div>}
                    </div>}


                    {team && <div key={team.id}>
                        <h2>Your team: {team.name}</h2>
                        <Team.Card
                            team={team}
                            canEdit={false}
                            state={this.props.state}
                            actions={this.props.actions}
                            isAdmin={false}
                            yourTeam />
                        {(course.participantsCanChooseTeams && canChangeTeamsAndRoles) && <div>
                            <p className="help-block"><Link to={linkToChangeTeams}>Change team</Link> (You can pick/change to another team until the case starts, thereafter you will need to contact one of your instructors)</p>
                        </div>}
                    </div>}


                </div>
                <div className="col-xs-12 col-lg-6">
                    {(!scripts.isEditorEmpty(course.intro) || (course.intros && course.intros.length > 0)) && <h2 style={{ paddingTop: 0 }}>Case introduction</h2>}
                    {!scripts.isEditorEmpty(course.intro) && <div>
                        <Card>
                            <LMSDisplay course={course} text={course.intro} />
                        </Card>
                    </div>}
                    {(course.intros && course.intros.length > 0) && <div>
                        <LMSChapter
                            isEditable={false}
                            value={course.intros} />
                    </div>}

                    <Widgets.CalendarEvents course={course} />

                    <Widgets.CourseInfo course={course} />

                    {false && <div>
                        <h2>Danger zone</h2>
                        <Card>
                            <p>You can drop out of the course. Please be careful as all your data will be erased.</p>
                            <a onClick={this._dropCourse.bind(this)} className="btn btn-danger">Drop course</a>
                        </Card>
                    </div>}
                </div>
            </div>
        </div>

    }

    _dropCourse(e) {
        e.preventDefault()
        if (confirm(`Are you sure you want to drop this course? All your course data will be erased.`)) {
            const course = this.props.state.course.model
            // Get participant
            let userParticipants = course.participants.filter((item) => {
                return !item.isAdmin && item.userz.id === this.props.state.user.model.id
            })
            let participant = userParticipants[0]
            if (this.props.state.local.game.participantId) {
                const hisParticipant = userParticipants.filter(part => part.id === this.props.state.local.game.participantId)
                if (hisParticipant.length > 0) {
                    participant = hisParticipant[0]
                }
            }
            this.props.actions.participant.remove(participant, () => {
                this.props.history.push('/')
            })
        }
    }
}

export default ReduxBinder(GameTabCourse, {
    state: ['course', 'user', 'local']
})