import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'
import Scripts from 'alias-tools/scripts'

const scoringOptions = [
    { label: 'None', value: null },
    { label: '5 - High', value: 5 },
    { label: '4', value: 4 },
    { label: '3 - ok', value: 3 },
    { label: '2', value: 2 },
    { label: '1', value: 1 },
    { label: '0 - Lowest', value: 0 }
]

const variableOptions = [
    { label: 'None', value: null },
    { label: 'Very high impact', value: 1 },
    { label: 'High impact', value: 0.5 },
    { label: 'No impact', value: 0 },
    { label: 'Negative impact', value: -0.5 },
    { label: 'Very negative impact', value: -1 }
]


export default class FormInputRadio extends Component {
    static propTypes = {
        question: PropTypes.any,
        variables: PropTypes.any,
        onChange: PropTypes.func,
        course: PropTypes.object
    }
    render() {
        const { question, course } = this.props

        const badgesOptions = course.badges.map(badge => {
            return {
                label: badge.name,
                value: badge.staticId
            }
        })

        const scoringTags = course.scoringTags.map(tag => {
            return {
                label: tag.name,
                value: tag.staticId
            }
        })

        const valuesOptions = course.values.map(value => {
            return {
                label: value.name,
                value: value.staticId
            }
        })

        return (
            <div className="radio">
                {false && <Input type="select"
                    placeholder="Select variable (*Optional)"
                    label={`Variable`}
                    name="variableStaticId"
                    value={question.variableStaticId}
                    options={course.variables.map(variable => {
                        return {
                            label: variable.name,
                            value: variable.staticId
                        }
                    })}
                    onChange={this._onChange.bind(this)} />}


                <h3>Answers</h3>
                {(!question.options || question.options.length === 0) && <div className="alert alert-info">No answers available. Please add by clicking the button below.</div>}
                <ul>
                    {question.options.map((option, i) => {
                        return (
                            <li key={option.id}>
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <div className="col-xs-9 col-xs-offset-3">
                                            <h4>{`Answer ${i + 1}  `} <small><a onClick={() => {
                                                this._removeOption(option.id)
                                            }} className="red"><i className="fa fa-trash" /> {` Remove`}</a></small></h4>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-xs-3">Answer</label>
                                        <div className="col-xs-9">
                                            <Input type="textarea"
                                                minRows={1}
                                                value={option.text}
                                                placeholder={`Ex: Option ${i + 1}`}
                                                onChange={(name, value) => {
                                                    this._onChangeOption(option.id, 'text', value)
                                                }} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label col-xs-3">Score</label>
                                        <div className="col-xs-9">
                                            <Input type="select"
                                                options={scoringOptions}
                                                value={option.score}
                                                name="score"
                                                placeholder="Pick a score (Optional)"
                                                onChange={(name, value) => {
                                                    this._onChangeOption(option.id, 'score', value)
                                                }} />
                                        </div>
                                    </div>
                                    {false && question.variableStaticId && <div className="form-group">
                                        <label className="control-label col-xs-3">Variable impact</label>
                                        <div className="col-xs-9">
                                            <Input type="select"
                                                options={variableOptions}
                                                value={option.impact}
                                                placeholder="Pick an impact on the variable (Optional)"
                                                onChange={(name, value) => {
                                                    this._onChangeOption(option.id, 'impact', value)
                                                }} />
                                        </div>
                                    </div>}
                                    <div className="form-group">
                                        <label className="control-label col-xs-3">Unlocks badge</label>
                                        <div className="col-xs-9">
                                            <Input type="select"
                                                options={badgesOptions}
                                                value={option.badgeStaticId}
                                                placeholder="Pick a badge the team/participant will get if they choose this answer (Optional)"
                                                onChange={(name, value) => {
                                                    this._onChangeOption(option.id, 'badgeStaticId', value)
                                                }} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label col-xs-3"></label>
                                        <div className="col-xs-9">
                                            <strong>Values</strong>
                                        </div>
                                    </div>
                                    {option.values && option.values.map(value => {
                                        return <div key={value.id}>
                                            <div className="row">
                                                <div className="col-xs-3">
                                                    <Input type="select"
                                                        label="Value"
                                                        options={valuesOptions}
                                                        value={value.valueStaticId}
                                                        placeholder="Pick a value to impact"
                                                        onChange={(name, newValue) => {
                                                            this._changeValue(option.id, value.id, 'valueStaticId', newValue)
                                                        }} />
                                                </div>
                                                <div className="col-xs-7">
                                                    <Input type="number"
                                                        label="Amount of change"
                                                        value={value.amount}
                                                        placeholder="Ex: 1000 or -500"
                                                        onChange={(name, newValue) => {
                                                            this._changeValue(option.id, value.id, 'amount', newValue)
                                                        }} />
                                                </div>
                                                <label className="col-xs-2">
                                                    <a onClick={() => this._removeValue(option.id, value.id)} className="red" style={{ position: 'relative', top: 20 }}>Remove</a>
                                                </label>
                                            </div>
                                        </div>
                                    })}
                                    <div className="form-group">
                                        <label className="control-label col-xs-3"></label>
                                        <div className="col-xs-9">
                                            <a onClick={() => this._addValue(option.id)}>+ Add a value</a>
                                        </div>
                                        <div className="col-xs-9"></div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label col-xs-3"></label>
                                        <div className="col-xs-9">
                                            <strong>Tags</strong>
                                        </div>
                                    </div>
                                    {option.scoringTags && option.scoringTags.map(tag => {
                                        return <div key={tag.id}>
                                            <div className="row">
                                                <div className="col-xs-3">
                                                    <Input type="select"
                                                        label="Value"
                                                        options={scoringTags}
                                                        value={tag.scoringTagStaticId}
                                                        placeholder="Pick a scoring tag"
                                                        onChange={(name, newValue) => {
                                                            this._changeScoringTag(option.id, tag.id, 'scoringTagStaticId', newValue)
                                                        }} />
                                                </div>
                                                <div className="col-xs-7">
                                                    <Input type="select"
                                                        label="Amount of change"
                                                        options={scoringOptions}
                                                        value={tag.amount}
                                                        placeholder="1 to 5"
                                                        onChange={(name, newValue) => {
                                                            this._changeScoringTag(option.id, tag.id, 'amount', newValue)
                                                        }} />
                                                </div>
                                                <label className="col-xs-2">
                                                    <a onClick={() => this._removeScoringTag(option.id, tag.id)} className="red" style={{ position: 'relative', top: 20 }}>Remove</a>
                                                </label>
                                            </div>
                                        </div>
                                    })}

                                    <div className="form-group">
                                        <label className="control-label col-xs-3"></label>
                                        <div className="col-xs-9">
                                            <a onClick={() => this._addScoringTag(option.id)}>+ Add a scoring tag</a>
                                        </div>
                                        <div className="col-xs-9"></div>
                                    </div>

                                </div>
                            </li>
                        )
                    })}
                    <li>
                        <a className="btn btn-default" onClick={this._addOption.bind(this)}><i className="fa fa-plus" style={{ marginRight: '3px' }} />Add an answer</a>
                    </li>
                </ul>
            </div>
        )
    }

    _onChangeOption(id, name, value) {
        let { question } = this.props
        question.options = question.options.map(option => {
            if (option.id !== id) {
                return option
            }
            option[name] = value
            return option
        })
        this.props.onChange(question)
    }

    _removeOption(id) {
        let { question } = this.props
        question.options = question.options.filter(option => option.id !== id)
        this.props.onChange(question)
    }

    _addOption() {
        let { question } = this.props
        question.options.push({
            id: Scripts.randomString(),
            text: "",
            impact: null,
            score: null,
            badgeStaticId: '',
            values: []
        })
        this.props.onChange(question)
    }

    _addValue(optionId) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.values = opt.values || []
            opt.values.push({
                id: Scripts.randomString(),
                valueStaticId: null,
                amount: 0
            })
            return opt
        })
        this.props.onChange(question)
    }
    _changeValue(optionId, valueListId, type, newValue) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.values = opt.values.map(val => {
                if (val.id !== valueListId) { return val }
                val[type] = newValue
                return val
            })
            return opt
        })
        this.props.onChange(question)
    }
    _removeValue(optionId, valueListId) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.values = opt.values.filter(val => val.id !== valueListId)
            return opt
        })
        this.props.onChange(question)
    }

    _addScoringTag(optionId) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.scoringTags = opt.scoringTags || []
            opt.scoringTags.push({
                id: Scripts.randomString(),
                scoringTagStaticId: null,
                amount: 0
            })
            return opt
        })
        this.props.onChange(question)
    }
    _changeScoringTag(optionId, scoringTagListId, type, newScoringTag) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.scoringTags = opt.scoringTags.map(tag => {
                if (tag.id !== scoringTagListId) { return tag }
                tag[type] = newScoringTag
                return tag
            })
            return opt
        })
        this.props.onChange(question)
    }
    _removeScoringTag(optionId, scoringTagListId) {
        let { question } = this.props
        question.options = question.options.map(opt => {
            if (opt.id !== optionId) { return opt }
            opt.scoringTags = opt.scoringTags.filter(val => val.id !== scoringTagListId)
            return opt
        })
        this.props.onChange(question)
    }

    _onChange(name, value) {
        let question = this.props.question
        question[name] = value
        this.props.onChange(question)
    }
}