import React, { Component } from 'react'
import PropTypes from 'prop-types'
import api, { apiUpload } from 'alias-tools/api'
import Scripts from 'alias-tools/scripts'

const MAX_FILE_SIZE = 30 * 1000000

const styles = {
    input: { display: 'none' }
}

export default class FileUpload extends Component {
    static propTypes = {
        onComplete: PropTypes.func,
        courseId: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['core', '']),
        text: PropTypes.any,
        canUpload: PropTypes.bool
    }
    static defaultProps = {
        onComplete: () => { },
        type: "",
        text: "Upload file",
        canUpload: true
    }
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            progress: 0,
            uploadIsComplete: false,
            errors: null,
            url: null,
            status: ''
        }
    }
    render() {
        const { props, state } = this

        if (props.post && props.post.video) {
            return <div></div>
        }

        if (!state.file) {
            return <div style={{ display: 'inline' }}>
                <input type="file" ref="fileIsSelect" style={styles.input} onChange={this._onChangeInput.bind(this)} />
                <a onClick={() => this.refs.fileIsSelect.click()} className="btn btn-link">{props.text}</a>
                {state.errors && <div className="alert alert-error">
                    <p>Something went wrong. Please try again</p>
                </div>}
            </div>
        }

        return <div>
            <h4>Uploading {state.file.name} <span>({Scripts.bitesDisplay(state.file.size)})</span></h4>
            {state.status && <p className="help-block">Status: {state.status}</p>}
            <div className="progress" style={{ marginBottom: '0' }}>
                <div className={`progress-bar ${state.uploadIsComplete ? 'progress-bar-success' : 'progress-bar-info progress-bar-striped active'}`} style={{ minWidth: '3em', width: state.progress + '%' }}>
                    {`${state.progress}%`}
                </div>
            </div>
        </div>
    }

    _onChangeInput(e) {
        const { props } = this
        const file = e.target.files[0]

        if (file && file.size > MAX_FILE_SIZE) {
            return alert("Maximum file size of 30MB. Please select a smaller file.")
        }
        // if (file.type.indexOf('video') === -1) {
        //     return alert("Please select a video format (ex: mp4).")
        // }
        this.setState({ file: file, errors: null, status: 'Uploading...' })


        var formData = new FormData();
        formData.append("file", file);

        apiUpload(
            "/files/upload",
            formData,
            (progress) => {
                if (progress.progress === 100) {
                    this.setState({
                        status: "Wrapping things up...",
                        progress: progress.progress
                    })
                } else {
                    this.setState({ progress: progress.progress })
                }
            },
            {
                courseId: props.courseId,
                type: props.type || ""
            })
            .then((res) => {
                if (!res.data.success) {
                    return this._setError()
                }
                this.setState({
                    progress: 0,
                    uploadIsComplete: false,
                    file: null,
                    status: "Uploading...",
                    errors: ""
                })
                props.onComplete({
                    path: res.data.path,
                    name: file.name
                })
            })
            .catch((err) => {
                console.warn(err)
                this._setError()
            })
    }


    _setError(message) {
        this.setState({
            progress: 0,
            uploadIsComplete: false,
            file: null,
            errors: message || "Something went wront, please try again."
        })
    }
}
