import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, FileUploaderWithProgress, VideoUpload, VideoPlayer, FileUpload, FileIcons } from 'alias-components'
import EmailItem from './EmailItem'
import moment from 'moment-timezone'
import scripts from 'alias-tools/scripts'
import api from 'alias-tools/api'


function getRandomOffset() {
    let random = Math.floor(Math.random() * (6000 - 2000 + 1)) + 2000
    random = random * 10
    return random
}

export default class MessagingConversationEmail extends Component {
    static propTypes = {
        participant: PropTypes.object.isRequired,
        threadId: PropTypes.string,
        thread: PropTypes.object,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool,
        admin: PropTypes.object,
        back: PropTypes.func.isRequired,
        questionAnswered: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        inputPlaceholderText: PropTypes.string,
        course: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            text: '',
            answer: '',
            lastMessage: null,
            typing: {
                randomOffset: getRandomOffset()
            },
            isSending: false,
            answerDocFile: false,
            docsBeingUploaded: [],
            showActivityModal: false,
            showPreviousResponses: false,
            tempActivityStaticId: "temp-" + scripts.randomString(),
            videoUrl: null,
            isRemovingVideo: false,
            userHasStartedAnswering: false
        }
    }



    render() {
        const { props, state } = this
        let messages = props.thread ? [...props.thread.messages] : []

        let lastMessage = (messages.length > 0) ? messages[messages.length - 1] : null
        let hasFollowUpQuestionComing = false
        if (lastMessage) {
            if (lastMessage.created.isAfter(moment())) {
                if (!this.refreshLatest) {
                    this.refreshLatest = setTimeout(() => {
                        clearInterval(this.refreshLatest)
                        this.refreshLatest = null
                        this.forceUpdate()
                    }, lastMessage.created.diff(moment()) + 1000)
                }
                hasFollowUpQuestionComing = true
                messages.splice(messages.length - 2, 2)
                lastMessage = messages[messages.length - 1]
            }
        }

        let answerIsFile = (lastMessage && lastMessage.question && lastMessage.question.type === 'doc')
        let showForm = false
        let showReply = false
        if (props.isAdmin) {
            showForm = false  // Admin can never answer questions!!
            if (!hasFollowUpQuestionComing) {
                if (!lastMessage) {
                    showReply = true
                } else {
                    if (!lastMessage.question) {
                        showReply = true
                    } else {
                        if (lastMessage.answer) {
                            showReply = true
                        }
                    }
                }
            }
        } else {
            if (hasFollowUpQuestionComing === false) {
                if (lastMessage && lastMessage.question && !lastMessage.answer) {
                    showForm = true
                }
                showReply = !showForm
            }
        }

        let activity = null
        if (props.thread.activityStaticId) {
            activity = props.course.byStaticId.activities[props.thread.activityStaticId]
        }

        let bcc = null
        if (activity) {
            if (props.course.byStaticId.organizations[activity.roleStaticId]) {
                bcc = props.course.byStaticId.organizations[activity.roleStaticId].name
            }
            if (props.course.byId.teams[activity.roleStaticId]) {
                bcc = props.course.byId.teams[activity.roleStaticId].name
            }
        }

        let replyAsCharacter = false
        if (props.admin && props.participant && props.admin.id !== props.participant.id) {
            replyAsCharacter = true
        }


        return <div style={{ width: '100%', height: '100%' }}>
            <div className="list">
                <h4>
                    {props.thread.subject}
                    {bcc && <small><br />BCC: {bcc}</small>}
                    {(props.thread.activityId && props.isAdmin) && <a onClick={this._editActivity.bind(this)} className="pull-right"><i className="fa fa-edit"></i> Edit</a>}
                </h4>

                {(props.thread.activityId && props.isAdmin && props.participant) && <div className="well" style={{ marginBottom: 5 }}>
                    Participant Name: {props.participant.displayName.bigTeamThenName} <br />
                    Roles: {props.participant.roleNames.join(', ')}
                </div>}

                <br />
                {messages.map((message, i) => <EmailItem key={i}
                    course={props.course}
                    message={message}
                    minimize={messages.length > 1 && i < messages.length - 1}
                    participant={props.participant}
                    isAdmin={props.isAdmin}
                    questionAnswered={props.questionAnswered}
                    addMessage={props.addMessage}
                    actions={props.actions}
                    thread={props.thread} />)}

                {(props.isAdmin && props.thread.participantReadLog) && <p className="help-block">
                    <i className="fa fa-info"></i> Last read by participant: {moment(props.thread.participantReadLog.updated).tz(props.course.timezone).format("MMM Do H:mm")}
                </p>}
                {(props.isAdmin && !props.thread.participantReadLog) && <p className="help-block"><i className="fa fa-info"></i> Participant has not read this email</p>}

                {(props.isAdmin && !showReply) && <p className="alert alert-info">Participant is answering a form, no replies can be typed until he completes the form.</p>}

                {showForm && <Card title="Reply">
                    <form onSubmit={this._onSubmitAnswer.bind(this)}>
                        {!answerIsFile && <Input type={lastMessage.question.type === 'text' ? 'textarea' : lastMessage.question.type}
                            options={lastMessage.question.options.map(option => {
                                return {
                                    text: option.text,
                                    id: option.id
                                }
                            })}
                            value={state.answer}
                            disabled={!!lastMessage.answer || props.isAdmin}
                            placeholder={props.inputPlaceholderText}
                            onChange={(name, value) => this._onAnswerChange(lastMessage, value)} />}
                        {answerIsFile && (
                            <div>
                                {!state.answer && <div>
                                    <p className="help-block" style={{ marginLeft: 0 }}>Please attach the requested document:</p>
                                    <FileUpload
                                        courseId={props.course.id}
                                        onComplete={(doc) => this._onAnswerDocQuestion(doc, lastMessage)} />
                                </div>}
                                {state.answer && <div>
                                    <a target="_blank" href={`/api/files?url=${state.answer.path}`}><FileIcons name={state.answer.name} height={80} isProcessing={true} /> {state.answer.name}</a>
                                </div>}
                                {false && <div>
                                    <h3>OLD</h3>
                                    <input ref="answerDocInput" type="file" onChange={e => this._setAnswerDocFile(e, lastMessage)} />
                                    {!state.answerDocFile && <p className="help-block">
                                        Please attach a document:<br />
                                        <a className="btn btn-default" onClick={() => this.refs.answerDocInput.click()}>Attach a document</a>
                                    </p>}
                                    {state.answerDocFile && <div>
                                        {!state.answerDocFile.completed && <p>
                                            <strong>{state.answerDocFile.name}</strong><br />
                                            <FileUploaderWithProgress doc={state.answerDocFile} actions={this.props.actions} onComplete={this._onAnswerFileComplete.bind(this)} />
                                        </p>}
                                        {state.answerDocFile.completed && <p>
                                            <strong>{state.answerDocFile.name}</strong>
                                        </p>}
                                    </div>}
                                </div>}
                                <br />
                            </div>
                        )}

                        <button
                            className="btn btn-primary"
                            type="submit"
                            aria-label="Reply"
                            disabled={state.answer === ""}>
                            <i className="fa fa-send"></i> Reply
                        </button>
                        {state.userHasStartedAnswering && <span className="margin-left-5" style={{ color: '#858585', fontStyle: 'italic' }}><i className="fa fa-arrow-left" /> Don't forget to hit reply after answering</span>}
                    </form>
                </Card>}
                {showReply && <Card title={replyAsCharacter ? "Reply as character" : "Reply"}>
                    <form
                        onSubmit={this._onSubmit.bind(this)}>
                        <Input
                            value={state.text}
                            onChange={(name, value) => this.setState({ text: value })}
                            type="textarea"
                            placeholder="Type your reply here..."
                            ref="composeMessage" />
                        {state.docsBeingUploaded.length > 0 && <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                            {this.state.docsBeingUploaded.map((doc) => {
                                return <li key={doc.staticId} style={{ paddingBottom: '10px' }}>
                                    <i className="fa fa-file" /> {doc.name}
                                    <FileUploaderWithProgress doc={doc} actions={this.props.actions} onComplete={this._onFileComplete.bind(this)} />
                                </li>
                            })}
                        </ul>}
                        {state.videoUrl && <div>
                            <VideoPlayer url={state.videoUrl} />
                            <p><a onClick={this._onRemoveVideo.bind(this)} className="btn red">Remove video</a></p>
                        </div>}
                        <button className="btn btn-primary"
                            type="submit"
                            aria-label="Send message"
                            disabled={state.docsBeingUploaded.filter(doc => doc.completed === false).length > 0 || state.text === "" || state.isSending}>
                            <i className="fa fa-send"></i> {state.isSending ? "Sending..." : "Reply"}
                        </button>
                        {(lastMessage && lastMessage.type !== 'answer') && <a onClick={() => this.refs.files.click()} className="btn btn-link" style={{ marginLeft: 20 }}><i className="fa fa-paperclip" /> Attach a file</a>}
                        <input
                            onChange={this._setUploadDocs.bind(this)}
                            ref="files"
                            type="file"
                            multiple="multiple" />

                        {(false && !state.videoUrl) && <VideoUpload courseId={props.course.id} onComplete={this._onVideoComplete.bind(this)} />}

                        {(props.isAdmin && activity && !state.showPreviousResponses) && <a onClick={() => this.setState({ showPreviousResponses: true })} style={{ marginTop: 10, display: 'block' }}>Show previous answers ({(activity.data && activity.data.previousResponses) ? activity.data.previousResponses.length : 0}) <i className="fa fa-caret-down" /></a>}
                        {state.showPreviousResponses && <div>
                            <h3>Previous responses</h3>
                            {(!activity.data.previousResponses || (activity.data.previousResponses && activity.data.previousResponses.length === 0)) && <p className="help-block">No canned responses found.</p>}
                            {activity.data.previousResponses && <table className="table table-stripped table-hover">
                                <tbody>
                                    {activity.data.previousResponses.map((response, rI) => <tr key={rI}>
                                        <td><p>{response}</p></td>
                                        <td><a onClick={() => this.setState({ text: response })}>Insert</a></td>
                                    </tr>)}
                                </tbody>
                            </table>}
                            <a onClick={() => this.setState({ showPreviousResponses: false })}>Hide previous answers <i className="fa fa-caret-up" /></a>
                        </div>}
                    </form>
                </Card>}
            </div>
        </div>
    }

    _editActivity() {
        const { props } = this
        if (props.thread && props.thread.activityStaticId) {
            let activity = props.course.byStaticId.activities[props.thread.activityStaticId]
            this.props.actions.local.openModalActivity(activity.id)
        }
    }

    _onRemoveVideo() {
        this.setState({ isRemovingVideo: true })
        api(
            '/video/remove',
            'GET',
            { url: this.state.videoUrl }
        )
            .then(() => {
                this.setState({
                    isRemovingVideo: false,
                    videoUrl: null
                })
            })
            .catch(() => {
                this.setState({ isRemovingVideo: false })
            })
    }

    _onVideoComplete(url) {
        this.setState({ videoUrl: url })
    }

    _onAnswerChange(lastMessage, value) {
        this.setState({
            lastMessage: lastMessage,
            answer: value,
            userHasStartedAnswering: true
        })
    }
    _onSubmitAnswer(e) {
        e.preventDefault()
        this.props.questionAnswered(this.state.lastMessage, this.state.answer, this.props.participant.id)
        this.setState({
            lastMessage: null,
            answer: '',
            userHasStartedAnswering: false
        })
    }

    _onSubmit(e) {
        e.preventDefault()
        const { props, state } = this

        this.setState({
            isSending: true
        })

        let fromId = props.participant.id
        if (props.isAdmin && !props.participant.isAdmin) {
            props.thread.participants.map(participantId => {
                if (!props.course.byId.participants[participantId]) {
                    fromId = participantId
                }
            })
        }
        if (props.isAdmin) {
            let activity = null
            if (props.thread.activityStaticId) {
                activity = props.course.byStaticId.activities[props.thread.activityStaticId]
                if (activity) {
                    activity.data.previousResponses = activity.data.previousResponses || []
                    activity.data.previousResponses.unshift(state.text)
                    activity.data.previousResponses = activity.data.previousResponses.slice(0, 10)
                    this.props.actions.activity.update(activity, () => { })
                }
            }
        }
        props.addMessage(state.text, (activityLog) => {
            if (state.docsBeingUploaded.length > 0) {
                state.docsBeingUploaded.map(doc => {
                    doc.activityStaticId = activityLog.id
                    props.actions.doc.update(doc)
                })

            }
            this.setState({
                text: '',
                docsBeingUploaded: [],
                tempActivityStaticId: 'temp-' + scripts.randomString(),
                isSending: false,
                videoUrl: null
            })
            // if (this.refs.composeMessage && typeof this.refs.composeMessage.focus === 'function') {
            //     this.refs.composeMessage.focus()
            // }
        }, fromId, state.videoUrl)
    }

    _onAnswerDocQuestion(doc, lastMessage) {
        this.setState({
            answer: doc,
            lastMessage: lastMessage,
            userHasStartedAnswering: true
        })

    }

    _onFileComplete(finishedUploadingDoc) {
        var docsBeingUploaded = this.state.docsBeingUploaded.map(doc => {
            if (doc.staticId != finishedUploadingDoc.staticId) { return doc }
            return {
                ...finishedUploadingDoc,
                completed: true
            }
        })
        this.setState({
            docsBeingUploaded: docsBeingUploaded
        })
    }
    _setUploadDocs(e) {
        const course = this.props.course
        let fileList = []
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                // Check if doc name already exists
                // let existingDocNames = course.docs.filter(d => d.name === e.target.files[k].name)
                // if (existingDocNames.length > 0) {
                //     alert(`The file ${e.target.files[k].name} cannot be uploaded because of the name, please change the name of the file and upload again.`)
                // } else {
                let staticId = scripts.randomString()
                var model = {
                    staticId: staticId,
                    activityStaticId: this.state.tempActivityStaticId,
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: course.id,
                    file: e.target.files[k],
                    completed: false
                }
                fileList.push(model)
                // }

            }
        }
        this.refs.files.value = ""
        this.setState({
            docsBeingUploaded: this.state.docsBeingUploaded.concat(fileList)
        })
    }
    _setAnswerDocFile(e, lastMessage) {
        const { course, participant } = this.props
        let fileList = []
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                // Check if doc name already exists
                // let existingDocNames = course.docs.filter(d => d.name === e.target.files[k].name)
                // if (existingDocNames.length > 0) {
                //     alert(`The file ${e.target.files[k].name} cannot be uploaded because of the name, please change the name of the file and upload again.`)
                // } else {
                var model = {
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: course.id,
                    file: e.target.files[k],
                    completed: false,
                    data: {
                        participantId: participant.id,
                        activityId: lastMessage.activity.id
                    }
                }
                fileList.push(model)
                // }

            }
        }
        this.setState({
            answerDocFile: fileList[0],
            lastMessage: lastMessage
        })
    }
    _onAnswerFileComplete(finishedUploadingDoc) {
        this.setState({
            answerDocFile: {
                ...this.state.answerDocFile,
                completed: true
            },
            answer: finishedUploadingDoc.id
        })
    }

    _deleteDoc(doc) {
        if (confirm("Are you sure you want to delete " + doc.name + "?")) {
            this.props.actions.doc.remove(doc)
        }
    }
}
