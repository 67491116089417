import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Button, Input, FormErrors } from 'alias-components'
import FormQuestion from './elements/FormQuestion'
import IsValid from 'alias-tools/validation'
import Scripts from 'alias-tools/scripts'
import structures from 'alias-store/structures'
import Config from 'alias-config'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'


export default class FormModal extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        formId: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let form = {
            ...structures.form
        }
        form.questions = []
        if (props.formId && props.formId !== 'new' && course.byId.forms[props.formId]) {
            form = {
                ...form,
                ...course.byId.forms[props.formId]
            }
        }
        this.state = {
            form: form,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.form.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.formId !== this.state.form.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (nextProps.formId && course.byId.forms[nextProps.formId]) {
                this.setState({
                    form: course.byId.forms[nextProps.formId]
                })
            } else {
                this.setState({
                    form: {
                        ...structures.form,
                        questions: []
                    }
                })
            }
        }
    }

    render() {
        const { form, type } = this.state
        const { state } = this.props
        const course = state.course.model

        var title = "Create a new form"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.formId !== 'new') {
            title = "Update form: " + form.name
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }


        return (
            <Modal show={this.props.isOpen} onHide={this._handleClose.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                {this.props.isOpen && <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <FormErrors fields={this.state.errorFields} />
                            <Input name="name"
                                label="Form name"
                                placeholder="Ex: Stats question for module 1..."
                                value={form.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{
                                    'autoFocus': true
                                }} />
                            {form.questions.map((question, i) => {
                                if (!question) { return null }
                                return (
                                    <FormQuestion key={question.id}
                                        number={i + 1}
                                        course={course}
                                        question={question}
                                        onChange={this._onQuestionChange.bind(this)}
                                        onRemove={this._onQuestionRemove.bind(this)} />
                                )
                            })}
                            <div className="row">
                                <div className="col-xs-7">
                                    <Input type="select"
                                        placeholder="Select question type..."
                                        value={type}
                                        label="New question type"
                                        options={Config.app.questionTypes}
                                        onChange={(name, value) => {
                                            this.setState({
                                                type: value
                                            })
                                        }} />
                                </div>
                                <div className="col-xs-5">
                                    <a className="btn btn-secondary btn-block"
                                        onClick={this._addQuestion.bind(this)}
                                        style={{
                                            marginTop: '20px'
                                        }}><i className="fa fa-plus" style={{
                                            marginRight: '4px'
                                        }} />Add question</a>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} />
                        <a onClick={this._handleClose.bind(this)} className="btn btn-default">Close</a>
                        {(form && form.id) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-clone`}>This will create a duplicate of this form and directly show it here. The duplicate will have the same name with "- clone" at the end.</Tooltip>}>
                            <a onClick={this._cloneForm.bind(this)}
                                className="btn btn-secondary"
                                style={{ marginLeft: '4px' }}>Clone form</a>
                        </OverlayTrigger>}
                        {(form && form.id) && <a onClick={this._deleteForm.bind(this)}
                            className="btn btn-danger"
                            style={{ marginLeft: '4px' }}>Delete</a>}
                    </Modal.Footer>
                </form>}
            </Modal>
        )
    }

    _onQuestionRemove(id) {
        let { form } = this.state
        form.questions = form.questions.filter(question => question.id !== id)
        this.setState({
            form: form,
            isEdited: true
        })
    }

    _onQuestionChange(editedQuestion) {
        let { form } = this.state
        form.questions = form.questions.map((question) => {
            return question.id === editedQuestion.id ? editedQuestion : question
        })
        this.setState({
            form: form,
            isEdited: true
        })
    }

    _addQuestion() {
        let { type, form } = this.state
        if (!type) {
            alert("Please select a type.")
            return false
        }

        let newInput = {
            ...structures.formQuestion,
            score: null,
            options: []
        }
        newInput.id = Scripts.randomString()
        newInput.type = type
        form.questions.push(newInput)
        this.setState({
            form: form,
            isEdited: true
        })
    }

    _onChangeNew(name, value) {
        var form = this.state.form
        form[name] = value
        this.setState({
            form: form,
            isEdited: true
        })
    }

    _isFormValid() {
        var form = this.state.form
        var formIsValid = true
        var errorFields = []
        // Name
        var errors = IsValid(form.name, {
            required: null,
            maxLength: 150
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Form name",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const course = this.props.state.course.model
        let form = this.state.form
        form.courseId = course.id
        this.props.actions.form.create(form, (newForm) => {
            this.setState({ isEdited: false })
            this.props.openModal('form', newForm.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }

        this.props.actions.form.update(this.state.form, () => {
            // this.setState({
            //   form: structures.form
            // })
            // this.props.handleClose()
            this.setState({ isEdited: false })
        })
    }

    _deleteForm() {
        const form = this.state.form
        if (confirm("Are you sure you want to delete " + form.name + "?")) {
            this.props.actions.form.remove(form, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _cloneForm() {
        let form = { ...this.state.form }
        delete form.id
        delete form.staticId
        form.name = form.name + " - Clone"
        this.props.actions.form.create(form, (newForm) => {
            this.props.openModal('form', newForm.id)
        })
    }

    _handleClose() {
        const isEdited = this.state.isEdited
        if (!isEdited) {
            this.props.handleClose()
            return
        }
        let confirmation = confirm("You have edited the form, are you sure you want to leave without saving?")
        if (confirmation) {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}