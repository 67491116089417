import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Button } from 'alias-components'
import { isValidEmail } from 'alias-tools/validation'

export default class ParticipantModalImport extends Component {

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired
    }


    constructor(props) {
        super(props)

        this.state = {
            isFileSelected: false,
            isSaving: false,
            ignoreFirstRow: true,
            usersToImport: [],
            teams: [],
            creatingTeams: false,
            creatingRoles: false
        }
    }

    render() {
        const { teams, usersToImport } = this.state
        let nonValidUsers = []
        if (this.state.isFileSelected) {
            nonValidUsers = usersToImport.filter(user => {
                return !user.isValid
            })
        }

        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Import CSV file</Modal.Title>
                </Modal.Header>
                {!this.state.isFileSelected && (
                    <Modal.Body>
                        <div>
                            <h4>Select a CSV file to import</h4>
                            <p className="help-block">Please use a CSV comma (,) separated file in <strong>UTF-8 format</strong> with the following columns in this exact order:</p>
                            <ol>
                                <li>First name (Required but can be blank)</li>
                                <li>Last name (Required but can be blank)</li>
                                <li>Email (Required and CANNOT be blank)</li>
                                <li>Title (ex: Mr, Dr, ...) (Optional)</li>
                                <li>Team name (Optional)</li>
                                <li>Role X name (Optional)</li>
                            </ol>
                            Here is a sample csv in the correct format for you to use <a target="_blank" href="/live-case-sample-csv.csv" download="live-case-sample-csv.csv">Sample CSV file</a>
                            <p className="help-block">Please note that a participant can be added twice if you need someone to be part of different teams.</p>
                            <p className="help-block">Please note that you can NOT have more than 100 participants per course. This is to improve their experience during the course.</p>
                            <input type="file" ref="picker" onChange={this._fileChanged.bind(this)} />

                            <a onClick={() => {
                                this.refs.picker.click()
                            }} className="btn btn-primary">Select a CSV file</a>
                            <label style={{ marginLeft: '10px' }}>
                                <input type="checkbox"
                                    ref="ignore"
                                    checked={this.state.ignoreFirstRow}
                                    onChange={() => {
                                        this.setState({ ignoreFirstRow: !this.state.ignoreFirstRow })
                                    }} /> Ignore first row</label>
                            <br />
                            <br />
                        </div>
                    </Modal.Body>
                )}
                {this.state.isFileSelected && !this.state.isSaving && (
                    <form onSubmit={this._add.bind(this)}>
                        <Modal.Body>
                            <div>

                                {teams.length > 0 && <div>
                                    <h3>Teams</h3>
                                    <p>You want to create <strong>{teams.length} team{teams.length > 1 ? 's' : ''}</strong></p>
                                    <h3>{teams.map((t, i) => <span key={i} className="label label-info" style={{ marginRight: '5px', marginBottom: '5px' }}>{t}</span>)}</h3>
                                    <br />
                                </div>}


                                <div>
                                    <h3>Participants</h3>
                                    <p>You want to import <strong>{this.state.usersToImport.length} participant{this.state.usersToImport.length > 1 ? 's' : ''}</strong></p>
                                    {nonValidUsers.length > 0 && <div className="alert alert-warning">
                                        <strong>Some emails are not valid</strong>
                                        <p>You can edit them below.</p>
                                    </div>}

                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>First</th>
                                                <th>Last</th>
                                                <th>Email</th>
                                                {teams.length > 0 && <th>Team</th>}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersToImport.map(user => {
                                                return <User
                                                    user={user}
                                                    key={user.tempId}
                                                    onChange={this._onChangeOfUser.bind(this)}
                                                    teams={teams}
                                                    onRemove={this._onRemoveOfUser.bind(this)} />
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button text="Import" isLoading={this.state.isSaving} />
                            <a onClick={this._close.bind(this)} className="btn btn-default">Cancel</a>
                        </Modal.Footer>
                    </form>
                )}
                {this.state.isSaving && (
                    <Modal.Body>
                        <div>
                            <h3>Saving</h3>
                            {this.state.teams && (
                                <p>
                                    {!this.state.creatingTeams && (
                                        <i className="fa fa-check green fa-fw" style={{ marginRight: '10px' }} />
                                    )}
                                    {this.state.creatingTeams && (
                                        <i className="fa fa-spinner fa-pulse fa-fw" style={{ marginRight: '10px' }} />
                                    )}
                                    Creating teams
                                </p>
                            )}
                            <p>
                                <i className="fa fa-spinner fa-pulse fa-fw" style={{ marginRight: '10px' }} />
                                Creating participants
                            </p>
                            <p className="help-block">Please note that it may take a second to refresh the list in the background.</p>
                        </div>
                    </Modal.Body>
                )}
            </Modal>
        )
    }

    _onChangeOfUser(user) {
        let users = this.state.usersToImport.map(originalUser => {
            if (originalUser.tempId === user.tempId) {
                // console.log("Edited user", user.email, user.tempId, user.isValid)
                return user
            }
            return originalUser
        })
        this.setState({
            usersToImport: users
        })
    }

    _onRemoveOfUser(user) {
        let users = this.state.usersToImport.filter(originalUser => {
            if (originalUser.tempId === user.tempId) {
                return false
            }
            return true
        })
        this.setState({
            usersToImport: users
        })
    }

    _close() {
        this.setState({
            usersToImport: [],
            isFileSelected: false,
            isSaving: false,
            isSending: false
        })
        this.props.handleClose()
    }

    _fileChanged(e) {
        const course = this.props.state.course.model
        let usersToImport = []
        let teams = []
        let file = e.target.files[0]
        // if (file.type !== "" && file.type !== 'text/csv') {
        //     return alert("Please select a csv file.")
        // } else
        if (file.name.indexOf('.csv') === -1) {
            // Check the name because windows doesn't give you file type.
            return alert("Please select a csv file.")
        }

        let reader = new FileReader()
        reader.onload = (e) => {
            // e.target.result should contain the text
            let content = e.target.result
            // content = content.replace(/\\r/igm,'')
            let rows = content.split(/\r?\n|\r/)
            let startCounter = (this.state.ignoreFirstRow) ? 1 : 0

            // Max number of participants
            if ((course.participants.length + rows.length) > 102) {
                let areYouSure = confirm(`To provide a better experience to the participants, we advise to not have more than 100 participants per course.\n
                If you require more than 100 participants, we recommend that you copy this course and launch 2 courses simultaneously.`)
                if (!areYouSure) {
                    return
                }
            }
            if (rows.length === 0) {
                return alert("No participants found in file, please make sure there are participants in the file and try again.")
            }

            // Check if it's , or ;
            let delimiter = ','
            if (rows[0] && rows[0].indexOf(delimiter) === -1) {
                delimiter = ';'
            }

            for (let i = startCounter; i < rows.length; i++) {
                let userArray = rows[i].split(delimiter)
                if (!userArray || userArray.length < 3) {
                    continue
                }
                let user = {
                    tempId: i,
                    first: userArray[0].trim(),
                    last: userArray[1].trim(),
                    email: userArray[2] ? userArray[2].toLowerCase().trim() : '',
                    title: userArray[3] ? userArray[3].trim() : '',
                    roles: [],
                    isValid: isValidEmail(userArray[2].trim()),
                    isSending: false
                }

                if (userArray[4]) {
                    // Has teams
                    if (teams.indexOf(userArray[4]) === -1) {
                        teams.push(userArray[4])
                    }
                    user.teamName = userArray[4]
                }
                // For roles
                for (let roleColumn = 5; roleColumn < 15; roleColumn++) {
                    if (userArray[roleColumn]) {
                        let roles = course.roles.filter(role => role.name === userArray[roleColumn])
                        if (roles.length > 0) {
                            user.roles.push(roles[0].staticId)
                        }
                    }
                }
                usersToImport.push(user)
            }
            this.setState({
                isFileSelected: true,
                usersToImport: usersToImport,
                teams: teams
            })
        }
        reader.readAsText(file)
    }

    // _add_new(e) {
    //     e.preventDefault()
    //     const { state } = this.props
    //     let { teams } = this.state
    //     const course = state.course.model

    //     this.setState({
    //         isSaving: true,
    //         creatingTeams: teams.length > 0
    //     })

    //     let teamsToCreate = []
    //     if (teams.length > 0) {
    //         teams = teams.filter()
    //     }
    // }

    _add(e) {
        e.preventDefault()
        const { teams } = this.state
        const course = this.props.state.course.model
        let creatingTeams = true
        this.setState({
            isSaving: true,
            creatingTeams: creatingTeams
        })
        // Create teams
        if (teams.length > 0) {
            let teamCounter = 0
            for (let i = 0; i < teams.length; i++) {
                let currentTeamName = teams[i]
                let teamExists = course.teams.filter(team => {
                    return team.name === currentTeamName
                }).length > 0
                if (!teamExists) {
                    this.props.actions.team.create({
                        name: currentTeamName,
                        courseId: course.id
                    }, () => {
                        teamCounter++
                        if (teamCounter === teams.length) {
                            creatingTeams = false
                            this.setState({
                                creatingTeams: creatingTeams
                            })
                            this._addUserz()

                        }
                    })
                } else {
                    teamCounter++
                    if (teamCounter === teams.length) {
                        creatingTeams = false
                        this.setState({
                            creatingTeams: creatingTeams
                        })
                        this._addUserz()
                    }
                }
            }
        }

        if (teams.length === 0) {
            this._addUserz()
        }
        return false
    }

    _addUserz() {
        const course = this.props.state.course.model
        const teams = course.teams
        const usersToImport = this.state.usersToImport

        const formatedParticipants = JSON.parse(JSON.stringify(usersToImport)).map(user => {
            let newParticipant = {
                isAdmin: false,
                courseId: course.id
            }

            if (user.teamName) {
                let currentTeam = teams.filter(team => team.name === user.teamName)
                if (currentTeam.length > 0) {
                    newParticipant.teamId = currentTeam[0].id
                }
            }
            newParticipant.roles = user.roles

            delete user.roles
            delete user.tempId
            delete user.isValid
            delete user.isSending
            delete user.teamName
            newParticipant.userz = user
            return newParticipant
        })

        this.props.actions.participant.create(formatedParticipants, () => {
            this.setState({
                usersToImport: [],
                isFileSelected: false,
                isSaving: false
            })
            this.props.handleClose()
        })
    }
}

class User extends Component {
    static propTypes = {
        user: PropTypes.any,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        teams: PropTypes.array
    }
    constructor(props) {
        super(props)

    }

    render() {
        const { teams, user } = this.props
        const isValid = isValidEmail(user.email)
        return (
            <tr className={isValid ? '' : 'danger'}>
                <td>
                    <input type="text" value={user.first} placeholder="User's first name"
                        className="form-control" onChange={(e) => this._updateUser('first', e.target.value)} />
                </td>
                <td>
                    <input type="text" value={user.last} placeholder="User's last name"
                        className="form-control" onChange={(e) => this._updateUser('last', e.target.value)} />
                </td>
                <td>
                    <input type="email" value={user.email} placeholder="User's email address"
                        className="form-control" onChange={(e) => this._updateUser('email', e.target.value)} />
                </td>
                <td>
                    <select value={user.teamName}
                        onChange={(e) => this._updateUser('teamName', e.target.value)}
                        className="form-control">
                        <option value={""} disabled>None</option>
                        {teams.map((t, i) => <option key={i} value={t}>{t}</option>)}
                    </select>
                </td>
                <td>
                    <a onClick={() => {
                        this.props.onRemove(user)
                    }} className="btn btn-danger"><i className="fa fa-trash" /></a>
                </td>
            </tr>
        )
    }

    _updateUser(attribute, value) {
        let user = this.props.user
        user[attribute] = value
        this.props.onChange(user)
    }
}