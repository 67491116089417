import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import scripts from 'alias-tools/scripts'

export default class VariableCard extends Component {
    static propTypes = {
        variable: PropTypes.object.isRequired,
        onEditClick: PropTypes.func,
        children: PropTypes.any
    }
    render() {
        const { variable, onEditClick } = this.props;
        return (
            <Card title={variable.name}>
                <div>
                    {onEditClick && <a onClick={onEditClick} className="card-edit"><i className="fa fa-pencil"></i></a>}
                    <p className="help-block">{variable.description ? variable.description : 'No description'}</p>
                    <div className="row">
                        <div className="col-md-6">
                            <strong>Base value</strong> {scripts.numberFormatting(variable.base)} {variable.unit}
                        </div>
                        <div className="col-md-6">
                            <strong>Max difference</strong> +/- {scripts.numberFormatting(variable.difference)}{variable.unit}
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </Card>
        )
    }
}