import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button, FormErrors, Input, Avatar } from 'alias-components'
import IsValid from 'alias-tools/validation'
import ReduxBinder from 'alias-store/ReduxBinder'
import api from 'alias-tools/api'
import localAuthentication from 'alias-tools/localAuthentication'

// TEST LINK: http://localhost:3000/auth/invite/5959c4728e16ac04008a02ac/jwqq6e8x

class Invite extends Component {

    static propTypes = {
        route: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        let email = props.match.params.email || ''
        email = email.replace(/_at_/gi, '@').replace(/_dot_/gi, '.')

        this.state = {
            isRegister: true,
            email: email || 'denis@hashup.io', // email
            password: props.match.params.password || '123whyowhy', // 123
            courseId: props.match.params.courseId,
            course: null,
            inviteCode: props.match.params.inviteCode,
            errorFields: [],
            hasLocalStorage: true,
            isLoading: false,
            loadingCourseError: null
        }
    }

    componentDidMount() {
        this.props.actions.auth.clearMessages()

        this._fetchCourse(this.props.match.params.courseId)
    }

    componentWillUnmount() {
        this.props.actions.auth.clearMessages()
    }

    UNSAFE_componentWillReceiveProps() {

    }

    _fetchCourse(courseId) {
        if (!courseId) {
            return
        }
        this.setState({ isLoading: true })
        api(`/courses/info/${courseId}`, 'GET', {})
            .then((response) => {
                this.setState({
                    isLoading: false,
                    course: response.data ? response.data : null
                })
            })
            .catch((response) => {
                console.warn(response) // eslint-disable-line
                this.setState({ isLoading: false })
            })

    }

    render() {
        const { props, state } = this
        const auth = props.state.auth
        const { email, password } = this.state
        const isDisabled = password === "" || email === ""

        if (state.isLoading) {
            return <div className="text-centered"><i className="fa fa-spinner fa-pulse"></i></div>
        }

        if (localAuthentication.isAuthenticated()) {
            return <div>
                {state.course && <div className="text-centered">
                    You have been invited to join
                    <h3>{state.course.name}</h3>
                    <Avatar user={state.course} size={150} />
                    <p className="help-block">{state.course.description}</p>
                </div>}
                <p className="text-centered">Do you want to join the case?</p>
                <a onClick={this._joinCase.bind(this)} className="btn btn-primary btn-block">Join case</a>
                <Link to="/" className="btn btn-secondary btn-block">View my cases</Link>
            </div>
        }

        if (!state.isRegister) {
            return (
                <form onSubmit={this._login.bind(this)} className="form-horizontal">
                    <Helmet title="Invite login" />
                    {state.course && <div className="text-centered">
                        You have been invited to join
                        <h3>{state.course.name}</h3>
                        <Avatar user={state.course} size={150} />
                        <p className="help-block">{state.course.description}</p>
                    </div>}
                    <fieldset>
                        <legend>LOGIN</legend>
                        {auth.messages.error && (<div className="alert alert-warning"><p>{auth.messages.error}</p></div>)}
                        {auth.messages.success && (<div className="alert alert-success"><p>{auth.messages.success}</p></div>)}
                        <FormErrors fields={this.state.errorFields} />
                        <Input type="email"
                            name="email"
                            label="Your email"
                            placeholder="Ex: john.smith@email.com"
                            value={email}
                            onChange={(name, value) => this.setState({ email: value })}
                            attributes={{ autoFocus: true, disabled: auth.isFetching }} />
                        <Input type="password"
                            name="password"
                            label="Your password"
                            placeholder="Ex: ******"
                            value={password}
                            onChange={(name, value) => this.setState({ password: value })}
                            attributes={{ disabled: auth.isFetching, ref: 'password' }} />
                        <Link to="/auth/forgotten"> Forgot your password?</Link>
                        <br />
                        <br />
                        <Button text="Login"
                            btnClass="btn btn-primary btn-block btn-lg"
                            disabled={isDisabled}
                            isLoading={auth.isFetching} />
                        <p className="help-block text-centered" style={{ fontSize: '16px' }}>
                            <br /> Don't have an account?
                            <br />
                            <a onClick={() => this.setState({ isRegister: true })}>Create an account</a>
                        </p>
                    </fieldset>
                </form>
            )
        }
        if (state.isRegister) {
            return (
                <form onSubmit={this._register.bind(this)} className="form-horizontal">
                    <Helmet title="Invite Register" />
                    {state.course && <div className="text-centered">
                        You have been invited to join
                        <h3>{state.course.name}</h3>
                        <Avatar user={state.course} size={150} />
                        <p className="help-block">{state.course.description}</p>
                    </div>}
                    <fieldset>
                        <legend>CREATE AN ACCOUNT</legend>
                        {auth.messages.error !== null && (
                            <div className="alert alert-warning animated">
                                <p>
                                    {auth.messages.error}
                                </p>
                            </div>
                        )}
                        <FormErrors fields={this.state.errorFields} />
                        <Input type="email"
                            name="email"
                            label="Your email"
                            placeholder="Ex: john.smith@email.com"
                            value={email}
                            onChange={(name, value) => this.setState({
                                email: value
                            })}
                            attributes={{
                                autoFocus: true,
                                disabled: auth.isFetching
                            }} />
                        <Input type="password"
                            name="password"
                            label="Your password"
                            placeholder="Ex: ******"
                            value={password}
                            onChange={(name, value) => this.setState({
                                password: value
                            })}
                            attributes={{
                                disabled: auth.isFetching,
                                ref: 'password'
                            }} />
                        <Button text="Create account"
                            btnClass="btn btn-primary btn-block btn-lg"
                            disabled={isDisabled}
                            isLoading={auth.isFetching} />
                        <p className="help-block text-centered" style={{ fontSize: '16px' }}>
                            <br /> Already have an account?
                            <br />
                            <a onClick={() => this.setState({ isRegister: false })}>Go to login</a>
                        </p>
                    </fieldset>
                </form>
            )
        }

    }

    _onChange() {
        var state = this.state
        state.email = this.refs.email.value
        state.password = this.refs.password.value
        this.setState(state)
        this.props.actions.auth.clearMessages()
    }

    _isFormValid() {
        const { email, password } = this.state
        let formIsValid = true
        let errorFields = []
        let errors
        // password
        errors = IsValid(password, {
            maxLength: 100,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Password",
                errors: errors
            })
        }
        // Email
        errors = IsValid(email, {
            email: null,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Email",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _login(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (!this._isFormValid()) {
            return false
        }

        this.props.actions.auth.login(email, password, (response) => {
            if (response.status === 200) {
                if (this.props.match.params.inviteCode) {
                    this._addUserToCourse(response.data.user)
                }
                // this.props.history.push('/')
            }
        })
    }
    _register(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.auth.register(email, password, (response) => {
            if (response.status === 200) {
                if (this.props.match.params.inviteCode) {
                    this._addUserToCourse(response.data.user)
                }
                // this.props.history.push('/');
                // return;
            } else {
                // this.refs.email.focus();
            }
        })
    }

    _joinCase(e) {
        e.preventDefault()
        let tokenInfo = localAuthentication.isAuthenticated()
        if (tokenInfo && tokenInfo.user) {
            this._addUserToCourse(tokenInfo.user)
        }
    }

    _addUserToCourse(user) {
        const { props } = this
        if (!props.match.params.inviteCode || !user || !props.match.params.courseId) {
            return
        }
        const { inviteCode, courseId } = props.match.params
        this.setState({ isLoading: true })
        api(`/courses/invite/${inviteCode}/${user.id}`, 'GET', {})
            .then((response) => {
                if (response && response.data && response.data.success) {
                    window.location.replace(`${window.location.origin}/course/my/${courseId}`)
                } else {
                    alert("Something went wrong. Most likely situation is that the link is wrong or you have the wrong link. Please contact the person that sent you link and try again.")
                }
            })
            .catch((response) => {
                console.warn(response) // eslint-disable-line
                alert("Something went wrong, plese try again")
                this.setState({ isLoading: false })
                // window.location.
            })

    }
}

export default ReduxBinder(Invite, {
    state: ['auth', 'user']
})