import { combineReducers } from 'redux'
//
import auth from './modules/auth'
import user from './modules/user'

import course from './modules/course'

import module from './modules/module'
import character from './modules/character'
import activity from './modules/activity'
import activityLog from './modules/activityLog'
import organization from './modules/organization'
import role from './modules/role'
import team from './modules/team'
import thread from './modules/thread'
import participant from './modules/participant'
import doc from './modules/doc'
import form from './modules/form'
import scoringTag from './modules/scoringTag'
import badge from './modules/badge'
import variable from './modules/variable'
import value from './modules/value'
import local from './modules/local'
import control from './modules/control'
import forum from './modules/forum'

import notification from './modules/notification'

const rootReducer = combineReducers({
    auth,
    user,
    notification,

    course,
    team,
    thread,
    participant,
    activity,
    activityLog,
    character,
    organization,
    role,
    doc,
    module,
    form,
    scoringTag,
    badge,
    variable,
    value,
    forum,

    local,
    control
});

export default rootReducer
