import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Avatar, VideoPlayer } from 'alias-components'
import Form from '../../../Form'

export default class EmailItem extends Component {

    static propTypes = {
        message: PropTypes.object.isRequired,
        participant: PropTypes.object.isRequired,
        thread: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        minimize: PropTypes.bool,
        course: PropTypes.object.isRequired
    }

    static defaultProps = {
        isAdmin: false,
        minimize: false
    }

    constructor(props) {
        super(props)

        this.state = {
            minimize: props.minimize
        }
    }
    render() {
        const { props, state } = this
        const { participant, message, thread, actions } = props
        let senders = thread.participantsInfo.filter(p => p && p.id === message.participantId)

        let sender = null
        if (senders.length > 0) {
            sender = senders[0]
        }
        if (!sender) {
            senders = thread.participantsInfo.filter(p => p && p.id !== participant.id)
            if (senders.length > 0) {
                sender = senders[0]
            }
        }
        if (!sender) {
            return null
        }
        if (message.type === 'answer' && message.question && message.answer === null) {
            return null
        }

        if (sender && sender.userz && sender.userz.signature && message.message.indexOf(sender.userz.signature) === -1) {
            message.message += "\n" + sender.userz.signature
        }
        message.message = message.message || ""
        message.docs = message.docs || []
        message.videos = message.videos || []
        message.uploadedVideos = message.uploadedVideos || []
        message.message = message.message + ""

        if (message.type === 'answer' && message.question.type === 'multiple-choice' && message.answer !== null) {
            message.question.options.map(option => {
                if (option.id === message.answer.answer) {
                    message.message = option.text
                }
            })
        }

        let doc = false
        if (message.question && message.question.type === 'doc' && message.answer) {
            doc = true
        }
        state.minimize = false

        return <Card>
            <div className="message">
                <div className="title">
                    <div className="email-avatar"><Avatar user={sender.userz} size={30} /></div>
                    <div className="name">{(sender && sender.displayName) ? sender.displayName.short : "Not found"}</div>
                    <span className="pull-right">Sent: {message.created.format("MMM Do H:mm")}</span>
                </div>
                {(false) && <div><a onClick={() => this.setState({ minimize: false })}><i className="fa fa-arrow-down" /> View message</a></div>}
                {true && <div>
                    {(message.message && !doc) && <p className="text" dangerouslySetInnerHTML={{ __html: message.message }}></p>}
                    {(message.formLink) && <p>Please fill in this form: <a target="_blank" href={window.location.origin + message.formLink}>{window.location.origin}{message.formLink}</a></p>}

                    {message.videoUrl && <VideoPlayer url={message.videoUrl} />}

                    {message.uploadedVideos.map((videoLink, i) => <VideoPlayer url={videoLink} key={`uploaded-${i}`} />)}
                    {message.videos.map((videoLink, i) => <VideoPlayer url={videoLink} key={`embeded-${i}`} />)}

                    {message.docs.length > 0 && (
                        <ul className="list-unstyled">
                            {message.docs.map(function (doc) {
                                return <li key={doc.id}>
                                    <a href={!doc.type ? `/api/files?url=${doc.path}` : doc.path} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />
                                        {doc.name}
                                    </a>
                                </li>

                            })}
                        </ul>
                    )}
                    {(message.embeddedFormData && typeof message.embeddedFormData === 'object' && Object.keys(message.embeddedFormData).length > 0) && <div>
                        <Form.Display
                            {...message.embeddedFormData}
                            actions={actions}
                            key={message.created.format()}
                        />
                        {(message.embeddedFormData.log && props.course.showDashboard) && <p className="green"><strong>Great!</strong> Make sure to check your dashboard to see the impact of your decision(s).</p>}
                    </div>}
                    {false && <a onClick={() => this.setState({ minimize: !state.minimize })}><i className="fa fa-arrow-up" /> Show less</a>}
                </div>}
            </div>
        </Card>
    }

    _renderMultipleChoice(message, participant, isAdmin, questionAnswered) {
        let showAnswer = (['multiple-choice'].indexOf(message.question.type) === -1 && message.answer)
        return <span>
            {['multiple-choice'].indexOf(message.question.type) !== -1 && (
                <Input type={message.question.type}
                    options={message.question.options.map(option => {
                        return {
                            text: option.text,
                            id: option.id
                        }
                    })}
                    value={message.answer ? message.answer.answer : ''}
                    disabled={!!message.answer || isAdmin}
                    placeholder='Your answer here...'
                    onChange={(name, value) => questionAnswered(message, value, participant.id)} />)}
            {showAnswer && <span>{message.answer.answer || '...'}</span>}
        </span>
    }
}