import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default class TeamCard extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        canEdit: PropTypes.bool,
        team: PropTypes.object,
        children: PropTypes.any,
        openTeamModal: PropTypes.func,
        yourTeam: PropTypes.bool,
        isAdmin: PropTypes.bool
    }

    static defaultProps = {
        canEdit: false,
        yourTeam: false,
        isAdmin: false
    }

    constructor(props) {
        super(props)

        this.state = {
            showMore: false,
            copied: false
        }
    }

    render() {
        const { props } = this
        const { yourTeam, team, state } = props
        const { showMore } = this.state
        const course = state.course.model

        let participants = course.participants ? course.participants.filter(function (participant) {
            return participant.teamId == team.id
        }) : []
        let hasMore = participants.length > 6
        if (hasMore && !showMore) {
            participants = participants.slice(0, 6)
        }

        let teamName = team.name
        let organization = team.organizationId ? course.byId.organizations[team.organizationId] : null
        if (organization) {
            teamName += ` (${organization.name})`
        }


        return <div className="card-team">
            {!yourTeam && <h3>{teamName} {this.props.canEdit && <a onClick={this.props.openTeamModal.bind(null, team)} className="pull-right edit">Edit</a>}</h3>}
            {this.props.children}
            {participants.length === 0 && <p className="alert alert-info">No team members.</p>}
            {participants.map(member => {
                return <Card key={member.id}>
                    <div className="media">
                        <div className="media-left">
                            <Avatar user={member.userz} size={64} />
                        </div>
                        <div className="media-body">
                            <div className="row">
                                <div className="col-md-6"><h4 className="media-heading">{member.displayName.short}</h4></div>
                                <div className="col-md-6">
                                    <ul style={{ paddingLeft: 15 }}>
                                        {member.roles.length === 0 && <li className="help-block">None</li>}
                                        {member.roles.map(roleStaticId => {
                                            let role = course.byStaticId.roles[roleStaticId]
                                            if (!role) { return }
                                            if (!role.isVisibleByParticipant) { return }
                                            return <li key={member.id + roleStaticId}>{role.name}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            })}
            {hasMore && !showMore && (
                <p>
                    <a onClick={() => {
                        this.setState({ showMore: true })
                    }}><i className="fa fa-chevron-down" /> Show all team members</a>
                </p>
            )}
            {hasMore && showMore && (
                <p>
                    <a onClick={() => {
                        this.setState({ showMore: false })
                    }}><i className="fa fa-chevron-up" /> Show less</a>
                </p>
            )}
            {props.isAdmin && <p>
                <span>Team code: {course.id}-{team.id}</span>
                <CopyToClipboard text={`${course.id}-${team.id}`} onCopy={this.onCopy.bind(this)}>
                    {!this.state.copied ? <a style={{ marginLeft: 5 }}>Copy team code</a> : <span style={{ color: 'green', display: 'inline-block', marginLeft: 5 }}><br />Copied!</span>}
                </CopyToClipboard>
            </p>}

        </div>
    }

    onCopy() {
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 2000)
    }
}