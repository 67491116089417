import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'


export default class WidgetParticipantForms extends Component {
    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            showAllParticipants: false
        }
    }
    render() {
        const { course } = this.props
        const { showAllParticipants } = this.state

        let uniqueParticipants = []



        let participants = course.participants.filter(participant => {
            return !participant.isAdmin && !participant.isActor
        })
        participants.map(participant => {
            if (uniqueParticipants.indexOf(participant.userzId) === -1) {
                uniqueParticipants.push(participant)
            }
        })

        const hasMore = uniqueParticipants.length > 30
        if (hasMore && !showAllParticipants) {
            uniqueParticipants = uniqueParticipants.splice(0, 30)
        }

        return <div>
            <h2>Review your answers</h2>
            <Card>

            </Card>
        </div>
    }
}