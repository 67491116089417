import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, AvatarUploader, FormErrors } from 'alias-components'
import countries from 'alias-data/countries'
import structures from 'alias-store/structures'
import IsValid from 'alias-tools/validation'



export default class ModalCharacter extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        characterId: PropTypes.any,
        character: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let character = { ...structures.character }

        if (props.characterId && props.characterId !== 'new' && course.byId.characters[props.characterId]) {
            character = {
                ...character,
                ...course.byId.characters[props.characterId]
            }
        }
        this.state = {
            character: character,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.character.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.characterId != this.state.character.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (nextProps.characterId && course.byId.characters[nextProps.characterId]) {
                this.setState({
                    character: course.byId.characters[nextProps.characterId]
                })
            } else {
                this.setState({ character: { ...structures.character } })
            }
        }
    }

    render() {
        const { props, state } = this
        const character = state.character
        const organizations = props.state.course.model.organizations

        var title = "Create a new character"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.characterId !== 'new') {
            title = "Update character"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }


        return (
            <Modal show={props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {props.isOpen && <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="first"
                                label="First name"
                                placeholder="Ex: John..."
                                value={character.first}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input
                                name="last"
                                label="Last name"
                                placeholder="Ex: Smith..."
                                value={character.last}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="textarea"
                                name="description"
                                value={character.description}
                                onChange={this._onChangeNew.bind(this)}
                                label="Description"
                                placeholder="Who is this? Is he fun, serious,...?" />

                            <Input
                                type="number"
                                name="age"
                                label="Age"
                                placeholder="How old is this person?...Ex: 35"
                                value={character.age}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="select"
                                name="country"
                                value={character.country}
                                onChange={this._onChangeNew.bind(this)}
                                label="Country"
                                placeholder="Start typing country name..."
                                options={countries} />

                            <Input
                                type="select"
                                name="gender"
                                value={character.gender}
                                onChange={this._onChangeNew.bind(this)}
                                label="Gender"
                                options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }]} />

                            {false && <Input
                                type="select"
                                name="organizationStaticId"
                                value={character.organizationStaticId}
                                onChange={this._onChangeNew.bind(this)}
                                label="Organization"
                                placeholder="Start typing organization name..."
                                options={organizations.map((organization) => {
                                    return {
                                        value: organization.staticId,
                                        label: organization.name
                                    }
                                })} />}

                            <Input
                                type="text"
                                name="title"
                                value={character.title}
                                onChange={this._onChangeNew.bind(this)}
                                label="Title"
                                placeholder="Ex: Head of accounting..." />

                            <Input
                                type="textarea"
                                name="signature"
                                value={character.signature}
                                onChange={this._onChangeNew.bind(this)}
                                label="Email signature"
                                placeholder="Ex: Best, John..." />

                            {(this.props.characterId !== 'new') && (
                                <div>
                                    <strong>Avatar</strong>
                                    <AvatarUploader
                                        refName="charactermodal"
                                        fileType="character"
                                        object={character}
                                        actions={props.actions} />
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(character && character.id) && <a onClick={this._deleteCharacter.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>}
                    </Modal.Footer>
                </form>}
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        let character = this.state.character
        character[name] = value
        this.setState({ character: character, isEdited: true })
    }

    _isFormValid() {
        var character = this.state.character
        var formIsValid = true
        var errorFields = []
        var errors
        // First
        errors = IsValid(character.first, { maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Character first name",
                errors: errors
            })
        }
        // Last
        errors = IsValid(character.last, { maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Character last name",
                errors: errors
            })
        }
        // Age
        errors = IsValid(character.age, { integer: null })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Character age",
                errors: errors
            })
        }
        if (character.first === "" && character.last === "") {
            formIsValid = false
            errorFields.push({
                name: "Character",
                errors: ["The character needs at least a first or last name"]
            })
        }

        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const course = this.props.state.course.model
        let character = this.state.character
        character.courseId = course.id
        this.props.actions.character.create(this.state.character, (character) => {
            this.setState({ isEdited: false })
            this.props.openModal('character', character.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.character.update(this.state.character, () => {
            // this.setState({ character: structures.character })
            // this.props.handleClose()
            this.setState({ isEdited: false })
        })
    }

    _deleteCharacter() {
        const character = this.state.character
        if (confirm("Are you sure you want to delete " + character.first + ' ' + character.last + "?")) {
            this.props.actions.character.remove(character, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}