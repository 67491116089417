import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Helmet from 'react-helmet'
import { Card, FormErrors, Input, AvatarUploader, Button, CircularProgress } from 'alias-components'
import IsValid from 'alias-tools/validation'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Scripts from 'alias-tools/scripts'
import Course from '../components/Course'
import moment from 'moment-timezone'

import gradingImg from 'alias-assets/grading.png'

class CourseRunSettings extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        isConfig: PropTypes.bool
    }
    static defaultProps = {
        isConfig: false
    }
    constructor(props) {
        super(props)

        this.state = {
            course: props.state.course.model,
            errorFields: []
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            course: newProps.state.course.model
        })
    }
    render() {
        const { props } = this
        const course = props.state.course.model

        return (
            <div className="config-right-wrapper">
                <Helmet title={course.name + ' setup'} />
                <h1>Settings</h1>
                {!props.isConfig && <Card>
                    <form className="form-horizontal" onSubmit={this._saveCourse.bind(this)}>
                        <FormErrors fields={this.state.errorFields} />
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Name</label>
                            <div className="col-sm-9">
                                <Input type="text"
                                    name="name"
                                    value={course.name}
                                    placeholder="Enter course name..."
                                    onChange={this._onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Quick description</label>
                            <div className="col-sm-9">
                                <Input type="textarea"
                                    name="description"
                                    placeholder="Enter course quick description..."
                                    value={course.description}
                                    onChange={this._onChange.bind(this)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Avatar</label>
                            <div className="col-sm-9">
                                <AvatarUploader
                                    refName="course"
                                    fileType="course"
                                    object={course}
                                    actions={this.props.actions} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-offset-3 col-sm-10">
                                <Button text="Save" btnClass="btn btn-secondary" isLoading={this.props.state.course.isSaving} />
                            </div>
                        </div>
                    </form>
                </Card>}
                <Card title="Scoring">
                    <p className="help-block">
                        We recommend using a grading system from a case design perspective, but some situations might require you to use only a points system.
                        <br /> You can toggle your scoring from a normal grade out of 5 and a point system or just a point system, see example below:
                    </p>
                    {false && <div className="row">
                        <div className="col-xs-12 col-sm-3">
                            <h4 className="text-centered">Grade</h4>
                            <div className="text-centered">
                                <CircularProgress value={3.5} max={5} />
                                {false && <p className="text-centered">
                                    <strong>130pts</strong>
                                </p>}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <h4 className="text-centered">Just points</h4>
                            <h2 className="text-centered">130pts</h2>
                        </div>
                    </div>}
                    <img src={gradingImg} />
                    <br />
                    <Input type="bool" label="Use grade system?" value={course.scoringAverage}
                        name="scoringAverage" onChange={this._onChange.bind(this)} />
                    <Button text="Save" btnClass="btn btn-secondary" onClick={this._saveCourse.bind(this)} />
                </Card>
                <Card title="Show/hide features from user">
                    <form className="form-horizontal" onSubmit={this._saveCourse.bind(this)}>
                        <p className="help-block">You can show hide features from participants here. Such as Email, Chat and LMS</p>
                        <Input type="bool" name="showDashboard" value={course.showDashboard}
                            label="Show Dashboard feature to participants" onChange={this._onChange.bind(this)} />
                        <hr />
                        <h4>Dashboard features</h4>
                        <ul>
                            <li>
                                <Input
                                    type="bool"
                                    name="showDashboardScores"
                                    value={course.showDashboardScores}
                                    label="Show Dashboard score feature to participants"
                                    onChange={this._onChange.bind(this)} />
                            </li>
                            <li>
                                <Input
                                    type="bool"
                                    name="showDashboardScoringTags"
                                    value={course.showDashboardScoringTags}
                                    label="Show Dashboard tags feature to participants"
                                    onChange={this._onChange.bind(this)} />
                            </li>
                            <li>
                                <Input
                                    type="bool"
                                    name="showDashboardForms"
                                    value={course.showDashboardForms}
                                    label="Show Dashboard forms feature to participants"
                                    onChange={this._onChange.bind(this)} />
                            </li>
                        </ul>
                        <hr />
                        <Input
                            type="bool"
                            name="showEmail"
                            value={course.showEmail}
                            label="Show email feature to participants"
                            onChange={this._onChange.bind(this)} />
                        <Input
                            type="bool"
                            name="showChat"
                            value={course.showChat}
                            label="Show chat feature to participants"
                            onChange={this._onChange.bind(this)} />
                        <Input type="bool" name="showLMS" value={course.showLMS}
                            label="Show LMS feature to participants" onChange={this._onChange.bind(this)} />
                        <Input type="bool" name="showForum" value={course.showForum}
                            label="Show forum feature to participants" onChange={this._onChange.bind(this)} />
                        <Button text="Save" btnClass="btn btn-secondary" onClick={this._saveCourse.bind(this)} />
                    </form>
                </Card>

                <Card title="Team graphs">
                    <p className="help-block">
                        Team graphs allow you to push data for each teams and display it on their dashboards.
                        <br /> Plese install this Google sheets plugin and watch the video to see how it works:{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/live-case-v3/fgphffajofnfcidnihbhpabigpeoghep?utm_source=permalink">
                            Google sheets add-on
                        </a>
                    </p>
                    <ul>
                        {course.teamGraphs &&
                            course.teamGraphs.map(graph => {
                                return (
                                    <li key={graph.id}>
                                        <TeamGraph graph={graph} key={graph.id} onUpdate={this._onGraphUpdate.bind(this)}
                                            onRemove={this._onGraphRemove.bind(this)} />
                                    </li>
                                )
                            })}
                    </ul>
                    <a className="btn btn-link margin-right-5" onClick={this._addTeamGraph.bind(this)}>
                        <i className="fa fa-plus" /> Add a graph
                    </a>
                    <Button text="Save" btnClass="btn btn-secondary" onClick={this._saveCourse.bind(this)} />
                </Card>


                <Card title="Participant team & roles picking">
                    <p>
                        <i className="fa fa-info" /> You can allow participants to select their own teams & roles at the start of the case.
                    </p>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <h4>Teams</h4>
                            <Input type="bool"
                                value={course.participantsCanChooseTeams}
                                name="participantsCanChooseTeams"
                                label="Turn on participant teams picking"
                                onChange={this._onChange.bind(this)} />
                            {course.participantsCanChooseTeams && <Input type="number"
                                name="maxParticipantsPerTeam"
                                label="Max number of participants per team"
                                value={course.maxParticipantsPerTeam}
                                onChange={this._onChange.bind(this)} />}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <h4>Roles</h4>
                            <Input type="bool"
                                value={course.participantsCanChooseRoles}
                                name="participantsCanChooseRoles"
                                label="Turn on participant role picking"
                                onChange={this._onChange.bind(this)} />
                            {course.participantsCanChooseRoles && <Input type="number"
                                name="maxRolesPerParticipant"
                                label="Max number of roles per participant"
                                value={course.maxRolesPerParticipant}
                                onChange={this._onChange.bind(this)} />}
                        </div>
                    </div>

                    <Button text="Save" btnClass="btn btn-secondary" onClick={this._saveCourse.bind(this)} />
                </Card>


                <Card title="Conversational form delays">
                    <p>
                        <i className="fa fa-info" /> When you embed a form into an email or a chat, you can delay the next question (if there is one).
                    </p>
                    <Input type="number" value={course.delayEmail} name="delayEmail"
                        label="Email question delay (in seconds)" onChange={this._onChange.bind(this)} />
                    <Input type="number" value={course.delayChat} name="delayChat"
                        label="Chat question delay (in seconds)" onChange={this._onChange.bind(this)} />
                    <Button text="Save" btnClass="btn btn-secondary" onClick={this._saveCourse.bind(this)} />
                </Card>
                <hr />
                <Card title="Duplication">
                    <div>
                        <p className="help-block">
                            <i className="fa fa-info" /> You can duplicate or create a template from this course. It will copy everything except the participants, authors (except yourself) and teams.
                        </p>
                        <a onClick={() => this.setState({
                            showModalCourse: true
                        })} className="btn btn-default">
                            Duplicate
                        </a>
                        <Course.Modal
                            isShown={this.state.showModalCourse}
                            template={course}
                            actions={this.props.actions}
                            state={this.props.state}
                            close={() => this.setState({ showModalCourse: false })}
                        />
                    </div>
                </Card>
                <Card title="Danger zone">
                    <div>
                        <p className="help-block">
                            <i className="fa fa-info" style={{
                                marginRight: '5px'
                            }} /> Deleting a course will remove the course including it's organizations, characters, roles, modules,...
                            <br />
                            <strong>This cannot be undone!</strong>
                        </p>
                        <br />
                        <Button text="Delete course" btnClass="btn btn-danger" onClick={this._deleteCourse.bind(this)} />
                        <br />
                        <br />
                        <p className="help-block">
                            or <a onClick={this._downloadCourse.bind(this)}>Download as a JSON file</a>
                        </p>
                    </div>
                </Card>
            </div>
        )
    }

    _addTeamGraph() {
        let course = this.state.course
        if (!course.teamGraphs) {
            course.teamGraphs = []
        }
        course.teamGraphs.push({
            id: Scripts.randomString(),
            order: course.teamGraphs.length,
            name: '',
            description: '',
            type: 'table'
        })
        this.setState({
            course: course
        })
    }

    _onGraphUpdate(graph) {
        let course = this.state.course
        course.teamGraphs = course.teamGraphs.map(g => {
            return g.id === graph.id ? graph : g
        })
        this.setState({
            course: course
        })
    }

    _onGraphRemove(graph) {
        let course = this.state.course
        course.teamGraphs = course.teamGraphs.filter(g => g.id !== graph.id)
        this.setState({
            course: course
        })
    }

    _onChange(attributeName, value) {
        var course = this.state.course
        course[attributeName] = value
        this.setState({
            course: course
        })
    }

    _isFormValid() {
        var course = this.state.course
        var formIsValid = true
        var errorFields = []
        var errors
        // Name
        errors = IsValid(course.name, {
            required: null,
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: 'Name',
                errors: errors
            })
        }
        // description
        errors = IsValid(course.description, {
            maxLength: 500
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: 'Description',
                errors: errors
            })
        }
        if (!course.isTemplate) {
            errors = IsValid(course.timezone, {
                required: null
            })
            if (errors.length > 0) {
                formIsValid = false
                errorFields.push({
                    name: 'Timezone',
                    errors: errors
                })
            }
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _saveCourse(e) {
        if (e) {
            e.preventDefault()
        }
        const course = this.state.course
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.course.update(course)
    }

    _deleteCourse(e) {
        e.preventDefault()
        const course = this.props.state.course.model
        if (confirm('Are your sure you want to delete ' + course.name + '?')) {
            this.props.actions.course.remove(course, () => {
                window.location.reload()
            })
        }
    }

    _downloadCourse(e) {
        let course = JSON.parse(JSON.stringify(this.props.state.course.model))
        delete course.byId
        delete course.byStaticId
        const courseName = course.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')
        var dataStr = 'data:text/jsoncharset=utf-8,' + encodeURIComponent(JSON.stringify(course))
        e.target.setAttribute('href', dataStr)
        e.target.setAttribute('download', `${courseName}_${moment().format('YYYY_MM_DD__HH_mm_ss')}.json`)
    }
}

export default ReduxBinder(CourseRunSettings, {
    state: ['course', 'user']
})

class TeamGraph extends Component {
    static propTypes = {
        graph: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props)
        this.state = {
            copied: false
        }
    }
    render() {
        const { graph, onUpdate, onRemove } = this.props
        return (
            <div>
                <div className="row">
                    <div className="col-xs-6">
                        <Input
                            type="text"
                            value={graph.id}
                            disabled={true}
                            name="id"
                            label="ID"
                            onChange={() => { }} />
                    </div>
                    <div className="col-xs-6">
                        <CopyToClipboard text={graph.id} onCopy={this.onCopy.bind(this)}>
                            <button
                                className="btn btn-link"
                                style={{ marginTop: 25 }}>
                                Copy graph code
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <Input
                    type="text"
                    value={graph.name}
                    name="name"
                    label="Name"
                    placeholder="Name of the graph..."
                    onChange={(name, value) => onUpdate({
                        ...graph,
                        name: value
                    })}
                />
                <Input
                    type="text"
                    value={graph.description}
                    name="description"
                    label="Description"
                    placeholder="(*Optional) Describe the graph to the participants..."
                    onChange={(name, value) => onUpdate({
                        ...graph,
                        description: value
                    })}
                />
                <Input
                    type="select"
                    value={graph.type}
                    name="type"
                    label="Type"
                    placeholder="Choose type of display"
                    options={[
                        {
                            label: 'Table',
                            value: 'table'
                        },
                        {
                            label: 'Line graph',
                            value: 'line'
                        },
                        {
                            label: 'Bar graph',
                            value: 'bar'
                        },
                        {
                            label: 'Donught chart',
                            value: 'donught'
                        },
                        {
                            label: 'Pie chart',
                            value: 'pie'
                        },
                        {
                            label: 'Polar chart',
                            value: 'polar'
                        }
                    ]}
                    onChange={(name, value) => onUpdate({
                        ...graph,
                        type: value
                    })}
                />
                <a onClick={() => onRemove(graph)} className="btn btn-danger">
                    Remove graph
                </a>
                <br />
                <br />
            </div>
        )
    }
    onCopy() {
        this.setState({
            copied: true
        })
        const self = this
        setTimeout(() => {
            self.setState({
                copied: false
            })
        }, 2000)
    }
}
