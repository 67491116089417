import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Messaging from '../components/Messaging'

class CourseTabMessaging extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    render() {
        const { match } = this.props
        const participants = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.state.course.model.id && participant.isAdmin
        })
        if (participants.length === 0) {
            return <div>Not an admin</div>
        }
        return <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Messaging.View
                type={match.params.type}
                isAdmin={true}
                admin={participants[0]}
                {...this.props} />
        </div>

    }
}

export default ReduxBinder(CourseTabMessaging, {
    state: ['course', 'user', 'local']
})