import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import CourseMenu from './components/CourseMenu'
import { Link, Route, Switch } from 'react-router-dom'
import ControlTabs from './controlTabs'


class PageControl extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    UNSAFE_componentWillMount() {
        if (['denis@hashup.io', 'adrian@seriesimpact.com', 'tony@seriesimpact.com', 'adrian@livecase.com', 'tony@livecase.com'].indexOf(this.props.state.user.model.email) === -1) {
            this.props.history.push('/')
        }
        this.props.actions.control.getData()
    }

    render() {
        const { location, actions } = this.props

        const baseLink = `/control`
        let nav = [
            {
                name: 'Dashboard',
                link: `${baseLink}/dashboard`,
                baseLink: baseLink
            },
            {
                name: 'Courses & templates',
                link: `${baseLink}/courses`
            },
            {
                name: 'Users & authors',
                link: `${baseLink}/users`
            }
        ]


        let courseWrapperClassName = 'course-wrapper'
        courseWrapperClassName += (baseLink === location.pathname || baseLink + '/' === location.pathname) ? '' : ' tab-selected'


        return <div className={courseWrapperClassName}>
            <Helmet title="Control pages" />
            <CourseMenu nav={nav}
                actions={actions}
                course={{
                    name: 'SI CONTROL',
                    color: 'orange'
                }}
                isConfigurationPage={true} />
            <div className="config-right">
                <div className="mobile-menu hidden-sm hidden-md hidden-lg">
                    <Link to={baseLink} className="btn btn-secondary"><i className="fa fa-chevron-left" />Menu</Link>
                </div>

                <Switch>
                    <Route exact path={`${baseLink}`} component={ControlTabs.Dashboard} />
                    <Route path={`${baseLink}/dashboard`} component={ControlTabs.Dashboard} />
                    <Route exact path={`${baseLink}/users`} component={ControlTabs.Users} />
                    <Route path={`${baseLink}/users/:id`} component={ControlTabs.User} />
                    <Route path={`${baseLink}/courses`} component={ControlTabs.Courses} />
                </Switch>
            </div>
        </div>
    }
}

export default ReduxBinder(PageControl, {
    state: ['user', 'control']
})