import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'
import Select from 'react-select'
import Creatable from 'react-select/Creatable'
import moment from 'moment-timezone'
import Config from 'alias-config'
import { Modal } from 'react-bootstrap'
import DateTimeSetter from './DateTimeSetter'
import scripts from 'alias-tools/scripts'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default class Input extends Component {

    static propTypes = {
        type: PropTypes.oneOf(['bool', 'select', 'select-multi', 'dateTime', 'modalDateTime', 'multiple-choice', 'text', 'range', 'number', 'textarea', 'email', 'password', 'scoring']),

        name: PropTypes.any,
        value: PropTypes.any,
        label: PropTypes.string,
        description: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        attributes: PropTypes.object,
        disabled: PropTypes.bool,
        // For select
        options: PropTypes.array,
        multiSelect: PropTypes.bool,
        // For textarea
        minRows: PropTypes.number,
        // For date time
        timezone: PropTypes.any
    }
    /*eslint-disable*/
    static defaultProps = {
        type: 'text',
        name: '',
        value: '',
        label: '',
        description: '',
        placeholder: '',
        options: [],
        multiSelect: false,
        attributes: {},
        minRows: 3,
        disabled: false
    }
    /*eslint-enable*/

    constructor(props) {
        super(props)

        this.state = {
            hasFocus: false,
            showModalDateTime: false
        }
    }

    render() {
        const { label, description, value } = this.props

        let className = "special-input"
        className += value === '' ? ' on' : ''
        className += this.state.hasFocus ? ' focused' : ''

        const uniqueCode = scripts.randomString()

        return (
            <span className={className}>
                {label !== '' && <label htmlFor={uniqueCode}>{label}</label>}
                {description !== '' && <p className="help">{description}</p>}
                {this._getRender(uniqueCode)}
            </span>
        )
    }

    _onChange(name, value) {
        // this.setState({
        //     value: value
        // })
        this.props.onChange(name, value)
    }

    _getRender(uniqueCode) {
        const { type } = this.props

        switch (type) {
            case 'bool':
                return this._inputBool(uniqueCode)
            case 'textarea':
                return this._inputTextarea(uniqueCode)
            case 'select':
                return this._inputSelect(uniqueCode)
            case 'select-multi':
                return this._inputSelectMulti(uniqueCode)
            case 'dateTime':
                return this._inputDateTime(uniqueCode)
            case 'modalDateTime':
                return this._inputModalDateTime(uniqueCode)
            case 'multiple-choice':
                return this._inputMultipleChoice(uniqueCode)
            case 'text':
            case 'number':
            case 'range':
            case 'email':
            case 'password':
            case 'scoring':
                return this._inputText(uniqueCode)
        }
    }

    _inputText(uniqueCode) {
        const { type, placeholder, attributes, disabled, options, name } = this.props
        let { value } = this.props

        let allAttributes = {
            ...attributes,
            disabled: disabled
        }
        if (type === 'range' && options) {
            allAttributes.min = options[0] || 0
            allAttributes.max = options[1] || 100
        }


        if (type !== 'number' && type !== 'scoring') {
            // eslint-disable-next-line no-undefined
            if (value === null || value == undefined) {
                value = ''
            }
        } else {
            // eslint-disable-next-line no-undefined
            if (value === null || value == undefined) {
                value = 0
            }
        }

        return <div>
            <input id={uniqueCode}
                ref="input"
                value={value}
                type={type}
                placeholder={placeholder}
                onChange={(e) => {
                    let value = e.target.value
                    if (type === 'number' && value) {
                        value = Number(value)
                    }
                    this._onChange(name, value)
                }}
                onFocus={() => {
                    this.setState({
                        hasFocus: true
                    })
                }}
                onBlur={() => {
                    this.setState({
                        hasFocus: false
                    })
                }}
                {...allAttributes}
                className="input" />
            {type === 'range' && (
                <div className="range">
                    <div className="min">
                        Min:
                        {options[0] || 0}
                    </div>
                    Value:
                    {value || 'Not selected'}
                    <div className="max">
                        Max:
                        {options[1] || 300}
                    </div>
                </div>
            )}
        </div>
    }

    _inputTextarea(uniqueCode) {
        const { placeholder, attributes, disabled, minRows, name, value } = this.props

        let allAttributes = {
            ...attributes,
            disabled: disabled
        }

        return <Textarea id={uniqueCode}
            inputRef={tag => (this.textarea = tag)}
            minRows={minRows}
            value={value || ''}
            placeholder={placeholder}
            onChange={(e) => this._onChange(name, e.target.value)}
            onFocus={() => this.setState({ hasFocus: true })}
            onBlur={() => this.setState({ hasFocus: false })}
            {...allAttributes}
            className="input" />
    }

    _inputBool(uniqueCode) {
        const { name, value } = this.props

        return <div className="boolean">
            {value === true && <OverlayTrigger placement="top" overlay={<Tooltip id={uniqueCode}>ON/YES</Tooltip>}>
                <a onClick={() => this._onChange(name, !value)} className="selected" style={{ paddingTop: 13 }}><i className="fa fa-toggle-on" /></a>
            </OverlayTrigger>}
            {(value === false || value === '') && <OverlayTrigger placement="top" overlay={<Tooltip id={uniqueCode}>OFF/NO</Tooltip>}>
                <a onClick={() => this._onChange(name, !value)} className="not-selected" style={{ paddingTop: 13 }}><i className="fa fa-toggle-off" /></a>
            </OverlayTrigger>}
        </div>
    }

    _inputSelect() {
        const { props } = this

        let allAttributes = {
            ...props.attributes,
            disabled: props.disabled
        }

        let value = props.value


        if (props.multiSelect) {
            if (value) {
                value = props.options.filter(o => value.indexOf(o.value) !== -1)
            } else {
                value = []
            }
        } else {
            props.options.filter(o => {
                if (o.value != undefined && o.value === value) {
                    value = o
                    return true
                } else if (o.value == undefined && o.options) {
                    o.options.map(o1 => {
                        if (o1.value === value) {
                            value = o1
                            return
                        }
                    })
                }
                return false
            })
        }

        return <div style={{ marginBottom: 10 }}>
            <Select
                name="select"
                value={value}
                options={props.options}
                isMulti={props.multiSelect}
                isClearable={true}
                isSearchable={true}
                onChange={(val) => {
                    if (!props.multiSelect) {
                        if (val) {
                            this._onChange(props.name, val.value)
                        } else {
                            this._onChange(props.name, null)
                        }
                    } else {
                        this._onChange(props.name, val ? val.map(v => v.value) : [])
                    }
                }}
                onFocus={() => {
                    this.setState({
                        hasFocus: true
                    })
                }}
                onBlur={() => {
                    this.setState({
                        hasFocus: false
                    })
                }}
                placeholder={props.placeholder}
                {...allAttributes} />
        </div>
    }

    _inputSelectMulti(uniqueCode) {
        const { name, placeholder, attributes, options, disabled, value } = this.props

        let allAttributes = {
            ...attributes,
            disabled: disabled
        }

        return <Creatable id={uniqueCode}
            name="select"
            multi={true}
            delimiter=','
            value={value}
            options={options}
            isClearable={true}
            onChange={(val) => {
                if (value) {
                    value.push(val.value)
                    this._onChange(name, value)
                } else {
                    this._onChange(name, [])
                }
            }}
            onFocus={() => {
                this.setState({
                    hasFocus: true
                })
            }}
            onBlur={() => {
                this.setState({
                    hasFocus: false
                })
            }}
            placeholder={placeholder}
            {...allAttributes} />
    }

    _inputDateTime() {
        const { timezone, name, value } = this.props

        return <DateTimeSetter date={value}
            timezone={timezone}
            onChange={(newDate) => {
                this._onChange(name, newDate)
            }} />
    }

    _inputModalDateTime() {
        const { timezone, disabled, name, value } = this.props

        return <div>
            <a className="link" onClick={() => {
                if (!disabled) {
                    this.setState({
                        showModalDateTime: true
                    })
                }
            }}>
                {value && moment(value).tz(timezone) ? moment(value).tz(timezone).format(Config.app.time.dateTimeFormat) : "Not set"}
            </a>
            <Modal show={this.state.showModalDateTime}
                bsSize="small"
                onHide={() => {
                    this.setState({
                        showModalDateTime: false
                    })
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Set date and time
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateTimeSetter date={value}
                        timezone={timezone}
                        onChange={(newDate) => {
                            this._onChange(name, newDate)
                        }} />
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={() => {
                        this.setState({
                            showModalDateTime: false
                        })
                    }} className="btn btn-default">Close</a>
                </Modal.Footer>
            </Modal>
        </div>
    }

    _inputMultipleChoice() {
        const { name, options, disabled, value } = this.props

        return <span className="multiple-choice">
            {options.map((option, i) => {
                const selected = value === option.id
                return <a key={option.id}
                    onClick={() => {
                        if (selected || disabled) {
                            return
                        }
                        this._onChange(name, option.id)
                    }}
                    className={`choice ${selected ? 'selected' : ''}`}><i className={`fa fa-circle${selected ? '' : '-o'} fa-fw`} />
                    {option.text}
                </a>
            })}
        </span>
    }
}