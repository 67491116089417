import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'

export default class Layout extends Component {

    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.any
    }

    static defaultProps = {
        title: ""
    }

    render() {
        return <div id="layout">
            <Header title={this.props.title} />
            <div id="full-page">
                {this.props.children}
            </div>
        </div>

    }
}