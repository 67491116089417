import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { } from 'alias-components'
import moment from 'moment-timezone'
import ChatItem from './ChatItem'



export default class MessagingConversationChat extends Component {
    static propTypes = {
        participant: PropTypes.object.isRequired,
        threadId: PropTypes.string,
        thread: PropTypes.object,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool,
        back: PropTypes.func.isRequired,
        questionAnswered: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        inputPlaceholderText: PropTypes.string,
        course: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            text: ''
        }

    }

    componentWillUnmount() {
        if (this.refreshLatest) {
            clearInterval(this.refreshLatest)
            this.refreshLatest = null
        }
    }

    render() {
        const { props, state } = this
        let inputIsDisabled = true
        let isTyping = false

        if (props.isAdmin) { inputIsDisabled = false }
        if (!props.isAdmin && props.thread.isOnline) { inputIsDisabled = false }

        let messages = props.thread ? [...props.thread.messages] : []
        let lastMessage = (messages.length > 0) ? messages[messages.length - 1] : null

        let hasFollowUpQuestionComing = false
        if (lastMessage) {
            if (lastMessage.created && lastMessage.created.isAfter(moment())) {
                if (!this.refreshLatest) {
                    this.refreshLatest = setTimeout(() => {
                        clearInterval(this.refreshLatest)
                        this.refreshLatest = null
                        this.forceUpdate()
                    }, lastMessage.created.diff(moment()) + 2000)
                }
                hasFollowUpQuestionComing = true
                messages.splice(messages.length - 2, 2)
                lastMessage = messages[messages.length - 1]
            }
        }

        let showForm = false
        if (props.isAdmin) {
            showForm = hasFollowUpQuestionComing === false
        } else {
            if (lastMessage && lastMessage.question && ['multiple-choice', 'doc'].indexOf(lastMessage.question.type) !== -1 && !lastMessage.answer) {
                showForm = false
            } else {
                showForm = true
            }
            if (isTyping) {
                showForm = false
            }
        }

        let placeholderText = props.inputPlaceholderText
        if (hasFollowUpQuestionComing) {
            let charStaticId = props.thread.participantsInfo.filter(p => p.id !== props.participant.id).length > 0 ?
                props.thread.participantsInfo.filter(p => p.id !== props.participant.id)[0].id : null
            messages.push({
                message: '...',
                participantId: charStaticId
            })

            placeholderText = "Type your message here..."
            inputIsDisabled = true
        }


        return <div style={{ width: '100%', height: '100%' }}>
            <div className="list">
                {messages.map((message, i) => <ChatItem key={i}
                    message={message}
                    participant={props.participant}
                    isAdmin={props.isAdmin}
                    questionAnswered={props.questionAnswered}
                    thread={props.thread}
                    course={props.course}
                    actions={props.actions} />)}
                <br />
            </div>
            {showForm && <form
                onSubmit={this._answerQuestion.bind(this, lastMessage)}
                className="chat-input">
                <input disabled={inputIsDisabled}
                    value={state.text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    type={!lastMessage ? "text" : (lastMessage && lastMessage.question && ['range', 'number', 'scoring'].indexOf(lastMessage.question.type) !== -1) ? 'number' : 'text'}
                    placeholder={placeholderText}
                    ref="chatInput" />
                <button type="submit" aria-label="Send message"><i className="fa fa-send"></i></button>
            </form>}
        </div>

    }

    _answerQuestion(lastMessage, e) {
        e.preventDefault()
        const { props, state } = this
        if (lastMessage && lastMessage.type === 'answer') {
            props.questionAnswered(lastMessage, state.text, props.participant.id, () => {
                this.setState({ text: '' })
            })
        } else {
            let fromId = props.participant.id
            if (props.isAdmin && !props.participant.isAdmin) {
                props.thread.participants.map(participantId => {
                    if (!props.course.byId.participants[participantId]) {
                        fromId = participantId
                    }
                })
            }
            props.addMessage(state.text, () => {
                this.setState({ text: '' })
            }, fromId)
        }
    }
}