/* globals io */
import localAuthentication from 'alias-tools/localAuthentication'
import config from 'alias-config'


class LiveUpdate {
    constructor() {
        this.debug = true
        // this.onDisconnect = onDisconnect
        this.room = null
        this.subscriptions = []
        this.socket = io(config.api.socketIOhost)
        this.isAuthenticatedBool = false
        this.isAuthenticating = false
        this.hasLostConnection = false
        this.retryConnectionInterval = null
        this.addNotification = null
        this.removeNotification = null
        this.notificationCounter = 0
        this.notificationIdsList = []
        this.wakeInterval = null

        this.authenticate()

        // this.isAuthenticated = this.isAuthenticated.bind(this)
        this.joinCourse = this.joinCourse.bind(this)
        this.leaveCourse = this.leaveCourse.bind(this)
        // this.joinRoom = this.joinRoom.bind(this)
        // this.leaveRoom = this.leaveRoom.bind(this)
        // this.subscribe = this.subscribe.bind(this)
        // this.unSubscribeAll = this.unSubscribeAll.bind(this)
    }

    //
    // AUTHENTICATION
    //
    authenticate(callback) {
        if (this.isAuthenticatedBool || this.isAuthenticating) {
            if (callback) callback() // eslint-disable-line
            return
        }
        const token = localAuthentication.isAuthenticated()
        if (token) {
            this.socket.removeAllListeners('connect')
            this.socket.removeAllListeners('authenticated')
            this.socket.removeAllListeners('disconnect')
            this.socket.removeAllListeners('reconnect')
            this.socket.removeAllListeners('error')
            this.socket.open()
            this.socket.on('connect', () => {
                if (this.retryConnectionInterval) { clearInterval(this.retryConnectionInterval) }
                if (!this.isAuthenticating) {
                    this.isAuthenticating = true
                    // this.socket.emit('authentication', { id: token.id, userId: token.userId })
                }
                // this.isAuthenticatedBool = true
                // this.socket.on('authenticated', () => {
                //     console.log('User is authenticated on live server') // eslint-disable-line
                //     this.isAuthenticating = false
                //     this.isAuthenticatedBool = true
                //     if (callback) {
                //         callback()
                //     }
                // })
                if (callback) {
                    callback()
                }

                //
                // Disconnect issues
                //
                const disconnectFunc = (evt, reason) => {
                    console.warn(`LIVE SERVER: ${evt} => Reason: `, reason) // eslint-disable-line
                    this.isAuthenticating = false
                    this.isAuthenticatedBool = false
                    this.isJoiningRoom = false
                    // this.authenticate(callback)
                    this.hasLostConnection = true
                    if (false && this.addNotification) { // eslint-disable-line
                        this.notificationCounter = this.notificationCounter + 1
                        if (this.notificationCounter < 3 && this.notificationCounter > 1) {
                            this.notificationCounter = this.notificationCounter + 1
                            this.notificationIdsList.push(evt + this.notificationCounter)
                            this.addNotification({
                                id: evt + this.notificationCounter,
                                type: 'danger',
                                title: `Lost internet connection`,
                                body: `Any changes will not be saved, please reload the page.`,
                                timeout: null
                            })
                        }
                    }
                }
                this.socket.on('disconnect', (data) => {
                    disconnectFunc('disconnect', data)
                })
                this.socket.on('error', function (err) {
                    disconnectFunc('error', err)
                })
                // this.socket.on('reconnect_error', (err) => {
                //     disconnectFunc('reconnect_error', err)
                // })

                this.socket.on('reconnect_failed', (err) => {
                    console.warn("Reconnect failed", err) // eslint-disable-line
                    // disconnectFunc('reconnect_failed', err)
                })

                this.socket.on('connect_error', (err) => {
                    disconnectFunc('connect_error', err)
                })

                this.socket.on('reconnect', () => {
                    if (this.hasLostConnection) {
                        this.todoOnReconnect(callback, 'Socket reconnect')
                    }
                })

            })
        } else {
            console.warn("User doesn't have token") // eslint-disable-line
        }
    }

    todoOnReconnect(callback, fromReason = "Unknown") {
        console.warn("LIVE SERVER: Reconnecting and refreshing => From: ", fromReason) // eslint-disable-line
        this.notificationCounter = 0
        clearInterval(this.retryConnectionInterval)
        this.authenticate(callback)
        this.hasLostConnection = false
        if (this.onDisconnect) {
            this.onDisconnect()
            if (this.removeNotification && this.notificationIdsList.length > 0) {
                this.notificationIdsList.map(id => {
                    this.removeNotification({ id: id })
                })
            }
        }
    }

    launchWakeInterval() {
        let SLEEP_WAKE_TIMEOUT = 10000
        let SLEEP_WAKE_TIMEOUT_TOLERANCE = 2000
        let lastTime = (new Date()).getTime()

        this.wakeInterval = setInterval(() => {
            var currentTime = (new Date()).getTime()
            // console.log(currentTime, lastTime + SLEEP_WAKE_TIMEOUT + SLEEP_WAKE_TIMEOUT_TOLERANCE, currentTime > (lastTime + SLEEP_WAKE_TIMEOUT + SLEEP_WAKE_TIMEOUT_TOLERANCE))
            if (currentTime > (lastTime + SLEEP_WAKE_TIMEOUT + SLEEP_WAKE_TIMEOUT_TOLERANCE)) {
                // Wake!
                // console.warn("Has slept, now back")
            }
            lastTime = currentTime
        }, SLEEP_WAKE_TIMEOUT)
    }

    stopWakeInterval() {
        if (this.wakeInterval) {
            clearInterval(this.wakeInterval)
        }
    }

    //
    // CUSTOM FOR PLATFORM, REST CAN BE REUSED
    //
    joinCourse(room, subscribe, onDisconnect, addNotification = null, removeNotification = null) {
        this.onDisconnect = onDisconnect
        this.addNotification = addNotification
        this.removeNotification = removeNotification
        this.unSubscribeAll()
        // if (!this.isAuthenticatedBool) {
        //     this.authenticate(() => {
        //         if (room) {
        //             this.joinRoom(room, () => {
        //                 if (subscribe) {
        //                     this.subscribe(subscribe)
        //                 }
        //             })
        //         }
        //     })
        // } else {
        if (room) {
            this.joinRoom(room, () => {
                if (subscribe) {
                    this.subscribe(subscribe)
                }
            })
        }
        // }

        // this.launchWakeInterval()
    }

    leaveCourse() {
        this.leaveRoom()
    }


    //
    // JOIN/LEAVE ROOM
    //

    joinRoom(newRoom, callback) {
        // if (!this.isAuthenticatedBool) return console.warn('You are not authenticated on the live server') // eslint-disable-line
        if (this.room && this.room === newRoom) return // eslint-disable-line
        if (this.room) this.leaveRoom() // eslint-disable-line
        if (this.isJoiningRoom === true) return // eslint-disable-line
        if (this.debug) console.info("Joining ", newRoom) // eslint-disable-line
        this.room = newRoom
        this.isJoiningRoom = true
        let data = { room: newRoom }
        const token = localAuthentication.isAuthenticated()
        if (token) {
            data.token = token
        }
        this.socket.emit('joinRoom', data)
        this.socket.on('joinedRoom', () => {
            this.pushSubscription('joinedRoom')
            this.isJoiningRoom = false
            if (this.debug) console.info("Joined ", newRoom) // eslint-disable-line
            if (callback) callback() // eslint-disable-line
        })
    }
    leaveRoom() {
        this.stopWakeInterval()
        if (!this.room) return // eslint-disable-line
        this.unSubscribeAll()
        if (this.debug) console.info("Leaving room", this.room) // eslint-disable-line
        this.room = null
        this.socket.emit('leaveRoom', this.room)
    }

    //
    // SUBSCRIPTIONS
    //
    subscribe(subscribes) {
        // if (!this.isAuthenticatedBool) return console.warn('You are not authenticated on the live server') // eslint-disable-line
        if (!this.room) return console.warn("You cannot subscribe if no room is joined. Please join a room first") // eslint-disable-line

        if (Object.prototype.toString.call(subscribes) !== '[object Array]') {
            subscribes = [subscribes]
        }
        subscribes.map(subscription => {
            if (!subscription.method || !subscription.collectionName) return console.warn("Missing method or collection name when subscribing.") // eslint-disable-line
            this.addSubscription(subscription)
        })
    }
    addSubscription({ method, collectionName, callback }) {
        if (!method || !collectionName) return console.warn("Missing method or collection name when subscribing.") // eslint-disable-line
        let name = `${method}/${collectionName}`
        if (this.subscriptions.indexOf(name) !== -1) return // eslint-disable-line
        this.socket.on(name, (models) => {
            if (this.debug) console.log(models) // eslint-disable-line
            if (callback) callback(models) // eslint-disable-line
        })
        this.pushSubscription(name)

    }
    pushSubscription(subscriptionName) {
        this.subscriptions.push(subscriptionName)
    }
    unSubscribeAll() {
        this.subscriptions.map(subscription => {
            this.socket.removeAllListeners(subscription)
        })

        //Now reset the container..
        this.subscriptions = []
        this.socket.emit('leaveRoom', this.room)
    }
}

const liveUpdate = new LiveUpdate()

export default liveUpdate