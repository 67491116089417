


const csv = {
    arrayOfObjectsToCsv(data, columns = [], columnDelimiter = ',', lineDelimiter = '\n') {
        let csv = 'data:text/csv;charset=utf-8,'

        // Column name
        if (columns) {
            csv += columns.join(columnDelimiter) + lineDelimiter
        }

        data.map(row => {
            let rowArray = []
            Object.keys(row).map(cell => {
                rowArray.push(row[cell])
                csv += row[cell] + columnDelimiter
            })
            csv += lineDelimiter
        })

        csv = encodeURI(csv)
        return csv
    }
}

export default csv