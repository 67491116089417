import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, Avatar, FormErrors } from 'alias-components'
import IsValid from 'alias-tools/validation'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const initialState = {
    name: "",
    courseId: 0,
    id: null,
    organizationId: 0,
    adminId: 0
}

export default class TeamModal extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        courseId: PropTypes.any,
        handleClose: PropTypes.func.isRequired,
        team: PropTypes.object,
        isOpen: PropTypes.bool,
        onCreate: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            team: (props.team === null) ? Object.assign({}, initialState, { courseId: props.courseId }) : props.team,
            addParticipantSelect: "",
            errorFields: [],
            copied: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.team.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.team !== null) {
            this.setState({
                team: nextProps.team
            })
        } else {
            this.setState({
                team: Object.assign({}, initialState, { courseId: this.props.courseId })
            })
        }
    }

    render() {
        const team = this.state.team
        const course = this.props.state.course.model

        var title = "Create a new team"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.team !== null || team.id !== null) {
            title = "Update team"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }

        const participants = (course.participants) ? course.participants.filter(participant => !participant.isAdmin) : []

        const teamParticipants = (participants && (this.props.team !== null || team.id !== null)) ? participants.filter(participant => participant.teamId === team.id) : []


        return (
            <Modal show={this.props.isOpen} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div>
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Dragons..."
                                value={team.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />


                            {(this.props.team !== null || team.id !== null) && (
                                <div>
                                    {/*<Input
                                        type="select"
                                        name="organizationId"
                                        value={team.organizationId}
                                        onChange={this._onChangeNew.bind(this)}
                                        label="Organization"
                                        options={organizations.map(organization => {
                                            return {
                                                value: organization.id,
                                                label: organization.name
                                            }
                                        })} />*/}
                                    <br />
                                    <br />
                                    <h4>Members</h4>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            {teamParticipants.map(participant => {
                                                return <tr key={participant.id}>
                                                    <td><Avatar user={participant.userz} size={20} /> {participant.userz.first} {participant.userz.last} <a onClick={this._removeParticipant.bind(this, participant)} className="pull-right red"><i className="fa fa-times" /></a></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    <Input
                                        type="select"
                                        name="participant"
                                        value=""
                                        onChange={this._onChangeParticipant.bind(this)}
                                        label="Add a participant"
                                        placeholder="Select participant to add"
                                        options={participants.map(participant => {
                                            return {
                                                value: participant.id,
                                                label: participant.displayName.short,
                                                disabled: (participant.teamId) ? true : false
                                            }
                                        })} />


                                    <br />
                                    <h4>Team code</h4>
                                    <p className="help-block">Code used for the Google sheets integration.</p>
                                    <div className="row">
                                        <div className="col-xs-10">
                                            <Input
                                                type="text"
                                                value={`${course.id}-${team.id}`}
                                                disabled={true}
                                                onChange={() => { }} />
                                        </div>
                                        <div className="col-xs-2">
                                            <CopyToClipboard text={`${course.id}-${team.id}`} onCopy={this._onCopy.bind(this)}>
                                                {!this.state.copied ? <a className="btn btn-secondary">Copy</a> : <a className="btn btn-link">Copied!</a>}
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} isLoading={this.props.state.team.isSaving} />
                        <a onClick={this.props.handleClose} className="btn btn-default">Close</a>
                        <a onClick={this._remove.bind(this)} className="btn btn-danger" style={{ marginLeft: '4px', display: (this.props.team || this.state.team.id) ? 'inline-block' : 'none' }}>Delete</a>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onCopy() {
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 2000)
    }

    _onChangeNew(name, value) {
        let team = this.state.team
        team[name] = value
        this.setState({
            team: team
        })
    }

    _onChangeParticipant(name, value) {
        var participant = this.props.state.course.model.participants.filter(participant => participant.id == value)
        if (participant.length === 0) { return }
        participant = participant[0]
        if (this.props.team !== null) {
            participant.teamId = this.props.team.id
        }
        if (this.state.team.id) {
            participant.teamId = this.state.team.id
        }
        this.props.actions.participant.update(participant)
    }

    _isFormValid() {
        var team = this.state.team
        var formIsValid = true
        var errorFields = []
        // Name
        var errors = IsValid(team.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Team name",
                errors: errors
            })
        }

        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.team.create(this.state.team, (team) => {
            // Reopens modal with new team
            this.props.onCreate(team)
            // this.props.actions.local.modal('team', team.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.team.update(this.state.team)
    }

    _removeParticipant(participant, e) {
        e.preventDefault()
        participant.teamId = ""
        this.props.actions.participant.update(participant)
    }

    _remove(e) {
        e.preventDefault()
        if (confirm("Are you sure you want to delete the " + this.state.team.name + " team?")) {
            this.props.actions.team.remove(this.state.team, () => {
                this.props.handleClose()
            })
        }
    }
}