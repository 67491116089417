import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Card, LMSDisplay, Avatar, LMSChapter } from 'alias-components'
import Helmet from 'react-helmet'
import Scripts from 'alias-tools/scripts'
import countries from 'alias-data/countries'
import Widgets from '../components/Widgets'

class CourseConfigurationOverview extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    render() {
        const { state } = this.props
        const course = state.course.model

        return <div className="config-right-wrapper">
            <Helmet title={course.name + " setup"} />
            <h1>Overview</h1>
            <p className="help-block">Review of the case for first time or refresher</p>


            {!Scripts.isEditorEmpty(course.teacher) && (
                <Card title="Teacher notes">
                    <LMSDisplay
                        course={course}
                        text={course.teacher}
                        for="teacher" />
                </Card>
            )}
            {(course.teachers && course.teachers.length > 0) && <div>
                <h2>Teacher notes</h2>
                <LMSChapter
                    isEditable={true}
                    value={course.teachers}
                    onSave={(value, callback) => this._onSaveLMS('teachers', value, callback)} />
            </div>}
            {!Scripts.isEditorEmpty(course.intro) && (
                <Card title="Introduction shown to participants">
                    <LMSDisplay
                        course={course}
                        text={course.intro}
                        for="intro" />
                </Card>
            )}
            {(course.intros && course.intros.length > 0) && <div>
                <h2>Case intro</h2>
                <LMSChapter
                    isEditable={true}
                    value={course.intros}
                    onSave={(value, callback) => this._onSaveLMS('intros', value, callback)} />
            </div>}

            <Widgets.CalendarEvents course={course} isAdmin={true} />

            <h2>Case setup</h2>

            {(course.roles && course.roles.length > 0) && <div>
                <Card title={`Roles (${course.roles.length})`}>
                    <div>
                        <p className="help-block">
                            Roles are assigned to case participants to receive specific information/assignments.<br />
                        </p>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.roles.map(role => {
                                    return <tr key={role.id}>
                                        <td>
                                            <strong>{role.name}</strong>
                                            {role.isVisibleByParticipant ? <span className="badge info" style={{ marginLeft: 10 }}>Visible</span> : <span className="badge" style={{ marginLeft: 10 }}>IN-VISIBLE</span>}
                                            {(role.isVisibleByParticipant && role.isSelectableByParticipant) ? <span className="badge info" style={{ marginLeft: 10 }}>Selectable</span> : <span className="badge" style={{ marginLeft: 10 }}>NOT selectable</span>}
                                            <br />
                                            For authors: {role.details}
                                        </td>
                                        <td width={20}>
                                            <a onClick={() => this.props.actions.local.modal('role', role.id)}><i className="fa fa-pencil"></i></a>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <p className="help-block">
                            <strong>Visible/Invisible:</strong> Roles can be invisible to the participants but visible to the instructor<br />
                            <strong>Selectable/Not-selectable:</strong> If you enabled the option for participants to choose their own roles in settings, some roles can be selectable or not during the process.
                        </p>
                    </div>
                </Card>
            </div>}

            {(course.characters && course.characters.length > 0) && <div>
                <Card title={`Characters (${course.characters.length})`}>
                    <div>
                        <p className="help-block">Characters are used to send emails/chats and are created by the author of the case.</p>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.characters.map(character => {
                                    var country = countries.filter(country => country.value == character.country)
                                    country = (country.length > 0) ? country[0].label : "Not assigned"

                                    var organization = course.organizations.filter(organization => organization.staticId === character.organizationStaticId)
                                    organization = (organization.length > 0) ? organization[0].name : null

                                    var position = ""
                                    if (character.title) {
                                        position = character.title
                                        if (organization) {
                                            position += " at " + organization
                                        }
                                    } else {
                                        position = organization || "Not assigned"
                                    }

                                    return <tr key={character.id}>
                                        <td width={40}>
                                            <Avatar user={character} size={40} />
                                        </td>
                                        <td>
                                            <strong>{character.first} {character.last}</strong><br />
                                            {character.description}<br />
                                            <i className="fa fa-map-marker fa-fw" /> {country}
                                            {`  |  `}
                                            <i className="fa fa-building fa-fw" /> {position}
                                            {`  |  `}
                                            <i className={(character.gender == "") ? 'fa fa-circle-o fa-fw' : (character.gender == 'male') ? 'fa fa-male fa-fw' : 'fa fa-female fa-fw'} />
                                            {(character.age) ? character.age + " years old" : "Age unknown"}
                                        </td>
                                        <td width={20}>
                                            <a onClick={() => this.props.actions.local.modal('character', character.id)}><i className="fa fa-pencil"></i></a>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>}

            {(course.values && course.values.length > 0) && <div>
                <Card title={`Values (${course.values.length})`}>
                    <div>
                        <p className="help-block">
                            Roles are assigned to case participants to receive specific information/assignments.
                        </p>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th width={120}>Base value</th>
                                    <th width={50}>Units</th>
                                </tr>
                            </thead>
                            <tbody>
                                {course.values.map(value => {
                                    return <tr key={value.id}>
                                        <td>
                                            <strong>{value.name}</strong><br />
                                            {value.description}
                                        </td>
                                        <td>
                                            {value.base}
                                        </td>
                                        <td>
                                            {value.unit}
                                        </td>
                                        <td width={20}>
                                            <a onClick={() => this.props.actions.local.modal('value', value.id)}><i className="fa fa-pencil"></i></a>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>}

            {(course.scoringTags && course.scoringTags.length > 0) && <div>
                <Card title={`Tags (${course.scoringTags.length})`}>
                    <div>
                        <p className="help-block">Tags are scoring competencies.</p>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.scoringTags.map(tag => {
                                    return <tr key={tag.id}>
                                        <td>
                                            <strong>{tag.name}</strong><br />
                                            {tag.description}
                                        </td>
                                        <td width={20}>
                                            <a onClick={() => this.props.actions.local.modal('tag', tag.id)}><i className="fa fa-pencil"></i></a>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>}
        </div>
    }

    _onSaveLMS(name, value, callback) {
        let course = this.props.state.course.model
        course[name] = value
        this.props.actions.course.update(course, () => {
            if (callback) {
                callback()
            }
        })
    }
}

export default ReduxBinder(CourseConfigurationOverview, {
    state: ['course', 'user', 'local']
})