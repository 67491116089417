import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Button } from 'alias-components'
import moment from 'moment-timezone'
import ReduxBinder from "alias-store/ReduxBinder"


class ModuleTabSetup extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)


        this.state = {
            module: this._findModule(props),
            moduleId: props.match.params.moduleId
        }
    }

    _findModule(props) {
        const { moduleId } = props.match.params
        const course = props.state.course.model
        let module = null
        const moduleList = course.modules.filter(m => m.id === moduleId)
        if (moduleList.length > 0) {
            module = moduleList[0]
        }
        return module
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.moduleId !== this.state.moduleId) {
            this.setState({
                module: newProps.module
            })
        }
    }

    render() {
        const course = this.props.state.course.model
        const { module } = this.state
        if (!module) {
            return <div>Module not found</div>
        }
        return (
            <div>
                <form onSubmit={this._saveModule.bind(this)} className="row">
                    <div className="col-xs-12 col-md-6">
                        <Card title="Basic">
                            <div className="form-horizontal">
                                <Input type="text"
                                    label="Name"
                                    name="name"
                                    placeholder="Give it a name..."
                                    value={module.name}
                                    onChange={this._onChange.bind(this)} />
                                <Input type="textarea"
                                    label="Description"
                                    name="description"
                                    description="Will show up in the calendar under the module"
                                    placeholder="What is it about?..."
                                    value={module.description}
                                    onChange={this._onChange.bind(this)} />

                                <Input type="bool"
                                    label="Is shown to participants?"
                                    description="The module will be hidden from participants but the activities are active."
                                    name="isShownToParticipants"
                                    value={module.isShownToParticipants}
                                    onChange={this._onChange.bind(this)} />

                                <Input type="bool"
                                    label="Is draft"
                                    description="None of the activities will be visible by the participants"
                                    name="isDraft"
                                    value={module.isDraft}
                                    onChange={this._onChange.bind(this)} />

                                <Button text="Save"
                                    btnClass="btn btn-secondary"
                                    isLoading={this.props.state.module.isSaving} />
                            </div>
                        </Card>
                    </div>
                    <div className="col-xs-12 col-md-6">

                        <Card title="Start time">
                            <div>
                                <Input type="dateTime"
                                    label="Module start date/time"
                                    value={moment(course.start).tz(course.timezone).add(module.offset, 'm').format()}
                                    timezone={course.timezone}
                                    disabled={false}
                                    onChange={(name, value) => {
                                        let courseStart = moment(course.start).tz(course.timezone)
                                        let moduleStart = moment(value).tz(course.timezone)
                                        let difference = moduleStart.diff(courseStart, 'minutes')
                                        this._onChange('offset', difference > 0 ? difference : 0)
                                    }} />
                                <br />
                                <Button text="Save"
                                    btnClass="btn btn-secondary"
                                    isLoading={this.props.state.module.isSaving} />
                            </div>
                        </Card>


                    </div>
                </form>

                <hr />
                <Card title="Danger zone">
                    <p className="help-block"><i className="fa fa-info" /> You can delete the whole module here. Please note, that this cannot be undone and will also remove all the activities and documents in the module.</p>
                    <br />
                    <a onClick={this._removeModule.bind(this)} className="btn btn-danger">Delete module</a>
                </Card>
            </div>
        )
    }


    _onChange(name, value) {
        var module = this.state.module
        module[name] = value
        this.setState({
            module: module
        })
    }

    _saveModule(e) {
        e.preventDefault()
        // var module = this.state.module;
        this.props.actions.module.update(this.state.module, function () { })
    }

    _removeModule(e) {
        e.preventDefault()
        const course = this.props.state.course.model
        if (confirm(`Are you sure you want to delete this module?`)) {
            this.props.history.push(`/course/configuration/${course.id}`)
            this.props.actions.module.remove(this.state.module, function () { })
        }
    }
}

export default ReduxBinder(ModuleTabSetup, {
    state: ['course', 'module']
})