import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'
import countries from 'alias-data/countries'

export default class CardCharacter extends Component {

    static propTypes = {
        character: PropTypes.object.isRequired,
        organizations: PropTypes.array.isRequired,
        edit: PropTypes.func,
        children: PropTypes.any
    }

    render() {
        const { props } = this
        const character = props.character
        var country = countries.filter(function (country) {
            return country.value == character.country
        })
        country = (country.length > 0) ? country[0].label : "Not assigned"

        var organization = props.organizations.filter(function (organization) {
            return organization.staticId === character.organizationStaticId
        })
        organization = (organization.length > 0) ? organization[0].name : null

        var position = "Not assigned"
        if (character.title) {
            position = character.title
            // if (organization) {
            //     position += " at " + organization
            // }
        } else {
            // position = organization || "Not assigned"
        }
        // <br />
        // <i className="fa fa-building fa-fw" />
        // { position }

        return <Card>
            <div className="card-course">
                <div className="left">
                    <Avatar user={character} size={120} />
                </div>
                <div className="right">
                    <h3>{character.first} {character.last}</h3>
                    {character.description ? <p className="description">{character.description}</p> : <p className="help-block"><em>No description</em></p>}
                    <p className="help-block">
                        <i className="fa fa-map-marker fa-fw" />
                        {country}

                        <br />
                        <i className={(character.gender == "") ? 'fa fa-circle-o fa-fw' : (character.gender == 'male') ? 'fa fa-male fa-fw' : 'fa fa-female fa-fw'} />
                        {(character.age) ? character.age + " years old" : "Age unknown"}
                    </p>
                    {props.children}
                    {props.edit && <a onClick={() => props.edit(character)} className="btn btn-secondary">Edit</a>}
                </div>
            </div>
        </Card>

    }
}