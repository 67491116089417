import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'alias-components'
import { Bar, Bubble } from 'react-chartjs-2'
import html2canvas from 'html2canvas'
import scripts from 'alias-tools/scripts'

// http://recharts.org/en-US/api/Bar

/*

https://www.chartjs.org/docs/latest/axes/?h=y%20axis
Chart.scaleService.updateScaleDefaults('linear', {
    ticks: {
        min: 0
    }
});
https://www.chartjs.org/docs/latest/getting-started/usage.html
{
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
*/

export default class WidgetFormAnswersList extends Component {
    static propTypes = {
        course: PropTypes.object,
        form: PropTypes.object,
        participants: PropTypes.array,
        logs: PropTypes.array,
        showAllAnswers: PropTypes.bool
    }

    static defaultProps = {
        showAllAnswers: false,
        participants: []
    }
    render() {
        const { props } = this
        // debugger
        let participantIdList = props.participants.map(p => p.id)
        const logs = props.logs ? props.logs : props.course.activityLogs.filter(l => participantIdList.indexOf(l.participantId) !== -1 && l.formStaticId === props.form.staticId)


        return <div>
            {props.form.questions.map((question, i) => {
                return <QuestionDataView key={question.id + i}
                    course={props.course}
                    question={question}
                    formLogs={logs}
                    index={i + 1}
                    showAllAnswers={props.showAllAnswers}
                    screenShot={this._screenShot.bind(this)} />
            })}
            <a className="hidden" ref="downloadLink"></a>
        </div>
    }
    _screenShot(element, name, e) {
        e.preventDefault()
        if (!element) { return }
        html2canvas(element).then((canvas) => {
            // document.body.appendChild(canvas)
            const link = this.refs.downloadLink
            link.setAttribute('download', name + '.png')
            link.setAttribute('href', canvas.toDataURL())
            link.setAttribute('target', '_blank')
            link.click()
        })
    }
}


class QuestionDataView extends Component {
    static propTypes = {
        course: PropTypes.object,
        question: PropTypes.object,
        formLogs: PropTypes.array,
        showAllAnswers: PropTypes.bool,
        screenShot: PropTypes.func
    }
    static defaultProps = {
        screenShot: () => { }
    }
    constructor(props) {
        super(props)

        this.state = {
            showAnswers: props.showAllAnswers
        }
    }

    render() {
        const { showAnswers } = this.state
        const { props } = this
        const { question, course, formLogs } = props
        const hasMultipleAnswers = formLogs.length > 1
        let results = null

        let answers = []
        formLogs.filter(log => {
            log.data.answers.map(answer => {
                if (answer.id === question.id) {
                    answers.push({
                        participantId: log.participantId,
                        answer: answer.answer
                    })
                }
            })
        })

        let answerDisplay = null
        switch (question.type) {
            case 'text': {
                answerDisplay = <table className="table table-hover table-stripped">
                    <tbody>
                        {answers.length === 0 && <tr className="info">
                            <td colSpan={2}>No answers</td>
                        </tr>}
                        {answers.map((answer, i) => {
                            let participant = course.byId.participants[answer.participantId]
                            return <tr key={"answer" + i}>
                                <td width={60}><Avatar user={participant} tooltip={true} /></td>
                                <td>{answer.answer || "No answer"}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                break
            }
            case 'multiple-choice': {
                let labels = []
                let data = []
                let chartData = {}

                if (hasMultipleAnswers) {

                    labels = question.options.map((option, i) => `A${i + 1}`)
                    data = question.options.map(() => 0)
                    question.options.map((option, i) => {
                        answers.map(answer => {
                            if (answer.answer === option.id) {
                                data[i]++
                            }
                        })
                    })

                    chartData = {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Number of participants',
                                backgroundColor: '#107998',
                                data: data
                            }
                        ]
                    }
                }

                answerDisplay = <div>
                    {hasMultipleAnswers && <div>
                        <div ref="mChart">
                            <Bar data={chartData} options={{
                                legend: { display: false },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }} />
                        </div>
                        <a onClick={(e) => this.props.screenShot(this.refs.mChart, 'chart', e)} className="pull-right">Download chart</a>
                        <div className="clear"></div>
                    </div>}
                    {question.options.map((option, i) => {

                        let badgeName = null
                        if (option.badgeStaticId && course.byStaticId.badges[option.badgeStaticId]) {
                            badgeName = course.byStaticId.badges[option.badgeStaticId].name
                        }

                        let variableName = null
                        if (question.variableStaticId && course.byStaticId.variables[question.variableStaticId]) {
                            variableName = course.byStaticId.variables[question.variableStaticId].name
                        }

                        let valuesImpact = null
                        if (option.values && option.values.length > 0) {
                            option.values.map(val => {
                                let value = course.byStaticId.values[val.valueStaticId]
                                if (value) {
                                    if (!valuesImpact) { valuesImpact = [] }
                                    valuesImpact.push(`${value.name} => ${val.amount >= 0 ? `+${val.amount}` : val.amount}`)
                                }
                            })
                            if (valuesImpact) {
                                valuesImpact = valuesImpact.join(' / ')
                            }
                        }
                        let hasAnswers = false
                        return <div key={option.id + question.id + i}>
                            <p style={{ marginBottom: 0 }}><strong>A{i + 1}:</strong> {option.text}</p>
                            <p className="help-block" style={{ marginTop: 0 }}>
                                <strong>Score:</strong> {option.score}
                                {badgeName && <span> - <strong>Badge:</strong> {badgeName}</span>}
                                {variableName && <span> - <strong>Variable:</strong> {variableName}</span>}
                                {variableName && <span> - <strong>Variable impact:</strong> {option.impact}</span>}
                                {valuesImpact && <span> - <strong>Values impact:</strong> {valuesImpact}</span>}
                                {option.scoringTags && option.scoringTags.map(tag => {
                                    if (!tag.scoringTagStaticId || (tag.scoringTagStaticId && !course.byStaticId.scoringTags[tag.scoringTagStaticId])) { return }
                                    return <span key={tag.id}> - <strong>{course.byStaticId.scoringTags[tag.scoringTagStaticId].name}:</strong> {tag.amount}</span>
                                })}
                            </p>
                            {answers.map((answer, ai) => {
                                if (answer.answer !== option.id) { return null }
                                hasAnswers = true
                                return <div style={{ marginRight: '5px', marginBottom: '10px', display: 'inline-block', verticalAlign: 'top' }}
                                    key={`${i}-${ai}-${question.id}-${option.id}-${answer.participantId}`}>
                                    {course.byId.participants[answer.participantId] && <Avatar
                                        user={course.byId.participants[answer.participantId]}
                                        tooltip={true}
                                        size={30} />}
                                </div>
                            })}
                            {!hasAnswers && <p><i>(No one has chosen this answer)</i></p>}
                        </div>
                    })}
                </div>
                break
            }
            case 'range':
            case 'number': {
                let dataPoints = []
                answers.map((answer, i) => {
                    dataPoints.push({ x: i + 1, y: answer.answer, r: 5 })
                })

                let chartData = {
                    datasets: [
                        {
                            label: 'Selected value',
                            data: dataPoints,
                            backgroundColor: "#107998"
                        }]
                }
                answerDisplay = <div>
                    {hasMultipleAnswers && <div>
                        <div ref="mRange">
                            <Bubble data={chartData} options={{ legend: { display: false } }} />
                        </div>
                        <a onClick={(e) => this.props.screenShot(this.refs.mRange, 'chart', e)} className="pull-right">Download chart</a>
                        <div className="clear"></div>
                    </div>}
                    <br />
                    <table className="table table-stripped table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Answers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {answers.map((answer, i) => {
                                let participant = course.byId.participants[answer.participantId]
                                return <tr key={i}>
                                    <td width={40}>{participant && <Avatar size={30} user={participant} />}</td>
                                    <td>{participant && <span>{participant.displayName.withTeam}</span>}</td>
                                    <td>{answer.answer}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                break
            }

            case 'doc': {
                let docs = []

                answers.map(answer => {
                    if (typeof answer.answer === 'string') {
                        // Old style
                        let doc = course.docs.filter(d => d.id === answer.answer)
                        docs.push({
                            id: answer.answer,
                            doc: doc.length > 0 ? { ...doc[0], type: 'old' } : null,
                            participant: course.byId.participants[answer.participantId]
                        })
                    } else {
                        // New style
                        docs.push({
                            id: scripts.randomString(),
                            doc: answer.answer,
                            participant: course.byId.participants[answer.participantId]
                        })
                    }
                })

                answerDisplay = <table className="table table-stripped table-hover">
                    <tbody>
                        {docs.map(doc => <tr key={doc.id}>
                            <td width={40}>{doc.participant && <Avatar size={30} user={doc.participant} />}</td>
                            <td>{doc.participant && <span>{doc.participant.displayName.withTeam}</span>}</td>
                            <td>{doc.doc ? <a href={doc.doc.type ? doc.doc.path : `/api/files?url=${doc.doc.path}`} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />{doc.doc.name}</a> : 'Document not found'}</td>
                        </tr>)}
                    </tbody>
                </table>
                break
            }
            case 'scoring': {
                answerDisplay = <table className="table table-stripped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Scores</th>
                        </tr>
                    </thead>
                    <tbody>
                        {answers.map((answer, i) => {
                            let participant = course.byId.participants[answer.participantId]
                            return <tr key={i}>
                                <td width={40}>{participant && <Avatar size={30} user={participant} />}</td>
                                <td>{participant && <span>{participant.displayName.withTeam}</span>}</td>
                                <td>{answer.answer}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }

        let variableName = null
        if (question.variableStaticId && course.byStaticId.variables[question.variableStaticId]) {
            variableName = course.byStaticId.variables[question.variableStaticId].name
        }
        let scoringTags = null
        if (question.type !== 'multiple-choice' && question.scoringTags) {
            scoringTags = question.scoringTags.map(tag => {
                tag.name = course.byStaticId.scoringTags[tag.scoringTagStaticId] ? course.byStaticId.scoringTags[tag.scoringTagStaticId].name : 'Not found'
                return tag
            })
        }

        return <div style={{ padding: '5px 10px', borderLeft: '3px solid #cacaca', marginBottom: '25px' }}>
            <h4><strong>Q{props.index}: {question.question}</strong> <a onClick={() => this.setState({ showAnswers: !showAnswers })}> ({showAnswers ? 'Hide' : 'Show'})</a></h4>
            {showAnswers && <div>
                {answerDisplay}
                {results}
                <h4>Attributes</h4>
                <div style={{ float: 'left', display: 'inline', marginRight: 15 }}><strong>Weight:</strong> {question.weight}</div>
                {["multiple-choice"].indexOf(question.type) === -1 && <div style={{ float: 'left', display: 'inline', marginRight: 15 }}><strong>Score:</strong> {question.score}pt{(question.score && question.score > 1) ? 's' : ''}</div>}
                {variableName && <div style={{ float: 'left', display: 'inline', marginRight: 15 }}><strong>Variable impacted:</strong> {variableName}</div>}
                {scoringTags && scoringTags.map(tag => {
                    return <div key={tag.id} style={{ float: 'left', display: 'inline', marginRight: 15 }}><strong>{tag.name}:</strong> {tag.amount}pt{(tag.amount && tag.amount > 1) ? 's' : ''}</div>
                })}
                <div className="clear"></div>

                {question.feedback && <div>
                    {!results && <br />}
                    <h4>Feedback</h4>
                    {question.feedback}
                </div>}
            </div>}
        </div>
    }
}