import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Search } from 'alias-components'
import Fuse from 'fuse.js'


class ControlTabCourses extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            showCount: 30
        }
    }

    render() {
        const { search, showCount } = this.state

        const { control } = this.props.state
        let { courses } = control
        const totalNumberOfCourses = courses.length
        if (search) {
            let options = {
                keys: [{
                    name: 'name',
                    weight: .6
                }, {
                    name: 'description',
                    weight: 0.4
                }],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.4
            }
            let fuse = new Fuse(courses, options)
            courses = fuse.search(search)
        }
        courses = courses.slice(0, showCount)

        return (
            <div className="config-right-wrapper">
                <div className="page-header">
                    <h1 style={{ display: 'inline-block' }}>Courses</h1>
                    <div className="pull-right">
                        <Search search={this.state.search}
                            onChange={(search) => this.setState({ search: search })} />
                    </div>
                </div>

                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map(c => {
                            return <tr key={c.id}>
                                <td>{c.name}</td>
                                <td>{c.description}</td>
                                <td>{!c.isTemplate ? 'Case' : c.isPublic ? 'Marketplace' : 'Template'}</td>
                            </tr>
                        })}

                    </tbody>
                </table>
                {showCount < totalNumberOfCourses && (
                    <div style={{ textAlign: 'center', margin: '20px 0 40px' }}>
                        <a onClick={() => {
                            this.setState({
                                showCount: showCount + 30
                            })
                        }}
                        className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show 30 more</a>
                        &nbsp;of {totalNumberOfCourses - showCount} left
                    </div>
                )}

            </div>
        )
    }
}

export default ReduxBinder(ControlTabCourses, {
    state: ['control']
})