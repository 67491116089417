import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'
import structures from 'alias-store/structures'
import { Input, Card } from 'alias-components'
import Form from '../components/Form'
import Scripts from 'alias-tools/scripts'
import Config from 'alias-config'
import IsValid from 'alias-tools/validation'


class BuilderTabForm extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    _stateSetter(props) {
        const formStaticId = props.match.params.formStaticId
        const questionId = props.match.params.questionId
        const course = props.state.course.model
        const form = {
            ...structures.form,
            ...(formStaticId && course.byStaticId.forms[formStaticId]) ? course.byStaticId.forms[formStaticId] : {}
        }

        return {
            formStaticId: formStaticId,
            form: form,
            questionId: questionId,
            move: {
                position: 'before',
                newIndex: null
            }
        }
    }

    constructor(props) {
        super(props)
        this.state = this._stateSetter(props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.formStaticId !== this.state.formStaticId) {
            this.setState(this._stateSetter(newProps))
        }
        if (newProps.match.params.questionId !== this.state.questionId) {
            this.setState({ questionId: newProps.match.params.questionId })
        }
    }

    render() {
        const { props, state } = this
        const course = props.state.course.model

        if (!state.formStaticId || !course.byStaticId.forms[state.formStaticId]) {
            return this._renderPickAForm()
        }

        let question = null
        if (state.questionId) {
            let potentialQuestion = state.form.questions.filter(q => q.id === state.questionId)
            if (potentialQuestion.length > 0) {
                question = potentialQuestion[0]
            }
        }


        return <div className="builder-form">
            <Helmet title={`${course.name} - ${state.form.name}`} />
            <div className="left">
                <div className="name">
                    <div className="form-wrapper">
                        <Link to={`/course/configuration/${course.id}/comps/forms`} className="btn btn-link"><i className="fa fa-chevron-left" /> Back</Link>

                        <form onSubmit={this._update.bind(this)}>
                            <Input
                                label="Form name"
                                placeholder="Enter form name..."
                                type="text"
                                name="name"
                                onChange={this._onChange.bind(this)}
                                value={state.form.name} />
                            <button type="submit" className="btn btn-secondary">Save</button>
                        </form>
                        <a onClick={this._cloneForm.bind(this)} className="btn btn-link" style={{ marginRight: 5 }}>Clone form</a>
                        <a onClick={this._deleteForm.bind(this)} className="btn btn-link red">Delete form</a>
                        <hr />
                        {state.form.questions.map(question => {
                            if (!question) { return null }
                            let typeDisplay = null
                            const pqs = Config.app.questionTypes.filter(q => q.value === question.type)
                            if (pqs.length > 0) {
                                typeDisplay = pqs[0].label
                            }

                            return <div className={`question ${state.questionId === question.id && 'active'}`} key={question.id}>
                                <h2>{question.question || <small>Please enter a question</small>}</h2>
                                {typeDisplay && <span className="badge default">{typeDisplay}</span>}
                                {(question.badgeStaticId && course.byStaticId.badges[question.badgeStaticId]) && <span className="badge info" style={{ marginLeft: 5 }}>{course.byStaticId.badges[question.badgeStaticId].name}</span>}
                                <Link to={`/builder/${course.id}/form/${state.form.staticId}/${question.id}`} className="overlay"></Link>
                            </div>
                        })}
                        <p className="text-right">
                            <br />
                            <a onClick={this._addQuestion.bind(this)} className="btn btn-secondary"><i className="fa fa-plus" /> New question</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="question-wrapper">
                    {!question && <div className="alert alert-info">Please select a question from the left.</div>}
                    {question && <form onSubmit={this._update.bind(this)}>
                        <Form.Question
                            question={question}
                            type={'text'}
                            onChange={this._onChangeQuestion.bind(this)}
                            onRemove={this._onRemove.bind(this)}
                            variables={course.variables}
                            course={course} />
                        <br />
                        {state.form.questions.length > 1 && <Card title="Move">
                            <div className="row">
                                <div className="col-md-3">
                                    <Input type="select"
                                        label="Before/After"
                                        value={state.move.position}
                                        onChange={(namve, value) => {
                                            this.setState({
                                                move: {
                                                    ...state.move,
                                                    position: value
                                                }
                                            })
                                        }}
                                        options={[
                                            {
                                                label: "Before",
                                                value: 'before'
                                            },
                                            {
                                                label: "After",
                                                value: 'after'
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-md-9">
                                    <Input type="select"
                                        label="Question"
                                        value={state.move.newIndex}
                                        onChange={(name, value) => {
                                            this.setState({
                                                move: {
                                                    ...state.move,
                                                    newIndex: value
                                                }
                                            })
                                        }}
                                        options={state.form.questions.map((q, i) => ({
                                            label: q.question,
                                            value: i,
                                            disabled: q.id === state.questionId
                                        }))}
                                    />
                                </div>
                            </div>
                        </Card>}
                        <button type="submit" className="btn btn-primary btn-lg">Save form</button>
                    </form>}
                </div>
            </div>
        </div>
    }

    _onChange(name, value) {
        let form = this.state.form
        form[name] = value
        this.setState({ form: form })
    }

    _onChangeQuestion(name, value) {
        let { form, questionId } = this.state
        form.questions = form.questions.map(q => {
            if (q.id !== questionId) { return q }
            q[name] = value
            return q
        })
        this.setState({ form: form })
    }

    _onRemove(questionId) {
        let { form } = this.state
        form.questions = form.questions.filter(question => question.id !== questionId)
        this.setState({ form: form })
    }

    _addQuestion(e) {
        e.preventDefault()
        const course = this.props.state.course.model
        let { form } = this.state
        const newId = Scripts.randomString()

        let newInput = {
            ...structures.formQuestion,
            options: [],
            type: null
        }
        newInput.id = newId
        form.questions.push(newInput)
        this.setState({ form: form })
        this.props.history.push(`/builder/${course.id}/form/${form.staticId}/${newId}`)
    }

    _renderPickAForm() {
        const { props } = this
        const course = props.state.course.model
        return <div>
            <ul>
                {course.forms.map(f => {
                    return <li key={f.id}><Link to={`/builder/${course.id}/form/${f.staticId}`}>{f.name}</Link></li>
                })}
            </ul>
        </div>
    }

    _isFormValid() {
        var form = this.state.form
        var formIsValid = true
        var errorFields = []
        // Name
        var errors = IsValid(form.name, {
            required: null,
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        let { form, move, questionId } = this.state
        if (move.newIndex !== null) {
            form = this._move(form, questionId, move.position, move.newIndex)
            this.setState({ move: { position: 'before', newIndex: null }, form: form })
        }
        this.props.actions.form.update(form, () => { })
    }

    _move(form, toMoveQuestionId, position, newIndex) {
        let questionIndex = null
        form.questions.map((q, i) => {
            if (q.id === toMoveQuestionId) {
                questionIndex = i
            }
        })
        if (position === 'after') {
            newIndex += 1
        }
        if (questionIndex !== null) {
            form.questions = this._arrayMove(form.questions, questionIndex, newIndex)
        }
        return form
    }

    _arrayMove(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
            var k = newIndex - arr.length + 1
            while (k--) {
                arr.push(undefined)
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
        return arr // for testing purposes
    }

    _cloneForm() {
        let form = { ...this.state.form }
        delete form.id
        delete form.staticId
        form.name = form.name + " - Clone"
        this.props.actions.form.create(form, (newForm) => {
            this.props.history.push(`/builder/${newForm.courseId}/form/${newForm.staticId}`)
        })
    }

    _deleteForm() {
        const form = this.state.form
        this.props.actions.form.remove(form, () => {
            this.props.history.goBack()
        })
    }

}


export default ReduxBinder(BuilderTabForm, {
    state: ['course', 'form']
})