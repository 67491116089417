
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Thermometer extends Component {
    static propTypes = {
        value: PropTypes.number,
        max: PropTypes.number,
        format: PropTypes.string,
        height: PropTypes.number,
        ratio: PropTypes.number,
        showLabel: PropTypes.bool,
        backgroundColor: PropTypes.string
    }
    static defaultProps = {
        value: 44,
        max: 100,
        format: '',
        height: 200,
        ratio: 20 / 300,
        showLabel: true,
        backgroundColor: "#F2F2F2"
    }
    _generateStyles() {
        const { props } = this
        let styles = {}
        let width = props.height * props.ratio

        styles.wrapper = {
            height: props.height + 30,
            display: 'block'
        }

        styles.glass = { width: props.height * props.ratio, height: width }


        return styles
    }
    render() {
        const { props } = this
        const styles = this._generateStyles()
        console.log(styles)
        return <div className="thermometer" style={styles.wrapper}>
            <span className="glass" style={styles.glass}>
                <strong className="total" style={{ bottom: `${props.value}%` }}>{props.value}{props.format}</strong>
                <span className="amount" style={{ height: `${props.value}%` }}></span>
            </span>
            <div className="bulb">
                <span className="red-circle"></span>
                <span className="filler">
                    <span></span>
                </span>
            </div>
        </div>
    }
}


export default Thermometer