/* eslint-disable no-undef */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Input, FileUploaderWithProgress, FileUpload, FileIcons } from 'alias-components'
import moment from 'moment-timezone'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'


export default class ChatItem extends Component {
    static propTypes = {
        message: PropTypes.object.isRequired,
        participant: PropTypes.object,
        isAdmin: PropTypes.bool,
        thread: PropTypes.object,
        questionAnswered: PropTypes.func,
        course: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            answerDocFile: false,
            answer: ''
        }
    }


    render() {
        const { message, participant, isAdmin, thread, questionAnswered } = this.props
        let posters = thread.participantsInfo.filter(p => p && p.id === message.participantId)
        let poster = null
        if (posters.length > 0) {
            poster = posters[0]
        }
        let fromUser = message.participantId === participant.id

        let showMultipleChoice = message.type === 'answer' && ['multiple-choice'].indexOf(message.question.type) !== -1 && !message.answer
        let showFileUpload = message.type === 'answer' && ['doc'].indexOf(message.question.type) !== -1 && !message.answer
        if (message.answer && message.question.type === 'multiple-choice') {
            message.message = ''
            message.question.options.map(option => {
                if (option.id === message.answer.answer) {
                    message.message = option.text
                    showMultipleChoice = false
                }
            })
        }
        if (message.answer && message.question.type === 'doc') {
            message.message = ''
            showFileUpload = false
        }

        let showTimeStamp = true
        if (message.type === 'answer' && !message.answer) {
            showTimeStamp = false
        }
        if (message.message === '...') {
            showTimeStamp = false
        }

        return <ReactCSSTransitionGroup
            transitionName="chat-message"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}>
            <div className={`message ${(fromUser) ? 'alt' : ''} ${message.type === 'excuse' ? '' : ''} ${(showMultipleChoice || showFileUpload) ? 'multiple' : ''}`}>
                {poster && <Avatar user={poster.userz} size={30} tooltip={true} />}
                <div className="text">
                    {showMultipleChoice && this._renderMultipleChoice(message, participant, isAdmin, questionAnswered)}
                    {showFileUpload && this._renderFileUpload(message, participant, isAdmin, questionAnswered)}
                    {(!showMultipleChoice && !showFileUpload && message.message === '...') ? <img src={require('alias-assets/chat-loader.gif')} alt="Loader" style={{ height: 10 }} /> : message.message}
                    {(message.docs && message.docs.length > 0) && <ul>
                        {message.docs.map(doc => {
                            if (!doc) { return null }
                            return <li key={doc.id}>
                                <a href={`/api/files?url=${doc.path}`} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />
                                    {doc.name}
                                </a>
                            </li>
                        })}
                    </ul>}
                </div>
                {showTimeStamp && <span className="timestamp">{moment(message.created).fromNow()}</span>}
            </div>
        </ReactCSSTransitionGroup>
    }

    _renderMultipleChoice(message, participant, isAdmin, questionAnswered) {
        return <span>
            {['multiple-choice'].indexOf(message.question.type) !== -1 && (<span>
                {!message.answer && <span className="help-block">Please choose an answer:</span>}
                <Input type={message.question.type}
                    options={message.question.options.map(option => {
                        return {
                            text: option.text,
                            id: option.id
                        }
                    })}
                    value={message.answer ? message.answer.answer : ''}
                    disabled={!!message.answer || isAdmin}
                    placeholder='Your answer here...'
                    onChange={(name, value) => questionAnswered(message, value, participant.id)} />
            </span>)}
            {(['multiple-choice'].indexOf(message.question.type) === -1 && message.answer) && <span>{message.answer.answer || '...'}</span>}
        </span>
    }

    _renderFileUpload(message, participant, isAdmin) {
        const { props } = this
        if (isAdmin) {
            return <div className="help-block"><i className="fa fa-arrow-up margin-right-5"></i>Awaiting participant to upload a document</div>
        }
        return <div>

            <FileUpload courseId={props.course.id} onComplete={(doc) => props.questionAnswered(message, doc, participant.id)} />
            {false && <div>
                <h3>OLD</h3>
                {this.state.answerDocFile && <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                    <li style={{ paddingBottom: '10px', color: '#333' }}>
                        <i className="fa fa-file" /> {this.state.answerDocFile.name}
                        <FileUploaderWithProgress doc={this.state.answerDocFile} actions={this.props.actions} onComplete={this._onAnswerFileComplete.bind(this)} />
                    </li>
                </ul>}
                {!this.state.answerDocFile && <a onClick={() => !this.props.isAdmin ? this.refs.files.click() : null} disabled={this.props.isAdmin} className="btn btn-secondary"><i className="fa fa-paperclip" /> Attach a file</a>}
                <input onChange={this._setAnswerDocFile.bind(this)} ref="files" type="file" />
                {/*this.state.docsBeingUploaded.length > 0 && <a className="btn btn-primary">Submit file{this.state.docsBeingUploaded.length > 2 && 's'}</a>*/}
                {/*this.state.docsBeingUploaded.length > 0 && <a onClick={this._deleteDoc.bind(this, this.state.docsBeingUploaded[0])} className="btn btn-danger" style={{ marginLeft: 10 }}>Remove file</a>*/}
            </div>}
        </div>
    }


    _setAnswerDocFile(e) {
        const { course, participant } = this.props
        let fileList = []
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                // Check if doc name already exists
                // let existingDocNames = course.docs.filter(d => d.name === e.target.files[k].name)
                // if (existingDocNames.length > 0) {
                //     alert(`The file ${e.target.files[k].name} cannot be uploaded because of the name, please change the name of the file and upload again.`)
                // } else {
                var model = {
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: course.id,
                    file: e.target.files[k],
                    completed: false,
                    data: {
                        participantId: participant.id,
                        activityId: this.props.message.activity.id
                    }
                }
                fileList.push(model)
                // }

            }
        }
        this.setState({
            answerDocFile: fileList[0]
        })
    }
    _onAnswerFileComplete(finishedUploadingDoc) {
        this.setState({
            answerDocFile: {
                ...this.state.answerDocFile,
                completed: true
            },
            answer: finishedUploadingDoc.id
        })
        this.props.questionAnswered(this.props.message, finishedUploadingDoc.id, this.props.participant.id)
    }


}