import Tools from './MessagingTools'
import View from './MessagingView'
import ViewConversationChat from './components/views/MessagingConversationChat'
import ViewConversationEmail from './components/views/MessagingConversationEmail'
import EmailItem from './components/views/EmailItem'
import ChatItem from './components/views/ChatItem'
import Convervation from './components/MessagingConversation'

export default {
    Tools,
    View,
    ViewConversationChat,
    ViewConversationEmail,
    EmailItem,
    ChatItem,
    Convervation
}