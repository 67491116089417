import React from 'react'
import { render } from 'react-dom'
import RouterLayer from './RouteLayer'

console.info(`Frontend version: ${VERSION || 'Not defined'}`) // eslint-disable-line
console.info(`Environment: ${ENV_CONFIG}`) // eslint-disable-line
console.info(`Running debug version: ${DEBUG}`) // eslint-disable-line

const destination = document.getElementById('app')

render(<RouterLayer />, destination)
