import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import structures from 'alias-store/structures'



class BuilderTabStory extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    _stateSetter(props) {
        const formStaticId = props.match.params.formStaticId
        const questionId = props.match.params.questionId
        const course = props.state.course.model
        const form = {
            ...structures.form,
            ...(formStaticId && course.byStaticId.forms[formStaticId]) ? course.byStaticId.forms[formStaticId] : {}
        }

        return {
            formStaticId: formStaticId,
            form: form,
            questionId: questionId,
            move: {
                position: 'before',
                newIndex: null
            }
        }
    }

    constructor(props) {
        super(props)
        this.state = this._stateSetter(props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.formStaticId !== this.state.formStaticId) {
            this.setState(this._stateSetter(newProps))
        }
        if (newProps.match.params.questionId !== this.state.questionId) {
            this.setState({ questionId: newProps.match.params.questionId })
        }
    }

    render() {
        const { props } = this
        const course = props.state.course.model

        return <div className="story">
            <Helmet title={`${course.name} - Story`} />
            <div className="header"></div>
        </div>
    }

}


export default ReduxBinder(BuilderTabStory, {
    state: ['course']
})