import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Card, Form, Avatar } from 'alias-components'
import { Link } from 'react-router-dom'


class ControlTabUser extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    getUser(props) {
        let users = props.state.control.users.filter(u => u.id === props.match.params.id)
        if (users.length === 0) {
            return {
                first: '',
                last: '',
                email: '',
                title: '',
                participants: []
            }
        }
        return users[0]
    }

    constructor(props) {
        super(props)

        this.state = {
            user: this.getUser(props)
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let users = newProps.state.control.users.filter(u => u.id === newProps.match.params.id)
        if (users.length === 0) { this.props.history.push('/control/users') }
        this.setState({ user: users[0] })
    }


    render() {
        const { user } = this.state
        const { courses } = this.props.state.control

        const formStructure = [
            {
                name: "first",
                label: "First name",
                placeholder: "First name...",
                type: "text"
            },
            {
                name: "last",
                label: "Last name",
                placeholder: "Last name...",
                type: "text"
            },
            {
                name: "email",
                label: "Email",
                placeholder: "Email...",
                type: "text"
            },
            {
                name: "title",
                label: "Title",
                placeholder: "Mr,Miss...",
                type: "text"
            },
            {
                name: "registered",
                label: "Is registered?",
                description: "If you toggle to 'NOT' registered, the user can register again in case he forgot his password.",
                type: "bool"
            }
        ]

        let theirCourses = user.participants.map(p => {
            let participantCourses = courses.filter(c => c.id === p.courseId)
            if (participantCourses.length === 0) { return null }
            p.course = participantCourses[0]
            return p
        })

        return (
            <div className="config-right-wrapper">
                <div className="page-header">
                    <h1><Link to="/control/users">Users</Link> <small><i className="fa fa-chevron-right"></i> {user.first} {user.last} {user.email}</small> </h1>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}><Avatar user={user} size={160} /></div>
                <Card title="Basic info">
                    <Form model={user}
                        structures={formStructure}
                        update={this._save.bind(this)} />
                </Card>
                <Card title="Their courses">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th colSpan={2}>Name</th>
                                <th>Position</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {theirCourses.map(p => {
                                return <tr key={p.id}>
                                    <td><Avatar user={p.course} /></td>
                                    <td>
                                        <strong>{p.course.name}</strong> {p.course.isTemplate && <span>(Template)</span>}
                                        <p>{p.course.description}</p>
                                    </td>
                                    <td>{p.isAdmin ? 'Author' : p.isActor ? 'Actor' : 'Participant'}</td>
                                    <td></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Card>
            </div>
        )
    }


    _save() {
        this.props.actions.control.updateUser(this.state.user)
    }
}

export default ReduxBinder(ControlTabUser, {
    state: ['control']
})