import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'
import scripts from 'alias-tools/scripts'

export default class ParticipantCardAuthor extends Component {
    static propTypes = {
        participant: PropTypes.object,
        children: PropTypes.any
    }
    render() {
        const { participant } = this.props;
        const { userz } = participant;

        return (
            <Card>
                <div className="card-course" >
                    <div className="left">
                        <Avatar size={120} user={userz} />
                    </div>
                    <div className="right">
                        <h3>{participant.displayName.short}
                            <br />
                            <small>{userz ? userz.email : ''}</small>
                        </h3>
                        {this.props.children}
                    </div>
                </div>
            </Card>
        )
    }
}