import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { } from 'alias-components'
import { Link } from 'react-router-dom'


class PickTeams extends Component {
    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }
    static defaultProps = {}
    constructor(props) {
        super(props)
    }

    _getParticipant(props) {
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && !participant.isAdmin
        })
        let participant = (props.state.local.game && props.state.local.game.participantId && participants.filter(p => p.id === props.state.local.game.participantId).length > 0) ?
            participants.filter(p => p.id === props.state.local.game.participantId)[0]
            : participants[0]
        return participant
    }
    render() {
        const { props } = this
        const course = props.state.course.model
        const participant = this._getParticipant(props)

        if (course.participantsCanChooseRoles === false) {
            return <div className="container">
                <br />
                <br />
                <p className="alert alert-info">You are not allowed to choose your own roles in this case</p>
                <p className="text-center"><Link to={`/course/my/${course.id}`} className="btn btn-primary">Continue to case</Link></p>
            </div>

        }

        let participantHasReachedMax = false
        if (course.maxRolesPerParticipant && participant.roles.length >= course.maxRolesPerParticipant) {
            participantHasReachedMax = true
        }

        let availableRoles = course.roles.filter(role => {
            if (!role.isSelectableByParticipant) { return false }
            if (!role.isVisibleByParticipant) { return false }
            return participant.roles.indexOf(role.staticId) === -1
        })
        if (participant.teamId) {
            let takenRoles = []
            course.participants
                .map(p => {
                    if (p.teamId !== participant.teamId) { return }
                    takenRoles = [
                        ...takenRoles,
                        ...p.roles
                    ]
                })
            availableRoles = availableRoles.filter(role => takenRoles.indexOf(role.staticId) === -1)
        }

        return <div className="container-fluid">
            <div className="alert alert-warning">
                Pick up to {course.maxRolesPerParticipant} role(s) <i className="fa fa-arrow-down"></i> then click next
                <Link to={`/course/my/${course.id}`} className="btn btn-primary pull-right">Next</Link>
                <div className="clear"></div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <h4>Roles you CAN choose:</h4>
                    {availableRoles.map(role => {
                        return <div className="list-group" key={role.id}>
                            <div className="list-group-item">
                                <h4 className="list-group-item-heading">{role.name}</h4>
                                {role.description && <p className="list-group-item-text">{role.description}</p>}
                                <br />
                                {!participantHasReachedMax && <a onClick={this._addRole.bind(this, role)} className="btn btn-default">Choose</a>}
                            </div>
                        </div>
                    })}
                </div>
                <div className="col-sm-6">
                    <h4>Roles you HAVE chosen:</h4>
                    {participant.roles.length === 0 && <p className="alert alert-info">Please select roles from the left column</p>}
                    {participant.roles.map((roleStaticId, i) => {
                        let role = course.byStaticId.roles[roleStaticId]
                        if (!role) { return null }
                        return <div className="list-group" key={role.id + i}>
                            <div className="list-group-item">
                                <h4 className="list-group-item-heading">{role.name}</h4>
                                {role.description && <p className="list-group-item-text">{role.description}</p>}
                                <br />
                                {role.isSelectableByParticipant && <a onClick={this._removeRole.bind(this, role)} className="btn btn-default">Remove</a>}
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    }

    _addRole(role) {
        let participant = this._getParticipant(this.props)
        participant.roles.push(role.staticId)
        participant.hasPickedRoles = true
        this.props.actions.participant.update(participant)
    }

    _removeRole(role) {
        let participant = this._getParticipant(this.props)
        participant.roles = participant.roles.filter(roleStaticId => roleStaticId !== role.staticId)
        participant.hasPickedRoles = true
        this.props.actions.participant.update(participant)
    }

}




export default ReduxBinder(PickTeams, {
    state: ['course', 'user', 'local', 'participant', 'team']
})
