import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'

export default class LogFormListItem extends Component {
    static propTypes = {
        course: PropTypes.object.isRequired,
        log: PropTypes.object.isRequired
    }
    render() {
        const { log, course } = this.props;
        const form = course.byStaticId.forms[log.formStaticId]
        const activity = course.byId.activities[log.data.activityId]

        return <p>
            <strong>{form.name}{activity ? ` (Activity: ${activity.name})` : ''}</strong><br />FROM: {course.byId.participants[log.fromParticipantId] ? course.byId.participants[log.fromParticipantId].displayName.bigTeamThenName : "Not found"} <i className="fa fa-chevron-right"></i> TO: {course.byId.participants[log.participantId] ? course.byId.participants[log.participantId].displayName.bigTeamThenName : "Not found"}<br />
            <a href={`/form/${course.id}/${form.staticId}/${log.id}`} target="_blank" className="btn btn-secondary">Edit</a>
        </p>
    }
}