import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FileUploaderWithProgress extends Component {

    static propTypes = {
        doc: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
        onComplete: PropTypes.func
    }


    static defaultProps = {
        url: "",
        onComplete: () => console.log("File uploaded successfully") // eslint-disable-line
    }


    constructor(props) {
        super(props)
        this.state = {
            doc: props.doc,
            progress: 0,
            isFinished: false,
            hasError: false
        }
    }

    componentDidMount() {
        const model = this.props.doc

        this.props.actions.doc.upload(model, (progress) => {
            if (!this.state.isFinished) {
                this.setState({ progress: progress.progress, isFinished: (progress.progress === 100) })
            }
        }, (complete) => {
            this.setState({ isFinished: true, progress: 100 })
            this.props.onComplete(complete)
        }, (response) => {
            if (!response) { return }

            if (response.error && response.error.message && response.error.message.indexOf('maxFileSize') !== -1) {
                alert("The file " + this.state.doc.file.name + " is too big!")
            }
            if (response.error && response.error.message) {
                alert(response.error.message)
            }
        })
    }

    render() {
        var loadingText = this.state.progress + "%"
        if (this.state.isFinished) {
            return <span style={{ marginLeft: 10 }} className="green"><i className="fa fa-check" /> Upload complete</span>
        }
        return <div className="progress" style={{ marginBottom: '0' }}>
            <div className={this.state.isFinished
                ? "progress-bar progress-bar-success"
                : "progress-bar progress-bar-info progress-bar-striped active"} style={{
                    minWidth: '3em',
                    width: this.state.progress + '%'
                }}>
                {loadingText}
            </div>
        </div>
    }
}