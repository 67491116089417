import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Animated extends Component {

    static propTypes = {
        transitionName: PropTypes.object.isRequired,
        transitionEnter: PropTypes.bool,
        transitionLeave: PropTypes.bool,
        transitionAppear: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.any
    }

    constructor(props) {
        super(props)

        this.state = {
            mounted: false
        }
    }

    getDefaultProps() {
        return {
            transitionEnter: true,
            transitionLeave: true,
            transitionAppear: true
        }
    }

    componentDidMount() {
        this.setState({
            mounted: true
        })
    }

    render() {

        let children

        if (!this.props.transitionAppear) {
            children = this.props.children
        } else {
            if (this.state.mounted) {
                children = this.props.children
            }
        }

        var attributes = {}
        attributes.className = this.props.className
        attributes.transitionName = this.props.transitionName
        attributes.transitionEnter = this.props.transitionEnter
        attributes.transitionLeave = this.props.transitionLeave
        attributes.transitionEnterTimeout = 1000
        attributes.transitionLeaveTimeout = 1000

        return (
            <ReactCSSTransitionGroup {...attributes}>
                {children}
            </ReactCSSTransitionGroup>
        )
    }
}