import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading, Card } from 'alias-components'
import Form from './components/Form'
import ReduxBinder from 'alias-store/ReduxBinder'
import queryString from 'query-string'


class PageForm extends Component {

    static propTypes = {
        location: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        params: PropTypes.object,
        match: PropTypes.object,
        // For modal version
        isModal: PropTypes.bool,
        isOpen: PropTypes.bool,
        handleClose: PropTypes.func
    }

    static defaultProps = {
        isModal: false,
        isOpen: false,
        handleClose: () => console.log("No close function") // eslint-disable-line
    }

    constructor(props) {
        super(props)
        // this._loadCourse(props)
        this.state = {
            formStaticId: props.match.params.formStaticId,
            courseId: props.match.params.courseId
        }
    }

    UNSAFE_componentWillMount() {
        this._loadCourse(this.props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.isFetching && this.state.formStaticId && this.state.formStaticId !== newProps.match.params.formStaticId) {
            this.setState({
                courseId: newProps.params.courseId,
                formStaticId: newProps.params.formStaticId
            })
            this._loadCourse(newProps)
        }
    }

    _loadCourse(props) {
        this.isFetching = true
        const courseId = props.match.params.courseId
        // const logId = props.match.params.logId
        const user = props.state.user.model
        let participants = user.participants.filter(p => (p.courseId === courseId))
        // if (logId) {
        //     participants = participants.filter(p => p.isAdmin)
        // } else {
        //     participants = participants.filter(p => !p.isAdmin)
        // }
        if (participants.length > 0) {
            let participant = participants[0]
            props.actions.course.load(courseId, () => {
                this.isFetching = false
            }, true, participant.id)
        }
    }

    render() {
        const { match, state, location } = this.props
        const query = queryString.parse(location.search)
        const { params } = match
        const courseState = state.course
        const user = state.user.model
        const course = courseState.model

        if ((courseState.isFetching && !courseState.isLoaded) || Object.keys(course.byStaticId).length === 0) {
            return <Loading />
        }


        const formStaticId = this.props.match.params.formStaticId
        const form = course.byStaticId.forms[formStaticId]
        const logId = this.props.match.params.logId
        const log = course.activityLogs.filter(log => log.id === logId)[0]
        if (!form) {
            return (
                <div className="course-wrapper">
                    <Card>Form not found.</Card>
                </div>
            )
        }
        let currentParticipant = null
        if (params.fromParticipantId) {
            const currentParticipants = course.participants.filter(part => part.id === params.fromParticipantId)
            if (currentParticipants.length > 0) {
                currentParticipant = currentParticipants[0]
            }
        } else {
            const currentParticipants = course.participants.filter(part => part.userzId === user.id)
            if (currentParticipants.length === 1) {
                currentParticipant = currentParticipants[0]
            }
        }
        let participantTo = null
        if (params.toParticipantId) {
            const toParticipants = course.participants.filter(part => part.id === params.toParticipantId)
            if (toParticipants.length > 0) {
                participantTo = toParticipants[0]
            }
        }

        let activity = query.activityId && course.byId.activities[query.activityId] ? course.byId.activities[query.activityId] : null



        return <div className="course-wrapper" style={{ overflow: 'auto' }}>
            <Helmet title={form.name} />
            <div className="container">
                <h1>{form.name} {activity && <small>{activity.name}</small>}</h1>

                {(participantTo && currentParticipant && currentParticipant.id !== participantTo.id) && <h2>{participantTo.displayName.bigTeamThenName}</h2>}

                <Card>
                    <Form.Display form={form}
                        log={log}
                        userz={user}
                        participantFrom={currentParticipant}
                        participantTo={participantTo}
                        activityId={query.activityId}
                        groupId={query.groupId}
                        course={course}
                        actions={this.props.actions}
                        type="page"
                        callback={() => {

                        }} />
                </Card>
            </div>
        </div>

    }
}

export default ReduxBinder(PageForm, {
    state: ['course', 'activityLog', 'user']
})