import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Search } from 'alias-components'
import Course from './components/Course'
import Helmet from 'react-helmet'
import Scripts from 'alias-tools/scripts'
import ReduxBinder from 'alias-store/ReduxBinder'

class PageInstructorTemplates extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            isNewTemplateModalOpen: false,
            search: ''
        }
    }

    render() {
        const self = this
        const participants = this.props.state.user.model.participants.filter(function (item) {
            return item.course.isTemplate
        })

        let hisTemplates = []
        let otherPeoplesTemplates = []
        let searchResults = []

        if (this.state.search === "") {
            hisTemplates = participants.filter(function (item) {
                return item.isAdmin
            })

            otherPeoplesTemplates = participants.filter(function (item) {
                return !item.isAdmin
            })
        } else {
            searchResults = participants.filter(function (item) {
                return item.course.name.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
                    || item.course.description.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
            })
        }

        return (
            <Layout title="Instructor templates">
                <div className="container course-list">
                    <Helmet title="Instructor templates" />
                    <div className="page-header">
                        <Search search={this.state.search}
                            onChange={(search) => {
                                self.setState({
                                    search: search
                                })
                            }} />

                        <a className="btn btn-secondary pull-right" onClick={() => {
                            self.setState({ isNewTemplateModalOpen: true })
                        }}>+ New template</a>
                        <Course.ModalTemplate
                            isShown={this.state.isNewTemplateModalOpen}
                            close={() => {
                                self.setState({ isNewTemplateModalOpen: false })
                            }}
                            state={this.props.state}
                            actions={this.props.actions} />
                    </div>
                    {this.state.search !== "" && (
                        <div>
                            <p><em>Showing search results for <strong>{this.state.search}</strong></em></p>
                            {searchResults.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No results.</strong>
                                </div>
                            )}
                            {searchResults.map(function (participant, i) {
                                return <Course.Card course={participant.course} isCourse={true} key={i} />
                            })}
                        </div>
                    )}
                    {this.state.search === "" && (
                        <div>
                            {hisTemplates.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No templates yet</strong>
                                    <p>Templates allow you to create courses and re-use them again and again, with different people or different dates.</p>
                                </div>
                            )}
                            {hisTemplates.map(function (relation) {
                                return <Course.Card course={relation.course} isTemplate={true} key={relation.id || Scripts.randomString()} />
                            })}
                            <br />
                            <br />
                            {otherPeoplesTemplates.length > 0 && (
                                <div>
                                    <h1>Other people's templates</h1>
                                    <p>These are templates that other people made and that you can use.</p>
                                    {otherPeoplesTemplates.length === 0 && (
                                        <div className="alert alert-info">
                                            <strong>No templates yet</strong>
                                            <p>Templates allow you to create courses and re-use them again and again, with different people or different dates.</p>
                                        </div>
                                    )}
                                    {otherPeoplesTemplates.map(function (template, i) {
                                        return <Course.Card course={template.course} isMarket={true} key={i} />
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default ReduxBinder(PageInstructorTemplates, {
    state: ['user', 'course']
})