import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import Scoring from '../../Scoring'

export default class DashboardInstructorOverallScore extends Component {
    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props);

    }
    render() {
        const { course } = this.props;
        let data = {
            zero: 0,
            one: 0,
            two: 0,
            three: 0,
            four: 0,
            five: 0
        }

        course.participants.filter(part => !part.isActor && !part.isAdmin).map(participant => {
            let score = participant.score ? participant.score : Scoring.Tools.participant(participant, course.activities, course.activityLogs, course.forms, course.scoringTags);
            if (!score) {
                data.zero++;
                return;
            }
            score = score.score;


            if (score < 0.5) {
                data.zero++;
                return;
            } else if (score >= 0.5 && score < 1.5) {
                data.one++;
                return;
            } else if (score >= 1.5 && score < 2.5) {
                data.two++;
                return;
            } else if (score >= 2.5 && score < 3.5) {
                data.three++;
                return;
            } else if (score >= 3.5 && score < 4.5) {
                data.four++;
                return;
            } else if (score >= 4.5 && score <= 5) {
                data.five++;
            }

        })

        let chartData = {
            labels: ['0', '1', '2', '3', '4', '5'],
            datasets: [
                {
                    label: 'Number of participants',
                    backgroundColor: '#107998',
                    data: [data.zero, data.one, data.two, data.three, data.four, data.five]
                }
            ]
        };

        return <div>
            <Bar data={chartData} options={{
                legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }} />
        </div>
    }
}