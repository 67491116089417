import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Scripts from 'alias-tools/scripts'
import { Input } from 'alias-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'


class ParticipantInviteWidet extends Component {
    static propTypes = {
        course: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }
    static defaultProps = {}
    constructor(props) {
        super(props)
    }
    render() {
        const { props } = this
        const course = props.course

        let inviteLink = `${window.location.origin}/auth/invite/${course.id}/${course.inviteCode}`

        return <div>
            {course.inviteCode && <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Input type="bool"
                            value={course.inviteCode !== ''}
                            onChange={() => {
                                // Create invite code on server
                                if (!course.inviteCode) {
                                    course.inviteCode = Scripts.randomString()
                                    this.props.actions.course.update(course)
                                } else {
                                    course.inviteCode = ''
                                    this.props.actions.course.update(course)
                                }
                            }}
                            label="Turn on invite code"
                            description="Invite code allows you to share a code or a link, which enables anyone with it to sign up to the case." />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <p className="help-block" style={{ marginTop: 5 }}>Need to cancel the previous code? You can simply <a onClick={() => {
                            course.inviteCode = Scripts.randomString()
                            this.props.actions.course.update(course)
                        }}>Generate new code</a></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Input type="text"
                            label="Invite code"
                            description="Share this code to have them join the case after they register/login"
                            value={course.inviteCode}
                            onChange={() => { }}
                            disabled={true} />
                        <CopyToClipboard text={course.inviteCode} onCopy={() => {
                            this.props.actions.notification.add({
                                type: 'success',
                                title: 'Invite code copied to your clipboard'
                            })
                        }}>
                            <button
                                className="btn btn-secondary"
                                style={{ marginTop: 0 }}>
                                Copy invite code
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <Input type="text"
                            label="Invite link"
                            description="Share this link to have them join the case"
                            value={inviteLink}
                            onChange={() => { }}
                            disabled={true} />
                        <CopyToClipboard text={inviteLink}
                            onCopy={() => {
                                this.props.actions.notification.add({
                                    type: 'success',
                                    title: 'Invite link copied to your clipboard'
                                })
                            }}>
                            <button
                                className="btn btn-secondary"
                                style={{ marginTop: 0 }}>
                                Copy invite link
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>

            </div>}
            {!course.inviteCode && <div>
                <Input type="bool"
                    value={course.inviteCode !== ''}
                    onChange={() => {
                        // Create invite code on server
                        if (!course.inviteCode) {
                            course.inviteCode = Scripts.randomString()
                            this.props.actions.course.update(course)
                        } else {
                            course.inviteCode = ''
                            this.props.actions.course.update(course)
                        }
                    }}
                    label="Turn on invite code"
                    description="Invite code allows you to share a code or a link, which enables anyone with it to sign up to the course." />
            </div>}
        </div>
    }
}

export default ReduxBinder(ParticipantInviteWidet, {
    state: ['course']
})