import React, { Component } from 'react'
import PropTypes from 'prop-types'
import tus from 'tus-js-client'
import api, { apiUpload } from 'alias-tools/api'

const styles = {
    input: {
        display: 'none'
    }
}

export default class VideoUpload extends Component {
    static propTypes = {
        onComplete: PropTypes.func,
        courseId: PropTypes.string,
        type: PropTypes.string
    }
    static defaultProps = {
        onComplete: () => {
        },
        courseId: "videos",
        type: ""
    }
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            progress: 0,
            uploadIsComplete: false,
            errors: null,
            url: null,
            status: ''
        }
    }
    render() {
        const { props, state } = this

        if (props.post && props.post.video) {
            return <div></div>
        }

        if (!state.file) {
            return <div style={{
                display: 'inline'
            }}>
                <input type="file" ref="fileIsSelect" style={styles.input}
                    onChange={this._onChangeInput.bind(this)} />
                <a onClick={() => this.refs.fileIsSelect.click()} className="btn btn-link">Upload a video</a>
                {state.errors && <div className="alert alert-error">Something went wrong. Please try again</div>}
            </div>
        }

        return <div>
            <h4>Uploading {state.file.name} to Vimeo<span>({state.file.size}bytes)</span></h4>
            {state.status && <p className="help-block">Status:
                {state.status}
            </p>}
            <div className="progress" style={{
                marginBottom: '0'
            }}>
                <div className={`progress-bar ${state.uploadIsComplete ? 'progress-bar-success' : 'progress-bar-info progress-bar-striped active'}`} style={{
                    minWidth: '3em',
                    width: state.progress + '%'
                }}>
                    {`${state.progress}%`}
                </div>
            </div>
        </div>
    }

    _onChangeInput(e) {
        const file = e.target.files[0]
        if (file.type.indexOf('video') === -1) {
            return alert("Please select a video format (ex: mp4).")
        }
        this.setState({
            file: file,
            errors: null
        })

        //   this._createVideo(file)
        this._uploadToAWS(file)
        return
        // this._uploadTUS(file)
    }

    _uploadToAWS(file) {
        const { props } = this
        this.setState({
            status: 'Uploading...'
        })

        var formData = new FormData()
        formData.append("file", file)

        apiUpload(
            "/video/upload",
            formData,
            (progress) => {
                if (progress.progress === 100) {
                    this.setState({
                        status: "Moving video...one moment please.",
                        progress: progress.progress
                    })
                } else {
                    this.setState({
                        progress: progress.progress
                    })
                }
            },
            {
                courseId: props.courseId,
                fileSize: file.size,
                fileName: file.name,
                description: `Video for course ID: ${props.courseId}`,
                type: props.type || ""
            })
            .then((res) => {
                if (!res.data.success) {
                    return this._setError()
                }
                this.setState({
                    progress: 0,
                    uploadIsComplete: false,
                    file: null,
                    status: "Uploading...",
                    errors: ""
                })
                props.onComplete(res.data.url)
            })
            .catch((err) => {
                console.warn(err)
                this._setError()
            })
    }

    // _uploadTUS(file) {
    //     const { props } = this
    //     var headers = {}
    //     this.setState({ status: 'Uploading...' })
    //     // If authenticated, add access token in request
    //     const token = localAuthentication.isAuthenticated()
    //     if (token) {
    //         headers = {
    //             'Authorization': token.id
    //         }
    //     }
    //     let options = {}
    //     options.courseId = props.courseId
    //     if (file.type) {
    //         options.extension = file.type.split('/')[1]
    //         options.fileName = file.name
    //         options.courseId = props.courseId

    //     }

    //     let url = window.location.origin.indexOf('localhost:3000') !== -1 ? 'http://localhost:5000' : window.location.origin
    //     // let url = ''
    //     url += '/api/video/upload'
    //     if (Object.keys(options).length > 0) {
    //         url += "?"
    //         url += queryString.stringify(options)
    //     }

    //     this.upload = new tus.Upload(file, {
    //         endpoint: url,
    //         retryDelays: [0, 1000, 3000, 5000],
    //         metadata: {
    //             courseId: props.courseId,
    //             filename: file.name
    //         },
    //         headers: headers,
    //         onError: (error) => {
    //             console.warn("Failed because: " + error)
    //             this._setError()
    //         },
    //         onProgress: (bytesUploaded, bytesTotal) => {
    //             let percentage = Math.round(bytesUploaded / bytesTotal * 2 / 3 * 100)
    //             this.setState({ progress: percentage })
    //         },
    //         onSuccess: (res) => {
    //             // console.log("Download %s from %s", this.upload.file.name, this.upload.url)
    //             let videoUrl = this.upload.url
    //             this.setState({
    //                 progress: 67,
    //                 uploadIsComplete: false,
    //                 errors: null,
    //                 status: 'Uploading',
    //                 url: videoUrl
    //             })
    //             // Do request for uploading to Vimeo
    //             this._moveVideoOnServer(videoUrl, file.size, file.name)
    //             // props.onComplete(videoUrl)
    //         }
    //     })

    //     // Start the upload
    //     this.upload.start()
    // }

    //
    // For other methods.
    //

    _createVideo(file) {
        const { props } = this

        api(
            `/video/vimeo/create`,
            'post',
            {
                name: `${file.name}`,
                word: props.courseId,
                "upload": {
                    "size": file.size
                }
            })
            .then((resp) => {
                if (resp.status !== 200) {
                    this._setError("Vimeo issue. Please try again")
                    return
                }
                const response = resp.data
                if (!response.success) {
                    this._setError()
                    return
                }
                this._uploadTUSToVimeo(response, file)
            })
            .catch((response) => {
                console.warn(response)
                this._setError("Vimeo couldn't create a video. Please try again")
            })
    }

    _uploadTUSToVimeo(response, file) {
        let url = response.upload_link
        this.upload = new tus.Upload(file, {
            endpoint: url,
            uploadUrl: url,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {},
            headers: {
                "Upload-Offset": 0,
                "Content-Type": "application/offset+octet-stream",
                "Accept": "application/vnd.vimeo.*+json;version=3.4"
            },
            onError: (error) => {
                console.warn("Failed because: " + error)
                this._setError()
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = Math.round(bytesUploaded / bytesTotal * 2 / 3 * 100)
                this.setState({
                    progress: percentage
                })
            },
            onSuccess: () => {
                // console.log("Download %s from %s", this.upload.file.name, this.upload.url)
                let videoUrl = this.upload.url
                this.setState({
                    progress: 100,
                    uploadIsComplete: false,
                    errors: null,
                    status: 'Uploading',
                    url: videoUrl
                })
                this.props.onComplete(response.videoId)
            }
        })

        // Start the upload
        this.upload.start()
    }

    // _upload(response, file) {
    //     // let formData = new Form()
    //     // form:"<form method="POST" action="https://1512435599.cloud.vimeo.com/upload?ticket_id=209297827&video_file_id=1237323533&signature=5ec27a973ee25e2e5b50b6b115b9e87d&v6=1&redirect_url=https%3A%2F%2Fvimeo.com%2Fupload%2Fapi%3Fvideo_file_id%3D1237323533%26app_id%3D142791%26ticket_id%3D209297827%26signature%3D3f9f5d3ac84c1452005c40e349dccde31efd6f1a" enctype="multipart/form-data">\n<label for="file">File:</label>\n<input type="file" name="file_data" id="file"><br>\n<input type="submit" name="submit" value="Submit">\n</form>"
    //     const { props } = this
    //     let data = new FormData()
    //     data.append("file_data", file)
    //     vimeoUpload(response.upload_link, data, (progress) => {
    //         this.setState({ progress: progress.progress })
    //         if (progress.progress === 100) {
    //             let videoId = response.videoId
    //             this.setState({
    //                 progress: 0,
    //                 uploadIsComplete: false,
    //                 file: null,
    //                 errors: ""
    //             })
    //             props.onComplete(videoId)
    //         }
    //     })
    //         .catch((err) => {
    //             console.warn(err)
    //             alert("Something went wrong.")
    //         })
    // }

    // _moveVideoOnServer(videoUrl, size, name) {
    //     const { props } = this
    //     this.setState({ status: 'Uploading to Vimeo' })
    //     let url = window.location.origin.indexOf('localhost:3000') !== -1 ? 'http://localhost:5000' : window.location.origin
    //     // url += '/api/video/move'
    //     url += '/api/video/vimeo/upload'
    //     let data = {
    //         pathName: videoUrl,
    //         fileSize: size,
    //         fileName: name,
    //         description: `Video uploaded for course ID: ${props.courseId}`
    //     }
    //     api(url, "GET", data)
    //         .then((response) => {
    //             let data = response.data
    //             if (!data.success) {
    //                 return this._setError("Couldn't create video on vimeo, please try again.")
    //             }
    //             let videoId = response.data.videoId
    //             this.setState({
    //                 progress: 0,
    //                 uploadIsComplete: false,
    //                 file: null,
    //                 status: '',
    //                 errors: ""
    //             })
    //             props.onComplete(videoId)
    //         })
    //         .catch((err) => {
    //             this._setError("Couldn't upload video to vimeo. Please try again")
    //         })
    // }

    // _moveVideoOnServer2(videoUrl, size, name) {
    //     const { props } = this
    //     let url = window.location.origin.indexOf('localhost:3000') !== -1 ? 'http://localhost:5000' : window.location.origin
    //     url += '/api/video/move'
    //     let data = {
    //         videoUrl: videoUrl,
    //         size: size,
    //         name: name
    //     }
    //     api(url, "GET", data)
    //         .then((response) => {
    //             let data = response.data
    //             if (!data.success) {
    //                 return this._setError("Couldn't create video on vimeo, please try again.")
    //             }
    //             let videoId = response.data.videoId
    //             this.setState({
    //                 progress: 0,
    //                 uploadIsComplete: false,
    //                 file: null,
    //                 errors: ""
    //             })
    //             props.onComplete(videoId)
    //         })
    //         .catch((err) => {
    //             this._setError("Couldn't upload video to vimeo. Please try again")
    //         })
    // }

    _setError(message) {
        this.setState({
            progress: 0,
            uploadIsComplete: false,
            file: null,
            errors: message || "Something went wront, please try again."
        })
    }

    _startUpload() {
        if (this.upload) {
            this.upload.start()
        }
    }
    _pauseUpload() {
        if (this.upload) {
            this.upload.abort()
        }
    }
}
