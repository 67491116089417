import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class DragChangeNumber extends Component {

    static propTypes = {
        number: PropTypes.number.isRequired,
        onChangeFinished: PropTypes.func,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        suffixPlural: PropTypes.string
    };

    static defaultProps = {
        number: 0,
        onChangeFinished: () => {
            console.log(`No on change finished function`) // eslint-disable-line
        },
        prefix: '+',
        suffix: 'min',
        suffixPlural: 'mins'
    };

    constructor(props) {
        super(props)

        this.state = {
            number: props.number,
            isEditing: false,
            isTrackingMouse: false,
            previousX: 0
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            number: newProps.number
        })
    }

    render() {
        const { isEditing, number, isTrackingMouse, previousX } = this.state
        const { prefix, suffix, suffixPlural } = this.props

        if (!isEditing) {
            return (
                <a style={{ cursor: 'ew-resize' }}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        this.setState({
                            isTrackingMouse: true,
                            previousX: e.clientX
                        })
                    }}
                    onMouseMove={(e) => {
                        e.preventDefault()
                        if (isTrackingMouse) {
                            let difference = Math.floor((e.clientX - previousX))
                            this.setState({
                                number: (number + difference > 0) ? number + difference : 0,
                                previousX: e.clientX
                            })
                        }
                    }}>
                    {prefix}{number}{number > 1 ? suffixPlural : suffix}
                    {isTrackingMouse && <span
                        onMouseUp={this._save.bind(this)}
                        style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000000 }}></span>}
                </a>
            )
        }
        if (isEditing) {
            return (
                <form onSubmit={this._save.bind(this)}>
                    <input
                        type="number"
                        value={number}
                        onChange={(e) => {
                            this.setState({ number: e.target.value })
                        }} />
                    <button type="submit"><i className="fa fa-check" /></button>
                </form>
            )
        }
    }

    _save(e) {
        if (e) e.preventDefault()
        this.setState({
            isTrackingMouse: false,
            isEditing: false
        })
        this.props.onChangeFinished(this.state.number)
    }
}