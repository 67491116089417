import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading } from 'alias-components'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link, Route, Switch } from 'react-router-dom'
import CourseMenu from './components/CourseMenu'
import Course from './components/Course'
import CourseConfigurationTabs from './courseConfigurationTabs'
import Common from './common'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'


class PageCourseConfiguration extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId

        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        if (participants.length === 0) {
            console.warn("No participants found for this user in the course") // eslint-disable-line
        } else {
            // Connecting server live updates
            this._liveUpdateListen(courseId)
        }
        this.state = {
            courseId: courseId
        }
    }

    UNSAFE_componentWillMount() {
        const course = this.props.state.user.model.participants.filter((item) => {
            return item.course && item.course.id === this.props.match.params.courseId && item.isAdmin
        })
        const participants = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.match.params.courseId && participant.isAdmin
        })
        if (course.length > 0 && participants.length > 0) {
            if (!this.props.state.course.isFetching) {
                if (this.isFetching !== true) {
                    this.isFetching = true
                    this.props.actions.course.load(this.props.match.params.courseId, () => {
                        this.isFetching = false
                    }, false, participants[0].id)
                }
            }
        } else {
            this.props.history.push('/')
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.courseId !== newProps.match.params.courseId) {
            this.setState({
                courseId: newProps.match.params.courseId
            })
            const participants = newProps.state.user.model.participants.filter(participant => {
                return participant.course && participant.course.id === newProps.match.params.courseId && participant.isAdmin
            })
            if (participants.length > 0) {
                newProps.actions.course.load(newProps.match.params.courseId, () => {
                }, false, participants[0].id)
            }
            this._liveUpdateListen(newProps.match.params.courseId)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions),
            () => {
                if (participants.length > 0) {
                    props.actions.course.load(courseId, null, true, participants[0].id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    render() {
        const { props } = this
        const courseState = this.props.state.course
        const course = courseState.model

        if (courseState.isFetching || !this.props.state || !this.props.actions) {
            return (<Loading />)
        }

        const courseCheck = Course.Tools.validate(course)
        const baseLink = `/course/configuration/${course.id}`


        let nav = [
            {
                name: 'Getting started',
                link: `${baseLink}/started`
            },
            {
                name: 'Setup',
                type: 'header'
            },
            {
                name: 'Info',
                link: `${baseLink}/intro`
            },
            {
                name: 'Components',
                link: `${baseLink}/comps`
            },
            {
                name: 'Default settings',
                link: `${baseLink}/settings`
            }
        ]
        nav.push({
            name: <span>Modules
                <OverlayTrigger placement="right" overlay={(<Tooltip id="tooltip">Add a new module</Tooltip>)}>
                    <a className="right" style={{
                        top: '-5px',
                        textTransform: 'capitalize'
                    }} onClick={props.actions.local.modal.bind(this, 'module', 'new')}><i className="fa fa-plus" /> Add</a>
                </OverlayTrigger>
            </span>,
            type: 'header'
        })
        if (course.modules.length === 0) {
            nav.push({
                name: 'None',
                type: 'help'
            })
        } else {
            course.modules.sort((a, b) => {
                return a.offset - b.offset
            }).map(module => {
                nav.push({
                    name: <span>{module.name}<span className="number">{module.isDraft ? '(Draft)' : ''}</span></span>,
                    link: `${baseLink}/module/${module.id}`
                })
            })
        }

        let courseWrapperClassName = 'course-wrapper'
        courseWrapperClassName += (baseLink === this.props.location.pathname || baseLink + '/' === this.props.location.pathname) ? '' : ' tab-selected'

        return (
            <div className={courseWrapperClassName}>
                <Helmet title={course.name} />
                <CourseMenu course={course}
                    nav={nav}
                    actions={props.actions}
                    isConfigurationPage={true}
                    header={<Link to={'/course/instructor/' + course.id} className="btn btn-default btn-block">
                        <i className="fa fa-chevron-left"></i> {course.isLaunched ? "Back to case" : "View case"}
                    </Link>}
                    user={props.state.user.model} />

                <div className="config-right">
                    {(course.isLaunched && courseCheck.success) && <div className="alert alert-info">
                        <strong><i className="fa fa-info margin-right-5"></i> Case is live</strong><br />
                        We recommend you avoid doing modifications when a case is LIVE.<br />
                        If you do, please make sure that you have a green checkmark (<i className="fa fa-check green"></i>) next to "Checklist" in the left menu.
                    </div>}
                    {(course.isLaunched && courseCheck.count.errors > 0) && <div className="alert alert-danger">
                        <strong><i className="fa fa-info margin-right-5"></i> Course has errors</strong><br />
                        Your course is live and has errors. Participants will only be able to see a standby page. Please check the "Checklist" page for details.
                    </div>}
                    {(course.isLaunched && courseCheck.count.warnings > 0) && <div className="alert alert-warning">
                        <strong><i className="fa fa-info margin-right-5" ></i> Course has anomalies</strong><br />
                        We recommend you resolve the issues for a smoother user experience
                    </div>}
                    <div className="config-right-wrapper">
                        <div className="mobile-menu hidden-sm hidden-md hidden-lg">
                            <Link to={baseLink} className="btn btn-secondary"><i className="fa fa-chevron-left" /> Menu</Link>
                        </div>

                        <Switch>
                            <Route exact path={`${baseLink}`} component={CourseConfigurationTabs.GettingStarted} />
                            <Route path={`${baseLink}/started`} component={CourseConfigurationTabs.GettingStarted} />
                            <Route path={`${baseLink}/intro/:type?`} component={CourseConfigurationTabs.Introduction} />
                            <Route path={`${baseLink}/overview`} component={CourseConfigurationTabs.Overview} />
                            <Route path={`${baseLink}/comps/:type?`} component={CourseConfigurationTabs.Comps} />
                            <Route path={`${baseLink}/settings`} component={CourseConfigurationTabs.Settings} />
                            <Route path={`${baseLink}/module/:moduleId/:tab?`} component={CourseConfigurationTabs.Module} />
                        </Switch>
                    </div>
                </div>
                <Common.AllModals {...props} />
            </div>
        )
    }
}

export default ReduxBinder(PageCourseConfiguration, {
    state: ['course', 'user', 'module', 'local']
})