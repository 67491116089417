/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import backgroundImage from 'alias-assets/blurred-graph.png'
import { Avatar } from 'alias-components'

export default class ValueGraph extends Component {

    static propTypes = { //eslint-disable-line
        value: PropTypes.any,
        team: PropTypes.any,
        participant: PropTypes.any,
        state: PropTypes.object.isRequired,
        openModal: PropTypes.func,
        graphIsShown: PropTypes.bool
    }
    static defaultProps = {
        graphIsShown: true
    }

    constructor(props) {
        super(props)
        this.state = {
            isTableOpen: false,
            graphIsShown: props.graphIsShown
        }
    }


    render() {
        const { props, state } = this
        const { openModal } = props

        let data = null
        if (state.graphIsShown) {
            data = this._calculatePoints()
        }
        const value = this.props.value



        return <div style={{ margin: '20px auto 0' }}>
            {!state.graphIsShown && <div style={{
                display: 'block',
                width: '100%',
                height: 360,
                backgroundImage: `url("${backgroundImage}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                textAlign: 'center'
            }}>

                <a onClick={() => this.setState({ graphIsShown: true })} className="btn btn-secondary" style={{ marginTop: 100 }}>View graph</a>
            </div>}
            {(data && data.success) && <Line data={data.graph} options={{
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }} />}
            {((data && !data.success)) && <p className="alert alert-info"><strong>No graph available</strong><br />{data.reason || ''}</p>}
            {(!state.isTableOpen && data && data.success) && <a onClick={() => this.setState({ isTableOpen: !state.isTableOpen })}>View table <i className="fa fa-caret-down"></i></a>}
            {state.isTableOpen && <a onClick={() => this.setState({ isTableOpen: !state.isTableOpen })}>Hide table <i className="fa fa-caret-up"></i></a>}
            {state.isTableOpen && <div>
                <table className="table table-hover table-condensed table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Module</th>
                            <th>Forms</th>
                            <th>Who?</th>
                            <th>Change {`${value.unit ? `(in ${value.unit})` : ''}`}</th>
                            <th>Value {`${value.unit ? `(in ${value.unit})` : ''}`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0</td>
                            <td colSpan={2}>Start</td>
                            <td></td>
                            <td>0</td>
                            <td>{value.base}</td>
                        </tr>
                        {data.logForPoints.map((point, i) => {
                            return <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{point.module ? point.module.name : 'None'}</td>
                                <td><a onClick={() => openModal(point.form, point.log)}>{point.form.name} Q{point.questionNumber}</a></td>
                                <td>{point.who}</td>
                                <td>{point.addedAmount >= 0 ? `+${point.addedAmount}` : point.addedAmount}</td>
                                <td>{point.value}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
    }

    _calculatePoints() {
        const course = this.props.state.course.model
        let value = this.props.value
        const participant = this.props.participant
        const team = this.props.team


        if (!participant && !team) {
            return {
                success: false,
                reason: "No team or participant"
            }
        }
        if (!value) {
            return {
                success: false,
                reason: "No value selected"
            }
        }

        let data = {
            labels: [],
            datasets: [
                {
                    label: `${value.name} ${value.unit ? `(in ${value.unit})` : ''}`,
                    backgroundColor: 'rgba(76,171,131,0.3)',
                    borderColor: 'rgba(76,171,131,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(76,171,131,0.5)',
                    hoverBorderColor: 'rgba(76,171,131,1)',
                    data: []
                }
            ]
        }

        //
        // GET THE PARTICIPANTS AND ROLES
        //

        let participantsList = []
        let teamId = null
        if (participant) {
            participantsList = [participant.id]
            teamId = (participant.teamId) ? participant.teamId : null
        }
        if (team) {
            teamId = team.id
            participantsList.push(team.id)
        }
        if (teamId) {
            course.participants.map(part => {
                if (part.teamId === teamId) {
                    participantsList.push(part.id)

                }
            })
        }

        if (participantsList.length === 0) {
            return {
                success: false,
                reason: "No participants found"
            }
        }


        let logForPoints = []

        let labelValue = 0
        let pointValue = value.base * 1
        // Start value
        data.labels.push(labelValue)
        data.datasets[0].data.push(pointValue)

        let logs = course.activityLogs
            .filter(log => {
                // Filters
                if (log.type !== 'form') { return false }
                if (!log.data && !log.data.answers) { return false }
                if (!Array.isArray(log.data.answers)) { return false }
                if (log.data.answers.length === 0) { return false }
                if (participantsList.indexOf(log.participantId) === -1) { return false }
                if (!log.formStaticId) { return false }
                return true
            })

        logs = logs.map(log => {
            let activity = null
            let module = null
            let parentActivity = null
            if (log.data.activityId || log.activityId) {
                let activityId = log.activityId || log.data.activityId
                if (course.byId.activities[activityId]) {
                    activity = course.byId.activities[activityId]
                    if (activity.parentStaticId) {
                        parentActivity = course.byStaticId.activities[activity.parentStaticId]
                        if (parentActivity && parentActivity.moduleStaticId) {
                            module = course.byStaticId.modules[parentActivity.moduleStaticId] || null
                        }
                    }
                }
            }
            log.info = {}
            log.info.activity = activity
            log.info.parentActivity = parentActivity
            log.info.module = module
            log.time = (module && parentActivity) ? moment(course.start).add(module.offset + parentActivity.offset + activity.offset, 'minutes').format() : moment(log.created).format()
            return log
        })
        logs = logs.sort((a, b) => new Date(a.time) - new Date(b.time))
        logs.map(log => {
            // Finding the form
            let form = course.byStaticId.forms[log.formStaticId]
            if (!form || !form.questions || form.questions.length === 0) { return false }

            form.questions.map((question, i) => {
                // Filters
                if (question.type !== 'multiple-choice') { return false }
                if (!question.options) { return false }
                if (!Array.isArray(question.options)) { return false }

                let formHasValue = false
                let totalAddedAmountForThatForm = 0

                question.options.map(opt => {
                    if (!opt.values) { return }
                    opt.values.map(val => {
                        if (val.valueStaticId !== value.staticId) { return false }
                        formHasValue = true

                        // Check that the answer in the log is this
                        log.data.answers.map(answer => {
                            if (answer.id !== question.id) { return false }
                            if (answer.answer !== opt.id) { return false }

                            // Add the info here
                            // labelValue++
                            // data.labels.push(labelValue)
                            totalAddedAmountForThatForm += val.amount * 1
                            // pointValue = pointValue + val.amount * 1
                            // data.datasets[0].data.push(pointValue)


                        })

                    })
                })

                // the point should be here.
                if (formHasValue) {
                    pointValue = pointValue + totalAddedAmountForThatForm

                    // Graph
                    labelValue++
                    data.labels.push(labelValue)
                    data.datasets[0].data.push(pointValue)
                    // Who
                    let who = "?"
                    if (log.participantId) {
                        if (course.byId.participants[log.participantId]) {
                            who = <Avatar
                                user={course.byId.participants[log.participantId]}
                                size={20}
                                tooltip={true} />
                        }
                        if (course.byId.teams[log.participantId]) {
                            who = "TEAM"
                        }
                    }

                    //Table
                    logForPoints.push({
                        form: form,
                        questionNumber: i + 1,
                        log: log,
                        who: who,
                        module: log.info.module,
                        parentActivity: log.info.parentActivity,
                        isOffset: (log.info.module && log.info.parentActivity) ? log.info.module.offset + log.info.parentActivity.offset + log.info.activity.offset : false,
                        activity: log.info.activity,
                        addedAmount: totalAddedAmountForThatForm,
                        value: pointValue
                    })
                }

            })

        })


        return {
            graph: data,
            logForPoints: logForPoints,
            success: true
        }
    }
}