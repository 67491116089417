import React, { Component } from 'react'
import PropTypes from 'prop-types'
import scripts from 'alias-tools/scripts'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export default class Avatar extends Component {

    static propTypes = {
        user: PropTypes.object,
        size: PropTypes.number,
        tooltip: PropTypes.bool,
        tooltipPosition: PropTypes.string,
        classNames: PropTypes.string
    }

    static defaultProps = {
        tooltipPosition: 'top',
        size: 40,
        tooltip: false
    }

    render() {
        let { user, size, tooltip, tooltipPosition, classNames } = this.props
        const randomNumber = scripts.randomString()
        let attributes = {}
        let participant = null
        let name = "?"
        let initials = "?"

        if (user && user.userz) {
            participant = user
            user = participant.userz
        }


        attributes.className = `avatar ${classNames}`

        attributes.style = {
            width: size,
            height: size,
            lineHeight: size + 'px',
            fontSize: size * 2 / 3 + "px",
            backgroundColor: (user && user.color) ? user.color : "#DA9523"
        }

        if (user === undefined || !user) {
            const initials = "?"
            attributes.className += " with-initials"
            if (tooltip) {
                let tooltipEl = <Tooltip id={`tooltip-${randomNumber}`}>{`Not found`}</Tooltip>
                return <OverlayTrigger placement={tooltipPosition} overlay={tooltipEl}>
                    <a {...attributes}>{initials}</a>
                </OverlayTrigger>

            }
            return <div {...attributes}>{initials}</div>
        }


        if (participant) {
            name = participant.displayName.bigTeamThenName
            initials = participant.displayName.short.charAt(0)
        } else if (user.name) {
            name = user.name
            initials = name.charAt(0)
        } else {
            name = `${user.first} ${user.last} ${user.email ? '(' + user.email + ')' : ''}`.trim()
            if (name.charAt(0) === "(") {
                name = user.email
            }
            initials = name.charAt(0)
        }




        if (!user.avatar) {
            attributes.className += " with-initials"
            if (tooltip) {
                let tooltipEl = <Tooltip id={`tooltip-${randomNumber}`}>{name}</Tooltip>
                return (
                    <OverlayTrigger placement={tooltipPosition} overlay={tooltipEl}>
                        <a {...attributes}><span className="initials">{initials}</span></a>
                    </OverlayTrigger>
                )
            }
            return <div {...attributes}><span className="initials">{initials}</span></div>
        }

        var imageAttributes = {}
        imageAttributes.style = {
            width: size,
            height: size
        }
        imageAttributes.alt = name

        imageAttributes.src = `/api/proxy/avatar?url=${user.avatar}`


        attributes.style = Object.assign({}, attributes.style, {
            background: 'none'
        })
        if (tooltip) {
            let tooltipEl = <Tooltip id={`tooltip-${randomNumber}`}>{name}</Tooltip>
            return (
                <OverlayTrigger placement={tooltipPosition} overlay={tooltipEl}>
                    <a {...attributes}><img {...imageAttributes} /></a>
                </OverlayTrigger>
            )
        }
        return (<div {...attributes}><img {...imageAttributes} /></div>)

    }
}