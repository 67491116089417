import storage from './storage'

const tokenName = 'liveCaseToken'
const dataName = 'liveCase'

export default {
    login(token) {
        return storage.setLocalStorage(tokenName, token)
    },
    logout() {
        return storage.removeLocalStorage(tokenName) && storage.removeLocalStorage(dataName)
    },
    isAuthenticated() {
        return storage.getLocalStorage(tokenName)
    }
}