import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import CourseMenu from './components/CourseMenu'
import Course from './components/Course'
import Messaging from './components/Messaging'
import { Link, Route, Switch } from 'react-router-dom'
import CourseRunTabs from './courseRunTabs'
import Common from './common'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'

class PageCourseRun extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId

        // Connecting server live updates
        this._liveUpdateListen(courseId)

        this.state = {
            courseId: this.props.match.params.courseId
        }
    }

    UNSAFE_componentWillMount() {
        const course = this.props.state.user.model.participants.filter(item => {
            return item.course && item.course.id === this.props.match.params.courseId && item.isAdmin
        })
        const participants = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.match.params.courseId && participant.isAdmin
        })
        if (course.length > 0 && participants.length > 0) {
            if (this.isFetching !== true) {
                this.isFetching = true
                this.props.actions.course.load(this.props.match.params.courseId, () => {
                    this.isFetching = false
                }, false, participants[0].id)
            }
        } else {
            this.props.history.push('/')
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const participants = newProps.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === newProps.match.params.courseId && participant.isAdmin
        })
        if (this.state.courseId !== newProps.match.params.courseId && participants.length > 0) {
            this.setState({
                courseId: newProps.match.params.courseId
            })
            newProps.actions.course.load(newProps.match.params.courseId, () => {
            }, false, participants[0].id)
            this._liveUpdateListen(newProps.match.params.courseId)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions),
            () => {
                if (participants.length > 0) {
                    props.actions.course.load(courseId, null, true, participants[0].id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    render() {
        const { props } = this
        if (!this.props.state || !this.props.actions) {
            return <Loading />
        }
        const courseState = this.props.state.course
        const course = courseState.model
        const modules = course.modules.filter(m => !m.isDraft).sort((a, b) => {
            return a.offset - b.offset
        })

        if (courseState.isFetching && !courseState.isLoaded) {
            return <Loading />
        }

        let rolePlayActivities = course.activities.filter(activity => activity.type === "rolePlay")


        const participants = props.state.user.model.participants.filter(participant => {
            return participant.courseId === course.id && participant.isAdmin
        })
        const participant = participants.length > 0 ? participants[0] : null


        const baseLink = `/course/instructor/${course.id}`
        let nav = [
            {
                name: <span>Launch checklist<span className="number"><i className={`fa fa-${course.isLaunched ? 'check' : 'times'}`}></i></span></span>,
                link: `${baseLink}/checklist`,
                baseLink: baseLink
            },
            {
                name: 'Overview',
                link: `${baseLink}/overview`
            },
            {
                name: `SETUP`,
                type: 'header'
            },
            {
                name: 'Scheduling',
                link: `${baseLink}/scheduling`
            },
            {
                name: <span>Participants<span className="number">{course.participants.filter(part => !part.isAdmin && !part.isActor).length}</span></span>,
                link: `${baseLink}/participants`
            },
            {
                name: 'Settings',
                link: `${baseLink}/settings`
            },
            {
                name: `Game`,
                type: 'header'
            },
            {
                name: 'Scoring',
                link: `${baseLink}/scoring`
            },
            {
                name: 'Happening now',
                link: `${baseLink}/now`
            },
            {
                name: `Comms`,
                type: 'header'
            }
        ]

        if (course.showEmail) {
            let mailUnreadCount = 0
            if (participant) {
                const mails = Messaging.Tools.getThreadsForParticipant(course, 'email', participant, false, course.activities)
                mailUnreadCount = mails ? mails.filter(thread => thread.isUnread).length : 0
            }
            nav.push({
                name: <span>Mailbox {mailUnreadCount > 0 ? <span className="counter active"><i className="fa fa-circle fa-fw" style={{ position: 'relative', left: -7 }} /></span> : ''}</span>,
                link: `${baseLink}/messaging/email`
            })
        }
        if (course.showChat) {
            let chatUnreadCount = 0
            if (participant) {
                const chats = Messaging.Tools.getThreadsForParticipant(course, 'chat', participant, false, course.activities)
                chatUnreadCount = chats ? chats.filter(thread => thread.isUnread).length : 0
            }
            nav.push({
                name: <span>Chat {chatUnreadCount > 0 ? <span className="counter active"><i className="fa fa-circle fa-fw" style={{ position: 'relative', left: -7 }} /></span> : ''}</span>,
                link: `${baseLink}/messaging/chat`
            })
        }

        if (rolePlayActivities.length > 0) {
            nav.push({
                name: 'Role plays',
                link: `${baseLink}/role-plays`
            })
        }
        if (course.showForum) {
            // Check for unread <span className="counter active">{mailUnreadCount}</span>
            let hasUnread = false
            if (participant) {
                const logs = course.activityLogs.filter(l => l.type === "forum-read" && l.participantId === participant.id)
                const latestPost = (course.forums && course.forums.length > 0) ? course.forums[0] : null
                if (logs.length > 0 && latestPost) {
                    if (new Date(latestPost.latestUpdate) - new Date(logs[0].updated) > 0) {
                        hasUnread = true
                    }
                }
                if (logs.length === 0 && latestPost) {
                    hasUnread = true
                }
            }
            nav.push({
                name: <span>Forum {hasUnread ? <span className="counter active"><i className="fa fa-circle fa-fw" style={{ position: 'relative', left: -7 }} /></span> : ''}</span>,
                link: `${baseLink}/forum`
            })
        }
        if (modules.length > 0) {
            nav.push({
                name: `Module${modules.length > 1 ? 's' : ''}`,
                type: 'header'
            })
            modules.map(module => {
                nav.push({
                    name: module.name,
                    link: `${baseLink}/module/${module.staticId}`
                })
            })
        }



        let courseWrapperClassName = 'course-wrapper'
        courseWrapperClassName += (this.props.location.pathname.indexOf('/mailbox') !== -1 || this.props.location.pathname.indexOf('/email') !== -1 || this.props.location.pathname.indexOf('/chat') !== -1) ? ' with-mailbox' : ''
        courseWrapperClassName += (baseLink === this.props.location.pathname || baseLink + '/' === this.props.location.pathname) ? '' : ' tab-selected'

        const courseCheck = Course.Tools.validate(course)

        return <div className={courseWrapperClassName}>
            <Helmet title={course.name} />
            <CourseMenu course={course}
                actions={props.actions}
                header={<Link to={'/course/configuration/' + course.id}
                    className="btn btn-default btn-block"><i className="fa fa-cog"></i> Configure</Link>}
                nav={nav}
                user={props.state.user.model} />
            <div className="config-right">
                <div className="mobile-menu hidden-sm hidden-md hidden-lg">
                    <Link to={baseLink} className="btn btn-secondary"><i className="fa fa-chevron-left" />
                        Menu</Link>
                </div>
                {courseCheck.count.errors > 0 && <div className="alert alert-danger">
                    <strong>Course error</strong><br />
                    There is an error in the course, please return to <Link to={`/course/configuration/${course.id}/started`}>course configuration checklist</Link> to identify and fix the issue.<br />
                    If the course is live, participants will see a standby page.
                </div>}
                {(false && courseCheck.count.warnings > 0) && <div className="alert alert-warning">
                    <strong>Course warnings</strong><br />
                    We are detecting some anomalies with the case design, please return to <Link to={`/course/configuration/${course.id}/started`}>configuration checklist</Link> to identify and fix the issue.<br />
                </div>}
                {courseCheck.count.errors === 0 && <Switch>
                    <Route exact path={`${baseLink}`} component={CourseRunTabs.Checklist} />
                    <Route path={`${baseLink}/checklist`} component={CourseRunTabs.Checklist} />
                    <Route path={`${baseLink}/overview`} component={CourseRunTabs.Overview} />
                    <Route path={`${baseLink}/scheduling`} component={CourseRunTabs.Scheduling} />
                    <Route path={`${baseLink}/participants`} component={CourseRunTabs.Participants} />
                    <Route path={`${baseLink}/teams`} component={CourseRunTabs.Teams} />
                    <Route path={`${baseLink}/settings`} component={CourseRunTabs.Settings} />
                    <Route path={`${baseLink}/scoring`} component={CourseRunTabs.Scoring} />
                    <Route path={`${baseLink}/now`} component={CourseRunTabs.Now} />
                    <Route path={`${baseLink}/messaging/:type/:participantId?/:threadId?`} component={CourseRunTabs.Messaging} />
                    <Route path={`${baseLink}/role-plays/:activityId?`} component={CourseRunTabs.RolePlay} />
                    <Route path={`${baseLink}/forum`} component={CourseRunTabs.Forum} />
                    <Route path={`${baseLink}/module/:moduleStaticId`} component={CourseRunTabs.Module} />
                    <Route path={`${baseLink}/duplicates`} component={CourseRunTabs.Duplicates} />
                </Switch>}
            </div>
            <Common.AllModals {...props} />
        </div>

    }
}

export default ReduxBinder(PageCourseRun, {
    state: ['course', 'user', 'local']
})