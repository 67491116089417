import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Badge from '../components/Badge'
import Character from '../components/Character'
import Role from '../components/Role'
import Value from '../components/Value'
import Activity from '../components/Activity'
import Form from '../components/Form'
import Scoring from '../components/Scoring'
import { ModalModule } from 'alias-components'

export default class CommonAllModals extends Component {
    static propTypes = {
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }
    static defaultProps = {}
    constructor(props) {
        super(props)
    }
    render() {
        const { props } = this
        const modals = props.state.local.modals

        return <div>
            <Badge.Modal isOpen={modals.badge !== null}
                handleClose={this._closeModal.bind(this, 'badge')}
                openModal={this._openModal.bind(this)}
                badgeId={modals.badge}
                state={props.state}
                actions={props.actions} />

            <Character.Modal
                isOpen={modals.character !== null}
                handleClose={this._closeModal.bind(this, 'character')}
                openModal={this._openModal.bind(this)}
                characterId={modals.character}
                state={props.state}
                actions={props.actions} />

            <Role.Modal isOpen={modals.role !== null}
                handleClose={this._closeModal.bind(this, 'role')}
                openModal={this._openModal.bind(this)}
                roleId={modals.role}
                state={props.state}
                actions={props.actions} />

            <Value.Modal isOpen={modals.value !== null}
                handleClose={this._closeModal.bind(this, 'value')}
                openModal={this._openModal.bind(this)}
                valueId={modals.value}
                state={props.state}
                actions={props.actions} />

            <Form.Modal isOpen={modals.form !== null}
                handleClose={this._closeModal.bind(this, 'form')}
                openModal={this._openModal.bind(this)}
                formId={modals.form}
                state={props.state}
                actions={props.actions} />

            <Scoring.Modal isOpen={modals.tag !== null}
                handleClose={this._closeModal.bind(this, 'tag')}
                openModal={this._openModal.bind(this)}
                scoringTagId={modals.tag}
                state={props.state}
                actions={props.actions} />

            <ModalModule isShown={modals.module !== null}
                close={this._closeModal.bind(this, 'module')}
                state={props.state}
                actions={props.actions}
                history={props.history} />


            <Activity.PreviewModal isOpen={modals.activityPreview !== null}
                handleClose={this._closeModal.bind(this, 'activityPreview')}
                activityId={modals.activityPreview}
                state={props.state} />

            <Activity.Modal isOpen={modals.activity !== null}
                handleClose={() => props.actions.local.closeModalActivity()}
                openModal={(activityId) => props.actions.local.openModalActivity(activityId)}
                openFormModal={(formId) => this._openModal('form', formId)}
                openPreview={(activityId) => this._openModal('activityPreview', activityId)}
                activityId={modals.activity}
                moduleStaticId={modals.activityModule}
                parentStaticId={modals.activityParent}
                state={props.state}
                actions={props.actions} />
        </div>
    }

    _closeModal(name) {
        this.props.actions.local.modal(name, null)
    }
    _openModal(name, value) {
        this.props.actions.local.modal(name, value)
    }
}
