import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

export default class ModalComponent extends Component {

    static propTypes = {
        isShown: PropTypes.bool.isRequired,
        close: PropTypes.func,
        title: PropTypes.any,
        footer: PropTypes.any,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool
    }

    static defaultProps = {
        showHeader: false,
        showFooter: false,
        isShown: false,
        close: function () {
            alert("You haven't coded the close function for the modal!")
        }
    }


    render() {
        const { props } = this

        return (
            <Modal show={props.isShown} onHide={() => props.close()}>
                {(props.title || props.showHeader) && (
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {props.title}
                        </Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                {props.showFooter && (
                    <Modal.Footer>
                        {!props.footer && <a onClick={() => props.close()} className="btn btn-default">Close</a>}
                    </Modal.Footer>
                )}
            </Modal>
        )
    }

}