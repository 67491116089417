import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Input, Avatar, Search } from 'alias-components'
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'


class ControlTabUsers extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            showCount: 30
        }
    }


    render() {
        const { search, showCount } = this.state
        const { control } = this.props.state
        let { users } = control
        const totalNumberOfUsers = users.length

        if (search) {
            let options = {
                keys: [{
                    name: 'first',
                    weight: .4
                }, {
                    name: 'last',
                    weight: 0.4
                }, {
                    name: 'email',
                    weight: 0.3
                }],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.4
            };
            let fuse = new Fuse(users, options)
            users = fuse.search(search)
        }
        users = users.slice(0, showCount)


        return (
            <div className="config-right-wrapper">
                <div className="page-header">
                    <h1 style={{ display: 'inline-block' }}>Users</h1>
                    <div className="pull-right">
                        <Search search={this.state.search}
                            onChange={(search) => this.setState({ search: search })} />
                    </div>
                </div>


                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th colSpan={3}>Name</th>
                            <th>Is registered?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(u => {
                            return <tr key={u.id} className={`${u.registered ? '' : 'danger'}`}>
                                <td width={40}><Avatar user={u} size={30} /></td>
                                <td>{u.first} {u.last}</td>
                                <td>{u.email}</td>
                                <td>
                                    {u.registered ? <i className="fa fa-check green"></i> : <i className="fa fa-times red"></i>}
                                </td>
                                <td><Link to={`/control/users/${u.id}`}><i className="fa fa-pencil"></i></Link></td>
                            </tr>
                        })}

                    </tbody>
                </table>
                {showCount < totalNumberOfUsers && (
                    <div style={{ textAlign: 'center', margin: '20px 0 40px' }}>
                        <a onClick={() => {
                            this.setState({
                                showCount: showCount + 30
                            })
                        }}
                            className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show 30 more</a>
                        &nbsp;of {totalNumberOfUsers - showCount} left
                    </div>
                )}
            </div>
        )
    }
}

export default ReduxBinder(ControlTabUsers, {
    state: ['control']
})