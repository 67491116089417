import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import { Modal } from 'react-bootstrap'
import Messaging from '../Messaging'

const defaultMessage = {
    message: '',
    created: null,
    participantId: null,
    type: 'message' // or question or answer
}

export default class ActivityPreviewModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        state: PropTypes.object,
        activityId: PropTypes.string,
        handleClose: PropTypes.func
    }

    render() {
        const { props } = this
        const course = props.state.course.model

        let activity = (props.activityId && course.byId.activities[props.activityId]) ? course.byId.activities[props.activityId] : null
        if (!activity) {
            return <Modal show={props.isOpen} onHide={props.handleClose}>
                <Modal.Header>
                    <Modal.Title>{'No activity found'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="alert alert-info">Activity not found</p>
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={props.handleClose} className="btn btn-default">Close</a>
                </Modal.Footer>
            </Modal>
        }
        let character = (activity.characterStaticId && course.byStaticId.characters[activity.characterStaticId]) ? course.byStaticId.characters[activity.characterStaticId] : { first: 'Not found', last: 'Not found', title: 'Not found' }
        let fakeUser = (activity.roleStaticId && course.byStaticId.roles[activity.roleStaticId]) ? {
            first: 'Jane',
            last: 'Smith',
            title: 'Head of previews'
        }
            : { first: 'Jane', last: 'Smith', title: 'Head of previews' }
        let messages = []


        if (activity && activity.type === 'email') {
            messages.push({
                ...defaultMessage,
                message: Messaging.Tools.template(activity.body, character, fakeUser) + '\n' + character.signature,
                docs: course.docs.filter(doc => doc.activityStaticId === activity.staticId),
                newDocs: (activity && activity.data && activity.data.docs) ? activity.data.docs : []
            })
        }
        if (activity && activity.formStaticId && course.byStaticId.forms[activity.formStaticId]) {
            let form = course.byStaticId.forms[activity.formStaticId]
            form.questions.map(question => {
                messages.push({
                    ...defaultMessage,
                    message: question.question
                })
            })
        }


        return (
            <Modal show={props.isOpen} onHide={props.handleClose}>
                <Modal.Header>
                    <Modal.Title>{activity ? `Preview of : ${activity.name}` : 'No activity selected'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {activity && <div>
                        <h3>{activity.subject}</h3>
                        {messages.map((message, i) => {
                            return <Card key={i}>
                                <p style={{ whiteSpace: 'pre-line' }}>{message.message}</p>
                                {(message && message.docs && message.docs.length > 0) && (
                                    <ul className="list-unstyled">
                                        {message.docs.map(function (doc) {
                                            return (
                                                <li key={doc.id}>
                                                    <a href={doc.path} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />
                                                        {doc.name}
                                                    </a>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                )}
                                {message.newDocs && (
                                    <ul className="list-unstyled">
                                        {message.newDocs.map(doc => {
                                            return <li key={doc.id}>
                                                <a href={`/api/files?url=${doc.path}`} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />
                                                    {doc.name}
                                                </a>
                                            </li>
                                        })}
                                    </ul>
                                )}
                            </Card>
                        })}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={props.handleClose} className="btn btn-default">Close</a>
                </Modal.Footer>
            </Modal>
        )
    }
}