import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors } from 'alias-components'
import IsValid from 'alias-tools/validation'
import moment from 'moment-timezone'

const TIMEZONES = moment.tz.names().filter(function (timezone) {
    return timezone.indexOf("GMT") === -1
}).map(function (timezone) {
    return {
        value: timezone,
        label: timezone
    }
})

export default class CourseModal extends Component {

    static propTypes = {
        template: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        makeTemplate: PropTypes.bool,
        isShown: PropTypes.bool,
        close: PropTypes.func
    }

    static defaultProps = {
        isShown: false,
        makeTemplate: false,
        close: function () {
            alert("You haven't coded the close function for the modal!")
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            course: {
                name: '',
                description: '',
                timezone: '',
                templateId: 0
            },
            errorFields: []
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.course.clearMessages()
    }

    render() {
        const { props, state } = this
        const course = state.course
        const templates = props.state.user.model.participants.filter((participant) => {
            return participant.isAdmin && participant.course != undefined
        }).map((item) => {
            return {
                value: item.course.id,
                label: item.course.name
            }
        })



        return (
            <Modal show={this.props.isShown} onHide={this._onClose.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new case</Modal.Title>
                </Modal.Header>
                <form onSubmit={this._create.bind(this)}>
                    <Modal.Body>
                        <div style={{ padding: '5px 15px' }}>

                            <FormErrors fields={state.errorFields} />

                            {(!props.template && templates.length > 0) && (
                                <Input
                                    type="select"
                                    name="templateId"
                                    description="This will copy all the content from the previous case except any participant/team data"
                                    value={course.templateId}
                                    onChange={this._onChangeNew.bind(this)}
                                    label="Use previous case as base"
                                    options={templates} />
                            )}
                            {props.template && <div>
                                <strong>Template</strong>
                                <p>{props.template.name}</p>
                            </div>}

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Acquisition course..."
                                value={course.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input
                                type="textarea"
                                name="description"
                                value={course.description}
                                onChange={this._onChangeNew.bind(this)}
                                label="Quick description"
                                placeholder="What is it about?..." />

                            <Input
                                type="select"
                                name="timezone"
                                value={course.timezone}
                                onChange={this._onChangeNew.bind(this)}
                                label="Timezone"
                                options={TIMEZONES}
                                placeholder="Pick a timezone for where the course will be running..." />

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a onClick={this._onClose.bind(this)} className="btn btn-default">Cancel</a>
                        <Button text="Create" isLoading={props.state.course.isSaving} />
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        var course = this.state.course
        course[name] = value
        this.setState({ course: course })
    }

    _onChange() {
        var course = this.state.course
        course.name = this.refs.name.value
        course.templateId = this.refs.templateId.value
        this.setState({
            course: course
        })
    }

    _isFormValid() {
        var course = this.state.course
        var formIsValid = true
        var errorFields = []
        let errors = null
        // Timezone
        if (!this.props.makeTemplate) {
            errors = IsValid(course.timezone, { 'required': null })
            if (errors.length > 0) {
                formIsValid = false
                errorFields.push({
                    name: "Timezone",
                    errors: errors
                })
            }
        }
        // Name
        errors = IsValid(course.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Case name",
                errors: errors
            })
        }
        // Description
        errors = IsValid(course.description, { maxLength: 500 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Case description",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        let self = this
        let course = this.state.course
        if (this.props.template) {
            course.templateId = this.props.template.id
        }
        if (this.props.makeTemplate) {
            course.isTemplate = true
            course.isPublic = false
        }

        this.props.actions.course.create(course, function (course) {
            self.props.close()
            window.location.href = "/course/configuration/" + course.id
        })
    }

    _onClose() {
        this.props.actions.course.clearMessages()
        this.setState({
            errorFields: []
        })
        this.props.close()
        return false
    }
}