import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import Scoring from '../../Scoring'

export default class DashboardInstructorScoreByModule extends Component {

    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props);

    }
    render() {
        const { course } = this.props;
        let data = {};
        const modules = course.modules;

        modules.map(module => {
            data[module.id] = {
                zero: 0,
                one: 0,
                two: 0,
                three: 0,
                four: 0,
                five: 0
            }
        });

        modules.map(module => {
            course.participants.map(participant => {
                if (participant.isAdmin || participant.isActor) { return; }
                let moduleActivities = [];
                course.activities.filter(activity => !activity.parentStaticId && activity.moduleStaticId === module.staticId).map(groupActivity => {
                    course.activities.filter(activity => activity.parentStaticId === groupActivity.staticId).map(childActivity => {
                        moduleActivities.push(childActivity)
                    })
                });
                let score = participant.score ? participant.score : Scoring.Tools.participant(participant, moduleActivities, course.activityLogs, course.forms, course.scoringTags);
                if (!score) {
                    data[module.id].zero++;
                    return;
                }
                let realScore = score.score;
                if (realScore < 0.5 || realScore === null) {
                    data[module.id].zero++;
                    return;
                } else if (realScore >= 0.5 && realScore < 1.5) {
                    data[module.id].one++;
                    return;
                } else if (realScore >= 1.5 && realScore < 2.5) {
                    data[module.id].two++;
                    return;
                } else if (realScore >= 2.5 && realScore < 3.5) {
                    data[module.id].three++;
                    return;
                } else if (realScore >= 3.5 && realScore < 4.5) {
                    data[module.id].four++;
                    return;
                } else if (realScore >= 4.5 && realScore <= 5) {
                    data[module.id].five++;
                }

            })
        })

        const colors = ["#631D76", "#FE5F55", "#BE3E82", "#F9C80E", "#496DDB", '#FE9920', '#CFD11A', '#91C499', '#9E768F', '#E55381', '#D183C9', '#2292A4', '#7DBBC3', '#AB3428'];
        let chartData = {
            labels: ['0', '1', '2', '3', '4', '5'],
            datasets: modules.map((module, i) => {
                return {
                    label: module.name,
                    backgroundColor: colors[i],
                    data: [data[module.id].zero, data[module.id].one, data[module.id].two, data[module.id].three, data[module.id].four, data[module.id].five]
                }
            })
        }
        //  options={{ legend: { display: false } }}
        return <div>
            <Bar data={chartData} options={{
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }} />
        </div>
    }
}