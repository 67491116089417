import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Input from './Input'
import Button from './Button'
import FormErrors from './FormErrors'
import IsValid from 'alias-tools/validation'

export default class ModalModule extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        isShown: PropTypes.bool.isRequired,
        close: PropTypes.func,
        history: PropTypes.object
    }

    static defaultProps = {
        isShown: false,
        close: function () {
            alert("You haven't coded the close function for the modal!")
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            module: {
                name: '',
                order: 0
            },
            errorFields: []
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.module.clearMessages()
    }

    render() {
        const module = this.state.module

        return (
            <Modal show={this.props.isShown} onHide={this._onClose.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create a new module
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={this._create.bind(this)}>
                    <Modal.Body>
                        <div>
                            <FormErrors fields={this.state.errorFields} />
                            <Input name="name"
                                label="Name"
                                placeholder="Ex: Module 1..."
                                value={module.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{
                                    'autoFocus': true
                                }} />

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text="Create module" />
                        <a onClick={this._onClose} className="btn btn-default">Close</a>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        var module = this.state.module
        module[name] = value
        this.setState({
            module: module
        })
    }

    _isFormValid() {
        var module = this.state.module
        var formIsValid = true
        var errorFields = []
        // Name
        var errors = IsValid(module.name, {
            required: null,
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Module name",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        const course = this.props.state.course.model
        if (!this._isFormValid()) {
            return false
        }
        let module = this.state.module
        module.order = course.modules.length
        module.courseId = course.id
        this.props.actions.module.create(this.state.module, (newModule) => {
            this.setState({
                module: Object.assign({}, this.state.model, {
                    name: ''
                })
            })
            this.props.actions.activity.create({
                courseId: course.id,
                name: 'Main group',
                moduleStaticId: newModule.staticId,
                offset: 0
            }, () => {
                this.props.close()
                this.props.history.push(`/course/configuration/${course.id}/module/${newModule.staticId}/info`)
            })
        })
    }

    _onClose() {
        this.props.actions.module.clearMessages()
        var module = this.state.module
        module.name = ""
        module.offset = 0
        this.setState({
            errorFields: [],
            module: module
        })
        this.props.close()
        return false
    }
}