import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import scripts from 'alias-tools/scripts'


const componentStyle = {
    wrapper: { maxWidth: 640, maxHeight: 400, minHeight: 250, background: '#333333' },
    newWrapper: { position: 'relative', paddingTop: '56.25%' },
    player: { position: 'absolute', top: 0, left: 0 }
}

export default class VideoPlayer extends Component {
    static propTypes = {
        url: PropTypes.string,
        vimeoId: PropTypes.string
    }
    static defaultProps = {}

    render() {
        const { props } = this

        if (props.vimeoId) {
            return <div>
                <iframe
                    src={`https://player.vimeo.com/video/${props.vimeoId}?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=142791`}
                    width="400"
                    height="300"
                    frameBorder="0"
                    // allow="autoplay; fullscreen"
                    allowFullScreen></iframe>
            </div>
        }
        if (!props.url) {
            return <span></span>
        }
        let videoInfo = scripts.parseVideo(props.url)

        if (videoInfo.type === 'youtube') {
            return <div className="videoWrapper"
                style={{ marginBottom: '10px' }}>
                <iframe src={`https://www.youtube.com/embed/${videoInfo.id}`}
                    frameBorder="0"
                    allowFullScreen></iframe>
            </div>
        }
        if (videoInfo.type === 'vimeo') {
            return <div className="videoWrapper"
                style={{ marginBottom: '10px' }}>
                <iframe src={`https://player.vimeo.com/video/${videoInfo.id}?badge=0`}
                    frameBorder="0"
                    allowFullScreen></iframe>
            </div>
        }
        return <div style={{ background: '#000000' }}>
            <div className="player-wrapper">
                <ReactPlayer
                    className="react-player"
                    url={props.url}
                    controls={true}
                    light={false}
                    width="100%"
                    height="100%" />
            </div>
        </div>
    }
}
