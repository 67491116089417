/* globals DEBUG */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors } from 'alias-components'
import Scripts from 'alias-tools/scripts'
import IsValid from 'alias-tools/validation'

const initialState = {
    userz: {
        first: "",
        last: "",
        email: ""
    }
}


export default class ParticipantModal extends Component {

    static propTypes = {
        course: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool,
        isOpen: PropTypes.bool,
        participant: PropTypes.object,
        handleClose: PropTypes.func
    }

    static defaultProps = {
        isOpen: false,
        handleClose: function () {
            alert("You haven't coded the close function for the modal!")
        },
        isAdmin: false
    }

    constructor(props) {
        super(props)

        this.state = {
            participant: (props.participant === null) ? JSON.parse(JSON.stringify(initialState)) : props.participant,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isEdited) {
            return
        }
        if (nextProps.participant !== null) {
            this.setState({
                participant: nextProps.participant
            })
        } else {
            this.setState({
                participant: JSON.parse(JSON.stringify(initialState))
            })
        }
    }

    render() {
        const { isAdmin } = this.props
        const user = this.state.participant.userz


        let title = `Add a new ${isAdmin ? 'author' : 'participant'}`
        let btnText = "Add"
        let formSubmit = this._create.bind(this)
        let canEdit = false
        let isNew = true

        if (this.props.participant !== null) {
            title = "Update participant"
            btnText = "Save"
            formSubmit = this._update.bind(this)

            isNew = false
            if (!user.registered) {
                canEdit = true
            }
        }

        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div>
                            {(canEdit === false && isNew === false) && (
                                <p className="alert alert-warning">
                                    <strong>Not editable</strong><br />
                                    The participant is already registered on the platform and cannot be edited.
                                </p>
                            )}
                            <FormErrors fields={this.state.errorFields} />
                            <Input name="email"
                                label="Email"
                                placeholder="Ex: student@school.com..."
                                value={user.email}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }}
                                disabled={(canEdit === false && isNew === false)} />
                            <Input name="first"
                                label="First name"
                                placeholder="Ex: John..."
                                value={user.first}
                                onChange={this._onChangeNew.bind(this)}
                                disabled={(canEdit === false && isNew === false)} />
                            <Input name="last"
                                label="Last name"
                                placeholder="Ex: Smith..."
                                value={user.last}
                                onChange={this._onChangeNew.bind(this)}
                                disabled={(canEdit === false && isNew === false)} />
                            <Input name="title"
                                label="Title"
                                placeholder="Ex: Mr, Dr,..."
                                value={user.title}
                                onChange={this._onChangeNew.bind(this)}
                                disabled={(canEdit === false && isNew === false)} />
                            {(DEBUG && isNew) && (
                                <div>
                                    <br />
                                    <br />
                                    <a onClick={this._generateRandomUser.bind(this)}>Generate random user</a>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} isLoading={this.props.state.course.isSaving} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(this.props.participant !== null) && <a onClick={this._removeParticipant.bind(this)} className="btn btn-link red">Remove from course</a>}
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        let participant = this.state.participant
        participant.userz[name] = value
        this.setState({
            participant: participant,
            isEdited: true
        })
    }

    _onChange() {
        let participant = this.state.participant
        participant.userz.first = this.refs.first.value
        participant.userz.last = this.refs.last.value
        participant.userz.email = this.refs.email.value
        participant.userz.title = this.refs.title.value
        this.setState({
            participant: participant,
            isEdited: true
        })
    }

    _isFormValid() {
        var participant = this.state.participant
        var formIsValid = true
        var errorFields = []
        var errors = []
        // First
        errors = IsValid(participant.userz.first, {
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "First name",
                errors: errors
            })
        }
        // Last
        errors = IsValid(participant.userz.last, {
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Last name",
                errors: errors
            })
        }
        // Email
        errors = IsValid(participant.userz.email, {
            email: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Email",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const { props } = this
        const user = this.state.participant.userz
        // If Admin, check for duplicates
        if (props.isAdmin) {
            let isDuplicateAdmin = false
            this.props.course.participants.filter(participant => {
                if (participant.isAdmin && participant.userz.email == user.email) {
                    isDuplicateAdmin = true
                }
            })
            if (isDuplicateAdmin) {
                alert("An author can only be added once.")
                return false
            }
        }

        let participant = {
            isAdmin: props.isAdmin,
            courseId: props.course.id,
            userz: user
        }
        this.props.actions.participant.create([participant], () => {
            this.setState({ isEdited: false })
            this._close()
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        let participant = this.state.participant
        this.props.actions.participant.update(participant, () => {
            this.setState({ isEdited: false })
        })
    }

    _generateRandomUser(e) {
        e.preventDefault()
        this.setState({
            participant: {
                userz: {
                    first: Scripts.randomString(),
                    last: Scripts.randomString(),
                    email: Scripts.randomString() + "@mailinator.com"
                }
            }
        })
    }

    _removeParticipant() {
        const participant = this.state.participant

        if (confirm("Are you sure you want to remove " + participant.userz.first + " " + participant.userz.last + " from the course?")) {
            this.props.actions.participant.remove(participant, () => {
                this.setState({ isEdited: false })
                this._close()
            })
        }
    }

    _close() {
        if (!this.state.isEdited) {
            return this.props.handleClose()
        }
        if (confirm("You have made some edit, are you sure you want to close?")) {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}