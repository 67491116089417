import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card } from 'alias-components'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Config from 'alias-config'

export default class CourseCard extends Component {

    static propTypes = {
        course: PropTypes.object.isRequired,
        isInstructor: PropTypes.bool,
        isCourse: PropTypes.bool,
        isTemplate: PropTypes.bool,
        isMarket: PropTypes.bool,
        children: PropTypes.any
    }

    static defaultProps = {
        course: {
            name: '',
            description: '',
            id: 0
        },
        isCourse: false,
        isInstructor: false,
        isTemplate: false,
        isMarket: false
    }

    render() {
        const { props } = this
        const course = props.course

        let link = ""
        link = (props.isInstructor) ? "/course/instructor" : link
        link = (props.isTemplate) ? "/course/configuration" : link
        link = (props.isMarket) ? "/course/market" : link
        link = (props.isCourse) ? "/course/my" : link
        link += "/" + course.id

        return (
            <Card>
                <div className="card-course2">
                    <Avatar size={120} user={course} />
                    <h3>{course.name}</h3>
                    <p className={(course.description) ? 'description' : 'description'}>{(course.description) ? course.description : 'No description'}</p>
                    {course.start && <p>{moment(course.start).tz(course.timezone).format(Config.app.time.dateTimeFormat)} ({course.timezone})</p>}
                    {props.children}
                    <Link to={link} className="overlay"></Link>
                    {props.isInstructor && <Link to={`/course/configuration/${course.id}`} className="card-edit"><i className="fa fa-pencil"></i></Link>}
                </div>
            </Card>
        )
    }
}