import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, AvatarUploader, FormErrors } from 'alias-components'
import countries from 'alias-data/countries'
import industries from 'alias-data/industries'
import structures from 'alias-store/structures'
import IsValid from 'alias-tools/validation'


export default class ModalOrganization extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        organizationId: PropTypes.any,
        organization: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let organization = { ...structures.organization }

        if (props.organizationId && props.organizationId !== 'new' && course.byId.organizations[props.organizationId]) {
            organization = {
                ...organization,
                ...course.byId.organizations[props.organizationId]
            }
        }
        this.state = {
            organization: organization,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.organization.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.organizationId != this.state.organization.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (course.byId.organizations[nextProps.organizationId]) {
                this.setState({
                    organization: course.byId.organizations[nextProps.organizationId]
                })
            } else {
                this.setState({ organization: { ...structures.organization } })
            }
        }
    }

    render() {
        const organization = this.state.organization

        let title = "Create a new organization"
        let btnText = "Create"
        let formSubmit = this._create.bind(this)

        if (this.props.organizationId !== 'new') {
            title = "Update organization"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }

        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div>
                            <FormErrors fields={this.state.errorFields} />
                            <Input
                                type="text"
                                name="name"
                                value={organization.name}
                                onChange={this._onChangeNew.bind(this)}
                                label="Name"
                                placeholder="Ex: Supercorp..."
                                attributes={{
                                    'autoFocus': true
                                }} />
                            <Input
                                type="textarea"
                                name="description"
                                value={organization.description}
                                onChange={this._onChangeNew.bind(this)}
                                label="Quick description"
                                placeholder="What is it organization about?..." />
                            <Input
                                type="select"
                                name="country"
                                value={organization.country}
                                onChange={this._onChangeNew.bind(this)}
                                label="Country"
                                placeholder="Start typing country name..."
                                options={countries} />
                            <Input
                                type="select"
                                name="industry"
                                value={organization.industry}
                                onChange={this._onChangeNew.bind(this)}
                                label="Industry"
                                placeholder="Start typing industry name..."
                                options={industries} />
                            {(this.props.organizationId !== 'new') && (
                                <div>
                                    <strong>Avatar</strong>
                                    <AvatarUploader
                                        refName="orgmodal"
                                        fileType="organization"
                                        object={organization}
                                        actions={this.props.actions} />
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} isLoading={this.props.state.organization.isSaving} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(organization && organization.id) && <a onClick={this._deleteOrg.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>}
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        let organization = this.state.organization
        organization[name] = value
        this.setState({ organization: organization })
    }

    _isFormValid() {
        let organization = this.state.organization
        let formIsValid = true
        let errorFields = []
        let errors
        // Name
        errors = IsValid(organization.name, {
            required: null,
            maxLength: 40
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({ name: "Organization name", errors: errors })
        }
        // Description
        errors = IsValid(organization.description, { maxLength: 500 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({ name: "Template description", errors: errors })
        }
        this.setState({ errorFields: errorFields })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()

        if (!this._isFormValid()) {
            return false
        }
        const course = this.props.state.course.model
        let organization = this.state.organization
        organization.courseId = course.id
        this.props.actions.organization.create(this.state.organization, (organization) => {
            this.setState({ isEdited: false })
            this.props.openModal(organization.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.organization.update(this.state.organization, () => {
            // this.setState({ organization: structures.organization })
            // this.props.handleClose()
            this.setState({ isEdited: false })
        })
    }

    _deleteOrg() {
        const organization = this.state.organization
        if (confirm("Are you sure you want to delete " + organization.name + "?")) {
            this.props.actions.organization.remove(organization, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}