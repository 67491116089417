import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'

export default class Button extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        text: PropTypes.string.isRequired,
        loadingText: PropTypes.string,
        btnClass: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool
    }


    static defaultProps = {
        isLoading: false,
        loadingText: "",
        btnClass: 'btn btn-primary',
        onClick: null,
        disabled: false
    }


    render() {
        let text = this.props.text;
        let attributes = {
            className: this.props.btnClass,
            onClick: this.props.onClick
        };

        if (this.props.isLoading) {
            attributes.disabled = true;
            text = (<span><Spinner />{this.props.loadingText}</span>);
        }

        if (this.props.disabled) {
            attributes.disabled = true;
        }

        return (<button type="submit" {...attributes}>
            {text}
        </button>);
    }
}