import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import { Avatar } from 'alias-components'

export default class CourseMenu extends Component {
    static propTypes = {
        course: PropTypes.object,
        header: PropTypes.node,
        nav: PropTypes.array,
        isConfigurationPage: PropTypes.bool,
        actions: PropTypes.object.isRequired,
        user: PropTypes.object
    }

    static defaultProps = {
        course: {
            name: '',
            color: 'green'
        },
        header: null,
        nav: [],
        isConfigurationPage: false
    }

    render() {
        const { state } = this
        const { course, header, nav, isConfigurationPage, actions, user } = this.props

        return (
            <div className="config-left">
                {isConfigurationPage && <i className="fa fa-cog menu-settings" />}
                <div className="brand">
                    <Link to="/">
                        <i className="fa fa-home" />
                        <span className="live">LIVE<span className="case">CASE</span></span>
                    </Link>
                    <a onClick={() => actions.local.modalHelpOpen(course, user)} className="help">Help?</a>
                </div>

                <div className="course">
                    <Avatar size={120} user={course} />
                    <h3>{course.name}</h3>
                </div>
                {header && <div className="header">{header}</div>}
                <ul id="nav-sub">
                    {nav.map((item, i) => {
                        if (item.type === 'header') {
                            return <li key={`course-menu-${i}`} className="header">{item.name}</li>
                        }
                        if (item.type === 'help') {
                            return <li key={`course-menu-${i}`} className="help-block">{item.name}</li>
                        }
                        if (item.baseLink) {
                            // This is for the default paths
                            return (
                                <li key={`course-menu-${i}`}>
                                    <NavLink activeClassName="active"
                                        to={item.link}
                                        isActive={(match, location) => location.pathname === item.baseLink || location.pathname === item.link}
                                        className="main">
                                        {item.name}
                                    </NavLink>
                                </li>
                            )
                        }
                        return (
                            <li key={`course-menu-${i}`}>
                                <NavLink activeClassName="active"
                                    to={item.link}
                                    className="main">
                                    {item.name}
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>

            </div>
        )
    }

    _contactModal() {
        const { course, user, actions } = this.props
        actions.local.openContact(course, user)

    }
}