import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ReduxBinder from "alias-store/ReduxBinder"
import { Card, Modal, Input, Search, LMSChapter } from "alias-components"
import Fuse from 'fuse.js'
import Course from '../components/Course'




class CourseConfigurationGettingStarted extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    static defaultProps = {}

    render() {

        return (
            <div className="config-right-wrapper">
                <h2>Getting started with authoring</h2>
                <p>Here are a couple of pointers to get you started, a quick checklist of the base requirements and some stats to see if everything makes sense.</p>

                <GettingStartedSteps {...this.props} />

                <br />
                <br />
                <FAQ />
                <br />
            </div>
        )
    }


}

export default ReduxBinder(CourseConfigurationGettingStarted, {
    state: ["course"]
})


class CourseTools extends Component {
    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            isShownRoles: false,
            isShownCharacters: false,
            isShownModules: false
        }
    }
    render() {
        const { props, state } = this
        const course = props.course

        return (
            <div>
                <h3>Case authoring tools</h3>
                <Card>
                    <div>
                        <p>Here are some tools to get a quick overview of how actvities are distributed:</p>
                        <table className="table table-hover table-striped">
                            <tbody>
                                <tr>
                                    <td>Roles activity distribution</td>
                                    <td><a onClick={() => this.setState({ isShownRoles: true })}>View</a></td>
                                </tr>
                                <tr>
                                    <td>Characters activity usage</td>
                                    <td><a onClick={() => this.setState({ isShownCharacters: true })}>View</a></td>
                                </tr>
                                <tr>
                                    <td>Activities per modules</td>
                                    <td><a onClick={() => this.setState({ isShownModules: true })}>View</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>


                <Modal isShown={state.isShownRoles}
                    close={() => this.setState({ isShownRoles: false })}
                    showHeader={true}
                    title="Roles activity distribution"
                    showFooter={true}>
                    <div>
                        {state.isShownRoles && (
                            <Card>
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Roles</th>
                                            <th>Emails</th>
                                            <th>Chats</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {course.roles.map(role => {
                                            let roleActivities = course.activities.filter(a => a.roleStaticId === role.staticId)
                                            return (
                                                <tr key={role.id} className={roleActivities.length === 0 ? 'info' : ''}>
                                                    <td>{role.name}</td>
                                                    <td>{roleActivities.filter(a => a.type === 'email').length}</td>
                                                    <td>{roleActivities.filter(a => a.type === 'chat').length}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Card>
                        )}
                    </div>
                </Modal>

                <Modal isShown={state.isShownCharacters}
                    close={() => this.setState({ isShownCharacters: false })}
                    showHeader={true}
                    title="Characters activity usage"
                    showFooter={true}>
                    <div>
                        {state.isShownCharacters && (
                            <Card>
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Characters</th>
                                            <th>Emails</th>
                                            <th>Chats</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {course.characters.map(character => {
                                            let roleActivities = course.activities.filter(a => a.characterStaticId === character.staticId)
                                            return (
                                                <tr key={character.id} className={roleActivities.length === 0 ? 'info' : ''}>
                                                    <td>{character.first} {character.last}</td>
                                                    <td>{roleActivities.filter(a => a.type === 'email').length}</td>
                                                    <td>{roleActivities.filter(a => a.type === 'chat').length}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Card>
                        )}
                    </div>
                </Modal>


                <Modal isShown={state.isShownModules}
                    close={() => this.setState({ isShownModules: false })}
                    showHeader={true}
                    title="Activities per modules"
                    showFooter={true}>
                    <div>
                        {state.isShownModules && (
                            <div>
                                {course.modules.map(mod => {
                                    return (
                                        <Card title={mod.name} key={mod.id}>
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Role</th>
                                                        <th>Emails</th>
                                                        <th>Chats</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {course.roles.map(role => {
                                                        let roleActivities = course.activities.map(a => {
                                                            if (a.parentStaticId) {
                                                                let parent = course.byStaticId.activities[a.parentStaticId]
                                                                if (parent) {
                                                                    a.moduleStaticId = parent.moduleStaticId
                                                                }
                                                            }
                                                            return a
                                                        }).filter(a => a.roleStaticId === role.staticId && a.moduleStaticId === mod.staticId)
                                                        return (
                                                            <tr key={role.id} className={roleActivities.length === 0 ? 'info' : ''}>
                                                                <td>{role.name}</td>
                                                                <td>{roleActivities.filter(a => a.type === 'email').length}</td>
                                                                <td>{roleActivities.filter(a => a.type === 'chat').length}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </Card>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </Modal>

            </div>
        )
    }
}


class GettingStartedSteps extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            course: props.state.course.model,
            modalForms: false,
            modalValues: false,
            modalBadges: false,
            moduleName: ''
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            course: newProps.state.course.model
        })
    }


    render() {
        const { props, state } = this
        const course = state.course
        const gettingStartedStep = {
            about: false,
            time: false,
            extras: false,
            next: false
        }

        let gettingStarted = {
            ...gettingStartedStep,
            ...course.gettingStarted
        }


        let steps = [
            {
                id: 'about',
                title: "What is this case about?",
                content: (
                    <div>
                        <p>We recommend starting with the teacher notes. It usually helps writing it out to map out what you are going to do.</p>
                        <p className="help-block">This is just to help you get started, find a more complete section in the <strong>Info</strong> page on the left</p>
                        {!gettingStarted.about && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please enter some teaching notes below</div>}

                        <LMSChapter isInEditMode={true}
                            value={course.teachers}
                            isEditable={true}
                            onChange={(value) => this._onChange('teachers', value)}
                        />
                        <br />
                        <button type="submit" className="btn btn-primary"
                            onClick={(e) => this._saveCourse(e, 'about')}>Save & next
                        </button>
                    </div>
                ),
                isExpanded: !gettingStarted.about,
                isComplete: gettingStarted.about
            },
            {
                id: 'time',
                title: "Time reference",
                content: (
                    <div>
                        <p>A case needs a starting "reference" time. It will help you map out your content and can be changed at any time in the Info page in left.</p>
                        <p>For example, your case could start next Monday at 9AM or if you do not know yet, you could just assume it will start at 10AM and pick a random date to change when you are ready.</p>
                        <p className="help-block">All of your content (Modules, Activities, ...) is nested based on this date/time with <strong>offsets</strong>. This allows you to duplicate your case later and use it again by just changing start date/time.<br /></p>
                        <form onSubmit={this._saveCourse.bind(this)}>
                            {!gettingStarted.time && <div className="alert alert-warning"><i className="fa fa-arrow-down" />Please pick a reference or actual start date/time below</div>}
                            <Input type="dateTime"
                                name="start"
                                placeholder="Course start date/time..."
                                value={course.start}
                                timezone={course.timezone}
                                onChange={this._onChange.bind(this)} />
                            <br />
                            <button type="submit" className="btn btn-primary"
                                onClick={(e) => this._saveCourse(e, 'time')}>Save & next
                            </button>
                        </form>
                    </div>
                ),
                isExpanded: gettingStarted.about && !gettingStarted.time,
                isComplete: gettingStarted.time
            },
            {
                id: 'role',
                title: "Create a role",
                content: (
                    <div>
                        <p>
                            <strong>What are roles?</strong> Roles are assigned to participants and allow you to send different information to different participants.<br />
                            For example, you could have a class where you have CEOs, COOs, CFOs, ... these would be considered roles. The different positions would receive different information.
                        </p>
                        <p>You are required to at least create 1 role, which you can assign to everyone in your class, or several</p>
                        {course.roles.length === 0 && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please create a role by clicking the button below</div>}
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.roles.map(role => {
                                    return (
                                        <tr key={role.id}>
                                            <td>
                                                <strong>{role.name}</strong>
                                                {role.isVisibleByParticipant ? <span className="badge info" style={{ marginLeft: 10 }}>Visible</span> : <span className="badge" style={{ marginLeft: 10 }}>IN-VISIBLE</span>}
                                                {(role.isVisibleByParticipant && role.isSelectableByParticipant) ? <span className="badge info" style={{ marginLeft: 10 }}>Selectable</span> : <span className="badge" style={{ marginLeft: 10 }}>NOT selectable</span>}
                                                <p className="help-block">{role.details}</p>
                                            </td>
                                            <td width={20}>
                                                <a onClick={() => this.props.actions.local.modal('role', role.id)}><i className="fa fa-pencil"></i></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <a className="btn btn-primary" onClick={() => this.props.actions.local.modal('role', 'new')}>Create a role</a>
                        <p className="help-block">Find this again later in the left menu <strong>Elements</strong>, then <strong>Roles</strong> tab</p>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length === 0,
                isComplete: course.roles.length > 0
            },
            {
                id: 'character',
                title: "Create a character",
                content: (
                    <div>
                        <p>
                            <strong>What are characters?</strong> Characters are used to send emails and chats to communicate with participants.<br />
                            For example, you could have Jane Doe, head of Sales at FactoryX and/or John Smith, head of Sourcing at TechX.
                        </p>
                        <p>You are required to create at least 1 character.</p>
                        {course.characters.length === 0 && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please create a character by clicking the button below</div>}
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.characters.map(character => {
                                    return (
                                        <tr key={character.id}>
                                            <td>
                                                <strong>{character.first} {character.last}</strong>
                                            </td>
                                            <td width={20}>
                                                <a onClick={() => this.props.actions.local.modal('character', character.id)}><i className="fa fa-pencil"></i></a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <a className="btn btn-primary" onClick={() => this.props.actions.local.modal('character', 'new')}>Create a character</a>
                        <p className="help-block">Find this again later in the left menu <strong>Elements</strong>, then <strong>Characters</strong> tab</p>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length > 0 && course.characters.length === 0,
                isComplete: course.characters.length > 0
            },
            {
                id: 'module',
                title: "Create a first module",
                content: (
                    <div>
                        <p>
                            <strong>What is a module?</strong> Modules allow you cut the class into topics and/or timeslots.<br />
                            For example, you could have module 1: Introduction (Monday 9:00am), module 2: Negociation (Monday 9:30am),...
                        </p>
                        <p>You are required to at least create 1 module.</p>
                        {course.modules.length === 0 && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please create a module by giving it a name and clicking the button below</div>}
                        <table className="table table-striped table-hover">
                            <tbody>
                                {course.modules.map(module => {
                                    return (
                                        <tr key={module.id}>
                                            <td>
                                                <strong>{module.name}</strong>
                                            </td>
                                            <td width={20}>
                                                <Link to={`/course/configuration/${course.id}/module/${module.staticId}`}><i className="fa fa-pencil" /></Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <form onSubmit={this._createModule.bind(this)}>
                            <Input type="text"
                                onChange={(name, value) => this.setState({ moduleName: value })}
                                value={state.moduleName}
                                placeholder="Enter a module name..." />
                            <button type="submit" className="btn btn-primary">Create module</button>
                        </form>
                        <p className="help-block">You can create another module in the left menu by clicking "+ Add" next to the title <strong>Modules</strong>.<br />
                            You can also edit the module you created by clicking it's name in the left menu and then selected the <strong>Info</strong> tab.
                        </p>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length > 0 && course.characters.length > 0 && course.modules.length === 0,
                isComplete: course.modules.length > 0
            },
            {
                id: 'activities',
                title: "Activities",
                content: (
                    <div>
                        <p>
                            <strong>What are activities?</strong> Activities are where the fun happens. This is where you create emails, chat, ... and write the case content.<br />
                            Activities are organized in the modules. Here is a list of activities:
                        </p>
                        <ul>
                            <li><strong>Email</strong>: Will send an email to the participants LIVE<strong>CASE</strong> mailbox on behalf of a character that you created before.</li>
                            <li><strong>Chat</strong>: Similar to email but in chat form. Please note that emails can only use conversational form for a better participant experience.</li>
                            <li><strong>Calendar</strong>: Will add a calendar event on the participants calendar on their homepage. Useful for planned lectures, reviews, ...</li>
                            <li><strong>Role plays</strong>: Enables you to organize role plays for the participants (including time, location and who)</li>
                            <li><strong>Forum post</strong>: Will create a post on the case forum page for the class to see. Useful for discussions & updates.</li>
                        </ul>
                        {course.modules.length === 0 && <div className="alert alert-info">Please create a module first</div>}
                        {course.activities.filter(a => a.parentStaticId).length === 0 && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please create an actibity by clicking the button below</div>}
                        {course.modules.length > 0 && (
                            <div>
                                {course.modules.map((module, i) => {
                                    if (i > 0) { return }
                                    let parentActivity = null
                                    let potentialParentActivity = course.activities.filter(a => a.moduleStaticId === module.staticId).sort((a, b) => a.offset - b.offset)
                                    if (potentialParentActivity.length > 0) {
                                        parentActivity = potentialParentActivity[0]
                                    }
                                    return (
                                        <div key={`mod-${module.id}`}>
                                            <h4>Module: {module.name}</h4>
                                            {parentActivity && <a onClick={() => props.actions.local.openModalActivity('new', null, parentActivity.staticId)} className="btn btn-primary">Create an activity</a>}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <p className="help-block">Find this again later in the left menu under <strong>Modules</strong>, select a module, then <strong>Activities</strong> tab</p>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length > 0 && course.characters.length > 0 && course.modules.length > 0 && course.activities.filter(a => a.parentStaticId).length === 0,
                isComplete: course.activities.filter(a => a.parentStaticId).length > 0
            },
            {
                id: 'form',
                title: "Create a form",
                content: (
                    <div>
                        <p>
                            <strong>What are forms?</strong> Create forms, aka quizzes, for participants to answer questions (multiple-choice, text, file uploads, ...). <a onClick={() => this.setState({ modalForms: true })} style={{ display: 'none' }}>Find out more <i className="fa fa-external-link" /></a>
                        </p>
                        {course.forms.length === 0 && <div className="alert alert-warning"><i className="fa fa-arrow-down" /> Please create a form by clicking the button below</div>}
                        {course.forms.length > 0 && (
                            <div>
                                {course.forms.map((form, i) => {
                                    if (i > 0) { return }
                                    return (
                                        <div key={`form-${form.id}`}>
                                            <h4>{form.name}</h4>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <a className="btn btn-primary" onClick={() => this.props.actions.local.modal('form', 'new')}>Create a form</a>
                        <p className="help-block">Find this again later in the left menu <strong>Elements</strong>, then <strong>Forms</strong> tab</p>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length > 0 && course.characters.length > 0 && course.modules.length > 0 && course.activities.filter(a => a.parentStaticId).length > 0 && course.forms.length === 0,
                isComplete: course.forms.length > 0
            },
            {
                id: 'extras',
                title: "Extra features",
                content: (
                    <div>
                        <p>Don't forget about the extras!</p>
                        <table className="table table-hover table-striped">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Scoring</strong><br />
                                        As you create forms and add questions, you will have the option to add scoring to these questions to give participants points and see how well they are doing.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Values</strong><br />
                                        Values allow you to define variables that are impacted when forms are answered. <a onClick={() => this.setState({ modalValues: true })} style={{ display: 'none' }}>Find out more <i className="fa fa-external-link" /></a>

                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <strong>Badges</strong><br />
                                        Create different story lines depending on the participants answers to form questions.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Tags</strong><br />
                                        Add scoring for competencies or abilities. Similar to scoring, you can add tags to questions and scoring.<br />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-primary"
                            onClick={(e) => this._saveCourse(e, 'extras')}>I understand
                        </button>
                    </div>
                ),
                isExpanded: gettingStarted.about && gettingStarted.time && course.roles.length > 0 && course.characters.length > 0 && course.modules.length > 0 && course.activities.filter(a => a.parentStaticId).length > 0 && course.forms.length > 0,
                isComplete: gettingStarted.extras
            }
        ]

        let percentage = Math.floor((steps.filter(step => step.isComplete).length) / steps.length * 100)

        const courseCheck = Course.Tools.validate(course)

        return (
            <div>

                {percentage === 100 && (
                    <div>
                        <div className="alert alert-success">
                            <h4>Congratulations!</h4>
                            <p>You have completed all of the getting started steps.</p>
                            <p>Please see below for frequently asked questions and answers.</p>
                        </div>
                        <h3>What next?</h3>
                        <p>You have been introduced the main features of the platform.<br />
                            Feel free to click around and write the content you want for your LIVE<strong>CASE</strong>
                        </p>
                        <br />
                        <Card title="Overall checklist">
                            <ul className="list-unstyled" style={{ fontSize: '16px', lineHeight: '30px', width: '100%' }}>
                                {courseCheck.data.map(model => {
                                    return (
                                        <li key={model.modelName} style={{ width: '100%' }}>
                                            <i className={`fa fa-${model.count.errors === 0 ? 'check green' : 'times red'} fa-fw`} style={{ marginRight: '8px' }} />
                                            {model.counter} {model.modelName}
                                            {model.count.errors > 0 ? ` (${model.count.errors} error${model.count.errors > 1 ? 's' : ''}) ` : ""}
                                            {model.count.warnings > 0 ? ` (${model.count.warnings} warning${model.count.warnings > 1 ? 's' : ''}) ` : ""}
                                            {model.count.info > 0 ? ` (${model.count.info} info) ` : ""}

                                            {model.data.length > 0 && (
                                                <table className="table table-hover" style={{ padding: '5px 25px', paddingRight: 0, width: '100%' }}>
                                                    <tbody>
                                                        {model.data.map((info, i) => {
                                                            return (
                                                                <tr className={info.level === 'critical' ? 'danger' : info.level === 'info' ? 'info' : 'warning'} key={model.modelName + i}>
                                                                    <td>
                                                                        <i className={`fa fa-${info.level === 'critical' ? 'times' : info.level === 'info' ? 'bullhorn' : 'warning'}`}></i>&nbsp;&nbsp;{info.message}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>
                        </Card>
                        <br />
                        <CourseTools course={course} />
                    </div>
                )}


                {percentage < 100 && (
                    <div>
                        <strong>Progress</strong>
                        <div className="progress">
                            <div className={`progress-bar ${percentage == 100 ? 'progress-bar-success' : 'progress-bar-info'}`} style={{ minWidth: '3em', width: percentage + '%' }}>
                                {`${percentage}%`}
                            </div>
                        </div>
                        {steps.map((step, i) => {
                            return (
                                <Expandable key={`step-${step.id}`}
                                    title={`Step ${i + 1} > ${step.title}`}
                                    isExpanded={step.isExpanded}
                                    isComplete={step.isComplete}
                                    showIsComplete={true}>
                                    {step.content}
                                </Expandable>
                            )
                        })}
                    </div>
                )}


                <Modal isShown={state.modalForms}
                    close={() => this.setState({ modalForms: false })}
                    showHeader={true}
                    title="What are forms?"
                    showFooter={true}>
                    <div>
                        <p>
                            Forms are the corner stone of the participants journey. <br />
                            Create forms for participants to answer questions pertinent to your case.<br />
                            Questions can be:
                        </p>
                        <ul>
                            <li>Multiple choice</li>
                            <li>Text</li>
                            <li>Numbers</li>
                            <li>File upload</li>
                            <li>Numbered range (ex: 50-100)</li>
                            <li>Scoring</li>
                        </ul>
                        <p>See below for an example basic form.</p>
                        <p className="alert alert-danger">IMAGE here</p>

                        <p>Forms can impact the participants/teams <strong>score, values, tags and can even create different story paths</strong> depending on what they answer.</p>

                        <p>You can link to these forms or, prefered method, embed these forms in emails, chats and role plays as a plain form, or as a conversational form, where the next question only appears after the previous one has been answered by the participant.</p>
                        <strong>Example of a linked form (for EMAIL only)</strong>
                        <p>
                            A linked form is simply a link to a form page. Anyone logged in and part of the case with the link can answer the form. <br />
                            It is usually only used when a form needs to be answered several times (ex a participant needs to score more than one person).<br />
                            If you plan on duplicating your case, you SHOULD use this format to attach a form as it will update the link automatically unlike a copy and paste.
                        </p>
                        <p>Here is what it looks like:</p>

                        <p className="alert alert-danger">IMAGE here</p>
                        <strong>Example of an embedded form (for EMAIL and/or FORUMS only)</strong>

                        <p>An embedded form will display the form at the end of the email for the participant to answer.<br />
                            See below for an example of what it will look like
                        </p>
                        <p className="alert alert-danger">IMAGE here</p>

                        <strong>Example of a conversational form (for EMAIL and/or CHAT only)</strong>
                        <p className="alert alert-danger">IMAGE here</p>
                        <p>The conversational forms really bring the dialogue to the next level from participants point of view.</p>

                    </div>
                </Modal>

                <Modal isShown={state.modalValues}
                    close={() => this.setState({ modalValues: false })}
                    showHeader={true}
                    title="What are values?"
                    showFooter={true}>
                    <div>
                        <p>
                            Values are variables that you set with an initial value.<br />
                            This value can be impacted as the participant answers questions in the forms.
                        </p>
                        <p>Ex: You can create a value called "Profit" with a starting value of $1000, and depending on how the participant answers, have it increase or decrease. The participant will have a chart</p>
                        <p className="alert alert-danger">IMAGE here</p>
                    </div>
                </Modal>

            </div>
        )
    }


    _onChange(attributeName, value) {
        var course = this.state.course
        course[attributeName] = value
        this.setState({
            course: course
        })
    }
    _saveCourse(e, type) {
        if (e) {
            e.preventDefault()
        }

        let course = this.state.course
        if (type) {
            course.gettingStarted[type] = true
        }
        this.props.actions.course.update(course)
    }

    _createModule(e) {
        if (e) { e.preventDefault() }
        const { state } = this
        let module = {
            courseId: state.course.id,
            offset: 0,
            name: state.moduleName,
            isDraft: false
        }
        this.props.actions.module.create(module, (newModule) => {
            this.props.actions.activity.create({
                courseId: state.course.id,
                name: 'Main group',
                moduleStaticId: newModule.staticId,
                offset: 0
            }, () => {
                // Callback
            })
        })
    }
}



class FAQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            shown: 6
        }
    }
    render() {
        const { state } = this

        let faqs = [
            {
                title: "How do I ask for assistance?",
                content: <div>
                    <p>Simply click the <strong>Help?</strong> button on the top left of the menu and ask for assistance, we'll get back in touch with you as soon as possible.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I add another author?",
                content: <div>
                    <p>Go to <strong>Info</strong> page in the left menu, then select the <strong>Authors</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "What start time should I put?",
                content: <div>
                    <p>Ideally this would be the actual start time of your case. Keep in mind it is very simple to change later.</p>
                    <p>If you do not know when you will use the case, pick a date a little in the future at the time you will most likely start it.</p>
                    <p>For example, you are not sure when you will run it, but it will most likely be a 9AM class. Pick a couple of days ahead at 9AM.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new role?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Roles</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new character?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Characters</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new form?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Forms</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new value?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Values</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new Tags?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Tags</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How do I create/edit/manage a new badges?",
                content: <div>
                    <p>Go to <strong>Components</strong> page in the left menu, then select the <strong>Badges</strong> tab on the top of the page.</p>
                </div>,
                search: ""
            },
            {
                title: "How does the scheduling for modules and activities work?",
                content: <div>
                    <p>It all starts with the course start date/time. All the rest, ie Modules and Activities, will have an offset from that date/time.</p>
                    <p>For example:</p>
                    <ul>
                        <li>Your case starts at 9AM</li>
                        <li>You set your first module to start 10mins after your introduction, so it has an offset of 10mins and will launch at 9:10AM</li>
                        <li>Activities are nested inside the module, so if an activity has an offset of 0mins, it will be sent at 9:10AM (same as module). If it has a 5mins offset, it be sent at 9:15AM (case starts at 9AM, Module offset of 10mins, and activity offset of 5mins, so 9AM + 10mins + 5mins)</li>
                    </ul>
                </div>,
                search: ""
            },
            {
                title: "An activity is not showing to participants?",
                content: <div>
                    <p>If this happens, the most likely scenarios to check are:</p>
                    <ul>
                        <li>Check that the module is not "Drafted" by going to the Module, then selecting "Info" in the top navigation of that page</li>
                        <li>Check that the activity is no "Drafted"</li>
                        <li>Check that the scheduling is right (ie that the time it should have been sent is before NOW)</li>
                    </ul>
                </div>,
                search: ""
            },
            {
                title: "How can I test my case?",
                content: <div>
                    <p>To get a quick glance, you can use the <strong>Activities</strong> view in the modules. <br />
                        If you want to go through the whole course like a participant, you can:
                    </p>
                    <ol>
                        <li>Click "Preview case" in the left menu</li>
                        <li>Add yourself as a participant in the participants page using your email</li>
                        <li>Assign yourself 1 or more roles</li>
                        <li>Make sure the start time is right for your test in the <strong>Scheduling</strong> page</li>
                        <li>Go to <strong>Getting started</strong> page for the instructor, make sure everything is ok.</li>
                        <li>Click "Launch case" in the Getting started page.</li>
                    </ol>
                </div>,
                search: ""
            },
            {
                title: "How many characters should I create?",
                content: <div>
                    <p>That will depend mostly on your content, but, to keep participants more immerged, we recommend at least 4 characters that they can interact with.</p>
                </div>,
                search: ""
            }
        ]

        if (state.search) {
            const options = {
                keys: ["title", "search"],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.5
            }
            let fuse = new Fuse(faqs, options)
            faqs = fuse.search(state.search)
        }
        let hasMore = false
        if (faqs.length > state.shown) {
            hasMore = true
            faqs = faqs.slice(0, state.shown)
        }
        return <div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <h2>FAQ</h2>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Search search={state.search}
                        onChange={(search) => {
                            this.setState({ search: search, shown: 6 })
                        }} />
                </div>
            </div>


            {faqs.map((aq, i) => {
                return <Expandable key={`qa-${i}`}
                    title={`Q: ${aq.title}`}
                    isExpanded={false}
                    showIsComplete={false}>
                    {aq.content}
                </Expandable>
            })}
            {hasMore && <div className="text-centered">
                <a className="btn btn-secondary" onClick={() => this.setState({ shown: state.shown + 10 })}>Show more</a>
            </div>}
        </div>
    }
}


class Expandable extends Component {
    static propTypes = {
        isExpanded: PropTypes.bool,
        title: PropTypes.string,
        showIsComplete: PropTypes.bool,
        isComplete: PropTypes.bool
    }
    static defaultProps = {
        isExpanded: false
    }
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: props.isExpanded,
            isHovered: false
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            isExpanded: newProps.isExpanded
        })
    }
    render() {
        const { props, state } = this

        let styleElements = {}
        if (state.isHovered) {
            styleElements.cursor = 'pointer'
        }
        if (!state.isExpanded) {
            styleElements.marginBottom = 0
        }


        return <Card>
            <h3 onClick={() => this.setState({ isExpanded: !state.isExpanded })}
                onMouseEnter={() => this.setState({ isHovered: true })}
                onMouseLeave={() => this.setState({ isHovered: false })}
                style={styleElements}>
                {props.title}
                {(props.isComplete && props.title !== "Checklist" && props.showIsComplete) && <i className="fa fa-check green pull-right" />}
            </h3>

            {state.isExpanded && <div>
                {props.children}
            </div>}
        </Card>
    }
}