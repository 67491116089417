import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Input } from 'alias-components'
import { Modal } from 'react-bootstrap'
import Email from './elements/Email'
import ActivityTypeCalendar from './elements/ActivityTypeCalendar'
import ActivityChat from './elements/ActivityChat'
import ActivityRolePlay from './elements/ActivityRolePlay'
import ActivityForum from './elements/ActivityForum'
import { activity as initialState } from 'alias-store/structures'
import ReduxBinder from 'alias-store/ReduxBinder'
import moment from 'moment-timezone'


const activityTypes = [
    {
        label: 'Email',
        value: 'email'
    },
    {
        label: 'Chat',
        value: 'chat'
    },
    {
        label: 'Role play',
        value: 'rolePlay'
    },
    {
        label: 'Calendar event',
        value: 'calendar'
    },
    {
        label: 'Forum post',
        value: 'forum'
    }
]

class ActivityModal extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        handleClose: PropTypes.func,
        openModal: PropTypes.func,
        openFormModal: PropTypes.func,
        openPreview: PropTypes.func,

        activityId: PropTypes.string,
        moduleStaticId: PropTypes.string,
        parentStaticId: PropTypes.string,

        state: PropTypes.object,
        actions: PropTypes.object
    }

    _getState(props) {
        const activityId = props.activityId
        const course = props.state.course.model
        let activity = null

        if (activityId === 'new') {
            activity = JSON.parse(JSON.stringify(initialState))
            activity.courseId = course.id
            if (props.parentStaticId) {
                activity.parentStaticId = props.parentStaticId
            } else {
                if (props.moduleStaticId) {
                    activity.moduleStaticId = props.moduleStaticId
                }
            }
        } else if (activityId !== null && course.byId && course.byId.activities) {
            activity = course.byId.activities[activityId]
        }


        return {
            activity: activity,
            currentActivityIsEdited: false,
            isSaving: false
        }
    }

    constructor(props) {
        super(props)
        this.state = this._getState(props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.activityId !== this.props.activityId && this.state.currentActivityIsEdited === false) {
            this.setState(this._getState(newProps))
        }
    }

    render() {
        const { props } = this
        let { activity, isSaving } = this.state
        const course = this.props.state.course.model
        const isNew = (activity && !activity.id)
        const type = activity && activity.parentStaticId ? 'activity' : 'group'


        let lineageOffset = 0
        let activityDocs = []
        let badgesOptions = [
            { label: 'None', value: '' }
        ]
        if (activity) {
            activityDocs = course.docs.filter(doc => {
                return doc.activityStaticId == activity.staticId
            })
            if (activity.parentStaticId) {
                let parentActivity = course.byStaticId.activities[activity.parentStaticId]
                if (parentActivity) {
                    lineageOffset += parentActivity.offset
                    if (parentActivity.moduleStaticId) {
                        let module = course.byStaticId.modules[parentActivity.moduleStaticId]
                        if (module) {
                            lineageOffset += module.offset
                        }
                    }
                }
            } else {
                if (activity.moduleStaticId) {
                    let module = course.byStaticId.modules[activity.moduleStaticId]
                    if (module) {
                        lineageOffset += module.offset
                    }
                }
            }

            course.badges.map(badge => {
                badgesOptions.push({ label: badge.name, value: badge.staticId })
            })
        }



        return <Modal show={props.isOpen} onHide={this._handleClose.bind(this)}>
            <Modal.Header>
                <Modal.Title>{(!activity || isNew) ? `Create a new ${type}` : activity.name}</Modal.Title>
            </Modal.Header>
            {(props.isOpen && activity) && <form onSubmit={this._onSave.bind(this)}>
                <Modal.Body>
                    <h3>Info</h3>
                    <Card>
                        <div className="form-horizontal">
                            {type === 'group' && (
                                <div className="form-group">
                                    <label className="control-label col-sm-3">Module</label>
                                    <div className="col-sm-9">
                                        <Input
                                            value={activity.moduleStaticId || ''}
                                            type="select"
                                            name="moduleStaticId"
                                            placeholder="Select a module"
                                            onChange={this._onChangeAttribute.bind(this)}
                                            options={course.modules.sort((a, b) => {
                                                return a.offset - b.offset
                                            }).map(module => {
                                                return {
                                                    label: module.name,
                                                    value: module.staticId
                                                }
                                            })} />
                                    </div>
                                </div>
                            )}
                            {type === 'activity' && <div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Group</label>
                                    <div className="col-sm-9">
                                        <Input
                                            value={activity.parentStaticId}
                                            type="select"
                                            name="parentStaticId"
                                            placeholder="Select a group"
                                            onChange={this._onChangeAttribute.bind(this)}
                                            options={course.modules.sort((a, b) => {
                                                return a.offset - b.offset
                                            }).map(module => {
                                                return {
                                                    label: module.name,
                                                    options: course.activities
                                                        .filter(act => !act.parentStaticId && act.moduleStaticId && act.moduleStaticId === module.staticId && act.type !== 'triggered-email')
                                                        .sort((a, b) => a.offset - b.offset)
                                                        .map(act => {
                                                            // let module = course.byStaticId.modules[act.moduleStaticId] || { name: 'Module not found', offset: 0 }
                                                            return {
                                                                label: act.name, // `${module.name} - ${act.name}`,
                                                                value: act.staticId
                                                            }
                                                        })
                                                }
                                            })} />
                                    </div>
                                </div>
                            </div>}

                            <div className="form-group">
                                <label className="control-label col-sm-3">Name</label>
                                <div className="col-sm-9">
                                    <Input
                                        name="name"
                                        value={activity.name}
                                        onChange={this._onChangeAttribute.bind(this)}
                                        placeholder="Enter a name for activity"
                                        attributes={{ autoFocus: true }} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="control-label col-sm-3">Send time</label>
                                <div className="col-sm-9">
                                    <Input
                                        type="dateTime"
                                        value={moment(course.start).tz(course.timezone).add(lineageOffset, 'm').add(activity.offset, 'm').format()}
                                        timezone={course.timezone}
                                        disabled={false}
                                        onChange={(name, value) => {
                                            let lineageStart = moment(course.start).tz(course.timezone).add(lineageOffset, 'm')
                                            let difference = moment(value).tz(course.timezone).diff(lineageStart, 'minutes')
                                            this._onChangeAttribute('offset', difference > 0 ? difference : 0)
                                        }} />
                                </div>
                            </div>



                            {type === 'activity' && <div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Type</label>
                                    <div className="col-sm-9">
                                        <Input
                                            value={activity.type}
                                            type="select"
                                            name="type"
                                            placeholder="Select type of activity"
                                            onChange={this._onChangeAttribute.bind(this)}
                                            options={activityTypes} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label"></label>
                                    <div className="col-sm-9">
                                        <Input type="bool"
                                            label="Is this a draft?"
                                            description="Making the activity a draft will prevent students from viewing it."
                                            value={activity.isDraft}
                                            onChange={() => this._onActivityUpdate({ isDraft: !activity.isDraft })} />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </Card>



                    {activity.id && <div>

                        {['email', 'chat'].indexOf(activity.type) !== -1 && <div>
                            <h3>Badge visibility</h3>
                            <Card>
                                <Input type="select"
                                    label="Only show this if the team/participant has a badge"
                                    value={activity.badgeStaticId}
                                    options={badgesOptions}
                                    onChange={(name, value) => this._onActivityUpdate({ badgeStaticId: value })} />
                            </Card>
                        </div>}



                        {activity.type === 'email' && <div>
                            <h3>Email</h3>
                            <p className="help-block"><i className="fa fa-info" /> Interact with your participants through a messaging system, similar to email.</p>
                            <Card>
                                <Email activity={activity}
                                    docs={activityDocs}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onChange={this._onChangeAttribute.bind(this)} />
                            </Card>
                        </div>}

                        {activity.type === 'forum' && <div>
                            <h3>Forum post</h3>
                            <p className="help-block"><i className="fa fa-info" /> Interact with your participants through the class form which is visible by everyone in the class.</p>
                            <Card>
                                <ActivityForum activity={activity}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onChange={this._onChangeAttribute.bind(this)} />
                            </Card>
                        </div>}

                        {['email', 'forum'].indexOf(activity.type) !== -1 && <div>
                            <h3>Attach a form</h3>
                            <p className="help-block">
                                <i className="fa fa-info" /> Attach a form at the end of the {activity.type} for the participant to answer.<br />
                            </p>
                            <Card>
                                <div className="row">
                                    <div className="col-xs-12 col-xs-8">
                                        <Input type="select"
                                            options={course.forms.map(form => {
                                                return {
                                                    label: form.name,
                                                    value: form.staticId
                                                }
                                            })}
                                            placeholder="Select a form (Optional)"
                                            label="Embedded form"
                                            ref="form"
                                            name="embeddedFormStaticId"
                                            value={activity.embeddedFormStaticId}
                                            onChange={this._onChangeAttribute.bind(this)} />
                                        {activity.embeddedFormStaticId && <a onClick={this._openFormModal.bind(this, course.byStaticId.forms[activity.embeddedFormStaticId].id)}>Edit this form</a>}
                                    </div>
                                    <div className="col-xs-12 col-xs-4">
                                        <br />
                                        <a onClick={this._openFormModal.bind(this, 'new')} className="btn btn-link">Create new form</a>
                                    </div>
                                </div>

                            </Card>
                        </div>}


                        {activity.type === 'chat' && <div>
                            <h3>Chat</h3>
                            <p className="help-block"><i className="fa fa-info" /> Interact with your participants through a messaging system, similar to a chat.</p>
                            <Card>
                                <ActivityChat activity={activity}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onChange={this._onChangeAttribute.bind(this)} />
                            </Card>
                        </div>}

                        {(!isNew && (activity.type === 'email' || activity.type === 'chat')) && <div>
                            <h3>Conversational form</h3>
                            <p className="help-block">
                                <i className="fa fa-info" /> You can attach a form into an email/chat that will transform it into a conversation where each question shows up one after the other.
                            </p>
                            <Card>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-8">
                                        <Input type="select"
                                            options={course.forms.map(form => {
                                                return {
                                                    label: form.name,
                                                    value: form.staticId
                                                }
                                            })}
                                            placeholder="Select a form (Optional)"
                                            label="Conversational form"
                                            ref="form"
                                            name="formStaticId"
                                            value={activity.formStaticId}
                                            onChange={this._onChangeAttribute.bind(this)} />
                                        {activity.formStaticId && <a onClick={() => this.props.openFormModal(course.forms.filter(form => form.staticId === activity.formStaticId)[0].id)}>Edit this form</a>}
                                    </div>
                                    <div className="col-xs-12 col-sm-4">
                                        <br />
                                        <a onClick={this._openFormModal.bind(this, 'new')} className="btn btn-link">Create new form</a>
                                    </div>
                                </div>

                            </Card>
                        </div>}


                        {activity.type === 'rolePlay' && <div>
                            <h3>Role play</h3>
                            <p className="help-block"><i className="fa fa-info" /> Set up role plays between participants or teams.</p>
                            <ActivityRolePlay activity={activity}
                                state={this.props.state}
                                actions={this.props.actions}
                                onActivityUpdate={this._onActivityUpdate.bind(this)} />

                            <h3>Email notification</h3>
                            <p className="help-block"><i className="fa fa-info" /> Send the participants an email when the role play starts.</p>
                            <Card>
                                <Email activity={activity}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onChange={this._onChangeAttribute.bind(this)}
                                    type="rolePlay" />
                            </Card>
                        </div>}



                        {activity.type === 'calendar' && <div>
                            <h3>Calendar event</h3>
                            {/*<p className="help-block"><i className="fa fa-info" /> .</p>*/}
                            <Card>
                                <ActivityTypeCalendar activity={activity}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onActivityUpdate={this._onActivityUpdate.bind(this)} />
                            </Card>
                        </div>}



                        {(false && !isNew && activity.type === 'email') && <div>
                            <h3>Previous responses</h3>
                            <p className="help-block">Previous responses can help you answer emails faster and remember it for the next course. They will automatically be added when you answer emails during the course.</p>
                            <Card>
                                {(!activity.data.previousResponses || (activity.data.previousResponses && activity.data.previousResponses.length === 0)) && <p className="help-block">No canned responses found.</p>}
                                {activity.data.previousResponses && <table className="table table-stripped table-hover">
                                    <tbody>
                                        {activity.data.previousResponses.map((response, rI) => <tr key={rI}>
                                            <td><p>{response}</p></td>
                                            <td><a onClick={this._removeCannedResponse.bind(this, rI)} className="red"><i className="fa fa-trash" /></a></td>
                                        </tr>)}
                                    </tbody>
                                </table>}
                            </Card>
                        </div>}
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>}

                </Modal.Body>
                <Modal.Footer>
                    <Button text={isNew ? "Create" : "Save"} isLoading={isSaving} />
                    <a onClick={this._handleClose.bind(this)} className="btn btn-default">Close</a>
                    {(!isNew && type === 'activity') && <a onClick={this._clone.bind(this)} className="btn btn-default" style={{ marginLeft: '4px' }}>Clone activity</a>}
                    {(!isNew && type === 'activity' && activity.type !== 'forum') && <a onClick={() => props.openPreview(activity.id)} className="btn btn-default" style={{ marginLeft: '4px' }}><i className="fa fa-eye" /> Preview</a>}
                    {!isNew && <a onClick={this._onRemove.bind(this)} className="btn btn-danger" style={{ marginLeft: '4px' }}>Delete</a>}
                </Modal.Footer>
            </form>}
        </Modal>
    }

    _openFormModal(formId) {
        this.props.actions.local.modal('form', formId)
    }



    _onActivityUpdate(updatedActivity) {
        let activity = { ...this.state.activity, ...updatedActivity }
        this.setState({
            activity: activity,
            currentActivityIsEdited: true
        })
    }

    _onChangeAttribute(name, value) {
        let activity = this.state.activity
        activity[name] = value
        this.setState({
            activity: activity,
            currentActivityIsEdited: true
        })
    }

    _onChange(componentReturn) {
        var activity = this.state.activity

        if (componentReturn) {
            switch (componentReturn.from) {
                case 'email':
                    activity.roleStaticId = componentReturn.content.roleStaticId
                    activity.characterStaticId = componentReturn.content.characterStaticId
                    activity.subject = componentReturn.content.subject
                    activity.body = componentReturn.content.body
                    activity.data = componentReturn.content.data
                    break
            }
        }
        this.setState({
            activity: activity,
            currentActivityIsEdited: true
        })
    }

    _onSave(e) {
        e.preventDefault()
        let activity = this.state.activity
        this.setState({ isSaving: true })
        // const course = this.props.state.course.model
        // let moduleStaticId = activity.moduleStaticId || course.byStaticId.activities[activity.parentStaticId].moduleStaticId

        // const baseLink = `/course/configuration/${course.id}/module/${moduleStaticId}/activities`
        if (activity.parentStaticId) {
            delete activity.moduleStaticId
        }
        delete activity.docs
        if (activity.data.rolePlay) {
            activity.data.rolePlay = activity.data.rolePlay.sort((a, b) => {
                let x = a.offset - b.offset
                if (x !== 0) { return x }
                if (a.group.toLowerCase() < b.group.toLowerCase()) { return -1 }
                if (a.group.toLowerCase() > b.group.toLowerCase()) { return 1 }
                return 0
            })
        }
        if (!activity.id) {
            this.props.actions.activity.create(activity, (newActivity) => {
                this.setState({ isSaving: false, currentActivityIsEdited: false })
                // alert("NEED TO SAVE HERE WITH NEW METHOD")
                this.props.actions.local.openModalActivity(newActivity.id)
                // this.props.history.push(`${baseLink}#activityStaticId=${newActivity.staticId}`)
            })
        } else {
            this.props.actions.activity.update(activity, () => {
                this.setState({ isSaving: false, currentActivityIsEdited: false })
            })
        }
    }

    _onRemove() {
        const activity = this.state.activity
        if (confirm("Are you sure you want to delete " + activity.name + "?")) {
            this.props.actions.activity.remove(activity, () => {
                this.setState({ currentActivityIsEdited: false })
                this.props.handleClose()
            })
        }
    }

    _clone(e) {
        e.preventDefault()
        let activity = { ...this.state.activity }
        this.setState({ isSaving: true })
        // const course = this.props.state.course.model
        // let moduleStaticId = activity.moduleStaticId || course.byStaticId.activities[activity.parentStaticId].moduleStaticId

        // const baseLink = `/course/configuration/${course.id}/module/${moduleStaticId}/activities`

        delete activity.id
        delete activity.data.rolePlay
        delete activity.staticId
        activity.name = activity.name + " (Clone)"

        this.props.actions.activity.create(activity, (newActivity) => {
            this.setState({ isSaving: false, currentActivityIsEdited: false })
            alert(`Your activity has been cloned and is opened.\n (It has the same name as your current activity)`)
            this.props.actions.local.openModalActivity(newActivity.id)
            // this.props.history.push(`${baseLink}#activityStaticId=${newActivity.staticId}`)
        })
    }

    _removeCannedResponse(responseIndex) {
        let activity = this.state.activity
        delete activity.data.previousResponses[responseIndex]
        this.setState({
            activity: activity,
            currentActivityIsEdited: true
        })
    }

    _handleClose() {
        if (this.state.currentActivityIsEdited) {
            let confirmation = confirm("You have edited the activity, are you sure you want to leave without saving?")
            if (confirmation) {
                this.setState({ currentActivityIsEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ currentActivityIsEdited: false })
            this.props.handleClose()
        }
    }
}

export default ReduxBinder(ActivityModal, {
    state: ['course']
})