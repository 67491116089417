
const hasLocalStorage = () => {
    let testKey = 'test-localStorage',
        storage = window.localStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch (error) {
        console.info("Doesn't have local storage", error);
        return false;
    }
}

export default {
    hasLocalStorage() {
        return hasLocalStorage();
    },
    setLocalStorage(key, data){
        if (!hasLocalStorage()) {
            return false;
        }
        localStorage.setItem(key, JSON.stringify(data));
        return true;
    },
    removeLocalStorage(key) {
        if (!hasLocalStorage()) {
            return false;
        }
        localStorage.removeItem(key);
        return true;
    },
    getLocalStorage(key) {
        if (!hasLocalStorage()) {
            return false;
        }
        return JSON.parse(localStorage.getItem(key));
    }
}