/* globals io */
import React, { Component } from 'react'
import ReduxBinder from 'alias-store/ReduxBinder'
import config from 'alias-config'


class Admin extends Component {
    static propTypes = {}
    static defaultProps = {}
    constructor(props) {
        super(props)

        this.state = {
            onlineUsersCount: 0,
            isConnected: false
        }
    }

    componentDidMount() {
        this._connect()
    }
    componentWillUnmount() {
        if (this.refresh) {
            clearInterval(this.refresh)
        }
    }

    _connect() {
        this.socket = io(config.api.socketIOhost)
        this.socket.open()
        this.socket.on('connect', () => {
            this.setState({ isConnected: true })
            this.socket.on('disconnect', () => {
                this.setState({ isConnected: false })
            })

            this.socket.on('admin-count', (count) => {
                // console.log("Online", count)
                this.setState({ onlineUsersCount: count })
            })
            this._refresh()
        })
    }

    _refresh() {
        if (this.refresh) {
            clearInterval(this.refresh)
        }
        this._actionsToDo()
        this.refresh = setInterval(() => {
            this._actionsToDo()
        }, 1000 * 30)
    }
    _actionsToDo() {
        if (!this.socket) {
            console.log("Socket missing !?!") // eslint-disable-line
            return
        }
        this.socket.emit('admin-getCount')
    }
    render() {
        const { state } = this

        return <div className="container">
            <h1>Admin</h1>
            <h2>Is Connected: {state.isConnected ? 'Yes' : 'No'}</h2>
            <h2>Online users: {state.onlineUsersCount}</h2>
            <a onClick={this._actionsToDo.bind(this)} className="btn btn-secondary">Refresh</a>
        </div>
    }
}


export default ReduxBinder(Admin, { state: ['course'] })