import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import ForumForm from './ForumForm'
import Form from '../Form'
import moment from 'moment'
import { VideoPlayer, Avatar } from 'alias-components'


export default class ForumModalView extends Component {
    static propTypes = {
        actions: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        course: PropTypes.object,
        participantId: PropTypes.string,
        post: PropTypes.object,
        lastReadLog: PropTypes.object,
        updateUnread: PropTypes.func
    }
    static defaultProps = {
        show: false,
        post: {
            title: 'Post title'
        }
    }
    constructor(props) {
        super(props)

        this.state = {}
    }
    render() {
        const { props } = this
        const course = props.course

        // Author
        let mainPostAuthor = null
        if (props.post) {
            if (course.byId.participants[props.post.participantId]) {
                mainPostAuthor = course.byId.participants[props.post.participantId]
                mainPostAuthor = mainPostAuthor.displayName.bigTeamThenName
            }
            if (course.byStaticId.characters[props.post.participantId]) {
                mainPostAuthor = course.byStaticId.characters[props.post.participantId]
                mainPostAuthor = `${mainPostAuthor.last} ${mainPostAuthor.first}`
            }
        }

        if (props.post && props.post.comments) {
            props.post.comments = props.post.comments.map(c => {
                c.isNew = false
                if (props.lastReadLog
                    && moment(c.created).isAfter(moment(props.lastReadLog.updated).add(2, 'seconds'))
                    && c.participantId !== props.participantId
                ) {
                    c.isNew = true
                }
                return c
            })
        }
        let unreadHasBeenDisplayed = false
        const participant = course.byId.participants[props.participantId]

        let formData = null
        if (props.post && props.post.embeddedFormStaticId && course.byStaticId.forms[props.post.embeddedFormStaticId]) {
            let currentParticipant = course.byId.participants[props.participantId]
            if (currentParticipant) {
                let logs = course.activityLogs.filter(l => {
                    if (l.type !== 'form') { return false }
                    if (l.activityId !== props.post.activityId) { return false }
                    if (l.fromParticipantId !== props.participantId) { return false }
                    return true
                })

                formData = {
                    form: course.byStaticId.forms[props.post.embeddedFormStaticId],
                    log: logs.length === 0 ? null : logs[0], // TO BE DONE
                    activityId: props.post.activityId,
                    participantFrom: currentParticipant,
                    participantTo: currentParticipant,
                    userz: currentParticipant.userz,
                    course: course,
                    actions: props.actions,
                    isDisabled: logs.length > 0,  // If log is set
                    showQuestionState: false
                }
            }
        }

        return <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.post ? props.post.title : "Post title"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.post && <div className="forum forum-modal">
                    <div className="post">
                        <CreateOrEditPostOrComment
                            author={mainPostAuthor}
                            post={props.post}
                            actions={props.actions}
                            participant={course.byId.participants[props.participantId]}
                            courseId={course.id}
                            isMainPost={true}
                            handleClose={props.handleClose} />

                        {formData && <div>
                            <Form.Display {...formData} />
                        </div>}
                    </div>
                    <h4>Comments ({props.post.comments.length})</h4>
                    <hr />
                    {props.post.comments.length === 0 && <p className="help-block">No comments yet.</p>}
                    {props.post.comments.map(post => {
                        let author = "Not found"
                        let image = null
                        let commentParticipant = null
                        if (course.byId.participants[post.participantId]) {
                            commentParticipant = course.byId.participants[post.participantId]
                            author = course.byId.participants[post.participantId]
                            image = author.userz.avatar
                            author = author.displayName.bigTeamThenName
                        }
                        if (course.byStaticId.characters[post.participantId]) {
                            author = course.byStaticId.characters[post.participantId]
                            author = `${author.last} ${author.first}`
                        }

                        let showUnreadBar = false
                        if (!unreadHasBeenDisplayed && post.isNew) {
                            showUnreadBar = true
                            unreadHasBeenDisplayed = true
                        }
                        return <div key={post.id}>
                            {showUnreadBar && <p className="alert alert-info text-centered" style={{ margin: '10px 0', padding: 5 }}>UNREAD ACTIVITY BELOW <i className="fa fa-caret-down" style={{ marginLeft: 5 }} /></p>}

                            <div className="media comment">
                                <div className="media-left">
                                    {author && <Avatar user={commentParticipant} classNames="media-object" />}
                                </div>
                                <div className="media-body">
                                    <CreateOrEditPostOrComment author={author} post={post}
                                        actions={props.actions}
                                        participant={course.byId.participants[props.participantId]}
                                        courseId={course.id}
                                        isMainPost={false}
                                        parentId={props.post.id} />
                                </div>
                            </div>
                        </div>
                    })}

                    <hr />

                    <div className="media comment">
                        <div className="media-left">
                            <Avatar user={participant} classNames="media-object" />
                        </div>
                        <div className="media-body">
                            <ForumForm
                                actions={props.actions}
                                participantId={props.participantId}
                                courseId={course.id}
                                updateUnread={props.updateUnread}
                                isMainPost={false}
                                parentId={props.post.id} />
                        </div>
                    </div>

                </div>}
            </Modal.Body>
            <Modal.Footer>
                <a onClick={props.handleClose} className="btn btn-default">Close</a>
            </Modal.Footer>
        </Modal>
    }

}


class CreateOrEditPostOrComment extends Component {
    static propTypes = {
        author: PropTypes.string,
        post: PropTypes.object,
        participant: PropTypes.object,
        isMainPost: PropTypes.bool,
        actions: PropTypes.object,
        handleClose: PropTypes.func
    }
    static defaultProps = {
        // handleClose: () => { }
    }
    constructor(props) {
        super(props)
        this.state = {
            isInEditMode: false,
            isRemoving: false
        }
    }
    render() {
        const { props, state } = this

        if (state.isInEditMode) {

            return <ForumForm
                actions={props.actions}
                isMainPost={props.isMainPost}
                post={props.post}
                onComplete={() => this.setState({ isInEditMode: false })} />
        }
        let canEdit = props.participant.isAdmin || props.participant.id === props.post.participantId
        // const isOwner = props.participant.id === props.post.participantId

        const hasVoted = props.post.votes.filter(v => v.participantId === props.participant.id).length > 0
        const positiveVotes = props.post.votes.filter(v => v.positive).length
        // const negativeVotes = props.post.votes.length - positiveVotes
        // {(false && hasVoted) ? <span style={{ marginLeft: 4, marginRight: 4 }}>{negativeVotes} dislikes</span> : <a onClick={this._onVote.bind(this, false)} style={{ marginLeft: 4, marginRight: 4 }}>{negativeVotes} Dislike</a>}
        return <div>
            {props.post.text.split('\n').map((l, i) => <p key={i} className="text">{l}</p>)}
            {(props.post.files && props.post.files.length > 0) && <ul className="list-unstyled" style={{ marginBottom: 0 }}>
                {props.post.files.map((file, i) => {
                    return <li key={i} style={{ paddingBottom: '10px' }}>
                        <a href={`/api/files?url=${file.path}`} target="_blank">{file.name}</a>
                    </li>
                })}
            </ul>}
            {(props.post && props.post.video) && <VideoPlayer url={props.post.video} />}
            <p className="details">
                {props.post.type !== 'activity' && <span style={{ marginRight: 4 }}>{hasVoted ? <span>{positiveVotes} likes</span> : <a onClick={this._onVote.bind(this, true)}>{positiveVotes} Likes</a>}</span>}
                From: {props.author} - Posted: {moment(props.post.created).fromNow()}
                {canEdit && <span> - <a onClick={() => this.setState({ isInEditMode: true })}>Edit</a> - {state.isRemoving ? <i className="fa fa-spinner fa-pulse" /> : <a onClick={this._onDelete.bind(this)}>Delete</a>}</span>}
            </p>
        </div>
    }

    _onVote(isPositive = true) {
        let post = this.props.post

        if (isPositive) {
            post.voteCount++
            post.votes.push({
                positive: true,
                participantId: this.props.participant.id,
                created: new Date()
            })
        } else {
            post.voteCount--
            post.votes.push({
                positive: false,
                participantId: this.props.participant.id,
                created: new Date()
            })
        }
        this.props.actions.forum.update(post, () => {

        })
    }

    _onDelete() {
        this.setState({ isRemoving: true })
        if (confirm("Are you sure you want to delete this post?")) {
            this.props.actions.forum.remove(this.props.post, () => {

                // this.setState({isRemoving: false })
                if (this.props.isMainPost && this.props.handleClose) {
                    this.props.handleClose()
                }
            })
        } else {
            this.setState({ isRemoving: false })
        }
    }
}