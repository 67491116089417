import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ParticipantReview from '../components/Participant/ParticipantReview'
import ReduxBinder from 'alias-store/ReduxBinder'

class GameTabDashboard extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    constructor(props) {
        super(props)
    }

    render() {
        const course = this.props.state.course.model
        // Get participant
        let userParticipants = course.participants.filter((item) => {
            return !item.isAdmin && item.userz.id == this.props.state.user.model.id
        })

        let participant = userParticipants[0]
        if (this.props.state.local.game.participantId) {
            const hisParticipant = userParticipants.filter(part => part.id == this.props.state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
            }
        }
        let team = null
        // if (participant.teamId) {
        //     team = course.byId.teams[participant.teamId] || null
        // }

        return (
            <div className="config-right-wrapper">
                <h1>Dashboard</h1>
                <p className="help-block">
                    <i className="fa fa-info" /> Come back here after you fill forms in mailbox or in role plays to see the impact.
                </p>
                <ParticipantReview
                    participant={participant}
                    team={team}
                    state={this.props.state}
                    actions={this.props.actions} />
            </div>
        )
    }
}

export default ReduxBinder(GameTabDashboard, {
    state: ['course', 'user', 'local']
})