import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'

const colWidth = 200
const scoreArray = [0, 1, 2, 3, 4, 5]
const variableImpactArray = [
    {
        value: 1,
        label: 'Very high'
    },
    {
        value: 0.5,
        label: 'High'
    },
    {
        value: 0,
        label: 'None'
    },
    {
        value: -0.5,
        label: 'Negative'
    },
    {
        value: 11,
        label: 'Very negative'
    }
]

export default class RolePlayScoringMatrix extends Component {
    static propTypes = {
        course: PropTypes.object,
        settings: PropTypes.object,
        onChangeSettings: PropTypes.func
    }
    render() {
        const { course, settings } = this.props

        const parties = settings.parties
        const form = course.byStaticId.forms[settings.globalFormStaticId]
        const scoringMatrix = settings.scoringMatrix || {}

        let questions = []
        form.questions.map(question => {
            if (question.type === 'multiple-choice') {
                let questionSummary = {}
                questionSummary.id = question.id
                questionSummary.question = question.question
                questionSummary.variableStaticId = question.variableStaticId
                questionSummary.options = question.options.map(option => {
                    parties.map(party => {
                        option.values = option.values || {}
                        option.values[party.roleStaticId] = option.values[party.roleStaticId] || {
                            score: '',
                            variableStaticId: '',
                            impact: ''
                        }
                        if (scoringMatrix[question.id] && scoringMatrix[question.id][option.id] && scoringMatrix[question.id][option.id][party.roleStaticId]) {
                            option.values[party.roleStaticId] = scoringMatrix[question.id][option.id][party.roleStaticId] || {
                                score: '',
                                variableStaticId: '',
                                impact: ''
                            }
                        }
                    })
                    return option
                })
                questions.push(questionSummary)
            }
        })
        return <div>
            <h3>Scoring matrix</h3>
            <p className='help-block'>From here, you can affect the scoring depending on which party it is.</p>
            <p className="alert alert-warning">Please note that any change(s) in the settings above could effect the scoring matrix.</p>
            <Card>
                {questions.length === 0 && <p className="alert alert-info">This form doesn't have any multiple questions</p>}
                {questions.map(question => <div key={question.id}>
                    <h4>Q: {question.question}</h4>
                    <table className='table table-hover table-stripped'>
                        <thead>
                            <tr>
                                <th rowSpan={2}>Answers</th>
                                {parties.map((party, i) => <th key={`name-${question.id}${party.id}${i}`} width={colWidth} className='centered'>{party.roleStaticId ? course.byStaticId.roles[party.roleStaticId].name : 'Not set'}</th>)}
                            </tr>
                            <tr>
                                {parties.map((party, i) => <th key={`fields-${question.id}${party.id}${i}`} className='centered'>Score</th>)} {/*<br /> Variable > Impact*/}
                            </tr>
                        </thead>
                        <tbody>
                            {question.options.map((option, o) => <tr key={`row-${question.id}${option.id}${o}`}>
                                <td>{option.text}</td>
                                {parties.map((party, p) => <td key={`cell-${question.id}${option.id}${o}${party.roleStaticId}${p}`} className='centered'>
                                    <select value={option.values[party.roleStaticId].score} onChange={(e) => this._onChange(question.id, option.id, party.roleStaticId, 'score', e.target.value)}>
                                        {scoreArray.map((score, i) => <option value={score} key={`score-${question.id}${option.id}${party.id}${i}`}>{score}</option>)}
                                        <option value="">None</option>
                                    </select>
                                    {/*
                                    <br />
                                    {<select value={option.values[party.roleStaticId].variableStaticId}
                                        onChange={(e) => this._onChange(question.id, option.id, party.roleStaticId, 'variableStaticId', e.target.value)}>
                                        {course.variables.map((variable, i) => <option value={variable.staticId} key={`variable-${question.id}${party.id}${option.id}${i}`}>{variable.name}</option>)}
                                        <option value="">None</option>
                                    </select>}
                                    {option.values[party.roleStaticId].variableStaticId !== '' && <select value={option.values[party.roleStaticId].impact} onChange={(e) => this._onChange(question.id, option.id, party.roleStaticId, 'impact', e.target.value)}>
                                        {variableImpactArray.map((impact, i) => <option value={impact.value} key={`impact-${question.id}${party.id}${option.id}${i}`}>{impact.label}</option>)}
                                    </select>}
                                    */}
                                </td>)}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                )}
            </Card>
            <br />
        </div>
    }

    _onChange(questionId, optionId, partyRoleStaticId, type, value) {
        let { settings, onChangeSettings } = this.props
        settings.scoringMatrix = settings.scoringMatrix || {}
        settings.scoringMatrix[questionId] = settings.scoringMatrix[questionId] || {}
        settings.scoringMatrix[questionId][optionId] = settings.scoringMatrix[questionId][optionId] || {}
        settings.scoringMatrix[questionId][optionId][partyRoleStaticId] = settings.scoringMatrix[questionId][optionId][partyRoleStaticId] || {}
        settings.scoringMatrix[questionId][optionId][partyRoleStaticId][type] = value
        onChangeSettings(settings)
    }
}
