import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Search, Onboarding } from 'alias-components'
import Course from './components/Course'
import Moment from 'moment'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'

class PageInstructorCourses extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            isNewCourseModalOpen: false,
            search: ''
        }
    }

    render() {
        const self = this
        const participants = this.props.state.user.model.participants.filter(function (item) {
            return item.isAdmin && (item.course && !item.course.isTemplate)
        })

        const currentDateTime = Moment()
        let runningCourses = []
        let finishedCourses = []
        let searchResults = []

        if (this.state.search === "") {
            runningCourses = participants.filter(function (item) {
                return item.course && (item.course.end == null || Moment(item.course.end) > currentDateTime)
            }).map(function (item) {
                return item.course
            })

            finishedCourses = participants.filter(function (item) {
                return item.course && (item.course.end && Moment(item.course.end) < currentDateTime)
            }).map(function (item) {
                return item.course
            })
        } else {
            searchResults = participants.filter(function (item) {
                return item.course.name.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
                    || item.course.description.toLowerCase().indexOf(self.state.search.toLowerCase()) !== -1
            }).map(function (item) {
                return item.course
            })
        }

        return (
            <Layout title="Instructor dashboard">
                <div className="container course-list">
                    <Helmet title="Instructor dashboard" />
                    <div className="page-header">
                        <Search search={this.state.search}
                            onChange={(search) => {
                                self.setState({
                                    search: search
                                })
                            }} />

                        <a className="btn btn-secondary pull-right" onClick={() => {
                            self.setState({ isNewCourseModalOpen: true })
                        }}>+ Create course</a>

                        <Course.Modal isShown={this.state.isNewCourseModalOpen} close={() => {
                            self.setState({ isNewCourseModalOpen: false })
                        }} state={this.props.state}
actions={this.props.actions} />
                    </div>
                    {this.state.search !== "" && (
                        <div>
                            <p><em>Showing search results for <strong>{this.state.search}</strong></em></p>

                            {searchResults.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No results.</strong>
                                </div>
                            )}
                            {searchResults.map(function (course) {
                                return <Course.Card course={course} isInstructor={true} key={course.id} />
                            })}

                        </div>
                    )}
                    {this.state.search === "" && (
                        <div>
                            {runningCourses.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No ongoing courses yet.</strong>
                                    <p>You do not currently have any ongoing course.</p>
                                </div>
                            )}

                            {runningCourses.map(function (course, i) {
                                return <Course.Card course={course} isInstructor={true} key={i} />
                            })}

                            <br />
                            <br />
                            {finishedCourses.length > 0 && (
                                <div>
                                    <h1>Finished courses</h1>
                                    {finishedCourses.length === 0 && (
                                        <div className="alert alert-info">
                                            <strong>No running courses yet.</strong>
                                            <p>You do not have any running courses for the moment. You can start by creating a template first, and then coming here to create the course.</p>
                                        </div>
                                    )}
                                    {finishedCourses.map(function (course) {
                                        return <Course.Card course={course} isInstructor={true} key={course.id} />
                                    })}
                                </div>
                            )}
                            <br />
                            <br />
                            <Onboarding title="Do you know about templates?">
                                <div>
                                    <p>
                                        Templates allow you use the same course several times! Saving you hours of work in the future. You can create a <Link to="/courses/templates">template</Link> or find one in our <Link to="/courses/market">marketplace</Link>
                                        &nbspor even turn a course you already have into a template by going into the configuration page of the course and click "Make template"
                                    </p>
                                </div>
                            </Onboarding>
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default ReduxBinder(PageInstructorCourses, {
    state: ['user', 'course']
})