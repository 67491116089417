var errors = [
    {
        id: 'general',
        title: "Something went wrong...",
        message: "We're going to reload the page to try and fix it ~5secs",
        action: function () {

        }
    },
    {
        id: 'server',
        title: "Sever issue",
        message: "Having some issues connecting to the server...can you check your internet connection and reload the page?"
    }
];


module.exports = errors;