import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Search } from 'alias-components'
import Course from './components/Course'
import Helmet from 'react-helmet'
import ReduxBinder from 'alias-store/ReduxBinder'

class PageInstructorMarketplace extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            market: [],
            isLoading: true,
            search: ''
        }
    }

    _updateMarket(market) {
        this.setState({
            market: market,
            isLoading: false
        })
    }

    componentDidMount() {
        this.props.actions.course.getMarket(this._updateMarket.bind(this))
    }

    render() {
        const self = this
        const market = this.state.market

        let searchResults = []
        if (this.state.search) {
            searchResults = market.filter((course) => {
                return course.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                    || course.description.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
            })
        }

        if (this.state.isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h4><i className="fa fa-spinner fa-pulse" /> Loading market place</h4>
                </div>
            )
        }

        return (
            <Layout title="Instructor marketplace">
                <div className="container course-list">
                    <Helmet title="Instructor template marketplace" />
                    <div className="page-header">
                        <Search search={this.state.search}
                            onChange={(search) => {
                                self.setState({
                                    search: search
                                })
                            }} />
                    </div>

                    {this.state.search === '' && (
                        <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> The marketplace brings you templates ready to be used for your own course.</p>
                    )}

                    {this.state.search !== "" && (
                        <div>
                            <p><em>Showing search results for <strong>{this.state.search}</strong></em></p>
                            {searchResults.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>No results.</strong>
                                </div>
                            )}
                            {searchResults.map(function (course) {
                                return <Course.Card course={course} isMarket={true} key={course.id} />
                            })}
                        </div>
                    )}
                    {this.state.search === "" && (
                        <div>
                            {market.length === 0 && (
                                <div className="alert alert-info">
                                    <strong>There are currently no templates in the marketplace</strong>
                                    <p>The market place allows you to use templates created by other people.</p>
                                </div>
                            )}
                            {market.map(function (course) {
                                return <Course.Card course={course} isMarket={true} key={course.id} />
                            })}
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default ReduxBinder(PageInstructorMarketplace, {
    state: ['course']
})