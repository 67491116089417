import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Search extends Component {

    static propTypes = {
        onSubmit: PropTypes.func,
        onChange: PropTypes.func,
        search: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            search: ''
        }
    }

    render() {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                if (this.props.onSubmit) {
                    this.props.onSubmit(this.refs.participantSearch.value)
                }
            }} className="search">
                <button type="submit" aria-label="Search"><i className="fa fa-search" /></button>
                <input type="text"
                    ref="participantSearch"
                    value={this.props.search}
                    onChange={() => {
                        if (this.props.onChange) {
                            this.props.onChange(this.refs.participantSearch.value)
                        }
                    }}
                    placeholder="Search or filter..." />
            </form>
        )
    }
}