import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import moment from "moment-timezone"
import ReduxBinder from "alias-store/ReduxBinder"
import { Card, Search } from "alias-components"
import Fuse from 'fuse.js'
import Participant from '../components/Participant'

class CourseRunChecklist extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    static defaultProps = {}
    constructor(props) {
        super(props)
    }

    _caseCheck(course) {
        let checks = []
        let canLaunch = true

        const participants = course.participants.filter(p => !p.isAdmin)

        // Added participants
        if (participants.length === 0) {
            checks.push({
                success: false,
                text: "Have you added participants?",
                canLaunch: true
            })
        } else {
            checks.push({
                success: true,
                text: "Have you added participants?",
                canLaunch: true
            })
        }
        const participantsWithoutRoles = participants.filter(p => !p.roles || (p.roles && p.roles.length === 0))
        if (participantsWithoutRoles.length > 0) {
            checks.push({
                success: false,
                text: "Do all my participants have roles assigned?",
                canLaunch: true
            })
        } else {
            checks.push({
                success: true,
                text: "Do all my participants have roles assigned?",
                canLaunch: true
            })
        }

        const hasTeams = course.teams.length > 0
        if (hasTeams) {
            let participantsWithoutTeams = participants.filter(p => !p.teamId)
            if (participantsWithoutTeams.length > 0) {
                checks.push({
                    success: false,
                    text: "Do all participants have a team?",
                    canLaunch: true
                })
            } else {
                checks.push({
                    success: true,
                    text: "Do all participants have a team?",
                    canLaunch: true
                })
            }
        }
        // checks.push({
        //     success: true,
        //     text: "Have you scheduled your case?",
        //     canLaunch: true,
        //     isCustom: true,
        //     customText: moment(course.start).format("ddd, MMM Do YYYY H:mm")
        // })

        return {
            checks,
            canLaunch
        }
    }
    render() {
        const course = this.props.state.course.model

        let checkOutPut = this._caseCheck(course)

        return (
            <div className="config-right-wrapper">
                <h2>Launch checklist for instructing</h2>
                <p>Here are a couple of pointers to get you started and a checklist to see if everything is ready.</p>
                <p>If this is your first time, you can follow the steps just below or scroll all the way down for more explanation on how things work.</p>

                <Card title="Step 1 > Scheduling">
                    <div>
                        <p>Go to the scheduling page on your left to schedule the start date and time for the case as well as the modules and activities.</p>
                        <p>Tweak as much as your heart desires.</p>

                        <table className="table table-hover table-stripped">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Are these correct?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Have you scheduled your course start?</td>
                                    <td>{moment(course.start).tz(course.timezone).format("ddd, MMM Do YYYY H:mm")}</td>
                                </tr>
                                <tr>
                                    <td>Is the timezone correct?</td>
                                    <td>{course.timezone}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Link className="btn btn-secondary" to={`/course/instructor/${course.id}/scheduling`}>
                            Schedule
                        </Link>
                        <br />
                    </div>
                </Card>

                <Card title="Step 2 > Add participants">
                    <div>
                        <p>Either enable invitation below or go to the participants page to add them manually as well as arrange the participants into teams and assign roles.</p>
                        <div>
                            <Participant.InviteWidget course={course} />
                            {course.inviteCode && <br />}
                        </div>

                        <table className="table table-hover table-stripped">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Completed?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkOutPut.checks.map((check, i) => {
                                    if (i > 0 && course.participants.filter(p => !p.isAdmin).length === 0) {
                                        return null
                                    }
                                    return (
                                        <tr key={i}>
                                            <th>{check.text}</th>
                                            <th className={check.success ? "success" : "warning"}>
                                                {(!check.isCustom && check.success) && <span><i className="fa fa-check" /> Yes</span>}
                                                {(!check.isCustom && !check.success) && <span><i className="fa fa-times" /> No</span>}
                                                {check.isCustom && <span>{check.customText}</span>}
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Link className="btn btn-secondary" to={`/course/instructor/${course.id}/participants`}>
                            Manage participants
                        </Link>
                    </div>
                </Card>

                <Card title="Step 3 > Launch">
                    <div>
                        <p>Make sure everything looks ready to go in the checklist below and launch the case.</p>


                        {course.isLaunched && (
                            <div>
                                <p><a onClick={this._launchToggle.bind(this)} className="btn btn-warning">Unlaunch course</a></p>
                                <p className="alert alert-success">Your case is launched, participants will now be able to play.</p>
                            </div>
                        )}
                        {!course.isLaunched && (
                            <div>
                                <p><a onClick={this._launchToggle.bind(this)} className="btn btn-primary">Launch course</a></p>
                                <p className="alert alert-warning">Until the case is "launched", participants will only view an intro page of the case.</p>
                            </div>
                        )}
                    </div>
                </Card>

                <Card title="Finished">
                    <div>
                        <p className="help-block">
                            <i className="fa fa-info" /> Marking the course as finished will simply move it in the menus for you and the participants.
                        </p>
                        {course.isFinished && (
                            <p>
                                <a
                                    className="btn btn-default"
                                    onClick={() => {
                                        course.isFinished = false
                                        this.props.actions.course.update(course)
                                    }}>
                                    Mark course as NOT finished
                                </a>
                            </p>
                        )}
                        {!course.isFinished && (
                            <p>
                                <a
                                    className="btn btn-warning"
                                    onClick={() => {
                                        course.isFinished = true
                                        this.props.actions.course.update(course)
                                    }}>
                                    Mark course as finished
                                </a>
                            </p>
                        )}
                    </div>
                </Card>

                <br />
                <br />
                <FAQ />
                <br />
            </div>
        )
    }

    _launchToggle() {
        let course = this.props.state.course.model
        course.isLaunched = !course.isLaunched
        this.props.actions.course.update(course)
    }
    _finishToggle() {
        let course = this.props.state.course.model
        course.isFinished = !course.isFinished
        this.props.actions.course.update(course)
    }
}

export default ReduxBinder(CourseRunChecklist, {
    state: ["course"]
})

class FAQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            shown: 6
        }
    }
    render() {
        const { state } = this

        let faqs = [
            {
                title: "How do I ask for assistance?",
                content: <div>
                    <p>Simply click the <strong>Help?</strong> button on the top left of the menu and ask for assistance, we'll get back in touch with you as soon as possible.</p>
                </div>,
                search: ""
            },
            // {
            //     title: "How do I add another author?",
            //     content: <div>
            //         <p>Go to <strong>Info</strong> page in the left menu, then select the <strong>Authors</strong> tab on the top of the page.</p>
            //     </div>,
            //     search: ""
            // },
            {
                title: "What start time should I put?",
                content: <div>
                    <p>Ideally this would be the actual start time of your case. Keep in mind it is very simple to change later.</p>
                    <p>If you do not know when you will use the case, pick a date a little in the future at the time you will most likely start it.</p>
                    <p>For example, you are not sure when you will run it, but it will most likely be a 9AM class. Pick a couple of days ahead at 9AM.</p>
                </div>,
                search: ""
            },
            {
                title: "How does the scheduling for modules and activities work?",
                content: <div>
                    <p>It all starts with the course start date/time. All the rest, ie Modules and Activities, will have an offset from that date/time.</p>
                    <p>For example:</p>
                    <ul>
                        <li>Your case starts at 9AM</li>
                        <li>You set your first module to start 10mins after your introduction, so it has an offset of 10mins and will launch at 9:10AM</li>
                        <li>Activities are nested inside the module, so if an activity has an offset of 0mins, it will be sent at 9:10AM (same as module). If it has a 5mins offset, it be sent at 9:15AM (case starts at 9AM, Module offset of 10mins, and activity offset of 5mins, so 9AM + 10mins + 5mins)</li>
                    </ul>
                </div>,
                search: ""
            },
            {
                title: "An activity is not showing to participants?",
                content: <div>
                    <p>If this happens, the most likely scenarios to check are:</p>
                    <ul>
                        <li>Check that the module is not "Drafted" by going to the Module, then selecting "Info" in the top navigation of that page</li>
                        <li>Check that the activity is no "Drafted"</li>
                        <li>Check that the scheduling is right (ie that the time it should have been sent is before NOW)</li>
                    </ul>
                </div>,
                search: ""
            },
            {
                title: "How can I test my case?",
                content: <div>
                    <p>To get a quick glance, you can use the <strong>Activities</strong> view in the modules. <br />
                        If you want to go through the whole course like a participant, you can:
                    </p>
                    <ol>
                        <li>Add yourself as a participant in the participants page using your email</li>
                        <li>Assign yourself 1 or more roles</li>
                        <li>Make sure the start time is right for your test in the <strong>Scheduling</strong> page</li>
                        <li>Go to <strong>Getting started</strong> page for the instructor, make sure everything is ok.</li>
                        <li>Click "Launch case" in the Getting started page.</li>
                    </ol>
                    <p>We usually recommend that you create a duplicate of the case in "Settings" prior to using the case to experience it.</p>
                </div>,
                search: ""
            }

        ]

        if (state.search) {
            const options = {
                keys: ["title", "search"],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.5
            }
            let fuse = new Fuse(faqs, options)
            faqs = fuse.search(state.search)
        }

        let hasMore = false
        if (faqs.length > state.shown) {
            hasMore = true
            faqs = faqs.slice(0, state.shown)
        }
        return <div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <h2>FAQ</h2>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Search
                        search={state.search}
                        onChange={(search) => {
                            this.setState({ search: search, shown: 6 })
                        }} />
                </div>
            </div>


            {faqs.map((aq, i) => {
                return <Expandable key={`qa-${i}`}
                    title={`Q: ${aq.title}`}
                    isExpanded={false}
                    showIsComplete={false}>
                    {aq.content}
                </Expandable>
            })}
            {hasMore && <div className="text-centered">
                <a className="btn btn-secondary" onClick={() => this.setState({ shown: state.shown + 10 })}>Show more</a>
            </div>}
        </div>
    }
}


class Expandable extends Component {
    static propTypes = {
        isExpanded: PropTypes.bool,
        title: PropTypes.string,
        showIsComplete: PropTypes.bool,
        isComplete: PropTypes.bool
    }
    static defaultProps = {
        isExpanded: false
    }
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: props.isExpanded,
            isHovered: false
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            isExpanded: newProps.isExpanded
        })
    }
    render() {
        const { props, state } = this

        let styleElements = {}
        if (state.isHovered) {
            styleElements.cursor = 'pointer'
        }
        if (!state.isExpanded) {
            styleElements.marginBottom = 0
        }


        return <Card>
            <h3 onClick={() => this.setState({ isExpanded: !state.isExpanded })}
                onMouseEnter={() => this.setState({ isHovered: true })}
                onMouseLeave={() => this.setState({ isHovered: false })}
                style={styleElements}>
                {props.title}
                {(props.isComplete && props.title !== "Checklist" && props.showIsComplete) && <i className="fa fa-check green pull-right" />}
            </h3>

            {state.isExpanded && <div>
                {props.children}
            </div>}
        </Card>
    }
}