import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { Animated, Button, FormErrors, Input } from 'alias-components'
import IsValid from 'alias-tools/validation'
import ReduxBinder from 'alias-store/ReduxBinder'


class Forgotten extends Component {

    static propTypes = {
        route: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            email: props.match.params.email || '',
            emailSent: false,
            errorFields: []
        };
    }

    componentDidMount() {
        // this.props.actions.auth.clearMessages();
    }

    componentWillUnmount() {
        // this.props.actions.auth.clearMessages();
    }

    render() {

        const auth = this.props.state.auth;
        const { email, emailSent } = this.state;
        const isDisabled = email === "";

        return (
            <form onSubmit={this._forgottenPassword.bind(this)} className="form-horizontal">
                <Helmet title="Forgotten Password" />
                {!emailSent && (
                    <fieldset>
                        {auth.messages.error !== null && (
                            <Animated className="animated" transitionName={{ enter: "fadeInDown", leave: "fadeOutUp" }}>
                                <div className="alert alert-warning animated"><p>{auth.messages.error}</p></div>
                            </Animated>
                        )}
                        {auth.messages.success !== null && (
                            <Animated className="animated" transitionName={{ enter: "fadeInDown", leave: "fadeOutUp" }}>
                                <div className="alert alert-success animated"><p>{auth.messages.error}</p></div>
                            </Animated>
                        )}
                        <legend>Forgot your password?</legend>
                        <p>Please enter your email, we will send you an email to reset your password.</p>
                        <Input type="email"
                            name="email"
                            label="Your email"
                            placeholder="Ex: john.smith@email.com"
                            value={email}
                            onChange={(name, value) => this.setState({ email: value })}
                            attributes={{ autoFocus: true, disabled: auth.isFetching }} />

                        <Button btnClass="btn btn-primary btn-block btn-lg" disabled={isDisabled} text="Reset Password" isLoading={auth.isFetching} />
                        <p className="help-block text-centered">
                            <br />
                            Remember your password?<br />
                            <Link to="/auth/login">Back to login</Link>
                        </p>
                    </fieldset>
                )}
                {emailSent && (
                    <fieldset>
                        <legend>Forgot your password?</legend>
                        <div className="alert alert-success">
                            <strong>Email sent</strong>
                            <p>An email has been sent to you with a link to reset your password. Please find it and click it.</p>
                        </div>
                        <Link to="/auth/login">Back to login</Link>
                    </fieldset>
                )}
            </form>
        );
    }

    _onChange() {
        var state = this.state;
        state.email = this.refs.email.value;
        state.password = this.refs.password.value;
        this.setState(state);
        this.props.actions.auth.clearMessages();
    }

    _forgottenPassword(e) {
        e.preventDefault();
        const self = this;
        const email = this.state.email;
        // #todo validate this email
        this.props.actions.auth.forgottenPassword(email, function (response) {
            self.setState({
                emailSent: true
            })
        });
    }
}

export default ReduxBinder(Forgotten, { state: ['auth', 'user'] })