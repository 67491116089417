import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link, Switch, Route } from 'react-router-dom'
import ModuleTabs from './ModuleTabs'

class CourseConfigurationTabModule extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    render() {
        let { moduleId, tab } = this.props.match.params
        const course = this.props.state.course.model


        tab = tab || 'activities'
        const tabList = [
            {
                label: 'Activities',
                value: 'activities'
            },
            {
                label: 'Setup',
                value: 'setup'
            },
            {
                label: 'Intro',
                value: 'intro'
            },
            {
                label: 'Teachers',
                value: 'teachers-notes'
            },
            {
                label: 'Triggered',
                value: 'triggered'
            }
        ] // , 'triggered'

        let baseLink = `/course/configuration/${course.id}/module/${moduleId}`
        let basePath = `/course/configuration/${course.id}/module/:moduleId`

        return (
            <div className="config-right-wrapper">
                <div className="page-header tab-list">
                    <h1 style={{ marginRight: 25 }}>Info</h1>
                    {tabList.map((thisTab) => {
                        return <Link to={`${baseLink}/${thisTab.value}`}
                            key={thisTab.value}
                            className={(tab === thisTab.value) ? 'active' : ''}>
                            {thisTab.label}
                        </Link>
                    })}
                </div>
                <div style={{ minHeight: 700 }}>
                    <Switch>
                        <Route exact path={`${basePath}`} component={ModuleTabs.Activities} />
                        <Route path={`${basePath}/activities`} component={ModuleTabs.Activities} />
                        <Route path={`${basePath}/setup`} component={ModuleTabs.Setup} />
                        <Route path={`${basePath}/triggered`} component={ModuleTabs.Triggered} />
                        <Route path={`${basePath}/intro`} component={ModuleTabs.Intro} />
                        <Route path={`${basePath}/teachers-notes`} component={ModuleTabs.Teachers} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default ReduxBinder(CourseConfigurationTabModule, {
    state: ['course', 'module']
})