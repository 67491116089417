import api from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import config from 'alias-config'
import localAuthentication from '../../tools/localAuthentication'
//
// Action types
//
const CLEAR = 'si/auth/CLEAR'
const FETCHING = 'si/auth/FETCHING'
const ERROR = 'si/auth/ERROR'
const REGISTER = 'si/auth/REGISTER'
// const FORGOTTEN = 'si/auth/FORGOTTEN'
// Used in other modules as well
const LOGIN = 'si/auth/multi/LOGIN'
const LOGOUT = 'si/auth/multi/LOGOUT'

const initialState = {
    isFetching: false, // When it's getting data from the server (usually to show a loader)
    messages: {
        error: null, // String
        success: null // String
    },
    token: null, // Token given by the server to use in queries.  If set, it means the user is logged in.
    user: {
        id: null
    }
}

//
// Reducers
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false
            }
        case FETCHING:
            return {
                ...state,
                messages: initialState.messages,
                isFetching: true
            }
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isFetching: false
            }
        case LOGIN:
            return {
                ...state,
                isFetching: false,
                token: action.token
            }
        case REGISTER:
            return {
                ...state,
                isFetching: false,
                messages: {
                    error: null,
                    success: "You have been registered."
                }
            }
        case LOGOUT:
            return {
                ...initialState
            }
        default:
            return state
    }
}

//
// Action creators
//

export function clearMessages() {
    return {
        type: CLEAR
    }
}

function loginMethod(email, password, dispatch, getState, callback) {
    dispatch({
        type: FETCHING
    })
    if (email) {
        email = email.trim().toLowerCase()
    }
    api('/userz/login/custom', 'POST', {
        email: email,
        password: password
    }, getState(), {}, dispatch)
        .then((response) => {
            localAuthentication.login(response.data)
            dispatch({
                type: LOGIN,
                token: response.data.id,
                user: {
                    id: response.data.userId
                }
            })
            if (callback) {
                callback(response)
            }
        })
        .catch((response) => {
            dispatch({
                type: ERROR,
                error: "Login failed, please try again"
            })
            if (callback) {
                callback(response)
            }
            errorHandling(dispatch, ERROR, response)
        })
}

export function register(email, password, callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({
            type: FETCHING
        })
        if (email) {
            email = email.trim().toLowerCase()
        }
        api('/userz/register', 'POST', {
            email: email,
            password: password,
            registered: true
        }, getState(), {})
            .then((response) => {
                localAuthentication.login(response.data)
                dispatch({
                    type: LOGIN,
                    token: response.data.id,
                    user: {
                        id: response.data.userId
                    }
                })
                if (callback) {
                    callback(response)
                }
            })
            .catch((response) => {
                if (response.status === 422) {
                    dispatch({
                        type: ERROR,
                        error: "Might want to try logging-in instead..."
                    })
                } else {
                    dispatch({
                        type: ERROR,
                        error: "Couldn't register with this information. Please try again."
                    })
                }
                callback(response)
            })
    }
}

export function login(email, password, callback) {
    return (dispatch, getState) => {
        loginMethod(email, password, dispatch, getState, callback)
    }
}

const localLogout = (cb = () => { }) => {
    localStorage.removeItem(config.app.localStorageName)
    localStorage.removeItem(config.app.localStorageName + 'Id')
    localStorage.removeItem(config.app.localStorageName + 'Data')
    localStorage.clear()
    cb()
}

export function logout(user, callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({
            type: FETCHING
        })

        if (user && user.isUsingInseadLogin) {
            const token = localAuthentication.isAuthenticated()
            if (token) {
                localLogout(() => {
                    window.location = `/api/sso/insead/logout?token=${token.id}`
                })
                return
            }
        }

        api('/userz/logout', 'POST', {}, getState(), {})
            .then((response) => {
                localAuthentication.login(response.data)
                if (callback) {
                    callback(response)
                }
                dispatch({
                    type: LOGOUT
                })

            })
            .catch((response) => {
                localLogout()

                dispatch({
                    type: LOGOUT
                })
                if (callback) {
                    callback(response)
                }
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function forgottenPassword(email, callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({
            type: FETCHING
        })

        api('/userz/reset/ask', 'POST', {
            email: email
        }, getState(), {})
            .then((response) => {
                if (callback) {
                    callback(response)
                }
                dispatch({
                    type: LOGOUT
                })

            })
            .catch((response) => {
                if (callback) {
                    callback(response)
                }
            })
    }
}

export function resetPassword(password, token, callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({
            type: FETCHING
        })

        api('/userz/reset/new', 'POST', {
            password: password,
            token: token
        }, getState(), {})
            .then((response) => {
                if (localStorage) {
                    localStorage.setItem(config.app.localStorageName, response.data.id)
                    localStorage.setItem(config.app.localStorageName + 'Id', response.data.userId)
                }
                dispatch({
                    type: LOGIN,
                    token: response.data.id,
                    user: {
                        id: response.data.userId
                    }
                })
                if (callback) {
                    callback(response)
                }
            })
            .catch(function (response) {
                dispatch({
                    type: ERROR,
                    error: "Token not valid. Please try forgotten password again"
                })
                if (callback) {
                    callback(response.response)
                }
            })
    }
}