import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ModuleSingleView from './components/ModuleSingleView'
import Forum from '../Forum'

export default class ModulesView extends Component {
    static propTypes = {
        state: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        history: PropTypes.object,
        match: PropTypes.object
    }

    _getParticipant(participants, game) {
        let participant = (game && game.participantId && participants.filter(p => p.id === game.participantId).length > 0) ?
            participants.filter(p => p.id === game.participantId)[0]
            : participants[0]
        return participant
    }

    render() {
        const { props } = this
        const { state, match } = props
        const course = state.course.model

        let modules = course.modules.filter(m => m.isShownToParticipants && !m.isDraft)
        let module = null
        if (match.params && match.params.moduleId) {
            if (course.byId.modules[match.params.moduleId]) {
                module = course.byId.modules[match.params.moduleId]
            }
        }

        let hasUnreadForum = false
        const participants = course.participants.filter(item => {
            return !item.isAdmin && item.userz.id === props.state.user.model.id
        })
        const participant = this._getParticipant(participants, props.state.local.game)
        if (participant) {
            const logs = course.activityLogs.filter(l => l.type === "forum-read" && l.participantId === participant.id)
            const latestPost = (course.forums && course.forums.length > 0) ? course.forums[0] : null
            if (logs.length > 0 && latestPost) {
                if (new Date(latestPost.latestUpdate) - new Date(logs[0].updated) > 0) {
                    hasUnreadForum = true
                }
            }
            if (logs.length === 0 && latestPost) {
                hasUnreadForum = true
            }
        }


        return <div className={`modules`}>
            <Helmet title={course.name + " modules"} />
            <div className="modules-list">
                {course.showForum && <div className={`item ${(match.params && match.params.moduleId === 'forum') ? 'active' : ''} `} onClick={() => this._selectModule('forum')}>
                    <h3>Class forum {hasUnreadForum && <small className="badge danger">New</small>}</h3>
                    <p>Share and ask questions with everyone in your cohort.</p>
                    <a onClick={() => this._selectModule('forum')} className="arrow"><i className="fa fa-chevron-right" /></a>
                </div>}
                {modules.map(mod => {
                    return <div key={mod.id} className={`item ${(module && module.id && module.id === mod.id) ? 'active' : ''} `} onClick={() => this._selectModule(mod.id)}>
                        <h3>{mod.name}</h3>
                        <p>{mod.description || "No description"}</p>
                        <a onClick={() => this._selectModule(mod.id)} className="arrow"><i className="fa fa-chevron-right" /></a>
                    </div>
                })}
            </div>
            {module && <div className="module-view">
                <ModuleSingleView course={course} module={module} />
            </div>}
            {(match.params && match.params.moduleId === 'forum') && <div className="module-view"><Forum.Page /></div>}
        </div>
    }

    _back() {
        const { history, state, isAdmin } = this.props
        const course = state.course.model
        const baseLink = `/ course / ${isAdmin ? 'instructor' : 'my'} /${course.id}`
        let link = baseLink
        history.push(link)
    }



    _selectModule(moduleId) {
        const { history, isAdmin, state } = this.props
        const course = state.course.model
        let link = `/course/${isAdmin ? 'instructor' : 'my'}/${course.id}/modules`

        if (moduleId) {
            link += `/${moduleId}`
        }
        history.push(link)
    }
}