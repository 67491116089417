import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import Character from '../../components/Character'
import ReduxBinder from 'alias-store/ReduxBinder'
import Fuse from 'fuse.js'

class CourseConfigurationTabCharacters extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: ''
        }
    }

    render() {
        const { props, state } = this
        const { actions, match } = this.props
        const characterId = match.params.characterId
        const course = props.state.course.model
        let characters = course.characters

        if (state.search !== '') {
            const options = {
                keys: ["first", "last", "description"],
                minMatchCharLength: 1,
                shouldSort: false,
                threshold: 0.2
            }
            let fuse = new Fuse(characters, options)
            characters = fuse.search(state.search)
        }
        // Add stats
        characters = characters.map(character => {
            character.activitiesSentToRoles = {}
            character.activities = course.activities
                .filter(activity => activity.type === "email" && activity.characterStaticId === character.staticId)
                .map(activity => {
                    if (!character.activitiesSentToRoles[activity.roleStaticId]) {
                        let role = course.byStaticId.roles[activity.roleStaticId]
                        character.activitiesSentToRoles[activity.roleStaticId] = {
                            roleStaticId: activity.roleStaticId,
                            roleName: role ? role.name : 'Role not found',
                            count: 1
                        }
                    } else {
                        character.activitiesSentToRoles[activity.roleStaticId].count++
                    }
                })

            return character
        })

        return (
            <div>
                <Helmet title={course.name + " characters"} />
                <div className="page-header">
                    <Search
                        search={state.search}
                        onChange={(search) => {
                            this.setState({ search: search })
                        }} />
                    <a onClick={this._openModal.bind(this, 'new')}
                        className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add character</a>
                </div>

                {state.search === '' && <p className="help-block" style={{ marginBottom: '15px' }}>
                    <i className="fa fa-info margin-right-5" />
                    Characters will allow you to send messages on behalf of someone else.
                </p>}

                {state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}>
                        <em>Showing results for <strong>{state.search}</strong><a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a></em>
                    </p>
                )}

                {characters.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No characters.</strong>
                        {state.search === '' && (
                            <p>Click the "+ New character" on the top right of the site.</p>
                        )}
                    </div>
                )}
                {characters.map((character, i) => {
                    return <Character.Card key={character.id}
                        character={character}
                        edit={this._openModal.bind(this, character.id)}
                        organizations={course.organizations}>
                        <p className="help-block">
                            <i className="fa fa-info"></i> Sending {character.activities.length} emails {` `}
                            {Object.keys(character.activitiesSentToRoles).length > 0 && <span>
                                ({Object.keys(character.activitiesSentToRoles).map(roleStaticId => {
                                    let role = character.activitiesSentToRoles[roleStaticId]
                                    return <span key={role.roleStaticId}>{role.count}x {role.roleName} </span>
                                })})
                            </span>}
                        </p>
                    </Character.Card>

                })}


            </div>
        )
    }


    _openModal(characterId) {
        const course = this.props.state.course.model
        this.props.actions.local.modal('character', characterId)
        //this.props.history.push(`/course/configuration/${course.id}/characters/${characterId === null ? 'new' : characterId}`)
    }

    _closeModal() {
        const course = this.props.state.course.model
        this.props.history.push(`/course/configuration/${course.id}/characters`)
    }
}

export default ReduxBinder(CourseConfigurationTabCharacters, {
    state: ['course', 'character']
})
