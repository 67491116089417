import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormAnswers from './FormAnswers'

export default class FormResults extends Component {

    static propTypes = {
        form: PropTypes.object.isRequired,
        activityLog: PropTypes.object,
        course: PropTypes.object
    }

    render() {
        const { form, activityLog, course } = this.props

        const answeredBy = course.byId.participants[activityLog.fromParticipantId] ? course.byId.participants[activityLog.fromParticipantId].displayName.bigTeamThenName : "Not found"
        const answeredFor = course.byId.participants[activityLog.participantId] ? course.byId.participants[activityLog.participantId].displayName.bigTeamThenName : "Not found"

        let qas = []
        let scoringMatrix = null
        let activity = null
        if (activityLog.data.activityId) {
            activity = course.byId.activities[activityLog.data.activityId]
            if (activity
                && activity.type === 'rolePlay'
                && activity.data.generator
                && activity.data.generator.type === 'n-party'
                && activity.data.generator.settings
                && activity.data.generator.settings.globalFormUseScoringMatrix === true) {
                scoringMatrix = activity.data.generator.settings.scoringMatrix
            }

        }

        form.questions.map(question => {
            let qa = {
                question: question.question,
                answer: "None",
                score: null,
                scoringTagName: null,
                variable: {
                    impact: "",
                    name: ""
                }
            }
            let currentAnswerObject = activityLog.data.answers.filter(answer => answer.id === question.id)
            // console.log(activityLog, question)
            if (currentAnswerObject.length > 0) {
                qa.answer = currentAnswerObject[0].answer
                qa.feedback = question.feedback
                if (question.type === 'multiple-choice') {
                    // Normal form scoring
                    question.options.map(option => {
                        if (option.id === currentAnswerObject[0].answer) {
                            qa.answer = option.text
                            let participant = course.byId.participants[activityLog.participantId]

                            if (scoringMatrix
                                && scoringMatrix[question.id]
                                && scoringMatrix[question.id][option.id]
                                && scoringMatrix[question.id][option.id][participant.roleStaticId]) {
                                if (activity.data.generator.settings.globalFormStaticId === form.staticId) {
                                    let scoringMatrixValue = activity.data.generator.settings.scoringMatrix[question.id][option.id][participant.roleStaticId]
                                    if (scoringMatrixValue.score) {
                                        qa.score = (scoringMatrixValue.score || 0) + '.0 / 5'
                                    }
                                    if (scoringMatrixValue.variableStaticId) {
                                        qa.variable.name = course.byStaticId.variables[scoringMatrixValue.variableStaticId] ? course.byStaticId.variables[scoringMatrixValue.variableStaticId].name : ""
                                        qa.variable.impactCoefficient = scoringMatrixValue.impact
                                    }
                                }
                            }

                            if (!qa.score && option.score) {
                                qa.score = option.score + '.0 / 5'
                                if (option.impact) {
                                    qa.variable.impactCoefficient = option.impact
                                }

                                if (question.scoringTagStaticId) {
                                    qa.scoringTagName = course.byStaticId.scoringTags[question.scoringTagStaticId] ? course.byStaticId.scoringTags[question.scoringTagStaticId].name : ""
                                }

                            }
                            if (question.variableStaticId) {
                                qa.variable.name = course.byStaticId.variables[question.variableStaticId] ? course.byStaticId.variables[question.variableStaticId].name : ""
                                qa.variable.impact = "No impact"
                            }

                            if (question.variableStaticId && option.impact) {
                                switch (option.impact) {
                                    case -1:
                                        qa.variable.impact = "Very negative impact"
                                        break
                                    case -0.5:
                                        qa.variable.impact = "Negative impact"
                                        break
                                    case 0:
                                        qa.variable.impact = "No impact"
                                        break
                                    case 0.5:
                                        qa.variable.impact = "Positive impact"
                                        break
                                    case 1:
                                        qa.variable.impact = "Very positive impact"
                                        break
                                }
                            }
                        }
                    })
                }
                qas.push(qa)
            }
        })

        let testForm = form
        let testParticipants = [{ id: activityLog.participantId }]

        return (
            <div onSubmit={this._onSubmit}>
                <br />

                <p>
                    <strong>Who answered the form:</strong>
                    <br />
                    {answeredBy}
                </p>
                <p>
                    <strong>Who was graded:</strong>
                    <br />
                    {answeredFor}
                </p>
                <br />

                <FormAnswers course={course}
                    form={testForm}
                    participants={testParticipants}
                    logs={[activityLog]}
                    showAllAnswers={true} />

            </div>
        )
    }
}