import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal } from 'alias-components'
import api from 'alias-tools/api'

export default class CourseJoinWithInvite extends Component {
    static propTypes = {
        isShown: PropTypes.bool,
        onClose: PropTypes.func,
        user: PropTypes.object
    }
    static defaultProps = {
        isShown: false,
        onClose: () => { },
        user: { id: '' }
    }
    constructor(props) {
        super(props)

        this.state = {
            inviteCode: ''
        }
    }
    render() {
        const { props, state } = this


        return <Modal
            isShown={props.isShown}
            close={props.onClose}
            title="Join a case with an invitation code"
            showHeader={true}
            showFooter={true}>
            <div>
                <form onSubmit={this._onSubmit.bind(this)}>
                    <p>Please enter your invitation code below:</p>
                    <Input type="text"
                        value={state.inviteCode}
                        placeholder="Enter code here"
                        onChange={(name, value) => this.setState({ inviteCode: value })} />
                    <button className="btn btn-primary" type="submit">Join</button>
                </form>
            </div>
        </Modal>
    }
    _onSubmit(e) {
        e.preventDefault()
        const { props, state } = this
        if (!state.inviteCode) {
            return alert("Please enter an invitation code")
        }
        if (!state.inviteCode || !props.user) {
            return
        }
        this.setState({ isLoading: true })
        api(`/courses/invite/${state.inviteCode}/${props.user.id}`, 'GET', {})
            .then((response) => {
                if (response && response.data && response.data.success) {
                    window.location.replace(`${window.location.origin}/course/my/${response.data.participant.courseId}`)
                } else {
                    alert("Wrong invitation code. Please contact the person that sent you the code and try again.")
                }
            })
            .catch((response) => {
                console.warn(response) // eslint-disable-line
                alert("Something went wrong, plese try again")
                this.setState({ isLoading: false })
            })
    }
}
