import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

// Home
import { } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'


class EmailConfirm extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.props.actions.auth.clearMessages()
        setTimeout(this._redirect.bind(this), 2000)
    }

    render() {

        return (
            <div className="form-horizontal">
                <Helmet title="Email confirmation" />
                <fieldset>
                    <legend>CONFIRMING EMAIL</legend>
                </fieldset>
                {this.state.isLoading && (
                    <div>
                        <p style={{ textAlign: 'center' }}>Please wait while we verify your email.</p>
                        <p style={{ textAlign: 'center', fontSize: '18px' }}><i className="fa fa-spinner fa-pulse" /></p>
                    </div>
                )}
                {!this.state.isLoading && (
                    <div>
                        <p className="alert alert-success">Email confirmed</p>
                        <p style={{ textAlign: 'center', fontSize: '18px' }}><i className="fa fa-spinner fa-pulse" /></p>
                    </div>
                )}
            </div>
        )
    }

    _redirect() {
        window.location.href = "/"
    }
}

export default ReduxBinder(EmailConfirm, { state: ['auth', 'user'] })