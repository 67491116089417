import api, { apiUpload } from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'
import Badge from '../../app/authenticated/pages/components/Badge'
import Scoring from '../../app/authenticated/pages/components/Scoring'
import moment from 'moment-timezone'

const BASE_PATH = '/courses'
//
// Action types
//
const CLEAR = 'si/course/CLEAR'
const FETCHING = 'si/course/FETCHING'
const LOADED = 'si/course/LOADED'
const SAVING = 'si/course/SAVING'
const SAVED = 'si/course/SAVED'
const REMOVE = 'si/course/REMOVE'
const ERROR = 'si/course/ERROR'

//
// From other modules
//
const MODULE_SAVED = 'si/module/SAVED'
const MODULE_REMOVE = 'si/module/REMOVE'

const ADD_USERZ = 'si/participant/SAVED'
const REMOVE_USERZ = 'si/participant/REMOVE'
const REMOVE_ALL_USERZ = 'si/participant/REMOVE_ALL'


const CHARACTER_SAVED = 'si/character/SAVED'
const CHARACTER_REMOVE = 'si/character/REMOVE'

const ACTIVITY_SAVED = 'si/activity/SAVED'
const ACTIVITY_REMOVE = 'si/activity/REMOVE'

const ACTIVITY_LOG_SAVED = 'si/activityLog/SAVED'
const ACTIVITY_LOG_REMOVE = 'si/activityLog/REMOVE'
const ACTIVITY_LOG_PARTICIPANT_READ = 'si/activityLog/ACTIVITY_LOG_PARTICIPANT_READ'
const ACTIVITY_LOG_PARTICIPANT_READ_COUNT = 'si/activityLog/ACTIVITY_LOG_PARTICIPANT_READ_COUNT'


const ORGANIZATION_SAVED = 'si/organization/SAVED'
const ORGANIZATION_REMOVE = 'si/organization/REMOVE'

const ROLE_SAVED = 'si/role/SAVED'
const ROLE_REMOVE = 'si/role/REMOVE'

const TEAM_SAVED = 'si/team/SAVED'
const TEAM_REMOVE = 'si/team/REMOVE'

const THREAD_SAVED = 'si/thread/SAVED'
const THREAD_REMOVE = 'si/thread/REMOVE'

const DOC_SAVED = 'si/doc/SAVED'
const DOC_REMOVE = 'si/doc/REMOVE'

const FORM_SAVED = 'si/form/SAVED'
const FORM_REMOVE = 'si/form/REMOVE'

const VARIABLE_SAVED = 'si/variable/SAVED'
const VARIABLE_REMOVE = 'si/variable/REMOVE'

const VALUE_SAVED = 'si/value/SAVED'
const VALUE_REMOVE = 'si/value/REMOVE'

const SCORING_TAG_SAVED = 'si/scoringTag/SAVED'
const SCORING_TAG_REMOVE = 'si/scoringTag/REMOVE'

const BADGE_SAVED = 'si/badge/SAVED'
const BADGE_REMOVE = 'si/badge/REMOVE'

const FORUM_SAVED = 'si/forum/SAVED'
const FORUM_REMOVE = 'si/forum/REMOVE'

const LOGOUT = 'si/auth/multi/LOGOUT'

const initialState = {
    isFetching: false, // When it's getting data from the server (usually to show a loader)
    isSaving: false,
    messages: {
        error: null, // String
        success: null // String
    },
    model: {
        ...structure.course,
        byId: {},
        byStaticId: {}
    },
    lastRefresh: null
}

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    let participants = []
    let course = state.model
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false,
                isSaving: false
            }
        case FETCHING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: true
            }
        case LOADED:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: {
                    ...initialState.model,
                    ...action.model
                },
                lastRefresh: new Date().toISOString()
            }
        case SAVING:
            if (action.model) {
                return {
                    ...state,
                    messages: initialState.messages,
                    isSaving: true,
                    isFetching: false,
                    model: {
                        ...state.model,
                        ...action.model
                    }
                }
            }
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case SAVED:
            if (action.model) {
                return {
                    ...state,
                    messages: initialState.messages,
                    isSaving: false,
                    isFetching: false,
                    model: {
                        ...state.model,
                        ...action.model
                    }
                }
            }
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false
            }
        case REMOVE:
            return initialState
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isFetching: false,
                isSaving: false
            }
        case ADD_USERZ: {
            let hasUpdate = false
            participants = course.participants.map((participant) => {
                if (participant.id === action.model.id) {
                    hasUpdate = true
                    return action.model
                }
                return participant
            })
            if (!hasUpdate) {
                participants.push(action.model)
            }
            course = {
                ...state.model,
                participants: participants
            }
            course = prepParticipants(course)
            course = prepTeams(course)
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: course
            }
        }
        case REMOVE_USERZ:
            participants = state.model.participants.filter((participant) => {
                return participant.id !== action.model.id
            })
            course = {
                ...state.model,
                participants: participants
            }
            course = prepParticipants(course)
            course = prepTeams(course)
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: course
            }
        case REMOVE_ALL_USERZ: {
            let participants = state.model.participants.filter((participant) => {
                return participant.isAdmin
            })

            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: {
                    ...state.model,
                    participants: participants
                }
            }
        }

        //
        //
        //
        // MODULES
        case MODULE_SAVED:
            if (action.model) {
                let isUpdate = false

                let modules = course.modules.map((module) => {
                    if (module.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return module
                })
                if (!isUpdate) {
                    modules.push(action.model)
                }
                course = {
                    ...state.model,
                    modules: modules
                }
                course = prepModules(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case MODULE_REMOVE:
            if (action.model) {
                const modules = state.model.modules.filter(module => module.id !== action.model.id)
                const activities = state.model.activities.filter(activity => activity.moduleStaticId !== action.model.staticId)
                course = {
                    ...state.model,
                    modules: modules,
                    activities: activities
                }
                course = prepModules(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state



        // FORM
        case FORM_SAVED:
            if (action.model) {
                let isUpdate = false

                let forms = state.model.forms.map((form) => {
                    if (form.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return form
                })
                if (!isUpdate) {
                    forms.push(action.model)
                }
                course = {
                    ...state.model,
                    forms: forms
                }
                course = prepForms(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case FORM_REMOVE:
            if (action.model) {
                const forms = state.model.forms.filter((form) => form.id !== action.model.id)
                return {
                    ...state,
                    model: {
                        ...state.model,
                        forms: forms
                    }
                }
            }
            return state



        // ORGANIZATION
        case ORGANIZATION_SAVED:
            if (action.model) {
                let isUpdate = false

                let organizations = state.model.organizations.map((org) => {
                    if (org.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return org
                })
                if (!isUpdate) {
                    organizations.push(action.model)
                }
                course = {
                    ...state.model,
                    organizations: organizations
                }
                course = prepOrganizations(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case ORGANIZATION_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let organizations = state.model.organizations.filter((org) => org.id !== action.model.id)
                // Update characters
                let characters = state.model.characters.map(character => {
                    if (character.organizationStaticId === action.model.staticId) {
                        character.organizationStaticId = ""
                    }
                    return character
                })
                // Update roles
                let roles = state.model.roles.map(role => {
                    if (role.organizationStaticId === action.model.staticId) {
                        role.organizationStaticId = ""
                    }
                    return role
                })
                let course = {
                    ...state.model,
                    organizations: organizations,
                    characters: characters,
                    roles: roles
                }
                course = prepCourse(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state



        // CHARACTERS
        case CHARACTER_SAVED:
            if (action.model && action.model.courseId !== 0) {
                let isUpdate = false

                let characters = state.model.characters.map((character) => {
                    if (character.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return character
                })
                if (!isUpdate) {
                    characters.push(action.model)
                }
                // characters = sortCharacters(characters)
                course = {
                    ...state.model,
                    characters: characters
                }
                course = prepCharacters(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case CHARACTER_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let characters = state.model.characters.filter((character) => character.id !== action.model.id)
                // Update activities
                let activities = state.model.activities.map(activity => {
                    if (activity.characterStaticId === action.model.staticId) {
                        activity.characterStaticId = null
                    }
                    return activity
                })
                return {
                    ...state,
                    model: {
                        ...state.model,
                        characters: characters,
                        activities: activities
                    }
                }
            }
            return state



        // ACTIVITY
        case ACTIVITY_SAVED:
            if (action.model && action.model.courseId !== 0) {
                let isUpdate = false

                let activities = state.model.activities.map(activity => {
                    if (activity.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return activity
                })
                if (!isUpdate) {
                    activities.push(action.model)
                }
                course = {
                    ...state.model,
                    activities: activities
                }
                course = prepActivities(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case ACTIVITY_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let activities = state.model.activities.filter((activity) => {
                    if (activity.id === action.model.id) {
                        return false
                    }
                    return true
                })
                const activityLogs = state.model.activityLogs.filter(log => log.activityStaticId !== action.model.staticId)
                course = {
                    ...state.model,
                    activities: activities,
                    activityLogs: activityLogs
                }
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state

        //
        //
        // ACTIVITY LOG
        case ACTIVITY_LOG_SAVED:
            if (action.model && action.model.courseId !== 0) {
                let isUpdate = false

                let activityLogs = state.model.activityLogs.map((activity) => {
                    if (activity.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return activity
                })
                if (!isUpdate) {
                    activityLogs.push(action.model)
                }
                course = {
                    ...state.model,
                    activityLogs: activityLogs
                }
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case ACTIVITY_LOG_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let activityLogs = state.model.activityLogs.filter((activity) => activity.id !== action.model.id)
                course = {
                    ...state.model,
                    activityLogs: activityLogs
                }
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case ACTIVITY_LOG_PARTICIPANT_READ: {
            // Save the fact that I fetched the data
            let participants = state.model.participants
            if (action.participantId) {
                participants = participants.map(p => {
                    if (p.id === action.participantId) {
                        p.hasFetchedReadActivity = true
                    }
                    return p
                })
                course = prepParticipants(course)
            }
            // ADd logs if necessary
            let activityLogs = state.model.activityLogs
            if (!action.activityLogs || (action.activityLogs && action.activityLogs.length === 0)) {
                return {
                    ...state,
                    model: course
                }
            }
            let oldestLog = action.activityLogs[action.activityLogs.length - 1]
            let doesItExist = course.activityLogs.filter(l => l.id === oldestLog.id).length > 0
            if (doesItExist) {
                return {
                    ...state,
                    model: course
                }
            }
            activityLogs = [
                ...activityLogs,
                ...action.activityLogs
            ]



            course = {
                ...state.model,
                activityLogs: activityLogs,
                participants: participants
            }

            return {
                ...state,
                model: course
            }
        }
        case ACTIVITY_LOG_PARTICIPANT_READ_COUNT: {
            if (action.counts) {
                let participants = state.model.participants
                    .map(participant => {
                        if (action.counts[participant.id]) {
                            participant.readCount = action.counts[participant.id]
                        }
                        return participant
                    })
                course = {
                    ...course,
                    ...participants
                }
                course = prepParticipants(course)
            }
            return {
                ...state,
                model: course
            }
        }
        //
        //
        // ROLE
        case ROLE_SAVED:
            if (action.model && action.model.courseId !== 0) {
                let isUpdate = false

                let roles = state.model.roles.map((role) => {
                    if (role.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return role
                })
                if (!isUpdate) {
                    roles.push(action.model)
                }
                course = {
                    ...state.model,
                    roles: roles
                }
                course = prepRoles(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case ROLE_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let roles = state.model.roles.filter((role) => {
                    if (role.id === action.model.id) {
                        return false
                    }
                    return true
                })
                let participants = state.model.participants.map(participant => {
                    participant.roles = participant.roles.filter(roleStaticId => roleStaticId !== action.model.staticId)
                    return participant
                })
                let activities = state.model.activities.map(activity => {
                    if (activity.roleStaticId === action.model.staticId) {
                        activity.roleStaticId = null
                    }
                    return activity
                })
                let course = {
                    ...state.model,
                    roles: roles,
                    participants: participants,
                    activities: activities
                }
                course = prepCourse(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        //
        //
        // FORUM
        case FORUM_SAVED:
            if (action.model && action.model.courseId !== 0) {
                let isUpdate = false

                let forums = state.model.forums.map((forum) => {
                    if (action.model.parentId && forum.id === action.model.parentId) {
                        forum.comments = forum.comments || []
                        forum.comments = forum.comments.map(p => {
                            if (p.id === action.model.id) {
                                isUpdate = true
                                return action.model
                            }
                            return p
                        })
                        if (!isUpdate) {
                            forum.comments.push(action.model)
                        }
                    }
                    if (forum.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }

                    return forum
                })
                if (!isUpdate && !action.model.parentId) {
                    forums.push(action.model)
                }
                course = {
                    ...state.model,
                    forums: forums
                }
                course = prepForums(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case FORUM_REMOVE:
            if (action.model && action.model.courseId !== 0) {
                let forums = state.model.forums
                if (!action.model.parentId) {
                    forums = forums.filter((forum) => {
                        if (forum.id === action.model.id) {
                            return false
                        }
                        return true
                    })
                } else {
                    forums = forums.map((forum) => {
                        if (forum.id === action.model.parentId) {
                            forum.comments = forum.comments || []
                            forum.comments = forum.comments.filter(comment => comment.id !== action.model.id)
                        }
                        return forum
                    })
                }
                let course = {
                    ...state.model,
                    forums: forums
                }
                course = prepForums(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state


        // TEAM
        case TEAM_SAVED:
            if (action.model) {
                let isUpdate = false
                let teams = state.model.teams.map((team) => {
                    if (team.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return team
                })
                if (!isUpdate) {
                    teams.push(action.model)
                }
                // XXXXX teams = prepTeams(teams)
                course = {
                    ...state.model,
                    teams: teams
                }
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case TEAM_REMOVE:
            if (action.model) {
                let teams = state.model.teams.filter((team) => {
                    if (team.id === action.model.id) {
                        return false
                    }
                    return true
                })
                let participants = state.model.participants.map(participant => {
                    if (participant.teamId === action.model.id) {
                        participant.teamId = null
                    }
                    return participant
                })
                course = {
                    ...state.model,
                    teams: teams,
                    participants: participants
                }
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state


        // THREADS
        case THREAD_SAVED:
            if (action.model) {
                let isUpdate = false
                let threads = state.model.threads.map((thread) => {
                    if (thread.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return thread
                })
                if (!isUpdate) {
                    threads.push(action.model)
                }
                // XXXXX threads = prepthreads(threads)
                course = {
                    ...state.model,
                    threads: threads
                }
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case THREAD_REMOVE:
            if (action.model) {
                let threads = state.model.threads.filter((thread) => {
                    if (thread.id === action.model.id) {
                        return false
                    }
                    return true
                })
                let participants = state.model.participants.map(participant => {
                    if (participant.threadId === action.model.id) {
                        participant.threadId = null
                    }
                    return participant
                })
                return {
                    ...state,
                    model: {
                        ...state.model,
                        threads: threads,
                        participants: participants
                    }
                }
            }
            return state




        // DOCS
        case DOC_SAVED:
            if (action.model) {
                let isUpdate = false

                let docs = state.model.docs.map((doc) => {
                    if (doc.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return doc
                })
                if (!isUpdate) {
                    docs.push(action.model)
                }

                return {
                    ...state,
                    model: {
                        ...state.model,
                        docs: docs
                    }
                }
            }
            return state
        case DOC_REMOVE:
            if (action.model) {
                let docs = state.model.docs.filter((doc) => {
                    if (doc.id === action.model.id) {
                        return false
                    }
                    return true
                })
                return {
                    ...state,
                    model: {
                        ...state.model,
                        docs: docs
                    }
                }
            }
            return state

        // VALUE
        case VALUE_SAVED:
            if (action.model) {
                let isUpdate = false

                let values = state.model.values.map((value) => {
                    if (value.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return value
                })
                if (!isUpdate) {
                    values.push(action.model)
                }
                course = {
                    ...state.model,
                    values: values
                }
                course = prepValues(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case VALUE_REMOVE:
            if (action.model) {
                const values = state.model.values.filter((value) => value.id !== action.model.id)
                course = {
                    ...state.model,
                    values: values
                }
                course = prepValues(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state

        // VARIABLE
        case VARIABLE_SAVED:
            if (action.model) {
                let isUpdate = false

                let variables = state.model.variables.map((variable) => {
                    if (variable.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return variable
                })
                if (!isUpdate) {
                    variables.push(action.model)
                }
                course = {
                    ...state.model,
                    variables: variables
                }
                course = prepVariables(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case VARIABLE_REMOVE:
            if (action.model) {
                const variables = state.model.variables.filter((variable) => variable.id !== action.model.id)
                course = {
                    ...state.model,
                    variables: variables
                }
                course = prepVariables(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state

        // SCORING TAG
        case SCORING_TAG_SAVED:
            if (action.model) {
                let isUpdate = false

                let scoringTags = state.model.scoringTags.map((scoringTag) => {
                    if (scoringTag.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return scoringTag
                })
                if (!isUpdate) {
                    scoringTags.push(action.model)
                }
                course = {
                    ...state.model,
                    scoringTags: scoringTags
                }
                course = prepScoringTags(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case SCORING_TAG_REMOVE:
            if (action.model) {
                const scoringTags = state.model.scoringTags.filter((scoringTag) => scoringTag.id !== action.model.id)
                let course = {
                    ...state.model,
                    scoringTags: scoringTags
                }
                course = prepScoringTags(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state

        // BADGE
        case BADGE_SAVED:
            if (action.model) {
                let isUpdate = false
                let badges = state.model.badges.map((badge) => {
                    if (badge.id === action.model.id) {
                        isUpdate = true
                        return action.model
                    }
                    return badge
                })
                if (!isUpdate) {
                    badges.push(action.model)
                }
                course = {
                    ...state.model,
                    badges: badges
                }
                course = prepBadges(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state
        case BADGE_REMOVE:
            if (action.model) {
                const badges = state.model.badges.filter((badge) => badge.id !== action.model.id)
                let course = {
                    ...state.model,
                    badges: badges
                }
                course = prepBadges(course)
                course = prepParticipants(course)
                course = prepTeams(course)
                return {
                    ...state,
                    model: course
                }
            }
            return state




        case LOGOUT:
            return initialState
        default:
            return state
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return { type: CLEAR }
}


export function getCourseInfoForInvite(courseId, callback = () => { }) {
    return (dispatch, getState) => {
        api(`/courses/info/${courseId}`, 'GET', {}, getState(), {}, dispatch)
            .then((response) => {
                if (callback) {
                    callback(response)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function addParticipantThroughInviteCode(inviteCode, callback = () => { }) {
    if (!inviteCode) {
        return
    }
    return (dispatch, getState) => {
        api(`/courses/invite/${inviteCode}`, 'GET', {}, getState(), {}, dispatch)
            .then((response) => {
                if (callback) {
                    callback(response)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}



export function load(modelId, callback, refresh = false, participantId = null) {
    return (dispatch, getState) => {
        // Sets the loading state
        if (!refresh) {
            dispatch({ type: FETCHING })
        }
        let url = '/courses/fetch/' + modelId
        url += participantId ? "/" + participantId : ''
        api(url, 'GET', {}, getState(), {}, dispatch)
            .then((response) => {
                let course = response.data
                course.byId = {}
                course.byStaticId = {}
                // Sort and add byId
                course = prepCourse(course)

                dispatch({ type: LOADED, model: course })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function create(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        let state = getState()
        delete model.id
        api('/userz/' + state.user.model.id + '/courses', 'POST', model, state, {}, dispatch) //
            .then((response) => {
                dispatch({ type: SAVED, model: response.data })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function update(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        let state = getState()
        let toUpdate = { ...model }
        let dontUpload = ['characters', 'activities', 'organizations', 'roles', 'teams',
            'threads', 'participants', 'docs', 'forms'
            , 'activityLogs', 'avatar', 'variables', 'scoringTags', 'byId', 'byStaticId'
            , 'badges', 'forums', 'modules']
        dontUpload.map(item => {
            delete toUpdate[item]
        })


        api(BASE_PATH + '/' + model.id, 'PATCH', toUpdate, state, {}, dispatch) //
            .then((response) => {
                dispatch({ type: SAVED, model: model })
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Case saved'
                    }
                })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function remove(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        let state = getState()
        api(BASE_PATH + '/' + model.id, 'DELETE', {}, state, {}, dispatch) //
            .then((response) => {
                dispatch({ type: REMOVE, model: model })
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'warning',
                        title: 'Case deleted'
                    }
                })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function inviteParticipants(courseId, emails, callback) {
    return (dispatch, getState) => {
        api(BASE_PATH + '/' + courseId + '/mail/invite', 'POST', { emails: emails }, getState(), {
            where: {
                isPublic: true
            }
        }, dispatch) //
            .then((response) => {
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Email invite sent'
                    }
                })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function getMarket(callback) {
    return (dispatch, getState) => {
        api(BASE_PATH + '/', 'GET', {}, getState(), {
            where: {
                isPublic: true
            }
        }, dispatch)
            .then((response) => {
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function getMarketById(id, callback) {
    return (dispatch, getState) => {
        api(BASE_PATH + '/' + id, 'GET', {}, getState(), {}, dispatch) //
            .then((response) => {
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
            })
    }
}


export function avatar(id, newAvatar, callback) {
    return (dispatch, getState) => {

        var formData = new FormData()
        formData.append("file", newAvatar)

        apiUpload(BASE_PATH + '/' + id + '/avatar/upload', formData, null, null, getState())
            .then((response) => {
                dispatch({ type: SAVED, model: response.data })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch((response) => {
                errorHandling(dispatch, ERROR, response)
                if (callback) {
                    callback(response.data)
                }
            })
    }
}

export function liveAdd(model) {
    return { type: SAVED, model: model }
}
export function liveUpdate(model) {
    return { type: SAVED, model: model }
}
export function liveRemove(model) {
    return { type: REMOVE, model: model }
}

function prepCourse(course) {
    course = prepOrganizations(course)
    course = prepCharacters(course)
    course = prepRoles(course)
    course = prepActivities(course)
    course = prepValues(course)
    course = prepVariables(course)
    course = prepScoringTags(course)
    course = prepBadges(course)
    course = prepForms(course)
    course = prepModules(course)
    course = prepTeams(course)
    course = prepParticipants(course)
    course = prepForums(course)
    if (!course.timezone) {
        console.warn("This course doesn't have a timezone")  //eslint-disable-line
        console.warn(course) //eslint-disable-line
        course.timezone = "Europe/Paris"
    }
    return course
}


function prepForums(course) {
    course.byId.forums = {}
    course.forums = course.forums || []
    course.activities.map(activity => {
        if (course.forums.filter(f => f.id === activity.staticId).length > 0) { return }
        if (activity.isDraft) { return }
        if (activity.type !== "forum") { return }
        if (!activity.parentStaticId) { return }
        let parentActivity = course.byStaticId.activities[activity.parentStaticId]
        if (!parentActivity) { return }
        let module = course.byStaticId.modules[parentActivity.moduleStaticId]
        if (!module) { return }
        if (module.isDraft) { return }
        let sendTime = moment(course.start).tz(course.timezone).add(module.offset, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm')
        if (moment().isBefore(sendTime)) { return }

        course.forums.push({
            id: activity.staticId,
            participantId: activity.characterStaticId,
            voteCount: 0,
            title: activity.data.title,
            text: activity.data.text,
            video: activity.data.video,
            votes: [],
            created: sendTime.format(),
            updated: sendTime.format(),
            formData: {
                activityId: activity.id,
                embeddedFormStaticId: activity.embeddedFormStaticId
            },
            embeddedFormStaticId: activity.embeddedFormStaticId,
            type: 'activity'
        })
    })
    course.forums = course.forums
        .map(post => {
            post.comments = post.comments || []
            course.forums.filter(p => {
                if (p.parentId && p.parentId === post.id) {
                    post.comments.push(p)
                }
            })
            course.byId.forums[post.id] = post
            return post
        })
        .filter(p => !p.parentId)
        .map(p => {
            // For sorting, if votes count to push up, then use updated, otherwise use created
            p.latestUpdate = p.updated
            p.comments.map(c => {
                if (new Date(c.updated) - new Date(p.latestUpdate) > 0) {
                    p.latestUpdate = c.updated
                }
            })
            return p
        })
        .sort((a, b) => {
            return new Date(b.latestUpdate) - new Date(a.latestUpdate)
        })

    return course
}

function prepOrganizations(course) {
    course.byId.organizations = {}
    course.byStaticId.organizations = {}
    course.organizations = course.organizations
        .sort((a, b) => {
            let nameA = a.name.toLowerCase()
            let nameB = b.name.toLowerCase()
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) { return 1 }

            return 0
        })
        .map(org => {
            course.byId.organizations[org.id] = org
            course.byStaticId.organizations[org.staticId] = org
            return org
        })
    return course
}
function prepCharacters(course) {
    course.byId.characters = {}
    course.byStaticId.characters = {}
    course.characters = course.characters
        .sort((a, b) => {
            let nameA = a.last.toLowerCase()
            let nameB = b.last.toLowerCase()
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
        .map(char => {
            course.byId.characters[char.id] = char
            course.byStaticId.characters[char.staticId] = char
            return char
        })
    return course
}



function prepRoles(course) {
    course.byId.roles = {}
    course.byStaticId.roles = {}
    course.roles = course.roles.sort((a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }).map(role => {
        course.byId.roles[role.id] = role
        course.byStaticId.roles[role.staticId] = role
        role.organization = role.organizationStaticId && course.byStaticId.organizations && course.byStaticId.organizations[role.organizationStaticId] ? course.byStaticId.organizations[role.organizationStaticId] : null
        return role
    })
    return course
}

function prepForms(course) {
    course.byId.forms = {}
    course.byStaticId.forms = {}
    course.forms = course.forms.sort((a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
        .map(form => {
            course.byId.forms[form.id] = form
            course.byStaticId.forms[form.staticId] = form
            return form
        })
    return course
}

function prepParticipants(course) {
    course.byId.participants = {}
    course.participants = course.participants
        .map(participant => {
            // Display name
            let user = participant.userz || { email: '', first: '', last: '' }
            participant.teamName = (participant.teamId && course.byId.teams && course.byId.teams[participant.teamId]) ? course.byId.teams[participant.teamId].name : ""
            let short = `${user.first}${user.first ? ' ' : ''}${user.last}${(!user.first && !user.last) ? user.email : ''}`
            participant.displayName = {
                short: short,
                withEmail: `${user.first}${user.first ? ' ' : ''}${user.last} (${user.email})`,
                withTeam: `${short}${participant.teamName ? ' - ' : ''}${participant.teamName}`,
                // withRole: `${short}${(participant.teamName || participant.isAdmin || participant.isActor) ? ' - ' : ''}${participant.roleName}${participant.isAdmin ? 'Author' : ''}${participant.isActor ? 'Actor' : ''}`,
                // withRoleAndTeam: `${short}${participant.roleName ? ' - ' : ''}${participant.roleName}${participant.teamName ? ' - ' : ''}${participant.teamName}`,
                // bigTeamThenName: `${participant.teamName ? participant.teamName.toUpperCase() + ' - ' : ''}${participant.roleName ? participant.roleName + ' - ' : ''}${participant.isAdmin ? '(Author) ' : ''}${short}`,
                bigTeamThenName: `${participant.teamName ? participant.teamName.toUpperCase() + ' - ' : ''}${participant.isAdmin ? '(Author) ' : ''}${short}`,
                forSorting: `${user.last}${user.last ? ' ' : ''}${user.first}${(!user.first && !user.last) ? '' : ' '}${user.email}`.toLowerCase()
            }
            // Roles
            participant.roleNames = participant.roles.map(roleStaticId => {
                if (course.byStaticId.roles[roleStaticId]) {
                    return course.byStaticId.roles[roleStaticId].name
                }
                return 'ROLE NOT FOUND'
            })
            // participant.roleNamesString = participant.roleNames.join(', ')

            // Score & badges
            if (!course.isTemplate && !participant.isAdmin) {
                participant.score = Scoring.Tools.participant(participant, course.activities, course.activityLogs, course.forms, course.scoringTags)
                participant.badgesInfo = Badge.Tools.getParticipantBadges(course, participant)
            }
            // add to ID search
            course.byId.participants[participant.id] = participant

            return participant
        })
        .sort((a, b) => {
            if (a.forSorting < b.forSorting) {
                return -1
            }
            if (a.forSorting > b.forSorting) {
                return 1
            }
            return 0
        })
    return course
}

function prepTeams(course) {
    course.byId.teams = {}
    course.teams = course.teams.sort((a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }).map(team => {
        if (!course.isTemplate) {
            team.score = Scoring.Tools.team(team.id, course.participants.filter(p => p.teamId === team.id), course.activities, course.activityLogs, course.forms, course.scoringTags)
            team.badgesInfo = Badge.Tools.getTeamBadges(course, team.id)
        }
        course.byId.teams[team.id] = team

        if (team.participants) {
            team.participants = team.participants
                .sort((a, b) => {
                    if (a.forSorting < b.forSorting) {
                        return -1
                    }
                    if (a.forSorting > b.forSorting) {
                        return 1
                    }
                    return 0
                })
        }
        return team
    })
    return course
}

function prepActivities(course) {
    course.byId.activities = {}
    course.byStaticId.activities = {}
    course.activities = course.activities
        .map(activity => {
            course.byId.activities[activity.id] = activity
            course.byStaticId.activities[activity.staticId] = activity
            return activity
        })
        .sort((a, b) => a.offset - b.offset)
    return course
}

function prepVariables(course) {
    course.byId.variables = {}
    course.byStaticId.variables = {}
    course.variables = course.variables
        .map(variable => {
            course.byId.variables[variable.id] = variable
            course.byStaticId.variables[variable.staticId] = variable
            return variable
        })
        .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
        })
    return course
}

function prepValues(course) {
    course.byId.values = {}
    course.byStaticId.values = {}
    course.values = course.values
        .map(value => {
            course.byId.values[value.id] = value
            course.byStaticId.values[value.staticId] = value
            return value
        })
        .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
        })
    return course
}

function prepScoringTags(course) {
    course.byId.scoringTags = {}
    course.byStaticId.scoringTags = {}
    course.scoringTags = course.scoringTags
        .map(scoringTag => {
            course.byId.scoringTags[scoringTag.id] = scoringTag
            course.byStaticId.scoringTags[scoringTag.staticId] = scoringTag
            return scoringTag
        })
        .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
        })
    return course
}

function prepBadges(course) {
    course.byId.badges = {}
    course.byStaticId.badges = {}
    course.badges = course.badges
        .map(badge => {
            course.byId.badges[badge.id] = badge
            course.byStaticId.badges[badge.staticId] = badge
            return badge
        })
        .sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
        })
    return course
}

function prepModules(course) {
    course.byId.modules = {}
    course.byStaticId.modules = {}
    course.modules = course.modules
        .sort((a, b) => {
            return a.offset - b.offset
        })
        .map(module => {
            module.start = moment(course.start).tz(course.timezone).add(module.offset || 0, 'm')
            course.byId.modules[module.id] = module
            course.byStaticId.modules[module.staticId] = module
            return module
        })
    return course
}

// function sortMyStuff(arr, attribute) {
//     return arr.sort((a, b) => {
//         const nameA = a.name.toLowerCase()
//         const nameB = b.name.toLowerCase()
//         if (nameA < nameB) { return -1 }
//         if (nameA > nameB) { return 1 }
//         return 0
//     })
// }