import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'

export default class ParticipantCard extends Component {

    static propTypes = {
        participant: PropTypes.object.isRequired,
        canEdit: PropTypes.bool,
        onEdit: PropTypes.func,
        children: PropTypes.any
    }

    static defaultProps = {
        canEdit: false
    }

    render() {
        const participant = this.props.participant

        return <Card>
            <div className="card-course" style={{ minHeight: 80 }}>
                {this.props.canEdit && (<a onClick={() => { this.props.onEdit(participant) }} className="card-edit"><i className="fa fa-pencil"></i></a>)}
                <div className="left">
                    <Avatar user={participant.userz} size={80} />
                </div>
                <div className="right">
                    <h4 style={{ marginTop: 0, marginBottom: 5 }}>{participant.displayName.withEmail}</h4>
                    {this.props.children}
                </div>
            </div>
        </Card>

    }
}