import api from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'

const BASE_PATH = '/modules';
//
// Action types
//
const CLEAR = 'si/module/CLEAR';
const SAVING = 'si/module/SAVING';
const SAVED = 'si/module/SAVED';
const REMOVE = 'si/module/REMOVE';
const ERROR = 'si/module/ERROR';

const initialState = {
    isSaving: false, // When it's getting data from the server (usually to show a loader)
    messages: {
        error: null, // String
        success: null // String
    },
    model: structure.module
};

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case SAVED:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case REMOVE:
            return { ...initialState };
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isSaving: false
            }
        default:
            return state;
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return { type: CLEAR }
}

export function create(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        var state = getState();
        delete model.id;
        api('/courses/' + model.courseId + BASE_PATH, 'POST', model, state, {}, dispatch)
            . //
            then(function (response) {
                dispatch({ type: SAVED, model: response.data });
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Module created'
                    }
                });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function update(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        var state = getState();
        api(BASE_PATH + '/' + model.id, 'PATCH', model, state, {}, dispatch)
            . //
            then(function (response) {
                dispatch({ type: SAVED, model: response.data });
                if (model.showNotification !== false) {
                    dispatch({
                        type: 'si/notification/ADD',
                        notification: {
                            type: 'success',
                            title: 'Module saved'
                        }
                    });
                }
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function remove(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        var state = getState();

        api('/courses/' + model.courseId + BASE_PATH + '/' + model.id, 'DELETE', model, state, {}, dispatch)
            . //
            then(function (response) {
                dispatch({ type: REMOVE, model: model });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function liveAdd(model) {
    return { type: SAVED, model: model }
}
export function liveUpdate(model) {
    return { type: SAVED, model: model }
}
export function liveRemove(model) {
    return { type: REMOVE, model: model }
}