import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FormErrors extends Component {
    static propTypes = {
        fields: PropTypes.any
    }
    render() {
        if (this.props.fields.length === 0) {
            return null
        }
        const style = {
            padding: '10px'
        }
        return (
            <div className="alert alert-warning" style={style}>
                {this.props.fields.map(function (field, i) {
                    return (
                        <div key={i}>
                            <strong>{field.name} error</strong>
                            <ul>
                                {field.errors.map(function (error, j) {
                                    return (
                                        <li key={j}>{error}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        )
    }
}