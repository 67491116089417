import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'

const styles = {
    ul: {
        listStyle: 'none',
        padding: '5px 0 0 0',
        margin: '0'
    },
    li: {
        display: 'inline-block',
        paddingRight: '5px',
        paddingBottom: '5px',
        verticalAlign: 'top'
    }
}

export default class AvatarListing extends Component {
    static propTypes = {
        members: PropTypes.array,
        tooltips: PropTypes.bool,
        size: PropTypes.number
    }
    static defaultProps = {
        members: [],
        tooltips: false,
        size: 40
    }
    render() {
        const { members, size, tooltips } = this.props
        return <ul style={styles.ul}>
            {members.map((member, i) => <li key={member.id + i} style={styles.li}><Avatar user={member} size={size} tooltip={tooltips} tooltipPosition="bottom" /></li>)}
        </ul>
    }
}