import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, LMSChapter, LMSDisplay } from 'alias-components'
import ReduxBinder from "alias-store/ReduxBinder"
import scripts from 'alias-tools/scripts'


class ModuleTabTeachersNotes extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            module: this._findModule(props),
            moduleId: props.match.params.moduleId
        }
    }

    _findModule(props) {
        const { moduleId } = props.match.params
        const course = props.state.course.model
        let module = null
        const moduleList = course.modules.filter(m => m.id === moduleId)
        if (moduleList.length > 0) {
            module = moduleList[0]
        }
        return module
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.moduleId !== this.state.moduleId) {
            this.setState({
                module: newProps.module
            })
        }
    }
    render() {
        const course = this.props.state.course.model
        const { module } = this.state

        if (!module) {
            return <div>Module not found</div>
        }

        return (
            <div>
                <p className="help-block">
                    <i className="fa fa-info" /> Give notes for the teachers/instructors here specific to this module <br />
                    This will be shown in the module sections of the instructions and NOT to the participant.
                </p>
                <LMSChapter isEditable={true}
                    isInEditMode={true}
                    value={module.teachers}
                    onSave={this._saveModule.bind(this)}
                    isAdmin={true}
                    courseId={course.id} />


                {!scripts.isEditorEmpty(module.teacher) && (
                    <div>
                        <br />
                        <h2 style={{ paddingTop: 0 }}>Deprecated module intro</h2>
                        <p>Please copy/edit your old content to new system above.</p>
                        <p>This content will still be displayed to participants. When you are ready, please delete the data: <br />
                            <a className="btn btn-danger" onClick={this._deleteOldInfo.bind(this)}>Delete old intro</a>
                        </p>
                        <Card>
                            <LMSDisplay course={course}
                                text={module.teacher} />
                        </Card>
                    </div>
                )}
            </div>
        )
    }


    _deleteOldInfo(e) {
        if (e & e.preventDefault) { e.preventDefault() }
        let module = this.state.module
        module.teacher = null
        this.props.actions.module.update(module, () => {
            this.setState({ module: module })
        })
    }


    _saveModule(value, callback = () => { }) {
        let module = this.state.module
        module.teachers = value
        this.props.actions.module.update(module, (res) => {
            this.setState({ module: module })
            callback(res)
        })
    }



}

export default ReduxBinder(ModuleTabTeachersNotes, {
    state: ['course', 'module']
})