import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Input from './Input'
import Button from './Button'

const initialState = {
    name: "",
    courseId: 0,
    moduleStaticId: 0
}

export default class ModalActivityGroup extends Component {

    static propTypes = {
        courseId: PropTypes.any.isRequired,
        moduleStaticId: PropTypes.any.isRequired,
        isGroup: PropTypes.bool,
        actions: PropTypes.object.isRequired,
        activity: PropTypes.object,
        isOpen: PropTypes.bool,
        handleClose: PropTypes.func,
        state: PropTypes.object
    }

    static defaultProps = {
        isGroup: true
    }

    constructor(props) {
        super(props)

        var activity = (props.activity === null) ? Object.assign({}, initialState) : props.activity
        activity.courseId = props.courseId
        activity.moduleStaticId = props.moduleStaticId

        this.state = {
            activity: activity
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.activity.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.activity !== null) {
            this.setState({
                activity: nextProps.activity
            })
        } else {
            var activity = (this.props.activity === null) ? Object.assign({}, initialState) : this.props.activity
            activity.courseId = this.props.courseId
            activity.moduleStaticId = this.props.moduleStaticId

            this.setState({
                activity: activity
            })
        }
    }

    render() {
        const activity = this.state.activity

        var title = "Create a new activity"
        var btnText = "Create"
        var formSubmit = this._create


        return (
            <Modal show={this.props.isOpen} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div>
                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Accident group..."
                                value={activity.name}
                                onChange={this._onChangeNew}
                                attributes={{ 'autoFocus': true }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a onClick={this.props.handleClose} className="btn btn-default">Cancel</a>
                        <Button text={btnText} isLoading={this.props.state.activity.isSaving} />
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        let activity = this.state.activity
        activity[name] = value
        this.setState({ activity: activity })
    }

    _create(e) {
        e.preventDefault()
        const self = this
        this.props.actions.activity.create(this.state.activity, function (group) {
            self.setState({
                activity: initialState
            })
            self.props.handleClose(group)
        })
    }

    _update(e) {
        e.preventDefault()
        const self = this
        this.props.actions.activity.update(this.state.activity, function () {
            self.setState({
                activity: initialState
            })
            self.props.handleClose(null)
        })
    }
}