import api, { apiUpload } from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'
import localAuthentication from '../../tools/localAuthentication'

//
// Action types
//
const CLEAR = 'si/user/CLEAR'
const FETCHING = 'si/user/FETCHING'
const LOAD = 'si/user/LOAD'
const SAVING = 'si/user/SAVING'
const SAVED = 'si/user/SAVED'
const ERROR = 'si/user/ERROR'
// Used in other modules From other modules
const LOGIN = 'si/auth/multi/LOGIN'
const LOGOUT = 'si/auth/multi/LOGOUT'

const COURSE_SAVED = 'si/course/SAVED'
const COURSE_REMOVE = 'si/course/REMOVE'

const COURSE_PARTICIPANT_SAVED = 'si/participant/SAVED'
const COURSE_PARTICIPANT_REMOVE = 'si/participant/REMOVE'

const initialState = {
    isFetching: false,
    isLoaded: false,
    isSaving: false,
    messages: {
        error: null, // String
        success: null // String
    },
    model: structure.userz
}

const BASE_PATH = '/userz'


//
// Reducers
//
export default function reducer(state = initialState, action = {}) {

    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false,
                isSaving: false
            }
        case FETCHING:
            return {
                ...state,
                messages: initialState.messages,
                isFetching: true,
                isSaving: false
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isFetching: false,
                isSaving: false
            }
        case LOAD:
            return {
                ...state,
                model: {
                    ...state.model,
                    ...action.user
                },
                isFetching: false,
                isLoaded: true,
                messages: initialState.messages
            }
        case SAVED:
            return {
                ...state,
                model: {
                    ...state.model,
                    ...action.user
                },
                isFetching: false,
                isLoaded: true,
                isSaving: false,
                messages: initialState.messages
            }

        // FROM OTHER MODULES
        case LOGIN: // From the auth
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false,
                model: {
                    ...state.model,
                    ...action.user
                }
            }
        case LOGOUT:
            return { ...initialState }
        case COURSE_SAVED: // From the program redux
            if (!action.model) { return state }

            var participants = state.model.participants
            var foundParticipants = false
            for (var k in participants) {
                if (participants[k].course && participants[k].course.id === action.model.id) {
                    participants[k].course = action.model
                    foundParticipants = true
                    break
                }
            }

            if (!foundParticipants) {
                participants.push({ courseId: action.model.id, course: action.model, isAdmin: true })
            }
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false,
                model: {
                    ...state.model,
                    participants: participants
                }
            }
        case COURSE_REMOVE:
            participants = state.model.participants.filter((participant) => {
                return participant.course && participant.course.id !== action.model.id
            })
            return {
                ...state,
                messages: initialState.messages,
                isFetching: false,
                model: {
                    ...state.model,
                    participants: participants
                }
            }
        // COURSE PARTICIPANT
        case COURSE_PARTICIPANT_SAVED: {
            const newParticipants = action.model
            if (newParticipants.userzId !== state.model.id) { return state }

            let hasUpdate = false
            let userzParticipants = state.model.participants.map((participant) => {
                if (participant.id === newParticipants.id) {
                    hasUpdate = true
                    return {
                        ...participant,
                        ...newParticipants
                    }
                }
                return participant
            })
            if (!hasUpdate) {
                userzParticipants.push(newParticipants)
            }
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: {
                    ...state.model,
                    participants: userzParticipants
                }
            }
        }
        case COURSE_PARTICIPANT_REMOVE:
            participants = state.model.participants.filter((participant) => participant.id != action.model.id)
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false,
                model: {
                    ...state.model,
                    participants: participants
                }
            }

        default:
            return state
    }
}

//
// Action creators
//

export function clearMessages() {
    return { type: CLEAR }
}

export function load(callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({ type: FETCHING })
        // var userId = getState().user.model.id
        const userId = localAuthentication.isAuthenticated().userId
        api('/userz/' + userId, 'GET', {}, getState(), {
            include: [
                {
                    relation: 'participants',
                    "scope": {
                        "include": ["course"]
                    }
                }
            ]
        }, dispatch)
            .then(function (response) {
                dispatch({ type: LOAD, user: response.data })
                if (callback) { callback(response.data) }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
                if (callback) { callback(response.data) }
            })
    }
}

export function update(user) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({ type: SAVING })
        var newUser = {}
        for (var k in user) {
            if (k !== 'password' && k !== 'participants') {
                newUser[k] = user[k]
            }
        }
        api('/userz/' + user.id, 'PATCH', newUser, getState(), {}, dispatch)
            .then(function (response) {
                dispatch({ type: SAVED, user: response.data })
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Profile saved'
                    }
                })
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function changePassword(oldPassword, newPassword, callback) {
    return (dispatch, getState) => {
        // Sets the loading state
        dispatch({ type: SAVING })

        api('/userz/updatePassword', 'PUT', {
            oldPassword: oldPassword,
            newPassword: newPassword
        }, getState(), {}, dispatch)
            .then(() => {
                dispatch({ type: CLEAR })
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Password changed'
                    }
                })
                if (callback) {
                    callback({ success: true })
                }
            })
            .catch(function (response) {
                if (callback) {
                    callback({ success: false, message: response.data.error.message })
                }
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function avatar(id, newAvatar, callback) {
    return (dispatch, getState) => {

        var formData = new FormData()
        formData.append("file", newAvatar)

        apiUpload(BASE_PATH + '/' + id + '/avatar/upload', formData, null, null, getState())
            .then(function (response) {
                dispatch({ type: SAVED, user: response.data })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
                if (callback) {
                    callback(response.data)
                }
            })
    }
}