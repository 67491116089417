import InstructoreOverallScore from './instructor/DashboardInstructorOverallScore'
import InstructorTeamLadder from './instructor/DashboardInstructorTeamLadder'
import InstructorScoringTags from './instructor/DashboardInstructorScoringTags'
import InstructorScoreByModule from './instructor/DashboardInstructorScoreByModule'
import InstructorScoringList from './instructor/DashboardInstructorScoringList'

export default {
    InstructoreOverallScore,
    InstructorTeamLadder,
    InstructorScoringTags,
    InstructorScoreByModule,
    InstructorScoringList
}