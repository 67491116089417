import scripts from 'alias-tools/scripts'

export default {
    getDataPoints(course, variable, participantsList) {
        let debug = false
        //
        // GET THE ACTIVITIES AND THE LOGS
        //
        let variableActivitiesIdList = []
        const variableActivities = course.activities.filter(activity => {
            if (!activity.parentStaticId) { return false }
            if (['chat', 'email', 'rolePlay'].indexOf(activity.type) === -1) { return false }
            if (!activity.formStaticId) { return false }
            // check if form has the variable
            let form = course.byStaticId.forms[activity.formStaticId]
            if (!form || !form.questions || form.questions.length === 0) { return false }
            let formHasVariable = false
            form.questions.map(question => {
                if (question.variableStaticId === variable.staticId) {
                    formHasVariable = true
                }
            })
            if (!formHasVariable) { return false }

            variableActivitiesIdList.push(activity.id)
            return true
        })


        const participantsLogs = course.activityLogs.filter(log => {
            if (log.type !== 'form') { return false }
            if (participantsList.indexOf(log.participantId) === -1) { return false }
            return true
        })

        // Logs with no activity creates a new plot point!!!
        let logsWithNoActivity = participantsLogs.filter(log => {
            if (log.activityId) { return false }
            if (!log.formStaticId) { return false }
            let form = course.byStaticId.forms[log.formStaticId]
            if (!form || !form.questions || form.questions.length === 0) { return false }
            let formHasVariable = false
            form.questions.map(question => {
                if (question.variableStaticId === variable.staticId) {
                    formHasVariable = true
                }
            })
            if (!formHasVariable) { return false }
            return true
        })

        const totalAmountOfStepsForVariable = variableActivities.length
        const variableStepAmount = (totalAmountOfStepsForVariable > 0) ? variable.difference / totalAmountOfStepsForVariable : variable.difference

        //
        // Build the data that will impact the variable
        //
        let graphPointsData = []

        variableActivities.map(activity => {
            let point = {
                logs: participantsLogs.filter(l => l.activityId === activity.id),
                form: course.byStaticId.forms[activity.formStaticId],
                activity: activity,
                coefficient: {
                    amount: 0,
                    count: 0,
                    total: 0
                },
                created: null
            }
            graphPointsData.push(point)
        })
        // Random logs with no activity
        logsWithNoActivity.map(log => {
            let point = {
                logs: [log],
                form: course.byStaticId.forms[log.formStaticId],
                activity: null,
                coefficient: {
                    amount: 0,
                    count: 0,
                    total: 0
                },
                created: null
            }
            graphPointsData.push(point)
        })

        //
        // Calculate the coefficients now
        //
        graphPointsData = graphPointsData.map(point => {
            if (point.logs.length === 0) { return null }
            point.logs = scripts.sortArrayObjectDate(point.logs, 'created')
            point.created = point.logs.length > 0 ? point.logs[0].created : null
            point.logs.map((log, i) => {
                if (i === 0) { point.created === log.created }
                let questionsWithVariableCount = 0
                let totalImpactFromQuestionsWithThisVariable = 0
                point.form.questions.map(question => {
                    if (['multiple-choice'].indexOf(question.type) === -1
                        || question.variableStaticId !== variable.staticId) { return }

                    questionsWithVariableCount++
                    question.options.map(option => {
                        log.data.answers.map(answer => {
                            if (answer.answer === option.id) {
                                if (option.impact === undefined) {
                                    option.impact = 0
                                }
                                totalImpactFromQuestionsWithThisVariable = totalImpactFromQuestionsWithThisVariable + option.impact
                                questionsWithVariableCount++
                            }
                        })
                    })
                })
                // Now average the points for the form and add it to the coefficient
                point.coefficient.total = totalImpactFromQuestionsWithThisVariable / questionsWithVariableCount
                point.coefficient.count++
            })
            point.coefficient.amount = point.coefficient.total / point.coefficient.count
            return point
        }).filter(pt => pt !== null)

        graphPointsData = scripts.sortArrayObjectDate(graphPointsData, 'created')

        return {
            numberOfSteps: totalAmountOfStepsForVariable,
            stepAmount: variableStepAmount,
            points: graphPointsData
        }
    }
}