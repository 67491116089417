import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button } from 'alias-components'
import structures from 'alias-store/structures'


export default class ModalCharacter extends Component {

    static propTypes = {
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        type: PropTypes.string,
        participantId: PropTypes.string,
        course: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false,
        type: 'chat',
        isAdmin: false
    }

    constructor(props) {
        super(props)
        this.state = {
            thread: {
                ...structures.thread,
                participants: [],
                courseId: props.course.id,
                type: props.type
            },
            message: '',
            fromId: props.participantId
        }
    }


    render() {
        const { state, props } = this

        if (!props.participantId) {
            return null
        }

        let fromOptions = []
        if (props.participantId && props.course.byId.participants[props.participantId]) {
            let adminParticipant = props.course.byId.participants[props.participantId]
            fromOptions.push({
                label: `${adminParticipant.displayName.bigTeamThenName}`,
                value: adminParticipant.id
            })
        }
        fromOptions = [
            ...fromOptions,
            ...props.isAdmin ? props.course.characters.map(character => {
                return {
                    label: `${character.first} ${character.last}`,
                    value: character.staticId
                }
            }) : []
        ]

        const toOptions = props.course.participants.filter(p => {
            // if (!props.isAdmin) {
            //     return !p.isAdmin && p.id !== props.participantId
            // }
            return p.id !== props.participantId
        }).map(p => {
            return {
                label: `${p.displayName.bigTeamThenName}`,
                value: p.id
            }
        })


        return <Modal show={props.isOpen} onHide={this._handleClose.bind(this)}>
            <Modal.Header closeButton>
                <Modal.Title>Compose</Modal.Title>
            </Modal.Header>
            <form onSubmit={this._send.bind(this)}>
                <Modal.Body>
                    <div>
                        <Input type='select'
                            label='From'
                            value={state.fromId}
                            options={fromOptions}
                            disabled={!props.isAdmin}
                            onChange={(name, value) => this.setState({ fromId: value })} />
                        <Input type='select'
                            multiSelect={true}
                            name='participants'
                            label='To'
                            placeholder='Who should receive this?'
                            options={toOptions}
                            value={state.thread.participants}
                            onChange={this._onChange.bind(this)} />
                        {props.type === 'chat' && <div>
                            <Input type='text'
                                name='subject'
                                label='Group name'
                                placeholder='Ex: Team dragon (Optional)'
                                value={state.thread.subject}
                                onChange={this._onChange.bind(this)} />
                        </div>}
                        {props.type === 'email' && <div>
                            <Input type='text'
                                name='subject'
                                label='Subject'
                                placeholder='Enter the subject...'
                                value={state.thread.subject}
                                onChange={this._onChange.bind(this)} />

                            <Input type='textarea'
                                name='message'
                                label='Message'
                                placeholder='Type your email here...'
                                value={state.message}
                                onChange={this._onChange.bind(this)} />
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button text={props.type === 'chat' ? 'Create group' : 'Send'} />
                    <a onClick={this._handleClose.bind(this)} className="btn btn-default">Cancel</a>
                </Modal.Footer>
            </form>
        </Modal>
    }

    _handleClose(newThread) {
        const { props } = this
        this.setState({
            thread: {
                ...structures.thread,
                participants: []
            },
            message: ''
        })
        props.handleClose(newThread)
    }

    _onChange(name, value) {
        if (name === 'message') {
            this.setState({ message: value })
            return
        }
        let thread = this.state.thread
        thread[name] = value
        this.setState({ thread: thread })
    }

    _send(e) {
        e.preventDefault()
        const { props, state } = this
        let thread = state.thread
        if (thread.participants.length === 0) {
            return alert("Please select some participants")
        }
        if (!state.fromId) {
            return alert("Please select 'FROM' field.")
        }
        if (thread.participants.indexOf(state.fromId) === -1) {
            thread.participants.push(state.fromId)
        }

        if (state.fromId && state.fromId !== props.participantId) {
            thread.participants.push(state.fromId)
        }
        thread.courseId = props.course.id
        thread.type = props.type
        delete thread.id
        props.actions.thread.create(thread, (newThread) => {
            if (props.type === 'chat') {
                this._handleClose(newThread)
                return
            }
            props.actions.activityLog.create({
                courseId: props.course.id,
                type: props.type,
                participantId: state.fromId,
                threadId: newThread.id,
                data: {
                    message: state.message,
                    type: 'character'
                }
            }, () => {
                this._handleClose(newThread)
            })

        })
    }
}