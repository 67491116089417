import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class NotFound extends Component {

	render() {
		return <div id="not-found" className="container">
			<Helmet title="404 - Page Not Found" />
			<h1>Woops...</h1>
			<p>We couldn't find the page you were looking for...</p>
		</div>

	}
}
