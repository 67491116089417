import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import Scoring from '../../Scoring'

export default class DashboardInstructorScoringTags extends Component {
    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props)

    }
    render() {
        const { course } = this.props
        let data = {}
        course.scoringTags.map(tag => {
            data[tag.id] = {
                zero: 0,
                one: 0,
                two: 0,
                three: 0,
                four: 0,
                five: 0
            }
        })

        course.participants.map(participant => {
            let score = participant.score ? participant.score : Scoring.Tools.participant(participant, course.activities, course.activityLogs, course.forms, course.scoringTags)
            if (!score) {
                course.scoringTags.map(tag => {
                    data[tag.id].zero++
                })
                return
            }
            score.scoringTags.map(tag => {
                let tagScore = tag.score
                if (tagScore < 0.5 || tagScore === null) {
                    data[tag.id].zero++
                    return
                } else if (tagScore >= 0.5 && tagScore < 1.5) {
                    data[tag.id].one++
                    return
                } else if (tagScore >= 1.5 && tagScore < 2.5) {
                    data[tag.id].two++
                    return
                } else if (tagScore >= 2.5 && tagScore < 3.5) {
                    data[tag.id].three++
                    return
                } else if (tagScore >= 3.5 && tagScore < 4.5) {
                    data[tag.id].four++
                    return
                } else if (tagScore >= 4.5 && tagScore <= 5) {
                    data[tag.id].five++
                }
            })
        })

        const colors = ["#631D76", "#FE5F55", "#BE3E82", "#F9C80E", "#496DDB", '#FE9920', '#CFD11A', '#91C499', '#9E768F', '#E55381', '#D183C9', '#2292A4', '#7DBBC3', '#AB3428']
        let chartData = {
            labels: ['0', '1', '2', '3', '4', '5'],
            datasets: course.scoringTags.map((tag, i) => {
                return {
                    label: tag.name,
                    backgroundColor: colors[i],
                    data: [data[tag.id].zero, data[tag.id].one, data[tag.id].two, data[tag.id].three, data[tag.id].four, data[tag.id].five]
                }
            })
        }
        //  options={{ legend: { display: false } }}
        return <div>
            <Bar data={chartData} options={{
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }} />
        </div>
    }
}