import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Card, LMSDisplay } from 'alias-components'
import Config from 'alias-config'
import ReduxBinder from 'alias-store/ReduxBinder'
import scripts from 'alias-tools/scripts'

class GameTabModule extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    render() {
        const course = this.props.state.course.model
        const moduleStaticId = this.props.match.params.moduleStaticId
        let module = course.modules.filter(mod => mod.staticId === moduleStaticId)[0] || {
            start: new Date(),
            description: null,
            staticId: "hello"
        }
        const moduleStart = moment(course.start).tz(course.timezone).add(module.offset || 0, 'm')
        const showIntro = moment().tz(course.timezone).isAfter(moduleStart.clone().subtract(5, 'm'))
        return (
            <div className="config-right-wrapper">
                <Card title="Basic info">
                    <div className="form-horizontal">
                        <div className="form-group">
                            <label className="control-label col-md-3">Start date</label>
                            <div className="col-md-9"><p className="form-control-static">{moduleStart.format(Config.app.time.dateTimeShortFormat)}</p></div>
                        </div>
                        {module.description && (
                            <div className="form-group">
                                <label className="control-label col-md-3">Description</label>
                                <div className="col-md-9"><p className="form-control-static">{module.description}</p></div>
                            </div>
                        )}
                    </div>
                </Card>

                {(showIntro && !scripts.isEditorEmpty(module.intro)) && (
                    <Card title="Course introduction">
                        <LMSDisplay
                            course={course}
                            module={module}
                            text={module.intro}
                            for={module.staticId} />
                    </Card>
                )}
            </div>
        );
    }
}

export default ReduxBinder(GameTabModule, {
    state: ['course']
})