import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import Dashboard from '../components/Dashboard'
import Widgets from '../components/Widgets'

class CourseRunTabScoring extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    render() {
        const { props } = this
        const course = props.state.course.model
        const hasTeams = course.teams.length > 0

        return (
            <div className="config-right-wrapper">
                <h1>Scoring</h1>

                <Card title="Class scores">
                    <Dashboard.InstructorScoringList
                        course={course}
                        actions={props.actions}
                        state={props.state} />
                </Card>


                {(hasTeams && false) && <Card title="Team ranking">
                    <Dashboard.InstructorTeamLadder
                        course={course} />
                </Card>}

                <Card title="Forms review">
                    <Widgets.Forms
                        course={course}
                        actions={props.actions} />
                </Card>

                <Card title="Scoring & Scoring tags">
                    <Widgets.Graph
                        course={course} />
                </Card>

                {false && <Card title="Overall score for participants">
                    <Dashboard.InstructoreOverallScore
                        course={course} />
                </Card>}

                {(false && course.scoringTags.length > 0) && <Card title="Scoring tags">
                    <Dashboard.InstructorScoringTags
                        course={course} />
                </Card>}

                <Card title="Overall score by module">
                    <Dashboard.InstructorScoreByModule
                        course={course} />
                </Card>
            </div>
        )
    }
}

export default ReduxBinder(CourseRunTabScoring, {
    state: ['course', 'module']
})