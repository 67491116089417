import Module from './Module'
import GettingStarted from './GettingStarted'
import Overview from './Overview'
import Comps from './Comps'
import Settings from './Settings'
import Introduction from './Introduction'

export default {
    Module,
    GettingStarted,
    Overview,
    Comps,
    Settings,
    Introduction
}