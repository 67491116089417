import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors, Card } from 'alias-components'
import IsValid from 'alias-tools/validation'
import structures from 'alias-store/structures'

export default class RoleModal extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        roleId: PropTypes.any,
        role: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let role = { ...structures.role }

        if (props.roleId && props.roleId !== 'new' && course.byId.roles[props.roleId]) {
            role = {
                ...role,
                ...course.byId.roles[props.roleId]
            }
        }
        this.state = {
            role: role,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.role.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const course = nextProps.state.course.model
        if (nextProps.roleId != this.state.role.id && this.state.isEdited === false) {
            if (nextProps.roleId && course.byId.roles[nextProps.roleId]) {
                this.setState({
                    role: course.byId.roles[nextProps.roleId]
                })
            } else {
                this.setState({ role: { ...structures.role } })
            }
        } else if (this.state.isEdited === false) {
            if (nextProps.roleId && course.byId.roles[nextProps.roleId]) {
                this.setState({
                    role: course.byId.roles[nextProps.roleId]
                })
            }
        }
    }

    render() {
        const { state } = this.props
        const role = this.state.role
        const course = state.course.model

        var title = "Create a new role"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.roleId !== 'new') {
            title = "Update role"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }

        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {this.props.isOpen && <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: CEO..."
                                value={role.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input name="details"
                                label="Details (for authors)"
                                placeholder="Enter some details here..."
                                description="You can enter here what kind of personality the character has or any notes you deem important. This will only be seen by authors."
                                type="textarea"
                                value={role.details}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input name="description"
                                label="Description (for participants)"
                                placeholder="Enter a description here..."
                                description="This will be seen by the participants. You can enter some notes or more details in what this role represents"
                                type="textarea"
                                value={role.description}
                                onChange={this._onChangeNew.bind(this)} />

                            {false && <Input name="organizationStaticId"
                                label="Organization"
                                placeholder="Select an organization..."
                                type="select"
                                value={role.organizationStaticId}
                                options={course.organizations.map(o => { return { label: o.name, value: o.staticId } })}
                                onChange={this._onChangeNew.bind(this)} />}

                            <Input name="isVisibleByParticipant"
                                label="Can be seen by participants?"
                                description="Allows you to create roles completely hidden to the participants."
                                type="bool"
                                value={role.isVisibleByParticipant}
                                onChange={this._onChangeNew.bind(this)} />

                            {role.isVisibleByParticipant && <Input name="isSelectableByParticipant"
                                label="Can be chosen by participants?"
                                description="Enables to show/hide the role to the participants DURING the selection (if feature enabled in course configuration)."
                                type="bool"
                                value={role.isSelectableByParticipant}
                                onChange={this._onChangeNew.bind(this)} />}



                            <Card title="Sample participant">
                                <div>
                                    <p><i className="fa fa-info" /> Sample participants are used to preview the activities such as email and chats</p>
                                    <Input
                                        name="first"
                                        label="First name"
                                        placeholder="Ex: John..."
                                        value={role.previewParticipant.first}
                                        onChange={this._onChangePreviewParticipant.bind(this)} />
                                    <Input
                                        name="last"
                                        label="Last name"
                                        placeholder="Ex: Smith..."
                                        value={role.previewParticipant.last}
                                        onChange={this._onChangePreviewParticipant.bind(this)} />
                                    <Input
                                        name="title"
                                        label="Title"
                                        placeholder="Ex: Head of marketing..."
                                        value={role.previewParticipant.title}
                                        onChange={this._onChangePreviewParticipant.bind(this)} />
                                </div>
                            </Card>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(role && role.id) && (<a onClick={this._deleteRole.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>)}
                    </Modal.Footer>
                </form>}
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        var role = this.state.role
        role[name] = value
        this.setState({ role: role, isEdited: true })
    }
    _onChangePreviewParticipant(name, value) {
        var role = this.state.role
        role.previewParticipant[name] = value
        this.setState({ role: role, isEdited: true })
    }

    _isFormValid() {
        var role = this.state.role
        var formIsValid = true
        var errorFields = []
        // Name
        var errors = IsValid(role.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Role name",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const course = this.props.state.course.model
        let role = this.state.role
        role.courseId = course.id
        this.props.actions.role.create(role, (role) => {
            this.setState({ isEdited: false })
            this.props.openModal('role', role.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.role.update(this.state.role, (newRole) => {
            this.setState({ isEdited: false, role: newRole })
            // this.setState({ role: structures.role })
            // this.props.handleClose()
        })
    }

    _deleteRole() {
        const role = this.state.role
        if (confirm("Are you sure you want to delete " + role.name + "?")) {
            this.props.actions.role.remove(role, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}