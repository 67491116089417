/* global ENV_CONFIG */
var BaseConfig = require('./config.base');
var ProdConfig = require('./config.prod');

var Config = BaseConfig
if (ENV_CONFIG !== 'local') {
	Config = Object.assign({}, BaseConfig, ProdConfig);
}
// if (ENV_CONFIG === 'local') {
// 	console.info("CONFIG", Config) // eslint-disable-line
// }
module.exports = Config;
