import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Card extends Component {

    static propTypes = {
        padding: PropTypes.bool,
        title: PropTypes.any,
        className: PropTypes.string,
        style: PropTypes.object,
        children: PropTypes.any,
        onClick: PropTypes.func,
        isCentered: PropTypes.bool
    }

    static defaultProps = {
        padding: true,
        title: null,
        style: {},
        isCentered: false
    }

    render() {
        const { onClick, padding, className, style, title, isCentered } = this.props
        let attributes = {}

        attributes.className = "card"
        if (!padding) {
            attributes.className = "card no-padding"
        }
        if (className) {
            attributes.className = attributes.className + ' ' + className
        }
        if (onClick) {
            attributes.className = attributes.className + ' clickable'
            attributes.onClick = onClick
        }
        if (isCentered) {
            attributes.className = attributes.className + ' centered'
        }
        attributes.style = style


        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="card"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}>
                <div {...attributes}>
                    {title && <h3>{title}</h3>}
                    {this.props.children}
                </div>
            </ReactCSSTransitionGroup>
        )
    }
}