import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Grid, Card, Input, Avatar } from 'alias-components'
import { Link } from 'react-router-dom'


class PickTeams extends Component {
    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }
    static defaultProps = {}
    constructor(props) {
        super(props)
    }

    _getParticipant(props) {
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && !participant.isAdmin
        })
        let participant = (props.state.local.game && props.state.local.game.participantId && participants.filter(p => p.id === props.state.local.game.participantId).length > 0) ?
            participants.filter(p => p.id === props.state.local.game.participantId)[0]
            : participants[0]
        return participant
    }
    render() {
        const { props } = this
        const course = props.state.course.model
        const participant = this._getParticipant(props)

        if (course.participantsCanChooseTeams === false) {
            return <div className="container">
                <br />
                <br />
                <p className="alert alert-info">You are not allowed to choose your team in this case</p>
                <p className="text-center"><Link to={`/course/my/${course.id}`} className="btn btn-primary">Continue to case</Link></p>
            </div>
        }

        let canJoin = false
        let currentParticipantTeam = null
        let teams = course.teams
            .map(team => {
                team.participants = course.participants.filter(p => p.teamId === team.id)
                team.hasParticipant = participant.teamId === team.id
                if (team.hasParticipant) {
                    currentParticipantTeam = team
                }
                return team
            })
            .filter(t => t.participants.length > 0 && !t.hasParticipant)

        // Add the owners team at start
        if (currentParticipantTeam) {
            teams.unshift(currentParticipantTeam)
        } else {
            // Adds the create team
            teams.unshift("New")
            // Adds the join button
            canJoin = true
        }




        return <div className="container-fluid">
            <div className="alert alert-warning">
                Join OR create a team below <i className="fa fa-arrow-down"></i> then click next
                <Link to={`/course/my/${course.id}`} className="btn btn-primary pull-right">Next</Link>
                <div className="clear"></div>
            </div>

            <Grid>
                {teams.map(team => {
                    if (team === "New") {
                        return <TeamView key="new"
                            createTeam={this._createTeam.bind(this)} />
                    }
                    return <TeamView key={team.id}
                        participant={participant}
                        team={team}
                        leaveTeam={this._leaveTeam.bind(this)}
                        joinTeam={this._joinTeam.bind(this)}
                        canJoin={canJoin} />
                })}
            </Grid>
        </div>
    }

    _joinTeam(team) {
        let participant = this._getParticipant(this.props)
        participant.teamId = team.id
        participant.hasPickedTeam = true
        this.props.actions.participant.update(participant)
    }

    _createTeam(name) {
        if (!name) {
            return alert("Please enter a team name")
        }
        let teamNameList = this.props.state.course.model.teams.map(t => t.name.toLowerCase())
        if (teamNameList.indexOf(name.toLowerCase()) !== -1) {
            return alert("That name has already been taken, please choose another name for your team.")
        }
        this.props.actions.team.create({
            courseId: this.props.match.params.courseId,
            name: name
        }, (team) => {
            let participant = this._getParticipant(this.props)
            participant.teamId = team.id
            participant.hasPickedTeam = true
            this.props.actions.participant.update(participant)
        })


    }
    _leaveTeam() {
        let participant = this._getParticipant(this.props)
        if (!participant) { return }
        participant.teamId = ""
        participant.hasPickedTeam = false
        this.props.actions.participant.update(participant)
    }
}



class TeamView extends Component {
    static propTypes = {
        team: PropTypes.object,
        participant: PropTypes.object,
        createTeam: PropTypes.func,
        joinTeam: PropTypes.func,
        leaveTeam: PropTypes.func,
        canJoin: PropTypes.bool
    }
    constructor(props) {
        super(props)

        this.state = {
            name: ""
        }
    }
    render() {
        const { props, state } = this

        if (props.team) {
            let styling = {}
            let participantIsInTeam = false
            if (props.participant && props.participant.teamId === props.team.id) {
                participantIsInTeam = true
                styling = { border: '5px solid #0BCEC4' }
            }
            return <div>
                <Card title={props.team.name} style={styling}>
                    <div>
                        <table className="table-hover table-striped" style={{ width: '100%' }}>
                            <tbody>
                                {props.team.participants.map(participant => {
                                    return <tr key={participant.id + props.team.id}>
                                        <td><div style={{ padding: '3px 0' }}><Avatar user={participant.userz} size={25} /></div></td>
                                        <td>{participant.displayName.short}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {participantIsInTeam && <a className="btn btn-secondary btn-block" style={{ marginTop: 10 }} onClick={props.leaveTeam}>Leave team</a>}
                        {props.canJoin && <a className="btn btn-secondary btn-block" style={{ marginTop: 10 }} onClick={() => props.joinTeam(props.team)}>Join team</a>}
                    </div>
                </Card>
            </div>
        }

        return <Card title="Create a team" style={{ border: '5px solid #FAFF7F' }}>
            <form onSubmit={this._onSubmit.bind(this)}>
                <Input type="text"
                    label="Enter team name"
                    placeholder="Ex: Dragons..."
                    value={state.name}
                    onChange={(name, value) => this.setState({ name: value })} />
                <button className="btn btn-primary btn-block">Create team</button>
            </form>
        </Card>

    }

    _onSubmit(e) {
        e.preventDefault()
        if (!this.state.name) {
            return alert("Please enter a team name")
        }
        this.props.createTeam(this.state.name)
    }

}

export default ReduxBinder(PickTeams, {
    state: ['course', 'user', 'local', 'participant', 'team']
})
