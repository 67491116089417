import Configuration from './ScoringConfiguration'
import Card from './ScoringCard'
import Tools from './ScoringTools'
import Modal from './ScoringModal'

export default {
    Configuration,
    Card,
    Tools,
    Modal
}