import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Search, Card, AvatarListing } from 'alias-components'
import Team from '../components/Team'
import ReduxBinder from 'alias-store/ReduxBinder'
import Helmet from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class CourseRunTabTeams extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            teamModalIsOpen: false,
            team: null,
            search: ''
        }
    }

    render() {
        const { actions, state } = this.props
        const { search, teamModalIsOpen, team } = this.state

        const course = state.course.model
        let teams = course.teams || []

        if (search !== '') {
            let searchLower = search.toLowerCase()
            teams = teams.filter((team) => {
                return team.name.toLowerCase().indexOf(searchLower) !== -1
            })
        }

        return (
            <div className="config-right-wrapper">
                <Helmet title="Teams" />
                <div className="page-header">
                    <Search
                        search={search}
                        onChange={(search) => {
                            this.setState({ search: search })
                        }} />
                    <a onClick={this._openTeamModal.bind(this, null)} className="btn btn-primary pull-right">+ Add a team</a>
                </div>

                {teams.length === 0 && search === '' && (
                    <p className="alert alert-info">This course does not have any teams for the moment</p>
                )}

                {search !== '' && (
                    <p><em>Showing result for <strong>{search}</strong></em></p>
                )}

                {teams.map(team => {
                    return <Team.Card
                        key={team.id}
                        team={team}
                        canEdit={true}
                        openTeamModal={this._openTeamModal.bind(this)}
                        state={this.props.state}
                        actions={actions} />
                })}

                <Team.Modal
                    isOpen={teamModalIsOpen}
                    handleNewTeam={this._handleNewTeam.bind(this)}
                    courseId={course.id}
                    handleClose={this._closeTeamModal.bind(this)}
                    team={team}
                    state={this.props.state}
                    actions={actions} />
            </div>
        )
    }

    _handleNewTeam(team) {
        this.setState({ team: team })
    }

    _openTeamModal(team) {
        this.setState({ teamModalIsOpen: true, team: team })
    }

    _closeTeamModal() {
        this.setState({ teamModalIsOpen: false })
    }
}

export default ReduxBinder(CourseRunTabTeams, {
    state: ['course', 'team']
})

class TeamRunCard extends Component {
    static propTypes = {
        team: PropTypes.object,
        course: PropTypes.object,
        selectTeam: PropTypes.func
    }
    constructor(props) {
        super(props)

        this.state = {
            copied: false
        }
    }
    render() {
        const { team, course, selectTeam } = this.props

        return <Card title={team.name}>
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Score: {team.score ? team.score.scoreDisplay : 'None'}</h4>
                    </div>
                    <div className="col-md-6">
                        <a className="btn btn-secondary" onClick={() => selectTeam(team)}>Details</a>
                        <CopyToClipboard text={`${course.id}-${team.id}`} onCopy={this.onCopy.bind(this)}>
                            <a style={{ marginLeft: 5 }}>Copy team code</a>
                        </CopyToClipboard>
                        {this.state.copied ? <span style={{ color: 'green' }}><br />Copied to your clipboard.</span> : null}
                    </div>
                </div>
                <AvatarListing members={team.participants.map(p => p.userz)} tooltips={true} />
            </div>
        </Card>
    }

    onCopy() {
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 2000)
    }
}
