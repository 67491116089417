import React, { Component } from 'react'
import Masonry from 'react-masonry-css'
import PropTypes from 'prop-types'

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
}

export default class Grid extends Component {
    static propTypes = {
        children: PropTypes.any,
        breakPoints: PropTypes.object
    }
    static defaultProps = {
        breakPoints: breakpointColumnsObj
    }
    constructor(props) {
        super(props)
    }
    render() {
        return <Masonry
            breakpointCols={this.props.breakPoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {this.props.children}
        </Masonry>
    }
}
