import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import moment from 'moment-timezone'
import Config from 'alias-config'
import { Link } from 'react-router-dom'

export default class WidgetCalendarEvents extends Component {
    static propTypes = {
        course: PropTypes.object,
        isAdmin: PropTypes.bool
    }
    static defaultProps = {
        isAdmin: false
    }

    render() {
        const { course, isAdmin } = this.props

        // const shownModules = course.modules.filter(module => module.isShownToParticipants && module.isDraft === false)
        const courseStart = moment(course.start).tz(course.timezone)
        let calendarEvents = []

        // calendarEvents.push({
        //     type: 'course',
        //     date: courseStart,
        //     text: ""
        // })


        course.modules.map(module => {
            if (module.isShownToParticipants === false) { return }
            if (module.isDraft) { return }
            calendarEvents.push({
                type: 'module',
                date: courseStart.clone().add(module.offset || 0, 'm'),
                text: module.name,
                description: module.description || ''
            })
        })

        course.activities.map(activity => {

            if (activity.type === 'calendar' && activity.isDraft === false) {
                let parentActivity = course.byStaticId.activities[activity.parentStaticId]
                if (!parentActivity) { return }
                let module = course.byStaticId.modules[parentActivity.moduleStaticId]
                if (module.isDraft) { return }
                if (activity.isDraft) { return }
                calendarEvents.push({
                    type: 'activity-calendar',
                    date: courseStart.clone().add(module.offset || 0, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm').add(1, 's'),
                    text: activity.data.name,
                    activity: activity
                })
                return
            }

            if (activity.type === 'rolePlay' && activity.isDraft === false) {
                let parentActivity = course.byStaticId.activities[activity.parentStaticId]
                if (!parentActivity) { return }
                let module = course.byStaticId.modules[parentActivity.moduleStaticId]
                if (module.isDraft) { return }
                if (activity.isDraft) { return }
                calendarEvents.push({
                    type: 'activity-rolePlay',
                    date: courseStart.clone().add(module.offset || 0, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm').add(1, 's'),
                    text: activity.name,
                    activity: activity
                })
            }
        })

        let calendarGroups = {}
        calendarEvents = calendarEvents.sort((a, b) => b.date.isBefore(a.date)).map((event) => {
            if (!calendarGroups[event.date.dayOfYear()]) {
                calendarGroups[event.date.dayOfYear()] = {
                    day: event.date.format("ddd Do, MMM YYYY"),
                    date: event.date.add(1, 's'),
                    activities: []
                }
            }
            calendarGroups[event.date.dayOfYear()].activities.push(event)
        })

        if (Object.keys(calendarGroups).length === 0) { return null }

        return <div>
            <h2>Calendar</h2>

            <Card>

                <div className="widget-calendar">
                    {Object.keys(calendarGroups).map(key => {
                        let group = calendarGroups[key]

                        return <div className="day" key={key}>
                            <div className="bubble-wrapper">
                                <div className={`bubble ${group.date.dayOfYear() === moment().tz(course.timezone).dayOfYear() ? 'today' : ''}`}>{group.date.format("DD")}</div>
                                <div className="line"></div>
                            </div>
                            <div className="content">
                                <h3>{group.day}</h3>
                                <ul>
                                    {calendarGroups[key].activities.sort((a, b) => a['date'].diff(b['date'])).map((event, i) => {
                                        let content = event.text
                                        switch (event.type) {
                                            case 'course':
                                                content = <EventCourse event={event} />
                                                break
                                            case 'module':
                                                content = <EventModule event={event} />
                                                break
                                            case 'activity-calendar':
                                                content = <EventActivityTypeCalendar event={event} />
                                                break
                                            case 'activity-rolePlay':
                                                content = <EventActivityTypeRolePlay event={event} isAdmin={isAdmin} />
                                        }
                                        return <li key={i}>
                                            <strong className="time">
                                                {event.date.format(Config.app.time.timeFormat)}
                                                {(event.activity && event.activity.data && event.activity.data.duration) && ` -> ${event.date.clone().add(event.activity.data.duration, 'm').format(Config.app.time.timeFormat)} (${event.activity.data.duration}mins)`}
                                            </strong>
                                            {content}
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    })}
                </div>
            </Card>
        </div>
    }
}

class EventActivityTypeCalendar extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props
        const { activity } = event
        const { location, duration, description } = activity.data
        return <span>
            <strong>{event.text}</strong>
            {(location || duration || description) && <ul style={{ paddingLeft: '40px', paddingTop: 0 }}>
                {location && <li>Location: {location}</li>}
                {description && <li>Description: {description}</li>}
            </ul>}
        </span>
    }
}

class EventActivityTypeRolePlay extends Component {
    static propTypes = {
        event: PropTypes.object,
        isAdmin: PropTypes.bool
    }
    static defaultProps = {
        isAdmin: false
    }
    render() {
        const { event, isAdmin } = this.props
        return <span>
            <strong>Role play </strong> {event.text} <Link to={`/course/${isAdmin ? 'instructor' : 'my'}/${event.activity.courseId}/role-plays/${event.activity.id}`} className="" style={{ marginLeft: 10 }}>View schedule & participation <i className="fa fa-chevron-right"></i></Link>
            <span className="help-block" style={{ textIndent: '60px', margin: '0' }}>(Please click "View" to see exact time and if you should be present)</span>
        </span>
    }
}

class EventModule extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props

        return <span>Start of module: <strong>{event.text}</strong><br />{event.description}</span>
    }
}

class EventCourse extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props

        return <span>Start of course {event.text ? ": " : ""}<strong>{event.text}</strong></span>
    }
}