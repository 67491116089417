
//
// Action types
//
const SAVE = 'si/local/SAVE'
const TOGGLE_MENU = 'si/local/TOGGLE_MENU'
const SAVE_MODAL = 'si/local/SAVE_MODAL'
const ACTIVITY_MODAL_CLOSE = 'si/local/ACTIVITY_MODAL_CLOSE'
const ACTIVITY_MODAL_OPEN = 'si/local/ACTIVITY_MODAL_OPEN'
const HELP_MODAL_OPEN = 'si/local/HELP_MODAL_OPEN'
const HELP_MODAL_CLOSE = 'si/local/HELP_MODAL_CLOSE'
const MODAL_FORMLOG_OPEN = 'si/local/MODAL_FORMLOG_OPEN'
const MODAL_FORMLOG_CLOSE = 'si/local/HELP_MODAL_CLOSE'


const initialState = {
    game: {
        participantId: null
    },
    isMenuOpen: false,
    modals: {
        badge: null,
        character: null,
        form: null,
        role: null,
        tag: null,
        value: null,
        activity: null,
        activityModule: null,
        activityParent: null,
        activityPreview: null,
        module: null,
        help: {
            isShown: false,
            course: null,
            user: null
        },
        formLog: {
            isShown: false,
            form: null,
            log: null
        }
    },
    homeSearch: ''
}

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SAVE:
            return {
                ...state,
                ...action.data
            }
        case TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen
            }
        case ACTIVITY_MODAL_CLOSE:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    activity: null,
                    activityModule: null,
                    activityParent: null
                }
            }
        case ACTIVITY_MODAL_OPEN:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    activity: action.id,
                    activityModule: action.module,
                    activityParent: action.parent
                }
            }
        case HELP_MODAL_OPEN:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    help: {
                        isShown: true,
                        course: action.course,
                        user: action.user
                    }
                }
            }
        case HELP_MODAL_CLOSE:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    help: {
                        isShown: false,
                        course: null,
                        user: null
                    }
                }
            }
        case MODAL_FORMLOG_OPEN:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    formLog: {
                        isShown: true,
                        form: action.form,
                        log: action.log
                    }
                }
            }
        case MODAL_FORMLOG_CLOSE:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    formLog: {
                        isShown: false,
                        form: null,
                        log: null
                    }
                }
            }
        case SAVE_MODAL: {
            if (initialState.modals[action.name] === undefined) {
                // eslint-disable-next-line no-console
                console.warn(`Modal ${action.name} not found.`)
                return state
            }
            let toBeUpdated = {}
            toBeUpdated[action.name] = action.value
            return {
                ...state,
                modals: {
                    ...state.modals,
                    ...toBeUpdated
                }
            }
        }
        default:
            return state
    }
}

//
// ACTIONS
//

export function save(data) {
    return {
        type: SAVE,
        data: data
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}

export function modals(data) {
    return {
        type: SAVE,
        data: {
            modals: data
        }
    }
}
export function modal(name, value) {
    return {
        type: SAVE_MODAL,
        name: name,
        value: value
    }
}
export function openModalActivity(id, module = null, parent = null) {
    return {
        type: ACTIVITY_MODAL_OPEN,
        id: id,
        module: module,
        parent: parent
    }
}
export function closeModalActivity() {
    return {
        type: ACTIVITY_MODAL_CLOSE
    }
}

export function modalHelpOpen(course, user) {
    return {
        type: HELP_MODAL_OPEN,
        course: course,
        user: user
    }
}

export function modalHelpClose() {
    return {
        type: HELP_MODAL_CLOSE
    }
}

export function modalFormLogOpen(form, log) {
    return {
        type: HELP_MODAL_OPEN,
        form: form,
        log: log
    }
}

export function modalFormLogClose() {
    return {
        type: HELP_MODAL_CLOSE
    }
}