/* eslint-disable no-console,curly */
let Scoring = {}

Scoring.participation = {}
Scoring.participation.participant = (participant, course, messagingActivities = [], unreadCount = 0) => {

    // if (participant.displayName.short.indexOf('Tony') !== -1) {
    //     debugger
    // }
    messagingActivities = messagingActivities.filter(thread => thread.activityId)

    let result = {
        questions: {
            completed: 0,
            total: 0
        },
        reads: {
            read: unreadCount,
            total: messagingActivities.length
        },
        score: 0
    }

    // const logs = course.activityLogs.filter(l => l.type === 'form' && l.participantId === participant.id)

    messagingActivities.map(message => {
        if (!message.activity) { return }
        let form = message.activity.formStaticId ? course.byStaticId.forms[message.activity.formStaticId] : course.byStaticId.forms[message.activity.embeddedFormStaticId]
        if (!form || (form && !form.questions)) { return }

        // let log = logs.filter(l => l.data && l.activityId === message.activity.id)

        result.questions.total += 1
        result.questions.completed += message.hasPendingQuestion ? 0 : 1
        // if (log.length === 0) {
        //     // Still add it to questions that need to be answered
        //     form.questions.map(question => {
        //         result.questions.total += question.weight || 0
        //     })
        //     return
        // }

        // log = log[0]
        // if (log.data && !log.data.answers) { return }
        // form.questions.map(question => {

        //     result.questions.total += question.weight || 0

        //     log.data.answers.map(answer => {
        //         if (answer.id === question.id) {
        //             result.questions.completed += question.weight || 0
        //         }
        //     })
        // })
    })

    result.reads.pct = result.reads.total === 0 ? false : (result.reads.read < result.reads.total) ? result.reads.read / result.reads.total * 100 : 100
    result.questions.pct = result.questions.total > 0 ? result.questions.completed / result.questions.total * 100 : false

    let scoreTotal = 0
    let scoreTotalCount = 0
    if (result.reads.pct) {
        scoreTotal += result.reads.pct
        scoreTotalCount++
    }
    if (result.questions.pct) {
        scoreTotal += result.questions.pct
        scoreTotalCount++
    }
    if (scoreTotalCount > 0) {
        result.score = Math.round(scoreTotal / scoreTotalCount)
    }

    return result
}


Scoring.participant = (participant = {}, activities = [], logs = [], forms = [], scoringTags = []) => {
    let totalScore = 0
    let scoreCount = 0

    let debugging = false

    if (debugging) { // participant.displayName.short
        console.group(participant.displayName ? participant.displayName.short : "Name not found")
        console.log(participant)
        console.groupCollapsed("Scoring tags")
    }
    if (scoringTags && scoringTags.length > 0) {
        scoringTags = scoringTags.map(tag => {
            tag.totalScore = 0
            tag.scoreCount = 0
            tag.score = null
            if (debugging) {
                console.log(tag)
            }
            return tag
        })
    }
    if (debugging) { console.groupEnd("Scoring tags") }

    logs.map(activityLog => {

        if (activityLog.type !== 'form') { return }
        if (
            activityLog.participantId !== participant.id
            && (participant.teamId && activityLog.participantId !== participant.teamId)) { return }

        if (debugging) { console.group(`Log ID: ${activityLog.id}`) }

        forms.map(form => {

            if (typeof activityLog.data != 'object' || form.staticId !== activityLog.formStaticId) { return }
            if (debugging) { console.groupCollapsed(`NAME: ${form.name}`) }

            form.questions.map((question, i) => {
                if (debugging) {
                    console.log(`Q${i + 1}: ${question.question}`)
                }

                activityLog.data.answers.map(answer => {
                    if (answer.id !== question.id) {
                        return
                    }
                    let weight = question.weight || 1

                    if (question.type === 'scoring') {
                        let customScoringFraming = answer.answer
                        if (typeof customScoringFraming !== 'number') { customScoringFraming = 0 }
                        if (customScoringFraming < 0) { customScoringFraming = 0 }
                        if (customScoringFraming > 5) { customScoringFraming = 5 }
                        scoreCount = scoreCount + weight
                        totalScore += answer.answer * weight
                    } else if (question.type === 'multiple-choice') {
                        question.options.map(option => {
                            if (option.id !== answer.answer) {
                                return
                            }
                            let score = option.score
                            if (debugging) {
                                console.log(`Answer: ${option.text}`)
                                console.log(`Score: ${score} / Weight: ${weight}`)
                            }

                            if (score === null || score === undefined) {
                                if (debugging) {
                                    console.log(`Score: not set`)
                                    console.log(`Total score: ${totalScore}`)
                                }
                                return
                            }

                            scoreCount = scoreCount + weight
                            totalScore += score * weight
                            if (debugging) {
                                console.log(`scoreCount:${scoreCount} ${weight}`)
                                console.log(`Total score: ${totalScore} ${score} ${weight}`)
                            }
                        })
                    } else {
                        if (question.score !== null && question !== 'undefined') {
                            let score = question.score
                            scoreCount = scoreCount + weight
                            totalScore += score * weight
                            if (debugging) {
                                console.log(`scoreCount: ${scoreCount}`)
                                console.log(`Total score: ${totalScore} - Points: ${score} - Weight: ${weight}`)
                            }
                        }
                    }
                    // scoring tags
                    if (scoringTags && ((question.type !== 'multiple-choice' && question.scoringTags) || (question.type === 'multiple-choice' && question.options))) {
                        if (question.type !== 'multiple-choice' && question.scoringTags && question.scoringTags.length > 0) {
                            scoringTags = scoringTags.map(tag => {
                                let tagTotalScore = tag.totalScore
                                let tagScoreCount = tag.scoreCount
                                let tagScore = tag.score

                                question.scoringTags.map(currentTag => {
                                    if (tag.staticId !== currentTag.scoringTagStaticId) { return }
                                    tagTotalScore += weight * currentTag.amount
                                    tagScoreCount += weight
                                })

                                tagScore = Math.round((tagTotalScore / tagScoreCount) * 10) / 10
                                return {
                                    ...tag,
                                    totalScore: tagTotalScore,
                                    scoreCount: tagScoreCount,
                                    score: tagScore,
                                    scoreDisplay: `${parseFloat(tagScore).toFixed(1)} / 5`
                                }
                            })
                        }
                        if (question.type === 'multiple-choice' && question.options && question.options.length > 0) {

                            scoringTags = scoringTags.map(tag => {
                                let tagTotalScore = tag.totalScore
                                let tagScoreCount = tag.scoreCount
                                let tagScore = tag.score

                                question.options.map(option => {
                                    if (option.id !== answer.answer) {
                                        return
                                    }
                                    if (option.scoringTags) {
                                        option.scoringTags.map(currentTag => {
                                            if (tag.staticId !== currentTag.scoringTagStaticId) { return }
                                            tagTotalScore += weight * currentTag.amount
                                            tagScoreCount += weight
                                        })
                                    }
                                })

                                tagScore = tagScoreCount > 0 ? Math.round((tagTotalScore / tagScoreCount) * 10) / 10 : 0
                                return {
                                    ...tag,
                                    totalScore: tagTotalScore,
                                    scoreCount: tagScoreCount,
                                    score: tagScore,
                                    scoreDisplay: `${parseFloat(tagScore).toFixed(1)} / 5`
                                }
                            })
                        }
                    }
                })

            })
            if (debugging) {
                console.groupEnd(`NAME: ${form.name}`)
            }
        })

        if (debugging) {
            console.groupEnd(`Log ID: ${activityLog.id}`)
        }
    })

    let hasNoScore = false
    if (scoreCount === 0) {
        hasNoScore = true
        scoreCount = 1
        if (debugging) {
            console.log(`
Participant score: NONE
total: ${totalScore}
count: ${scoreCount}
`)
            console.groupEnd(participant.displayName ? participant.displayName.short : "Name not found")
        }
        // return false
    }

    let averageScore = Math.round((totalScore / scoreCount) * 10) / 10
    if (hasNoScore) {
        scoreCount = 0
    }
    if (debugging) {
        console.log(`Participant score: ${parseFloat(averageScore).toFixed(1)} / 5`)
        console.groupEnd(participant.displayName ? participant.displayName.short : "Name not found")
    }
    return {
        score: averageScore,
        scoreDisplay: `${parseFloat(averageScore).toFixed(1)} / 5`,
        total: Math.round(totalScore),
        count: scoreCount,
        scoringTags: scoringTags
    }
}




Scoring.team = (teamId = '', participants = [], activities = [], logs = [], forms = [], scoringTags = [], debug = false) => {
    let totalScore = 0
    let scoreCount = 0
    if (debug) {
        console.log("Debugging team")
    }
    let scoringTagsScore = scoringTags ? scoringTags.map(tag => {
        return {
            ...tag,
            totalScore: 0,
            scoreCount: 0,
            score: 0
        }
    }) : []
    participants.map((participant, i) => {

        if (participant.teamId === teamId) {
            if (debug) {
                console.log(participant.teamName)
            }

            let userScore = participant.score
            if (!userScore) {
                userScore = Scoring.participant(participant, activities, logs, forms, scoringTags)
            }
            if (userScore) {
                scoreCount = scoreCount + userScore.count
                totalScore += userScore.total

                if (scoringTags) {
                    scoringTagsScore = scoringTagsScore.map(tag => {
                        userScore.scoringTags.map(t => {
                            if (t.id === tag.id && t.scoreCount > 0) {
                                if (debug) {
                                    console.log(t)
                                }
                                tag.totalScore = tag.totalScore + t.score
                                tag.scoreCount++
                            }
                        })
                        return tag
                    })
                }
            }
        }
    })

    let hasNoScore = false
    if (scoreCount === 0) {
        scoreCount = 1
        hasNoScore = true
    }

    let score = Math.round((totalScore / scoreCount) * 10) / 10
    if (hasNoScore) {
        scoreCount = 0
    }
    if (scoringTags) {
        scoringTagsScore = scoringTagsScore.map(tag => {
            let tagScoreCount = tag.scoreCount === 0 ? 1 : tag.scoreCount
            tag.score = tagScoreCount > 0 ? Math.round((tag.totalScore / tagScoreCount) * 10) / 10 : 0
            return tag
        })
    }
    const result = {
        score: score,
        scoreDisplay: `${parseFloat(score).toFixed(1)} / 5`,
        total: Math.round(totalScore),
        count: scoreCount,
        scoringTags: scoringTagsScore
    }
    if (debug) {
        console.log(result)
    }
    return result

}

export default Scoring