import ModalNew from './ForumModalNew'
import ModalView from './ForumModalView'
import Page from './ForumPage'
import Form from './ForumForm'

export default {
    ModalNew,
    ModalView,
    Page,
    Form
}