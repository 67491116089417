import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, AvatarListing } from 'alias-components'

export default class EventActivityTypeCalendar extends Component {
    static propTypes = { //eslint-disable-line
        course: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            showAllParticipants: false
        }
    }
    render() {
        const { course } = this.props
        const { showAllParticipants } = this.state

        let uniqueParticipants = []

        const admins = course.participants.filter(participant => {
            return participant.isAdmin
        })

        let participants = course.participants.filter(participant => {
            return !participant.isAdmin && !participant.isActor
        })
        participants.map(participant => {
            if (uniqueParticipants.indexOf(participant.userzId) === -1) {
                uniqueParticipants.push(participant)
            }
        }).sort(function (a, b) {
            return a.displayName.withEmail.localeCompare(b.displayName.withEmail);
        })

        const totalUnique = uniqueParticipants.length
        const hasMore = uniqueParticipants.length > 30
        if (hasMore && !showAllParticipants) {
            uniqueParticipants = uniqueParticipants.splice(0, 30)
        }

        return <div>
            <h2>Case info</h2>
            <Card>
                <div className="form-horizontal">
                    {course.description && <div className="form-group">
                        <label className="control-label col-xs-12 col-sm-3">Description</label>
                        <div className="col-xs-12 col-sm-9">
                            <p className="form-control-static">{course.description}</p>
                        </div>
                    </div>}
                    <div className="form-group">
                        <label className="control-label col-xs-12 col-sm-3">Timezone</label>
                        <div className="col-xs-12 col-sm-9">
                            <p className="form-control-static">{course.timezone}</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-xs-12 col-sm-3">Admins</label>
                        <div className="col-xs-12 col-sm-9">
                            <p className="form-control-static">{admins.length} admin{(admins.length > 1) ? 's' : ''}</p>
                            <AvatarListing members={admins.map(a => a.userz)} tooltips={true} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-xs-12 col-sm-3">Participants</label>
                        <div className="col-xs-12 col-sm-9">
                            <p className="form-control-static">{totalUnique} participant{(totalUnique > 1) ? 's' : ''}</p>
                            <AvatarListing members={uniqueParticipants} tooltips={true} />
                            {hasMore && (
                                <p>
                                    <a onClick={() => {
                                        this.setState({
                                            showAllParticipants: !showAllParticipants
                                        })
                                    }}><i className={!showAllParticipants ? 'fa fa-chevron-down' : 'fa fa-chevron-up'} />
                                        {!showAllParticipants ? 'Show all' : 'Show less'}
                                    </a>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    }
}