import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'


export default class ActivityTypeEmail extends Component {

    static propTypes = {
        activity: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        this.state = {
            docsBeingUploaded: []
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.activity && this.state.activity.id != newProps.activity.id) {
            this.setState({
                docsBeingUploaded: []
            })
        }
    }

    render() {
        let { onChange, activity, state } = this.props
        const course = state.course.model

        // Add organization info to characters
        var characters = course.characters.map(character => {
            if (character.organizationStaticId) {
                let org = course.byStaticId.organizations[character.organizationStaticId]
                if (org) {
                    character.organization = org
                }
            }
            return character
        })


        let to = [
            {
                label: "All students",
                value: "all"
            },
            {
                label: 'ROLES',
                value: 'ROLES',
                disabled: true
            }
        ]
        course.roles.map(role => {
            to.push({
                label: role.name,
                value: role.staticId
            })
        })
        if (course.teams.length > 0 && !course.isTemplate) {
            to.push({
                label: 'TEAMS',
                value: 'ROLES',
                disabled: true
            })
            course.teams.map(team => {
                to.push({
                    label: team.name,
                    value: team.id
                })
            })
        }
        if (course.organizations.length > 0) {
            to.push({
                label: 'ORGANIZATIONS',
                value: 'ORGANIZATIONS',
                disabled: true
            })
            course.organizations.map(org => {
                to.push({
                    label: org.name,
                    value: org.staticId
                })
            })
        }


        return <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-3 control-label">From</label>
                <div className="col-sm-9">
                    <Input
                        type="select"
                        name="characterStaticId"
                        value={activity.characterStaticId}
                        placeholder="Please select a sender"
                        onChange={onChange}
                        options={characters.map(character => {
                            return {
                                label: `${character.first} ${character.last} (${character.title} ${character.organization !== undefined && ("at " + character.organization.name)})`,
                                value: character.staticId
                            }
                        })} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">To</label>
                <div className="col-sm-9">
                    <Input
                        type="select"
                        name="roleStaticId"
                        value={activity.roleStaticId}
                        onChange={onChange}
                        placeholder="Please select a recipient"
                        options={to} />
                </div>
            </div>
        </div>
    }
}