import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, DragChangeNumber, Modal } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import moment from 'moment-timezone'
import config from 'alias-config'

const dateTimeFormat = config.app.time.activityDateTimeFormat

class CourseRunScheduling extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }
    static defaultProps = {}
    constructor(props) {
        super(props)

        this.state = {
            changeDateModuleId: null
        }
    }
    render() {
        const { props, state } = this
        const course = props.state.course.model

        const timezones = moment.tz.names().filter(timezone => {
            return timezone.indexOf("GMT") === -1
        }).map(function (timezone) {
            return {
                value: timezone,
                label: timezone
            }
        })

        const courseStart = moment(course.start).tz(course.timezone)

        const groups = course.activities.filter(a => a.moduleStaticId && !a.parentStaticId)
        const activities = course.activities.filter(a => a.parentStaticId)

        const modules = course.modules.map(mod => {
            mod.groups = groups
                .filter(group => {
                    return group.moduleStaticId === mod.staticId
                })
                .map(group => {
                    group.activities = activities.filter(activity => activity.parentStaticId === group.staticId)
                    return group
                })
            return mod
        })

        let selectedModule = null
        if (state.changeDateModuleId && course.byId.modules[state.changeDateModuleId]) {
            selectedModule = course.byId.modules[state.changeDateModuleId]
        }

        return <div className="config-right-wrapper">
            <h1>Scheduling</h1>
            <p className="help-block">From here, you can change the date/times of all the activities.</p>
            <Card title="Case start">
                <div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Timezone</label>
                        <div className="col-sm-9">
                            <Input type="select"
                                name="timezone"
                                placeholder="Select a timezone..."
                                value={course.timezone}
                                options={timezones}
                                onChange={this._onChangeCourse.bind(this)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">
                            Start date/time
                        </label>
                        <div className="col-sm-9">
                            <Input type="dateTime"
                                name="start"
                                placeholder="Case start date/time..."
                                value={course.start}
                                timezone={course.timezone}
                                onChange={this._onChangeCourse.bind(this)} />
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </Card>
            <Card title="Modules & Activities">
                {modules.length === 0 && <p className="alert alert-info">No modules in case</p>}
                {modules.map(module => {
                    const moduleStart = courseStart.clone().add(module.offset, 'm')
                    let bar = <div>
                        <h4 style={{
                            display: 'inline-block',
                            margin: 0,
                            color: module.isDraft ? '#cacaca' : '#333333',
                            fontSize: 16
                        }}>Module: {module.name} {module.isDraft ? <i> (Draft) </i> : ''}
                            <a onClick={() => this.setState({ changeDateModuleId: module.id })} className="margin-left-10"><i className="fa fa-pencil" /></a>
                        </h4>
                        <span className="pull-right">{moduleStart.format(dateTimeFormat)}{` `} <DragChangeNumber number={module.offset} onChangeFinished={(number) => this._moduleOffsetChange(module, number)} /></span>
                        <div className="clear"></div>
                    </div>
                    return <ExpandableItem key={module.id} bar={bar} quantity={module.groups.length}>
                        {module.groups.map(group => {
                            let groupOffset = moduleStart.clone().add(group.offset, 'm')
                            let bar = <div>
                                <strong style={{ color: module.isDraft ? '#cacaca' : '#333333' }}>Group: {group.name}</strong>
                                <a className="margin-left-10" onClick={() => this.props.actions.local.openModalActivity(group.id)}><i className="fa fa-pencil" /></a>
                                <span className="pull-right">{groupOffset.format(dateTimeFormat)}{` `} <DragChangeNumber number={group.offset} onChangeFinished={(number) => this._activityOffsetChange(group, number)} /></span>
                                <div className="clear"></div>
                            </div>
                            return <ExpandableItem key={group.id} bar={bar} quantity={group.activities.length}>
                                {group.activities.length === 0 && <p className="help-block">No activities</p>}
                                {group.activities.map(activity => {
                                    let activityOffset = groupOffset.clone().add(activity.offset, 'm')
                                    let bar = <div style={{ color: module.isDraft ? '#cacaca' : activity.isDraft ? '#cacaca' : '#333333' }}>
                                        {activity.type === 'email' ? <i className="fa fa-envelope fa-fw"></i> : activity.type === 'rolePlay' ? <i className="fa fa-users fa-fw"></i> : activity.type === 'calendar' ? <i className="fa fa-calendar fa-fw"></i> : activity.type === 'chat' ? <i className="fa fa-comments fa-fw"></i> : activity.type === 'forum' ? <i className="fa fa-th-list fa-fw"></i> : <i className="fa fa-circle fa-fw"></i>}
                                        {`   `}
                                        {activity.name} {activity.isDraft ? <i> (Draft)</i> : ''}
                                        <a className="margin-left-10" onClick={() => this.props.actions.local.openModalActivity(activity.id)}><i className="fa fa-pencil" /></a>
                                        <span className="pull-right">{activityOffset.format(dateTimeFormat)}{` `} <DragChangeNumber number={activity.offset} onChangeFinished={(number) => this._activityOffsetChange(activity, number)} /></span>
                                        <div className="clear"></div>
                                    </div>
                                    return <ExpandableItem key={activity.id} bar={bar}>
                                    </ExpandableItem>
                                })}
                            </ExpandableItem>
                        })}
                    </ExpandableItem>
                })}
            </Card>

            <Modal
                isShown={state.changeDateModuleId !== null}
                close={() => this.setState({ changeDateModuleId: null })}
                title="Change module date/time"
                showHeader={true}
                showFooter={true}>
                {selectedModule && <div>
                    <Input type="dateTime"
                        label="Module start date/time"
                        value={moment(course.start).tz(course.timezone).add(selectedModule.offset, 'm').format()}
                        timezone={course.timezone}
                        disabled={false}
                        onChange={(name, value) => {
                            let courseStart = moment(course.start).tz(course.timezone)
                            let moduleStart = moment(value).tz(course.timezone)
                            let difference = moduleStart.diff(courseStart, 'minutes')
                            this._moduleOffsetChange(selectedModule, difference > 0 ? difference : 0)
                            // this._onChange('offset', difference > 0 ? difference : 0)
                        }} />
                </div>}
            </Modal>
        </div>
    }

    _onChangeCourse(name, value) {
        let course = this.props.state.course.model
        course[name] = value
        this.props.actions.course.update(course)
    }
    _moduleOffsetChange(module, offset) {
        module.offset = offset
        this.props.actions.module.update(module)
    }
    _activityOffsetChange(activity, offset) {
        activity.offset = offset
        this.props.actions.activity.update(activity)
    }
}

export default ReduxBinder(CourseRunScheduling, {
    state: ['course']
})

class ExpandableItem extends Component {
    static propTypes = {
        bar: PropTypes.any,
        quantity: PropTypes.number
    }
    static defaultProps = {
        quantity: 0
    }
    constructor(props) {
        super(props)

        this.state = {
            isExpanded: false
        }
    }
    render() {
        const { props, state } = this
        return <div>
            <div style={{ borderBottom: '1px solid #cacaca', position: 'relative' }}>

                <div style={{ lineHeight: '30px', marginLeft: !props.children ? 0 : 70 }}>
                    {props.bar}
                </div>
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0 }}>
                    {props.children && <a onClick={this._toggleView.bind(this)} style={{ lineHeight: '30px' }}>
                        {state.isExpanded && <span style={{ fontSize: 18 }}><i className="fa fa-caret-down fa-fw" /> ({props.quantity})</span>}
                        {!state.isExpanded && <span style={{ fontSize: 18 }}><i className="fa fa-caret-right fa-fw" /> ({props.quantity})</span>}
                    </a>}
                </div>
            </div>
            <div className={`height-animation ${state.isExpanded ? 'open' : 'closed'}`} style={{ paddingLeft: 20 }}>
                {props.children}
            </div>
        </div>
    }
    _toggleView() {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }
}