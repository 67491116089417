import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Layout, Card, AvatarUploader, Button } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'

class PageProfile extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            user: props.state.user.model,
            security: {
                oldPassword: "",
                newPassword: "",
                response: null
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            user: newProps.state.user.model
        })
    }

    render() {
        var userState = this.props.state.user
        var security = this.state.security
        var user = this.state.user

        return (
            <Layout title={"Your profile"} isInstructor={false} location={this.props.location}
                state={this.props.state} actions={this.props.actions}>
                <Helmet title="Your profile" />
                <div className="container page-container">
                    <div className="tab-list">
                        <Link to={(this.props.location.state && this.props.location.state.from) ? this.props.location.state.from : '/'} className="btn btn-default"><i className="fa fa-arrow-left"></i> Back</Link>
                        <a onClick={this._logout.bind(this)} className="btn btn-default pull-right"><i className="fa fa-sign-out" /> Logout</a>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <Card title="Basic info">
                                <form onSubmit={this._saveUser.bind(this)} className="form-horizontal">
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Email</label>
                                        <div className="col-sm-9">
                                            <input value={user.email} onChange={this._onChange.bind(this)} ref="email"
                                                placeholder="Ex: john@email.com..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">First name</label>
                                        <div className="col-sm-9">
                                            <input value={user.first} onChange={this._onChange.bind(this)} ref="first"
                                                placeholder="Ex: John..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Last name</label>
                                        <div className="col-sm-9">
                                            <input value={user.last} onChange={this._onChange.bind(this)} ref="last"
                                                placeholder="Ex: Smith..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Company</label>
                                        <div className="col-sm-9">
                                            <input value={user.company} onChange={this._onChange.bind(this)} ref="company"
                                                placeholder="Ex: Harvard..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Title</label>
                                        <div className="col-sm-9">
                                            <input value={user.title} onChange={this._onChange.bind(this)} ref="title"
                                                placeholder="Ex: Mr, Ms, Dr..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-3 control-label">Mobile</label>
                                        <div className="col-sm-9">
                                            <input value={user.tel} onChange={this._onChange.bind(this)} ref="tel"
                                                placeholder="Ex: 00336123123123..." type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-offset-3 col-sm-9">
                                            <Button text="Save" btnClass="btn btn-secondary" isLoading={userState.isSaving} />
                                        </div>
                                    </div>
                                </form>
                            </Card>
                            <Card title="Security: Change password">
                                {user.hasPasswordSet && <div>
                                    <form onSubmit={this._changePassword.bind(this)} className="form-horizontal">
                                        {this.state.security.response !== null && this.state.security.success &&
                                            <p className="alert alert-success">Your password has been changed successfully</p>}
                                        {this.state.security.response !== null && !this.state.security.response.success &&
                                            <p className="alert alert-warning">
                                                {this.state.security.response.message}
                                            </p>}
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Old password</label>
                                            <div className="col-sm-9">
                                                <input value={security.oldPassword} onChange={this._onChangePassword.bind(this)} ref="oldPassword"
                                                    type="password" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">New password</label>
                                            <div className="col-sm-9">
                                                <input value={security.newPassword} onChange={this._onChangePassword.bind(this)} ref="newPassword"
                                                    type="password" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-md-offset-3 col-sm-9">
                                                <Button text="Change password" btnClass="btn btn-secondary" isLoading={userState.isSaving} />
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                                {!user.hasPasswordSet && <div>
                                    <p>You have authenticated with a third-party. If you wish to change that password, please view instructions on your third-party website.</p>
                                    <p>If you wish to set a password to login directly, please logout by clicking "Logout" on the top right of this page and go through the forgotten password process to set a password.</p>
                                </div>}
                            </Card>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Card title="Avatar">
                                <AvatarUploader
                                    object={this.props.state.user.model}
                                    actions={this.props.actions}
                                    refName="userz"
                                    fileType="user" />
                            </Card>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    _onChange() {
        var user = this.state.user
        user.first = this.refs.first.value
        user.last = this.refs.last.value
        user.company = this.refs.company.value
        user.title = this.refs.title.value
        user.tel = this.refs.tel.value
        this.setState({
            user: user
        })
    }

    _onChangePassword() {
        var security = this.state.security
        security.oldPassword = this.refs.oldPassword.value
        security.newPassword = this.refs.newPassword.value
        this.setState({
            security: security
        })
    }

    _saveUser(e) {
        e.preventDefault()
        this.props.actions.user.update(this.state.user)
    }

    _changePassword(e) {
        e.preventDefault()
        const self = this
        var security = this.state.security
        this.props.actions.user.changePassword(this.state.security.oldPassword, this.state.security.newPassword, function (response) {
            security.response = response
            self.setState({
                security: {
                    oldPassword: "",
                    newPassword: "",
                    response: response
                }
            })
        })
    }

    _logout(e) {
        if (e) e.preventDefault()
        var user = this.state.user
        this.props.actions.auth.logout(user, () => { })
    }
}

export default ReduxBinder(PageProfile, {
    state: ['auth', 'user']
})