import { hot } from 'react-hot-loader/root'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'alias-store/store'
import Helmet from "react-helmet"
import { metaData } from '../config'

import Public from './app/public/Public'
import * as PublicPages from './app/public/pages'
import Authenticated from './app/authenticated/Authenticated'

console.info(`Frontend version: ${VERSION || 'Not defined'}`) // eslint-disable-line
console.info(`Environment: ${ENV_CONFIG}`) // eslint-disable-line
console.info(`Running debug version: ${DEBUG}`) // eslint-disable-line
//
// STORE
//
if (window.location.hostname !== 'localhost' && (window.location.hostname === 'seriesimpact.com' || window.location.hostname === 'livecase.com') && window.location.protocol === 'http:') {
    window.location = window.location.href.replace('http:', 'https')
}

const store = configureStore()
// console.log("STATE ---------");
// console.log(store.getState());
// store.subscribe(function(){
// 	console.log("STATE ---------");
// 	console.log(store.getState());
// });

//
// COMPONENT
//
const RouterLayer = () => {
    return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <Helmet {...metaData} />
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/auth" component={Public} />
                        <Route path="/error/:errorId?" component={PublicPages.Error} />
                        <Route path="/" component={Authenticated} />
                        <Route component={PublicPages.NotFound} />
                    </Switch>
                </Router>
            </Provider>
        </div>
    )
}


export default hot(RouterLayer)
