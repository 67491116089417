import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Editor from './Editor'
import FileIcons from './FileIcons'
import FileUploaderWithProgress from './FileUploaderWithProgress'
import scripts from 'alias-tools/scripts'

export default class LMS extends Component {

    static propTypes = {
        // Basics
        course: PropTypes.object.isRequired,
        module: PropTypes.object,
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        // Editor
        text: PropTypes.any,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        // For files
        for: PropTypes.string.isRequired
    }

    static defaultProps = {
        course: {},
        module: null,
        placeholder: '',
        text: null
    }

    constructor(props) {
        super(props)

        this.state = {
            docsBeingUploaded: []
        }
    }

    render() {
        const course = this.props.course
        const module = this.props.module
        const docs = course.docs.filter((doc) => {
            if (module) {
                return doc.for === this.props.for && module.staticId == doc.moduleStaticId
            }
            return doc.for === this.props.for
        })
        return (
            <div>
                <Editor
                    tag={`${this.props.for}`}
                    text={this.props.text}
                    placeholder={this.props.placeholder}
                    onChange={(text) => {
                        this.props.onChange(text)
                    }} />
                <div>
                    {(this.state.docsBeingUploaded.length > 0 || docs.length > 0) && (
                        <ul className="lms-docs">
                            {this.state.docsBeingUploaded.map(doc => {
                                return <li key={doc.staticId}>
                                    {/*<div className="icon">
                                            <FileIcons name={doc.name} height={80} isProcessing={true} />
                                </div>*/}
                                    <div className="text">
                                        <p><FileIcons name={doc.name} height={80} isProcessing={true} /> {doc.name}</p>
                                        <FileUploaderWithProgress doc={doc} actions={this.props.actions} onComplete={this._finishedUploading.bind(this)} />
                                    </div>
                                </li>

                            })}
                            {docs.map(doc => {
                                return <li key={doc.staticId}>
                                    {/*<div className="icon">
                                            <FileIcons name={doc.name} height={80} />
                                        </div>*/}
                                    <div className="text">
                                        <a href={doc.path} target="_blank"><FileIcons name={doc.name} height={80} />{doc.name}</a>
                                        <a onClick={this._deleteDoc.bind(this, doc)} className={`pull-right ${this.props.state.doc.isSaving ? '' : ' red'}`}>
                                            {(this.props.state.doc.isSaving) && (<i className="fa fa-spinner fa-pulse" />)}
                                            {(!this.props.state.doc.isSaving) && (<i className="fa fa-times" />)} Remove
                                            </a>
                                    </div>
                                </li>

                            })}
                        </ul>
                    )}
                    <a onClick={(e) => {
                        e.preventDefault()
                        this.refs.filesInput.click()
                    }}><i className="fa fa-paperclip" /> Attach a file</a>
                    <input onChange={this._setUploadDocs.bind(this)} ref="filesInput" type="file" multiple="multiple" />
                </div>
            </div>
        )
    }

    _finishedUploading(finishedUploadingDoc) {
        var docsBeingUploaded = this.state.docsBeingUploaded.filter(doc => {
            return doc.staticId != finishedUploadingDoc.staticId
        })
        this.setState({ docsBeingUploaded: docsBeingUploaded })
    }

    _setUploadDocs(e) {
        var fileList = []
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                let staticId = scripts.randomString()
                var model = {
                    staticId: staticId,
                    for: this.props.for,
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: this.props.course.id,
                    file: e.target.files[k]
                }
                if (this.props.module) {
                    model.moduleStaticId = this.props.module.staticId
                }
                fileList.push(model)
            }
        }
        this.refs.filesInput.value = ""
        this.setState({ docsBeingUploaded: this.state.docsBeingUploaded.concat(fileList) })
    }

    _deleteDoc(doc) {
        if (confirm("Are you sure you want to delete " + doc.name + "?")) {
            this.props.actions.doc.remove(doc)
        }
    }
}