import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'
import Form from '../Form'

// http://www.chartjs.org/docs/latest/configuration/layout.html

// const baseDataSet = {
//     label: 'My First dataset',
//     fill: false,
//     lineTension: 0.1,
//     backgroundColor: 'rgba(75,192,192,0.4)',
//     borderColor: 'rgba(75,192,192,1)',
//     data: []
// }

// const graphLegendOptions = {
//     display: true,
//     position: 'bottom',
//     fullWidth: true,
//     reverse: false,
//     labels: {
//         fontColor: '#333'
//     }
// }

// http://www.chartjs.org/docs/latest/developers/axes.html
// let MyScale = Chart.Scale.extend({
//     /* extensions ... */
// });

// Chart.scaleService.registerScaleType('myScale', MyScale, defaultConfigObject);

// const graphOptions = {
//     layout: {
//         padding: {
//             left: 20,
//             right: 0,
//             top: 0,
//             bottom: 0
//         },
//         title: {
//             display: false
//         },
//         scales: {
//             yAxes: [{
//                 ticks: {
//                     beginAtZero: true
//                 }
//             }]
//         }
//         // scales: {
//         //     yAxes: [{
//         //         type: 'myScale' // this is the same key that was passed to the registerScaleType function
//         //     }]
//         // }
//     }
// }

// const colors = ["#631D76", "#FE5F55", "#BE3E82", "#F9C80E", "#496DDB", '#FE9920', '#CFD11A', '#91C499', '#9E768F', '#E55381', '#D183C9', '#2292A4', '#7DBBC3', '#AB3428']


export default class WidgetGraph extends Component {
    static propTypes = {
        course: PropTypes.object,
        actions: PropTypes.object,
        formStaticId: PropTypes.string
    }
    static defaultProps = {
        formStaticId: ""
    }
    constructor(props) {
        super(props)

        this.state = {
            formStaticId: props.formStaticId,
            participants: [],
            teams: [],
            showLogs: false
        }
    }



    render() {
        const { props, state } = this
        const { course } = props

        let currentFormLogs = null
        let currentForm = null

        const formSelectionOptions = course.forms.map(form => {
            let logs = course.activityLogs.filter(l => l.formStaticId === form.staticId)
            if (state.formStaticId && form.staticId === state.formStaticId) {
                currentFormLogs = logs
            }
            return {
                label: `${form.name} (${logs.length})`,
                value: form.staticId
            }
        })

        let formAnswers = {
            course: course,
            participants: [],
            logs: [],
            form: null,
            showAllAnswers: false
        }
        if (state.formStaticId) {
            formAnswers.form = course.byStaticId.forms[state.formStaticId]
            formAnswers.participants = course.participants
            if (currentFormLogs) {
                formAnswers.logs = currentFormLogs
            }
            currentForm = course.byStaticId.forms[state.formStaticId]
        }

        const colSize = 6


        return <div>

            <div className="row">
                <div className={`col-xs-12 col-sm-${colSize}`}>
                    <Input type="select"
                        label="Form"
                        name="formStaticId"
                        value={state.formStaticId}
                        options={formSelectionOptions}
                        placeholder="Select a form"
                        onChange={this._onChangeFilter.bind(this)} />
                </div>
                <div className={`col-xs-12 col-sm-${colSize}`}>
                    <br />
                    {(currentForm) && <a href={`/form/${currentForm.courseId}/${currentForm.staticId}`} target="_blank" className="btn btn-link">Go to form</a>}
                    {(state.formStaticId && currentFormLogs) && <a onClick={() => this.setState({ showLogs: !state.showLogs })} className="btn btn-link">{state.showLogs ? 'Hide' : 'View'} logs ({currentFormLogs.length})</a>}
                </div>
            </div>

            {!state.formStaticId && <p className="alert alert-info"><i className="fa fa-arrow-up" /> Please select a form above</p>}

            {state.showLogs && <div>
                <h3>Form logs</h3>
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>From</th>
                            <th>To</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentForm && currentFormLogs && currentFormLogs.map(log => {
                            let fromParticipant = course.byId.participants[log.fromParticipantId] ? course.byId.participants[log.fromParticipantId].displayName.bigTeamThenName : "Not found"
                            let toParticipant = course.byId.participants[log.participantId] ? course.byId.participants[log.participantId].displayName.bigTeamThenName : course.byId.teams[log.participantId] ? course.byId.teams[log.participantId].name : "Not found"
                            return <tr key={log.id}>
                                <td>{fromParticipant}</td>
                                <td>{toParticipant}</td>
                                <td style={{ textAlign: 'right' }}>
                                    <a href={`/form/${currentForm.courseId}/${currentForm.staticId}/${log.id}`} target="_blank" className="btn btn-secondary">Edit</a>
                                    <a className="btn btn-link red" onClick={() => this._onRemoveLog(log)}><i className="fa fa-trash-o" /></a>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <br />
                <h3>Form questions & answers</h3>
            </div>}

            {(state.formStaticId) && <div>
                <Form.Answers
                    {...formAnswers}
                    showAllAnswers={true} />
            </div>}
        </div>
    }

    _onRemoveLog(log) {
        if (confirm("Are you sure you want to delete this log?")) {
            this.props.actions.activityLog.remove(log)
        }
    }

    _onChangeFilter(name, value) {
        let state = this.state
        state[name] = value
        if (name === 'formStaticId' && !value) {
            state.showLogs = false
        }
        this.setState(state)
    }
}
