import Configuration from './BadgeConfiguration'
import Card from './BadgeCard'
import Tools from './BadgeTools'
import Modal from './BadgeModal'

export default {
    Configuration,
    Card,
    Tools,
    Modal
}