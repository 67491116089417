import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Card } from 'alias-components'
import { Link } from 'react-router-dom'


class ControlTabDashboard extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }


    render() {
        const { control } = this.props.state
        const { courses, users } = control

        return (
            <div className="config-right-wrapper">
                <div className="page-header">
                    <h1>Dashboard</h1>
                </div>

                <h2>Courses</h2>
                <div className="row">
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Templates" isCentered>
                            <h1>{courses.filter(c => c.isTemplate).length}</h1>
                        </Card>
                    </div>
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Market" isCentered>
                            <h1>{courses.filter(c => c.isTemplate && c.isPublic).length}</h1>
                        </Card>
                    </div>
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Running / Finished" isCentered>
                            <h1>{courses.filter(c => !c.isTemplate && c.isFinished).length} <small>{courses.filter(c => !c.isTemplate && !c.isFinished).length}</small></h1>
                        </Card>
                    </div>
                </div>
                <Link to="/control/courses" className="btn btn-link btn-block btn-lg">View courses & templates <i className="fa fa-chevron-right"></i></Link>
                <hr />
                <h2>Users</h2>
                <div className="row">
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Total" isCentered>
                            <h1>{users.length}</h1>
                        </Card>
                    </div>
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Authors" isCentered>
                            <h1>{users.filter(u => u.participants.filter(p => p.isAdmin).length > 0).length}</h1>
                        </Card>
                    </div>
                    <div className="col-xs-1 col-sm-4">
                        <Card title="Part... / None" isCentered>
                            <h1>{users.filter(u => u.participants.filter(p => !p.isAdmin).length > 0).length} <small>{users.filter(u => u.participants.length === 0).length}</small></h1>
                        </Card>
                    </div>
                </div>
                <Link to="/control/users" className="btn btn-link btn-block btn-lg">View users & authors <i className="fa fa-chevron-right"></i></Link>
                <hr />
            </div>
        );
    }
}

export default ReduxBinder(ControlTabDashboard, {
    state: ['control', 'user', 'local']
})