import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'

import countries from 'alias-data/countries'
import industries from 'alias-data/industries'
import structures from 'alias-store/structures'

export default class OrganizationCard extends Component {

    static propTypes = {
        organization: PropTypes.object,
        edit: PropTypes.func,
        children: PropTypes.any
    }

    static defaultProps = {
        organization: structures.organization
    }

    render() {
        const { edit, organization, children } = this.props

        let country = countries.filter(country => country.value == organization.country)
        country = (country.length > 0) ? country[0].label : "Not assigned"

        let industry = industries.filter(industry => industry.value == organization.industry)
        industry = (industry.length > 0) ? industry[0].label : "Not assigned"

        return (
            <Card>
                <div className="card-course">
                    {edit && (
                        <a onClick={edit.bind(this, organization)} className="card-edit"><i className="fa fa-pencil"></i></a>
                    )}
                    <div className="left">
                        <Avatar user={organization} size={120} />
                    </div>
                    <div className="right">
                        <h3>{organization.name}</h3>
                        <p className="description">{organization.description ? organization.description : <em>No description</em>}</p>
                        <p className="help-block">
                            <i className="fa fa-map-marker fa-fw" /> {country}<br />
                            <i className="fa fa-align-justify fa-fw" /> {industry}
                        </p>
                        {children}
                    </div>
                </div>
            </Card>
        )
    }
}