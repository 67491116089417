import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Loading, Layout, Card } from 'alias-components'
import Course from './components/Course'
import Organization from './components/Organization'
import Character from './components/Character'
import Role from './components/Role'
import Variable from './components/Variable'
import Form from './components/Form'
import Scoring from './components/Scoring'
import { Link } from 'react-router-dom'
import ReduxBinder from 'alias-store/ReduxBinder'

class PageInstructorMarketCourse extends Component {

    static defaultProps = {
        actions: PropTypes.object,
        state: PropTypes.objet
    }

    constructor(props) {
        super(props)

        this.state = {
            courseId: this.props.match.params.courseId,
            isLoading: true,
            course: {},
            showModalCourse: false,
            showModalTemplate: false
        }
    }
    componentDidMount() {
        this.props.actions.course.load(this.state.courseId, (course) => {
            this.setState({
                course: course,
                isLoading: false
            })
        })
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />
        }

        const course = this.state.course
        const pageTitle = `Instructor / Marketplace / ${course.name}`

        return (
            <Layout title={course.name}>
                <Helmet title={pageTitle} />

                <div className="container">
                    <br />
                    <Link to="/courses/market" className="btn btn-default"><i className="fa fa-chevron-left" /> Back to market</Link>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <Card title="Basic info">
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <div className="col-md-3 control-label">Name</div>
                                        <div className="col-md-9">
                                            <p className="form-control-static">{course.name}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-3 control-label">Description</div>
                                        <div className="col-md-9">
                                            <p className="form-control-static">{course.description}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-md-3 control-label">Game</div>
                                        <div className="col-md-9">
                                            <ul className="form-control-static" style={{ paddingLeft: '0' }}>
                                                <li>Organization{course.organizations.length > 1 ? 's' : ''}: {course.organizations.length}</li>
                                                <li>Character{course.characters.length > 1 ? 's' : ''}: {course.characters.length}</li>
                                                <li>Role{course.roles.length > 1 ? 's' : ''}: {course.roles.length}</li>
                                                <li>Activit{course.activities.length > 1 ? 'ies' : 'y'}: {course.activities.length}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card title="Use it">
                                <p className="help-block">You can create a course from this template by simply clicking the button below:</p>
                                <a className="btn btn-primary" onClick={() => {
                                    this.setState({
                                        showModalCourse: true
                                    })
                                }}>Use template</a>
                                {/*<a style={{marginLeft: '10px'}}>Add to your templates</a>*/}

                                <Course.Modal isShown={this.state.showModalCourse} course={course} actions={this.props.actions}
                                    state={this.props.state} close={() => {
                                        this.setState({
                                            showModalCourse: false
                                        })
                                    }} />
                            </Card>
                        </div>
                    </div>

                    <h3>Organizations</h3>
                    {course.organizations.length === 0 && (
                        <p className="help-block">No organizations</p>
                    )}
                    <div className="row">
                        {course.organizations.map(function (organization) {
                            return (
                                <div className="col-md-6" key={organization.id}>
                                    <Organization.Card organization={organization} />
                                </div>
                            )
                        })}
                    </div>

                    <h3>Characters</h3>
                    {course.characters.length === 0 && (
                        <p className="help-block">No characters</p>
                    )}
                    <div className="row">
                        {course.characters.map(function (character) {
                            return (
                                <div className="col-md-6" key={character.id}>
                                    <Character.Card character={character} organizations={course.organizations} />
                                </div>
                            )
                        })}
                    </div>

                    <h3>Roles</h3>
                    {course.roles.length === 0 && (
                        <p className="help-block">No roles</p>
                    )}
                    <div className="row">
                        {course.roles.map(function (role) {
                            return (
                                <div className="col-md-6" key={role.id}>
                                    <Role.Card role={role}></Role.Card>
                                </div>
                            )
                        })}
                    </div>

                    <h3>Variables</h3>
                    {course.variables.length === 0 && (
                        <p className="help-block">No variables</p>
                    )}
                    <div className="row">
                        {course.variables.map(function (variable) {
                            return (
                                <div className="col-md-6" key={variable.id}>
                                    <Variable.Card item={variable}></Variable.Card>
                                </div>
                            )
                        })}
                    </div>

                    <h3>Scoring tags</h3>
                    {course.variables.length === 0 && (
                        <p className="help-block">No scoring tags</p>
                    )}
                    <div className="row">
                        {course.scoringTags.map(scoringTag => {
                            return (
                                <div className="col-md-6" key={scoringTag.id}>
                                    <Scoring.Card item={scoringTag}></Scoring.Card>
                                </div>
                            )
                        })}
                    </div>

                    <h3>Forms</h3>
                    {course.variables.length === 0 && (
                        <p className="help-block">No forms</p>
                    )}
                    <div className="row">
                        {course.forms.map(form => {
                            return (
                                <div className="col-md-6" key={form.id}>
                                    <Form.Card form={form}></Form.Card>
                                </div>
                            )
                        })}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </Layout>
        )
    }
}

export default ReduxBinder(PageInstructorMarketCourse, {
    state: ['course', 'user']
})