import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Spinner extends Component {
    static propTypes = {
        style: PropTypes.any
    }
    render() {
        return <i className="fa fa-spinner fa-spin" style={this.props.style}></i>
    }

}