import Scripts from 'alias-tools/scripts'

//
// Action types
//
const CLEAR = 'si/notification/CLEAR'
const ADD = 'si/notification/ADD'
const REMOVE = 'si/notification/REMOVE'

const LOGOUT = 'si/auth/multi/LOGOUT'

const initialState = {
    notifications: []
}

const defaultNotification = {
    type: 'success',
    title: null,
    body: null,
    timeout: 3000,
    id: null
}

//
// Reducers
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return initialState
        case ADD:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...defaultNotification,
                        id: Scripts.randomString(),
                        ...action.notification
                    }
                ]
            }
        case REMOVE:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.notification.id)
            }
        case LOGOUT:
            return initialState
        default:
            return state
    }
}

//
// Action creators
//

export function clear() {
    return { type: CLEAR }
}

export function add(notification) {
    return { type: ADD, notification: notification }
}

export function remove(notification) {
    return { type: REMOVE, notification: notification }
}
