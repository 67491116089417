import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'

const initialData = {
    name: '',
    location: '',
    duration: 0,
    description: ''
}

export default class ActivityTypeCalendar extends Component {

    static propTypes = {
        activity: PropTypes.object,
        onActivityUpdate: PropTypes.func.isRequired,
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
    }



    render() {
        let { activity } = this.props;
        const calendar = activity.data;


        return <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-3 control-label">Name</label>
                <div className="col-sm-9">
                    <Input
                        type="text"
                        name="name"
                        value={calendar.name}
                        placeholder="Name of event"
                        onChange={this._onChange.bind(this)} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Location</label>
                <div className="col-sm-9">
                    <Input
                        type="text"
                        name="location"
                        value={calendar.location}
                        placeholder="Ex: Room A"
                        onChange={this._onChange.bind(this)} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Duration</label>
                <div className="col-sm-9">
                    <Input
                        type="number"
                        name="duration"
                        value={calendar.duration}
                        placeholder="Ex: 10"
                        onChange={this._onChange.bind(this)} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Description</label>
                <div className="col-sm-9">
                    <Input
                        type="textarea"
                        name="description"
                        value={calendar.description}
                        placeholder="Describe the event"
                        onChange={this._onChange.bind(this)} />
                </div>
            </div>
        </div>
    }
    _onChange(name, value) {
        let activity = this.props.activity
        activity.data[name] = value
        this.props.onActivityUpdate(activity)
    }
}