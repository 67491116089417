import config from 'alias-config'

export default {
    URL() {
        var URL = ""
        var api = config.api
        if (!api.host) {
            URL = window.location.origin
        } else {
            URL = ((api.ssl) ? 'https' : 'http') + '://' + api.host + ':' + api.port
        }
        return URL
    },
    apiURL() {
        var URL = ""
        var api = config.api
        if (!api.host) {
            URL = window.location.origin + ((api.prefix) ? api.prefix : '')
        } else {
            URL = ((api.ssl) ? 'https' : 'http') + '://' + api.host + ':' + api.port + ((api.prefix) ? api.prefix : '')
        }
        return URL
    },
    isEditorEmpty(editorValue) {
        if (!editorValue) { return true }
        if (typeof editorValue !== 'object') { return true }
        if (!editorValue.blocks) { return true }
        if (editorValue.blocks.length === 0) { return true }
        if (editorValue.blocks[0].text === "") { return true }
        return false
    },
    getUserDisplayName(user) {
        let displayName = ""
        displayName += user.first ? user.first : ''
        displayName += (user.first && user.last) ? ' ' : ''
        displayName += user.last ? user.last : ''
        if (!user.first && !user.last && user.email) {
            displayName += user.email
        }
        return displayName
    },
    randomString() {
        return (Math.floor(Math.random() * 999999) + +new Date()).toString(36)
    },
    numberFormatting(nStr) {
        nStr = nStr + ""
        var remainder = nStr.length % 3
        return (nStr.substr(0, remainder) + nStr.substr(remainder).replace(/(\d{3})/g, ' $1')).trim()
    },
    bitesDisplay(bites) {
        var sOutput = 0
        for (var aMultiples = ["kb", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], nMultiple = 0, nApprox = bites / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
            var decimalCount = (aMultiples[nMultiple] == 'kb') ? 1 : 100
            sOutput = Math.round(nApprox.toFixed(3) * decimalCount) / decimalCount + " " + aMultiples[nMultiple]
        }
        return sOutput
        // return Math.round(bites / 1000000 * 100) / 100 + 'MB'
    },
    sortArrayObjectDate(array, prop, reverse = false) {
        return array.sort(function (a, b) {
            let reverser = reverse ? 1 : -1
            return reverser * (new Date(b[prop]) - new Date(a[prop]))
        })
    },
    sortArrayObjectMoment(array, prop, reverse = false) {
        return array.sort(function (a, b) {
            if (a[prop] && b[prop]) {
                return !reverse ? b[prop].diff(a[prop]) : a[prop].diff(b[prop])
            }
            if (!a[prop] && !b[prop]) {
                return 0
            }
            if (!a[prop]) {
                return !reverse ? 1 : -1
            }
            if (!b[prop]) {
                return !reverse ? -1 : 1
            }
        })
    },
    templateReplacement(message, code, value) {
        var regexString = '{*' + code + '*}'
        if (message.indexOf(regexString) !== -1) {
            regexString = regexString.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
            return message.replace(new RegExp(regexString, "gi"), value)
        }
        return message
    },
    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1])
        } else {
            byteString = unescape(dataURI.split(',')[1])
        }
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], {
            type: mimeString
        })
    },
    parseVideo(url) {
        // - Supported YouTube URL formats:
        //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
        //   - http://youtu.be/My2FRPA3Gf8
        //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
        // - Supported Vimeo URL formats:
        //   - http://vimeo.com/25451551
        //   - http://player.vimeo.com/video/25451551
        // - Also supports relative URLs:
        //   - //player.vimeo.com/video/25451551
        if (!url) {
            return {
                type: null,
                id: null
            }
        }
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)

        let type = ""
        if (RegExp.$3.indexOf('youtu') > -1) {
            type = 'youtube'
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            type = 'vimeo'
        }

        return {
            type: type,
            id: RegExp.$6
        }
    }
}