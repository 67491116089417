import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Card, LMSChapter, LMSDisplay } from "alias-components"
import ReduxBinder from "alias-store/ReduxBinder"
import scripts from 'alias-tools/scripts'

class InfoTabsCase extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }

    render() {
        const course = this.props.state.course.model


        return (
            <div>
                <Helmet title={course.name + " introduction"} />

                <p className="help-block">
                    <i className="fa fa-info" /> Explain your course in greater details here. What the participants will be taught, how it works, ....<br />
                    This will be shown on the intro page, anyone you add as participant will be able to see this, even if the course is not launched
                </p>
                <LMSChapter
                    isEditable={true}
                    isInEditMode={true}
                    value={course.intros}
                    onSave={this._saveCourse.bind(this)}
                    isAdmin={true}
                    courseId={course.id} />
                {!scripts.isEditorEmpty(course.intro) && <div>
                    <br />
                    <h2 style={{ paddingTop: 0 }}>Deprecated case info</h2>
                    <p>Please copy/edit your old content to new system above.</p>
                    <p>This content will still be displayed to participants. When you are ready, please delete the data: <br />
                        <a className="btn btn-danger" onClick={this._deleteOldInfo.bind(this)}>Delete old info</a></p>
                    <Card>
                        <LMSDisplay course={course} text={course.intro} />
                    </Card>
                </div>}
            </div>
        )
    }



    _deleteOldInfo() {
        if (confirm("Are you sure you are ready to delete old case info?")) {
            let course = this.props.state.course.model
            course.intro = null
            this.props.actions.course.update(course)
        }
    }


    _saveCourse(value, callback = () => { }) {
        let course = this.props.state.course.model
        course.intros = value
        this.props.actions.course.update(course, callback)
    }
}

export default ReduxBinder(InfoTabsCase, {
    state: ["course"]
})