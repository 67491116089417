import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input, Avatar } from 'alias-components'
import Helmet from 'react-helmet'
import ReduxBinder from "alias-store/ReduxBinder"
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import ActivityTriggeredEmailModal from '../../components/Activity/elements/ActivityTriggeredEmail'


const styles = {
    box: {
        padding: '10px 0'
    },
    badgeIn: {
        position: 'absolute',
        top: 2,
        left: 0,
        fontSize: 9
    },
    badgeOut: {
        position: 'absolute',
        bottom: 2,
        left: 0,
        fontSize: 9
    }
}

const SortableItem = SortableElement(({ value }) => {
    return <tr key={value.id} style={{ zIndex: 10000, background: 'white' }}>
        <td width={30}><i className="fa fa-align-justify"></i></td>
        <td width={50}><Avatar user={value.to} size={30} /></td>
        <td style={{ position: 'relative' }}>
            {value.badgeIn && <span style={styles.badgeIn} className="badge warning">{value.badgeIn.name}</span>}
            <div style={styles.box}><i className="fa fa-envelope fa-fw"></i> {value.name}</div>
            {value.badgesOut && <div style={styles.badgeOut}>
                {value.badgesOut.map((badge, i) => {
                    return <span key={`${badge}-${i}`} style={{ fontSize: 9 }} className="badge info">{badge.name}</span>
                })}
            </div>}
        </td>
        <td width={80}><a onClick={() => value.openModal(value)} className="btn btn-link">Edit</a></td>
    </tr>
})

const SortableList = SortableContainer(({ items }) => {
    return <table className="table table-stripped table-hover">
        <tbody>
            {items.map((activity, index) => {
                return <SortableItem
                    key={activity.id}
                    index={index}
                    value={activity} />
            })}
        </tbody>
    </table>
})


class ModuleTabTriggered extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            filter: '', // jsxn13cl
            activity: null
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            module: newProps.module
        })
    }

    _getFilterOptions() {
        const course = this.props.state.course.model
        let filterOptions = []

        course.roles.map(role => {
            filterOptions.push({
                label: role.name,
                value: role.staticId
            })
        })
        return filterOptions
    }

    _filteredActivities(moduleStaticId, roleStaticId) {
        return this.props.state.course.model.activities
            .filter(activity => {
                if (activity.moduleStaticId !== moduleStaticId) { return false }
                if (activity.type !== "triggered-email") { return false }
                if (activity.roleStaticId !== roleStaticId) { return false }
                return true
            })
    }

    render() {
        const { props, state } = this
        const course = props.state.course.model
        const moduleId = props.match.params.moduleId
        const module = course.byId.modules[moduleId]
        if (!module) {
            return <div>Module not found</div>
        }
        let filterOptions = this._getFilterOptions()

        let activities = this._filteredActivities(module.staticId, state.filter)
            .map(activity => {
                activity.to = course.byStaticId.roles[activity.roleStaticId]
                activity.openModal = this._openModal.bind(this)
                // BadgeIn and BadgeOut
                activity.badgeIn = null
                if (activity.order > 0 && activity.badgeStaticId && course.byStaticId.badges[activity.badgeStaticId]) {
                    activity.badgeIn = course.byStaticId.badges[activity.badgeStaticId]
                }
                activity.badgesOut = null
                if (activity.embeddedFormStaticId && course.byStaticId.forms[activity.embeddedFormStaticId]) {
                    let form = course.byStaticId.forms[activity.embeddedFormStaticId]
                    form.questions.map(question => {
                        if (question.options) {
                            question.options.map(option => {
                                if (option.badgeStaticId && course.byStaticId.badges[option.badgeStaticId]) {
                                    if (!activity.badgesOut) {
                                        activity.badgesOut = []
                                    }
                                    activity.badgesOut.push(course.byStaticId.badges[option.badgeStaticId])
                                }
                            })
                        }
                    })
                }
                if (activity.formStaticId && course.byStaticId.forms[activity.formStaticId]) {
                    let form = course.byStaticId.forms[activity.formStaticId]
                    form.questions.map(question => {
                        if (question.options) {
                            question.options.map(option => {
                                if (option.badgeStaticId && course.byStaticId.badges[option.badgeStaticId]) {
                                    if (!activity.badgesOut) {
                                        activity.badgesOut = []
                                    }
                                    activity.badgesOut.push(course.byStaticId.badges[option.badgeStaticId])
                                }
                            })
                        }
                    })
                }
                return activity
            })
            .sort((a, b) => {
                let a1 = a.order
                let b1 = b.order
                if (a1 == b1) return 0
                return a1 > b1 ? 1 : -1
            })


        return <div>
            <Helmet title={course.name + " course activities"} />
            <p className="help-block">Triggered events allow for a more interactive story, where as soon as a participant reads a mail (the trigger), it releases the following email.</p>
            <div className="row">
                <div className="col-xs-12 col-md-4">
                    <Input type="select"
                        placeholder="Pick role..."
                        options={filterOptions}
                        value={state.filter}
                        onChange={(name, value) => this.setState({ filter: value })} />
                </div>
                <div className="col-xs-12 col-md-4">
                    <Input type="text"
                        placeholder="Search..."
                        value={state.search}
                        onChange={(name, value) => this.setState({ search: value })} />
                </div>

                <div className="col-xs-12 col-md-4">

                    {false && <a onClick={this._openModalNew.bind(this)} className="btn btn-secondary btn-block">+ Create group</a>}
                </div>
            </div>
            <br />
            {!state.filter && <div>
                <div className="alert alert-info"><i className="fa fa-arrow-up"></i> Please select a role above to get started.</div>
            </div>}

            {state.filter && <Card title={`To ${course.byStaticId.roles[state.filter].name} (${activities.length})`}>
                <div>
                    <SortableList
                        axis="y"
                        lockAxis="y"
                        pressDelay={200}
                        items={activities}
                        onSortEnd={this._onSortEnd.bind(this)} />
                    {state.search === "" && <a onClick={this._openModalNew.bind(this)} className="btn btn-link">+ Add activity</a>}
                </div>
            </Card>}

            <ActivityTriggeredEmailModal {...props}
                isShown={state.activity !== null}
                module={module}
                activity={state.activity}
                onClose={() => this.setState({ activity: null })}
                updateActivity={this._onUpdateActivity.bind(this)} />

        </div>
    }

    _onSortEnd({ oldIndex, newIndex }) {

        const { props, state } = this
        const course = props.state.course.model
        const moduleId = props.match.params.moduleId
        const module = course.byId.modules[moduleId]
        if (!module) { return }
        let activitiesToBeUpdated = []

        let activities = this._filteredActivities(module.staticId, state.filter)
            .map(activity => {
                activity.to = course.byStaticId.roles[activity.roleStaticId]
                activity.openModal = this._openModal.bind(this)
                return activity
            })
            .sort((a, b) => {
                let a1 = a.order
                let b1 = b.order
                if (a1 == b1) return 0
                return a1 > b1 ? 1 : -1
            })
        activities = arrayMove(activities, oldIndex, newIndex)
        activities.map((activity, i) => {
            if (activity.order !== i) {
                activitiesToBeUpdated.push({
                    id: activity.id,
                    order: i
                })
            }
        })

        if (activitiesToBeUpdated.length > 0) {
            // Update here
            this.props.actions.activity.updateOrder(course.id, activitiesToBeUpdated, () => { })
        }
        // this.setState(({ items }) => ({
        //     items: arrayMove(items, oldIndex, newIndex),
        // }))
    }

    // _onChange(activity, name, value) {
    //     if (activity[name] === value) { return }
    //     activity[name] = value
    //     this.props.actions.activity.update(activity, () => { })
    // }

    _openModal(activity) {
        this.setState({ activity: activity })
    }

    _openModalNew() {
        const { props, state } = this
        const course = props.state.course.model
        const moduleId = props.match.params.moduleId
        const module = course.byId.modules[moduleId]

        this._openModal({
            courseId: course.id,
            moduleStaticId: module.staticId,
            roleStaticId: state.filter,
            order: this._filteredActivities(module.staticId, state.filter).length,
            type: 'triggered-email',
            data: {
                delayOffsetInSeconds: course.delayEmail || 10
            }
        })
    }

    _onUpdateActivity(activity) {
        this.setState({ activity: activity })
    }

}



export default ReduxBinder(ModuleTabTriggered, {
    state: ['course', 'module', 'activity']
})