/* eslint-disable no-console,curly */
// import scripts from 'alias-tools/scripts'
import moment from 'moment-timezone'

export default {
    groupMaker(course, activity, participant, isAdmin = false) {
        if (!activity.data || !activity.data.settings || !activity.data.rolePlay) {
            return []
        }
        const formLogs = course.activityLogs.filter(log => log.type === 'form' && log.data.activityId === activity.id)
        const rolePlaySettings = activity.data.settings
        const rolePlay = activity.data.rolePlay
        const now = moment().tz(course.timezone)
        const rolePlayStartDate = activity.date
        // Preping data for display
        let groups = this.groupMakerHelpers.rolePlayToGroups(rolePlay)

        groups = groups.map(group => {
            let groupHasParticipant = false
            group.start = rolePlayStartDate.clone().add(group.offset, 'm')
            group.end = group.start.clone().add(rolePlaySettings.duration, 'm')
            group.formDisplayDate = group.end.clone().subtract(rolePlaySettings.formDisplayOffset, 'm')
            group.showForms = group.formDisplayDate.isBefore(now)
            group.participants = group.participants.map(participantInfo => {
                participantInfo.participant = course.byId.participants[participantInfo.participantId]
                if (!participantInfo.participant) { return null }
                participantInfo.party = rolePlaySettings.parties[participantInfo.partyIndex - 1] || { description: "" }
                if (group.showForms) {
                    if (participant) {
                        participantInfo.formData = {
                            fromId: participant.id,
                            toId: participantInfo.participantId,
                            formStaticId: null,
                            groupId: group.name,
                            activityId: activity.id,
                            form: { name: "" },
                            isDone: false
                        }
                        // debugger;
                        if (participantInfo.participantId === participant.id) {
                            // Global form
                            if (rolePlaySettings.globalFormStaticId) {
                                participantInfo.formData.formStaticId = rolePlaySettings.globalFormStaticId
                                participantInfo.formData.form = course.byStaticId.forms[rolePlaySettings.globalFormStaticId]
                            }

                        } else {
                            // Each form
                            if (participantInfo.party.formStaticId) {
                                participantInfo.formData.formStaticId = participantInfo.party.formStaticId
                                participantInfo.formData.form = course.byStaticId.forms[participantInfo.party.formStaticId]
                            }
                        }
                        if (participantInfo.formData.formStaticId) {
                            participantInfo.formData.isDone = formLogs.filter(l => l.formStaticId === participantInfo.formData.formStaticId
                                && l.fromParticipantId === participantInfo.formData.fromId
                                && l.participantId === participantInfo.formData.toId
                                && l.data && l.data.groupId === group.name).length > 0
                        }
                    }
                }
                if (participant && participant.id === participantInfo.participantId) {
                    groupHasParticipant = true
                }
                return participantInfo
            }).filter(p => p !== null)

            return isAdmin || (!isAdmin && groupHasParticipant) ? group : null
        }).filter(g => g !== null)

        return groups
    },
    groupMakerHelpers: {
        rolePlayToGroups(rolePlay) {
            let groups = []
            let groupNames = []
            rolePlay.map((rp, i) => {
                let position = groupNames.indexOf(rp.group)
                if (position === -1) {
                    let group = {
                        index: i + 1,
                        name: rp.group,
                        location: rp.location,
                        offset: rp.offset,
                        participants: [{
                            partyIndex: rp.partyIndex,
                            participantId: rp.participantId
                        }]
                    }
                    groupNames.push(rp.group)
                    groups.push(group)
                } else {
                    groups[position].participants.push({
                        partyIndex: rp.partyIndex,
                        participantId: rp.participantId
                    })
                }
            })
            return groups
        }
    },
    nPartyHelpers: {
        shuffle(array) {
            var currentIndex = array.length,
                temporaryValue,
                randomIndex
            // While there remain elements to shuffle...
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex)
                currentIndex -= 1
                // And swap it with the current element.
                temporaryValue = array[currentIndex]
                array[currentIndex] = array[randomIndex]
                array[randomIndex] = temporaryValue
            }

            return array
        },
        groupingWithTeams(parties) {
            let groups = []
            let k,
                j
            const bigParty = parties[0]
            let partiesOffset = []
            let usedParticipantsList = []
            for (k = 0; k < bigParty.participantsWithThatRole.length; k++) {
                // New group
                let group = []
                let groupTeamIdList = []
                // Add that participant to new group,
                let bigPartyParticipant = bigParty.participantsWithThatRole[k]
                groupTeamIdList.push(bigPartyParticipant.teamId)
                // console.log(`BIG: ${bigPartyParticipant.displayName.bigTeamThenName}`)
                group.push(bigPartyParticipant)
                for (j = 1; j < parties.length; j++) {
                    // Defining second party
                    let nParty = parties[j]
                    usedParticipantsList[j] = usedParticipantsList[j] || []
                    // if j in nParty doesn't exist, restart, Or if is more than max then restart.
                    if (partiesOffset[j] === undefined || partiesOffset[j] >= (nParty.participantsWithThatRole.length - 1)) {
                        partiesOffset[j] = 0
                    }
                    // They are the same team
                    let isSameTeamOrUsedParticipant = true
                    let tempUselessCounter = nParty.participantsWithThatRole.length + 2
                    while (isSameTeamOrUsedParticipant === true) {
                        if (tempUselessCounter < 0) {
                            isSameTeamOrUsedParticipant = false
                            console.warn("Probably an error")
                        }
                        tempUselessCounter--
                        let currentNPartyParticipant = nParty.participantsWithThatRole[partiesOffset[j]]
                        // currentNPartyParticipant ? console.log(currentNPartyParticipant.displayName.bigTeamThenName) : null
                        // currentNPartyParticipant ? console.log(currentNPartyParticipant !== undefined, (currentNPartyParticipant.teamId === undefined || (currentNPartyParticipant.teamId && groupTeamIdList.indexOf(currentNPartyParticipant.teamId) === -1)), usedParticipantsList[j].indexOf(currentNPartyParticipant.id) === -1) : null
                        if (currentNPartyParticipant
                            && (currentNPartyParticipant.teamId === undefined || (currentNPartyParticipant.teamId && groupTeamIdList.indexOf(currentNPartyParticipant.teamId) === -1))
                            && usedParticipantsList[j].indexOf(currentNPartyParticipant.id) === -1) {
                            isSameTeamOrUsedParticipant = false
                        } else {
                            if (usedParticipantsList[j].length === nParty.participantsWithThatRole.length) {
                                usedParticipantsList[j] = []
                            }
                            if (partiesOffset[j] === nParty.participantsWithThatRole.length) {
                                partiesOffset[j] = 0
                            } else {
                                partiesOffset[j]++
                            }
                        }
                    }
                    if (nParty.participantsWithThatRole[partiesOffset[j]]) {
                        if (nParty.participantsWithThatRole[partiesOffset[j]].teamId) {
                            groupTeamIdList.push(nParty.participantsWithThatRole[partiesOffset[j]].teamId)
                        }
                        usedParticipantsList[j].push(nParty.participantsWithThatRole[partiesOffset[j]].id)
                        group.push(nParty.participantsWithThatRole[partiesOffset[j]])
                    }
                    partiesOffset[j]++
                }
                groups.push(group)
            }
            return groups
        },
        groupingNoTeams(parties) {
            let groups = []
            let k,
                j
            const bigParty = parties[0]
            let partiesOffset = []
            for (k = 0; k < bigParty.participantsWithThatRole.length; k++) {
                // New group
                let group = []
                // Add that participant to new group,
                group.push(bigParty.participantsWithThatRole[k])
                for (j = 1; j < parties.length; j++) {
                    // Defining second party
                    let nParty = parties[j]
                    if (partiesOffset[j] === undefined) {
                        partiesOffset[j] = 0
                    }
                    // if k is less than max than add to group.
                    if (partiesOffset[j] < nParty.participantsWithThatRole.length) {
                        group.push(nParty.participantsWithThatRole[partiesOffset[j]])
                        partiesOffset[j]++
                    } else {
                        partiesOffset[j] = 0
                        group.push(nParty.participantsWithThatRole[partiesOffset[j]])
                        partiesOffset[j]++
                    }
                }
                groups.push(group)
            }
            return groups
        },
        calculateOffset(groups, offset, duration) {
            let finalGroups = []
            let j,
                i
            let participantIdsList = []
            for (j = 0; j < groups.length; j++) {
                let group = groups[j]
                let biggestCount = 0
                for (i = 0; i < group.length; i++) {
                    let participant = group[i]
                    if (participantIdsList.indexOf(participant.id) === -1) { // Not found in list
                        participantIdsList.push(participant.id) // Add participant in list
                    } else {
                        participantIdsList.push(participant.id)
                        let count = participantIdsList.filter(id => id === participant.id).length
                        if (count > biggestCount) {
                            biggestCount = count - 1
                        }
                    }
                }
                finalGroups.push({
                    offset: offset + biggestCount * duration,
                    group: group
                })
            }
            return finalGroups
        },
        locationAllocator(groups, locations, parties) {
            let k
            const smallestParty = parties[parties.length - 1]
            const participantsWithThatRole = smallestParty.participantsWithThatRole.map((participant, p) => {
                if (p <= locations.length) {
                    participant.location = locations[p]
                }
                return participant
            })

            for (k = 0; k < groups.length; k++) {
                let location = null
                groups[k].group.map(participant => {
                    if (participant.location) {
                        location = participant.location
                    }
                })
                groups[k].location = location
            }
            return groups
        },

        validateCourseAndForm(course, generatorForm) {
            // Check that there is at least 2 parties
            if (generatorForm.parties.length < 2) {
                return {
                    success: false,
                    reason: 'Not enough parties'
                }
            }
            // Check if 2 parties have the same role
            let roleList = []
            generatorForm.parties.map(party => {
                if (roleList.indexOf(party.roleStaticId) === -1) {
                    roleList.push(party.roleStaticId)
                } else {
                    return {
                        success: false,
                        reason: 'At least 2 parties have the same role. This is not allowed.'
                    }
                }
            })
            // Check that each roles has at least 1 participant
            let hasEnoughParticipants = true
            let participantsCountList = []
            generatorForm.parties.map(party => {
                let participantsCount = course.participants.filter(part => part.roleStaticId === party.roleStaticId).length
                if (participantsCount === 0) {
                    hasEnoughParticipants = false
                }
                participantsCountList.push(participantsCount)
            })
            if (!hasEnoughParticipants) {
                return {
                    success: false,
                    reason: 'No participants in one of the parties'
                }
            }
            // Check that the amount of rooms is equal to or more than the smallest amount of participants
            const min = Math.min(...participantsCountList)
            if (min > generatorForm.locations.length) {
                return {
                    success: false,
                    reason: 'Not enough rooms. Need a total of ' + min
                }
            }
            return {
                success: true
            }
        }
    },
    nPartyAllocator(course, generatorForm) {

        let participants = course.participants // Shuffle should be done outside so that it is testable.
        let teams = course.teams
        let groups = []

        const parties = generatorForm.parties.map(party => {
            party.participantsWithThatRole = participants.filter(participant => participant.roles.indexOf(party.roleStaticId) !== -1).map(participant => {
                return participant
            })
            return party
        }).sort((a, b) => {
            return parseFloat(b.participantsWithThatRole.length) - parseFloat(a.participantsWithThatRole.length)
        })

        if (teams.length > 0) {
            groups = this.nPartyHelpers.groupingWithTeams(parties)
        }

        if (teams.length === 0) {
            groups = this.nPartyHelpers.groupingNoTeams(parties)
        }

        groups = this.nPartyHelpers.calculateOffset(groups, generatorForm.offset, generatorForm.duration)

        groups = groups.sort((a, b) => {
            return parseFloat(a.offset) - parseFloat(b.offset)
        })

        groups = this.nPartyHelpers.locationAllocator(groups, generatorForm.locations, parties)

        // console.log("==============")
        const rolePlay = groups.map((group, i) => {
            let rp = {
                location: group.location,
                offset: group.offset,
                globalForm: group.globalForm,
                participants: []
            }

            // console.group(`Group ${i}`)
            // console.log(group)
            group.group.map(part => {
                rp.participants.push(part.id)
            })
            // console.log(rp)
            // console.groupEnd(`Group ${i}`)
            return rp
        })
        return rolePlay
        // return outputs
    }
}