import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Avatar, Search } from 'alias-components'
import Scoring from '../../Scoring'
import Messaging from '../../Messaging'
import Participant from '../../Participant'
import moment from 'moment-timezone'
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import csv from 'alias-tools/csv'
import Fuse from 'fuse.js'

const defaultShownParticipants = 15
moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: '1s',
        ss: '%ds',
        m: "1m",
        mm: "%dmins",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1 month",
        MM: "%d months",
        y: "1y",
        yy: "%dy"
    }
})

const PIPopover = <Popover id={`tooltip-pi-t`} title="Participation Index (aka PI)">
    <div>
        Is a percentage consisting of:<br />
        <ul className="list-unstyled">
            <li>+ Read emails / Total emails</li>
            <li>+ Read chats / Total chats</li>
            <li>+ Finished forms / Total forms</li>
        </ul>
    </div>
</Popover>

export default class DashboardInstructorScoringList extends Component {
    static propTypes = {
        course: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object
    }
    static defaultProps = {}
    constructor(props) {
        super(props)

        this.state = {
            showTeam: props.course.teams.length > 0,
            shownNumberOfParticipants: defaultShownParticipants,
            isLoadingParticipationScores: false,
            participantScoreDataLoaded: false,
            lastLoadedParticipationScore: moment(),
            selectedParticipant: null,
            selectedTeam: null,
            search: ''
        }
    }
    componentDidMount() {
        // this._loadParticipationScores()
    }
    render() {
        const { props, state } = this

        const hasTeams = props.course.teams.length > 0


        let participants = props.course.participants
            .filter(p => !p.isAdmin)
            .map(participant => {
                participant.score = participant.score ? participant.score : Scoring.Tools.participant(participant, props.course.activities, props.course.activityLogs, props.course.forms, props.course.scoringTags)
                participant.points = !participant.score ? 0 : Math.round(participant.score.total)
                return participant
            })
            .sort((a, b) => b.points - a.points)
            .map((participant, i) => {
                participant.rank = i + 1
                return participant
            })
        if (state.participantScoreDataLoaded) {
            participants = this._processParticipantParticipationScores(participants)
        }

        let teams = props.course.teams
            .map(team => {
                let score = team.score ? team.score : Scoring.Tools.team(team.id, props.course.participants, props.course.activities, props.course.activityLogs, props.course.forms, props.course.scoringTags)
                team.points = !score ? 0 : Math.round(score.total)
                return team
            })
            .sort((a, b) => b.points - a.points)
            .map((team, i) => {
                team.rank = i + 1
                return team
            })
        if (state.participantScoreDataLoaded) {
            teams = this._processTeamParticipationScores(teams, participants)
        }

        if (state.search) {
            if (state.showTeam) {
                // Teams
                const optionsTeam = {
                    shouldSort: false,
                    threshold: 0.3,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                }
                const fuseTeams = new Fuse(teams, optionsTeam)
                teams = fuseTeams.search(state.search)
            }
            if (!state.showTeam) {
                // Participants
                const optionsParticipant = {
                    shouldSort: false,
                    threshold: 0.3,
                    minMatchCharLength: 1,
                    keys: [
                        "displayName.short",
                        "teamName"
                    ]
                }
                const fuseParticipants = new Fuse(participants, optionsParticipant)
                participants = fuseParticipants.search(state.search)
            }
        }

        return <div>
            <div className="row">
                {hasTeams > 0 && (
                    <div className="col-xs-6 col-sm-4">
                        <div style={{ marginTop: 12 }}>
                            {state.showTeam && <a onClick={() => this.setState({ showTeam: !state.showTeam, shownNumberOfParticipants: defaultShownParticipants })} className="btn btn-primary">Show participants</a>}
                            {!state.showTeam && <a onClick={() => this.setState({ showTeam: !state.showTeam, shownNumberOfParticipants: defaultShownParticipants })} className="btn btn-primary">Show teams</a>}
                        </div>
                        {false && <Input
                            label="View"
                            type="select"
                            value={state.showTeam}
                            onChange={(name, value) => this.setState({ showTeam: value })}
                            options={[{ label: 'View participants', value: false }, { label: 'View teams', value: true }]} />}
                    </div>
                )}
                <div className="col-xs-6 col-sm-4">
                    <div style={{ marginTop: 0 }}>
                        <Search search={state.search}
                            onChange={(search) => this.setState({ search: search })} />
                    </div>
                </div>
                <div className="col-xs-6 col-sm-4">
                    <div style={{ marginTop: 11 }}>
                        <a onClick={() => this._loadParticipationScores()}><i className="fa fa-refresh fa-fw" />  {state.isLoadingParticipationScores ? <i className="fa fa-spinner fa-pulse" /> : "Refresh PI"}</a>
                        {state.participantScoreDataLoaded && <span className="help-block" style={{ display: 'inline', marginLeft: 3 }}> {state.lastLoadedParticipationScore.fromNow()}</span>}<br />
                        <a onClick={this._downloadCsv.bind(this)}><i className="fa fa-download fa-fw" /> Download csv</a>
                    </div>
                </div>

            </div>

            {state.showTeam && <div>
                <h3>Teams ({teams.length})</h3>
                {this._renderTeamTable(teams)}
            </div>}
            {!state.showTeam && <div>
                {hasTeams && <h3>Participants ({participants.length})</h3>}
                {this._renderParticipantTable(participants)}
            </div>}



            <Participant.ModalReview
                isOpen={state.selectedParticipant !== null}
                handleClose={this._closeParticipantModal.bind(this)}
                participant={state.selectedParticipant}
                state={props.state}
                actions={props.actions}
                isAdmin={true}
                isModal={true} />

            <Participant.ModalReview
                isOpen={state.selectedTeam !== null}
                handleClose={this._closeTeamModal.bind(this)}
                team={state.selectedTeam}
                state={props.state}
                actions={props.actions}
                isAdmin={true}
                isModal={true} />
        </div>
    }

    _renderTeamTable(teams) {
        const { state } = this
        let showShowMore = false

        if (teams.length > state.shownNumberOfParticipants) {
            teams = teams.splice(0, state.shownNumberOfParticipants)
            showShowMore = true
        }
        return <div>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th width={40}>Rank</th>
                        <th>Name</th>
                        {this.props.course.scoringAverage && <th>Grade</th>}
                        <th>Points</th>
                        <th>
                            <OverlayTrigger placement="top" overlay={PIPopover}>
                                <a>PI</a>
                            </OverlayTrigger>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {teams.map(team => {
                        return <tr key={team.id}>
                            <td>{team.rank}</td>
                            <td>{team.name}</td>
                            {this.props.course.scoringAverage && <td>{(team.score && team.score.scoreDisplay) ? team.score.scoreDisplay : "No grade"}</td>}
                            <td>{(team.score && team.score.total != undefined ? team.score.total + `pt${team.score.total > 1 ? 's' : ''}` : 'No pts')}</td>
                            <td>{(team.participationScore) ? team.participationScore + "%" : "N/A"}</td>
                            <td><a onClick={this._openTeamModal.bind(this, team)}>View</a></td>
                        </tr>
                    })}
                </tbody>
            </table>
            {showShowMore && <a onClick={() => this.setState({ shownNumberOfParticipants: state.shownNumberOfParticipants + 20 })}>Show more <i className="fa fa-caret-down" /></a>}
        </div>
    }
    _renderParticipantTable(participants) {
        const { props, state } = this
        const hasTeams = props.course.teams.length > 0
        let showShowMore = false

        if (participants.length > state.shownNumberOfParticipants) {
            participants = participants.splice(0, state.shownNumberOfParticipants)
            showShowMore = true
        }

        return <div>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th colSpan={2}>Name</th>
                        {hasTeams && <th>Team</th>}
                        <th>Rank</th>
                        {this.props.course.scoringAverage && <th>Grade</th>}
                        <th>Score</th>
                        <th>
                            <OverlayTrigger placement="top" overlay={PIPopover}>
                                <a>PI</a>
                            </OverlayTrigger>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {participants.map(participant => {
                        return <tr key={participant.id}>
                            <td width={40}>
                                <Avatar user={participant} size={30} />
                            </td>
                            <td>
                                {participant.userz.registered ? (
                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-reg-${participant.id}`}>IS registered</Tooltip>}>
                                        <i className="fa fa-check fa-fw green" style={{ marginRight: 3 }} />
                                    </OverlayTrigger>
                                ) : (
                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-reg-${participant.id}`}>NOT registered</Tooltip>}>
                                        <i className="fa fa-times fa-fw red" style={{ marginRight: 3 }} />
                                    </OverlayTrigger>
                                )}
                                {participant.displayName.withEmail}
                            </td>
                            {hasTeams && <td>{participant.teamName || 'None'}</td>}
                            <td>{participant.rank}</td>
                            {this.props.course.scoringAverage && <td>
                                {(participant.score && participant.score.scoreDisplay) ? participant.score.scoreDisplay : 'No grade'}
                            </td>}
                            <td>{participant.points ? `${participant.points}pt${participant.points > 1 ? 's' : ''}` : 'No pts'}</td>
                            <td>{(participant.participationScore) ? participant.participationScore.score + "%" : "N/A"}</td>
                            <td><a onClick={this._openParticipantModal.bind(this, participant)}>View</a></td>
                        </tr>
                    })}
                </tbody>
            </table>
            {showShowMore && <a onClick={() => this.setState({ shownNumberOfParticipants: state.shownNumberOfParticipants + 20 })}>Show more <i className="fa fa-caret-down" /></a>}
        </div>
    }

    _openParticipantModal(participant) {
        this.setState({ selectedParticipant: participant })
    }
    _closeParticipantModal() {
        this.setState({ selectedParticipant: null })
    }


    _openTeamModal(team) {
        this.setState({ selectedTeam: team })
    }
    _closeTeamModal() {
        this.setState({ selectedTeam: null })
    }

    _processParticipantParticipationScores(participants) {
        const { props } = this
        return participants.map(participant => {
            const mails = Messaging.Tools.getThreadsForParticipant(props.course, 'email', participant, false, props.course.activities)
            const chats = Messaging.Tools.getThreadsForParticipant(props.course, 'chat', participant, false, props.course.activities)
            let messagingActivities = [
                ...mails,
                ...chats
            ]
            participant.participationScore = Scoring.Tools.participation.participant(participant, props.course, messagingActivities, participant.readCount)
            return participant
        })
    }
    _processTeamParticipationScores(teams, participants) {
        return teams.map(team => {
            let teamParticipantScore = 0
            let teamParticipantScoreCount = 0
            participants
                .filter(participant => {
                    if (participant.teamId !== team.id) { return }
                    if (!participant.participationScore) {
                        participant = this._processParticipantParticipationScores([participant])[0]
                    }
                    teamParticipantScore += participant.participationScore.score
                    teamParticipantScoreCount++
                })
            team.participationScore = teamParticipantScoreCount > 0 ? Math.floor(teamParticipantScore / teamParticipantScoreCount) : null
            return team
        })
    }

    _loadParticipationScores() {
        this.setState({ isLoadingParticipationScores: true })

        this.props.actions.activityLog.fetchAllReadActivityForCourse(this.props.course.id, () => {
            this.setState({
                isLoadingParticipationScores: false,
                participantScoreDataLoaded: true,
                lastLoadedParticipationScore: moment()
            })
        })
    }

    _downloadCsv(e) {
        const { props, state } = this
        let csvData = ""
        let fileName = ""
        if (!state.showTeam) {
            fileName = "Participants-" + moment().format("YY-MM-DD-HH-mm")
            let participants = props.course.participants.filter(p => !p.isAdmin)
            participants = this._processParticipantParticipationScores(participants)
            let cols = ["Name", "Team", "Score", "Participation score"]
            participants = participants
                .map(participant => {
                    participant.score = participant.score ? participant.score : Scoring.Tools.participant(participant, props.course.activities, props.course.activityLogs, props.course.forms, props.course.scoringTags)
                    participant.points = !participant.score ? 0 : Math.round(participant.score.total)
                    return participant
                })
                .sort((a, b) => b.points - a.points)
                .map((participant, i) => {
                    participant.rank = i + 1
                    return participant
                })
                .map(participant => {
                    return {
                        name: participant.displayName.withEmail,
                        team: participant.teamName || "None",
                        grade: participant.score.scoreDisplay || "No grade",
                        score: participant.points || "No points",
                        participationScore: (participant.participationScore) ? participant.participationScore.score + "%" : "N/A"
                    }
                })


            csvData = csv.arrayOfObjectsToCsv(participants, cols)
        }
        if (state.showTeam) {
            fileName = "Teams-" + moment().format("YY-MM-DD-HH-mm")
            let cols = ["Team", "Rank", "Score"]
            const teams = props.course.teams
                .map(team => {
                    team.score = team.score ? team.score : Scoring.Tools.team(team.id, props.course.participants, props.course.activities, props.course.activityLogs, props.course.forms, props.course.scoringTags)
                    team.points = !team.score ? 0 : Math.round(team.score.total)
                    return team
                })
                .sort((a, b) => b.points - a.points)
                .map((team, i) => {
                    team.rank = i + 1
                    return team
                })
                .map(team => {
                    return {
                        name: team.name,
                        rank: team.rank,
                        grade: team.score.scoreDisplay,
                        score: team.points
                    }
                })
            csvData = csv.arrayOfObjectsToCsv(teams, cols)
        }

        let btn = e.target
        btn.setAttribute('href', csvData)
        btn.setAttribute('download', fileName.replace(' ', '') + '.csv')
    }
}
