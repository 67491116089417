import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Input } from 'alias-components'
import FormInputRadio from './FormInputRadio'
import FormInputRange from './FormInputRange'
import Config from 'alias-config'
import Badge from '../../Badge'
import ReduxBinder from 'alias-store/ReduxBinder'
import Scripts from 'alias-tools/scripts'


const scoringOptions = [
    { label: 'None', value: null },
    { label: '5 - High', value: 5 },
    { label: '4', value: 4 },
    { label: '3 - ok', value: 3 },
    { label: '2', value: 2 },
    { label: '1', value: 1 },
    { label: '0 - Lowest', value: 0 }
]


class FormQuestion extends Component {

    static propTypes = {
        question: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        number: PropTypes.any,
        course: PropTypes.object,
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            modalBadge: false
        }
    }

    render() {
        const { question, number, course } = this.props

        let typeDisplay = null
        const pqs = Config.app.questionTypes.filter(q => q.value === question.type)
        if (pqs.length > 0) {
            typeDisplay = pqs[0].label
        }
        const scoringTags = course.scoringTags.map(tag => {
            return {
                label: tag.name,
                value: tag.staticId
            }
        })

        return <Card>
            <div className="form-input">
                <h3>Question {number && `${number}`}
                    {typeDisplay && <small> Type: {typeDisplay}</small>}
                    <a className="pull-right red" onClick={() => this.props.onRemove(question.id)}><i className="fa fa-trash-o" /> Remove</a>
                </h3>

                <Input type="select"
                    label="Choose type of question"
                    placeholder="Select type of question"
                    name="type"
                    value={question.type}
                    onChange={this._onChange.bind(this)}
                    options={Config.app.questionTypes}
                />

                {question.type && <div>
                    <Input
                        type="textarea"
                        placeholder="Ex: What sales value did you get?"
                        label={`Question`}
                        name="question"
                        value={question.question}
                        onChange={this._onChange.bind(this)} />
                    <Input
                        type="textarea"
                        placeholder="Add some more information here (Optional)"
                        description="WARNING: Descriptions will not show up if the form is used in email and/or chats."
                        label={`Description`}
                        name="description"
                        value={question.description}
                        onChange={this._onChange.bind(this)}
                        minRows={2} />

                    <strong>Embed videos</strong>
                    {question.videos && question.videos.map((video, i) => {
                        return <div key={`video-${i}`} className="row">
                            <div className="col-xs-9">
                                <Input type="text"
                                    value={video}
                                    onChange={(name, value) => this._onChangeVideo(i, value)}
                                    placeholder="Enter a youtube or vimeo URL here..." />
                            </div>
                            <div className="col-xs-3">
                                <a onClick={this._removeEmbeddedVideo.bind(this, i)} className="btn btn-danger"><i className="fa fa-trash"></i></a>
                            </div>
                        </div>
                    })}
                    <p><a onClick={this._addEmbedVideo.bind(this)}>+ Add embedded video</a></p>
                    <br />

                    <div className="row">
                        <div className="col-md-8">
                            <Input type="select"
                                label="Show if team/participant has badge"
                                placeholder="Pick a badge (Optional)"
                                description="Badges allow you to create different paths for participants and teams."
                                value={question.badgeStaticId}
                                name="badgeStaticId"
                                options={course.badges.map(badge => {
                                    return {
                                        label: badge.name,
                                        value: badge.staticId
                                    }
                                })}
                                onChange={this._onChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                            <a onClick={() => this.setState({ modalBadge: true })} className="btn btn-link">Create new badge</a>
                        </div>
                    </div>



                    <Input type="select"
                        placeholder="Select scoring weight (*Optional)"
                        label={`Scoring weight`}
                        name="weight"
                        value={question.weight}
                        options={Config.app.weightOptions}
                        onChange={this._onChange.bind(this)} />

                    {['multiple-choice', 'scoring'].indexOf(question.type) === -1 && (
                        <Input type="select"
                            label="Score"
                            options={scoringOptions}
                            value={question.score}
                            name="score"
                            placeholder="Pick a score (Optional)"
                            onChange={this._onChange.bind(this)} />
                    )}

                    {question.type === 'multiple-choice' && <FormInputRadio {...this.props} />}
                    {question.type === 'range' && <FormInputRange {...this.props} />}

                    <Input
                        type="textarea"
                        placeholder="Ex: The right answer was..."
                        label={`Feedback for student (Optional)`}
                        name="feedback"
                        value={question.feedback}
                        onChange={this._onChange.bind(this)}
                        minRows={2} />

                    {question.type !== 'multiple-choice' && <div>
                        <p><strong>Tags</strong></p>
                        {question.scoringTags && question.scoringTags.map(tag => {
                            return <div key={tag.id}>
                                <div className="row">
                                    <div className="col-xs-3">
                                        <Input type="select"
                                            label="Value"
                                            options={scoringTags}
                                            value={tag.scoringTagStaticId}
                                            placeholder="Pick a scoring tag"
                                            onChange={(name, newValue) => {
                                                this._changeScoringTag(tag.id, 'scoringTagStaticId', newValue)
                                            }} />
                                    </div>
                                    <div className="col-xs-7">
                                        <Input type="select"
                                            label="Amount of change"
                                            options={scoringOptions}
                                            value={tag.amount}
                                            placeholder="1 to 5"
                                            onChange={(name, newValue) => {
                                                this._changeScoringTag(tag.id, 'amount', newValue)
                                            }} />
                                    </div>
                                    <label className="col-xs-2">
                                        <a onClick={() => this._removeValue(tag.id)} className="red" style={{ position: 'relative', top: 20 }}>Remove</a>
                                    </label>
                                </div>
                            </div>
                        })}
                        <a onClick={() => this._addScoringTag()}>+ Add a scoring tag</a>
                    </div>}

                </div>}

                <Badge.Modal
                    state={this.props.state}
                    actions={this.props.actions}
                    handleClose={(badge) => {
                        this.setState({ modalBadge: false })
                        let question = this.props.question
                        question['badgeStaticId'] = badge.staticId
                        this.props.onChange(question)
                    }}
                    badgeId="new"
                    isOpen={this.state.modalBadge} />
            </div>
        </Card>
    }

    _onChange(name, value) {
        let question = this.props.question
        question[name] = value
        this.props.onChange(question)
    }

    _addEmbedVideo() {
        let question = this.props.question
        if (!question.videos) {
            question.videos = []
        }
        question.videos.push("")
        this.props.onChange(question)
    }
    _removeEmbeddedVideo(videoIndex) {
        let question = this.props.question
        question.videos = question.videos.map((v, i) => {
            if (i === videoIndex) { return null }
            return v
        }).filter(v => v !== null)
        this.props.onChange(question)
    }
    _onChangeVideo(videoIndex, url) {
        let question = this.props.question
        question.videos = question.videos.map((v, i) => {
            if (i === videoIndex) { return url }
            return v
        })
        this.props.onChange(question)
    }

    _addScoringTag() {
        let { question } = this.props
        let scoringTags = question.scoringTags || []
        scoringTags.push({
            id: Scripts.randomString(),
            scoringTagStaticId: null,
            amount: 0
        })

        this._onChange("scoringTags", scoringTags)
    }
    _changeScoringTag(scoringTagListId, type, newScoringTag) {
        let { question } = this.props
        let scoringTags = question.scoringTags || []
        scoringTags = scoringTags.map(tag => {
            if (tag.id !== scoringTagListId) { return tag }
            tag[type] = newScoringTag
            return tag
        })

        this._onChange("scoringTags", scoringTags)
    }
    _removeScoringTag(scoringTagListId) {
        let { question } = this.props
        let scoringTags = question.scoringTags || []
        scoringTags = scoringTags.filter(val => val.id !== scoringTagListId)
        this._onChange("scoringTags", scoringTags)
    }
}





export default ReduxBinder(FormQuestion, {
    state: ['course']
})