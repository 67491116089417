import api, { apiUpload } from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'

const BASE_PATH = '/docs';
//
// Action types
//
const CLEAR = 'si/doc/CLEAR';
const SAVING = 'si/doc/SAVING';
const SAVED = 'si/doc/SAVED';
const REMOVE = 'si/doc/REMOVE';
const ERROR = 'si/doc/ERROR';

const initialState = {
    isSaving: false, // When it's getting data from the server (usually to show a loader)
    messages: {
        error: null, // String
        success: null // String
    },
    model: structure.doc
};

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case SAVED:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case REMOVE:
            return { ...initialState };
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isSaving: false
            }
        default:
            return state;
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return { type: CLEAR }
}

export function upload(model, onProgress, onComplete, onError) {
    return (dispatch, getState) => {

        var formData = new FormData();
        formData.append("file", model.file);
        delete model.id;
        apiUpload("/docs/" + model.courseId + '/upload/' + model.staticId, formData, function (progress) {
            if (onProgress) {
                onProgress(progress);
            }
        }, model)
            .then(function (response) {
                dispatch({ type: SAVED, model: response.data });
                if (onComplete) {
                    onComplete(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
                if (onError) {
                    onError(response.response);
                }
            });
    }
}

export function create(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        var state = getState();

        api('/courses/' + model.courseId + BASE_PATH, 'POST', model, state, {}, dispatch)
            .then(function (response) {
                dispatch({ type: SAVED, model: response.data });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function update(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        var state = getState();
        api(BASE_PATH + '/' + model.id, 'PATCH', model, state, {}, dispatch)
            .
            //
            then(function (response) {
                dispatch({ type: SAVED, model: response.data });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function remove(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model });
        // Add default data
        const state = getState();
        api('/courses/' + model.courseId + BASE_PATH + '/' + model.id, 'DELETE', model, state, {}, dispatch)
            .
            //
            then(function (response) {
                dispatch({ type: REMOVE, model: model });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            });
    }
}

export function liveAdd(model) {
    return { type: SAVED, model: model }
}
export function liveUpdate(model) {
    return { type: SAVED, model: model }
}
export function liveRemove(model) {
    return { type: REMOVE, model: model }
}