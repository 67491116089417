import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'

function initDate(date, timezone) {
    timezone = timezone || "Europe/London"
    date = date || moment()
        .tz(timezone)
        .format()

    return { date: date, timezone: timezone }
}

export default class DateTimeSetter extends Component {

    static propTypes = {
        date: PropTypes.any,
        timezone: PropTypes.string,
        onChange: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.state = initDate(this.props.date, this.props.timezone)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(initDate(newProps.date, newProps.timezone))
    }

    render() {

        let date = moment(this.state.date).tz(this.state.timezone).format()

        let hours = []
        for (let i = 0; i <= 24; i++) {
            hours.push(i)
        }
        let minutes = []
        for (let j = 0; j < 60; j++) {
            minutes.push(j)
        }

        return (
            <div>
                <DatePicker
                    inline
                    dateFormat="YYYY-MM-DD"
                    selected={moment(date.match(/\d{4}-\d{2}-\d{2}/)[0], "YYYY-MM-DD")}
                    onChange={this._onChangeStart.bind(this)}
                    placeholderText="Click to select a start date" />

                <p style={{ marginTop: '5px' }}>
                    <select
                        ref="hours"
                        value={date.match(/T(\d{2}):/)[1]}
                        onChange={this._onChangeTime.bind(this)}
                        className="form-control"
                        style={{
                            width: 'auto',
                            display: 'inline-block',
                            minWidth: '60px'
                        }}>
                        {hours.map(function (time) {
                            if (time < 10) {
                                time = "0" + time
                            }
                            return (
                                <option key={time} value={time}>{time}</option>
                            )
                        })}
                    </select>
                    :
                    <select
                        ref="minutes"
                        value={date.match(/:(\d{2}):/)[1]}
                        onChange={this._onChangeTime.bind(this)}
                        className="form-control"
                        style={{
                            width: 'auto',
                            display: 'inline-block',
                            minWidth: '60px'
                        }}>
                        {minutes
                            .map(function (time) {
                                if (time < 10) {
                                    time = "0" + time
                                }
                                return (
                                    <option key={time} value={time}>{time}</option>
                                )
                            })}
                    </select>
                </p>
            </div>
        )
    }

    _onChangeStart(start) {
        var startDate = this.state.date
        var date = start.format("YYYY-MM-DD")
        // const time = moment(course.start).format("HH:mm:ss"); course.start =
        // date+"T"+time+"+00:00";
        if (!startDate) {
            startDate = moment().tz(this.state.timezone).format().replace(/\d{4}-\d{2}-\d{2}/, date)
        } else {
            startDate = startDate.replace(/\d{4}-\d{2}-\d{2}/, date)
        }
        this.setState({ date: startDate })
        this.props.onChange(startDate)
    }

    _onChangeTime() {
        var date = this.state.date
        var hours = this.refs.hours.value
        var minutes = this.refs.minutes.value
        if (!date) {
            date = moment().tz(this.state.timezone).format()
        } else {
            date = moment(date).tz(this.state.timezone).format()
        }
        date = date.replace(/T\d{2}:\d{2}:\d{2}/, "T" + hours + ":" + minutes + ":00")
        date = moment(date).tz('Etc/GMT').format()
        this.setState({ date: date })
        this.props.onChange(date)
    }
}