
import CourseRun from './CourseRun'
import CourseConfiguration from './CourseConfiguration'
import InstructorMarketCourse from './InstructorMarketCourse'

import Profile from './Profile'
import InstructorCourses from './InstructorCourses'
import InstructorTemplates from './InstructorTemplates'
import InstructorMarket from './InstructorMarket'
import Courses from './Courses'
import CourseGame from './CourseGame'
import CoursePickRoles from './CoursePickRoles'
import Import from './Import'
import Form from './Form'
import Control from './Control'
import Builder from './Builder'
import Home from './Home'
import Picker from './CoursePick'
import Admin from './Admin'

export default {
    Home,
    Courses,
    CourseGame,
    CoursePickRoles,
    Picker,

    InstructorTemplates,
    InstructorCourses,
    CourseConfiguration,
    CourseRun,
    Builder,

    InstructorMarket,
    InstructorMarketCourse,

    Profile,
    Import,
    Form,
    Control,
    Admin
}
