import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Search, Onboarding, Loading, Grid } from 'alias-components'
import Course from './components/Course'
import ReduxBinder from 'alias-store/ReduxBinder'
import Helmet from 'react-helmet'
import Fuse from 'fuse.js'

const INITIAL_SHOW_COUNT = 6
const ADD_TO_SHOW_COUNT = 6

class PageHome extends Component {
    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            search: props.state.local.homeSearch,
            showFinishedCourses: false,
            showFinishedInstructorCourses: false,
            showCoursesCount: INITIAL_SHOW_COUNT,
            showInstructorsCount: INITIAL_SHOW_COUNT,
            showNewCourseModal: false,
            showJoinWithCodeModal: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.course.clearMessages()
    }

    render() {
        const { props, state } = this
        const userState = props.state.user
        const user = userState.model

        if (userState.isFetching || !user.id) {
            return <Loading />
        }

        let courses = user.participants
            .filter(participant => {
                if (!participant.course) { return false }
                if (participant.course.isTemplate) { return false }
                return true
            })
            .sort((a, b) => new Date(b.course.start) - new Date(a.course.start))


        const search = props.state.local.homeSearch
        if (search) {
            const options = {
                shouldSort: false,
                threshold: 0.3,
                minMatchCharLength: 1,
                keys: [
                    "course.name",
                    "course.description"
                ]
            }
            let fuse = new Fuse(courses, options)
            courses = fuse.search(search)
        }
        let courseList = []
        let myCourses = courses.filter((participant) => {
            if (participant.isAdmin) { return false }
            if (!state.showFinishedCourses && search === "") {
                if (participant.course.isFinished) {
                    return false
                }
            }
            // To remove duplicates in the left menu.
            if (courseList.indexOf(participant.course.id) !== -1) { return false }
            courseList.push(participant.course.id)
            return true
        })

        let instructorCourses = courses.filter((participant) => {
            if (!participant.isAdmin) { return false }
            if (!state.showFinishedInstructorCourses && search === "") {
                if (participant.course.isFinished) {
                    return false
                }
            }
            return true
        })

        let totalCourses = myCourses.length
        let totalInstructors = instructorCourses.length
        myCourses = myCourses.slice(0, state.showCoursesCount)
        instructorCourses = instructorCourses.slice(0, state.showInstructorsCount)

        return <Layout>
            <Helmet title="Home" />
            <div className="container-fluid">
                <div className="page-header">
                    <Search search={search}
                        onChange={(search) => this._search(search)} />
                </div>
                {search !== "" && <p><em>Showing search results for <strong>"{search}"</strong></em> <a onClick={this._search.bind(this, '')} style={{ marginLeft: 5 }}><i className="fa fa-times-circle" /> Clear search</a></p>}

                {instructorCourses.length > 0 && <div>
                    <h3>
                        <span className="logo-type">Instructor<span>cases</span></span> ({totalInstructors})
                        <small className="pull-right">
                            <a className="btn btn-primary"
                                style={{ position: 'relative', top: -4 }}
                                onClick={() => this.setState({ showNewCourseModal: true })}>+ New case</a>
                        </small>
                    </h3>
                    <div className="clear"></div>
                    {instructorCourses.length === 0 && <p className="help-block">None</p>}
                    {instructorCourses.length > 0 && <Grid>
                        {instructorCourses.map(p => <Course.Card course={p.course} isInstructor={true} key={`instructor-${p.course.id}`} />)}
                    </Grid>}

                    {instructorCourses.length < totalInstructors && (
                        <a className="btn btn-secondary"
                            style={{ marginRight: 10 }}
                            onClick={() => this.setState({ showInstructorsCount: state.showInstructorsCount + ADD_TO_SHOW_COUNT })}>
                            <i className="fa fa-chevron-down" /> Show more
                        </a>
                    )}

                    {instructorCourses.length > INITIAL_SHOW_COUNT && (
                        <a className="btn btn-secondary"
                            style={{ marginRight: 10 }}
                            onClick={() => this.setState({ showInstructorsCount: state.showInstructorsCount - ADD_TO_SHOW_COUNT })}>
                            <i className="fa fa-chevron-up" /> Show less
                        </a>
                    )}


                    {search === "" && <a onClick={() => this.setState({ showFinishedInstructorCourses: !state.showFinishedInstructorCourses })} className="btn btn-link">{state.showFinishedInstructorCourses ? 'Hide finished' : 'Show finished'}</a>}
                    <br />
                    <br />
                    <h3>
                        <span className="logo-type">Participant<span>cases</span></span> ({totalCourses})
                        <a onClick={() => this.setState({ showJoinWithCodeModal: true })} className="btn btn-primary pull-right" style={{ position: 'relative', top: -4 }}>Join case with code</a>
                    </h3>
                    <div className="clear"></div>
                </div>}

                {myCourses.length > 0 && <div>
                    {(instructorCourses.length === 0 && search === "") && <div>
                        <a onClick={() => this.setState({ showJoinWithCodeModal: true })} className="btn btn-primary pull-right">Join case with code</a>
                        <div className="clear"></div>
                    </div>}
                    <Grid>
                        {myCourses.map(p => <Course.Card course={p.course} isCourse={true} key={`instructor-${p.course.id}`} />)}
                    </Grid>
                    {myCourses.length < totalCourses && (
                        <a
                            onClick={() => this.setState({ showCoursesCount: state.showCoursesCount + ADD_TO_SHOW_COUNT })}
                            className="btn btn-secondary"
                            style={{ marginRight: 10 }}>
                            <i className="fa fa-chevron-down" /> Show more
                        </a>
                    )}
                    {myCourses.length > INITIAL_SHOW_COUNT && (
                        <a
                            onClick={() => this.setState({ showCoursesCount: state.showCoursesCount - ADD_TO_SHOW_COUNT })}
                            className="btn btn-secondary"
                            style={{ marginRight: 10 }}>
                            <i className="fa fa-chevron-up" /> Show less
                        </a>
                    )}

                    {search === "" && <a onClick={() => this.setState({ showFinishedCourses: !state.showFinishedCourses })} className="btn btn-link">{state.showFinishedCourses ? 'Hide finished' : 'Show finished'}</a>}
                </div>}

                {((myCourses.length === 0 && search === "")) && <div>
                    <p className="alert alert-info">You are currently not participating in any courses. If you are supposed to, please contact your instructor to be added to a case.</p>
                </div>}
                {(instructorCourses.length === 0 && search === '') && <div>
                    <br />
                    <br />
                    <Onboarding title="Are you an instructor?">
                        <div>
                            <p>You can create your own cases on the platform here: <a onClick={() => this.setState({ showNewCourseModal: true })}>Create a new case</a></p>
                        </div>
                    </Onboarding>
                </div>}
                <br />
                <br />

                <Course.Modal
                    isShown={state.showNewCourseModal}
                    close={() => this.setState({ showNewCourseModal: false })}
                    state={props.state}
                    actions={props.actions} />

                <Course.ModalJoinInvite
                    isShown={state.showJoinWithCodeModal}
                    onClose={() => this.setState({ showJoinWithCodeModal: false })}
                    user={user} />

            </div>
        </Layout>

    }

    _search(search) {
        this.props.actions.local.save({ homeSearch: search })
    }
}

export default ReduxBinder(PageHome, {
    state: ['user', 'course', 'local']
})
