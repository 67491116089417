import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Fuse from 'fuse.js'
import { Search } from 'alias-components'
import Organization from '../../components/Organization'
import ReduxBinder from 'alias-store/ReduxBinder'

class CourseConfigurationTabOrganizations extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: ''
        }
    }



    render() {
        const course = this.props.state.course.model
        const { organizationId } = this.props.match.params

        let organizations = course.organizations

        if (this.state.search !== '') {
            let options = {
                keys: [{
                    name: 'name',
                    weight: .4
                }, {
                    name: 'description',
                    weight: 0.2
                }, {
                    name: 'country',
                    weight: 0.2
                }, {
                    name: 'industry',
                    weight: 0.2
                }],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.4
            }
            let fuse = new Fuse(organizations, options)
            organizations = fuse.search(this.state.search)
        }

        return (
            <div>
                <Helmet title={course.name + " organizations"} />
                <div className="page-header">
                    <Search search={this.state.search} onChange={(search) => {
                        this.setState({
                            search: search
                        })
                    }} />
                    <a onClick={this._openModal.bind(this, null)} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add organization</a>
                </div>
                {this.state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}>
                        <i className="fa fa-info" style={{ marginRight: '5px' }} /> Organizations can be anything that a person could belong to. Such as a company, an NGO, a community, ...
                    </p>
                )}
                {this.state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{this.state.search}</strong></em> <a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a></p>
                )}
                {organizations.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No organizations.</strong>
                        {this.state.search === '' && (
                            <p>Click the "Add organization" button on top right.</p>
                        )}
                    </div>
                )}
                {organizations.map(organization => {
                    return (
                        <Organization.Card key={organization.id}
                            organization={organization}
                            edit={this._openModal.bind(this, organization.id)}>
                            <p className="help-block">
                                Characters: {course.characters.filter(char => char.organizationStaticId == organization.staticId).length}
                            </p>
                        </Organization.Card>
                    )
                })}

                <Organization.Modal isOpen={organizationId !== undefined}
                    handleClose={this._closeModal.bind(this)}
                    organizationId={organizationId}
                    openModal={this._openModal.bind(this)}
                    state={this.props.state}
                    actions={this.props.actions} />
            </div>
        )
    }

    _openModal(organizationId) {
        const course = this.props.state.course.model
        this.props.history.push(`/course/configuration/${course.id}/organizations/${organizationId === null ? 'new' : organizationId}`)
    }

    _closeModal() {
        const course = this.props.state.course.model
        this.props.history.push(`/course/configuration/${course.id}/organizations`)
    }

}

export default ReduxBinder(CourseConfigurationTabOrganizations, {
    state: ['course', 'organization']
})