import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import ScoringModal from './ScoringModal'
import ScoringCard from './ScoringCard'

export default class Configuration extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            search: ''
        }
    }

    render() {
        const course = this.props.state.course.model;
        const { scoringTagId } = this.props.match.params;

        let scoringTags = course.scoringTags;

        if (this.state.search !== '') {
            let search = this.state.search.toLowerCase();
            scoringTags = scoringTags.filter((tag) => {
                return tag.name.toLowerCase().indexOf(search) !== -1;
            })
        }

        scoringTags = scoringTags.map(scoringTag => {
            scoringTag.questionsWithScoringTagCount = 0;
            scoringTag.forms = course.forms.filter(form => {
                let addForm = false;
                form.questions.map(question => {
                    if (question.scoringTagStaticId === scoringTag.staticId) {
                        addForm = true;
                        scoringTag.questionsWithScoringTagCount++;
                    }
                })
                return addForm;
            })
            return scoringTag;
        });

        return (
            <div>
                <Helmet title={course.name + " variables"} />
                <div className="page-header">
                    <Search search={this.state.search}
                        onChange={(search) => {
                            this.setState({
                                search: search
                            })
                        }} />
                    <a onClick={this._openModal.bind(this, 'new')} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add tag</a>
                </div>

                {this.state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Use scoring tags to grade participants on different skills/qualities. For example, communication, negotiation, teamwork,...</p>
                )}

                {this.state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{this.state.search}</strong></em><a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a></p>
                )}

                {scoringTags.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No scoring tags</strong>
                        {this.state.search === '' && (
                            <p>Click the "Add" button on the top right.</p>
                        )}
                    </div>
                )}

                {scoringTags.map(scoringTag => {
                    return <ScoringCard scoringTag={scoringTag}
                        onEditClick={this._openModal.bind(this, scoringTag.id)}
                        key={scoringTag.id}>
                        <p className="help-block">
                            <i className="fa fa-info" /> Forms: {scoringTag.forms.length} - Questions: {scoringTag.questionsWithScoringTagCount}
                        </p>
                    </ScoringCard>
                })}



            </div>
        );
    }

    _openModal(scoringTagId) {
        this.props.actions.local.modal('tag', scoringTagId)
        // const course = this.props.state.course.model;
        // this.props.history.push(`/course/configuration/${course.id}/scoring/${scoringTagId === null ? 'new' : scoringTagId}`)
    }

}
