import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Route, Switch } from 'react-router-dom'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'
import BuilderTabs from './builderTabs'

class PageBuilder extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId

        // Connecting server live updates
        this._liveUpdateListen(courseId)

        this.state = {
            courseId: this.props.match.params.courseId
        }
    }

    UNSAFE_componentWillMount() {
        const course = this.props.state.user.model.participants.filter(item => {
            return item.course && item.course.id === this.props.match.params.courseId && item.isAdmin
        })
        const participants = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.match.params.courseId && participant.isAdmin
        })
        if (course.length > 0 && participants.length > 0) {
            if (this.isFetching !== true) {
                this.isFetching = true
                this.props.actions.course.load(this.props.match.params.courseId, () => {
                    this.isFetching = false
                }, false, participants[0].id)
            }
        } else {
            this.props.history.push('/')
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const participants = newProps.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === newProps.match.params.courseId && participant.isAdmin
        })
        if (this.state.courseId !== newProps.match.params.courseId && participants.length > 0) {
            this.setState({ courseId: newProps.match.params.courseId })
            newProps.actions.course.load(newProps.match.params.courseId, () => { }, false, participants[0].id)

            this._liveUpdateListen(newProps.match.params.courseId)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions),
            () => {
                if (participants.length > 0) {
                    props.actions.course.load(courseId, null, true, participants[0].id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    render() {
        const { props } = this
        if (!props.state || !props.actions) {
            return <Loading />
        }
        const courseState = props.state.course
        const course = courseState.model

        if (courseState.isFetching && !courseState.isLoaded) {
            return <Loading />
        }
        const basePath = `/builder/${course.id}`
        // "/builder/5aa627409317a6715ab35a32/form/jeqsxgky"
        return <div className='course-wrapper'>
            <Helmet title={course.name} />
            <Switch>
                <Route path={`${basePath}/form/:formStaticId?/:questionId?`} component={BuilderTabs.Form} />
                <Route path={`${basePath}/story`} component={BuilderTabs.Story} />
                <Route path="*" component={BuilderTabs.Pick} />
            </Switch>
        </div>

    }
}

export default ReduxBinder(PageBuilder, {
    state: ['course', 'user']
})