import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AtomicBlockUtils, CompositeDecorator, Editor, EditorState, Entity, RichUtils, convertToRaw, convertFromRaw } from 'draft-js'


const Video = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0))
    const { src } = entity.getData()
    const type = entity.getType()

    if (type === 'youtube' || type === 'vimeo') {
        return (
            <div className="videoWrapper">
                <iframe src={src}
                    frameBorder="0"
                    allowFullScreen></iframe>
            </div>
        )
    }
}

function videoBlockRenderer(block) {
    if (block.getType() === 'atomic') {
        return {
            component: Video,
            editable: false
        }
    }

    return null
}

const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
        <a href={url} target="_blank">
            {props.children}
        </a>
    )
}

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity()
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            )
        },
        callback
    )
}

function setState(props) {
    let decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link
        }
    ])
    return {
        tag: props.tag,
        editorState: (props.text !== null && typeof props.text === 'object' && Object.keys(props.text).length > 0) ? EditorState.createWithContent(convertFromRaw(props.text), decorator) : EditorState.createEmpty(decorator)
    }
}

export default class EditorComponent extends Component {

    static propTypes = {
        tag: PropTypes.any,
        text: PropTypes.any,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onSave: PropTypes.func,
        readOnly: PropTypes.bool
    }


    static defaultProps = {
        text: null,
        placeholder: '',
        btnText: 'Save',
        isLoading: false,
        readOnly: false,
        onChange: function () {
            console.log("You need to define a prop onSave") // eslint-disable-line
        }
    }

    constructor(props) {
        super(props)
        this.state = setState(this.props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.state.tag || (this.state.tag && newProps.tag !== this.state.tag)) {
            this.setState(setState(newProps))
        }
    }

    render() {

        return (
            <div className={this.props.readOnly ? `lms lms-read` : `lms`}>
                {!this.props.readOnly && (
                    <div className="style-wrapper">
                        <a onClick={this._toggleBlockType.bind(this, 'header-one')} className="style">H1</a>
                        <a onClick={this._toggleBlockType.bind(this, 'header-two')} className="style">H2</a>
                        <a onClick={this._toggleBlockType.bind(this, 'header-three')} className="style">H3</a> |
                        <a onClick={this._toggleBlockType.bind(this, 'unordered-list-item')} className="style"><i className="fa fa-list-ul" /></a>
                        <a onClick={this._toggleBlockType.bind(this, 'ordered-list-item')} className="style"><i className="fa fa-list-ol" /></a>
                        |
                        <a onClick={this._toggleInlineStyle.bind(this, 'BOLD')} className="style"><i className="fa fa-bold" /></a>
                        <a onClick={this._toggleInlineStyle.bind(this, 'ITALIC')} className="style"><i className="fa fa-italic" /></a>
                        <a onClick={this._toggleInlineStyle.bind(this, 'UNDERLINE')} className="style"><i className="fa fa-underline" /></a>
                        |
                        <a onMouseDown={this._link.bind(this)} className="style"><i className="fa fa-link" /></a>
                        <a onMouseDown={this._removeLink.bind(this)} className="style"><i className="fa fa-unlink" /></a>
                        |
                        <a onMouseDown={this._video.bind(this)} className="style"><i className="fa fa-video-camera" /></a>
                    </div>
                )}
                <Editor blockRendererFn={videoBlockRenderer}
                    onChange={this._onChange.bind(this)}
                    readOnly={this.props.readOnly}
                    spellCheck={true}
                    handleKeyCommand={this._handleKeyCommand.bind(this)}
                    placeholder={this.props.placeholder}
                    editorState={this.state.editorState} />
            </div>
        )
    }

    _video(e) {
        e.preventDefault()
        const { editorState } = this.state

        let urlType = ''
        let urlValue = window.prompt("Paste Youtube OR Vimeo URL here")
        if (!urlValue) {
            return
        }
        if (urlValue.indexOf('youtube') !== -1) {
            urlType = 'youtube'
            urlValue = urlValue.replace('watch?v=', 'embed/')
        }
        if (urlValue.indexOf('vimeo') !== -1) {
            urlType = 'vimeo'
            urlValue = urlValue.replace('https://vimeo.com/', 'https://player.vimeo.com/video/') + "?title=0&byline=0&portrait=0&badge=0"
        }

        const entityKey = Entity.create(urlType, 'IMMUTABLE', {
            src: urlValue
        })
        this._onChange(AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        ))
    }

    _link(e) {
        e.preventDefault()
        const editorState = this.state.editorState
        const selection = editorState.getSelection()
        if (selection.isCollapsed()) {
            return
        }

        const href = window.prompt('Enter a URL')
        if (!href) {
            return
        }
        const entityKey = Entity.create('LINK', 'MUTABLE', {
            url: href
        })
        this._onChange(RichUtils.toggleLink(
            editorState,
            editorState.getSelection(),
            entityKey
        ))
    }

    _removeLink(e) {
        e.preventDefault()
        const editorState = this.state.editorState
        const selection = editorState.getSelection()
        if (!selection.isCollapsed()) {
            this._onChange(RichUtils.toggleLink(editorState, selection, null))
        }
    }

    _handleKeyCommand(command) {
        const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
        if (newState) {
            this._onChange(newState)
            return 'handled'
        }
        return 'not-handled'
    }

    _toggleBlockType(blockType) {
        this._onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        )
    }

    _toggleInlineStyle(inlineStyle) {
        this._onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        )
    }

    _onChange(editorState) {
        this.setState({
            editorState: editorState
        })
        this.props.onChange(convertToRaw(editorState.getCurrentContent()))
    }
}

// To hide the error message.
// console.error = (function () {
//     var error = console.error
//
//     return function (exception) {
//         if ((exception + '').indexOf('Warning: A component is `contentEditable`') != 0) {
//             error.apply(console, arguments)
//         }
//     }
// })()