import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CircularProgress extends Component {
    static propTypes = {
        size: PropTypes.number,
        strokeWidth: PropTypes.number,
        percentage: PropTypes.number,
        value: PropTypes.number,
        max: PropTypes.number
    }

    static defaultProps = {
        size: 100,
        strokeWidth: 5
    }

    _getPercentage() {
        const { percentage, value, max } = this.props
        if (percentage) {
            return {
                type: 'percentage',
                percentage: percentage
            }
        }
        if (value !== undefined && max) {
            return {
                type: 'value',
                value: value,
                max: max,
                percentage: (value / max) * 100
            }
        }
        return {
            type: 'none',
            percentage: 0
        }
    }

    render() {
        const { size, strokeWidth } = this.props
        const values = this._getPercentage()
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (this.props.size - strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${size} ${size}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * values.percentage / 100;

        return (
            <svg
                width={size}
                height={size}
                viewBox={viewBox}
                className="circular-progress">
                <circle
                    className="circle-background"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`} />
                <circle
                    className="circle-progress"
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />
                <text
                    className="circle-text"
                    x="50%"
                    y="50%"
                    dy=".3em"
                    textAnchor="middle"
                    style={{ lineHeight: size, fontSize: size / 4 }}>
                    {values.type === 'percentage' && `${values.percentage}%`}
                    {values.type === 'value' && `${values.value}/${values.max}`}
                    {values.type === 'none' && `None`}
                </text>
            </svg>
        );
    }
}