import React, { Component } from 'react'
import PropTypes from 'prop-types'
import storage from 'alias-tools/storage'
import { Route, Switch, Redirect } from 'react-router-dom'
import localAuthentication from 'alias-tools/localAuthentication'

import ReduxBinder from 'alias-store/ReduxBinder'
import * as PublicPages from './pages'
import logoImg from 'alias-assets/logo.png'

class PublicWrapper extends Component {

    static propTypes = {
        route: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            hasLocalStorage: storage.hasLocalStorage()
        }
    }

    render() {
        const { location } = this.props
        if (localAuthentication.isAuthenticated() && location.pathname.indexOf('/auth/invite') === -1) {
            return <Redirect to={{
                pathname: (location.state && location.state.from) ? location.state.from : '/'
            }} />
        }

        return (
            <div className="authentication-wrapper">
                <div className="panel">
                    <div className="logo">
                        <img src={logoImg} alt="Live CASE" />
                    </div>
                    {!this.state.hasLocalStorage && (
                        <div className="alert alert-danger">
                            Your browser is either in private mode or doesn't support the storage we need. You won't be able to login or register.
                            <br /> Please exit private mode or change browser.
                        </div>
                    )}
                    <div className="panel-body">
                        <Switch>
                            <Route exact path="/auth" component={PublicPages.Login} />
                            <Route path="/auth/login/:email?/:password?" component={PublicPages.Login} />
                            <Route path="/auth/forgotten/:email?" component={PublicPages.Forgotten} />
                            <Route path="/auth/register/:email?/:password?" component={PublicPages.Register} />
                            <Route path="/auth/reset/:token" component={PublicPages.Reset} />
                            <Route path="/auth/confirm/:token" component={PublicPages.EmailConfirm} />
                            <Route path="/auth/invite/:courseId/:inviteCode?/:email?" component={PublicPages.Invite} />
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}


export default ReduxBinder(PublicWrapper, { state: ['auth', 'user'] })