import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Popover, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Fuse from 'fuse.js'
import { Search, Input, Card, Avatar } from 'alias-components'
import Participant from '../components/Participant'
import Team from '../components/Team'
import ReduxBinder from 'alias-store/ReduxBinder'

const MAX_NUMBER_OF_PARTICIPANTS = 150
const DEFAULT_SHOWN_PARTICIPANTS = 15
class CourseRunTabParticipants extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        location: PropTypes.object
    }

    static defaultProps = {
    }

    constructor(props) {
        super(props)
        this.state = {
            isParticipantModalOpen: false,
            participant: null,
            importModalIsOpen: false,
            search: '',
            showNumberOfParticipants: DEFAULT_SHOWN_PARTICIPANTS,
            isTeamModalOpen: false,
            selectedTeam: null
        }
    }

    render() {
        const { props, state } = this
        const { isParticipantModalOpen, importModalIsOpen, showNumberOfParticipants, participant } = state

        const course = props.state.course.model
        let participants = course.participants.filter(function (participant) {
            return !participant.isAdmin
        })
        const totalParticipants = participants.length
        const hasTeams = (course.teams && course.teams.length > 0)
        // const hasRoles = (course.roles && course.roles.length > 0)
        const numberOfRegisteredParticipants = participants.filter(p => p.userz && p.userz.registered).length
        const numberOfNonAssigns = participants
            .filter(participant => {
                if (hasTeams) {
                    return participant.teamId && participant.roles.length > 0
                }
                return participant.roles.length > 0

            }).length

        let participantsWithNoTeams = []
        if (hasTeams) {
            participantsWithNoTeams = participants.filter(p => !p.teamId || (p.teamId && !course.byId.teams[p.teamId]))
        }


        if (state.search) {
            if (['no:role', 'no:team', 'no:register', 'no:assign'].indexOf(state.search) !== -1) {
                participants = participants.filter((participant) => {
                    if (state.search === 'no:role') {
                        return participant.roles.length === 0
                    }
                    if (state.search === 'no:team') {
                        return !participant.teamId
                    }
                    if (state.search === 'no:register') {
                        return participant.userz && !participant.userz.registered
                    }
                    if (state.search === 'no:assign') {
                        if (hasTeams) {
                            return !participant.teamId || participant.roles.length === 0
                        }
                        return participant.roles.length === 0

                    }
                })
            } else {
                const options = {
                    keys: [{
                        name: 'userz.first',
                        weight: 0.3
                    }, {
                        name: 'userz.last',
                        weight: 0.3
                    }, {
                        name: 'userz.email',
                        weight: 0.3
                    }, {
                        name: 'teamName',
                        weight: 0.2
                    }, {
                        name: 'roleNames',
                        weight: 0.2
                    }],
                    minMatchCharLength: 1,
                    shouldSort: false,
                    threshold: 0.2
                }
                let fuse = new Fuse(participants, options)
                participants = fuse.search(state.search)
            }
        }

        let teams = []
        let showShowMore = false
        if (hasTeams) {
            let participantCounter = participantsWithNoTeams.length
            let teamCounter = 0
            teams = course.teams.map(team => {
                team.participants = participants.filter(p => p.teamId === team.id)
                participantCounter += team.participants.length
                if (participantCounter > state.showNumberOfParticipants && teamCounter > 0) {
                    showShowMore = true
                    return null
                } else {
                    teamCounter++
                }
                return team
            }).filter(t => t)
        } else {
            if (participants.length > state.showNumberOfParticipants) {
                participants = participants.slice(0, showNumberOfParticipants)
                showShowMore = true
            }
        }

        return <div className="config-right-wrapper">
            <Helmet title={course.name + " course participants"} />
            <div className="page-header">
                <Search search={state.search}
                    onChange={(search) => this.setState({ search: search })} />
                <div className="pull-right">
                    <a onClick={this._openTeamModal.bind(this, null)} className="btn btn-secondary margin-right-5">Create a team</a>

                    <button
                        onClick={() => this.setState({ isParticipantModalOpen: true, participant: null })}
                        className="btn btn-primary"
                        disabled={totalParticipants > MAX_NUMBER_OF_PARTICIPANTS}>+ Add participant</button>

                </div>
            </div>

            <Participant.Modal
                course={course}
                isAdmin={false}
                isOpen={isParticipantModalOpen}
                handleClose={this._closeModal.bind(this)}
                participant={participant}
                state={props.state}
                actions={props.actions} />

            <Participant.ModalImport
                isOpen={importModalIsOpen}
                handleClose={() => this.setState({ importModalIsOpen: false })}
                state={props.state}
                actions={props.actions} />

            <Team.Modal
                isOpen={state.isTeamModalOpen}
                courseId={course.id}
                handleClose={this._closeTeamModal.bind(this)}
                onCreate={this._openTeamModal.bind(this)}
                team={state.selectedTeam}
                state={props.state}
                actions={props.actions} />

            {(state.search === '') && <div>
                <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Add and manage all your case participants here</p>
                {(totalParticipants > MAX_NUMBER_OF_PARTICIPANTS * 0.8 && totalParticipants < MAX_NUMBER_OF_PARTICIPANTS) && <div className="alert alert-warning">
                    <p>Please note that you may only have a maximum of {MAX_NUMBER_OF_PARTICIPANTS} participants per course. If you need more than {MAX_NUMBER_OF_PARTICIPANTS} participants, we advise to you to copy the case and launch 2 simulatenous courses to provide a better experience to your participants.</p>
                </div>}

                {totalParticipants > MAX_NUMBER_OF_PARTICIPANTS && <div className="alert alert-warning">
                    <strong><i className="fa fa-bullhorn" /> Max {MAX_NUMBER_OF_PARTICIPANTS} participants</strong>
                    <p>You may only have a maximum of {MAX_NUMBER_OF_PARTICIPANTS} participants per course. If you need more than {MAX_NUMBER_OF_PARTICIPANTS} participants, we advise to you to copy the course and launch 2 simulatenous courses to provide a better experience to your participants.</p>
                </div>}

                <div className="row">
                    <div className="col-xs-12 col-sm-4">
                        <Card title="# of participants" isCentered>
                            <h1>{totalParticipants}</h1>
                            <OverlayTrigger
                                placement="bottom"
                                id="upload"
                                overlay={<Popover
                                    id="upload-pop"
                                    title="Upload from CSV">Prepare your list of participants in excel and then upload it here in .csv.</Popover>}>
                                <a onClick={() => this.setState({ importModalIsOpen: true })}>Import</a>
                            </OverlayTrigger>

                            {participants.length > 0 && `  |  `}
                            {(participants.length > 0) && <OverlayTrigger placement="bottom" id="delete-all-participants" overlay={<Popover id="delete-all-participants-pop" title="Delete all participants">Clicking this will remove all the participants from the course.</Popover>}>
                                <a className="red hidden-xs" onClick={this._removeAllParticipants.bind(this)}>Remove all</a>
                            </OverlayTrigger>}
                        </Card>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <Card title="Have registered" isCentered>
                            <h1>{totalParticipants > 0 ? Math.round(numberOfRegisteredParticipants / totalParticipants * 100) : 0}% ({numberOfRegisteredParticipants})</h1>
                            <OverlayTrigger placement="bottom" id="view-registered" overlay={<Popover id="view-registered-pop" title="Not registered">View all participants that are NOT registered.</Popover>}>
                                <a onClick={() => this.setState({ search: 'no:register' })}>View</a>
                            </OverlayTrigger>
                            {`  |  `}
                            <OverlayTrigger placement="bottom" id="invite-registered" overlay={<Popover id="invite-registered-pop" title="Not registered">Sends an invitation email to participants who have NOT registered.</Popover>}>
                                <a onClick={this._inviteUnregistered.bind(this)}>Invite</a>
                            </OverlayTrigger>
                        </Card>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <Card title={`Have ${hasTeams ? 'Teams/' : ''}Roles`} isCentered>
                            <h1>{totalParticipants > 0 ? Math.round(numberOfNonAssigns / totalParticipants * 100) : 0}% ({numberOfNonAssigns})</h1>
                            <OverlayTrigger placement="bottom" id="view-no-assign" overlay={<Popover id="view-no-assign-pop" title="Not assigned team/or role">View all participants that have NO roles{hasTeams ? ' and/or NO teams' : ' '}.</Popover>}>
                                <a onClick={() => this.setState({ search: 'no:assign' })}>View</a>
                            </OverlayTrigger>
                        </Card>
                    </div>
                </div>
                <Card title="Invitation code/link">
                    <Participant.InviteWidget course={course} />
                </Card>
            </div>}


            {state.search !== '' && (
                <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{state.search}</strong></em><a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}><i className="fa fa-times-circle margin-right-5" /> Clear search</a></p>
            )}

            {participants.length === 0 && (
                <div className="alert alert-info">
                    <strong>No participants</strong>
                </div>
            )}
            {(!hasTeams && participants.length > 0) && <div>
                <h4>Participants</h4>
                <table className="table table-hover table-striped">
                    <tbody>
                        {participants.map(participant => {
                            return <ParticipantCard2 key={participant.id}
                                course={course}
                                actions={props.actions}
                                participant={participant}
                                participantEdit={this._participantEdit.bind(this)}
                                participantRemove={this._participantRemove.bind(this)}
                                participantInvite={this._participantInvite.bind(this)} />
                        })}
                    </tbody>
                </table>
            </div>}

            {hasTeams && <div>
                {participantsWithNoTeams.length > 0 && <div>
                    <h4>Participants with no teams ({participantsWithNoTeams.length})</h4>
                    <Card padding={false}>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {participantsWithNoTeams.map(participant => {
                                    return <ParticipantCard2 key={participant.id}
                                        course={course}
                                        actions={props.actions}
                                        participant={participant}
                                        participantEdit={this._participantEdit.bind(this)}
                                        participantRemove={this._participantRemove.bind(this)}
                                        participantInvite={this._participantInvite.bind(this)} />
                                })}
                            </tbody>
                        </table>
                    </Card>
                </div>}

                {teams.map(team => {
                    if (state.search && team.participants.length === 0) { return null }
                    return <TeamSection
                        key={team.id}
                        team={team}
                        course={course}
                        actions={props.actions}
                        participant={participant}
                        openTeamModal={this._openTeamModal.bind(this)}
                        participantEdit={this._participantEdit.bind(this)}
                        participantRemove={this._participantRemove.bind(this)}
                        participantInvite={this._participantInvite.bind(this)} />
                })}

                {showShowMore && (
                    <div>
                        <a onClick={() => this.setState({ showNumberOfParticipants: showNumberOfParticipants + DEFAULT_SHOWN_PARTICIPANTS })}
                            className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
                    </div>
                )}

            </div>}

            {false && <div>
                <h1>OLD</h1>

                {hasTeams && <div>
                    {participantsWithNoTeams.length > 0 && <div>
                        <h3>Participants with no teams ({participantsWithNoTeams.length})</h3>
                        {participantsWithNoTeams.map(participant => {
                            return <ParticipantCard key={participant.id}
                                course={course}
                                actions={props.actions}
                                participant={participant}
                                participantEdit={this._participantEdit.bind(this)}
                                participantRemove={this._participantRemove.bind(this)}
                                participantInvite={this._participantInvite.bind(this)} />
                        })}
                    </div>}
                    {teams.map(team => {
                        // let teamParticipants = participants.filter(p => p.teamId === team.id)
                        if (state.search && team.participants.length === 0) { return null }
                        return <div key={team.id}>
                            <h3>{team.name} ({team.participants.length}) <small><a onClick={this._openTeamModal.bind(this, team)}>Edit</a></small></h3>
                            {team.participants.length === 0 && <div className="alert alert-info">No participants in this team</div>}
                            {team.participants.map(participant => {
                                return <ParticipantCard key={participant.id}
                                    course={course}
                                    actions={props.actions}
                                    participant={participant}
                                    participantEdit={this._participantEdit.bind(this)}
                                    participantRemove={this._participantRemove.bind(this)}
                                    participantInvite={this._participantInvite.bind(this)} />
                            })}
                        </div>
                    })}
                    {showShowMore && (
                        <div>
                            <a onClick={() => this.setState({ showNumberOfParticipants: showNumberOfParticipants + DEFAULT_SHOWN_PARTICIPANTS })}
                                className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
                        </div>
                    )}
                </div>}

                {!hasTeams && <div>
                    {state.search === "" && <h3>Participants</h3>}
                    {participants.map(participant => {
                        return <ParticipantCard key={participant.id}
                            course={course}
                            actions={props.actions}
                            participant={participant}
                            participantEdit={this._participantEdit.bind(this)}
                            participantRemove={this._participantRemove.bind(this)}
                            participantInvite={this._participantInvite.bind(this)} />

                    })}

                    {showShowMore && (
                        <div>
                            <a onClick={() => this.setState({ showNumberOfParticipants: showNumberOfParticipants + DEFAULT_SHOWN_PARTICIPANTS })}
                                className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
                        </div>
                    )}
                </div>}
            </div>}
            <br />
            <br />
        </div>

    }

    _participantEdit(participant) {
        this.setState({
            isParticipantModalOpen: true,
            participant: participant
        })
    }

    _participantRemove(participant) {
        if (confirm("Are you sure you want to remove " + participant.displayName.withEmail + " from the case?")) {
            this.props.actions.participant.remove(participant, () => {
            })
        }
    }

    _inviteUnregistered() {
        let participants = this.props.state.course.model.participants
            .filter(p => {
                if (p.isAdmin) { return false }
                if (!p.userz) { return false }
                if (p.userz.registered) { return false }
                return true
            })
            .map(p => p.userz.email)
        if (participants.length === 0) {
            return alert("Everyone is already registered.")
        }
        this._participantInvite(participants)
    }
    _participantInvite(emails) {
        this.props.actions.course.inviteParticipants(this.props.state.course.model.id, emails)
    }

    _closeModal() {
        this.setState({
            isParticipantModalOpen: false,
            participant: null
        })
        return false
    }

    _removeAllParticipants() {
        const course = this.props.state.course.model
        var youSure = confirm("Are you sure you want to remove all participants from " + course.name + "?")
        if (!youSure) {
            return false
        }
        this.props.actions.participant.removeAllParticipants(course.id, function () {

        })
    }



    _openTeamModal(team) {
        this.setState({ isTeamModalOpen: true, selectedTeam: team })
    }
    _closeTeamModal() {
        this.setState({ isTeamModalOpen: false, selectedTeam: null })
    }
}

export default ReduxBinder(CourseRunTabParticipants, {
    state: ['course', 'participant', 'team']
})

class TeamSection extends Component {
    static propTypes = {
        team: PropTypes.object,
        course: PropTypes.object,
        openTeamModal: PropTypes.func,
        participantEdit: PropTypes.func,
        participantRemove: PropTypes.func,
        participantInvite: PropTypes.func
    }
    static defaultProps = {
        participantEdit: () => { },
        participantRemove: () => { },
        participantInvite: () => { }
    }
    render() {
        const { props } = this
        const { team } = props
        let rolesCount = 0
        team.participants.map(p => {
            if (!p) { return }
            rolesCount += p.roles.length
        })

        return <div key={team.id}>
            <h4>
                {team.name} ({team.participants.length})
                <small className="margin-left-5"><a onClick={() => props.openTeamModal(team)}>Edit</a></small>
                <small className="pull-right"># of roles: {rolesCount}</small>
            </h4>
            <Card padding={false}>
                <table className="table table-hover table-striped" style={{ marginBottom: 0 }}>
                    <tbody>
                        {team.participants.length === 0 && <tr className="info"><td>No participants in this team</td></tr>}
                        {team.participants.map(participant => {
                            return <ParticipantCard2 key={participant.id}
                                course={props.course}
                                actions={props.actions}
                                participant={participant}
                                participantEdit={props.participantEdit}
                                participantRemove={props.participantRemove}
                                participantInvite={props.participantInvite} />
                        })}
                    </tbody>
                </table>
            </Card>
        </div>
    }
}
class ParticipantCard2 extends Component {
    static propTypes = {
        participant: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        course: PropTypes.object.isRequired,
        participantEdit: PropTypes.func.isRequired,
        participantRemove: PropTypes.func.isRequired,
        participantInvite: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props)
        this.state = {
            isInEditMode: false,
            teamId: props.participant.teamId,
            roles: props.participant.roles,
            hasPickedRoles: props.participant.hasPickedRoles
        }
    }
    render() {
        const { props, state } = this
        const participant = props.participant

        return <tr>
            <td width={10}>
                <span style={{ paddingLeft: 5 }}>
                    {participant.userz.registered && <OverlayTrigger placement="top" id={`registered-${participant.id}`} overlay={<Tooltip id={`registered2-${participant.id}`}>User is registered</Tooltip>}>
                        <i className="fa fa-check fa-fw green"></i>
                    </OverlayTrigger>}
                    {!participant.userz.registered && <OverlayTrigger placement="top" id={`registered-${participant.id}`} overlay={<Tooltip id={`registered2-${participant.id}`}>User is NOT registered</Tooltip>}>
                        <i className="fa fa-times fa-fw red"></i>
                    </OverlayTrigger>}
                </span>
            </td>
            <td width={40}>
                <Avatar user={participant.userz} size={40} />
            </td>

            <td colSpan={state.isInEditMode ? 2 : 1}>
                <strong className="margin-right-5">{participant.displayName.withEmail}</strong><br />
                {!state.isInEditMode && <div>
                    {props.course.teams.length > 0 && <span className="margin-right-5"><strong>Team: </strong> {participant.teamName || 'No team'}</span>}
                    <strong>Roles: </strong> {participant.roleNames.length === 0 ? 'No roles' : participant.roleNames.join(', ')}<br />
                </div>}
                {state.isInEditMode && <div>
                    {props.course.teams.length > 0 && <Input
                        type="select"
                        name="teamId"
                        value={state.teamId}
                        onChange={(tempParticipant, value) => this.setState({ teamId: value })}
                        label="Team"
                        options={props.course.teams.map((team) => {
                            return { value: team.id, label: team.name }
                        })} />}

                    <Input
                        type="select"
                        multiSelect={true}
                        placeholder="Choose role(s) for this participant"
                        name="roles"
                        value={state.roles}
                        onChange={(tempParticipant, value) => {
                            this.setState({ roles: value })
                        }}
                        label="Roles"
                        options={props.course.roles.map((role) => {
                            return { value: role.staticId, label: role.name }
                        })} />

                    <Input
                        type="bool"
                        value={state.hasPickedRoles}
                        label="Has chosen role"
                        onChange={(name, value) => {
                            this.setState({ hasPickedRoles: value })
                        }}
                        description="This allows you to re-enable or disable the participant to choose their own roles." />

                    <a className="btn btn-secondary" onClick={this._saveAndExit.bind(this)}>Save & Exit</a>
                    <a className="btn btn-link" onClick={this._cancel.bind(this)}>Cancel</a>
                    <a className="btn btn-link" onClick={() => props.participantEdit(participant)}>Edit user</a>
                    <a className="btn btn-link red" onClick={() => props.participantRemove(participant)}>Remove participant</a>
                </div>}
            </td>
            {!state.isInEditMode && <td width={30}><a onClick={() => this.setState({ isInEditMode: true })}>Edit</a></td>}
        </tr>

    }

    _cancel() {
        this.setState({
            teamId: this.props.participant.teamId,
            roles: this.props.participant.roles,
            isInEditMode: false
        })
    }

    _saveAndExit() {
        const { props, state } = this
        let participant = props.participant
        participant.teamId = state.teamId
        participant.roles = state.roles
        participant.hasPickedRoles = state.hasPickedRoles
        props.actions.participant.update(participant)
        this.setState({ isInEditMode: false })
    }
}


class ParticipantCard extends Component {
    static propTypes = {
        participant: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        course: PropTypes.object.isRequired,
        participantEdit: PropTypes.func.isRequired,
        participantRemove: PropTypes.func.isRequired,
        participantInvite: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props)
        this.state = {
            isInEditMode: false,
            teamId: props.participant.teamId,
            roles: props.participant.roles
        }
    }
    render() {
        const { props, state } = this
        const participant = props.participant

        return <Participant.Card participant={participant}>
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-8">

                        {state.isInEditMode && <div>
                            {props.course.teams.length > 0 && <Input
                                type="select"
                                name="teamId"
                                value={state.teamId}
                                onChange={(tempParticipant, value) => this.setState({ teamId: value })}
                                label="Team"
                                options={props.course.teams.map((team) => {
                                    return { value: team.id, label: team.name }
                                })} />}
                            <Input
                                type="select"
                                multiSelect={true}
                                placeholder="Choose role(s) for this participant"
                                name="roles"
                                value={state.roles}
                                onChange={(tempParticipant, value) => {
                                    this.setState({ roles: value })
                                }}
                                label="Roles"
                                options={props.course.roles.map((role) => {
                                    return { value: role.staticId, label: role.name }
                                })} />
                            <a className="btn btn-link" onClick={this._saveAndExit.bind(this)}>Save & Exit</a>
                        </div>}

                        {!state.isInEditMode && <div>
                            {props.course.teams.length > 0 && <span><strong>Team: </strong> {participant.teamName || 'No team'}</span>}
                            {props.course.teams.length > 0 && <br />}
                            <strong>Roles: </strong> {participant.roleNames.length === 0 ? 'No roles' : participant.roleNames.join(', ')}<br />
                            <a onClick={() => this.setState({ isInEditMode: true })}>Edit Role(s){props.course.teams.length > 0 ? '/Team' : ''}</a>
                        </div>}

                    </div>


                    <div className="col-xs-12 col-sm-4">
                        <p>
                            <a onClick={() => props.participantEdit(participant)}>Edit</a>
                            {`  |  `}
                            <a onClick={() => props.participantRemove(participant)} className="red">Remove</a><br />
                            Status: {participant.userz.registered ? <span><i className="fa fa-check green"></i> Registered</span> : <span><i className="fa fa-times red"></i> NOT registered</span>}<br />
                            {!participant.userz.registered && <OverlayTrigger placement="bottom" id={`invite-all-participants${participant.id}`} overlay={<Tooltip id={`invite-all-participants${participant.id}`}>Send registration mail</Tooltip>}>
                                <a onClick={() => props.participantInvite([participant.userz.email])}><i className="fa fa-envelope-o" style={{ marginRight: 3 }} /> Send invite</a>
                            </OverlayTrigger>}
                        </p>
                        {props.course.participantsCanChooseRoles && <Input
                            type="bool"
                            label="Has chosen role(s)?"
                            value={participant.hasPickedRoles}
                            onChange={(name, value) => {
                                participant.hasPickedRoles = value
                                props.actions.participant.update(participant)
                            }} />}
                    </div>

                </div>

            </div>
        </Participant.Card>
    }



    _saveAndExit() {
        const { props, state } = this
        let participant = props.participant
        participant.teamId = state.teamId
        participant.roles = state.roles
        props.actions.participant.update(participant)
        this.setState({ isInEditMode: false })
    }
}