import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import Value from '../../components/Value'

class CourseConfigurationTabValues extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            search: ''
        }
    }

    render() {
        const course = this.props.state.course.model
        const { valueId } = this.props.match.params

        let values = course.values

        if (this.state.search !== '') {
            let search = this.state.search.toLowerCase()
            values = values.filter((value) => {
                return value.name.toLowerCase().indexOf(search) !== -1
            })
        }

        values = values.map(value => {
            // value.questionsWithValueCount = 0
            // value.forms = course.forms.filter(form => {
            //     let addForm = false
            //     form.questions.map(question => {
            //         if (question.valueStaticId === value.staticId) {
            //             addForm = true
            //             value.questionsWithValueCount++
            //         }
            //     })
            //     return addForm
            // })
            return value
        })

        return (
            <div>
                <Helmet title={course.name + " values"} />
                <div className="page-header">
                    <Search search={this.state.search}
                        onChange={(search) => this.setState({ search: search })} />
                    <a onClick={this._openModal.bind(this, 'new')} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add value</a>

                </div>

                {this.state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Use forms to have the participants have impacts on the values.</p>
                )}

                {this.state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{this.state.search}</strong></em></p>
                )}

                {values.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No values</strong>
                        {this.state.search === '' && (
                            <p>Click the "Add" button on the top right.</p>
                        )}
                    </div>
                )}

                {values.map(value => {
                    return (
                        <Value.Card value={value}
                            key={value.id}
                            onEditClick={this._openModal.bind(this, value.id)}>
                            {/*<p className="help-block">
                                <i className="fa fa-info" /> Forms: {value.forms.length} - Questions: {value.questionsWithValueCount}
                    </p>*/}
                        </Value.Card>
                    )
                })}



            </div>
        )
    }

    _openModal(valueId) {
        this.props.actions.local.modal('value', valueId)

        // const course = this.props.state.course.model
        // this.props.history.push(`/course/configuration/${course.id}/values/${valueId === null ? 'new' : valueId}`)
    }
}



export default ReduxBinder(CourseConfigurationTabValues, {
    state: ['course', 'value']
})