import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Card, DragChangeNumber, Avatar, Input } from 'alias-components'
import config from 'alias-config'
import moment from 'moment-timezone'
import ReduxBinder from "alias-store/ReduxBinder"

const dateTimeFormat = config.app.time.activityDateTimeFormat

class ModuleTabActivities extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    }

    static defaultProps = {}

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            filter: '',
            newFormModal: null,
            previewActivityId: null,
            module: this._findModule(props),
            moduleId: props.match.params.moduleId
        }
    }


    _findModule(props) {
        const { moduleId } = props.match.params
        const course = props.state.course.model
        let module = null
        const moduleList = course.modules.filter(m => m.id === moduleId)
        if (moduleList.length > 0) {
            module = moduleList[0]
        }
        return module
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.moduleId !== this.state.moduleId) {
            this.setState({
                module: newProps.module
            })
        }
    }

    _getFilterOptions() {
        const course = this.props.state.course.model
        let filterOptions = []
        filterOptions.push({
            label: "ROLES",
            options: course.roles.map(role => {
                return {
                    label: role.name,
                    value: role.staticId
                }
            })
        })
        if (course.organizations.length > 1) {
            filterOptions.push({
                label: "ORGANIZATIONS",
                options: course.organizations.map(org => {
                    return {
                        label: org.name,
                        value: org.staticId
                    }
                })
            })

        }
        return filterOptions
    }

    render() {
        const { props, state } = this
        const { moduleId } = props.match.params
        const course = props.state.course.model
        const moduleList = course.modules.filter(m => m.id === moduleId)
        if (moduleList.length === 0) {
            return <div>Module not found</div>
        }
        const module = moduleList[0]

        var activities = course.activities.filter(activity => (!activity.moduleStaticId || activity.moduleStaticId == module.staticId) && activity.type !== 'triggered-email')

        // ERRORS
        const activitiesWithNoTo = activities.filter(activity => {
            if (["rolePlay", 'calendar'].indexOf(activity.type) !== -1) { return false }
            if (activity.moduleStaticId != module.staticId) { return false }
            if (!activity.parentStaticId) { return false }
            return !activity.roleStaticId
        })
        const activitiesWithNoFrom = activities.filter(activity => {
            if (["rolePlay", 'calendar'].indexOf(activity.type) !== -1) { return false }
            if (activity.moduleStaticId != module.staticId) { return false }
            if (!activity.parentStaticId) { return false }
            return !activity.characterStaticId
        })

        const filterOptions = this._getFilterOptions()


        return <div>
            <Helmet title={course.name + " course activities"} />

            <div className="row">
                <div className="col-xs-12 col-md-4">
                    <Input type="text"
                        placeholder="Search..."
                        value={state.search}
                        onChange={(name, value) => this.setState({ search: value })} />
                </div>
                <div className="col-xs-12 col-md-4">
                    <Input type="select"
                        placeholder="Filter..."
                        options={filterOptions}
                        value={state.filter}
                        onChange={(name, value) => this.setState({ filter: value })} />
                </div>
                <div className="col-xs-12 col-md-4">
                    <a onClick={this._selectActivity.bind(this, 'new', null)} className="btn btn-secondary btn-block"><i className="fa fa-plus"></i> Add group</a>
                </div>
            </div>
            <br />

            {activitiesWithNoTo.length > 0 && (
                <div className="alert alert-warning">
                    <strong><i className="fa fa-bullhorn" /> ACTIVITY(S) WITH NO RECIPIENT</strong>
                    <p>Some activities don't have a recipient (to), please find the ones with a <i className="fa fa-times red" /> and fix the error. You will not be able to launch the course as long as some of the activities don't have a recipient.</p>
                </div>
            )}
            {activitiesWithNoFrom.length > 0 && (
                <div className="alert alert-warning">
                    <strong><i className="fa fa-bullhorn" /> ACTIVITY(S) WITH NO SENDER</strong>
                    <p>Some activities don't have a sender (from), please find the ones with a <i className="fa fa-times red" /> and fix the error. You will not be able to launch the course as long as some of the activities don't have a sender.</p>
                </div>
            )}

            {activities.filter(activity => {
                return (!activity.parentStaticId && (activity.moduleStaticId === module.staticId || !activity.moduleStaticId))
            }).sort((a, b) => {
                return a.offset - b.offset
            }).map(activity => {
                let groupActivities = course.activities.filter(childActivity => {
                    return childActivity.parentStaticId == activity.staticId
                }).sort((a, b) => {
                    return a.offset - b.offset
                })

                let moduleStartDate = moment(course.start).tz(course.timezone).add(course.byStaticId.modules[activity.moduleStaticId].offset || 0, 'm')
                return (
                    <Card key={activity.id}>
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th onClick={this._selectActivity.bind(this, activity.id, null)} colSpan={4}>
                                        {activity.name}
                                        <span className="pull-right">{moduleStartDate ? moduleStartDate.clone().add(activity.offset, 'm').format(dateTimeFormat) : null} <DragChangeNumber number={activity.offset} onChangeFinished={(number) => this._activityTimeOffsetChange(activity, number)} /></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupActivities.length == 0 && (
                                    <tr className="info">
                                        <td colSpan={4}>No activities</td>
                                    </tr>
                                )}
                                {groupActivities.map(childActivity => {
                                    var hasErrors = ((childActivity.type === 'email' && (!childActivity.characterStaticId || !childActivity.roleStaticId))
                                        || (childActivity.type === 'chat' && (!childActivity.roleStaticId || !childActivity.roleStaticId || !childActivity.formStaticId)))
                                    if (state.search !== '') {
                                        let search = state.search.toLowerCase()
                                        if (childActivity.name.toLowerCase().indexOf(search) !== -1
                                            || childActivity.subject.toLowerCase().indexOf(search) !== -1
                                            || childActivity.body.toLowerCase().indexOf(search) !== -1) {
                                            // #todo  Need to make this more clear.
                                        } else {
                                            return null
                                        }
                                    }
                                    if (state.filter) {
                                        if (childActivity.roleStaticId !== state.filter) {
                                            return null
                                        }
                                    }
                                    return (
                                        <tr onClick={this._selectActivity.bind(this, childActivity.id, null)} key={childActivity.id}
                                            style={{ cursor: 'pointer' }} className={`${childActivity.isDraft ? 'warning' : ''} ${hasErrors ? 'danger' : ''}`}>
                                            <td>
                                                {childActivity.type === 'email' ? <i className="fa fa-envelope fa-fw"></i> : childActivity.type === 'rolePlay' ? <i className="fa fa-users fa-fw"></i> : childActivity.type === 'calendar' ? <i className="fa fa-calendar fa-fw"></i> : childActivity.type === 'chat' ? <i className="fa fa-comments fa-fw"></i> : childActivity.type === 'forum' ? <i className="fa fa-th-list fa-fw"></i> : <i className="fa fa-circle fa-fw"></i>}
                                                <span style={{ marginLeft: 10 }}>
                                                    {childActivity.name} {childActivity.isDraft ? <em> (Draft)</em> : ''}
                                                </span>
                                                {(childActivity.badgeStaticId && course.byStaticId.badges[childActivity.badgeStaticId]) && <span className="badge info">{course.byStaticId.badges[childActivity.badgeStaticId].name}</span>}
                                            </td>
                                            <td>
                                                {['email', 'chat'].indexOf(childActivity.type) !== -1 && this._renderFromTo(childActivity)}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {moduleStartDate ? moduleStartDate.clone().add(activity.offset, 'm').add(childActivity.offset, 'm').format(dateTimeFormat) + ' ' : null}
                                                <DragChangeNumber number={childActivity.offset} onChangeFinished={(number) => {
                                                    this._activityTimeOffsetChange(childActivity, number)
                                                }} />
                                            </td>
                                            <td>
                                                {['email', 'chat'].indexOf(childActivity.type) !== -1 && (
                                                    <a onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        props.actions.local.modal('activityPreview', childActivity.id)
                                                        // this.setState({ previewActivityId: childActivity.id })
                                                    }}>
                                                        <i className="fa fa-eye" />
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <a onClick={this._selectActivity.bind(this, 'new', activity.staticId)} className="btn btn-default">+ Add an activity</a>
                    </Card>
                )
            })}
            {activities.filter(activity => {
                return (!activity.parentStaticId && (activity.moduleStaticId === module.staticId || !activity.moduleStaticId))
            }).length === 0 && <div className="alert alert-info">
                <strong>Quick tutorial</strong>
                <p>Here's how to get started with activities:</p>
                <ol>
                    <li>Create a group by clicking "+Add new group" and giving it a name. Groups allow you to organize emails that are sent out.</li>
                    <li>Add an activity by clicking "+Add an activity" in the group. Activities are emails that are sent out. Give it a name, subject, body who it's sent to and who it's from.</li>
                    <li>Don't forget to click "Save" when you're done.</li>
                    <li>You can preview the message by going to "Preview" (Under the left menu) and going to mailbox.</li>
                </ol>
            </div>}
        </div>

    }

    _renderFromTo(activity) {
        const course = this.props.state.course.model
        const size = 20
        const character = course.byStaticId.characters[activity.characterStaticId]
        let role = null
        if (activity.roleStaticId) {
            if (activity.roleStaticId === 'all') {
                role = 'all'
            } else if (course.byStaticId.organizations[activity.roleStaticId]) {
                role = course.byStaticId.organizations[activity.roleStaticId]
            } else if (course.byStaticId.roles[activity.roleStaticId]) {
                role = course.byStaticId.roles[activity.roleStaticId]
            } else if (course.byId.teams[activity.roleStaticId]) {
                role = course.byId.teams[activity.roleStaticId]
            }

        }
        if (!role || !character) { return null }
        return <div>
            <span style={{ position: 'relative' }}><Avatar user={character} size={size}
                tooltip={true} />
            </span> <i className="fa fa-chevron-right" /> {role === 'all' ? "All" : role.name}
        </div>
    }

    _selectActivity(activityId = 'new', parentStaticId = null) {
        const { props } = this
        let moduleStaticId = null
        if (activityId === 'new') {
            if (!parentStaticId) {
                const course = props.state.course.model
                const { moduleId } = props.match.params
                if (course.byId.modules[moduleId]) {
                    moduleStaticId = course.byId.modules[moduleId].staticId
                }
            }
        }
        props.actions.local.openModalActivity(activityId, moduleStaticId, parentStaticId)
    }

    _activityTimeOffsetChange(activity, number) {
        activity.offset = number
        this.props.actions.activity.update(activity, () => {

        })
    }

}

export default ReduxBinder(ModuleTabActivities, {
    state: ['course', 'activity', 'doc', 'form', 'badge', 'value']
})