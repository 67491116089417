import moment from 'moment-timezone'

export default {
    getParticipantBadges(course, participant) {
        let badgesInfo = {}
        let participantLogs = course.activityLogs.filter(l => {
            if (l.type !== 'form') { return false }
            if (l.participantId !== participant.id) { return false }
            return true
        })
        participantLogs.map(log => {
            let form = course.byStaticId.forms[log.formStaticId]
            if (!form) { return }
            if (!log.data) { return }
            if (!log.data.answers) { return }
            log.data.answers.map(answer => {
                form.questions.map(question => {
                    if (question.id !== answer.id) { return }
                    if (question.type !== 'multiple-choice') { return }
                    if (question.options) {
                        question.options.map(option => {
                            if (!option.badgeStaticId) { return }
                            if (answer.answer !== option.id) { return }
                            if (course.byStaticId.badges[option.badgeStaticId]) {
                                if (!badgesInfo[option.badgeStaticId]) {
                                    badgesInfo[option.badgeStaticId] = {
                                        name: course.byStaticId.badges[option.badgeStaticId].name,
                                        count: 0,
                                        gottenTimeStamp: answer.created ? answer.created : log.created
                                    }
                                } else {
                                    let previousTimeStamp = badgesInfo[option.badgeStaticId].gottenTimeStamp
                                    let currentTimeStamp = answer.created ? answer.created : log.created
                                    if (moment(currentTimeStamp).isBefore(previousTimeStamp)) {
                                        badgesInfo[option.badgeStaticId].gottenTimeStamp = currentTimeStamp
                                    }
                                }
                                badgesInfo[option.badgeStaticId].count = badgesInfo[option.badgeStaticId].count + 1
                            }
                        })
                    }
                })
            })
        })
        return badgesInfo
    },
    getTeamBadges(course, teamId) {
        let badgesInfo = {}
        course.participants.map(participant => {
            if (participant.teamId === teamId) {
                let participantBadgesInfo = participant.badgesInfo
                if (!participantBadgesInfo) {
                    participantBadgesInfo = this.getParticipantBadges(course, participant)
                }
                Object.keys(participantBadgesInfo).map(badgeStaticId => {
                    if (!badgesInfo[badgeStaticId]) {
                        badgesInfo[badgeStaticId] = participantBadgesInfo[badgeStaticId]
                    } else {
                        let previousTimeStamp = badgesInfo[badgeStaticId].gottenTimeStamp
                        let currentTimeStamp = participantBadgesInfo[badgeStaticId].gottenTimeStamp
                        if (moment(currentTimeStamp).isBefore(previousTimeStamp)) {
                            badgesInfo[badgeStaticId].gottenTimeStamp = currentTimeStamp
                        }
                    }
                    badgesInfo[badgeStaticId].count = badgesInfo[badgeStaticId].count + participantBadgesInfo[badgeStaticId].count
                })

            }
        })
        return badgesInfo
    }
}