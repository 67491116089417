import React, { Component } from 'react'
import PropTypes from 'prop-types'
import structures from 'alias-store/structures'
import { Button, Input, FormErrors, VideoUpload, VideoPlayer, FileUpload } from 'alias-components'
import api from 'alias-tools/api'


export default class ForumForm extends Component {
    static propTypes = {
        post: PropTypes.object,
        actions: PropTypes.object,
        participantId: PropTypes.string,
        participant: PropTypes.object, // For the contact form
        courseId: PropTypes.string,
        isMainPost: PropTypes.bool,
        parentId: PropTypes.string,
        onComplete: PropTypes.func,
        updateUnread: PropTypes.func
    }
    static defaultProps = {
        isMainPost: true,
        onComplete: () => { }
    }
    constructor(props) {
        super(props)

        this.state = {
            post: props.post || { ...structures.form },
            docsBeingUploaded: [],
            isEdited: false,
            isSaving: false,
            isForInstructors: false,
            errorFields: [],
            isRemovingVideo: false
        }
    }

    render() {
        const { props, state } = this

        state.post.files = state.post.files || []

        return <form onSubmit={this._onSave.bind(this)}>
            <div className="form-horizontal">
                <FormErrors fields={state.errorFields} />
                {props.isMainPost && <Input name="title"
                    label="Title"
                    placeholder="Ex: Topic and/or question..."
                    value={state.post.title || ""}
                    onChange={this._onChange.bind(this)}
                    attributes={{ 'autoFocus': true }} />}

                <Input name="text"
                    label={!props.isMainPost ? "" : "Text"}
                    type="textarea"
                    placeholder="Give more details..."
                    value={state.post.text || ""}
                    onChange={this._onChange.bind(this)} />

                {((props.participant && !props.participant.isAdmin) && props.isMainPost) && <p>
                    <input value={state.isForInstructors} onClick={() => this.setState({ isForInstructors: !state.isForInstructors })} type="checkbox" /> Need an instructor? Check this box to get their attention. Alternatively, if you have an issue with the platform, <a onClick={() => props.actions.local.modalHelpOpen(props.courseId, props.participant.userz)}>contact the tech team here</a>
                </p>}

                {(state.post && state.post.video && state.post.video.indexOf('amazon') === -1) && <div>
                    <iframe
                        src={`https://player.vimeo.com/video/${state.post.video}?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=142791`}
                        width="400"
                        height="300"
                        frameBorder="0"
                        title="someFile.mp4"
                        allowFullScreen></iframe>
                    <a onClick={this._onVideoComplete.bind(this, null)} className="btn red">Remove video</a>
                </div>}
                {(state.post.files.length > 0) && <ul className="list-unstyled" style={{ lineHeight: '25px' }}>
                    {state.post.files.map((file, i) => {
                        return <li key={i} style={{ paddingBottom: '10px' }}>
                            <a href={`/api/files?url=${file.path}`} target="_blank">{file.name}</a> <a onClick={this._onRemoveFile.bind(this, file)} className="red">Remove</a>
                        </li>
                    })}
                </ul>}
                {(state.post && state.post.video) && <div>
                    <VideoPlayer url={state.post.video} />
                    <p><a disabled={state.isRemovingVideo} onClick={this._onRemoveVideo.bind(this)} className="btn red">{state.isRemovingVideo ? <i className="fa fa-spinner fa-pulse" /> : "Remove video"}</a></p>
                </div>}

                <Button text={props.post && props.post.id ? "Save" : "Post"} isLoading={state.isSaving} />
                {(props.post && props.post.id) && <a onClick={() => props.onComplete()} style={{ marginLeft: '5px' }}>Cancel</a>}
                <FileUpload courseId={props.courseId} onComplete={this._onFileComplete.bind(this)} />
                {false && <VideoUpload courseId={props.courseId} onComplete={this._onVideoComplete.bind(this)} />}
            </div>
        </form>
    }

    _onFileComplete(file) {
        let { post } = this.state
        post.files = post.files || []
        post.files.push(file)
        this.setState({ post: post })
    }

    _onRemoveFile(file) {

        api(
            '/files',
            'DELETE',
            { url: file.path }
        )
            .then(() => {
                let { post } = this.state
                post.files = post.files.filter(f => f !== file)
                this.setState({ post: post })
            })
            .catch(() => {

            })
    }

    _onRemoveVideo() {
        this.setState({ isRemovingVideo: true })
        api(
            '/video/remove',
            'GET',
            { url: this.state.post.video }
        )
            .then(() => {
                this.setState({
                    isRemovingVideo: false,
                    post: {
                        ...this.state.post,
                        video: null
                    }
                })
            })
            .catch(() => {
                this.setState({ isRemovingVideo: false })
            })
    }
    _onChange(name, value) {
        let { post } = this.state
        post[name] = value
        this.setState({ post: post })
    }

    _onVideoComplete(url) {
        let { post } = this.state
        post.video = url
        this.setState({ post: post })
    }

    _onSave(e) {
        e.preventDefault()
        let { post } = this.state
        if (this.props.isMainPost && !post.title) { return alert("Please enter a title to submit.") }
        if (!post.text) { return alert("Please enter some text to submit.") }
        this.setState({ isSaving: true })
        if (post.id) {
            this.props.actions.forum.update(post, () => {
                this._onFinishSave()
            })
        } else {
            post.courseId = this.props.courseId
            post.participantId = this.props.participantId
            post.parentId = this.props.parentId
            post.isForInstructors = this.state.isForInstructors
            this.props.actions.forum.create(post, () => {
                this.props.updateUnread()
                this._onFinishSave()
            })
        }
    }
    _onFinishSave() {
        this.setState({ isSaving: false, post: { ...structures.forum } })
        this.props.onComplete()
    }
}
