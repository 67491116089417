import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'

export default class RoleCard extends Component {

    static propTypes = {
        onClick: PropTypes.func,
        role: PropTypes.object,
        children: PropTypes.any
    }

    render() {
        const { onClick, role } = this.props;
        // ${role.organization ? 'at ' + role.organization.name : ''}
        return (
            <Card title={<span>
                {role.name}
                {role.isVisibleByParticipant ? <span className="badge info" style={{ marginLeft: 10 }}>Visible</span> : <span className="badge" style={{ marginLeft: 10 }}>IN-VISIBLE</span>}
                {(role.isVisibleByParticipant && role.isSelectableByParticipant) ? <span className="badge info" style={{ marginLeft: 10 }}>Selectable</span> : <span className="badge" style={{ marginLeft: 10 }}>NOT selectable</span>}
            </span>}>
                <div>
                    {role.organization && <p><i className="fa fa-building"></i> {role.organization.name}</p>}
                    {role.description && <p>{role.description}</p>}
                    {!role.description && <p className="help-block"><em>No description</em></p>}
                    {this.props.children}
                    {onClick && <a onClick={onClick} className="btn btn-secondary">Edit</a>}
                </div>
            </Card>
        )
    }
}