import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

export default class Notifications extends Component {
    static propTypes = {
        notifications: PropTypes.array,
        actions: PropTypes.object
    }
    render() {
        const self = this
        const notifications = this.props.notifications


        return (
            <div id="notif">
                {notifications.map(notification => {
                    return (
                        <NotificationItem
                            key={notification.id}
                            notification={notification}
                            actions={self.props.actions} />
                    )
                })}
                {/*<a onClick={this._addNotif} className="btn btn-primary btn-block">Add</a>*/}
            </div>
        )
    }

    _addNotif() {
        this.props.actions.notification.add({
            id: 'test',
            type: 'success',
            title: 'Adding a notification',
            body: "Yes I am",
            timeout: 3000
        })
    }
}

class NotificationItem extends Component {

    static propTypes = {
        notification: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            timer: false,
            notification: {
                ...{
                    type: 'success',
                    title: null,
                    body: null,
                    timeout: 3000
                },
                ...this.props.notification
            }
        }
    }

    componentDidMount() {
        if (!this.intervalKeeper && this.state.notification.timeout) {
            this.intervalKeeper = setInterval(this._remove.bind(this), this.state.notification.timeout)
        }
    }

    _remove() {
        if (this.intervalKeeper) {
            clearInterval(this.intervalKeeper)
        }
        this.props.actions.notification.remove(this.props.notification)
    }

    componentWillUnmount() {
        if (this.intervalKeeper) {
            clearInterval(this.intervalKeeper)
        }
    }

    render() {
        const notification = this.state.notification

        return (
            <ReactCSSTransitionGroup
                transitionName="notif"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}>
                <div className={"alert alert-" + notification.type}>
                    <button onClick={this._remove.bind(this)} type="button" className="close"><span aria-hidden="true">&times;</span></button>
                    {notification.title && (
                        <strong>{notification.title}</strong>
                    )}
                    {notification.body && (
                        <p>{notification.body}</p>
                    )}
                </div>
            </ReactCSSTransitionGroup>
        )
    }
}