import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Editor from './Editor'
import FileIcons from './FileIcons'

export default class LMSDisplay extends Component {

    static propTypes = {
        course: PropTypes.object.isRequired,
        module: PropTypes.object,
        text: PropTypes.any.isRequired,
        for: PropTypes.string
    }

    static defaultProps = {
        text: null,
        module: null,
        for: 'intro'
    }

    render() {
        const { module, text } = this.props;
        const docs = this.props.course.docs.filter(doc => {
            if (module) {
                return doc.for === this.props.for && module.staticId === doc.moduleStaticId;
            }
            return doc.for === this.props.for;
        });
        const showText = !text ? false : (typeof text !== 'object') ? false : Object.keys(text).length > 0 ? true : false;
        return (
            <div>
                {showText && <Editor text={text} readOnly={true} />}
                {docs.length > 0 && (
                    <ul className="lms-docs">
                        {docs.map(doc => {
                            return (
                                <li key={doc.staticId}>
                                    {/*<div className="icon">
                                        <a href={doc.path} target="_blank"><FileIcons name={doc.name} height={80} /></a>
                            </div>*/}
                                    <div className="text">
                                        <p><a href={doc.path} target="_blank"><FileIcons name={doc.name} height={80} /> {doc.name}</a></p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        )
    }
}