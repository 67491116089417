import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import { Link } from 'react-router-dom'

export default class FormCard extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        onEditClick: PropTypes.func,
        children: PropTypes.any
    }
    render() {
        const { form, onEditClick } = this.props
        // /builder/5aa627409317a6715ab35a32/form/jetf54ge
        return <Card title={form.name}>
            <div>
                {/*onEditClick && <a onClick={onEditClick} className="card-edit"><i className="fa fa-pencil"></i></a>*/}
                <p>
                    <Link to={`/builder/${form.courseId}/form/${form.staticId}`} className="btn btn-secondary" style={{ marginRight: 10 }}>Edit</Link>
                    <a onClick={onEditClick} className="btn btn-secondary" style={{ marginRight: 10 }}><i className="fa fa-window-restore"></i></a>
                    <a href={`/form/${form.courseId}/${form.staticId}`} target="_blank" className="btn btn-default">View</a>
                </p>
                {this.props.children}
            </div>
        </Card>

    }
}