import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'alias-components'
import ModalCompose from '../MessagingModalCompose'
import Fuse from 'fuse.js'
import { DropdownButton, MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment-timezone'

export default class MessagingConversationsList extends Component {
    static propTypes = {
        isAdmin: PropTypes.bool,
        admin: PropTypes.object,
        threads: PropTypes.array,
        participantId: PropTypes.string,
        threadId: PropTypes.string,
        back: PropTypes.func,
        selectThread: PropTypes.func,
        course: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            showNumberOfConversations: 25,
            showComposeModal: false
        }
    }

    _searchThreads(search) {
        let { course, threads } = this.props
        if (!search) { return threads }
        if (search === 'has:reply') {
            return threads.filter(thread => thread.hasReply)
        }
        let threadsIds = []
        let threadsForSearch = threads.map(thread => {
            thread.participantsInfo = thread.participants.map(pId => {
                return course.byId.participants[pId] || course.byStaticId.characters[pId]
            })
            return thread
        })
        const options = {
            shouldSort: false,
            threshold: 0.2,
            minMatchCharLength: 1,
            keys: [
                "subject",
                "participantsInfo.first",
                "participantsInfo.last",
                "participantsInfo.displayName.withTeam",
                "participantsInfo.roleName",
                "participantsInfo.organization.name",
                "messages.message"
            ]
        }

        let fuse = new Fuse(threadsForSearch, options)
        fuse.search(search).map(threadForSearch => {
            threadsIds.push(threadForSearch.id)
        })
        return threads.filter(thread => threadsIds.indexOf(thread.id) !== -1)
    }

    render() {
        const { props, state } = this
        const { search, showNumberOfConversations } = this.state
        let { isAdmin, threads, participantId, back, threadId, course, type, admin } = props

        threads = this._searchThreads(search)

        let hasMoreConversations = false
        if (threads.length > showNumberOfConversations) {
            hasMoreConversations = true
            threads = threads.slice(0, showNumberOfConversations)
        }

        let showCompose = false
        if (!isAdmin && participantId) { showCompose = true }
        if (isAdmin && admin && admin.id === participantId) { showCompose = true }


        return <div className={`column conversations ${participantId ? 'active' : ''} ${isAdmin ? 'admin' : 'non-admin'}`}>
            {isAdmin && <div className="header">
                <a onClick={() => back(true)} className="left-link hidden-sm hidden-md hidden-lg"><i className="fa fa-chevron-left" /></a>
            </div>}
            <div className="list-wrapper">
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="search">
                    <button type="submit" aria-label="Search"><i className="fa fa-search" /></button>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => this.setState({ search: e.target.value })}
                        placeholder="Search..." />
                    {(type === 'email' && isAdmin) && <DropdownButton title={""} id="search-options" pullRight>
                        <MenuItem onClick={() => this.setState({ search: 'has:reply' })}>Has reply</MenuItem>
                    </DropdownButton>}
                </form>
                <div className="list">

                    {!participantId && <div className="alert alert-info">
                        <strong><i className="fa fa-arrow-left" /> Select a participant</strong>
                        <p>You must first select a participant to view their activity.</p>
                    </div>}

                    {showCompose && <div style={{ margin: 10 }}>
                        <a className="btn btn-secondary btn-block"
                            onClick={() => this.setState({ showComposeModal: !state.showComposeModal })}>Compose</a>
                        <ModalCompose
                            isOpen={state.showComposeModal}
                            handleClose={(newThread) => {
                                this.setState({ showComposeModal: !state.showComposeModal })
                                if (newThread) {
                                    props.selectThread(newThread)
                                }
                            }}
                            type={props.type}
                            course={course}
                            actions={props.actions}
                            participantId={participantId}
                            isAdmin={props.isAdmin} />
                    </div>}


                    {(threads.length === 0 && participantId) && (
                        <div className="alert alert-info">
                            <strong>None</strong>
                        </div>
                    )}

                    {threads.map(thread => {
                        if (thread.type === 'chat') {
                            return this._renderChatConversation(thread, threadId)
                        }
                        if (thread.type === 'email') {
                            return this._renderMailConversation(thread, threadId)
                        }
                    })}
                    {hasMoreConversations && (
                        <div style={{ padding: '10px' }}>
                            <a onClick={() => this.setState({ showNumberOfConversations: showNumberOfConversations + 15 })} className="btn btn-secondary btn-block">Show more</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    }

    _renderChatConversation(thread, threadId) {
        const { selectThread, isAdmin } = this.props
        let className = "section"
        className += (threadId === thread.id) ? ' active' : ''
        className += (thread.isUnread) ? ' unread' : ''

        let showOnlineStatus = false
        if (thread && thread.messages && thread.messages.length > 0) {
            const lastMessage = thread.messages[thread.messages.length - 1]
            if (lastMessage && lastMessage.type === 'answer' && lastMessage.answer === null) {
                showOnlineStatus = true
            }
        }
        return <div className={className}
            key={thread.id}
            onClick={() => selectThread(thread)}>
            <div className="image">
                {this._renderAvatar(thread)}
                {(showOnlineStatus) && <span className={`status ${thread.isOnline ? 'on' : 'off'}`}></span>}
            </div>
            <div className="name">
                {thread.latest && <span className="not-sent-tag">
                    {thread.latest.fromNow()}
                    {(thread.hasPendingQuestion) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-action-${thread.id}`}>PENDING/UPCOMING action</Tooltip>}>
                        <span style={{ marginRight: 4 }}><i className="fa fa-flag red" style={{ marginLeft: 3, fontSize: 18 }} /></span>
                    </OverlayTrigger>}
                </span>}
                {this._renderNames(thread)}
            </div>
        </div>
    }
    _renderMailConversation(thread, threadId) {
        const { selectThread, isAdmin } = this.props
        let className = "section"
        className += (thread.id === threadId) ? ' active' : ''
        if (!isAdmin) {
            className += (thread.isUnread) ? ' unread' : ''
        }
        let showFlag = thread.hasPendingQuestion
        const lastMessage = (thread && thread.messages.length > 0) ? thread.messages[thread.messages.length - 1] : null
        if (lastMessage) {
            showFlag = showFlag && lastMessage.created.isBefore(moment())
        }

        return <div className={className}
            key={thread.id}
            onClick={() => selectThread(thread)}>
            <div className="image">
                {this._renderAvatar(thread)}
            </div>
            <div className="name">
                {thread.latest && <span className="not-sent-tag" style={{ lineHeight: '18px' }}>
                    {(isAdmin && thread.hasReply) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-read-${thread.id}`}>Participant has replied to message</Tooltip>}>
                        <span style={{ marginRight: 4 }}><i className="fa fa-reply" /></span>
                    </OverlayTrigger>}

                    {isAdmin && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-read-${thread.id}`}>{thread.isUnread ? 'Message NOT read by participant' : 'Message read by participant'}</Tooltip>}>
                        <span className={`badge ${thread.isUnread ? '' : 'info'}`} style={{ marginRight: 4 }}>{thread.isUnread ? 'Unread' : 'Read'}</span>
                    </OverlayTrigger>}
                    {thread.latest.fromNow()}
                    {(showFlag) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-action-${thread.id}`}>PENDING/UPCOMING action</Tooltip>}>
                        <span style={{ marginRight: 4 }}><i className="fa fa-flag red" style={{ marginLeft: 3, fontSize: 18 }} /></span>
                    </OverlayTrigger>}
                </span>}
                {this._renderNames(thread)}
                <p>{thread.subject}</p>
            </div>
        </div>
    }

    _renderAvatar(thread) {
        const { course, participantId } = this.props
        let otherParticipant = null
        if (thread.participants.length === 2) {
            let otherParticipantId = thread.participants.filter(pId => pId !== participantId)[0]
            otherParticipant = course.byId.participants[otherParticipantId] ? course.byId.participants[otherParticipantId].userz : course.byStaticId.characters[otherParticipantId] ? course.byStaticId.characters[otherParticipantId] : null
            return otherParticipant ? <Avatar user={otherParticipant} size={thread.type === 'chat' ? 30 : 50} /> : null
        }
        if (thread.participants.length > 2) {
            return <Avatar user={{ ...thread, name: thread.subject }} size={thread.type === 'chat' ? 30 : 50} />
        }
        return null
    }
    _renderNames(thread) {
        const { course, participantId, type } = this.props
        let display = []

        if (type === 'chat' && thread.subject) {
            display = thread.subject
        } else {
            let participants = thread.participants.filter(pId => pId !== participantId)
            participants.map((pId, i) => {
                let p = course.byId.participants[pId] || course.byStaticId.characters[pId]
                if (!p) { return null }
                display.push(<span key={thread.id + pId}>{p.displayName ? p.displayName.short : `${p.first} ${p.last}`}{(i !== participants.length - 1) && ', '}</span>)
            })
        }
        return <strong>{display}</strong>
    }
}