import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors } from 'alias-components'
import IsValid from 'alias-tools/validation'
import structures from 'alias-store/structures'


export default class ModalValue extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        valueId: PropTypes.any,
        value: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let value = { ...structures.value }

        if (props.valueId && props.valueId !== 'new' && course.byId.values[props.valueId]) {
            value = {
                ...value,
                ...course.byId.values[props.valueId]
            }
        }
        this.state = {
            value: value,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.value.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.valueId != this.state.value.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (nextProps.valueId && course.byId.values[nextProps.valueId]) {
                this.setState({
                    value: course.byId.values[nextProps.valueId]
                })
            } else {
                this.setState({ value: { ...structures.value } })
            }
        }
    }

    render() {
        const value = this.state.value

        var title = "Create a new value"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.valueId !== 'new') {
            title = "Update value"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }


        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {this.props.isOpen && <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Sales..."
                                value={value.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input
                                name="unit"
                                label="Unit"
                                placeholder="Ex: $..."
                                value={value.unit}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="number"
                                name="base"
                                label="Base value"
                                placeholder="Ex: 1000..."
                                value={value.base}
                                onChange={this._onChangeNew.bind(this)} />


                            <Input
                                type="textarea"
                                name="description"
                                label="Description" O
                                placeholder="Ex: Explain what this value is (Optional)..."
                                value={value.description}
                                onChange={this._onChangeNew.bind(this)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button text={btnText} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(this.props.valueId !== 'new') && (<a onClick={this._delete.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>)}

                    </Modal.Footer>
                </form>}
            </Modal>
        )
    }

    _onChangeNew(name, val) {
        var value = this.state.value
        value[name] = val
        this.setState({ value: value, isEdited: true })
    }

    _isFormValid() {
        var value = this.state.value
        var formIsValid = true
        var errorFields = []
        let errors
        // Name
        errors = IsValid(value.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Variable name",
                errors: errors
            })
        }

        // Base
        errors = IsValid(value.base, { required: null, integer: null })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Base value",
                errors: errors
            })
        }

        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        let value = this.state.value
        const course = this.props.state.course.model
        value.courseId = course.id
        this.props.actions.value.create(value, (value) => {
            this.setState({ isEdited: false })
            this.props.openModal('value', value.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const value = this.state.value
        this.props.actions.value.update(value, () => {
            this.setState({ isEdited: false })
            // this.props.handleClose()
        })
    }

    _delete() {

        const value = this.state.value
        if (confirm("Are you sure you want to delete " + value.name + "?")) {

            this.props.actions.value.remove(value, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}