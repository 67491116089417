import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'


export default class FormInputRange extends Component {
    static propTypes = {
        question: PropTypes.object,
        onChange: PropTypes.func
    }
    render() {
        let { question } = this.props
        question.data = question.data || {
            min: 0,
            max: 150
        };
        return (
            <div className="row">
                <div className="col-xs-6">
                    <Input
                        type="number"
                        placeholder="Ex: 0"
                        label={`Minimum value`}
                        name="min"
                        value={question.data.min}
                        onChange={this._onChange.bind(this)} />
                </div>
                <div className="col-xs-6">
                    <Input
                        type="number"
                        placeholder="Ex: 150"
                        label={`Maximum`}
                        name="max"
                        value={question.data.max}
                        onChange={this._onChange.bind(this)} />
                </div>
            </div>
        );
    }

    _onChange(name, value) {
        let question = this.props.question;
        question.data = question.data || {};
        question.data[name] = value;
        this.props.onChange(question);
    }
}