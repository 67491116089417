import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'alias-components'
import { Line } from 'react-chartjs-2'
import Scoring from '../Scoring'
import Form from '../Form'
import { debug } from 'util'

// http://www.chartjs.org/docs/latest/configuration/layout.html

const baseDataSet = {
    label: 'My First dataset',
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: 'rgba(75,192,192,1)',
    data: []
}

const graphLegendOptions = {
    display: true,
    position: 'bottom',
    fullWidth: true,
    reverse: false,
    labels: {
        fontColor: '#333'
    }
}

// http://www.chartjs.org/docs/latest/developers/axes.html
// let MyScale = Chart.Scale.extend({
//     /* extensions ... */
// });

// Chart.scaleService.registerScaleType('myScale', MyScale, defaultConfigObject);

const graphOptions = {
    layout: {
        padding: {
            left: 20,
            right: 0,
            top: 0,
            bottom: 0
        },
        title: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
        // scales: {
        //     yAxes: [{
        //         type: 'myScale' // this is the same key that was passed to the registerScaleType function
        //     }]
        // }
    }
}

const colors = ["#631D76", "#FE5F55", "#BE3E82", "#F9C80E", "#496DDB", '#FE9920', '#CFD11A', '#91C499', '#9E768F', '#E55381', '#D183C9', '#2292A4', '#7DBBC3', '#AB3428']


export default class WidgetGraph extends Component {
    static propTypes = {
        course: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            formStaticId: '',
            moduleId: '',
            viewTeams: props.course.teams.length > 0,
            participants: [],
            teams: []
        }
    }

    _getChardData() {
        const { props, state } = this
        const { course } = props

        let labels = ['Overall']
        course.scoringTags.map(tag => {
            labels.push(tag.name)
        })

        let forms = []
        let formStaticIds = state.formStaticId === '' ? [] : [state.formStaticId]
        if (formStaticIds.length > 0) {
            formStaticIds.map(formStaticId => {
                if (course.byStaticId.forms[formStaticId]) {
                    forms.push(course.byStaticId.forms[formStaticId])
                }
            })
        } else {
            forms = [...course.forms]
        }

        let logs = course.activityLogs.filter(l => {
            if (l.type !== 'form') { return false }
            if (formStaticIds.length === 0) { return true }
            return formStaticIds.indexOf(l.formStaticId) !== -1
        })


        let datasets = []
        if (state.viewTeams) {
            const teams = state.teams.length === 0 ? course.teams.map(t => t.id) : state.teams
            datasets = teams.map(teamId => {
                let team = course.byId.teams[teamId]
                let dataSet = { ...baseDataSet }
                dataSet.label = team.name

                let teamColor = team.color ? team.color : colors[Math.floor(Math.random() * colors.length)]
                dataSet.borderColor = teamColor
                dataSet.backgroundColor = teamColor

                // let teamScore = team.score ? team.score : Scoring.Tools.team(teamId, course.participants, course.activities, logs, forms, course.scoringTags)
                let teamScore = Scoring.Tools.team(teamId, course.participants.filter(p => {
                    delete p.score
                    return p
                }), course.activities, logs, forms, course.scoringTags)
                if (teamScore === false) {
                    dataSet.data = [0] // For overall
                    labels.map(() => {
                        dataSet.data.push(0)
                    })
                } else {
                    dataSet.data = [teamScore.score]
                    if (teamScore.scoringTags) {
                        labels.map((label) => {
                            teamScore.scoringTags.map(tag => {
                                if (tag.name === label) {
                                    dataSet.data.push(tag.score || 0)
                                }
                            })
                        })
                    }
                }
                return dataSet
            })
        } else {
            const participants = state.participants.length === 0 ? course.participants.filter(p => !p.isAdmin).map(p => {
                delete p.score
                return p.id
            }) : state.participants
            datasets = participants.map(participantId => {
                let participant = course.byId.participants[participantId]
                let dataSet = { ...baseDataSet }
                dataSet.label = participant.displayName.bigTeamThenName

                let participantColor = participant.userz.color ? participant.userz.color : colors[Math.floor(Math.random() * colors.length)]
                dataSet.borderColor = participantColor
                dataSet.backgroundColor = participantColor
                // let participantScore = participant.score ? participant.score : Scoring.Tools.participant(participant, course.activities, logs, forms, course.scoringTags)
                delete participant.score
                let participantScore = Scoring.Tools.participant(participant, course.activities, logs, forms, course.scoringTags)
                if (!participantScore) {
                    dataSet.data = [0] // For overall
                    labels.map(() => {
                        dataSet.data.push(0)
                    })
                } else {
                    dataSet.data = [participantScore.score]
                    if (participantScore.scoringTags) {
                        labels.map((label) => {
                            participantScore.scoringTags.map(tag => {
                                if (tag.name === label) {
                                    dataSet.data.push(tag.score)
                                }
                            })
                        })
                    }
                }
                return dataSet
            })
        }
        let data = {
            labels: labels,
            datasets: datasets,
            logs: logs
        }

        return data
    }

    render() {
        const { props, state } = this
        const { course } = props
        const courseHasTeams = course.teams.length > 0

        const formSelectionOptions = course.forms.map(form => {
            return {
                label: form.name,
                value: form.staticId
            }
        })
        const moduleSelectionOptions = course.modules.map(module => {
            return {
                label: module.name,
                value: module.id
            }
        })

        let teamParticipantOptions = []
        if (state.viewTeams) {
            teamParticipantOptions = course.teams.map(team => {
                return {
                    label: team.name,
                    value: team.id
                }
            })
        } else {
            teamParticipantOptions = course.participants.filter(p => !p.isAdmin).map(participant => {
                return {
                    label: participant.displayName.bigTeamThenName,
                    value: participant.id
                }
            })
        }

        const graphData = this._getChardData()

        let formAnswers = {
            course: course,
            participants: [],
            logs: [],
            form: null,
            showAllAnswers: false
        }
        if (state.formStaticId) {
            formAnswers.form = course.byStaticId.forms[state.formStaticId]
            formAnswers.participant = course.participants
            formAnswers.logs = graphData.logs
        }

        const colSize = 6
        return <div>
            <h3 style={{ marginTop: 0 }}>Filter
                {courseHasTeams && <small style={{ marginLeft: 15 }}>
                    <label>
                        <input type="checkbox"
                            checked={state.viewTeams}
                            name="viewTeams"
                            onChange={() => this._onChangeFilter('viewTeams', !state.viewTeams)} /> View by teams
                    </label>
                </small>}</h3>
            <div className="row">
                <div className={`col-xs-12 col-sm-${colSize}`}>
                    <Input type="select"
                        label="Form"
                        name="formStaticId"
                        value={state.formStaticId}
                        options={formSelectionOptions}
                        placeholder="Select a form"
                        onChange={this._onChangeFilter.bind(this)} />
                </div>
                <div className={`col-xs-12 col-sm-${colSize}`}>
                    <Input type="select"
                        multiSelect={true}
                        label={state.viewTeams ? 'Teams' : 'Participants'}
                        name={state.viewTeams ? 'teams' : 'participants'}
                        value={state.viewTeams ? state.teams : state.participants}
                        options={teamParticipantOptions}
                        placeholder={state.viewTeams ? 'Select teams' : 'Select participants'}
                        onChange={this._onChangeFilter.bind(this)} />
                </div>
                {false && <div className={`col-xs-12 col-sm-${colSize}`}>
                    <Input type="select"
                        label="Modules"
                        name="moduleId"
                        value={state.moduleId}
                        options={moduleSelectionOptions}
                        placeholder="Select a module"
                        onChange={this._onChangeFilter.bind(this)} />
                </div>}
            </div>

            {course.scoringTags.length > 0 && <Line data={graphData} legend={graphLegendOptions} options={graphOptions} />}

            {(state.formStaticId && false) && <div>
                <h4>Form answers <small> - Number of answers: {formAnswers.logs.length}</small></h4>
                <Form.Answers {...formAnswers} />
            </div>}
        </div>
    }

    _onChangeFilter(name, value) {
        let state = this.state
        state[name] = value
        this.setState(state)
    }
}


// {
//     label: 'My First dataset',
//     fill: false,
//     lineTension: 0.1,
//     backgroundColor: 'rgba(75,192,192,0.4)',
//     borderColor: 'rgba(75,192,192,1)',
//     borderCapStyle: 'butt',
//     borderDash: [],
//     borderDashOffset: 0.0,
//     borderJoinStyle: 'miter',
//     pointBorderColor: 'rgba(75,192,192,1)',
//     pointBackgroundColor: '#fff',
//     pointBorderWidth: 1,
//     pointHoverRadius: 5,
//     pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//     pointHoverBorderColor: 'rgba(220,220,220,1)',
//     pointHoverBorderWidth: 2,
//     pointRadius: 1,
//     pointHitRadius: 10,
//     data: [65, 59, 80, 81, 56, 55, 40]
// }