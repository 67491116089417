import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button, FormErrors, Input } from 'alias-components'
import IsValid from 'alias-tools/validation'
import ReduxBinder from 'alias-store/ReduxBinder'

class Login extends Component {

    static propTypes = {
        route: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        let email = props.match.params.email || ''
        email = email.replace(/_at_/gi, '@').replace(/_dot_/gi, '.')

        this.state = {
            email: email, // denis@hashup.io
            password: props.match.params.password || '', // 123
            errorFields: [],
            hasLocalStorage: true
        }
    }

    componentDidMount() {
        this.props.actions.auth.clearMessages()
    }

    componentWillUnmount() {
        this.props.actions.auth.clearMessages()
    }

    render() {
        const { props } = this
        const auth = props.state.auth
        const { email, password } = this.state
        const isDisabled = password === "" || email === ""

        return (
            <form onSubmit={this._login.bind(this)} className="form-horizontal">
                <Helmet title="Login" />
                <fieldset>
                    <legend>LOGIN</legend>
                    {auth.messages.error && (<div className="alert alert-warning"><p>{auth.messages.error}</p></div>)}
                    {auth.messages.success && (<div className="alert alert-success"><p>{auth.messages.success}</p></div>)}
                    <FormErrors fields={this.state.errorFields} />
                    <Input type="email"
                        name="email"
                        label="Your email"
                        placeholder="Ex: john.smith@email.com"
                        value={email}
                        onChange={(name, value) => this.setState({ email: value })}
                        attributes={{ autoFocus: true, disabled: auth.isFetching }} />
                    <Input type="password"
                        name="password"
                        label="Your password"
                        placeholder="Ex: ******"
                        value={password}
                        onChange={(name, value) => this.setState({ password: value })}
                        attributes={{ disabled: auth.isFetching, ref: 'password' }} />
                    <Link to="/auth/forgotten"> Forgot your password?</Link>
                    <br />
                    <br />
                    <Button text="Login"
                        btnClass="btn btn-primary btn-block btn-lg"
                        disabled={isDisabled}
                        isLoading={auth.isFetching} />
                    <p className="help-block text-centered">or <a href="/api/sso/insead/login" style={{ textDecoration: 'underline' }}>Login with INSEAD</a></p>
                    <p className="help-block text-centered" style={{ fontSize: '16px' }}>
                        <br /> Don't have an account?
                        <br />
                        <Link to='/auth/register'> Create an account</Link>
                    </p>
                </fieldset>
            </form>
        )
    }

    _onChange() {
        var state = this.state
        state.email = this.refs.email.value
        state.password = this.refs.password.value
        this.setState(state)
        this.props.actions.auth.clearMessages()
    }

    _isFormValid() {
        const { email, password } = this.state
        let formIsValid = true
        let errorFields = []
        let errors
        // password
        errors = IsValid(password, {
            maxLength: 100,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Password",
                errors: errors
            })
        }
        // Email
        errors = IsValid(email, {
            email: null,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Email",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _login(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (!this._isFormValid()) {
            return false
        }

        this.props.actions.auth.login(email, password, (response) => {
            if (response.status === 200) {
                this.props.history.push('/')
            }
        })
    }
}

export default ReduxBinder(Login, {
    state: ['auth', 'user']
})