import CalendarEvents from './WidgetCalendarEvents'
import CourseInfo from './WidgetCourseInfo'
import ParticipantForms from './WidgetParticipantForms'
import Graph from './WidgetGraph'
import Timeline from './WidgetTimeline'
import Forms from './WidgetForms'

export default {
    CalendarEvents,
    CourseInfo,
    ParticipantForms,
    Graph,
    Timeline,
    Forms
}