import Display from './FormDisplay'
import Modal from './FormModal'
import Results from './FormResults'
import Card from './FormCard'
import Answers from './FormAnswers'

import Question from './elements/FormQuestion'

export default {
    Display,
    Modal,
    Results,
    Card,
    Answers,
    Question
}