import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input } from 'alias-components'
import api from 'alias-tools/api'

export default class ModalContact extends Component {
    static propTypes = {
        user: PropTypes.object,
        course: PropTypes.object,
        isShown: PropTypes.bool,
        close: PropTypes.func

    }
    static defaultProps = {
        isShown: false
    }
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            messageSending: false,
            messageSent: false,
            errorSending: false
        }
    }
    render() {
        const { props, state } = this

        return <Modal
            isShown={props.isShown}
            close={() => props.close()}
            title="Have an issue? Need help?"
            showHeader={true}
            showFooter={true}>
            <div>
                <p>We are happy to help. Let us know what the issue is and we'll get back in touch with you as soon as possible.</p>
                {!state.messageSent && <form onSubmit={this._contact.bind(this)}>
                    {state.errorSending && <p className="alert alert-warn">There was an error sending the message, please try again.</p>}
                    <Input type="textarea"
                        label="Let us know your question or issue here:"
                        placeholder="Enter text here"
                        value={state.message}
                        onChange={(name, value) => this.setState({ message: value })} />
                    <button type="submit" className="btn btn-primary">Send message</button>
                </form>}
                {state.messageSent && <div className="alert alert-success">
                    <p>Your message has been sent. We will get back to you as soon as possible.</p>
                    <p><a onClick={() => this.setState({ messageSent: false })} className="btn btn-primary">Send another message</a></p>
                </div>}
            </div>
        </Modal>
    }

    _contact(e) {
        if (e) { e.preventDefault() }
        let user = JSON.parse(JSON.stringify(this.props.user))
        delete user.participants
        const course = this.props.course
        let message = this.state.message
        if (!message) {
            return alert("Please enter some text.")
        }
        this.setState({ errorSending: false, messageSending: true })
        let content = `
        <p>FROM: ${user ? `${user.first} ${user.last} - ${user.email}` : 'Not found'}</p>
        <p>COURSE: ${course.name} - ${course.id}</p>
        <p>MESSAGE: <br />${message}</p>
        `
        let contentText = `
        FROM: ${user ? `${user.first} ${user.last} - ${user.email}` : 'Not found'}\n
        COURSE: ${course.name} - ${course.id}\n
        \n
        MESSAGE:
        ${message}
        `
        api('/contact', 'POST', {
            from: user,
            message: content,
            messageText: contentText
        })
            .then((res) => {
                this.setState({ errorSending: false, messageSending: false, messageSent: true, message: '' })
            })
            .catch((res) => {
                this.setState({ errorSending: true, messageSending: false })
            })
    }
}
