import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Messaging from '../components/Messaging'

class GameTabMessaging extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    render() {
        const { state, match } = this.props
        const course = state.course.model
        // Get participant
        let userParticipants = course.participants.filter((item) => {
            return !item.isAdmin && item.userz.id === state.user.model.id
        })

        let participant = userParticipants[0]
        if (state.local && state.local.game && state.local.game.participantId) {
            const hisParticipant = userParticipants.filter(part => part.id === state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
            }
        }

        return <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Messaging.View
                type={match.params.type}
                isAdmin={false}
                participant={participant}
                {...this.props} />
        </div>

    }
}

export default ReduxBinder(GameTabMessaging, {
    state: ['course', 'user', 'local']
})