import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Module from '../components/Module'

class GameTabModules extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    render() {

        return <Module.View {...this.props} />

    }
}

export default ReduxBinder(GameTabModules, {
    state: ['course', 'user', 'local']
})