import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Card, Avatar } from 'alias-components'
import { Modal } from 'react-bootstrap'
import Form from '../Form'
import { debug } from 'util';



export default class RolePlaysDisplay extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        participant: PropTypes.object,
        isAdmin: PropTypes.bool,
        activityId: PropTypes.string,
        activity: PropTypes.object,
        isInEditMode: PropTypes.bool,
        onGenerateRolePlays: PropTypes.func,
        selectedRolePlay: PropTypes.string
    }

    static defaultProps = {
        isAdmin: false,
        activityId: null
    }

    constructor(props) {
        super(props)

        this.state = {
            modalIsOpen: false,
            modal: {
                from: null,
                to: null,
                formStaticId: null,
                groupId: null,
                activityId: null
            }
        }
    }

    render() {
        let { state, activityId, participant, actions } = this.props
        const { modal, modalIsOpen } = this.state
        const course = state.course.model
        const userz = state.userz

        const { activities } = course

        let rolePlayActivities = []
        let formActivity = modal.activityId && activities.filter(a => a.id === modal.activityId).length > 0 ? activities.filter(a => a.id === modal.activityId)[0] : null
        const courseStart = moment(course.start).tz(course.timezone)
        rolePlayActivities = activities.filter(activity => {
            if (activityId && activity.id !== activityId) { return false }
            return activity.type === "rolePlay" && activity.data && activity.isDraft === false
        }).map(activity => {
            const parentActivity = course.byStaticId.activities[activity.parentStaticId]
            const module = course.byStaticId.modules[parentActivity.moduleStaticId]
            activity.date = courseStart.clone().add(module.offset || 0, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm')
            return activity
        }).sort((a, b) => {
            return a.date.diff(b.date)
        })

        // Modal form
        let form = modal.formStaticId ? course.byStaticId.forms[modal.formStaticId] : null

        return <div>
            <div className="alert alert-warning">
                Please fill out all the forms in your role play that will appear next to the participants names.
                <br /> Example: <i className="fa fa-list-alt" style={{ marginRight: 5 }} /> Role play scoring
                               </div>
            {rolePlayActivities.map(activity => {
                let isShown = (!activityId || (activityId && activity.id === activityId))
                if (!isShown) { return null }
                return <RolePlayItem key={activity.id}
                    activity={activity}
                    openModal={this._openModal.bind(this)}
                    {...this.props} />
            })}

            <Modal show={modalIsOpen} onHide={this._closeModal.bind(this)}>
                <Modal.Header closeButton>
                    {form && <Modal.Title>
                        {form.name} {formActivity && <small>{formActivity.name}</small>}
                    </Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {form && <Form.Display form={form}
                        log={null}
                        userz={userz}
                        participantFrom={modal.from}
                        participantTo={modal.to}
                        activityId={modal.activityId}
                        groupId={modal.groupId}
                        course={course}
                        actions={actions}
                        callback={this._closeModal.bind(this)} />}
                </Modal.Body>
            </Modal>

        </div>
    }

    _openModal(fromId, toId, formStaticId, groupId, activityId) {
        const course = this.props.state.course.model
        this.setState({
            modalIsOpen: true,
            modal: {
                from: course.byId.participants[fromId],
                to: course.byId.participants[toId],
                formStaticId: formStaticId,
                groupId: groupId,
                activityId: activityId
            }
        })
    }
    _closeModal() {
        this.setState({
            modalIsOpen: false,
            modal: {
                from: null,
                to: null,
                formStaticId: null,
                groupId: null,
                activityId: null
            }
        })
    }
}



const dateFormatForRolePlay = "ddd Do HH:mm (MMM YYYY)"

class RolePlayItem extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        participant: PropTypes.object,
        isAdmin: PropTypes.bool,
        activity: PropTypes.object,
        isShown: PropTypes.bool,
        openModal: PropTypes.func
    }
    static defaultProps = {
        isInEditMode: false,
        isShown: true
    }
    constructor(props) {
        super(props)

        this.state = {
            isShown: props.isShown
        }
    }
    componentDidMount() {
        if (!this.refresh) {
            this.refresh = setInterval(this.forceUpdate.bind(this), 60000)
        }
    }

    componentWillUnmount() {
        if (this.refresh) {
            clearInterval(this.refresh)
        }
    }

    _rolePlayToGroups(rolePlay) {
        let groups = []
        let groupNames = []
        rolePlay.map((rp, i) => {
            let position = groupNames.indexOf(rp.group)
            if (position === -1) {
                let group = {
                    index: i + 1,
                    name: rp.group,
                    location: rp.location,
                    offset: rp.offset,
                    participants: [{
                        partyIndex: rp.partyIndex,
                        participantId: rp.participantId
                    }]
                }
                groupNames.push(rp.group)
                groups.push(group)
            } else {
                groups[position].participants.push({
                    partyIndex: rp.partyIndex,
                    participantId: rp.participantId
                })
            }
        })
        return groups
    }

    _isFormCompleted(formLogs, formStaticId, participantInfo, group) {
        return formLogs.filter(l => l.formStaticId === formStaticId
            && l.fromParticipantId === participantInfo.formData.fromId
            && l.participantId === participantInfo.formData.toId
            && l.data && l.data.groupId === group.name).length > 0
    }

    _groupPrep(groups) {
        const { activity, participant, state, isAdmin } = this.props
        const course = state.course.model
        const formLogs = course.activityLogs.filter(log => log.type === 'form' && log.data.activityId === activity.id)

        const rolePlaySettings = activity.data.settings
        const now = moment().tz(course.timezone)
        const rolePlayStartDate = activity.date

        return groups.map(group => {
            // let groupHasParticipant = false
            group.start = rolePlayStartDate.clone().add(group.offset, 'm')
            group.end = group.start.clone().add(rolePlaySettings.duration, 'm')

            group.formDisplayDate = group.end.clone().subtract(rolePlaySettings.formDisplayOffset, 'm')
            group.showForms = false
            // group.formDisplayDate.isBefore(now) && !isAdmin && ()
            if (!isAdmin && participant) {
                if (group.participants.filter(pI => pI.participantId === participant.id).length > 0) {
                    group.showForms = true
                }
            }
            group.participants = group.participants.map(participantInfo => {
                participantInfo.participant = course.byId.participants[participantInfo.participantId]
                if (!participantInfo.participant) { return null }
                participantInfo.party = rolePlaySettings.parties[participantInfo.partyIndex - 1] || { description: "" }
                if (group.showForms) {
                    if (participant) {
                        participantInfo.formData = {
                            fromId: participant.id,
                            toId: participantInfo.participantId,
                            formStaticId: null,
                            groupId: group.name,
                            activityId: activity.id,
                            form: null,
                            isDone: false
                        }
                        if (participantInfo.participantId === participant.id) {
                            if (rolePlaySettings.globalFormStaticId) {
                                participantInfo.formData.formStaticId = rolePlaySettings.globalFormStaticId
                                participantInfo.formData.form = course.byStaticId.forms[rolePlaySettings.globalFormStaticId]
                            }

                        } else {
                            if (participantInfo.party.formStaticId) {
                                participantInfo.formData.formStaticId = participantInfo.party.formStaticId
                                participantInfo.formData.form = course.byStaticId.forms[participantInfo.party.formStaticId]
                            }
                        }
                        if (participantInfo.formData.formStaticId) {
                            participantInfo.formData.isDone = formLogs.filter(l => l.formStaticId === participantInfo.formData.formStaticId
                                && l.fromParticipantId === participantInfo.formData.fromId
                                && l.participantId === participantInfo.formData.toId
                                && l.data && l.data.groupId === group.name).length > 0
                        }
                    }
                }
                if (participant && participant.id === participantInfo.participantId) {
                    // groupHasParticipant = true
                }
                return participantInfo
            }).filter(p => p !== null)

            return group
            // return isAdmin || (!isAdmin && groupHasParticipant) ? group : null
        })
            .filter(g => g !== null)
            .sort((a, b) => {
                let x = a.offset - b.offset
                if (x !== 0) { return x }
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1 }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1 }
                return 0
            })
    }

    render() {
        const { isShown } = this.state
        const { activity, participant, state, isAdmin, openModal } = this.props
        const course = state.course.model

        if (!activity.data || !activity.data.settings || !activity.data.rolePlay) {
            return null
        }
        const rolePlaySettings = activity.data.settings
        const rolePlay = activity.data.rolePlay
        const rolePlayStartDate = activity.date
        // Preping data for display
        let groups = this._rolePlayToGroups(rolePlay)
        groups = this._groupPrep(groups)

        // Should show forms or not
        // let showForms = (!isAdmin && participant && rolePlay.filter(pI => pI.participantId === participant.id).length > 0)

        if (groups.length === 0) {
            return <div className="alert alert-info">
                <strong>No role play for you found</strong><br />
                <p>It could be that the role play is meant for one of your team mates.</p>
            </div>
        }

        return <div>
            <Card>
                <h2 style={{ margin: 0, padding: 0, cursor: 'pointer' }}>{activity.name} <small>First session will start on {rolePlayStartDate.format(dateFormatForRolePlay)}</small></h2>
                {/*<a onClick={() => this.setState({ isShown: !isShown })} className="btn btn-secondary">{isShown ? "Hide" : "Show"} sessions</a>*/}
            </Card>
            {isShown && <div style={{ paddingLeft: 20, paddingTop: 10, paddingBottom: 0, borderLeft: '3px solid #333', position: 'relative', top: '-20px' }}>
                <p><i className="fa fa-info"></i> For this role play, each session will last <strong>{rolePlaySettings.duration}min{rolePlaySettings.duration > 1 ? 's' : ''}</strong></p>
                {groups.map((group, j) => {
                    return <Card key={j} title={<span>Group {group.name}</span>}>
                        <div className="row">
                            <div className="col-sm-6">
                                <p><strong>Start -> </strong>{group.start.format(dateFormatForRolePlay)}</p>
                                <p><strong>End -> </strong>{group.end.format(dateFormatForRolePlay)}</p>
                            </div>
                            <div className="col-sm-6">
                                <p><strong>Location -> </strong>{group.location}</p>
                            </div>
                        </div>
                        <br />
                        <strong>Participants</strong>
                        <table className="table table-hover table-stripped">
                            <thead>
                                {group.participants.map((participantInfo, k) => {
                                    return <tr key={participantInfo.participantId + k}>
                                        <td width={40}><Avatar user={participantInfo.participant.userz} size={30} /></td>
                                        <td>
                                            {participantInfo.participant.displayName.bigTeamThenName} {(participantInfo.party && course.byStaticId.roles[participantInfo.party.roleStaticId]) && <span> ({course.byStaticId.roles[participantInfo.party.roleStaticId].name})</span>}
                                            {(participantInfo.party.description && (isAdmin || (!isAdmin && participant && participant.id === participantInfo.participant.id))) && <em><br />{participantInfo.party.description}</em>}
                                        </td>
                                        <td>
                                            {(group.showForms && participantInfo.formData && participantInfo.formData.formStaticId) && <span>
                                                {participantInfo.formData.isDone ? <s>{participantInfo.formData.form.name}</s> : <a onClick={() => openModal(participantInfo.formData.fromId, participantInfo.formData.toId, participantInfo.formData.formStaticId, participantInfo.formData.groupId, participantInfo.formData.activityId)}><i className="fa fa-list-alt" style={{ marginRight: 5 }} />{participantInfo.formData.form.name}</a>}
                                            </span>}
                                        </td>
                                    </tr>
                                })}
                            </thead>
                        </table>

                    </Card>
                })}
            </div>}
        </div>
    }
}