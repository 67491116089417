import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar } from 'alias-components'
import Fuse from 'fuse.js'

export default class MessagingParticipantList extends Component {
    static propTypes = { //eslint-disable-line
        course: PropTypes.object,
        selectParticipant: PropTypes.func,
        participantId: PropTypes.string,
        admin: PropTypes.object,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            showNumberOfParticipants: 20
        }
    }
    render() {
        const { search, showNumberOfParticipants } = this.state
        const { course, selectParticipant, participantId, admin } = this.props

        let participants = course.participants.filter(p => !p.isAdmin)
        if (search) {
            let options = {
                keys: [
                    {
                        name: 'userz.first',
                        weight: .6
                    },
                    {
                        name: 'userz.last',
                        weight: .6
                    },
                    {
                        name: 'userz.email',
                        weight: .6
                    },
                    {
                        name: 'teamName',
                        weight: .8
                    },
                    {
                        name: 'roleNames',
                        weight: .8
                    }
                ],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.2
            }
            let fuse = new Fuse(participants, options)
            participants = fuse.search(search)
        }
        participants = participants.sort(function (a, b) {
            return a.displayName.withEmail.localeCompare(b.displayName.withEmail)
        })

        const totalNumberOfParticipants = participants.length
        let showShowMoreBtn = false
        if (totalNumberOfParticipants > showNumberOfParticipants) {
            participants = participants.slice(0, showNumberOfParticipants)
            showShowMoreBtn = true
        }
        let adminParticipant = null
        if (admin) {
            const adminParticipants = course.participants.filter(p => p.id === admin.id)
            if (adminParticipants.length > 0) {
                adminParticipant = adminParticipants[0]
            }
        }
        return <div className="column participants">
            <div className="header">
                <Link to={`/course/instructor/${course.id}`} className="left-link" aria-label="Back"><i className="fa fa-chevron-left" /></Link>
                Participants
            </div>
            <div className="list-wrapper">
                <form onSubmit={(e) => e.preventDefault()} className="search">
                    <button type="submit" aria-label="Search"><i className="fa fa-search" /></button>
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => this.setState({ search: e.target.value })}
                        placeholder="Search..." />
                </form>
                <div className="list">
                    {adminParticipant && <ParticipantItem selectedParticipantId={participantId}
                        participant={adminParticipant}
                        selectParticipant={selectParticipant}
                        actions={this.props.actions} />}
                    {participants.length === 0 && (
                        <div className="alert alert-info" style={{ margin: '0px' }}>
                            <strong>No participants</strong>
                        </div>
                    )}
                    {participants.map(participant => {
                        return <ParticipantItem key={`chat-conv-${participant.id}`}
                            selectParticipant={selectParticipant}
                            selectedParticipantId={participantId}
                            participant={participant}
                            actions={this.props.actions} />
                        // return <div
                        //     className={participantId == participant.id ? 'section active' : 'section'}
                        //     key={`chat-conv-${participant.id}`}
                        //     onClick={() => this._onClickParticipant(participant)}>
                        //     <div className="image">
                        //         <Avatar user={participant.userz} size={60} />
                        //     </div>
                        //     <div className="name">
                        //         <strong>{participant.userz.first} {participant.userz.last}</strong>
                        //         <p>
                        //             {participant.teamName ? ` (${participant.teamName}) ` : ''}{participant.roleNames.join(', ')}
                        //         </p>
                        //     </div>
                        // </div>
                    })}
                    {showShowMoreBtn && (
                        <div style={{ padding: '10px' }}>
                            <a onClick={() => this.setState({ showNumberOfParticipants: showNumberOfParticipants + 20 })} className="btn btn-secondary btn-block">Show more</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    }

    _onClickParticipant(participant) {
        this.props.selectParticipant(participant)
    }
}

class ParticipantItem extends Component {
    static propTypes = {
        selectedParticipantId: PropTypes.string,
        participant: PropTypes.object,
        selectParticipant: PropTypes.func,
        actions: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            hasError: false
        }
    }
    render() {
        const { props, state } = this

        return <div
            className={props.selectedParticipantId == props.participant.id ? 'section active' : 'section'}
            onClick={() => this._onClickParticipant()}>
            <div className="image">
                <Avatar user={props.participant.userz} size={60} />
                {state.isLoading && <i className="fa fa-spinner fa-pulse" style={{ position: 'absolute', top: 18, left: 15, fontSize: 36, color: 'white' }} />}
            </div>
            <div className="name">
                <strong>{props.participant.userz.first} {props.participant.userz.last}</strong>
                <p>
                    {props.participant.teamName ? ` (${props.participant.teamName}) ` : ''}{props.participant.roleNames.join(', ')}
                </p>
            </div>
            {state.hasError && <div className="error">Error: Read logs <a onClick={() => this._onClickParticipant()}><i className="fa fa-refresh" /></a></div>}
        </div>
    }

    _onClickParticipant() {
        const { props } = this
        props.selectParticipant(props.participant)
        if (!props.participant.hasFetchedReadActivity) {
            this.setState({ isLoading: true, hasError: false })
            props.actions.activityLog.fetchParticipantReadActivity(props.participant, (res) => {
                if (!res.success) {
                    this.setState({ isLoading: false, hasError: true })
                } else {
                    this.setState({ isLoading: false, hasError: false })
                }
            })
        }
    }
}