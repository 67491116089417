import Activity from './Activity'
import Character from './Character'
import Course from './Course'
import Dashboard from './Dashboard'
import Form from './Form'
import Organization from './Organization'
import Participant from './Participant'
import Role from './Role'
import Scoring from './Scoring'
import Team from './Team'
import Variable from './Variable'
import Value from './Value'
import Widgets from './Widgets'
import Log from './Log'
import Messaging from './Messaging'
import Badge from './Badge'
import Module from './Module'
import Doc from './Doc'
import Forum from './Forum'
import Random from './Random'

export default {
    Activity,
    Character,
    Course,
    Dashboard,
    Form,
    Organization,
    Participant,
    Role,
    Scoring,
    Team,
    Variable,
    Widgets,
    Log,
    Messaging,
    Badge,
    Module,
    Doc,
    Value,
    Forum,
    Random
}