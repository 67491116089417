import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Error } from 'alias-components'

export default class PublicError extends Component {
    static propTypes = {
        match: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.intervalId = window.setInterval(() => {
            window.location.href = '/'
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    render() {
        const { match } = this.props
        const errorId = match.params.errorId || 'general'
        return (
            <Error errorId={errorId} />
        )
    }
}
