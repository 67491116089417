import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Avatar, Card, VideoPlayer } from 'alias-components'
import scripts from 'alias-tools/scripts'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const lineFormat = 'HH:mm'
const CONTENT_WRAPPER_STYLE = { maxWidth: '100%', overflow: 'hidden', width: '100%' }

export default class WidgetTimeline extends Component {
    static propTypes = {
        course: PropTypes.object,
        moduleStaticId: PropTypes.string,
        isAdmin: PropTypes.bool,
        search: PropTypes.string,
        roleStaticId: PropTypes.string,
        activityType: PropTypes.string,
        show: PropTypes.oneOf(['all', 'passed', 'upcoming'])
    }
    static defaultProps = {
        isAdmin: false,
        moduleStaticId: null
    }

    render() {
        const { props } = this
        const { course, moduleStaticId, roleStaticId, activityType, search } = props

        const courseStart = moment(course.start).tz(course.timezone)
        let calendarEvents = []
        const now = moment()

        if (!moduleStaticId && !roleStaticId && !activityType && !search) {
            calendarEvents.push({
                type: 'course',
                date: courseStart
            })
        }

        course.modules.map(module => {
            calendarEvents.push({
                type: 'module',
                date: courseStart.clone().add(module.offset || 0, 'm').add(1, 's'),
                isDraft: module.isDraft,
                module: module
            })
        })

        course.activities.map(activity => {
            if (activity.type === 'triggered') { return }
            if (!activity.parentStaticId) { return }
            let parentActivity = course.byStaticId.activities[activity.parentStaticId]
            if (!parentActivity) { return }
            let module = course.byStaticId.modules[parentActivity.moduleStaticId]
            if (!module) { return }

            // Search
            if (roleStaticId && roleStaticId !== activity.roleStaticId) { return }
            if (activityType && activityType !== activity.type) { return }

            let activityDate = courseStart.clone().add(module.offset || 0, 'm').add(parentActivity.offset, 'm').add(activity.offset, 'm').add(2, 's')
            let isDraft = module.isDraft ? true : activity.isDraft

            if (!isDraft) {
                calendarEvents.push({
                    type: activity.type,
                    date: activityDate,
                    activity: activity,
                    parentActivity: parentActivity,
                    module: module,
                    isDraft: module.isDraft ? true : activity.isDraft
                })
            }
        })

        if (props.show !== 'all') {
            calendarEvents = calendarEvents.filter(evt => {
                if (props.show === 'passed') {
                    return evt.date.isBefore(now)
                }
                if (props.show === 'upcoming') {
                    return evt.date.isAfter(now)
                }
            })
        }

        let calendarGroups = {}
        calendarEvents = calendarEvents.sort((a, b) => a.date.diff(b.date)).map(event => {
            let key = event.date.dayOfYear() + "-" + event.date.year()
            if (!calendarGroups[key]) {
                calendarGroups[key] = {
                    day: event.date.format("ddd Do, MMM YYYY"),
                    date: event.date,
                    activities: []
                }
            }
            calendarGroups[key].activities.push(event)
        })

        if (Object.keys(calendarGroups).length === 0) { return null }

        return <div>
            {Object.keys(calendarGroups).map(key => {
                let group = calendarGroups[key]

                return <Day group={group}
                    key={key}
                    search={search}
                    course={course} />
            })}
        </div>
    }
}

class Day extends Component {
    static propTypes = {
        group: PropTypes.object,
        calendarGroups: PropTypes.object,
        index: PropTypes.string,
        search: PropTypes.string,
        course: PropTypes.object
    }
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: true
        }
    }
    render() {
        const { props, state } = this
        const { group, search, course } = props
        return <Card>
            <h3>
                {group.day} ({group.activities.length})
                <a className="pull-right btn btn-secondary" onClick={() => this.setState({ isExpanded: !state.isExpanded })}>{state.isExpanded ? 'Minimize' : 'Expand'}</a>
            </h3>
            {state.isExpanded && <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: 60 }}>Time</th>
                        <th style={{ width: 60 }}>Type</th>
                        <th style={{ width: 60 }}>From</th>
                        <th style={{ width: 120 }}>To</th>
                        <th>Content</th>
                    </tr>
                </thead>
                <tbody>
                    {group.activities.sort((a, b) => a['date'].diff(b['date'])).map((event, i) => {
                        let content = <tr key={i}>
                            <td>Time</td>
                            <td>Type</td>
                            <td>From</td>
                            <td>To</td>
                            <td>Content</td>
                        </tr>

                        const args = {
                            event: event,
                            key: i,
                            search: search,
                            course: course
                        }

                        switch (event.type) {
                            case 'course':
                                content = <EventCourse {...args} />
                                break
                            case 'module':
                                content = <EventModule {...args} />
                                break
                            case 'calendar':
                                content = <EventActivityTypeCalendar {...args} />
                                break
                            case 'rolePlay':
                                content = <EventActivityTypeRolePlay {...args} />
                                break
                            case 'email':
                                content = <EventActivityTypeEmail {...args} />
                                break
                            case 'chat':
                                content = <EventActivityTypeChat {...args} />
                                break
                            case 'forum':
                                content = <EventForum {...args} />
                                break
                            default:
                                console.log(event, content) // eslint-disable-line
                        }
                        return content
                    })}
                </tbody>
            </table>}

        </Card>
    }
}

class EventForum extends Component {
    static propTypes = {
        event: PropTypes.object,
        course: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            isExpanded: false
        }
    }
    render() {
        const { props, state } = this
        const { event, course } = props

        let emailFrom = <Avatar />
        if (course.byStaticId.characters[event.activity.characterStaticId]) {
            emailFrom = <Avatar user={course.byStaticId.characters[event.activity.characterStaticId]} tooltip={true} />
        }

        let showShowMore = false
        const trimLength = 80
        let message = event.activity.data.text
        if (!state.isExpanded && message.length > trimLength) {
            showShowMore = true
            message = message.slice(0, trimLength) + "..."
        }
        event.activity.data = event.activity.data || { text: '', title: '' }

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.activity.id}`}>Type: Forum activity</Tooltip>}>
                    <i className="fa fa-th-list fa-fw"></i>
                </OverlayTrigger>
            </td>
            <td>{emailFrom}</td>
            <td></td>
            <td>
                <div style={CONTENT_WRAPPER_STYLE}>
                    <p><strong>{event.activity.data.title}</strong></p>
                    {message.split('\n').map((l, i) => <p key={i}>{l}</p>)} {showShowMore && <a onClick={() => this.setState({ isExpanded: true })}>Show more <i className="fa fa-chevron-down" /></a>}
                    {(event.activity.data && event.activity.data.video) && <VideoPlayer url={event.activity.data.video} />}
                </div>
            </td>
        </tr>
    }
}

class EventActivityTypeEmail extends Component {
    static propTypes = {
        event: PropTypes.object,
        course: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            isExpanded: true
        }
    }
    render() {
        const { state } = this
        const { event, course } = this.props
        const { activity } = event

        let emailFrom = <Avatar />
        if (course.byStaticId.characters[activity.characterStaticId]) {
            emailFrom = <Avatar user={course.byStaticId.characters[activity.characterStaticId]} tooltip={true} />
        }

        let emailTo = "Not found"
        if (course.byStaticId.organizations[activity.roleStaticId]) {
            emailTo = course.byStaticId.organizations[activity.roleStaticId].name
        }
        if (course.byStaticId.roles[activity.roleStaticId]) {
            emailTo = course.byStaticId.roles[activity.roleStaticId].name
        }
        if (course.byId.teams[activity.roleStaticId]) {
            emailTo = course.byId.teams[activity.roleStaticId].name
        }
        if (activity.roleStaticId === 'all') {
            emailTo = "All"
        }

        const docs = course.docs.filter(doc => doc.activityStaticId === activity.staticId)

        let showShowMore = false
        const trimLength = 80
        let message = activity.body
        if (!state.isExpanded && activity.body.length > trimLength) {
            showShowMore = true
            message = activity.body.slice(0, trimLength)
        }

        // embeddedFormStaticId
        // formStaticId

        let embeddedForm = null
        if (activity.embeddedFormStaticId && course.byStaticId.forms[activity.embeddedFormStaticId]) {
            embeddedForm = course.byStaticId.forms[activity.embeddedFormStaticId]
        }
        let conversationalForm = null
        if (activity.formStaticId && course.byStaticId.forms[activity.formStaticId]) {
            conversationalForm = course.byStaticId.forms[activity.formStaticId]
        }

        let badge = null
        if (activity.badgeStaticId && course.byStaticId.badges[activity.badgeStaticId]) {
            badge = course.byStaticId.badges[activity.badgeStaticId]
        }

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.activity.id}`}>Type: Email activity</Tooltip>}>
                    <i className="fa fa-envelope" />
                </OverlayTrigger>
            </td>
            <td>{emailFrom}</td>
            <td>{emailTo}</td>
            <td>
                <div style={CONTENT_WRAPPER_STYLE}>
                    <p><strong>{activity.subject} ({event.module.name})</strong></p>
                    {message.split('\n').map((l, i) => <p key={i}>{l}</p>)} {showShowMore && <a onClick={() => this.setState({ isExpanded: true })}>Show more <i className="fa fa-chevron-down" /></a>}
                    {!showShowMore && activity.data && activity.data.videos && activity.data.videos.map((videoURL, i) => {
                        let video = scripts.parseVideo(videoURL)
                        if (video.type && video.id) {
                            if (video.type === 'youtube') {
                                return (
                                    <div className="videoWrapper"
                                        key={i}
                                        style={{ marginBottom: '10px' }}>
                                        <iframe src={`https://www.youtube.com/embed/${video.id}`}
                                            frameBorder="0"
                                            allowFullScreen></iframe>
                                    </div>
                                )
                            }
                            if (video.type === 'vimeo') {
                                return (
                                    <div className="videoWrapper"
                                        key={i}
                                        style={{ marginBottom: '10px' }}>
                                        <iframe src={`https://player.vimeo.com/video/${video.id}?badge=0`}
                                            frameBorder="0"
                                            allowFullScreen></iframe>
                                    </div>
                                )
                            }
                        }
                    })}
                    {!showShowMore && activity.data && activity.data.uploadedVideos && activity.data.uploadedVideos.map((videoURL, i) => {
                        return <VideoPlayer url={videoURL} key={`uploadedVideos-${i}`} />
                    })}
                    {(!showShowMore && docs.length > 0) && <ul className="list-unstyled">
                        {docs.map(doc => <li key={doc.id}>
                            <a href={doc.path} target="_blank"><i className="fa fa-file" style={{ marginRight: '5px' }} />{doc.name}</a>
                        </li>)}
                    </ul>}
                    {(!showShowMore && conversationalForm) && <p><strong>Conversational form</strong>: {conversationalForm.name}</p>}
                    {(!showShowMore && embeddedForm) && <p><strong>Embedded form</strong>: {embeddedForm.name}</p>}
                    {(!showShowMore && badge) && <p><strong>Badge</strong>: <span className="badge info">{badge.name}</span></p>}
                </div>
            </td>
        </tr>
    }
}


class EventActivityTypeChat extends Component {
    static propTypes = {
        event: PropTypes.object,
        course: PropTypes.object
    }
    render() {
        const { event, course } = this.props
        const { activity } = event

        let emailFrom = <Avatar />
        if (course.byStaticId.characters[activity.characterStaticId]) {
            emailFrom = <Avatar user={course.byStaticId.characters[activity.characterStaticId]} tooltip={true} />
        }

        let emailTo = "Not found"
        if (course.byStaticId.organizations[activity.roleStaticId]) {
            emailTo = course.byStaticId.organizations[activity.roleStaticId].name
        }
        if (course.byStaticId.roles[activity.roleStaticId]) {
            emailTo = course.byStaticId.roles[activity.roleStaticId].name
        }
        if (course.byId.teams[activity.roleStaticId]) {
            emailTo = course.byId.teams[activity.roleStaticId].name
        }

        let formName = "Not found"
        if (course.byStaticId.forms[activity.formStaticId]) {
            formName = course.byStaticId.forms[activity.formStaticId].name
        }

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.activity.id}`}>Type: Chat activity</Tooltip>}>
                    <i className="fa fa-comments" />
                </OverlayTrigger>
            </td>
            <td>{emailFrom}</td>
            <td>{emailTo}</td>
            <td><strong>Form: </strong>{formName}</td>
        </tr>
    }
}


class EventActivityTypeCalendar extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props
        const { activity } = event
        const { location, duration, description } = activity.data

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.activity.id}`}>Type: Calendar activity</Tooltip>}>
                    <i className="fa fa-calendar" />
                </OverlayTrigger>
            </td>
            <td></td>
            <td></td>
            <td>
                <div style={CONTENT_WRAPPER_STYLE}>
                    <strong>Calendar Event:</strong> {event.text}
                    {(location || duration || description) && <ul style={{ paddingLeft: '40px', paddingTop: 0 }}>
                        {duration && <li>Duration: {duration}min(s)</li>}
                        {location && <li>Location: {location}</li>}
                        {description && <li>Description: {description}</li>}
                    </ul>}
                </div>
            </td>
        </tr>
    }
}



class EventActivityTypeRolePlay extends Component {
    static propTypes = {
        event: PropTypes.object,
        isAdmin: PropTypes.bool
    }
    static defaultProps = {
        isAdmin: false
    }
    render() {
        const { event, isAdmin } = this.props

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.activity.id}`}>Type: Role play activity</Tooltip>}>
                    <i className="fa fa-users" />
                </OverlayTrigger>
            </td>
            <td></td>
            <td></td>
            <td>
                <div style={CONTENT_WRAPPER_STYLE}>
                    <strong><i className="fa fa-users" /> Role play: </strong> {event.activity.name}<br />
                    {event.text} <Link to={`/course/${isAdmin ? 'instructor' : 'my'}/${event.activity.courseId}/role-plays/${event.activity.id}`} className="" style={{ marginLeft: 10 }}>View schedule & participation <i className="fa fa-chevron-right"></i></Link>
                </div>
            </td>
        </tr>
    }
}

class EventModule extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${event.module.id}`}>Type: Module start</Tooltip>}>
                    <i className="fa fa-calendar" />
                </OverlayTrigger>
            </td>
            <td></td>
            <td></td>
            <td><strong>Start of module: </strong>{event.module.name}</td>
        </tr>
    }
}

class EventCourse extends Component {
    static propTypes = {
        event: PropTypes.object
    }
    render() {
        const { event } = this.props

        return <tr className={event.isDraft ? 'warning' : ''}>
            <td>{event.date.format(lineFormat)}</td>
            <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-course-start`}>Type: Case start</Tooltip>}>
                    <i className="fa fa-calendar" />
                </OverlayTrigger>
            </td>
            <td></td>
            <td></td>
            <td><strong>Start of course</strong></td>
        </tr>
    }
}