import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import Form from '../../components/Form'
import ReduxBinder from 'alias-store/ReduxBinder'
import Fuse from 'fuse.js'

class CourseConfigurationTabForms extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            search: '',
            showCount: 30
        }
    }

    render() {
        const { state } = this
        const course = this.props.state.course.model
        const { formId } = this.props.match.params
        let forms = course.forms


        if (state.search !== '') {
            let options = {
                keys: [{
                    name: 'name',
                    weight: .6
                }, {
                    name: 'questions',
                    weight: 0.4
                }],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.4
            }
            let fuse = new Fuse(forms, options)
            forms = fuse.search(state.search)
            // let search = this.state.search.toLowerCase();
            // forms = forms.filter((form) => {
            //     return form.name.toLowerCase().indexOf(search) !== -1
            // })
        }
        const totalNumberOfForms = forms.length

        forms = forms.slice(0, state.showCount)

        return (
            <div>
                <Helmet title={course.name + " forms"} />
                <div className="page-header">
                    <Search
                        search={state.search}
                        onChange={(search) => {
                            this.setState({ search: search })
                        }} />
                    <a onClick={this._openModal.bind(this, 'new')}
                        className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add form</a>
                </div>
                {state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}>
                        <i className="fa fa-info" style={{ marginRight: '5px' }} />
                        Forms are used in emails, quizzes and/or Role plays.
                    </p>
                )}
                {state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}>
                        <em>Showing results for
                            <strong>{this.state.search}</strong>
                        </em>
                        <a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a>
                    </p>
                )}
                {forms.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No forms</strong>
                        {this.state.search === '' && (
                            <p>
                                Click the "+ New form" button on the top right.
                            </p>
                        )}
                    </div>
                )}
                {forms.map((form, i) => {
                    return <Form.Card form={form} key={form.id}
                        onEditClick={this._openModal.bind(this, form.id)}>
                        <p className="help-block">Activities: {course.activities.filter(act => act.formStaticId === form.staticId || act.embeddedFormStaticId === form.staticId).length}</p>
                    </Form.Card>
                })}

                {state.showCount < totalNumberOfForms && (
                    <div style={{ textAlign: 'center', margin: '20px 0 40px' }}>
                        <a onClick={() => {
                            this.setState({
                                showCount: state.showCount + 30
                            })
                        }}
                        className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
                        &nbsp;of {totalNumberOfForms - state.showCount} left
                    </div>
                )}
            </div>
        )
    }

    _openModal(formId) {
        this.props.actions.local.modal('form', formId)
        // const course = this.props.state.course.model;
        // this.props.history.push(`/course/configuration/${course.id}/forms/${formId === null ? 'new' : formId}`)
    }
}

export default ReduxBinder(CourseConfigurationTabForms, {
    state: ['course', 'form']
})