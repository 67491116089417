import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'

export default class ScoringCard extends Component {
    static propTypes = {
        scoringTag: PropTypes.any,
        onEditClick: PropTypes.func,
        children: PropTypes.any
    }
    render() {
        const { scoringTag, onEditClick, children } = this.props;
        return (
            <Card title={`${scoringTag.name}`}>
                <div>

                    <p className="help-block">{scoringTag.description ? scoringTag.description : 'No description'}</p>
                    {children}
                    {onEditClick && <a onClick={onEditClick} className="btn btn-secondary">Edit</a>}
                </div>
            </Card>
        )
    }
}