import api from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'

const BASE_PATH = '/participants'
//
// Action types
//
const CLEAR = 'si/participant/CLEAR'
const SAVING = 'si/participant/SAVING'
const SAVED = 'si/participant/SAVED'
const REMOVE = 'si/participant/REMOVE'
const REMOVE_ALL = 'si/participant/REMOVE_ALL'
const ERROR = 'si/participant/ERROR'

const initialState = {
    isSaving: false, // When it's getting data from the server (usually to show a loader)
    messages: {
        error: null, // String
        success: null // String
    },
    model: structure.participant
}

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case REMOVE_ALL:
        case SAVED:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case REMOVE:
            return { ...initialState }
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isSaving: false
            }
        default:
            return state
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return { type: CLEAR }
}

export function create(participants, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING })
        // Add default data
        var state = getState()
        participants = participants.map(participant => {
            delete participant.id
            return participant
        })
        api('/courses/userz/add', 'POST', {
            participants: participants
        }, state, {}, dispatch) //
            .then(function (response) {
                if (callback) {
                    callback(response.data)
                }
                let newParticipants = response.data.map(participant => {
                    if (participant.userzId == state.user.model.id) {
                        participant.course = state.course.model
                    }
                    return participant
                })

                newParticipants.map(function (newParticipant) {
                    if (newParticipant) {
                        dispatch({ type: SAVED, model: newParticipant })
                    }
                })
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function update(model, callback) {
    return (dispatch, getState) => {
        // dispatch({ type: SAVING, model: model })
        // Add default data
        var state = getState()
        let modelToUpdate = Object.assign({}, model)
        delete modelToUpdate.course
        // delete modelToUpdate.userz
        api(BASE_PATH + '/' + model.id, 'PATCH', modelToUpdate, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: SAVED, model: response.data })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function remove(participant, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING })
        // Add default data
        var state = getState()
        api(BASE_PATH + '/' + participant.id, 'DELETE', {}, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: REMOVE, model: participant })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function removeAllParticipants(courseId, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING })
        // Add default data
        var state = getState()
        api(BASE_PATH + '/' + courseId + "/course", 'DELETE', {}, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: REMOVE_ALL })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function liveAdd(model) {
    return { type: SAVED, model: model }
}
export function liveUpdate(model) {
    return { type: SAVED, model: model }
}
export function liveRemove(model) {
    return { type: REMOVE, model: model }
}