import api from '../../tools/api'
import errorHandling from '../../tools/errorHandling'

const BASE_PATH = '/control';
//
// Action types
//
const CLEAR = 'si/control/CLEAR';
const SAVING = 'si/control/SAVING';
const FETCHING = 'si/control/FETCHING';
const SAVED = 'si/control/SAVED';
const ERROR = 'si/control/ERROR';
const USER_UPDATE = 'si/control/USER_UPDATE'

const initialState = {
    isSaving: false,
    isFetching: false,
    isFetched: false,
    messages: {
        error: null, // String
        success: null // String
    },
    courses: [],
    users: []
};

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case FETCHING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false,
                isFetching: true
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true,
                isFetching: false
            }
        case SAVED:
            return {
                ...state,
                ...action.data,
                messages: initialState.messages,
                isSaving: false,
                isFetching: false
            }
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isSaving: false,
                isFetching: false
            }
        case USER_UPDATE:
            return {
                ...state,
                ...state.users.map(u => {
                    return u.id === action.user.id ? action.user : u
                }),
                messages: initialState.messages,
                isSaving: false,
                isFetching: false
            }
        default:
            return state;
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return {
        type: CLEAR
    }
}

export function getData(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: SAVING
        })
        api(`${BASE_PATH}/init`, 'GET', {}, getState(), {}, dispatch) //
            .then(function (response) {
                if (response.data && response.data.success) {
                    dispatch({
                        type: SAVED,
                        data: {
                            courses: response.data.data.courses,
                            users: response.data.data.users
                        }
                    })
                }

                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            })
    }
}

export function updateUser(user, callback) {
    return (dispatch, getState) => {
        dispatch({
            type: SAVING
        })
        let state = getState()
        api(`${BASE_PATH}/userz`, 'PUT', user, state, {}, dispatch) //
            .then(function (response) {
                dispatch({
                    type: USER_UPDATE,
                    user: response.data
                })

                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response);
            })
    }
}