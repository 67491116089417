/* globals module */
module.exports = {
    dev: {
        showApiQueries: false,
        showApiQueryResults: false
    },
    admins: ['denis@hashup.io', 'adrian@seriesimpact.com', 'tony@seriesimpact.com', 'adrian@livecase.com', 'tony@livecase.com'],
    api: {
        host: '',
        port: '',
        prefix: '/api',  // Ex: /api
        ssl: false,
        timeout: 2 * 60 * 1000,
        socketIOhost: 'http://localhost:5000'
    },
    analytics: false,

    app: {
        time: {
            databaseFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
            dateFormat: "dddd, MMMM Do YYYY",
            dateShortFormat: "MMM Do",
            timeFormat: "H:mm",
            dateTimeFormat: "ddd, MMM Do YYYY H:mm",
            dateTimeShortFormat: "MMM Do H:mm",
            activityDateTimeFormat: "ddd H:mm (MMM Do YY)"
        },
        questionTypes: [
            {
                label: 'Multiple choice',
                value: 'multiple-choice'
            },
            {
                label: 'Text',
                value: 'text'
            },
            {
                label: 'Number',
                value: 'number'
            },
            {
                label: 'Range',
                value: 'range'
            },
            {
                label: 'Custom scoring',
                value: 'scoring'
            },
            {
                label: 'Document upload',
                value: 'doc'
            }
        ],
        weightOptions: [
            {
                label: '0',
                value: 0
            },
            {
                label: '1/64 ~ 1.5%',
                value: 0.015625
            },
            {
                label: '1/32 ~ 3%',
                value: 0.03125
            },
            {
                label: '1/16 ~ 6%',
                value: 0.0625
            },
            {
                label: '1/8 ~ 12.5%',
                value: 0.125
            },
            {
                label: '1/4 ~ 25%',
                value: 0.25
            },
            {
                label: '1/2 ~ 50%',
                value: 0.5
            },
            ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(weight => {
                return {
                    label: weight + ' ~ ' + weight + '00%',
                    value: weight
                }
            })
        ]
    },
    metaData: {
        title: "Live CASE",
        titleTemplate: "%s | Live CASE",
        meta: [
            {
                "name": "description",
                "content": "Education through simulation"
            },
            {
                "name": "theme-color",
                "content": "#DC9600"
            }
        ]
        // link: [
        //     // {
        //     //     "rel": "apple-touch-icon",
        //     //     "sizes": "180x180",
        //     //     "href": require('./src/assets/favicons/apple-touch-icon-180x180.png')
        //     // },
        //     {
        //         "rel": "icon",
        //         "sizes": "32x32",
        //         "type": "image/png",
        //         "href": require('./src/assets/favicons/favicon-32x32.png')
        //     },
        //     {
        //         "rel": "icon",
        //         "sizes": "16x16",
        //         "type": "image/png",
        //         "href": require('./src/assets/favicons/favicon-16x16.png')
        //     },
        //     {
        //         "rel": "mask-icon",
        //         "href": require('./src/assets/favicons/safari-pinned-tab.svg'),
        //         "color": "#DC9600"
        //     }
        // ]
    }
}
