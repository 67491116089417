import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import scripts from 'alias-tools/scripts'

export default class ValueCard extends Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        onEditClick: PropTypes.func,
        children: PropTypes.any
    }
    render() {
        const { value, onEditClick } = this.props;
        return (
            <Card title={value.name}>
                <div>
                    <p className="help-block">{value.description ? value.description : 'No description'}</p>
                    <div className="row">
                        <div className="col-md-6">
                            <strong>Base value</strong> {scripts.numberFormatting(value.base)} {value.unit}
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                    {this.props.children}
                    {onEditClick && <a onClick={onEditClick} className="btn btn-secondary">Edit</a>}
                </div>
            </Card>
        )
    }
}