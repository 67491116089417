import structures from 'alias-store/structures'
import moment from 'moment-timezone'
import scripts from 'alias-tools/scripts'


const MessagingTypes = {
    EMAIL: 'email',
    CHAT: 'chat'
}

const defaultMessage = {
    message: '',
    created: null,
    participantId: null,
    type: 'message' // or question or answer
}

export default {

    sanity() {
        return 'hello'
    },

    getThreadsForParticipant(course, type, participant, isAdmin, activities) {
        // Generation of the threads
        let threads = []
        if (!participant.isAdmin) {
            threads = [
                ...threads,
                ...this.threadsFromActivitiesForParticipant(course, type, participant, activities),
                ...this.threadsFromRolePlaysForParticipant(course, participant),
                ...this.threadsForTriggeredActivities(course, type, participant)
            ]
        }
        if (!isAdmin || (isAdmin && participant.isAdmin)) {
            threads = [
                ...threads,
                ...this.threadsForParticipant(course, type, participant)
            ]
        }
        // Sorting and organizing the threads
        threads = threads.map(thread => {
            thread.messages = scripts.sortArrayObjectMoment(thread.messages, 'created', true)
            thread = this.getLatestActivity(thread, course, type, participant)
            thread.readStatus = null
            thread = this.getUnreadStatus(course, participant, thread, isAdmin)
            thread = this.hasReply(thread, participant, isAdmin)
            return thread
        })
        threads = scripts.sortArrayObjectMoment(threads, 'latest', false)
        return threads
    },

    threadsForParticipant(course, type, participant) {
        let threads = []
        course.threads.filter(thread => {
            if (thread.type !== type) { return }
            if (!thread.participants) { return }
            if (thread.participants.indexOf(participant.id) === -1) { return }

            let activityLogsForThread = course.activityLogs.filter(log => log.threadId === thread.id && log.type === type)
            thread.isOnline = true
            thread.messages = activityLogsForThread.map(log => {
                let message = { ...defaultMessage }
                message.created = moment(log.created).tz(course.timezone)
                message.message = log.data.message
                message.participantId = log.participantId
                message.docs = course.docs.filter(doc => doc.activityStaticId === log.id)
                if (log.data.videoUrl) {
                    message.videoUrl = log.data.videoUrl
                }
                return message
            })
            threads.push(thread)
        })
        return threads
    },

    threadsFromRolePlaysForParticipant(course, participant) {
        let threads = []
        const now = moment()
        let rolePlayActivities = course.activities.filter(activity => {
            if (activity.type !== 'rolePlay') { return false }
            // Base filter
            if (!course.byStaticId.activities[activity.parentStaticId]) { return false }
            if (!course.byStaticId.activities[activity.parentStaticId].moduleStaticId) { return false }
            if (!course.byStaticId.modules[course.byStaticId.activities[activity.parentStaticId].moduleStaticId]) { return false }
            if (course.byStaticId.modules[course.byStaticId.activities[activity.parentStaticId].moduleStaticId].isDraft) { return false }
            if (!activity.characterStaticId) { return false }
            if (!course.byStaticId.characters[activity.characterStaticId]) { return false }
            // Filter for participant
            if (!activity.data.rolePlay || activity.data.rolePlay.length === 0) { return false }
            if (activity.data.rolePlay.filter(rpInfoParticipant => rpInfoParticipant.participantId === participant.id).length === 0) { return false }
            return true
        })

        rolePlayActivities = this.setActivitiesSentTimes(course, rolePlayActivities).filter(a => {
            if (now.isBefore(a.sendTime)) {
                return false
            }
            return true
        })
        rolePlayActivities = scripts.sortArrayObjectMoment(rolePlayActivities, 'sendTime', true)

        rolePlayActivities.map(activity => {
            let activityThread = {
                ...structures.threads,
                type: 'email',
                messages: [],
                id: activity.id + participant.id,
                activityId: activity.id,
                activityStaticId: activity.staticId,
                participants: [participant.id, activity.characterStaticId],
                subject: activity.subject
            }
            let character = course.byStaticId.characters[activity.characterStaticId]
            activityThread.messages = []
            activityThread.messages.push({
                message: this.template(activity.body, character, participant.userz),
                created: activity.sendTime,
                participantId: character.staticId,
                type: 'message',
                videos: (activity.data && activity.data.videos) ? activity.data.videos : [],
                docs: course.docs.filter(doc => doc.activityStaticId === activity.staticId)
            })
            threads.push(activityThread)
        })

        return threads
    },


    threadsForTriggeredActivities(course, type, participant) {
        if (type !== 'email') { return [] }
        let threads = []
        const now = moment().tz(course.timezone)
        const courseStart = moment(course.start).tz(course.timezone)
        let readLogs = course.activityLogs.filter(l => l.type === 'read' && l.participantId === participant.id && l.activityStaticId)

        let triggeredActivities = course.activities
            .filter(a => {
                if (a.isDraft) { return false }
                if (a.type !== 'triggered-email') { return false }
                if (!a.moduleStaticId) { return false }
                if (!course.byStaticId.modules[a.moduleStaticId]) { return false }
                if (course.byStaticId.modules[a.moduleStaticId].isDraft) { return false }
                if (participant.roles.indexOf(a.roleStaticId) === -1) { return false }
                return true
            })

        const modules = course.modules.sort((a, b) => a.offset - b.offset)
        participant.roles.map((roleStaticId, roleIndex) => {
            let previousModuleIsComplete = true
            let previousSentEmailSentTime = null
            for (let moduleIndex in modules) {
                const module = modules[moduleIndex]

                if (module.isDraft) { continue }
                if (!previousModuleIsComplete) { break }
                let moduleStartTime = courseStart.clone().add(module.offset, 'm').add(roleIndex, 's')
                if (now.isBefore(moduleStartTime)) { break }

                let roleModuleActivities = triggeredActivities
                    .filter(a => a.moduleStaticId === module.staticId && a.roleStaticId === roleStaticId)
                    .sort((a, b) => a.order - b.order)

                let lastThreadAdded = false
                roleModuleActivities.map((activity, i) => {
                    if (lastThreadAdded) { return }
                    let activityIsReadLog = readLogs.filter(l => l.activityStaticId === activity.staticId)
                    if (activityIsReadLog.length === 0) {
                        // Didn't finish the module
                        previousModuleIsComplete = false
                        // Still add the message once
                        if (moduleIndex == 0 && i === 0) {
                            activity.sendTime = moduleStartTime.clone().add(moduleIndex * 1 + i, 's')
                            previousSentEmailSentTime = activity.sentTime
                            threads.push(this.fromActivityToEmailThread(course, participant, activity))
                            lastThreadAdded = true
                        }
                        if (!lastThreadAdded) {
                            lastThreadAdded = true
                            if (!previousSentEmailSentTime) {
                                // Means previous module didn't send any triggered emails, so the send time should be module start time
                                activity.sendTime = moduleStartTime.clone().add(moduleIndex * 1 + i, 's')
                            } else {
                                activity.sendTime = previousSentEmailSentTime.clone().add(activity.data.delayOffsetInSeconds, 's')
                            }
                            previousSentEmailSentTime = activity.sendTime
                            threads.push(this.fromActivityToEmailThread(course, participant, activity))
                        }
                        return
                    }
                    // Normal sending
                    if (i === 0 && moduleIndex > 0) {
                        if (!previousSentEmailSentTime) {
                            previousSentEmailSentTime = moduleStartTime.clone().add(moduleIndex * 1 + i, 's')
                        }
                        if (previousSentEmailSentTime.isBefore(moduleStartTime)) {
                            previousSentEmailSentTime = moduleStartTime.clone()
                        }
                    }
                    if (!previousSentEmailSentTime) {
                        previousSentEmailSentTime = moduleStartTime.clone()
                    }
                    activity.sendTime = previousSentEmailSentTime.clone().add(activity.data.delayOffsetInSeconds, 's')
                    previousSentEmailSentTime = moment(activityIsReadLog[0].created)
                    threads.push(this.fromActivityToEmailThread(course, participant, activity))
                })
            }

        })

        return threads
    },

    filterActivitiesForParticipant(course, type, participant, activities, team, badgesInfo) {

        activities = activities.filter(a => {
            // FILTERING
            if (a.type !== type) { return false }
            if (a.isDraft) { return false }
            if (!a.parentStaticId || !a.roleStaticId) { return false }
            if (!course.byStaticId.activities[a.parentStaticId]) { return false }
            if (!course.byStaticId.activities[a.parentStaticId].moduleStaticId) { return false }
            if (!course.byStaticId.modules[course.byStaticId.activities[a.parentStaticId].moduleStaticId]) { return false }
            if (course.byStaticId.modules[course.byStaticId.activities[a.parentStaticId].moduleStaticId].isDraft) { return false }
            if (!a.characterStaticId) { return false }
            if (!course.byStaticId.characters[a.characterStaticId]) { return false }
            if (team && team.organizationId && !course.byId.organizations[team.organizationId]) { return false }
            let roleOrganizationIds = []
            if (participant.roles) {
                participant.roles.map(roleStaticId => {
                    if (course.byStaticId.roles[roleStaticId]) {
                        let role = course.byStaticId.roles[roleStaticId]
                        if (role && role.organizationStaticId) {
                            roleOrganizationIds.push(role.organizationStaticId)
                        }
                    }
                })
            }

            let shouldSeeThisActivity = false
            if (participant.roles.indexOf(a.roleStaticId) !== -1) {
                shouldSeeThisActivity = true
            }
            if (a.roleStaticId === 'all') {
                shouldSeeThisActivity = true
            }
            if (team !== null && team.id === a.roleStaticId) {
                shouldSeeThisActivity = true
            }
            if (roleOrganizationIds.indexOf(a.roleStaticId) !== -1) {
                shouldSeeThisActivity = true
            }
            if (shouldSeeThisActivity === false) { return false }
            // if (participant.roles.indexOf(a.roleStaticId) === -1  // Roles
            //     && a.roleStaticId !== 'all'  // All
            //     && (((team !== null && team.id !== a.roleStaticId)))  // To team
            //     && (roleOrganizationIds.indexOf(a.roleStaticId) === -1) // To org
            // ) { return false }

            if (a.badgeStaticId && !badgesInfo[a.badgeStaticId]) { return false }
            if (type === 'chat') {
                if (!a.formStaticId) { return false }
                if (!course.byStaticId.forms[a.formStaticId]) { return false }
                if (course.byStaticId.forms[a.formStaticId].length === 0) { return false }
            }
            return true
        })
        return activities
    },

    threadsFromActivitiesForParticipant(course, type, participant, activities) { // eslint-disable-line
        let threads = []
        let now = moment().tz(course.timezone)
        // Is he part of a team?
        let team = null
        if (participant.teamId) {
            let potentialTeams = course.teams.filter(team => team.id === participant.teamId)
            if (potentialTeams.length > 0) {
                team = potentialTeams[0]
            }
        }
        let badgesInfo = team ? team.badgesInfo : participant.badgesInfo

        activities = this.filterActivitiesForParticipant(course, type, participant, activities, team, badgesInfo)

        // Get sent time and filter for the ones in the future.
        activities = this.setActivitiesSentTimes(course, activities).filter(a => {
            if (now.isBefore(a.sendTime)) {
                return false
            }
            // Check if there is a badge and if it does have a badge, check the timestamp
            if (a.badgeStaticId) {
                let badgeObtainedDate = moment(badgesInfo[a.badgeStaticId].gottenTimeStamp)
                if (badgeObtainedDate.isAfter(a.sendTime)) {
                    return false
                }
            }
            return true
        })

        activities = scripts.sortArrayObjectMoment(activities, 'sendTime', true)

        // If chat, 1 activity = 1 character = 1 chat
        if (type === MessagingTypes.CHAT) {
            course.characters.map(character => {
                let characterThread = {
                    ...structures.threads,
                    type: type,
                    messages: [],
                    id: participant.id + character.staticId,
                    participants: [participant.id, character.staticId]
                }
                if (!participant.isAdmin) {
                    characterThread.messages = this.messagesFromActivities(course, activities, participant, type, character)
                }
                characterThread.isOnline = (characterThread.messages.length > 0 && characterThread.messages[characterThread.messages.length - 1].type === 'answer' && !characterThread.messages[characterThread.messages.length - 1].answer)
                characterThread.hasPendingQuestion = (characterThread.messages.length > 0 && characterThread.messages[characterThread.messages.length - 1].type === 'answer' && !characterThread.messages[characterThread.messages.length - 1].answer)
                threads.push(characterThread)
            })
        }
        // If mail, 1 activity = 1 thread
        if (type === MessagingTypes.EMAIL) {
            activities.map(activity => {
                threads.push(this.fromActivityToEmailThread(course, participant, activity))
            })
        }
        return threads
    },
    fromActivityToEmailThread(course, participant, activity) {
        const type = "email"
        let activityThread = {
            ...structures.threads,
            type: type,
            messages: [],
            id: activity.id + participant.id,
            activityId: activity.id,
            activityStaticId: activity.staticId,
            participants: [participant.id, activity.characterStaticId],
            subject: activity.subject,
            activity: activity // For participation
        }
        let character = course.byStaticId.characters[activity.characterStaticId]
        activityThread.messages = []
        activityThread.hasPendingQuestion = false

        // Embedded form info
        let embeddedFormData = {}
        if (activity.embeddedFormStaticId && course.byStaticId.forms[activity.embeddedFormStaticId]) {
            let formLogs = course.activityLogs.filter(l => {
                if (l.data.activityId !== activity.id) { return false }
                if (l.formStaticId !== activity.embeddedFormStaticId) { return false }
                if (l.fromParticipantId !== participant.id) { return false }
                if (l.participantId !== participant.id) { return false }
                return true
            })
            embeddedFormData.form = course.byStaticId.forms[activity.embeddedFormStaticId]
            embeddedFormData.log = formLogs[0]
            embeddedFormData.userz = { id: participant.userzId }
            embeddedFormData.participantFrom = { id: participant.id }
            embeddedFormData.participantTo = { id: participant.id }
            embeddedFormData.activityId = activity.id
            embeddedFormData.course = course
            embeddedFormData.isDisabled = formLogs.length > 0
            activityThread.hasPendingQuestion = formLogs.length === 0
        }

        let docs = course.docs.filter(doc => doc.activityStaticId === activity.staticId)
        if (activity.data && activity.data.docs) {
            docs = [
                ...docs.map(d => {
                    d.type = "old"
                    return d
                }),
                ...activity.data.docs
            ]
        }

        activityThread.messages.push({
            message: this.template(activity.body, character, participant.userz),
            created: activity.sendTime,
            participantId: character.staticId,
            type: 'message',
            videos: (activity.data && activity.data.videos) ? activity.data.videos : [],
            formLink: (activity.data && activity.data.formLink) ? `/form/${course.id}/${activity.data.formLink}` : null,
            docs: docs,
            embeddedFormData: embeddedFormData,
            uploadedVideos: (activity.data && activity.data.uploadedVideos) ? activity.data.uploadedVideos : []
        })
        if (activity.formStaticId && !participant.isAdmin) {
            activityThread.messages = [
                ...activityThread.messages,
                ...this.messagesFromActivities(course, [activity], participant, type, character)
            ]
            activityThread.hasPendingQuestion = (activityThread.messages.length > 0 && activityThread.messages[activityThread.messages.length - 1].type === 'answer' && !activityThread.messages[activityThread.messages.length - 1].answer)
        }
        // Add random messages added
        let activityLogsForThread = course.activityLogs.filter(log => log.activityStaticId === activity.staticId
            && log.type === type
            && log.withParticipantId === participant.id)

        activityThread.messages = [
            ...activityThread.messages,
            ...activityLogsForThread.map(log => {
                let message = { ...defaultMessage }
                message.created = moment(log.created).tz(course.timezone)
                message.message = log.data.message
                message.participantId = log.participantId
                message.docs = course.docs.filter(doc => doc.activityStaticId === log.id)
                if (log.data.videoUrl) {
                    message.videoUrl = log.data.videoUrl
                }
                return message
            })
        ]

        return activityThread
    },
    messagesFromActivities(course, activities, participant, type, character) {
        let messages = []
        let pendingQuestionHasBeenAdded = false

        let team = participant.teamId && course.byId.teams[participant.teamId] ? course.byId.teams[participant.teamId] : null
        let badgesInfo = team ? team.badgesInfo : participant.badgesInfo

        // Delay
        let delay = type === 'chat' ? course.delayChat : course.delayEmail
        if (typeof delay !== 'number') { delay = 0 }


        activities.map(activity => {
            if (pendingQuestionHasBeenAdded) {
                return
            }
            // Extra filters
            if (activity.characterStaticId !== character.staticId) {
                return
            }

            const form = course.byStaticId.forms[activity.formStaticId]
            if (!form || !form.questions || form.questions.length === 0) { return }
            const logs = course.activityLogs.filter(l => l.type === 'form' && l.activityId === activity.id && l.participantId === participant.id)
            let log = null
            if (logs.length > 0) {
                log = logs[0]
            }
            let questionCreated = (messages.length > 0 && messages[messages.length - 1].created.isAfter(activity.sendTime)) ? messages[messages.length - 1].created : activity.sendTime

            if (!log) {
                let questionNumber = 0
                let canShowQuestion = false
                let noQuestionFound = false
                let securityCounter = 500
                while (canShowQuestion === false && securityCounter > 0 && noQuestionFound === false) {
                    securityCounter--
                    let currentQuestion = form.questions[questionNumber]
                    // If no question, then it's the end of the form
                    if (!currentQuestion) {
                        noQuestionFound = true
                        continue
                    }
                    // if question doesn't need a badge verification continue
                    if (!currentQuestion.badgeStaticId) {
                        canShowQuestion = true
                        continue
                    }
                    if (badgesInfo[currentQuestion.badgeStaticId]
                        && moment(badgesInfo[currentQuestion.badgeStaticId].gottenTimeStamp).isBefore(questionCreated.clone().add(1, 's'))) {
                        canShowQuestion = true
                        continue
                    }
                    questionNumber++
                }
                if (noQuestionFound) {
                    return
                }

                let formatedQuestion = {
                    created: questionCreated.clone().add(delay, 's'),
                    message: form.questions[questionNumber].question,
                    participantId: character.staticId,
                    videos: (form.questions[questionNumber].videos) ? form.questions[questionNumber].videos.filter(v => v !== "") : [],
                    type: 'question'
                }
                let formatedAnswer = {
                    type: 'answer',
                    created: questionCreated.clone().add(delay, 's').add(1, 's'),
                    question: form.questions[questionNumber],
                    answer: null,
                    form: form,
                    log: log,
                    participantId: participant.id,
                    activity: activity
                }
                messages = [...messages, formatedQuestion, formatedAnswer]
                pendingQuestionHasBeenAdded = true
                return
            }
            // There is a log
            form.questions.map(question => {
                if (pendingQuestionHasBeenAdded) {
                    return
                }
                let answerHasBeenFound = false
                if (question.badgeStaticId && !badgesInfo[question.badgeStaticId]) {
                    return
                }
                let questionCreated = (messages.length > 0 && messages[messages.length - 1].created.isAfter(activity.sendTime)) ?
                    messages[messages.length - 1].created : activity.sendTime

                if (question.badgeStaticId) {
                    let badgeObtainedDate = moment(badgesInfo[question.badgeStaticId].gottenTimeStamp)
                    let questionDate = questionCreated.clone().add(1, 's')
                    if (badgeObtainedDate.isAfter(questionDate)) {
                        return
                    }
                }

                let formatedQuestion = {
                    created: questionCreated.clone().add(300, 'ms'),
                    message: question.question,
                    participantId: character.staticId,
                    videos: (question.videos) ? question.videos.filter(v => v !== "") : [],
                    type: 'question'
                }
                let formatedAnswer = {
                    type: 'answer',
                    created: null,
                    question: question,
                    answer: null,
                    form: form,
                    log: log,
                    participantId: participant.id,
                    activity: activity
                }
                log.data.answers.map(answer => {
                    if (answer.id === question.id) {
                        answerHasBeenFound = true
                        // Add the answer to the log
                        formatedAnswer.created = moment(answer.created).tz(course.timezone)
                        formatedAnswer.answer = answer
                        formatedAnswer.message = answer.answer
                        if (question.type === 'doc') {
                            // Old file system
                            let docs = course.docs.filter(d => d.id === answer.answer)
                            if (docs.length > 0) {
                                formatedAnswer.docs = docs
                            }
                            // New file system
                            if (answer.answer !== 'string') {
                                formatedAnswer.docs = [{
                                    ...answer.answer,
                                    id: answer.id
                                }]
                            }
                        }
                    }
                })
                if (!answerHasBeenFound) {
                    formatedQuestion.created = questionCreated.clone().add(delay, 's')
                    formatedAnswer.created = questionCreated.clone().add(delay + 1, 's')
                    pendingQuestionHasBeenAdded = true
                }
                // Add pending question here
                messages = [...messages, formatedQuestion, formatedAnswer]
            })
        })

        return messages
    },

    template(message, character, userz) {
        if (character) {
            message = this.templateReplacement(message, 'from_first_name', character.first)
            message = this.templateReplacement(message, 'from_last_name', character.last)
            message = this.templateReplacement(message, 'from_title', character.title || "")
        }
        if (userz) {
            message = this.templateReplacement(message, 'to_first_name', userz.first)
            message = this.templateReplacement(message, 'to_last_name', userz.last)
            message = this.templateReplacement(message, 'to_title', userz.title || "")
        }
        // eslint-disable-next-line no-useless-escape
        const res = message.match(/(https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?/gim)
        if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
                message = message.replace(res[i], `<a href="${res[i]}" target="_blank">${res[i]}</a>`)
            }
        }

        return message
    },

    templateReplacement(message, code, value) {
        let regexString = '{*' + code + '*}'
        if (message.indexOf(regexString) !== -1) {
            // eslint-disable-next-line no-useless-escape
            regexString = regexString.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
            return message.replace(new RegExp(regexString, "gi"), value)
        }
        return message
    },

    getUnreadStatus(course, participant, thread, isAdmin) {
        let readStatuses = course.activityLogs.filter(l => {
            if (l.type !== 'read') { return false }
            if (l.participantId !== participant.id) { return false }
            if (thread.activityStaticId) {
                return l.activityStaticId === thread.activityStaticId
            }
            return l.threadId === thread.id
        })

        if (thread.messages.length === 0) {
            thread.unreadLog = null
            thread.isUnread = false
            return thread
        }

        if (isAdmin) {
            thread.participantReadLog = readStatuses.length > 0 ? readStatuses[0] : null
        }

        if (readStatuses.length === 0) {
            thread.unreadLog = null
            thread.isUnread = true
            return thread
        }
        if (readStatuses.length > 1) {
            console.warn("This thread has more than 1 unread")  // eslint-disable-line
            console.log(thread)  // eslint-disable-line
            console.log(readStatuses)  // eslint-disable-line
            readStatuses = readStatuses.sort((a, b) => new Date(a.updated) - new Date(b.updated))
        }
        thread.unreadLog = readStatuses[0]
        thread.isUnread = false
        if (moment(thread.latest).isAfter(moment(thread.unreadLog.updated))) {
            thread.isUnread = thread.messages.length > 0
        }
        return thread
    },

    hasReply(thread, participant, isAdmin) {
        thread.hasReply = false
        if (!isAdmin) { return thread }
        for (let i = 0; i < thread.messages.length; i++) {
            let message = thread.messages[i]
            if (message.type === 'message' && message.participantId === participant.id) {
                thread.hasReply = true
                break
            }
        }
        return thread
    },

    getLatestActivity(thread, course, type, participant) {
        const now = moment()
        thread.latest = null
        if (thread.messages.length > 0) {
            let foundDate = false
            let counter = 1
            while (foundDate === false) {
                let potentialLatest = thread.messages[thread.messages.length - counter]
                if (potentialLatest && !thread.activityId) {
                    thread.latest = potentialLatest.created
                    foundDate = true
                    continue
                }
                if (potentialLatest && moment(potentialLatest.created).isBefore(now)) {
                    if (type !== MessagingTypes.EMAIL || (type === MessagingTypes.EMAIL && potentialLatest.participantId !== participant.id)) {
                        thread.latest = potentialLatest.created
                        foundDate = true
                    }
                }
                counter = counter + 1
                if (counter === 500) {
                    foundDate = true
                }
            }

        }
        if (thread.latest === null && thread.type === MessagingTypes.CHAT && thread.created) {
            thread.latest = moment(thread.created).tz(course.timezone)
        }
        return thread
    },

    setActivitiesSentTimes(course, activities) {
        return activities.map(a => {
            // Calculate total offset to sort property
            let module = course.byStaticId.modules[course.byStaticId.activities[a.parentStaticId].moduleStaticId]
            a.totalOffset = course.byStaticId.activities[a.parentStaticId].offset + a.offset + module.offset
            a.sendTime = moment(course.start).tz(course.timezone).add(a.totalOffset, 'm')
            return a
        })
    }
}