import Course from './Course'
import Dashboard from './Dashboard'
import Module from './Module'
import RolePlay from './RolePlay'
import Messaging from './Messaging'
import Modules from './Modules'

export default {
    Course,
    Dashboard,
    Module,
    RolePlay,
    Messaging,
    Modules
}
