

const errorHanding = function (dispatch, ERROR, response) {
    console.warn(response);
    if (response.message) {
        dispatch({
            type: 'si/notification/ADD',
            notification: {
                type: 'danger',
                title: 'Something went wrong',
                body: 'You might need to reload the page'
            }
        });
    }
    if (response.data && response.data.error && response.data.error.message) {
        dispatch({
            type: 'si/notification/ADD',
            notification: {
                type: 'danger',
                title: 'Something went wrong',
                body: 'You might need to reload the page'
            }
        });
    }
};


export default errorHanding