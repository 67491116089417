import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Card, LMSDisplay, LMSChapter } from 'alias-components'
import Config from 'alias-config'
import scripts from 'alias-tools/scripts'

export default class ModuleSingleView extends Component {

    static propTypes = {
        module: PropTypes.object,
        course: PropTypes.object,
        isAdmin: PropTypes.bool
    }

    render() {
        let { course, module, isAdmin } = this.props
        const moduleStart = moment(course.start).tz(course.timezone).add(module.offset || 0, 'm')
        const showIntro = moment().tz(course.timezone).isAfter(moduleStart.clone().subtract(5, 'm'))
        return (
            <div className="config-right-wrapper">
                <h1>{module.name}</h1>
                <Card title="Basic info">
                    <div className="form-horizontal">
                        <div className="form-group">
                            <label className="control-label col-sm-3">Start date</label>
                            <div className="col-sm-9"><p className="form-control-static">{moduleStart.format(Config.app.time.dateTimeShortFormat)}</p></div>
                        </div>
                        {module.description && (
                            <div className="form-group">
                                <label className="control-label col-sm-3">Description</label>
                                <div className="col-sm-9"><p className="form-control-static">{module.description}</p></div>
                            </div>
                        )}
                    </div>
                </Card>

                {(showIntro && !scripts.isEditorEmpty(module.intro)) && (
                    <Card title="Case introduction">
                        <LMSDisplay
                            course={course}
                            module={module}
                            text={module.intro}
                            for={module.staticId} />
                    </Card>
                )}
                {(module.intros && module.intros.length > 0) && <div>
                    <h3>Module notes</h3>
                    <LMSChapter
                        isEditable={isAdmin}
                        value={module.intros} />
                    <br />
                </div>}

                {(isAdmin && !scripts.isEditorEmpty(module.intro)) && (
                    <Card title="Teacher notes">
                        <LMSDisplay
                            course={course}
                            module={module}
                            text={module.teacher}
                            for={module.staticId} />
                    </Card>
                )}
                {(isAdmin && module.teachers && module.teachers.length > 0) && <div>
                    <h3>Teachers notes</h3>
                    <LMSChapter
                        isEditable={isAdmin}
                        value={module.teachers} />
                    <br />
                </div>}
            </div>
        );
    }
}
