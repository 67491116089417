import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Scoring from '../../Scoring'


export default class DashboardInstructorTeamLadder extends Component {

    static propTypes = {
        course: PropTypes.object
    }

    render() {
        const { course } = this.props

        let teams = course.teams.map(team => {
            let score = team.score ? team.score : Scoring.Tools.team(team.id, course.participants, course.activities, course.activityLogs, course.forms, course.scoringTags);
            team.points = !score ? 0 : Math.round(score.total);
            return team
        }).sort((a, b) => b.points - a.points)

        return <table className="table table-hover table-stripped">
            <tbody>
                {teams.map((team, i) => {
                    return <tr key={team.id}>
                        <td width="40px">{i + 1}</td>
                        <td>{team.name}</td>
                        <td style={{ textAlign: 'right' }}>{team.points}</td>
                    </tr>
                })}
            </tbody>
        </table>
    }
}