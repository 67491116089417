/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VariableTools from './VariableTools'
import { Line } from 'react-chartjs-2'

export default class VariableGraph extends Component {

    static propTypes = {
        variable: PropTypes.any,
        team: PropTypes.any,
        participant: PropTypes.any,
        state: PropTypes.object.isRequired,
        openModal: PropTypes.func
    }

    constructor(props) {
        super(props)

        this.state = {
            isTableOpen: false
        }
    }

    render() {
        const { openModal } = this.props
        const { isTableOpen } = this.state
        const data = this._calculatePoints()
        const course = this.props.state.course.model

        return (
            <div style={{ margin: '20px auto 0' }}>
                <Line data={data.graph || {}} options={{
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }} />
                {!isTableOpen && <a onClick={() => this.setState({ isTableOpen: !isTableOpen })}>View table <i className="fa fa-caret-down"></i></a>}
                {isTableOpen && <a onClick={() => this.setState({ isTableOpen: !isTableOpen })}>Hide table <i className="fa fa-caret-up"></i></a>}
                {isTableOpen && <div>
                    <table className="table table-hover table-condensed table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                {/*<th>Impact</th>*/}
                                <th>Forms</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.points.map((point, i) => {
                                if (point.logs.length === 0) { return null }
                                return <tr key={point.created + i}>
                                    <td>{i + 1}</td>
                                    {/*<td>{i === 0 ? 0 : point.coefficient.amount}</td>*/}
                                    <td>
                                        <ul>
                                            {point.logs.map(log => {
                                                let form = course.byStaticId.forms[log.formStaticId] || null;
                                                let fromParticipantName = course.byId.participants[log.fromParticipantId] ? course.byId.participants[log.fromParticipantId].displayName.short : "Not found"
                                                let toParticipantName = course.byId.participants[log.participantId] ? course.byId.participants[log.participantId].displayName.short : "Not found"
                                                return <li key={log.id}>
                                                    <a onClick={() => openModal(form, log)}>
                                                        ({form.name})&nbsp;
                                                    {fromParticipantName}
                                                        &nbsp;<i className="fa fa-chevron-right"></i>&nbsp;
                                                    {toParticipantName}
                                                    </a>
                                                </li>
                                            })}
                                        </ul>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
            </div>
        )
    }

    _calculatePoints() {
        const course = this.props.state.course.model
        let variable = this.props.variable
        const participant = this.props.participant
        const team = this.props.team
        if (!participant && !team) {
            return (<div>No participant or team</div>)
        }
        if (!variable) {
            return (<div>No variable</div>)
        }

        let data = {
            labels: [],
            datasets: [
                {
                    label: variable.name,
                    backgroundColor: 'rgba(76,171,131,0.3)',
                    borderColor: 'rgba(76,171,131,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(76,171,131,0.5)',
                    hoverBorderColor: 'rgba(76,171,131,1)',
                    data: []
                }
            ]
        }

        //
        // GET THE PARTICIPANTS AND ROLES
        //

        let participantsList = []
        let participantsRoleStaticIdList = []
        let teamId = null
        if (participant) {
            participantsList = [participant.id]
            participantsRoleStaticIdList = participant.roles
            teamId = (participant.teamId) ? participant.teamId : null
        }
        if (team) {
            teamId = team.id
        }
        if (teamId) {
            course.participants.map(part => {
                if (part.teamId === teamId) {
                    participantsList.push(part.id)
                    participantsRoleStaticIdList = [
                        ...participantsRoleStaticIdList,
                        ...part.roles
                    ]
                }
            })
        }

        if (participantsList.length === 0) {
            return <p>No participant(s) found</p>
        }
        if (participantsRoleStaticIdList.length === 0) {
            return <p>Participant(s) don't have any roles...</p>
        }

        const variableData = VariableTools.getDataPoints(course, variable, participantsList)


        // Start data plotting
        let value = variable.base * 1
        data.labels.push(0)
        data.datasets[0].data.push(value)

        variableData.points.sort((a, b) => new Date(a.created) - new Date(b.created)).map((point, i) => {
            if (point.coefficient.count === 0) { return }
            data.labels.push(i + 1)
            value = value + point.coefficient.amount * variableData.stepAmount
            data.datasets[0].data.push(value)
        })
        return {
            graph: data,
            data: variableData
        }
    }
}