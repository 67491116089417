import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import Avatar from './Avatar'
import { Link } from 'react-router-dom'
import Config from 'alias-config'

const ADMIN_STYLE = { position: 'absolute', top: 0, right: 60, fontSize: 18, padding: 11 }
class Header extends Component {
    static propTypes = {
        title: PropTypes.any,
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    }
    render() {
        const { props } = this
        const user = props.state.user.model
        const isHome = window.location.pathname === '/'

        let showAdminLink = false
        if (user && user.email && Config.admins.indexOf(user.email) !== -1) {
            showAdminLink = true
        }

        return <div id="header">
            <div className="container-fluid">
                <h1 style={{ paddingLeft: isHome ? 0 : 45 }}>{isHome && <span className="logo">LIVE<span className="case">CASE</span></span>}{this.props.title}</h1>
                {!isHome && <Link className="menu" to="/"><i className="fa fa-home" /></Link>}
                {false && <a className="menu" onClick={props.actions.local.toggleMenu}><i className="fa fa-home" /></a>}
                {showAdminLink && <Link style={ADMIN_STYLE} to="/control">Admin</Link>}
                {user && <Link to="/profile" className="avatar-wrapper"><Avatar user={user} size={40} /></Link>}
            </div>
        </div>

    }
}

export default ReduxBinder(Header, {
    state: ['local', 'user']
})
