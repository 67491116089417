import Helmet from 'react-helmet'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Search } from 'alias-components'
import Role from '../../components/Role'
import ReduxBinder from 'alias-store/ReduxBinder'

class CourseConfigurationTabRoles extends Component {


    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            search: ''
        }
    }

    render() {
        const course = this.props.state.course.model
        let roles = course.roles

        if (this.state.search !== '') {
            let search = this.state.search.toLowerCase()
            roles = roles.filter((role) => {
                return role.name.toLowerCase().indexOf(search) !== -1
            })
        }

        roles = roles.map(role => {
            role.emails = course.activities.filter(a => a.roleStaticId === role.staticId && a.type === 'email').map(a => {
                if (a.parentStaticId && course.byStaticId.activities[a.parentStaticId]) {
                    a.parent = course.byStaticId.activities[a.parentStaticId]
                    if (a.parent.moduleStaticId && course.byStaticId.modules[a.parent.moduleStaticId]) {
                        a.moduleStaticId = a.parent.moduleStaticId
                    }
                }
                return a
            })
            role.emailsFromCharacters = {}
            role.emails.map(activity => {
                if (!role.emailsFromCharacters[activity.characterStaticId]) {
                    let character = course.byStaticId.characters[activity.characterStaticId]
                    role.emailsFromCharacters[activity.characterStaticId] = {
                        characterStaticId: activity.characterStaticId,
                        characterName: character ? `${character.first} ${character.last}` : "Not found",
                        count: 1
                    }
                } else {
                    role.emailsFromCharacters[activity.characterStaticId].count++
                }
            })

            return role
        })

        return (
            <div>
                <Helmet title={course.name + " roles"} />
                <div className="page-header">
                    <Search search={this.state.search}
                        onChange={(search) => {
                            this.setState({
                                search: search
                            })
                        }} />
                    <a onClick={this._openModal.bind(this, 'new')} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add role</a>
                </div>

                {this.state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Roles allow you to assign messages to roles, and then you assign participants to roles to create a bridge.</p>
                )}

                {this.state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{this.state.search}</strong></em></p>
                )}

                {roles.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No roles</strong>
                        {this.state.search === '' && (
                            <p>Click the "Add" button on the top right.</p>
                        )}
                    </div>
                )}
                <div className="row">
                    {roles.map(role => {
                        return <div className="col-md-12" key={role.id}>
                            <Role.Card onClick={this._openModal.bind(this, role.id)}
                                role={role}>
                                <p className="help-block">
                                    Emails per character:
                                    {Object.keys(role.emailsFromCharacters).length > 0 && Object.keys(role.emailsFromCharacters).map(characterStaticId => {
                                        let characterFrom = role.emailsFromCharacters[characterStaticId]
                                        return `${characterFrom.characterName} x${characterFrom.count}`
                                    }).join(', ')}
                                </p>
                                <p className="help-block">
                                    Emails per module: {course.modules.map(module => {
                                        return `${module.name} x${role.emails.filter(activity => activity.moduleStaticId === module.staticId).length}`
                                    }).join(', ')}
                                </p>
                                <p className="help-block">
                                    Total emails: {role.emails.length}
                                    {!course.isTemplate && ` - Participants: ${course.participants.filter(part => part.roleStaticId === role.staticId).length}`}
                                </p>
                            </Role.Card>
                        </div>

                    })}
                </div>
            </div>
        )
    }

    _openModal(roleId) {
        this.props.actions.local.modal('role', roleId)
        // const course = this.props.state.course.model;
        // this.props.history.push(`/course/configuration/${course.id}/roles/${roleId === null ? 'new' : roleId}`)
    }


}

export default ReduxBinder(CourseConfigurationTabRoles, {
    state: ['course', 'role']
})