import Badges from './Badges'
import Characters from './Characters'
import Forms from './Forms'
import Organizations from './Organizations'
import Roles from './Roles'
import Values from './Values'
import Variables from './Variables'
import Scoring from './Scoring'

export default {
    Badges,
    Characters,
    Forms,
    Organizations,
    Roles,
    Values,
    Variables,
    Scoring
}