import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link, Route, Switch } from 'react-router-dom'
import CompsTabs from './ComponentsTabs'

class CourseConfigurationComps extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        subId: PropTypes.any,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.state = {
            type: props.match.params.type || 'roles'
        }
    }

    componentDidMount() {
        this.props.actions.module.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.type !== this.state.type) {
            this.refs.wrapper.scrollTop = 0
            this.setState({ type: newProps.match.params.type })
        }
    }

    render() {
        const { props } = this
        const type = props.match.params.type || 'roles'
        const course = props.state.course.model

        const tabList = [
            {
                label: `Roles (${course.roles.length})`,
                value: 'roles'
            },
            {
                label: `Characters (${course.characters.length})`,
                value: 'characters'
            },
            {
                label: `Forms (${course.forms.length})`,
                value: 'forms'
            },
            {
                label: `Values (${course.values.length})`,
                value: 'values'
            },
            {
                label: `Tags (${course.scoringTags.length})`,
                value: 'tags'
            },
            {
                label: `Badges (${course.badges.length})`,
                value: 'badges'
            }


            // {
            //   label: `Organizations (${course.organizations.length})`,
            //   value: 'organizations'
            // },

            // {
            //     label: `Variables (${course.variables.length})`,
            //     value: 'variables'
            // }
        ]


        let baseLink = `/course/configuration/${course.id}/comps`

        return <div ref="wrapper">
            <div className="page-header tab-list">
                <h1>Elements</h1>
                <br />
                <br />
                {tabList.map((thisTab, i) => {
                    return <Link to={`${baseLink}/${thisTab.value}`}
                        key={thisTab.value}
                        className={(type === thisTab.value) ? 'active' : ''}
                        style={{ marginLeft: i === 0 ? '-10px' : 0 }}>
                        {thisTab.label}
                    </Link>
                })}
            </div>
            <div style={{ minHeight: 700 }}>
                <Switch>
                    <Route exact path={`${baseLink}`} component={CompsTabs.Roles} />
                    <Route path={`${baseLink}/badges`} component={CompsTabs.Badges} />
                    <Route path={`${baseLink}/characters`} component={CompsTabs.Characters} />
                    <Route path={`${baseLink}/forms`} component={CompsTabs.Forms} />
                    <Route path={`${baseLink}/organizations`} component={CompsTabs.Organizations} />
                    <Route path={`${baseLink}/roles`} component={CompsTabs.Roles} />
                    <Route path={`${baseLink}/values`} component={CompsTabs.Values} />
                    <Route path={`${baseLink}/variables`} component={CompsTabs.Variables} />
                    <Route path={`${baseLink}/tags`} component={CompsTabs.Scoring} />
                </Switch>
            </div>
        </div>
    }
}

export default ReduxBinder(CourseConfigurationComps, {
    state: ['course']
})