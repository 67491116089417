import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors } from 'alias-components'
import IsValid from 'alias-tools/validation'
import structures from 'alias-store/structures'


export default class ModalVariable extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        variableId: PropTypes.any,
        variable: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => console.log("No close function"), // eslint-disable-line
        isOpen: false
    }

    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let variable = { ...structures.variable }

        if (props.variableId && props.variableId !== 'new' && course.byId.variables[props.variableId]) {
            variable = {
                ...variable,
                ...course.byId.variables[props.variableId]
            }
        }
        this.state = {
            variable: variable,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.variable.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.variableId != this.state.variable.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (course.byId.variables[nextProps.variableId]) {
                this.setState({
                    variable: course.byId.variables[nextProps.variableId]
                })
            } else {
                this.setState({ variable: { ...structures.variable } })
            }
        }
    }

    render() {
        const variable = this.state.variable

        var title = "Create a new variable"
        var btnText = "Create"
        var formSubmit = this._create.bind(this)

        if (this.props.variableId !== 'new') {
            title = "Update variable"
            btnText = "Save"
            formSubmit = this._update.bind(this)
        }


        return (
            <Modal show={this.props.isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={formSubmit}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Sales..."
                                value={variable.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input
                                name="unit"
                                label="Unit"
                                placeholder="Ex: $..."
                                value={variable.unit}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="number"
                                name="base"
                                label="Base value"
                                placeholder="Ex: 1000..."
                                value={variable.base}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="number"
                                name="difference"
                                label="+/- Max difference"
                                placeholder="Ex: 10..."
                                value={variable.difference}
                                onChange={this._onChangeNew.bind(this)} />

                            <Input
                                type="textarea"
                                name="description"
                                label="Description" O
                                placeholder="Ex: Explain what this variable is (Optional)..."
                                value={variable.description}
                                onChange={this._onChangeNew.bind(this)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button text={btnText} isLoading={this.props.state.variable.isSaving} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(this.props.variableId !== 'new') && (<a onClick={this._delete.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>)}

                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        var variable = this.state.variable
        variable[name] = value
        this.setState({ variable: variable, isEdited: true })
    }

    _isFormValid() {
        var variable = this.state.variable
        var formIsValid = true
        var errorFields = []
        let errors
        // Name
        errors = IsValid(variable.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Variable name",
                errors: errors
            })
        }

        // Name
        errors = IsValid(variable.base, { required: null, integer: null })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Base value",
                errors: errors
            })
        }

        // Name
        errors = IsValid(variable.difference, { required: null, integer: null })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Max difference",
                errors: errors
            })
        }

        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        let variable = this.state.variable
        const course = this.props.state.course.model
        variable.courseId = course.id
        this.props.actions.variable.create(variable, (variable) => {
            this.setState({ isEdited: false })
            this.props.openModal(variable.id)
        })
    }

    _update(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        const variable = this.state.variable
        this.props.actions.variable.update(variable, () => {
            this.setState({ variable: structures.variable, isEdited: false })
            // this.props.handleClose()
        })
    }

    _delete() {

        const variable = this.state.variable
        if (confirm("Are you sure you want to delete " + variable.name + "?")) {

            this.props.actions.variable.remove(variable, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }
}