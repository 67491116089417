module.exports = [
    { label: "Accounting", value: "accounting" },
    { label: "Advertising", value: "advertising" },
    { label: "Aerospace", value: "aerospace" },
    { label: "Aircraft", value: "aircraft" },
    { label: "Airline", value: "airline" },
    { label: "Apparel & Accessories", value: "apparel" },
    { label: "Automotive", value: "automotive" },
    { label: "Banking", value: "banking" },
    { label: "Broadcasting", value: "broadcasting" },
    { label: "Brokerage", value: "brokerage" },
    { label: "Biotechnology", value: "biotechnology" },
    { label: "Call Centers", value: "call" },
    { label: "Cargo Handling", value: "cargo" },
    { label: "Chemical", value: "chemical" },
    { label: "Computer", value: "computer" },
    { label: "Construction", value: "construction" },
    { label: "Consulting", value: "consulting" },
    { label: "Consumer Products", value: "consumer" },
    { label: "Cosmetics", value: "cosmetics" },
    { label: "Defense", value: "defense" },
    { label: "Department Stores", value: "department" },
    { label: "Education", value: "education" },
    { label: "Electronics", value: "electronics" },
    { label: "Energy", value: "energy" },
    { label: "Entertainment & Leisure", value: "entertainment" },
    { label: "Executive Search", value: "executive" },
    { label: "Financial Services", value: "financial" },
    { label: "Food, Beverage & Tobacco", value: "food" },
    { label: "Governmental organization", value: "governmental" },
    { label: "Grocery", value: "grocery" },
    { label: "Health Care", value: "health" },
    { label: "Internet Publishing", value: "internet" },
    { label: "Investment Banking", value: "investment" },
    { label: "Legal", value: "legal" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "Motion Picture & Video", value: "motion" },
    { label: "Music", value: "music" },
    { label: "Newspaper Publishers", value: "newspaper" },
    { label: "Non-governmental organization", value: "non-governmental" },
    { label: "Online Auctions", value: "online" },
    { label: "Pension Funds", value: "pension" },
    { label: "Pharmaceuticals", value: "pharmaceuticals" },
    { label: "Private Equity", value: "private" },
    { label: "Publishing", value: "publishing" },
    { label: "Real Estate", value: "real" },
    { label: "Research", value: "research" },
    { label: "Retail & Wholesale", value: "retail" },
    { label: "Securities & Commodity Exchanges", value: "securities" },
    { label: "Service", value: "service" },
    { label: "Soap & Detergent", value: "soap" },
    { label: "Software", value: "software" },
    { label: "Sports", value: "sports" },
    { label: "Technology", value: "technology" },
    { label: "Telecommunications", value: "telecommunications" },
    { label: "Television", value: "television" },
    { label: "Transportation", value: "transportation" },
    { label: "Trucking", value: "trucking" },
    { label: "Venture Capital", value: "venture " }
];
