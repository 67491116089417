import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import BadgeCard from './BadgeCard'
import Fuse from 'fuse.js'

export default class BadgeConfiguration extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            shownNumberOfBadges: 20
        }
    }

    render() {
        const { props, state } = this
        const course = props.state.course.model
        const { id } = props.match.params
        let showShowMore = false
        let badges = course.badges

        if (state.search !== '') {
            const options = {
                keys: ["name"],
                minMatchCharLength: 1,
                shouldSort: false,
                threshold: 0.2
            }
            let fuse = new Fuse(badges, options)
            badges = fuse.search(state.search)
        }

        badges = badges.map(badge => {
            badge.questionsWithbadgeCount = 0
            badge.forms = course.forms.filter(form => {
                let addForm = false
                form.questions.map(question => {
                    if (question.badgeStaticId === badge.staticId) {
                        addForm = true;
                        badge.questionsWithbadgeCount++
                    }
                })
                return addForm
            })
            return badge
        })

        if (badges.length > state.shownNumberOfBadges) {
            showShowMore = true
            badges = badges.slice(0, state.shownNumberOfBadges)
        }

        return <div>
            <Helmet title={course.name + " badges"} />
            <div className="page-header">
                <Search search={state.search}
                    onChange={(search) => {
                        this.setState({
                            search: search
                        })
                    }} />
                <a onClick={this._openModal.bind(this, 'new')} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add badge</a>
            </div>

            {state.search === '' && (
                <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Use scoring tags to grade students on different skills/qualities. For example, communication, negotiation, teamwork,...</p>
            )}

            {state.search !== '' && (
                <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{state.search}</strong></em><a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a></p>
            )}

            {badges.length === 0 && (
                <div className="alert alert-info">
                    <strong>No badges</strong>
                    {state.search === '' && (
                        <p>Click the "Add" button on the top right.</p>
                    )}
                </div>
            )}

            {badges.map(badge => {
                return <BadgeCard badge={badge}
                    onEditClick={this._openModal.bind(this, badge.id)}
                    key={badge.id}>
                    <p className="help-block">
                        <i className="fa fa-info" /> Forms: {badge.forms.length} - Questions: {badge.questionsWithbadgeCount} {badge.isVisibleToParticipant ? ' - Visible to participants' : ''}
                    </p>
                </BadgeCard>
            })}

            {showShowMore && <div>
                <a onClick={() => this.setState({ show: shownNumberOfBadges + 20 })}
                    className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
            </div>}


        </div>

    }

    _openModal(badgeId) {
        this.props.actions.local.modal('badge', badgeId)
        // Old way
        // const course = this.props.state.course.model;
        // this.props.history.push(`/course/configuration/${course.id}/badges/${badgeId === null ? 'new' : badgeId}`)
    }

    _closeModal() {
        const course = this.props.state.course.model;
        this.props.history.push(`/course/configuration/${course.id}/badges`)
    }

}
