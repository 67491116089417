import React, { Component } from 'react'
import ReduxBinder from 'alias-store/ReduxBinder'
import PropTypes from 'prop-types'
import { Card, Avatar } from 'alias-components'
import WidgetForms from '../components/Widgets/WidgetForms'

class Duplicates extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    }
    render() {
        const course = this.props.state.course.model
        const logs = course.activityLogs.filter(l => l.type === 'form')
        const forms = course.forms
            .map(form => {
                form.hasDuplicate = false
                form.logs = logs.filter(l => l.formStaticId === form.staticId)
                form.logs = form.logs.map(log => {
                    log.isDuplicate = false
                    if (form.logs.filter(l => l.participantId === log.participantId && l.activityId === log.activityId).length > 1) {
                        log.isDuplicate = true
                        form.hasDuplicate = true
                    }
                    return log
                })
                return form
            })
            .filter(f => f.logs.length > 0 && f.hasDuplicate === true)
        // console.log(logs)
        return <div className="config-right-wrapper">
            <h2>Duplicates</h2>
            <p>Yellow means they are the same people with the same ID.</p>
            {forms.map(form => {
                return <Card key={form.id} title={form.name + "   => Count: " + form.logs.length}>
                    <div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>From > TO</th>
                                    <th>User</th>
                                    <th>Activity</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {form.logs.map(log => {
                                    return <LogRow key={log.id}
                                        log={log}
                                        actions={this.props.actions}
                                        course={course} />
                                })}
                            </tbody>
                        </table>
                        <WidgetForms course={course} actions={this.props.actions} formStaticId={form.staticId} />
                    </div>
                </Card>
            })}
        </div>
    }


}

class LogRow extends Component {
    constructor(props) {
        super(props)
        this.state = { isDeleting: false }
    }

    render() {
        const log = this.props.log
        const course = this.props.course

        return <tr key={log.id} className={log.isDuplicate ? 'warning' : ''}>
            <td>{log.fromParticipantId} > {log.participantId}</td>
            <td><Avatar user={course.byId.participants[log.participantId]} size={20} /></td>
            <td>{log.activityId}</td>
            <td><a onClick={() => this._onDelete(log)} className="red">Delete</a></td>
        </tr>
    }
    _onDelete(log) {
        if (confirm("Are you sure you want to delete this log?")) {
            this.setState({ isDeleting: true })
            this.props.actions.activityLog.remove(log, () => {

            })
        }
    }
}

export default ReduxBinder(Duplicates, { state: ['course', 'activityLog'] })