import React, { Component } from 'react'
import PropTypes from 'prop-types'
import scripts from 'alias-tools/scripts'
import api from 'alias-tools/api'
import Editor from './Editor'
import FileUpload from './FileUpload'
import Input from './Input'
import Card from './Card'
import FileIcons from './FileIcons'

export default class LMSChapter extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
        value: PropTypes.array,
        onSave: PropTypes.func,
        isAdmin: PropTypes.bool,
        courseId: PropTypes.string,
        isInEditMode: PropTypes.bool
    }
    static defaultProps = {
        isEditable: false,
        value: [],
        onSave: () => { console.warn("Need a save function on new LMS") },  // eslint-disable-line no-console
        isAdmin: false,
        courseId: 'test',
        isInEditMode: false
    }
    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            isSaving: false,
            isEditing: props.isInEditMode,
            isEdited: false
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.state.isEdited) {
            this.setState({ value: newProps.value })
        }
    }
    render() {
        const { props, state } = this
        if (state.isEditing) {
            return <div>
                {(!state.value || (state.value && state.value.length === 0)) && <div className="alert alert-warning"><i className="fa fa-bullhorn"></i> No sections at the moment, click "Add section below"</div>}
                <form onSubmit={this._onSave.bind(this)}>
                    {state.value.map(section => {
                        return <Card key={section.id}>
                            <div>
                                <Input
                                    type="text"
                                    label="Section title"
                                    placeholder="Enter section title here..."
                                    value={section.title}
                                    onChange={(name, value) => this._onChange(section.id, 'title', value)} />
                                <Editor
                                    tag={section.id}
                                    text={section.content}
                                    placeholder={this.props.placeholder}
                                    onChange={(text) => this._onChange(section.id, 'content', text)} />
                                <table className="table table-striped-table-hover">
                                    <tbody>
                                        {section.docs.map(doc => {
                                            return <tr key={doc.id}>
                                                <td>
                                                    <a target="_blank" href={`/api/files?url=${doc.path}`}><FileIcons name={doc.name} height={80} isProcessing={true} /> {doc.name}</a>
                                                    <a className="red pull-right" onClick={this._onRemoveFile.bind(this, section.id, doc)}>Remove</a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>

                                {!props.onChange && <button onClick={this._onSave.bind(this)} className="btn btn-primary">{state.isSaving ? <i className="fa fa-spinner fa-pulse"></i> : "Save"}</button>}
                                <FileUpload
                                    courseId={props.courseId}
                                    onComplete={(file) => this._onFileComplete(section.id, file)}
                                    type="core" />
                                <a onClick={this._onDeleteSection.bind(this, section.id)} className="btn btn-danger pull-right">Remove section</a>
                                <div className="clear"></div>
                            </div>
                        </Card>
                    })}
                </form>
                <hr />
                <a className="btn btn-secondary" onClick={this._addSection.bind(this)}><i className="fa fa-plus"></i> Add a section</a>
                <a onClick={this._exitEditMode.bind(this)} className="btn btn-link margin-left-5">Exit edit mode</a>
            </div>
        }
        if (!state.isEditing) {
            return <div>
                {(!state.value || (state.value && state.value.length === 0)) && <div className="help-block"><i className="fa fa-bullhorn"></i> No sections at the moment</div>}
                {state.value.map((section, i) => {
                    return <SectionCard key={section.id} section={section} isOpen={i === 0} />
                })}
                {props.isEditable && <div>
                    <p><a onClick={() => this.setState({ isEditing: true })} className="btn btn-secondary">Edit text</a></p>
                </div>}
            </div>
        }
    }

    _onChange(sectionId, attribute, val) {
        let value = this.state.value.map(section => {
            if (section.id === sectionId) {
                section[attribute] = val
            }
            return section
        })
        this.setState({ value: value, isEdited: true })
    }
    _onSave(e) {
        if (e && e.preventDefault) { e.preventDefault() }
        if (this.props.onChange) {
            this.props.onChange(this.state.value)
        } else {
            this.setState({ isSaving: true })
            this.props.onSave(this.state.value, () => {
                this.setState({ isSaving: false, isEdited: true })
            })
        }
    }

    _exitEditMode() {
        this.setState({ isEditing: false })
        // if (this.state.isEdited) {
        //     let conf = confirm("You have made changes without saving. Do you still wish to exit?")
        //     if (conf) {
        //         this.setState({ isEditing: false })
        //     }
        // } else {
        //     this.setState({ isEditing: false })
        // }
    }

    _addSection() {
        let value = this.state.value
        value.push({
            id: scripts.randomString(),
            title: '',
            content: null,
            docs: []
        })
        this.setState({
            value: value
        })
    }
    _onDeleteSection(sectionId) {
        let value = this.state.value
        value = value.filter(v => v.id !== sectionId)
        this.setState({ value: value })
    }

    _onFileComplete(sectionId, file) {
        let { value } = this.state
        value = value.map(section => {
            if (section.id === sectionId) {
                section.docs.push({
                    id: scripts.randomString(),
                    ...file
                })
            }
            return section
        })
        this.setState({ value: value })
    }
    _onRemoveFile(sectiondId, file) {
        api(
            '/files',
            'DELETE',
            { url: file.path }
        )
            .then(() => {
                let { value } = this.state
                value = value.map(section => {
                    if (section.id === sectiondId) {
                        section.docs = section.docs.filter(doc => doc.id !== file.id)
                    }
                    return section
                })
                this.setState({ value: value })
            })
            .catch(() => {
                alert("Couldn't delete the file, please try again.")
            })

    }

}


class SectionCard extends Component {
    static propTypes = {
        section: PropTypes.object,
        isOpen: PropTypes.bool
    }
    static defaultProps = {
        isOpen: false
    }
    constructor(props) {
        super(props)
        this.state = {
            isOpen: props.isOpen
        }
    }
    render() {
        const { props, state } = this
        const section = props.section
        return <Card>
            <div>
                <h3 onClick={() => this.setState({ isOpen: !state.isOpen })} style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer' }}>
                    {section.title}
                    <small className="pull-right" style={{ marginTop: '5px' }}>{state.isOpen ? 'Hide' : 'View'} <i className={`fa fa-chevron-${state.isOpen ? 'down' : 'up'}`} /></small>
                </h3>
                {state.isOpen && <div>
                    <br />
                    <Editor
                        tag={section.id}
                        text={section.content}
                        readOnly={true} />
                    <ul className="lms-docs">
                        {section.docs.map(doc => {
                            return <li key={doc.id}>
                                <a target="_blank" href={`/api/files?url=${doc.path}`}><FileIcons name={doc.name} height={80} isProcessing={true} /> {doc.name}</a>
                            </li>
                        })}
                    </ul>
                </div>}
            </div>
        </Card>
    }
}