import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'

function stateSetter(props) {

    return {
        formStaticId: props.match.params.formStaticId
    }
}

class BuilderTabPick extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        this.state = stateSetter(props)
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.match.params.formStaticId !== this.state.formStaticId) {
            this.setState(stateSetter(newProps))
        }
    }

    render() {
        const { props } = this
        const course = props.state.course.model
        return <div>
            Hello<br />
            What do you want to build?
            <Link to={`/builder/${course.id}/form`}>Form</Link>
        </div>
    }


}


export default ReduxBinder(BuilderTabPick, {
    state: ['course', 'form']
})