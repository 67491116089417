import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RolePlay from '../components/RolePlay'
import ReduxBinder from 'alias-store/ReduxBinder'

class CourseRunRolePlays extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object
    }

    render() {
        const { match } = this.props
        return <div className="config-right-wrapper">
            <RolePlay.Display {...this.props}
                isAdmin={true}
                activityId={match.params.activityId} />
        </div>
    }
}

export default ReduxBinder(CourseRunRolePlays, {
    state: ['course', 'user']
})