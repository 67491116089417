import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line, Bar, Doughnut, Pie, Polar } from 'react-chartjs-2'
import { isArray } from '../../../../../../node_modules/util'
import backgroundImage from 'alias-assets/blurred-graph.png'
const colors = ['#91C499', '#9E768F', '#E55381', '#D183C9', '#2292A4', '#7DBBC3', '#AB3428', "#631D76", "#FE5F55", "#BE3E82", "#F9C80E", "#496DDB", '#FE9920', '#CFD11A']

const CHART_OPTIONS = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
}

export default class TeamGraph extends Component {
    static propTypes = {
        graph: PropTypes.object.isRequired,
        team: PropTypes.object.isRequired,
        isGraphShown: PropTypes.bool
    }
    static defaultProps = {
        isGraphShown: true
    }
    constructor(props) {
        super(props)
        this.state={
            isGraphShown: props.isGraphShown
        }
    }
    render() {
        const { props, state } = this
        // const { team, graph } = props

        if (!props.team.graphData || !props.team.graphData[props.graph.id]) { return <p>No data</p> }

        if(!state.isGraphShown){
            return <div style={{
                display: 'block',
                width: '100%',
                height: 360,
                backgroundImage: `url("${backgroundImage}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                textAlign: 'center'
            }}>
                <a onClick={() => this.setState({ isGraphShown: true })} className="btn btn-secondary" style={{ marginTop: 100 }}>View data</a>
            </div>
        }


        let data = props.team.graphData[props.graph.id]
        switch (props.graph.type) {
            case 'line':
            case 'bar':
                return <GraphLine data={data} type={props.graph.type} />
            case 'donught':
            case 'pie':
                return <DonughtGraph data={data} type={props.graph.type} />
            case 'table':
                return <GraphTable data={data} />
            case 'polar':
                return <PolarGraph data={data} />
        }
        return <div></div>
    }
}


class GraphTable extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired
    }
    render() {
        const { data } = this.props
        if (!data || !isArray(data) || data.length === 0) { return <div>No data</div> }
        let firstRow = data[0]

        return <div style={{ overflowX: 'scroll' }}>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        {firstRow.map((cell, j) => {
                            return <th key={j}>{cell}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => {
                        if (!isArray(row) || i === 0) { return null }
                        return <tr key={i}>
                            {row.map((cell, j) => {
                                return <td key={j}>{cell}</td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}

class GraphLine extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        type: PropTypes.oneOf(['line', 'bar'])
    }
    static defaultProps = {
        type: 'line'
    }
    render() {
        const { data, type } = this.props
        if (!data || !isArray(data) || data.length === 0) { return <div>No data</div> }
        let dataPts = {
            labels: [],
            datasets: []
        }
        const sampleLine = {
            label: '',
            backgroundColor: 'rgba(76,171,131,0)',
            // borderColor: 'rgba(76,171,131,1)',
            borderWidth: 2,
            // hoverBackgroundColor: 'rgba(76,171,131,0.5)',
            // hoverBorderColor: 'rgba(76,171,131,1)',
            data: []
        }
        data.map((row, i) => {
            if (!isArray(row)) { return }
            if (i !== 0) {
                dataPts.labels.push(row[0])
            }
            for (let c = 1; c < row.length; c++) {
                if (i === 0) {
                    dataPts.datasets.push({
                        ...sampleLine,
                        borderColor: colors[c - 1],
                        backgroundColor: type === 'line' ? 'rgba(76,171,131,0)' : colors[c - 1],
                        label: row[c],
                        data: []
                    })
                    continue
                }
                dataPts.datasets[c - 1].data.push(row[c])
            }

        })

        switch (type) {
            case 'line':
                return <Line data={dataPts} options={CHART_OPTIONS} />
            case 'bar':
                return <Bar data={dataPts} options={CHART_OPTIONS} />
        }

    }
}


class DonughtGraph extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        type: PropTypes.oneOf(['donught', 'pie'])
    }
    static defaultProps = {
        type: 'donught'
    }
    render() {
        let { data, type } = this.props
        if (!data || !isArray(data) || data.length === 0) { return <div>No data</div> }
        let dataPts = {}
        data = data.filter(row => isArray(row))
        dataPts.labels = data.map(row => row[0])
        dataPts.datasets = [
            {
                data: data.map(row => row[1]),
                backgroundColor: colors.slice(0, data.length)
            }
        ]


        switch (type) {
            case 'donught':
                return <Doughnut data={dataPts} />
            case 'pie':
                return <Pie data={dataPts} />
        }

    }
}

class PolarGraph extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        type: PropTypes.oneOf(['polar'])
    }
    static defaultProps = {
        type: 'polar'
    }
    render() {
        let { data, type } = this.props
        if (!data || !isArray(data) || data.length === 0) { return <div>No data</div> }
        data = data.filter(row => isArray(row))

        let dataPts = {
            datasets: [
                {
                    data: data.map(row => row[1]),
                    backgroundColor: colors.slice(0, data.length)
                }
            ],
            labels: data.map(row => row[0])
        }
        dataPts.datasets = [
            {
                data: data.map(row => row[1]),
                backgroundColor: colors.slice(0, data.length)
            }
        ]


        switch (type) {
            case 'polar':
                return <Polar data={dataPts} />
        }
        return <p>Something went wrong</p>
    }
}