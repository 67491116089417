// require('es6-promise').polyfill()
import axios from 'axios'
import { api, dev } from '../../config'
import scripts from 'alias-tools/scripts'
import localAuthentication from 'alias-tools/localAuthentication'
import queryString from 'query-string'


var axiosInstance = axios.create({
    baseURL: scripts.apiURL(),
    timeout: api.timeout,
    responseType: 'json',
    headers: {
        'X-Custom-Header': 'foobar'
    }
})

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent

    return config
}, function (error) {
    // Do something with request error

    return Promise.reject(error)
})

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
    // Do something with response data
    if (dev.showApiQueries) {
        console.log(method + ": " + URL) // eslint-disable-line
    }
    if (dev.showApiQueryResults) {
        console.log(response) // eslint-disable-line
    }

    return response
}, (error) => {

    // Do something with response error
    if (error.message && error.message == "Network Error") {
        // window.location.href = '/error/server'
    }
    // https://github.com/pburtchaell/react-notification
    if (error && error.response) {
        switch (error.response.status) {
            case 401:
                if (localAuthentication.logout()) {
                    console.log("Api redirecting to login page") // eslint-disable-line
                    window.location.href = '/auth'
                }
                break
        }
    }

    return Promise.reject(error)
})

export default function apiCall(endpoint = "", method = "GET", data, state = {}, filter = {}, dispatch) {

    // Build the URL
    var URL = endpoint
    var headers = {}
    // If authenticated, add access token in request
    const token = localAuthentication.isAuthenticated()
    if (token) {
        headers = {
            'Authorization': token.id
        }
    }
    // Filter
    if (filter && Object.keys(filter).length > 0) {
        URL += "?filter=" + JSON.stringify(filter)
    }
    if ((method === "GET" || method === 'DELETE') && data && Object.keys(data).length > 0) {
        URL += "?" + queryString.stringify(data)
    }

    const options = {
        method: method,
        url: URL,
        data: data,
        headers: headers
    }

    return axiosInstance(options)
}

export function vimeoUpload(URL = "", formData, onProgress) {
    return axiosInstance({
        url: URL,
        method: 'post',
        data: formData,
        headers: {},
        onUploadProgress: function (progressEvent) {
            var percentCompleted = progressEvent.total > 0 ? progressEvent.loaded / progressEvent.total * 100 : 0
            if (onProgress) {
                onProgress({
                    loaded: progressEvent.loaded,
                    total: progressEvent.total,
                    progress: Math.round(percentCompleted * 100) / 100
                })
            }
        }
    })
}


export function apiUpload(URL = "", formData, onProgress, additionalData = {}, state = {}) {
    if (additionalData) {
        const queryString = EncodeQueryData(additionalData)
        URL += '?' + queryString
    }
    var headers = {}
    const token = localAuthentication.isAuthenticated()
    if (token) {
        headers = {
            'Authorization': token.id
        }
    }

    return axiosInstance({
        url: URL,
        method: 'post',
        data: formData,
        headers: headers,
        onUploadProgress: function (progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100
            if (onProgress) {
                onProgress({
                    loaded: progressEvent.loaded,
                    total: progressEvent.total,
                    progress: Math.round(percentCompleted * 100) / 100
                })
            }
        }
    })
}

function EncodeQueryData(data) {
    var ret = []
    for (var d in data) {
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]))
    }

    return ret.join("&")
}


export function fetchCall(URL = "", method = "post", headers = {}, formData = {}) {
    return axiosInstance({
        url: URL,
        method: method,
        data: formData,
        headers: headers
    })
}