import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Card, Input, Button } from 'alias-components'
import Email from './Email'


export default class ActivityTriggeredEmail extends Component {
    static propTypes = {
        isShown: PropTypes.bool,
        onClose: PropTypes.func,
        activity: PropTypes.object,
        state: PropTypes.object,
        module: PropTypes.object,
        updateActivity: PropTypes.func,
        actions: PropTypes.object
    }
    static defaultProps = {
        isShown: false,
        onClose: () => { alert("Closing") },
        updateActivity: () => { }
    }
    constructor(props) {
        super(props)

        this.state = {
            activity: props.activity,
            isEdited: false,
            isSaving: false
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.isEdited) {
            this.setState({ activity: newProps.activity })
        }
    }
    render() {
        const { props, state } = this
        let activity = state.activity
        const course = props.state.course.model


        const roleOptions = course.roles.map(role => {
            return {
                label: role.name,
                value: role.staticId
            }
        })
        const moduleOptions = course.modules.map(module => {
            return {
                label: module.name,
                value: module.staticId
            }
        })

        let activityDocs = []
        let badgesOptions = [
            { label: 'None', value: '' }
        ]
        if (activity) {
            activity.data = activity.data || {}
            if (activity.id && course.docs) {
                course.docs.filter(doc => {
                    return doc.activityStaticId == activity.staticId
                })
            }
            course.badges.map(badge => {
                badgesOptions.push({ label: badge.name, value: badge.staticId })
            })
        }




        return <Modal show={props.isShown} onHide={this._onClose.bind(this)}>
            <Modal.Header closeButton>
                <Modal.Title>Triggered activity</Modal.Title>
            </Modal.Header>
            <form onSubmit={this._save.bind(this)}>
                <Modal.Body>
                    {activity && <div style={{ padding: '5px 15px' }}>
                        <Card title="Basics">
                            <Input type="select"
                                name="moduleStaticId"
                                label="Module"
                                placeholder="Ex: Select a module..."
                                value={activity.moduleStaticId}
                                options={moduleOptions}
                                onChange={this._onChange.bind(this)} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Acquisition course..."
                                value={activity.name}
                                onChange={this._onChange.bind(this)}
                                attributes={{ 'autoFocus': true }} />


                            <Input type="select"
                                name="roleStaticId"
                                label="Who is this for?"
                                placeholder="Ex: Select a role..."
                                value={activity.roleStaticId}
                                options={roleOptions}
                                onChange={this._onChange.bind(this)} />

                            <Input type="bool"
                                label="Is this a draft?"
                                description="Making the activity a draft will prevent students from viewing it."
                                value={activity.isDraft}
                                onChange={() => this._onChange('isDraft', !activity.isDraft)} />
                        </Card>
                        <Card title="Delay">
                            <div>
                                <Input type="number"
                                    label="Delay offset from trigger (in seconds)"
                                    name="delayOffsetInSeconds"
                                    value={activity.data.delayOffsetInSeconds}
                                    onChange={(name, value) => this._onChangeData(name, value)} />
                            </div>
                        </Card>
                        {(activity.id && activity.type === 'triggered-email') && <div>

                            <Card title="Badge visibility">
                                {activity.order === 0 && <p className="help-block">Please note that badges cannot work on the very first activity of a module and will be ignored.</p>}
                                {activity.order !== 0 && <Input type="select"
                                    label="Only show this if the team/participant has a badge"
                                    value={activity.badgeStaticId}
                                    options={badgesOptions}
                                    name="badgeStaticId"
                                    onChange={this._onChange.bind(this)} />}
                            </Card>

                            <h3>Email</h3>
                            <p className="help-block"><i className="fa fa-info" /> Interact with your participants through a messaging system, similar to email.</p>
                            <Card>
                                <Email activity={activity}
                                    docs={activityDocs}
                                    state={this.props.state}
                                    actions={this.props.actions}
                                    onChange={this._onChange.bind(this)}
                                    type='triggered-email' />
                            </Card>

                            <h3>Attach a form</h3>
                            <p className="help-block">
                                <i className="fa fa-info" /> Attach a form at the end of the {activity.type} for the participant to answer.<br />
                            </p>
                            <Card>
                                <div className="row">
                                    <div className="col-xs-12 col-xs-8">
                                        <Input type="select"
                                            options={course.forms.map(form => {
                                                return {
                                                    label: form.name,
                                                    value: form.staticId
                                                }
                                            })}
                                            placeholder="Select a form (Optional)"
                                            label="Embedded form"
                                            ref="form"
                                            name="embeddedFormStaticId"
                                            value={activity.embeddedFormStaticId}
                                            onChange={this._onChange.bind(this)} />
                                        {activity.embeddedFormStaticId && <a onClick={this._openFormModal.bind(this, course.byStaticId.forms[activity.embeddedFormStaticId].id)}>Edit this form</a>}
                                    </div>
                                    <div className="col-xs-12 col-xs-4">
                                        <br />
                                        <a onClick={this._openFormModal.bind(this, 'new')} className="btn btn-link">Create new form</a>
                                    </div>
                                </div>

                            </Card>

                            <h3>Conversational form</h3>
                            <p className="help-block">
                                <i className="fa fa-info" /> You can attach a form into an email/chat that will transform it into a conversation where each question shows up one after the other.
                            </p>
                            <Card>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-8">
                                        <Input type="select"
                                            options={course.forms.map(form => {
                                                return {
                                                    label: form.name,
                                                    value: form.staticId
                                                }
                                            })}
                                            placeholder="Select a form (Optional)"
                                            label="Conversational form"
                                            ref="form"
                                            name="formStaticId"
                                            value={activity.formStaticId}
                                            onChange={this._onChange.bind(this)} />
                                        {activity.formStaticId && <a onClick={this._openFormModal.bind(this, course.byStaticId.forms[activity.formStaticId].id)}>Edit this form</a>}
                                    </div>
                                    <div className="col-xs-12 col-sm-4">
                                        <br />
                                        <a onClick={this._openFormModal.bind(this, 'new')} className="btn btn-link">Create new form</a>
                                    </div>
                                </div>

                            </Card>
                        </div>}

                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button text={(activity && activity.id) ? "Update" : "Create"} isLoading={state.isSaving} />
                    <a onClick={this._onClose.bind(this)} className="btn btn-default">Cancel</a>
                    <a onClick={this._onRemove.bind(this)} className="btn btn-danger">Remove</a>
                </Modal.Footer>
            </form>
        </Modal>
    }

    _openFormModal(formId) {
        this.props.actions.local.modal('form', formId)
    }

    _onChange(name, value) {
        let activity = this.state.activity
        activity[name] = value
        if (name === 'roleStaticId') {
            alert("Need to do the change of the ordering, maybe put it at the end??")
        }
        this.setState({ activity: activity, isEdited: true })
    }
    _onChangeData(name, value) {
        let activity = this.state.activity
        activity.data = activity.data || {}
        activity.data[name] = value
        this.setState({ activity: activity, isEdited: true })
    }
    _save(e) {
        e.preventDefault()
        const { props, state } = this
        this.setState({ isSaving: true })
        let activity = state.activity

        if (activity.id) {
            props.actions.activity.update(activity, (res) => {
                this.setState({
                    activity: res,
                    isSaving: false,
                    isEdited: false
                })
                props.updateActivity(res)
            })
        } else {
            props.actions.activity.create(activity, (res) => {
                this.setState({ activity: res, isSaving: false, isEdited: false })
                props.updateActivity(res)
            })
        }

    }
    _onClose() {
        if (this.state.isEdited) {
            const sure = confirm("You have made some changes, are you sure you want to close without saving?")
            if (!sure) {
                return
            }
        }
        this.setState({ isEdited: false, isSaving: false })
        this.props.onClose()
    }

    _onRemove() {
        const sure = confirm("Are you sure you want to delete this activity?")
        if (!sure) {
            return
        }
        this.props.actions.activity.remove(this.state.activity, () => {
            this.props.onClose()
        })
    }
}