/* eslint-disable no-undef */
import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
// import persistState from 'redux-localstorage'
import reducers from './reducers.jsx'

export default function configureStore(initialState) {
    // persistState(null,{key: 'liveCase'}),
    // window.devToolsExtension ? window.devToolsExtension() : f => f
    // The above line was below the applyMiddleware
    const createPersistentStore = compose(
        applyMiddleware(thunk)
    )(createStore)
    const store = createPersistentStore(reducers, initialState)

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextReducer = require('./reducers').default
            store.replaceReducer(nextReducer)
        })
    }
    return store
}
