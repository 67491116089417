import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, FormErrors, VideoPlayer } from 'alias-components'
import structures from 'alias-store/structures'
import ForumForm from './ForumForm'

export default class ForumModalNew extends Component {
    static propTypes = {
        actions: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        courseId: PropTypes.string,
        participantId: PropTypes.string,
        participant: PropTypes.object,
        updateUnread: PropTypes.func
    }
    static defaultProps = {
        show: false
    }
    constructor(props) {
        super(props)

        this.state = {
            // post: { ...structures.form },
            // isEdited: false,
            // isSaving: false,
            // errorFields: []
        }
    }
    render() {
        const { props, state } = this

        return <Modal show={props.show} onHide={this._onComplete.bind(this)}>
            <Modal.Header closeButton>
                <Modal.Title>New Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ForumForm
                    updateUnread={props.updateUnread}
                    actions={props.actions}
                    participantId={props.participantId}
                    participant={props.participant}
                    courseId={props.courseId}
                    isMainPost={true}
                    onComplete={this._onComplete.bind(this)} />
            </Modal.Body>
            <Modal.Footer>
                <a onClick={this._onComplete.bind(this)} className="btn btn-default">Close</a>
            </Modal.Footer>
        </Modal>
    }


    _onComplete() {
        this.props.handleClose()
    }

}
