import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Search } from 'alias-components'
import Participant from '../../components/Participant'
import Fuse from 'fuse.js'
import ReduxBinder from 'alias-store/ReduxBinder'

class InfoTabsAuthors extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            search: '',
            isModalOpen: false
        }
    }

    render() {
        const course = this.props.state.course.model
        let authors = course.participants.filter((participant) => participant.isAdmin)
        if (this.state.search !== '') {
            let search = this.state.search
            let options = {
                keys: [
                    {
                        name: 'userz.first',
                        weight: 0.4
                    }, {
                        name: 'userz.last',
                        weight: 0.4
                    }, {
                        name: 'userz.email',
                        weight: 0.2
                    }
                ],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.2
            }
            let fuse = new Fuse(authors, options)
            authors = fuse.search(search)
        }
        return <div>
            <div className="page-header">
                <Search
                    search={this.state.search}
                    onChange={(search) => {
                        this.setState({ search: search })
                    }} />
                <a
                    onClick={() => {
                        this.setState({ isModalOpen: true })
                    }}
                    className="btn btn-secondary pull-right">+ Add author</a>
            </div>
            <Participant.Modal
                isOpen={this.state.isModalOpen}
                handleClose={() => {
                    this.setState({ isModalOpen: false })
                }}
                participant={null}
                isAdmin={true}
                course={course}
                state={this.props.state}
                actions={this.props.actions} /> {this.state.search === '' && (
                    <p className="help-block">
                        <i
                            className="fa fa-info"
                            style={{
                                marginRight: '5px'
                            }} />
                        Authors can view, edit & use the course just like you.
                </p>
                )}
            {this.state.search !== '' && (
                <p>
                    <em>Showing results for
                        <strong>{this.state.search}</strong>
                    </em>
                </p>
            )}
            {authors.map((participant) => {
                return (
                    <Participant.CardAuthor key={participant.id}
                        participant={participant}>
                        <p>
                            <a onClick={this._removeMember.bind(this, participant)}
                                className="btn btn-link red">Remove author</a>
                        </p>
                    </Participant.CardAuthor>
                )
            })}
        </div>

    }

    _removeMember(participant) {
        const course = this.props.state.course.model
        const authors = course.participants.filter(p => p.isAdmin)
        if (authors.length < 2) {
            return alert("You cannot delete the last author of a course. You can delete the course in course page, on the bottom of the page.")
        }
        if (confirm("Are you sure you want to delete " + participant.userz.first + " " + participant.userz.last + "?")) {
            this.props.actions.participant.remove(participant, function () { })
        }
    }
}

export default ReduxBinder(InfoTabsAuthors, {
    state: ["course"]
})
