
const modelOutput = {
    modelName: '',
    counter: 0,
    count: {},
    success: true,
    message: '',
    data: []
}

const copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}


export default {
    validate(course) {
        let validateCheck = []
        let models = ['organizations', 'characters', 'roles', 'variables', 'scoringTags', 'modules', 'activities'] // , 'participants', 'teams'
        let errorCount = 0
        let warningCount = 0
        let infoCount = 0
        models.map(model => {
            let currentCheck = this.validateHelpers[model](course)
            currentCheck.count.errors = currentCheck.data.filter(info => info.level === 'critical').length
            currentCheck.count.warnings = currentCheck.data.filter(info => info.level === 'warning').length
            currentCheck.count.info = currentCheck.data.filter(info => info.level === 'info').length
            errorCount = errorCount + currentCheck.count.errors
            warningCount = warningCount + currentCheck.count.warnings
            infoCount = infoCount + currentCheck.count.info
            validateCheck.push(currentCheck)
        })

        return {
            success: errorCount === 0,
            data: validateCheck,
            count: {
                errors: errorCount,
                warnings: warningCount,
                info: infoCount
            }
        }
    },
    validateHelpers: {
        organizations(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'organizations'
            output.counter = course.organizations.length
            return output
        },
        characters(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'characters'
            output.counter = course.characters.length
            // Check relations organization
            // course.characters.map(character => {
            // if (character.organizationStaticId && !course.byStaticId.organizations[character.organizationStaticId]) {
            //     output.data.push({
            //         message: `Character ${character.first} ${character.last} has an organization which cannot be found`,
            //         model: character,
            //         level: 'warning'
            //     })
            // }
            // })
            return output
        },
        roles(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'roles'
            output.counter = course.roles.length
            if (output.counter === 0) {
                output.data.push({
                    message: `No roles found. You cannot have a course with no roles, please create a role`,
                    level: 'warning'
                })
            }
            // course.roles.map(role => {
            //     if (role.organizationStaticId && !course.byStaticId.organizations[role.organizationStaticId]) {
            //         output.data.push({
            //             message: `A role has an organization assigned to it which can't be found`,
            //             level: 'warning'
            //         })
            //     }
            // })
            return output
        },
        variables(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'variables'
            output.counter = course.variables.length
            return output
        },
        scoringTags(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'scoringTags'
            output.counter = course.scoringTags.length
            return output
        },
        modules(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'modules'
            output.counter = course.modules.length
            if (output.counter === 0) {
                output.data.push({
                    message: `No modules found. You cannot have a course with no modules, please create a module`,
                    level: 'critical'
                })
            }
            course.modules.map(module => {
                if (module.isDraft) {
                    output.data.push({
                        message: `Module > ${module.name} is still in draft mode, participants will not see it.`,
                        model: module,
                        level: 'info'
                    })
                }
            })

            return output
        },
        activities(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'activities'
            output.counter = course.activities.length
            course.activities.map(activity => {
                if (activity.type === 'email' || activity.type === 'chat') {
                    if (!activity.roleStaticId) {
                        output.data.push({
                            message: `Activity > ${activity.name} has no role assigned to it, so no one will receive the email.`,
                            model: activity,
                            level: 'warning'
                        })
                    }
                    if (!activity.characterStaticId) {
                        output.data.push({
                            message: `Activity > ${activity.name} has no character assigned to it.`,
                            model: activity,
                            level: 'warning'
                        })
                    }
                    if (activity.characterStaticId && !course.byStaticId.characters[activity.characterStaticId]) {
                        output.data.push({
                            message: `Activity > ${activity.name} has a character assigned which cannot be found.`,
                            model: activity,
                            level: 'warning'
                        })
                    }
                    if (activity.formStaticId && !course.byStaticId.forms[activity.formStaticId]) {
                        output.data.push({
                            message: `Activity > ${activity.name} has a form assigned which cannot be found.`,
                            model: activity,
                            level: 'warning'
                        })
                    }
                }

                if (activity.type === 'rolePlay') {
                    if (!activity.data || !activity.data.settings) {
                        output.data.push({
                            message: `Activity > ${activity.name} doesn't seem to have been configured.`,
                            model: activity,
                            level: 'warning'
                        })
                    } else {
                        let settings = activity.data.settings
                        if (settings.globalFormStaticId && !course.byStaticId.forms[settings.globalFormStaticId]) {
                            output.data.push({
                                message: `Activity > ${activity.name} has a global form which cannot be found.`,
                                model: activity,
                                level: 'criticial'
                            })
                        }
                        if (settings.parties && settings.parties.length === 0) {
                            output.data.push({
                                message: `Activity > ${activity.name} doesn't seem to have any parties`,
                                model: activity,
                                level: 'warning'
                            })
                        }
                        if (settings.parties) {
                            settings.parties.map(party => {
                                if (party.roleStaticId && !course.byStaticId.roles[party.roleStaticId]) {
                                    output.data.push({
                                        message: `Activity > ${activity.name} has a party with a role that cannot be found`,
                                        model: activity,
                                        level: 'criticial'
                                    })
                                }
                                if (party.formStaticId && !course.byStaticId.forms[party.formStaticId]) {
                                    output.data.push({
                                        message: `Activity > ${activity.name} has a party with a form which cannot be found.`,
                                        model: activity,
                                        level: 'criticial'
                                    })
                                }
                            })
                        }
                    }
                }

                if (!course.isTemplate) {
                    // Check participants in role play
                    if (activity.type === 'rolePlay') {
                        if (!activity.data || !activity.data.rolePlay) {
                            output.data.push({
                                message: `Activity > ${activity.name} doesn't seem to have to participants.`,
                                model: activity,
                                level: 'warning'
                            })
                        } else {
                            if (activity.data.rolePlay.length === 0) {
                                output.data.push({
                                    message: `Activity > ${activity.name} doesn't seem to have to participants.`,
                                    model: activity,
                                    level: 'warning'
                                })
                            }
                            activity.data.rolePlay.map(rp => {
                                if (!course.byId.participants[rp.participantId]) {
                                    output.data.push({
                                        message: `Activity > ${activity.name} has a participant which cannot be found`,
                                        model: activity,
                                        level: 'criticial'
                                    })
                                }
                            })
                        }
                    }

                }
            })
            return output
        },
        participants(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'participants'
            let participants = course.participants.filter(part => !part.isAdmin && !part.isActor)
            output.counter = participants.length
            if (!course.isTemplate) {
                // If not a template, then there should be participants
                if (participants.length === 0) {
                    output.data.push({
                        message: "You cannot run a course without participants. Please add participants",
                        level: 'warning'
                    })
                }
                participants.map(part => {
                    // Check role
                    if (course.participantsCanChooseRoles) {
                        if (part.roles.length === 0) {
                            output.data.push({
                                message: `Participant ${part.displayName.short} has no role assigned.`,
                                model: part,
                                level: 'warning'
                            })
                        }
                    }
                    if (part.roles.length > 0) {
                        let roleNotFound = false
                        part.roles.map(roleStaticId => {
                            if (!course.byStaticId.roles[roleStaticId]) {
                                roleNotFound = true
                            }
                        })
                        if (roleNotFound) {
                            output.data.push({
                                message: `Participant ${part.displayName.short} is assigned a role which cannot be found. Please give them a role.`,
                                model: part,
                                level: 'warning'
                            })
                        }
                    }
                    // Check teams
                    if (course.teams.length > 0) {
                        if (!part.teamId) {
                            output.data.push({
                                message: `Participant ${part.displayName.short} has no team. Please assign them a team.`,
                                model: part,
                                level: 'warning'
                            })
                        }
                        if (part.teamId && !course.byId.teams[part.teamId]) {
                            output.data.push({
                                message: `Participant ${part.displayName.short} is assigned a team which cannot be found. Please check.`,
                                model: part,
                                level: 'warning'
                            })
                        }
                    }
                })
            }
            return output
        },
        teams(course) {
            let output = copyObject(modelOutput)
            output.modelName = 'teams'
            output.counter = course.teams.length
            // course.teams.map(team => {
            //     if (team.organizationId && !course.byId.organizations[team.organizationId]) {
            //         output.data.push({
            //             message: `Team ${team.name} is assigned an organization which cannot be found. Please check`,
            //             model: team,
            //             level: 'warning'
            //         })
            //     }
            // })
            return output
        }
    }
}