import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FileUploaderWithProgress, Input, VideoUpload, VideoPlayer, FileUpload, FileIcons } from 'alias-components'
import scripts from 'alias-tools/scripts'
import api from 'alias-tools/api'


const mergeOptions = [
    "to_first_name",
    "to_last_name",
    "to_title",
    'from_first_name',
    'from_last_name',
    'from_title'
]

export default class ActivityTypeEmail extends Component {

    static propTypes = { //eslint-disable-line
        activity: PropTypes.object,
        docs: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
        type: PropTypes.string
    }
    static defaultProps = {  //eslint-disable-line
        docs: []
    }

    constructor(props) {
        super(props)
        this.state = {
            docsBeingUploaded: [],
            isRemovingVideo: false
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.activity && this.state.activity.id != newProps.activity.id) {
            this.setState({
                docsBeingUploaded: []
            })
        }
    }

    _getTo(course) {
        let to = [
            {
                label: "All students",
                value: "all"
            },
            {
                label: 'ROLES',
                options: course.roles.map(role => {
                    return {
                        label: role.name,
                        value: role.staticId
                    }
                })
            }
        ]

        if (course.teams.length > 0 && !course.isTemplate) {
            to.push({
                label: 'TEAMS',
                options: course.teams.map(team => {
                    return {
                        label: team.name,
                        value: team.id
                    }
                })
            })
        }
        if (course.organizations.length > 0) {
            to.push({
                label: 'ORGANIZATIONS',
                options: course.organizations.map(org => {
                    return {
                        label: org.name,
                        value: org.staticId
                    }
                })
            })

        }
        return to
    }

    render() {
        const { props } = this
        let { onChange, activity, state } = this.props
        const course = state.course.model

        // Add organization info to characters
        var characters = course.characters.map((character) => {
            if (character.organizationStaticId && course.byStaticId.organizations[character.organizationStaticId]) {
                character.organization = course.byStaticId.organizations[character.organizationStaticId]
            }
            return character
        })

        const to = this._getTo(course)

        activity.data.videos = activity.data.videos ? activity.data.videos : []
        activity.data.uploadedVideos = activity.data.uploadedVideos ? activity.data.uploadedVideos : []

        let rolePlayUrl = ""

        let customMergeOptions = [...mergeOptions]
        if (props.type === 'rolePlay') {
            rolePlayUrl = `${scripts.URL()}/course/my/${course.id}/role-plays/${props.activity.id}`
        }

        return <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-3 control-label">From</label>
                <div className="col-sm-9">
                    <Input
                        type="select"
                        name="characterStaticId"
                        value={activity.characterStaticId}
                        placeholder="Please select a sender"
                        onChange={onChange}
                        options={characters.map(character => {
                            return {
                                label: `${character.first} ${character.last} (${character.title} ${character.organization ? "at " + character.organization.name : ''})`,
                                value: character.staticId
                            }
                        })} />
                </div>
            </div>
            {(props.type !== 'rolePlay' && props.type !== 'triggered-email') && <div className="form-group">
                <label className="col-sm-3 control-label">To</label>
                <div className="col-sm-9">
                    <Input
                        type="select"
                        name="roleStaticId"
                        value={activity.roleStaticId}
                        onChange={onChange}
                        placeholder="Please select a recipient"
                        options={to} />
                </div>
            </div>}
            <div className="form-group">
                <label className="col-sm-3 control-label">Subject</label>
                <div className="col-sm-9">
                    <Input
                        name="subject"
                        value={activity.subject}
                        onChange={onChange}
                        type="text"
                        placeholder="Subject..." />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Body</label>
                <div className="col-sm-9">
                    <Input
                        type="textarea"
                        name="body"
                        onChange={onChange}
                        value={activity.body}
                        placeholder="Email body..." />
                    <select value="" ref="mailMergeTags" onChange={this._insertTag.bind(this)}
                        className="form-control" style={{ marginBottom: props.type === 'rolePlay' ? 10 : 0 }}>
                        <option value="" disabled>Insert a mail merge tag</option>
                        {customMergeOptions.map(tag => {
                            return (<option key={tag}>{tag}</option>)
                        })}
                    </select>
                    {props.type === 'rolePlay' && <p className="alert alert-info">
                        <i className="fa fa-bullhorn" /> Here is the link to the role play that you can copy and paste into the message:<br />
                        {rolePlayUrl}
                    </p>}
                </div>
            </div>
            {activity.id && <div>
                <div className="form-group">
                    <label className="col-sm-3 control-label">Form link</label>
                    <div className="col-sm-9">
                        <Input type="select"
                            options={course.forms.map(form => {
                                return {
                                    label: form.name,
                                    value: form.staticId
                                }
                            })}
                            placeholder="Select a form (Optional)"
                            ref="formLink"
                            name="embeddedFormStaticId"
                            description="Will add a link to a form at the end of the email"
                            value={activity.data.formLink}
                            onChange={(name, value) => {
                                let data = activity.data
                                data.formLink = value
                                this.props.onChange('data', data)
                            }} />
                    </div>
                </div>

                {false && (
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Attachments (old)</label>
                        <div className="col-sm-9">

                            {(this.state.docsBeingUploaded.length > 0 || props.docs.length > 0) && (
                                <ul className="list-unstyled" style={{ lineHeight: '25px', paddingTop: '5px', marginBottom: 0 }}>
                                    {this.state.docsBeingUploaded.map((doc) => {
                                        return (
                                            <li key={doc.staticId} style={{ paddingBottom: '10px' }}>
                                                <i className="fa fa-file" /> {doc.name}
                                                <FileUploaderWithProgress doc={doc} actions={this.props.actions} onComplete={this._finishedUploading.bind(this)} />
                                            </li>
                                        )
                                    })}
                                    {props.docs.map(doc => {
                                        return (
                                            <li key={doc.staticId} style={{ paddingBottom: '10px' }}>
                                                <i className="fa fa-file" /> {doc.name}
                                                <a onClick={this._deleteDoc.bind(this, doc)} className={'pull-right red'}>
                                                    <i className="fa fa-times" />
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}
                            <a onClick={(e) => {
                                e.preventDefault()
                                this.refs.files.click()
                            }} className="btn btn-link">Attach a file</a>
                            <input
                                onChange={this._setUploadDocs.bind(this)}
                                ref="files" type="file"
                                multiple="multiple" />

                        </div>
                    </div>
                )}

                <div className="form-group">
                    <label className="col-sm-3 control-label">Attachments</label>
                    <div className="col-sm-9">
                        {(props.docs.length > 0) && (
                            <div>
                                <ul className="list-unstyled" style={{ lineHeight: '25px', paddingTop: '5px', marginBottom: 0 }}>
                                    {props.docs.map(doc => {
                                        return (
                                            <li key={doc.staticId} className="red" style={{ paddingBottom: '10px' }}>
                                                <i className="fa fa-file" /> {doc.name}
                                                <a onClick={this._deleteDoc.bind(this, doc)} className={'pull-right red'}>
                                                    <i className="fa fa-times" />
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}

                        {activity.data.docs && activity.data.docs.length > 0 && (
                            <table className="table table-striped-table-hover">
                                <tbody>
                                    {activity.data.docs.map(doc => {
                                        return <tr key={doc.id}>
                                            <td>
                                                <a target="_blank" href={doc.path}><FileIcons name={doc.name} height={80} isProcessing={true} /> {doc.name}</a>
                                                <a className="red pull-right" onClick={this._onRemoveFile.bind(this, doc)}>Remove</a>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        )}

                        <FileUpload courseId={props.activity.courseId} type='core' onComplete={(doc) => this._onFileComplete(doc)} />

                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-3 control-label">Video links (for auto embed)</label>
                    <div className="col-sm-9">
                        {activity.data.videos.map((video, i) => {
                            return (
                                <div className="row" key={i}>
                                    <div className="col-xs-10">
                                        <input
                                            type="text" className="form-control"
                                            placeholder="Paste Youtube or Vimeo url here..."
                                            value={video || ''}
                                            ref={`video-${i}`}
                                            onChange={this._videoChange.bind(this, i)} />
                                    </div>
                                    <div className="col-xs-2">
                                        {/*{status}*/}
                                        <a onClick={this._videoRemove.bind(this, i)}
                                            style={{
                                                lineHeight: '34px'
                                            }} className="red"><i className="fa fa-trash" /></a>
                                    </div>
                                </div>)
                        })}


                        <a onClick={() => {
                            let data = activity.data
                            data.videos = data.videos || []
                            data.videos.push('')
                            onChange('data', data)
                        }} className="btn btn-link">Add a video</a>

                    </div>
                </div>
                {false && <div className="form-group">
                    <label className="col-sm-3 control-label">Upload a video</label>
                    <div className="col-sm-9">
                        {activity.data.uploadedVideos.map((video, i) => {
                            return <div key={`uploaded-${i}`}>
                                <VideoPlayer url={video} />
                                <p><a disabled={state.isRemovingVideo} onClick={this._onRemoveVideo.bind(this)} className="btn red">{state.isRemovingVideo ? <i className="fa fa-spinner fa-pulse" /> : "Remove video"}</a></p>
                            </div>
                        })}
                        {(activity.data.uploadedVideos.length === 0 && false) && <VideoUpload courseId={props.activity.courseId} type="activity" onComplete={this._onVideoUploadComplete.bind(this)} />}
                    </div>
                </div>}
            </div>}
        </div>

    }

    _onFileComplete(doc) {
        let activity = this.props.activity
        let data = activity.data
        data.docs = data.docs || []
        data.docs.push({
            id: scripts.randomString(),
            ...doc
        })
        this.props.onChange('data', data)
    }
    _onRemoveFile(doc) {
        api(
            '/files',
            'DELETE',
            { url: doc.path }
        )
            .then(() => {
                let activity = this.props.activity
                let data = activity.data
                data.docs = data.docs.filter(d => d.id !== doc.id)
                this.props.onChange('data', data)
            })
            .catch(() => {
                alert("Couldn't delete the file, please try again.")
            })

    }

    _onVideoUploadComplete(url) {
        let activity = this.props.activity
        let data = activity.data
        data.uploadedVideos = data.uploadedVideos || []
        data.uploadedVideos.push(url)
        this.props.onChange('data', data)
    }

    _onRemoveVideo() {
        this.setState({ isRemovingVideo: true })
        api(
            '/video/remove',
            'GET',
            { url: this.props.activity.data.uploadedVideos[0] }
        )
            .then(() => {
                this.setState({ isRemovingVideo: false })
                let activity = this.props.activity
                let data = activity.data
                data.uploadedVideos = []
                this.props.onChange('data', data)
            })
            .catch(() => {
                this.setState({ isRemovingVideo: false })
            })
    }

    _videoChange(i) {
        let activity = this.props.activity
        let data = activity.data
        data.videos = data.videos || []
        data.videos[i] = this.refs[`video-${i}`].value
        this.props.onChange('data', data)
    }

    _videoRemove(i) {
        let activity = this.props.activity
        let data = activity.data
        delete data.videos[i]
        this.props.onChange('data', data)
    }

    _insertTag() {
        var activity = this.props.activity
        let body = activity.body + " {*" + this.refs.mailMergeTags.value + "*}"
        this.props.onChange('body', body)
    }

    //
    // FOR DOCS
    //
    _finishedUploading(finishedUploadingDoc) {
        var docsBeingUploaded = this.state.docsBeingUploaded.filter(doc => {
            return doc.staticId != finishedUploadingDoc.staticId
        })
        this.setState({
            docsBeingUploaded: docsBeingUploaded
        })
    }

    _setUploadDocs(e) {
        const course = this.props.state.course.model
        let fileList = []
        for (var k in e.target.files) {
            if (typeof e.target.files[k] == "object") {
                let staticId = scripts.randomString()
                var model = {
                    staticId: staticId,
                    activityStaticId: this.props.activity.staticId,
                    name: e.target.files[k].name,
                    size: e.target.files[k].size,
                    courseId: course.id,
                    file: e.target.files[k]
                }
                fileList.push(model)
            }
        }
        this.refs.files.value = ""
        this.setState({
            docsBeingUploaded: this.state.docsBeingUploaded.concat(fileList)
        })
    }

    _deleteDoc(doc) {
        if (confirm("Are you sure you want to delete " + doc.name + "?")) {
            this.props.actions.doc.remove(doc)
        }
    }
}