import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Search } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import Variable from '../../components/Variable'

class CourseConfigurationTabVariables extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            search: ''
        }
    }

    render() {
        const course = this.props.state.course.model;
        const { variableId } = this.props.match.params;

        let variables = course.variables;

        if (this.state.search !== '') {
            let search = this.state.search.toLowerCase();
            variables = variables.filter((variable) => {
                return variable.name.toLowerCase().indexOf(search) !== -1;
            })
        }

        variables = variables.map(variable => {
            variable.questionsWithVariableCount = 0;
            variable.forms = course.forms.filter(form => {
                let addForm = false;
                form.questions.map(question => {
                    if (question.variableStaticId === variable.staticId) {
                        addForm = true;
                        variable.questionsWithVariableCount++;
                    }
                })
                return addForm;
            })
            return variable;
        });

        return (
            <div>
                <Helmet title={course.name + " variables"} />
                <div className="page-header">
                    <Search search={this.state.search}
                        onChange={(search) => this.setState({ search: search })} />
                    <a onClick={this._openModal.bind(this, null)} className="btn btn-primary pull-right"><i className="fa fa-plus"></i> Add variable</a>

                </div>

                {this.state.search === '' && (
                    <p className="help-block" style={{ marginBottom: '15px' }}><i className="fa fa-info" style={{ marginRight: '5px' }} /> Use formsto have the participants have impacts on the variables.</p>
                )}

                {this.state.search !== '' && (
                    <p style={{ marginBottom: '15px' }}><em>Showing results for <strong>{this.state.search}</strong></em></p>
                )}

                {variables.length === 0 && (
                    <div className="alert alert-info">
                        <strong>No variables</strong>
                        {this.state.search === '' && (
                            <p>Click the "Add" button on the top right.</p>
                        )}
                    </div>
                )}

                {variables.map(variable => {
                    return (
                        <Variable.Card variable={variable}
                            key={variable.id}
                            onEditClick={this._openModal.bind(this, variable.id)}>
                            <p className="help-block">
                                <i className="fa fa-info" /> Forms: {variable.forms.length} - Questions: {variable.questionsWithVariableCount}
                            </p>
                        </Variable.Card>
                    )
                })}

                <Variable.Modal isOpen={variableId !== undefined}
                    handleClose={this._closeModal.bind(this)}
                    openModal={this._openModal.bind(this)}
                    variableId={variableId}
                    state={this.props.state}
                    actions={this.props.actions} />

            </div>
        );
    }

    _openModal(variableId) {
        const course = this.props.state.course.model;
        this.props.history.push(`/course/configuration/${course.id}/variables/${variableId === null ? 'new' : variableId}`)
    }

    _closeModal() {
        const course = this.props.state.course.model;
        this.props.history.push(`/course/configuration/${course.id}/variables`)
    }

}



export default ReduxBinder(CourseConfigurationTabVariables, {
    state: ['course', 'variable']
})