import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { Card, CircularProgress } from 'alias-components'
import VariableGraph from '../Variable/VariableGraph'
import FormResults from '../Form/FormResults'
import Scoring from '../Scoring'
import Messaging from '../Messaging'
import Team from '../Team'
import Masonry from 'react-masonry-css'
import ValueGraph from '../Value/ValueGraph'
import moment from 'moment-timezone'

const maxScore = 5
const progressSize = 80
const progressStrokeWidth = 10

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
}
const breakpointColumnsObjModal = {
    default: 1
}
const defaultShownFormLogs = 10


export default class ParticipantReview extends Component {

    static propTypes = {
        participant: PropTypes.object,
        team: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool,
        isModal: PropTypes.bool
    }

    static defaultProps = {
        isAdmin: false,
        isModal: false
    }

    constructor(props) {
        super(props)
        this.state = {
            isModalFormOpen: false,
            currentForm: null,
            currentLog: null,
            shownFormLogsCount: defaultShownFormLogs
        }
    }

    // UNSAFE_componentWillMount() {
    //     startLoadTime = moment()
    //     console.log(`Component will mount: ${startLoadTime.format()}`)
    // }

    // componentDidMount() {
    //     let finishedTime = moment()
    //     console.log(`Component  did mount: ${finishedTime.format()}`)
    //     console.log(`Time diferencial ${finishedTime.diff(startLoadTime)} miliseconds`)
    // }

    render() {
        const { props, state } = this
        const course = props.state.course.model
        const { participant, team, isAdmin } = props
        const courseRefresh = props.state.course.lastRefresh
        // console.log(props.actions)
        let participantHasTeam = false

        if (!participant && !team) {
            return <div>No participant or team selected.</div>
        }

        // Score
        let overallScore = null
        let teamOverallScore = null
        let participantList = []
        let activities = []
        let participantScore = false


        // Activities
        course.activities.map(parentActivity => {
            if (parentActivity.parentStaticId) {
                return
            }
            course.activities.map(childActivity => {
                if (childActivity.parentStaticId === parentActivity.staticId) {
                    childActivity.moduleStaticId = parentActivity.moduleStaticId
                    activities.push(childActivity)
                }
            })
        })
        // Get scoring
        if (participant) {
            // eslint-disable-next-line no-undefined
            overallScore = (participant.score !== undefined && participant.score !== false) ? participant.score : Scoring.Tools.participant(participant, course.activities, course.activityLogs, course.forms, course.scoringTags)
            participantHasTeam = (participant.teamId) ? true : false
            // Participation
            const mails = Messaging.Tools.getThreadsForParticipant(course, 'email', participant, false, course.activities)
            const chats = Messaging.Tools.getThreadsForParticipant(course, 'chat', participant, false, course.activities)
            const messagingActivities = [
                ...mails,
                ...chats
            ]
            const readLogs = course.activityLogs.filter(l => l.type === 'read' && l.participantId === participant.id && l.activityStaticId)
            participantScore = Scoring.Tools.participation.participant(participant, course, messagingActivities, readLogs.length)
        }
        if (team || participant.teamId) {
            participantHasTeam = true
            const teamId = team ? team.id : participant.teamId ? participant.teamId : null
            course.participants.map(part => {
                if (part.teamId === teamId) {
                    participantList.push(part.id)
                }
            })
            let userTeam = course.byId.teams[teamId]
            teamOverallScore = userTeam.score ? userTeam.score : Scoring.Tools.team(teamId, course.participants, course.activities, course.activityLogs, course.forms, course.scoringTags)
        } else {
            participantList.push(participant.id)
        }



        if (team) {
            overallScore = team.score ? team.score : Scoring.Tools.team(team.id, course.participants, course.activities, course.activityLogs, course.forms, course.scoringTags)
        }

        // Forms
        const formActivityLogs = course.activityLogs.filter(log => log.type === 'form')
        let formsYouCompleted = []
        if (participant) {
            formsYouCompleted = formActivityLogs.filter(log => {
                return log.data && log.fromParticipantId && log.fromParticipantId === participant.id
            })
        }

        let logsForFormsCompletedForYouShowMore = false
        let logsForFormsCompletedForYou = formActivityLogs.filter(log => {
            if (participantList.indexOf(log.participantId) !== -1) { return true }
            let teamId = team ? team.id : participant.teamId ? participant.teamId : null
            if (teamId && teamId === log.participantId) { return true }
            return false
        })
        let logsForFormsCompletedForYouTotal = logsForFormsCompletedForYou.length
        if (logsForFormsCompletedForYou.length > state.shownFormLogsCount) {
            logsForFormsCompletedForYouShowMore = true
            logsForFormsCompletedForYou = logsForFormsCompletedForYou.splice(0, state.shownFormLogsCount)
        }

        let badgesInfo = []
        if (participant) {
            badgesInfo = participant.badgesInfo || []
        }
        if (team) {
            badgesInfo = team.badgesInfo || []
        }

        let areGraphsShown = true
        if (props.isAdmin) {
            let graphCounter = 0
            // graphCounter += course.variables.length
            graphCounter += course.values.length
            if ((team || participantHasTeam) && course.teamGraphs && course.teamGraphs.length > 0) {
                graphCounter += course.teamGraphs.length
            }
            if (graphCounter > 4) {
                areGraphsShown = false
            }
        }

        const participantScoreDisplay = <div className="row">
            <div className="col-xs-3">
                <CircularProgress
                    percentage={participantScore.score}
                    size={progressSize}
                    strokeWidth={progressStrokeWidth} />
            </div>
            <div className="col-xs-9">
                <p>An index of your participation on the platform.<br />
                    To improve your index:</p>
                <ul>
                    <li>Read all your emails and chats</li>
                    <li>Answer all questions in the emails/chats</li>
                    <li>Answer all questions in forms and role plays</li>
                </ul>
            </div>
        </div>

        return <div className="row" ref="wrapper">
            <div className="col-sm-12">

                {(props.isAdmin || (!props.isAdmin && course.showDashboardScores)) && <div className="scoring">
                    <h2 style={{ paddingTop: 0 }}>Scoring</h2>

                    <div className="row">
                        <div className="col-xs-12 col-sm-6">

                            <Card title="Overall">
                                <div className="score">
                                    <h3>Your score</h3>
                                    {!course.scoringAverage && <span className="value" style={{ lineHeight: progressSize + 'px', fontSize: progressSize / 3 }}>
                                        {(overallScore && overallScore.total) ? overallScore.total + 'pts' : 'No pts'}
                                    </span>}
                                    {course.scoringAverage && <div>
                                        {(overallScore && overallScore.count > 0 && overallScore.score > 0) ?
                                            <CircularProgress value={overallScore.score} max={maxScore} size={progressSize}
                                                strokeWidth={progressStrokeWidth} /> :
                                            <CircularProgress size={progressSize} strokeWidth={progressStrokeWidth} />}
                                        <p className="text-centered"><strong>{(overallScore && overallScore.total) ? overallScore.total + 'pts' : 'No pts'}</strong></p>
                                    </div>}
                                </div>
                                {(participantHasTeam) && <div className="score">
                                    <h3>Team score</h3>
                                    {!course.scoringAverage && <span className="value" style={{ lineHeight: progressSize + 'px', fontSize: progressSize / 3 }}>
                                        {(teamOverallScore && teamOverallScore.total) ? teamOverallScore.total + 'pts' : 'No pts'}
                                    </span>}
                                    {course.scoringAverage && <div>
                                        {(teamOverallScore && teamOverallScore.count > 0 && teamOverallScore.score > 0) ?
                                            <CircularProgress value={teamOverallScore.score} max={maxScore} size={progressSize}
                                                strokeWidth={progressStrokeWidth} /> :
                                            <CircularProgress size={progressSize} strokeWidth={progressStrokeWidth} />}
                                        <p className="text-centered"><strong>{(teamOverallScore && teamOverallScore.total) ? teamOverallScore.total + 'pts' : 'No pts'}</strong></p>
                                    </div>}

                                </div>}
                                <div className="clear"></div>
                            </Card>

                            {(participantScore && !isAdmin && course.showDashboardScoringTags && overallScore.scoringTags && overallScore.scoringTags.length > 0) && <Card title="Participation index">
                                {participantScoreDisplay}
                            </Card>}

                        </div>
                        <div className="col-xs-12 col-sm-6">
                            {(course.showDashboardScoringTags && overallScore.scoringTags && overallScore.scoringTags.length > 0) && <Card title="Your competencies">
                                {overallScore.scoringTags.map((tag, i) => {
                                    return <div className="score" key={i}>
                                        <h3>{tag.name}</h3>
                                        {!course.scoringAverage && <div>
                                            <span className="value" style={{ lineHeight: progressSize + 'px', fontSize: progressSize / 3 }}>
                                                {(tag.totalScore === 'number' && tag.scoreCount > 0) ? tag.totalScore + 'pts' : 'No pts'}
                                            </span>
                                        </div>}
                                        {course.scoringAverage && <div>
                                            {(typeof tag.score !== 'number' || tag.scoreCount === 0) && (
                                                <CircularProgress
                                                    size={progressSize}
                                                    strokeWidth={progressStrokeWidth}
                                                    type="none" />
                                            )}
                                            {(typeof tag.score === 'number' && tag.scoreCount > 0) && (
                                                <CircularProgress
                                                    value={tag.score}
                                                    max={maxScore}
                                                    size={progressSize}
                                                    strokeWidth={progressStrokeWidth} />
                                            )}
                                            <p className="text-centered"><strong>{tag.totalScore}pt{tag.totalScore > 1 ? 's' : ''}</strong></p>
                                        </div>}
                                    </div>
                                })}
                                <div className="clear"></div>
                            </Card>}
                            {(participantScore && !isAdmin && (!course.showDashboardScoringTags || !overallScore.scoringTags || (overallScore.scoringTags && overallScore.scoringTags.length === 0))) && <Card title="Participation index">
                                {participantScoreDisplay}
                            </Card>}
                        </div>
                    </div>




                    {Object.keys(badgesInfo).filter(b => !isAdmin ? course.byStaticId.badges[b].isVisibleToParticipant : true).length > 0 && <Card title="Badges">
                        <p>
                            {Object.keys(badgesInfo).filter(b => isAdmin ? true : course.byStaticId.badges[b].isVisibleToParticipant)
                                .map(badgeStaticId => {
                                    let badge = course.byStaticId.badges[badgeStaticId]
                                    if (!badge.description) {
                                        return <span key={badgeStaticId} className="badge info" style={{ marginRight: 5, padding: '5px 8px' }}>
                                            {course.byStaticId.badges[badgeStaticId].name} {!isAdmin ? '' : badge.isVisibleToParticipant ? `(Visible)` : '(NOT visible)'}
                                        </span>
                                    }
                                    return <OverlayTrigger key={badgeStaticId}
                                        trigger={['hover', 'focus']}
                                        placement="bottom"
                                        overlay={<Popover id={`badge-tooltip-${badge}`} title={badge.name}>{badge.description}</Popover>}>
                                        <span className="badge info" style={{ marginRight: 5, padding: '5px 8px' }}>
                                            {course.byStaticId.badges[badgeStaticId].name} {!isAdmin ? '' : badge.isVisibleToParticipant ? `(Visible)` : '(NOT visible)'}
                                        </span>
                                    </OverlayTrigger>
                                })}
                        </p>
                    </Card>}

                </div>}
            </div>
            <div className="col-sm-12">
                {(false && course.variables.length > 0) && <div>
                    <h2>Variables</h2>
                    <Masonry
                        breakpointCols={props.isModal ? breakpointColumnsObjModal : breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {course.variables.map(variable => {
                            return <div key={variable.id}>
                                <Card title={variable.name}>
                                    {variable.description && <p className="help-block">{variable.description}</p>}
                                    <VariableGraph
                                        state={this.props.state}
                                        participant={participant}
                                        team={team}
                                        variable={variable}
                                        openModal={this._openModal.bind(this)} />
                                </Card>
                            </div>
                        })}
                    </Masonry>
                </div>}

                {(course.values.length > 0) && <div>
                    <h2>Values</h2>
                    <Masonry
                        breakpointCols={props.isModal ? breakpointColumnsObjModal : breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {course.values.map(value => {
                            return <div key={value.id}>
                                <Card title={value.name}>
                                    {value.description && <p className="help-block">{value.description}</p>}
                                    <ValueGraph
                                        state={this.props.state}
                                        participant={participant}
                                        team={team}
                                        value={value}
                                        graphIsShown={areGraphsShown}
                                        openModal={this._openModal.bind(this)} />
                                </Card>
                            </div>
                        })}
                    </Masonry>
                </div>}

                {((team || participantHasTeam) && course.teamGraphs && course.teamGraphs.length > 0) && <div>
                    <h2>
                        Team Graphs
                        {false && <small className="pull-right" style={{ fontSize: 12 }}>
                            Last refresh: {moment(courseRefresh).fromNow()}<br />
                            {props.actions && <a onClick={() => (
                                props.actions.course.load(course.id, () => { }, true)
                            )}>Refresh data</a>}
                        </small>}
                    </h2>
                    <a style={{ opacity: 0, visibility: 'hidden', float: 'right', width: 0, height: 0, padding: 0, margin: 0 }} ref="downloadGraph">Download graph</a>
                    <Masonry
                        breakpointCols={props.isModal ? breakpointColumnsObjModal : breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {course.teamGraphs.map(graph => <div key={graph.id}>
                            <Card title={graph.name}>
                                {graph.description && <p className="help-block">{graph.description}</p>}
                                <Team.Graph
                                    team={team ? team : course.byId.teams[participant.teamId]}
                                    graph={graph}
                                    isGraphShown={areGraphsShown} />
                                <a onClick={this._downloadGraphCSV.bind(this, graph, team ? team : course.byId.teams[participant.teamId])}>Download csv</a>
                            </Card>
                        </div>)}
                    </Masonry>
                </div>}


                {(course.forms.length > 0 && (props.isAdmin || (!props.isAdmin && course.showDashboardForms))) && <div>
                    <h2>Forms ({logsForFormsCompletedForYouTotal})</h2>
                    <Card>
                        <div>
                            <table className="table table-hover table-striped" style={{ marginBottom: 0 }}>
                                <tbody>
                                    {logsForFormsCompletedForYou.length === 0 && <tr className="info">
                                        <td>No forms completed for the moment</td>
                                    </tr>}
                                    {logsForFormsCompletedForYou.map(log => {
                                        let form = course.byStaticId.forms[log.formStaticId] || null
                                        let activity = log.data && log.data.activityId && course.byId.activities[log.data.activityId] ? course.byId.activities[log.data.activityId] : null
                                        if (!form) {
                                            return <tr className="warning" key={log.id}><td>Form not found</td></tr>
                                        }
                                        return (
                                            <tr key={log.id}>
                                                <td>
                                                    <strong>{form.name}{activity ? ` (Activity: ${activity.name})` : ''}</strong><br />
                                                    FROM: {course.byId.participants[log.fromParticipantId] ? course.byId.participants[log.fromParticipantId].displayName.bigTeamThenName : "Not found"} <i className="fa fa-chevron-right"></i>
                                                    TO: {course.byId.participants[log.participantId] ? course.byId.participants[log.participantId].displayName.bigTeamThenName : course.byId.teams[log.participantId] ? course.byId.teams[log.participantId].name : "Not found"}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    <a className="btn btn-secondary"
                                                        onClick={() => {
                                                            this.setState({ isModalFormOpen: true, currentForm: form, currentLog: log })
                                                        }}>View</a>
                                                    {isAdmin && ` / `}
                                                    {isAdmin && (
                                                        <a href={`/form/${course.id}/${form.staticId}/${log.id}`}
                                                            target="_blank">Edit</a>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {logsForFormsCompletedForYouShowMore && (
                                <div>
                                    <a onClick={() => this.setState({ shownFormLogsCount: state.shownFormLogsCount + defaultShownFormLogs })}>Show more <i className="fa fa-caret-down" /></a>
                                    {state.shownFormLogsCount > defaultShownFormLogs && <a onClick={() => this.setState({ shownFormLogsCount: state.shownFormLogsCount - defaultShownFormLogs })} className="margin-left-5">Show less <i className="fa fa-caret-up" /></a>}
                                </div>
                            )}
                        </div>
                    </Card>
                    {(isAdmin && formsYouCompleted.length > 0) && (
                        <Card title="Forms you graded">
                            <table className="table table-hover table-striped">
                                <tbody>
                                    {formsYouCompleted.map(log => {
                                        let form = course.forms.filter(form => form.staticId === log.formStaticId)[0]
                                        return (
                                            <tr key={log.id}>
                                                <td>
                                                    {form.name}
                                                    <a
                                                        className="pull-right btn btn-secondary"
                                                        onClick={() => {
                                                            this.setState({ isModalFormOpen: true, currentForm: form, currentLog: log })
                                                        }}>View</a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Card>
                    )}
                    {this.state.currentForm && (
                        <Modal
                            show={state.isModalFormOpen}
                            onHide={() => this.setState({ isModalFormOpen: false })}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.state.currentForm.name}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormResults
                                    course={course}
                                    form={this.state.currentForm}
                                    activityLog={this.state.currentLog} />
                            </Modal.Body>
                            <Modal.Footer>
                                <a className="btn btn-primary" onClick={() => { this.setState({ isModalFormOpen: false }) }}>Close</a>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>}
            </div>
        </div>
    }


    _openModal(form, log) {
        this.setState({ isModalFormOpen: true, currentForm: form, currentLog: log })
    }

    _downloadGraphCSV(graph, team, e) {
        e.preventDefault()
        let data = team.graphData[graph.id]
        if (!data || Object.prototype.toString.call(data) !== '[object Array]') {
            return alert("This graph doesn't have data yet.")
        }
        // SERVER DOWNLOAD
        let fileName = `${team.name.replace(' ', '_')}_${graph.name.replace(' ', '_')}.csv`

        var form = document.createElement("form")
        form.setAttribute("method", "post")
        form.setAttribute("action", '/api/download/csv')
        form.setAttribute("target", "view")

        var hiddenFileName = document.createElement("input")
        hiddenFileName.setAttribute("type", "hidden")
        hiddenFileName.setAttribute("name", "fileName")
        hiddenFileName.setAttribute("value", fileName)
        form.appendChild(hiddenFileName)
        var hiddenData = document.createElement("input")
        hiddenData.setAttribute("type", "hidden")
        hiddenData.setAttribute("name", "data")
        hiddenData.setAttribute("value", JSON.stringify(data))
        form.appendChild(hiddenData)
        document.body.appendChild(form)

        window.open('', fileName)
        form.submit()
        return false

        // OLD WAY
        // let csv = ''
        // data.forEach(function (row) {
        //     if (Object.prototype.toString.call(row) !== '[object Array]') { return }
        //     // row = row.map(cell => cell.replace(',', '-'))
        //     csv += row.join(',')
        //     csv += "\n"
        // })
        // const url = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        // const hiddenElement = e.target
        // hiddenElement.href = url
        // hiddenElement.target = '_blank'
        // hiddenElement.download = `${team.name.replace(' ', '_')}_${graph.name.replace(' ', '_')}.csv`
    }
}
