import Modal from './ValueModal'
import Card from './ValueCard'
import Configuration from './ValueConfiguration'
import Graph from './ValueGraph'
import Tools from './ValueTools'

export default {
    Modal,
    Card,
    Configuration,
    Graph,
    Tools
}