import Modal from './VariableModal'
import Card from './VariableCard'
import Configuration from './VariableConfiguration'
import Graph from './VariableGraph'
import Tools from './VariableTools'

export default {
    Modal,
    Card,
    Configuration,
    Graph,
    Tools
}