import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'

class PageImport extends Component {

    static propTypes = {
        state: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            ignoreFirstRow: true,
            modelName: '',
            courseId: '',
            total: 0,
            count: 0,
            isDone: false
        }
    }

    render() {

        const models = ['module', 'activity', 'organization', 'character', 'role'];
        const participants = this.props.state.user.model.participants;

        return (
            <div className="container" style={{ paddingTop: '30px' }}>
                <Card title="Imports">
                    <div>
                        <p>
                            File should be CSV comma separated.
                            <br /> First row is column name (make sure it's exact)
                        </p>
                        <select className="form-control"
                            ref="courseId"
                            value={this.state.courseId}
                            onChange={() => {
                                this.setState({
                                    courseId: this.refs.courseId.value
                                });
                            }}>
                            <option value="">
                                Choose a course
                            </option>
                            {participants.map((participant, i) => {
                                return <option key={i} value={participant.course.id}>
                                    {participant.course.name}
                                </option>
                            })}
                        </select>
                        <br />
                        <select className="form-control"
                            ref="modelName"
                            value={this.state.modelName}
                            onChange={() => {
                                this.setState({
                                    modelName: this.refs.modelName.value
                                });
                            }}>
                            <option value="">
                                Choose a model
                            </option>
                            {models.map((model, i) => {
                                return <option key={i} value={model}>
                                    {model}
                                </option>
                            })}
                        </select>
                        <br />
                        <input type="file"
                            ref="characters"
                            onChange={this._onChangeFile.bind(this)}
                            className="form-control" />
                        <a onClick={(e) => {
                            e.preventDefault();
                            this.refs.characters.click();
                        }} className="btn btn-default">Select file to import</a>
                    </div>
                </Card>
                {this.state.total !== 0 && (
                    <div className={this.state.isDone ? 'alert alert-success' : 'alert-warning'}>
                        Progress
                     <br />
                        {this.state.count} /
                     {this.state.total}
                    </div>
                )}
            </div>
        )
    }

    _onChangeFile(e) {
        const self = this;
        const courseId = this.state.courseId;
        const model = this.state.modelName;
        let file = e.target.files[0];
        if (file.type !== 'text/csv') {
            return alert("Please select a csv file.");
        }
        if (this.state.modelName === '') {
            return alert('Please select a model first');
        }
        self.setState({
            isDone: false,
            total: 0,
            count: 0
        });
        var reader = new FileReader();
        reader.onload = function (e) {
            let items = [];
            let attributeNames = [];
            let content = e.target.result;
            let contentArray = self._parseCSV(content);
            self.setState({
                isDone: false,
                total: contentArray.length - 1,
                count: 0
            });
            for (let i = 1; i < contentArray.length; i++) {
                self.setState({
                    count: i
                });
                let newItem = {};
                contentArray[0].map((attribute, k) => {
                    newItem[attribute.trim()] = contentArray[i][k].trim();
                });
                newItem.courseId = courseId;
                self.props.actions[model].create(newItem);
            }
            self.setState({
                isDone: true
            });
        };
        reader.readAsText(file);
    }

    _parseCSV(str) {
        var arr = [];
        var quote = false; // true means we're inside a quoted field

        // iterate over each character, keep track of current row and column (of the returned array)
        var col, c;
        for (var row = col = c = 0; c < str.length; c++) {
            var cc = str[c],
                nc = str[c + 1]; // current character, next character
            arr[row] = arr[row] || []; // create a new row if necessary
            arr[row][col] = arr[row][col] || ''; // create a new column (start with empty string) if necessary

            // If the current character is a quotation mark, and we're inside a
            // quoted field, and the next character is also a quotation mark,
            // add a quotation mark to the current column and skip the next character
            if (cc == '"' && quote && nc == '"') {
                arr[row][col] += cc;
                ++c;
                continue;
            }

            // If it's just one quotation mark, begin/end quoted field
            if (cc == '"') {
                quote = !quote;
                continue;
            }

            // If it's a comma and we're not in a quoted field, move on to the next column
            if (cc == ',' && !quote) {
                ++col;
                continue;
            }

            // If it's a newline and we're not in a quoted field, move on to the next
            // row and move to column 0 of that new row
            if (cc == '\n' && !quote) {
                ++row;
                col = 0;
                continue;
            }

            // Otherwise, append the current character to the current column
            arr[row][col] += cc;
        }
        return arr;
    }
}

export default ReduxBinder(PageImport)