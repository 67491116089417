import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading, Avatar } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'

class PageCoursePickRole extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId

        // Connecting server live updates
        this._liveUpdateListen(courseId)


        this.state = this._getState(props, {})
    }

    _getState(props, state) {
        const course = props.state.course.model

        // Get participant
        let participantId = null
        let participant = null

        if (props.state.local.game.participantId) {
            const hisParticipant = course.participants.filter(part => part.id === this.props.state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
                participantId = participant.id
            }
        } else {
            if (course && course.participants) {
                const participants = course.participants.filter(item => {
                    return !item.isAdmin && item.userz.id === this.props.state.user.model.id
                })
                if (participants.length > 0) {
                    participant = participants[0]
                    participantId = participant.id
                }
            }
        }

        let availableRoles = course.roles.filter(r => r.isSelectableByParticipant === true && r.isVisibleByParticipant === true)


        let chosenRoles = state.chosenRoles || []
        // Add to chosen the roles he already has
        if (participant && participant.roles && participant.roles.length > 0) {
            participant.roles.map(roleStaticId => {
                let role = course.byStaticId.roles[roleStaticId]
                if (role && role.isVisibleByParticipant) {
                    chosenRoles.push(role)
                }
            })
        }
        // Filter chosen roles in case another user chooses them at same time
        if (participant && participant.teamId) {
            course.participants.map(p => {
                if (p.teamId === participant.teamId && participant.id !== p.id) {
                    chosenRoles = chosenRoles.filter(r => p.roles.indexOf(r.staticId) === -1)
                    availableRoles = availableRoles.filter(r => p.roles.indexOf(r.staticId) === -1)
                }
            })
        }

        return {
            courseId: props.match.params.courseId,
            participantId: participantId,
            participant: participant,
            chosenRoles: chosenRoles,
            availableRoles: availableRoles
        }
    }


    UNSAFE_componentWillMount() {

        const course = this.props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === this.props.match.params.courseId && !participant.isAdmin
        })
        if (course.length > 0) {
            if (this.isFetching !== true) {
                this._loadCourse(this.props)
            }
        } else {
            this.props.history.push('/')
        }
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        let stateAttributesToUpdate = {}
        if (this.state.courseId !== newProps.match.params.courseId) {
            stateAttributesToUpdate.courseId = newProps.match.params.courseId
            this._loadCourse(newProps)

            this._liveUpdateListen(this.props.match.params.courseId)
        }
        this.setState({
            ...this._getState(newProps, this.state)
        })
    }

    _loadCourse(props) {
        this.isFetching = true
        const courseId = props.match.params.courseId
        const user = props.state.user.model
        let participants = user.participants.filter(p => (p.courseId === courseId && !p.isAdmin))
        if (participants.length > 0) {
            let participant = participants[0]
            props.actions.course.load(courseId, () => {
                this.isFetching = false
            }, true, participant.id)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions),
            () => {
                if (participants.length > 0) {
                    props.actions.course.load(courseId, null, true, participants[0].id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    render() {
        // COURSE LOADING
        if (!this.props.state || !this.props.actions) {
            return <Loading />
        }

        const courseState = this.props.state.course
        const course = courseState.model

        if (courseState.isFetching && !courseState.isLoaded) {
            return <Loading />
        }

        if (course.participantsCanChooseRoles === false) {
            return <div className="course-wrapper" style={{ overflow: 'auto' }}>
                <div className="container">
                    <br />
                    <br />
                    <p className="alert alert-info">You are not allowed to choose your own roles in this course</p>
                    <p className="text-center"><Link to={`/course/my/${course.id}`} className="btn btn-primary">Continue to course</Link></p>
                </div>
            </div>
        }

        const { participantId, chosenRoles, availableRoles } = this.state

        if (!participantId) {
            return <Loading />
        }
        let participant = course.participants.filter(p => p.id === participantId)[0]


        if (participant && participant.hasPickedRoles) {
            return <div className="course-wrapper" style={{ overflow: 'auto' }}>
                <div className="container">
                    <br />
                    <br />
                    <p className="alert alert-info">You have already chosen your roles. Please continue to the course</p>
                    <p className="text-center"><Link to={`/course/my/${course.id}`} className="btn btn-primary">Continue to course</Link></p>
                </div>
            </div>
        }


        let participantHasReachedMax = false
        if (course.maxRolesPerParticipant && chosenRoles.length >= course.maxRolesPerParticipant) {
            participantHasReachedMax = true
        }

        let courseWrapperClassName = 'course-wrapper'

        return (
            <div className={courseWrapperClassName} style={{ overflow: 'auto' }}>
                <Helmet title={`${course.name}`} />
                <div className="container">
                    <br />
                    <br />
                    <div className="text-center">
                        <Avatar user={course} size={200} />

                        <h3>Hello {participant.displayName.short}</h3>
                        <p>Welcome to {course.name}. We're excited to have you here.</p>
                        <p>Before you can start, you need to pick which roles you would like to take part of. <br />
                            It's first come, first serve, so hurry and choose.</p>
                        <p><Link to={`/course/my/${course.id}`} className="btn btn-secondary">Back to course</Link></p>
                        {participantHasReachedMax && <p className="alert alert-info">You have reached the maximum allowed amount of roles. Please click save and continue</p>}
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <h4>Roles you CAN choose:</h4>
                            {availableRoles.map(role => {
                                if (chosenRoles.filter(r => r.id === role.id).length > 0) {
                                    return null
                                }
                                return <div className="list-group" key={role.id}>
                                    <div className="list-group-item">
                                        <h4 className="list-group-item-heading">{role.name}</h4>
                                        {role.description && <p className="list-group-item-text">{role.description}</p>}
                                        {!role.description && <p className="list-group-item-text help-block">No description</p>}
                                        <br />
                                        {!participantHasReachedMax && <a onClick={this._onChoose.bind(this, role)} className="btn btn-default">Choose</a>}
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="col-sm-6">
                            <h4>Roles you HAVE chosen:</h4>
                            {chosenRoles.length === 0 && <p className="alert alert-info">Please select roles from the column</p>}
                            {chosenRoles.map((role, i) => {
                                return <div className="list-group" key={role.id + i}>
                                    <div className="list-group-item">
                                        <h4 className="list-group-item-heading">{role.name}</h4>
                                        {role.description && <p className="list-group-item-text">{role.description}</p>}
                                        {!role.description && <p className="list-group-item-text help-block">No description</p>}
                                        <br />
                                        {role.isSelectableByParticipant && <a onClick={this._onRemove.bind(this, role)} className="btn btn-default">Remove</a>}
                                    </div>
                                </div>
                            })}
                            {chosenRoles.length > 0 && <div>
                                <a onClick={this._save.bind(this)} className="btn btn-primary btn-lg">Save & continue</a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _onChoose(role) {
        let chosenRoles = this.state.chosenRoles
        if (chosenRoles.filter(r => r.id === role.id).length > 0) {
            return alert("You have already chosen this role. Please choose another one")
        }
        chosenRoles.push(role)
        this.setState({
            chosenRoles: chosenRoles
        })
    }
    _onRemove(role) {
        let chosenRoles = this.state.chosenRoles
        this.setState({
            chosenRoles: chosenRoles.filter(r => r.id !== role.id)
        })
    }

    _save() {
        let { chosenRoles, courseId, participant } = this.state
        const { actions } = this.props

        participant.roles = participant.roles || []
        chosenRoles.map(role => {
            if (participant.roles.filter(r => r === role.staticId).length > 0) { return }
            participant.roles.push(role.staticId)
        })
        participant.hasPickedRoles = true
        // Save the participant
        actions.participant.update(participant, () => {
            window.location.href = `/course/my/${courseId}`
        })

    }
}
export default ReduxBinder(PageCoursePickRole, {
    state: ['course', 'user', 'local']
})