import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Onboarding extends Component {

    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.any
    }

    render() {
        const { title, children } = this.props
        return (
            <div className="onboarding">
                {title && (<h1><i className="fa fa-life-bouy"></i> {title}</h1>)}
                {children}
            </div>
        )
    }
}