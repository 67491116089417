import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, VideoUpload, VideoPlayer } from 'alias-components'
import api from 'alias-tools/api'

export default class ActivityTypeEmail extends Component {

    static propTypes = {
        activity: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        this.state = {
            docsBeingUploaded: [],
            isRemovingVideo: false
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.activity && this.state.activity.id != newProps.activity.id) {
            this.setState({
                docsBeingUploaded: []
            })
        }
    }

    render() {
        let { onChange, activity, state } = this.props
        const course = state.course.model

        // Add organization info to characters
        var characters = course.characters.map(character => {
            if (character.organizationStaticId) {
                let org = course.byStaticId.organizations[character.organizationStaticId]
                if (org) {
                    character.organization = org
                }
            }
            return character
        })

        return <div className="form-horizontal">
            <div className="form-group">
                <label className="col-sm-3 control-label">From</label>
                <div className="col-sm-9">
                    <Input
                        type="select"
                        name="characterStaticId"
                        value={activity.characterStaticId}
                        placeholder="Please select a sender"
                        onChange={onChange}
                        options={characters.map(character => {
                            return {
                                label: `${character.first} ${character.last} (${character.title} ${character.organization !== undefined && ("at " + character.organization.name)})`,
                                value: character.staticId
                            }
                        })} />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Title</label>
                <div className="col-sm-9">
                    <Input
                        type="text"
                        name="title"
                        value={activity.data.title || ''}
                        onChange={this._onChangeContent.bind(this)}
                        placeholder="Please enter a title for the post..." />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Text</label>
                <div className="col-sm-9">
                    <Input
                        type="textarea"
                        name="text"
                        value={activity.data.text || ''}
                        onChange={this._onChangeContent.bind(this)}
                        placeholder="Please enter content for the post..." />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Video</label>
                <div className="col-sm-9">
                    {activity.data.video && <div>
                        <VideoPlayer url={activity.data.video} />
                        <p><a disabled={state.isRemovingVideo} onClick={this._onRemoveVideo.bind(this)} className="btn red">{state.isRemovingVideo ? <i className="fa fa-spinner fa-pulse" /> : "Remove video"}</a></p>
                    </div>}
                    {(false && !activity.data.video) && <VideoUpload courseId={course.id} onComplete={this._onVideoComplete.bind(this)} />}
                </div>
            </div>

        </div>
    }

    _onVideoComplete(url) {
        this._onChangeContent("video", url)
    }
    _onRemoveVideo() {
        this.setState({ isRemovingVideo: true })
        let activity = this.props.activity.data.video
        api(
            '/video/remove',
            'GET',
            { url: activity.data.video }
        )
            .then(() => {
                this.setState({ isRemovingVideo: false })
                this.props.onChange("data", {
                    ...activity.data,
                    video: null
                })

            })
            .catch(() => {
                this.setState({ isRemovingVideo: false })
            })
    }

    _onChangeContent(name, value) {
        let activity = this.props.activity
        activity.data[name] = value
        this.props.onChange("data", activity.data)
    }
}