import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Button, FormErrors, Input } from 'alias-components'
import IsValid from 'alias-tools/validation'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Link } from 'react-router-dom'

class Register extends Component {

    static propTypes = {
        route: PropTypes.object,
        actions: PropTypes.object,
        state: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object
    }

    constructor(props) {
        super(props)

        let email = props.match.params.email || ''
        email = email.replace(/_at_/gi, '@').replace(/_dot_/gi, '.')

        this.state = {
            email: email, // denis@hashup.io
            password: props.match.params.password || '', // 123
            errorFields: []
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.auth.clearMessages()
    }

    componentDidMount() {
        this.props.actions.auth.clearMessages()
    }

    render() {
        const { props } = this
        const { auth } = props.state
        const { email, password } = this.state
        const isDisabled = (password === "" || email === "")

        return (
            <form onSubmit={this._register.bind(this)} className="form-horizontal">
                <Helmet title="Register" />
                <fieldset>
                    <legend>
                        CREATE AN ACCOUNT
                    </legend>
                    {auth.messages.error !== null && (
                        <div className="alert alert-warning animated">
                            <p>
                                {auth.messages.error}
                            </p>
                        </div>
                    )}
                    <FormErrors fields={this.state.errorFields} />
                    <Input type="email"
                        name="email"
                        label="Your email"
                        placeholder="Ex: john.smith@email.com"
                        value={email}
                        onChange={(name, value) => this.setState({
                            email: value
                        })}
                        attributes={{
                            autoFocus: true,
                            disabled: auth.isFetching
                        }} />
                    <Input type="password"
                        name="password"
                        label="Your password"
                        placeholder="Ex: ******"
                        value={password}
                        onChange={(name, value) => this.setState({
                            password: value
                        })}
                        attributes={{
                            disabled: auth.isFetching,
                            ref: 'password'
                        }} />
                    <Button text="Create account"
                        btnClass="btn btn-primary btn-block btn-lg"
                        disabled={isDisabled}
                        isLoading={auth.isFetching} />
                    <p className="help-block text-centered">or <a href="/api/sso/insead/login" style={{ textDecoration: 'underline' }}>Register with INSEAD</a></p>
                    <p className="help-block text-centered" style={{ fontSize: '16px' }}>
                        <br /> Already have an account?
                        <br />
                        <Link to="/auth/login">Go to login</Link>
                    </p>
                </fieldset>
            </form>
        )
    }

    _isFormValid() {
        const { email, password } = this.state
        let formIsValid = true
        let errorFields = []
        let errors
        // password
        errors = IsValid(password, {
            maxLength: 100,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Password",
                errors: errors
            })
        }
        // Email
        errors = IsValid(email, {
            email: null,
            required: null
        })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Email",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _register(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.auth.register(email, password, (response) => {
            if (response.status === 200) {
                // this.props.history.push('/');
                // return;
            } else {
                // this.refs.email.focus();
            }
        })
    }
}

export default ReduxBinder(Register, { state: ['auth', 'user'] })