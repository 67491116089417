import Card from './CourseCard'
import Modal from './CourseModal'
import ModalTemplate from './CourseModalTemplate'
import Tools from './CourseTools'
import ModalJoinInvite from './CourseModalJoinInvite'

export default {
    Card,
    Modal,
    ModalTemplate,
    Tools,
    ModalJoinInvite
}