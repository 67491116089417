import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import CardCharacter from './CharacterCard'
import CardOrganization from '../Organization/OrganizationCard'

export default class ModalCharacterInfo extends Component {

    static propTypes = {
        character: PropTypes.object,
        course: PropTypes.object,
        handleClose: PropTypes.func,
        isOpen: PropTypes.bool.isRequired
    }

    render() {
        const course = this.props.course
        const character = this.props.character
        const hisOrganization = (character.organizationStaticId && course.byStaticId.organizations[character.organizationStaticId]) ? course.byStaticId.organizations[character.organizationStaticId] : null
        return (
            <Modal show={this.props.isOpen} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{character.first} {character.last} info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <CardCharacter character={character} organizations={course.organizations} />
                        </div>
                        <div className="col-md-6">
                            {character.description != '' && (
                                <div>
                                    <h4>Full description</h4>
                                    <p>{character.description}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {false && (
                        <div>
                            <h3>Company info</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <CardOrganization organization={hisOrganization} />
                                </div>
                                <div className="col-md-6">
                                    <h4>Full description</h4>
                                    <p>{hisOrganization.description}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={this.props.handleClose} className="btn btn-default">Close</a>
                </Modal.Footer>
            </Modal>
        )
    }
}