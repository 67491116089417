import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import MessagingParticipantList from './components/MessagingParticipantList'
import MessagingTools from './MessagingTools'
import MessagingConversationsList from './components/MessagingConversationsList'
import MessagingConversation from './components/MessagingConversation'
import moment from 'moment-timezone'

export default class MessagingView extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        admin: PropTypes.object,
        participant: PropTypes.object,
        type: PropTypes.oneOf(['chat', 'email']),
        history: PropTypes.object,
        match: PropTypes.object
    }
    constructor(props) {
        super(props)

        this.state = {
            characterStaticId: props.match.params.characterStaticId
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.state.characterStaticId && newProps.match.params.characterStaticId !== this.state.characterStaticId && this.refs.chatInput) {
            this.refs.chatInput.focus()
        }
    }

    componentDidMount() {
        if (!this.refresh) {
            this.refresh = setInterval(this._forceRender.bind(this), 60000)
        }
        const { participantId } = this.props.match.params
        if (participantId) {
            const course = this.props.state.course.model
            if (course.byId.participants[participantId]) {
                this.props.actions.activityLog.fetchParticipantReadActivity(course.byId.participants[participantId])
            }
        }
    }

    componentDidUpdate() {
        this._scrollBottom()
    }

    componentWillUnmount() {
        if (this.refresh) {
            clearInterval(this.refresh)
        }
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.refresh = false
    }

    _scrollBottom() {
        if (this.refs.chatView) {
            this.refs.chatView.scrollTop = this.refs.chatView.scrollHeight
        }
    }

    _forceRender() {
        console.info("Refreshed the UI") // eslint-disable-line
        this.forceUpdate()
    }

    render() {
        let { state, isAdmin, type, match, participant, actions, admin } = this.props
        const course = state.course.model
        let { threadId, participantId } = match.params
        let threads = []

        // For admins, the participantId comes from the URL
        participantId = participant ? participant.id : participantId

        if (participantId) {
            participant = course.byId.participants[participantId]
            threads = MessagingTools.getThreadsForParticipant(course, type, participant, isAdmin, course.activities)
            let futureMailSendTimeOffset = null
            const now = moment()

            threads = threads.filter(thread => {
                if (!thread.latest || (thread.latest && thread.latest.isAfter(now))) {
                    if (!thread.messages || (thread.messages && thread.messages.length === 0)) { return false }
                    const latestMessage = thread.messages[thread.messages.length - 1]
                    const diff = latestMessage.created.diff(now)
                    if (!futureMailSendTimeOffset || (futureMailSendTimeOffset && futureMailSendTimeOffset > diff)) {
                        futureMailSendTimeOffset = diff
                    }

                    return type === 'email' ? false : true
                }
                return true
            })
            if (futureMailSendTimeOffset) {
                console.log("Future mail incoming in: ", futureMailSendTimeOffset) // eslint-disable-line
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }
                this.timeout = setTimeout(() => {
                    this._forceRender()
                }, futureMailSendTimeOffset + 50)
            }
        }


        return <div className={`messaging ${type === 'chat' ? 'chat' : ''} ${isAdmin ? '' : 'non-admin'}`}>
            <Helmet title={course.name + " " + type} />

            {isAdmin && <MessagingParticipantList
                course={course}
                selectParticipant={this._selectParticipant.bind(this)}
                participantId={participantId}
                admin={admin}
                back={this._back.bind(this)}
                actions={actions} />}



            <MessagingConversationsList
                isAdmin={isAdmin}
                type={type}
                course={course}
                threads={threads}
                participantId={participantId}
                threadId={threadId}
                actions={actions}
                back={this._back.bind(this)}
                admin={admin}
                selectThread={this._selectThread.bind(this)} />

            <MessagingConversation
                type={type}
                course={course}
                participant={participant}
                isAdmin={isAdmin}
                threadId={threadId}
                threads={threads}
                actions={actions}
                state={state}
                back={this._back.bind(this)} />

        </div>
    }

    _back(isMobile = false) {
        const { history, state, match, isAdmin, type } = this.props
        const { threadId, participantId } = match.params
        const course = state.course.model
        const baseLink = `/course/${isAdmin ? 'instructor' : 'my'}/${course.id}`
        let link = "" + baseLink
        if (isMobile) {
            if (isAdmin) {
                if (threadId) {
                    link = baseLink + `/messaging/${type}/${participantId}`
                } else {
                    if (participantId) {
                        link = baseLink + `/messaging/${type}`
                    }
                }
            }
            if (!isAdmin) {
                if (threadId) {
                    link = baseLink + `/messaging/${type}`
                }
            }
        }
        history.push(link)
    }

    _selectParticipant(participant) {
        const { history, state, type } = this.props
        const course = state.course.model
        // actions.activityLog.fetchParticipantReadActivity(participant, () => {
        //     console.log("Done")
        // })
        history.push(`/course/instructor/${course.id}/messaging/${type}/${participant.id}`)
    }

    _selectThread(thread) {
        const { history, isAdmin, state, match, type, actions } = this.props
        const course = state.course.model
        let { participantId } = match.params
        const participant = participantId ? course.byId.participants[participantId] : this.props.participant
        const goTo = () => {
            history.push(`/course/${isAdmin ? 'instructor' : window.location.pathname.indexOf('course/new') !== -1 ? 'new' : 'my'}/${course.id}/messaging/${type}${isAdmin ? `/${participantId}` : ''}/${thread.id}`)
        }
        if (thread.isUnread && (!isAdmin || (isAdmin && participant.isAdmin))) {
            if (!thread.unreadLog) {
                actions.activityLog.create({
                    type: 'read',
                    courseId: course.id,
                    threadId: thread.id,
                    activityStaticId: thread.activityStaticId,
                    participantId: participantId ? participantId : participant.id
                })
            } else {
                actions.activityLog.update(thread.unreadLog)
            }
        }
        goTo()
    }
}