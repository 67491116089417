import Dashboard from './Dashboard'
import Courses from './Courses'
import Users from './Users'
import User from './User'

export default {
    Dashboard,
    Courses,
    Users,
    User
}