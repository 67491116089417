import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

import { Animated, Button, Input } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'

class Reset extends Component {
    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object
    }
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirm: ''
        }
    }

    componentDidMount() {
        this.props.actions.auth.clearMessages();
    }

    componentWillUnmount() {
        this.props.actions.auth.clearMessages();
    }

    render() {
        const { auth } = this.props.state;
        const { password, confirm } = this.state;
        return (
            <form onSubmit={this._reset.bind(this)} className="form-horizontal">
                <Helmet title="Reset your password" />
                <fieldset>
                    <legend>RESET YOUR PASSWORD</legend>
                    <p>You can set a new password for your account here.</p>
                    {auth.messages.error !== null && (
                        <div className="alert alert-warning"><p>{auth.messages.error}</p></div>
                    )}
                    {auth.messages.success !== null && (
                        <div className="alert alert-success"><p>{auth.messages.success}</p></div>
                    )}
                    <Input
                        label="New password"
                        value={password}
                        placeholder="Enter your new desired password..."
                        onChange={(name, value) => this.setState({ password: value })}
                        type="password" />
                    <Input
                        label="Confirm new password"
                        value={confirm}
                        placeholder="Confirm this new password..."
                        onChange={(name, value) => this.setState({ confirm: value })}
                        type="password" />

                    <Button text="Reset password" isLoading={auth.isFetching} btnClass="btn btn-primary btn-block" />
                    <br />
                    <p style={{ textAlign: 'center' }}>or&nbsp;<Link to='/auth/login'>Back to login</Link></p>

                </fieldset>
            </form>
        );
    }

    _onChange(e) {
        var state = this.state;
        state.password = this.refs.password.value;
        state.confirm = this.refs.confirm.value;
        this.setState(state);
        this.props.actions.auth.clearMessages();
    }

    _reset(e) {
        e.preventDefault();
        var self = this;
        var token = this.props.match.params.token;
        var password = this.state.password;
        var confirm = this.state.confirm;

        if (password !== confirm) {
            alert("Passwords do not match");
            self.refs.confirm.focus();
            return false;
        }
        if (password === '') {
            alert("Please enter a password.")
            return false;
        }
        this.props.actions.auth.resetPassword(password, token, (response) => {
            if (response.status === 200) {
                this.props.history.push('/');
            }
        });
        return false;
    }
}

export default ReduxBinder(Reset, { state: ['auth', 'user'] })