import Activities from './ModuleTabActivities'
import Intro from './ModuleTabIntro'
import Setup from './ModuleTabSetup'
import Teachers from './ModuleTabTeachersNotes'
import Triggered from './ModuleTabTriggered'

export default {
    Activities,
    Intro,
    Setup,
    Teachers,
    Triggered
}