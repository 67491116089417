

var Config = {
    api: {
        host: '',
        port: '',
        prefix: '/api',  // Ex: /api
        ssl: true,
        timeout: 300000,
        socketIOhost: '//' + document.location.hostname
    },
    analytics: true
}

module.exports = Config