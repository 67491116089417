import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
// import AvatarEditor from './AvatarCropper'
import AvatarEditor from 'react-avatar-editor'
import Button from './Button'
import scripts from 'alias-tools/scripts'

export default class AvatarUploader extends Component {

    static propTypes = {
        object: PropTypes.object.isRequired,
        refName: PropTypes.string,  // Types are for avoiding clashes if more than 1 on the page: user character organization
        fileType: PropTypes.string, // Types are for saving to the directory: userz characters organizations
        actions: PropTypes.object.isRequired
    }


    static defaultProps = {
        refName: "avatar",
        fileType: 'user'
    }

    constructor(props) {
        super(props)

        this.state = {
            isCropping: false,
            isImageSelecting: false,
            isUploading: false,
            img: null,
            file: null,
            scale: 1.01,
            object: props.object,
            rotate: 0
        }
    }

    render() {
        const self = this

        if (!this.state.isCropping) {
            return (
                <div className="avatar-cropper">
                    <div className="left">
                        <Avatar user={this.state.object} size={120} />
                    </div>
                    <div className="right">
                        <input ref={this.props.refName + '-file'} type="file" accept="image/*" onChange={this._handleFileChange.bind(this)} />
                        <a className="btn btn-default" onClick={(e) => {
                            e.preventDefault()

                            self.refs[self.props.refName + '-file'].click()
                            self.setState({ isImageSelecting: true })

                        }}>Upload an avatar</a>
                    </div>
                </div>
            )
        }
        return (
            <div className="avatar-cropper cropper">
                <div className="left">
                    {/*<AvatarEditor
                        ref={this.props.refName}
                        image={this.state.img}
                        borderRadius={100}
                        width={200}
                        height={200}
                        border={30}
                        color={[0, 0, 0, 0.8]} // RGBA
                    scale={this.state.scale} />*/}
                    <AvatarEditor
                        ref={this.props.refName}
                        image={this.state.img}
                        borderRadius={100}
                        width={200}
                        height={200}
                        border={30}
                        color={[0, 0, 0, 0.8]} // RGBA
                        scale={this.state.scale}
                        rotate={this.state.rotate}
                    />
                    <input ref={this.props.refName + "-scale"} type="range" min="1.01" max="2" step="0.01" value={this.state.scale} onChange={this._scaleChange.bind(this)} />
                    <a onClick={() => this.setState({ rotate: (this.state.rotate + 90) % 360 })}>Rotate clockwise 90</a>
                </div>
                <div className="right">
                    <Button text="Save avatar" btnClass="btn btn-secondary" onClick={this._saveAvatar.bind(this)} isLoading={this.state.isLoading} />
                    <a><i className="" /></a>
                </div>
            </div>
        )
    }

    _scaleChange() {
        this.setState({
            scale: parseFloat(this.refs[this.props.refName + "-scale"].value)
        })
    }

    _handleFileChange(e) {
        const self = this
        var file = e.target.files[0]
        if (file.type.indexOf('image') === -1) {
            return alert("Please select an image (jpeg, jpg, png, gif).")
        }
        var reader = new FileReader()
        reader.onload = function (img) {
            self.setState({
                isCropping: true,
                img: img.target.result,
                file: file
            })
        }.bind(this)
        reader.readAsDataURL(file)
    }

    _saveAvatar() {
        const self = this
        const imgFormat = "image/jpeg"
        const imgDataURL = this.refs[this.props.refName].getImageScaledToCanvas().toDataURL(imgFormat, 0.5)
        let imgFile = dataURItoBlob(imgDataURL, imgFormat, scripts.randomString() + '.jpg')
        this.setState({
            isLoading: true
        })

        this.props.actions[this.props.fileType].avatar(this.props.object.id, imgFile, (response) => {
            self.setState({
                isCropping: false,
                isImageSelecting: false,
                isLoading: false,
                img: null,
                file: null,
                object: Object.assign({}, self.state.object, response)
            })
        })
    }
}


function dataURItoBlob(dataURI, format, name) {
    var binary = atob(dataURI.split(',')[1])
    var array = []
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    // return new File([new Uint8Array(array)], name, {type: format})
    return new Blob([new Uint8Array(array)], { type: format })
}
