import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Errors from 'alias-data/errors'
import { Link } from 'react-router-dom'

export default class ErrorPage extends Component {
    static propTypes = {
        errorId: PropTypes.any
    }
    render() {
        let title = "Error...";
        let message = "Something went wrong. Please reload the page.";
        if (this.props.errorId) {
            const errorId = this.props.errorId;
            const currentError = Errors.filter(function (error) {
                return error.id == errorId;
            })[0];
            if (currentError) {
                title = currentError.title;
                message = currentError.message;
                if (currentError.action) {
                    currentError.action();
                }
            }
        }

        return (
            <div id="loading" className="error">
                <div className="panel">
                    <h2>{title}</h2>
                    <p>{message}</p>
                    <br />
                    <Link to="/" className="btn btn-default btn-lg">Go back to home page</Link>
                </div>
            </div>
        );
    }
}