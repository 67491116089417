import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Loading extends Component {
    // <i className="fa fa-spinner fa-pulse fa-fw margin-bottom"></i>
    render() {
        return (
            <div id="loading">
                <div className="panel">
                    <div className="hand">
                        <div className="finger finger-1">
                            <div className="finger-item">
                                <span></span><i></i>
                            </div>
                        </div>
                        <div className="finger finger-2">
                            <div className="finger-item">
                                <span></span><i></i>
                            </div>
                        </div>
                        <div className="finger finger-3">
                            <div className="finger-item">
                                <span></span><i></i>
                            </div>
                        </div>
                        <div className="finger finger-4">
                            <div className="finger-item">
                                <span></span><i></i>
                            </div>
                        </div>
                        <div className="last-finger">
                            <div className="last-finger-item"><i></i></div>
                        </div>
                    </div>
                    <p>Getting your information ready...</p>
                </div>
            </div>
        );
    }
}