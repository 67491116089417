import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Card, LMSDisplay, LMSChapter } from 'alias-components'
import Config from 'alias-config'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Dashboard } from '../components'
import scripts from 'alias-tools/scripts'

class CourseRunTabModule extends Component {

    static propTypes = {
        actions: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    }

    render() {
        const course = this.props.state.course.model
        const moduleStaticId = this.props.match.params.moduleStaticId
        let module = course.modules.filter(mod => mod.staticId == moduleStaticId)[0]
        if (!module) {
            return <div>Module not found</div>
        }

        // Course for modules with filtered activities
        let moduleActivities = []
        course.activities.filter(activity => !activity.parentStaticId && activity.moduleStaticId === module.staticId).map(groupActivity => {
            course.activities.filter(activity => activity.parentStaticId === groupActivity.staticId).map(childActivity => {
                moduleActivities.push(childActivity)
            })
        })
        const courseForGraph = { ...course, activities: moduleActivities }
        const moduleStart = moment(course.start).tz(course.timezone).add(module.offset || 0, 'm')

        return <div className="config-right-wrapper">
            <h1>{module.name}</h1>
            <Card title="Basic info">
                <div className="form-horizontal">
                    <div className="form-group">
                        <label className="control-label col-md-3">Start date</label>
                        <div className="col-md-9"><p className="form-control-static">{moduleStart.format(Config.app.time.dateTimeShortFormat)}</p></div>
                    </div>
                    {module.description && (
                        <div className="form-group">
                            <label className="control-label col-md-3">Quick description</label>
                            <div className="col-md-9">{module.description}</div>
                        </div>
                    )}
                </div>
            </Card>

            {!scripts.isEditorEmpty(module.intro) && (
                <Card title="Module introduction">
                    <LMSDisplay
                        course={course}
                        module={module}
                        text={module.intro}
                        for={module.staticId} />
                </Card>
            )}
            {(module.intros && module.intros.length > 0) && <div>
                <h3>Module notes</h3>
                <LMSChapter
                    isEditable={true}
                    value={module.intros}
                    onSave={(value, callback) => this._onSaveLMS('intros', value, callback)} />
                <br />
            </div>}

            {!scripts.isEditorEmpty(module.teacher) && (
                <Card title="Teacher notes">
                    <LMSDisplay
                        course={course}
                        module={module}
                        text={module.teacher}
                        for={'teacher-' + module.staticId} />
                </Card>
            )}
            {(module.teachers && module.teachers.length > 0) && <div>
                <h3>Teacher notes</h3>
                <LMSChapter
                    isEditable={true}
                    value={module.teachers}
                    onSave={(value, callback) => this._onSaveLMS('teachers', value, callback)} />
            </div>}

            {(false && course.scoringTags.length > 0) && <Card title="Scoring tags">
                <Dashboard.InstructorScoringTags course={courseForGraph} />
            </Card>}
        </div>

    }

    _onSaveLMS(name, value, callback) {
        let course = this.props.state.course.model
        course[name] = value
        this.props.actions.course.update(course, () => {
            if (callback) {
                callback()
            }
        })
    }
}

export default ReduxBinder(CourseRunTabModule, {
    state: ['course', 'module']
})