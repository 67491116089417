import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

let iconSets = {}
iconSets.iconMappingAnimated = [
    {
        extensions: [],
        name: 'text',
        isDefault: true // Should always be first
    },
    {
        extensions: ['jpg', 'jpeg', 'gif', 'png', 'tiff'],
        name: 'image'
    },
    {
        extensions: ['ppt', 'pptx'],
        name: 'slides'
    },
    {
        extensions: ['xls', 'xlsx', 'numbers'],
        name: 'table'
    },
    {
        extensions: ['doc', 'docx', 'pages'],
        name: 'text'
    },
    {
        extensions: ['html', 'js', 'css', 'less', 'sass', 'scss', 'jsx', 'swift', 'java', 'sh'],
        name: 'code'
    }
]

iconSets.fontAwesomeIconMapping = [
    {
        extensions: [],
        name: 'file-o',
        isDefault: true // Should always be first
    },
    {
        extensions: ['xls', 'xlsx', 'numbers', 'csv'],
        name: 'file-excel-o'
    },
    {
        extensions: ['pdf'],
        name: 'file-pdf-o'
    },
    {
        extensions: ['wav', 'mp3', 'aac'],
        name: 'file-sound-o'
    },
    {
        extensions: ['doc', 'docx', 'pages', 'txt'],
        name: 'file-text-o'
    },
    {
        extensions: ['zip', 'gzip'],
        name: 'file-archive-o'
    },
    {
        extensions: ['jpg', 'jpeg', 'gif', 'png', 'tiff'],
        name: 'file-image-o'
    },
    {
        extensions: ['mp4', 'mov'],
        name: 'file-movie-o'
    },
    {
        extensions: ['html', 'js', 'css', 'less', 'sass', 'scss', 'jsx', 'swift', 'java', 'sh'],
        name: 'file-code-o'
    },
    {
        extensions: ['ppt', 'pptx', 'slides'],
        name: 'file-powerpoint-o'
    }
]


const usedIconsName = "fontAwesomeIconMapping"

export default class FileIcons extends Component {

    static propTypes = {
        name: PropTypes.string,
        height: PropTypes.number,
        animation: PropTypes.bool,
        progress: PropTypes.string,
        isProcessing: PropTypes.bool
    }

    static defaultProps = {
        height: 30,
        animation: true,
        progress: '100%',
        isProcessing: false,
        name: ''
    }

    render() {
        let extension = 'file'
        let iconSet = iconSets[usedIconsName]
        let type = iconSet[0].name
        let name = this.props.name
        if (name.indexOf('.') !== -1) {
            let parts = name.split('.')
            extension = parts[parts.length - 1].toLowerCase()
            for (let i = 1; i < iconSet.length; i++) {
                if (iconSet[i].extensions.indexOf(extension) !== -1) {
                    type = iconSet[i].name
                    break
                }
            }
        }
        if (usedIconsName === 'iconMappingAnimated') {
            let iconAttributes = Object.assign({}, this.props, {
                type: type,
                label: extension
            })
            return <Icon {...iconAttributes} />
        }
        if (usedIconsName === 'fontAwesomeIconMapping') {
            return <i className={`fa fa-${type}`} />
        }
        return null
    }
}