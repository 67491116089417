const courseSubscriptions = (actions) => {
    return [
        {
            collectionName: 'course',
            method: 'PATCH',
            callback: (model) => actions.course.liveUpdate(model)
        },
        //
        //
        {
            collectionName: 'organization',
            method: 'POST',
            callback: (model) => actions.organization.liveAdd(model)
        },
        {
            collectionName: 'organization',
            method: 'PATCH',
            callback: (model) => actions.organization.liveUpdate(model)
        },
        {
            collectionName: 'organization',
            method: 'DELETE',
            callback: (id) => actions.organization.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'character',
            method: 'POST',
            callback: (model) => actions.character.liveAdd(model)
        },
        {
            collectionName: 'character',
            method: 'PATCH',
            callback: (model) => actions.character.liveUpdate(model)
        },
        {
            collectionName: 'character',
            method: 'DELETE',
            callback: (id) => actions.character.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'role',
            method: 'POST',
            callback: (model) => actions.role.liveAdd(model)
        },
        {
            collectionName: 'role',
            method: 'PATCH',
            callback: (model) => actions.role.liveUpdate(model)
        },
        {
            collectionName: 'role',
            method: 'DELETE',
            callback: (id) => actions.role.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'variable',
            method: 'POST',
            callback: (model) => actions.variable.liveAdd(model)
        },
        {
            collectionName: 'variable',
            method: 'PATCH',
            callback: (model) => actions.variable.liveUpdate(model)
        },
        {
            collectionName: 'variable',
            method: 'DELETE',
            callback: (id) => actions.variable.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'scoringTag',
            method: 'POST',
            callback: (model) => actions.scoringTag.liveAdd(model)
        },
        {
            collectionName: 'scoringTag',
            method: 'PATCH',
            callback: (model) => actions.scoringTag.liveUpdate(model)
        },
        {
            collectionName: 'scoringTag',
            method: 'DELETE',
            callback: (id) => actions.scoringTag.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'form',
            method: 'POST',
            callback: (model) => actions.form.liveAdd(model)
        },
        {
            collectionName: 'form',
            method: 'PATCH',
            callback: (model) => actions.form.liveUpdate(model)
        },
        {
            collectionName: 'form',
            method: 'DELETE',
            callback: (id) => actions.form.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'module',
            method: 'POST',
            callback: (model) => actions.module.liveAdd(model)
        },
        {
            collectionName: 'module',
            method: 'PATCH',
            callback: (model) => actions.module.liveUpdate(model)
        },
        {
            collectionName: 'module',
            method: 'DELETE',
            callback: (id) => actions.module.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'activity',
            method: 'POST',
            callback: (model) => actions.activity.liveAdd(model)
        },
        {
            collectionName: 'activity',
            method: 'PATCH',
            callback: (model) => actions.activity.liveUpdate(model)
        },
        {
            collectionName: 'activity',
            method: 'DELETE',
            callback: (id) => actions.activity.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'activityLog',
            method: 'POST',
            callback: (model) => actions.activityLog.liveAdd(model)
        },
        {
            collectionName: 'activityLog',
            method: 'PATCH',
            callback: (model) => actions.activityLog.liveUpdate(model)
        },
        {
            collectionName: 'activityLog',
            method: 'DELETE',
            callback: (id) => actions.activityLog.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'participant',
            method: 'POST',
            callback: (model) => actions.participant.liveAdd(model)
        },
        {
            collectionName: 'participant',
            method: 'PATCH',
            callback: (model) => actions.participant.liveUpdate(model)
        },
        {
            collectionName: 'participant',
            method: 'DELETE',
            callback: (id) => actions.participant.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'team',
            method: 'POST',
            callback: (model) => actions.team.liveAdd(model)
        },
        {
            collectionName: 'team',
            method: 'PATCH',
            callback: (model) => actions.team.liveUpdate(model)
        },
        {
            collectionName: 'team',
            method: 'DELETE',
            callback: (id) => actions.team.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'thread',
            method: 'POST',
            callback: (model) => actions.thread.liveAdd(model)
        },
        {
            collectionName: 'thread',
            method: 'PATCH',
            callback: (model) => actions.thread.liveUpdate(model)
        },
        {
            collectionName: 'thread',
            method: 'DELETE',
            callback: (id) => actions.thread.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'badge',
            method: 'POST',
            callback: (model) => actions.badge.liveAdd(model)
        },
        {
            collectionName: 'badge',
            method: 'PATCH',
            callback: (model) => actions.badge.liveUpdate(model)
        },
        {
            collectionName: 'badge',
            method: 'DELETE',
            callback: (id) => actions.badge.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'doc',
            method: 'POST',
            callback: (model) => actions.doc.liveAdd(model)
        },
        {
            collectionName: 'doc',
            method: 'PATCH',
            callback: (model) => actions.doc.liveUpdate(model)
        },
        {
            collectionName: 'doc',
            method: 'DELETE',
            callback: (id) => actions.doc.liveRemove({ courseId: 'value', id: id })
        },
        //
        //
        {
            collectionName: 'forum',
            method: 'POST',
            callback: (model) => actions.forum.liveAdd(model)
        },
        {
            collectionName: 'forum',
            method: 'PATCH',
            callback: (model) => actions.forum.liveUpdate(model)
        },
        {
            collectionName: 'forum',
            method: 'DELETE',
            callback: (id) => actions.forum.liveRemove({ courseId: 'value', id: id })
        }
    ]
}
export default courseSubscriptions