import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import ReduxBinder from "alias-store/ReduxBinder"
import { Link, Switch, Route } from "react-router-dom"
import InfoTabs from './InfoTabs'

class CourseConfigurationIntroduction extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object
    }

    render() {
        const { props, state } = this
        const course = props.state.course.model
        const type = props.match.params.type || 'case'

        const tabList = [
            {
                label: `Case`,
                value: 'case'
            },
            {
                label: `Intro`,
                value: 'intro'
            },
            {
                label: `Teacher notes`,
                value: 'teachers'
            },
            {
                label: `Authors`,
                value: 'authors'
            },
        ]

        let baseLink = `/course/configuration/${course.id}/intro`

        return (
            <div className="config-right-wrapper">
                <Helmet title={course.name + " introduction"} />
                <div className="page-header tab-list">
                    <h1 style={{ marginRight: 25 }}>Info</h1>
                    {tabList.map((thisTab) => {
                        return <Link to={`${baseLink}/${thisTab.value}`}
                            key={thisTab.value}
                            className={(type === thisTab.value) ? 'active' : ''}>
                            {thisTab.label}
                        </Link>
                    })}
                </div>
                <div style={{ minHeight: 700 }}>
                    <Switch>
                        <Route exact path={`${baseLink}`} component={InfoTabs.Case} />
                        <Route path={`${baseLink}/case`} component={InfoTabs.Case} />
                        <Route path={`${baseLink}/authors`} component={InfoTabs.Authors} />
                        <Route path={`${baseLink}/intro`} component={InfoTabs.Intro} />
                        <Route path={`${baseLink}/teachers`} component={InfoTabs.TeachersNotes} />
                    </Switch>
                </div>
            </div>
        )
    }


}


export default ReduxBinder(CourseConfigurationIntroduction, {
    state: ["course"]
})
