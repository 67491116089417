import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import Input from "./Input"


export default class Form extends Component {
    static propTypes = {
        model: PropTypes.object,
        structures: PropTypes.array,
        create: PropTypes.func,
        update: PropTypes.func,
        isSaving: PropTypes.bool
    }

    static defaultProps = {
        create: () => {
            console.warn("No create function defined in the form") // eslint-disable-line
        },
        update: () => {
            console.warn("No create function defined in the form") // eslint-disable-line
        },
        isSaving: false
    }

    constructor(props) {
        super(props)

        this.state = {
            model: props.model
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            model: newProps.model
        })
    }

    render() {
        const { model } = this.state
        const { isSaving, structures } = this.props
        const isNew = (!model || model.id === "")


        return (
            <form onSubmit={this._onSubmit.bind(this)}>
                {structures.map(structure => <Input {...structure}
                    onChange={this._onChange.bind(this)}
                    value={model[structure.name]}
                    key={structure.name} />)}
                <br />
                <Button text={isNew ? 'Create' : 'Save'}
                    isLoading={isSaving} />
            </form>
        )
    }


    _onChange(name, value) {
        let model = this.state.model
        model[name] = value
        this.setState({
            model: model
        })
    }

    _onSubmit(e) {
        e.preventDefault()
        const model = this.state.model
        const { create, update } = this.props;
        (!model.id) ? create(model) : update(model)
    }
}

export const structure = {
    name: "",
    label: "",
    placeholder: "",
    description: "",
    type: "",
    disabled: false,
    options: [],
    timezone: null,
    attributes: {}
}