import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Search, Card } from 'alias-components'
import Fuse from 'fuse.js'
import ForumModalNew from './ForumModalNew'
import ForumModalView from './ForumModalView'
import moment from 'moment'

const IS_DEBUGING_UNREAD = false
if (IS_DEBUGING_UNREAD) {
    // eslint-disable-next-line no-console
    console.warn("CURRENTLY DEBUGGING UNREAD")
}

class ForumPage extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool
    }
    static defaultProps = {
        isAdmin: false
    }
    constructor(props) {
        super(props)
        this.isUpdatingLog = false

        this.state = {
            search: '',
            showCount: 30,
            showNew: false,
            showView: false,
            post: null,
            participant: this._getParticipant(),
            lastReadLog: this._getUnreadLog()
        }

    }
    componentDidMount() {
        if (!IS_DEBUGING_UNREAD) {
            this._updateUnRead()
        }
    }
    componentDidUpdate() {
        if (!IS_DEBUGING_UNREAD) {
            this._updateUnRead()
        }
    }
    componentWillUnmount() {
        this._updateUnRead()
    }
    _getUnreadLog() {
        let participant = this.state ? this.state.participant : null
        const course = this.props.state.course.model

        if (!participant) {
            participant = this._getParticipant()
            if (!participant) { return null }
        }
        const logs = course.activityLogs.filter(l => l.type === "forum-read" && l.participantId === participant.id)
        // console.log(logs.length === 0 ? "No unread log" : logs[0])
        return logs.length === 0 ? null : logs[0]
    }
    _onPost() {
        // console.log("_onPost")
        // this._updateUnRead(() => {
        //     this.setState({ lastReadLog: this._getUnreadLog() })
        // })
    }
    _updateUnRead(onComplete = () => { }) {
        let participant = this.state.participant
        const course = this.props.state.course.model

        if (this.isUpdatingLog) { return }

        if (!participant) {
            participant = this._getParticipant()
            if (!participant) { return }
        }
        const logs = course.activityLogs.filter(l => l.type === "forum-read" && l.participantId === participant.id)

        if (logs.length === 0) {
            this.isUpdatingLog = true
            this.props.actions.activityLog.create({
                type: "forum-read",
                participantId: participant.id,
                courseId: course.id
            }, () => {
                this.isUpdatingLog = false
                this.setState({ lastReadLog: this._getUnreadLog() })
                onComplete()
            })
        } else {
            const latestPost = course.forums[0]
            if (latestPost) {
                if (new Date(latestPost.latestUpdate) - new Date(logs[0].updated) <= 0) { return }
                this.isUpdatingLog = true
                this.props.actions.activityLog.update({
                    ...logs[0],
                    updated: new Date()
                }, () => {
                    this.isUpdatingLog = false
                    this.setState({ lastReadLog: this._getUnreadLog() })
                    onComplete()
                })
            }
        }
    }
    _getParticipant() {
        // Get participant
        const { props } = this
        const course = props.state.course.model
        let userParticipants = course.participants.filter((item) => {
            return ((!props.isAdmin && !item.isAdmin) || (props.isAdmin && item.isAdmin)) && item.userz.id === props.state.user.model.id
        })

        let participant = userParticipants[0]
        if (props.state.local && props.state.local.game && props.state.local.game.participantId) {
            const hisParticipant = userParticipants.filter(part => part.id === props.state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
            }
        }
        return participant
    }
    render() {
        const { props, state } = this
        const course = props.state.course.model
        let forums = course.forums || []
        if (!state.participant.isAdmin) {
            forums = forums.filter(f => !f.isForInstructors || (f.isForInstructors && f.participantId === state.participant.id))
        }

        if (state.search !== '') {
            let options = {
                keys: [{
                    name: 'title',
                    weight: .6
                }, {
                    name: 'text',
                    weight: 0.4
                }],
                minMatchCharLength: 1,
                shouldSort: true,
                threshold: 0.4
            }
            let fuse = new Fuse(forums, options)
            forums = fuse.search(state.search)
        } else {
            forums = forums.map(forum => {
                forum.isNew = false
                if (state.lastReadLog
                    && moment(forum.latestUpdate).isAfter(moment(state.lastReadLog.updated).add(2, 'seconds'))) {
                    forum.isNew = true
                }
                return forum
            })
        }
        const totalNumberOfForums = forums.length

        forums = forums.slice(0, state.showCount)

        let hasUnread = false


        return <div className="config-right-wrapper">
            <Helmet title={course.name + " forum"} />
            <div className="page-header">
                <div className="row">
                    <div className="col-xs-12 col-sm-4"><h1>Forum</h1></div>
                    <div className="col-xs-12 col-sm-4">
                        <a onClick={() => this.setState({ showNew: true })} className="btn btn-secondary pull-right">New post</a>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <Search
                            search={state.search}
                            onChange={(search) => {
                                this.setState({ search: search })
                            }} />
                    </div>
                </div>

            </div>
            {state.search === '' && <p className="help-block" style={{ marginBottom: '15px' }}>
                <i className="fa fa-info fa-fw margin-right-5" /> A place to discuss with your class...
            </p>}

            {state.search !== '' && (
                <p style={{ marginBottom: '15px' }}>
                    <em>Showing results for <strong> {state.search}</strong></em>
                    <a onClick={() => this.setState({ search: '' })} style={{ marginLeft: '10px' }}>Clear search</a>
                </p>
            )}
            {forums.length === 0 && (
                <div className="alert alert-info">
                    <strong>No posts</strong>
                    {state.search === '' && <p>No one has posted anything yet. Be the first to share or ask something.</p>}
                </div>
            )}
            <div className="forum">
                {forums.map((post, i) => {
                    let author = "Not found"
                    if (course.byId.participants[post.participantId]) {
                        author = course.byId.participants[post.participantId]
                        author = author.displayName.bigTeamThenName
                    }
                    if (course.byStaticId.characters[post.participantId]) {
                        author = course.byStaticId.characters[post.participantId]
                        author = `${author.last} ${author.first}`
                    }
                    let showUnreadBarBefore = true
                    let showUnreadBar = false
                    if (i === 0 && post.isNew === true) {
                        hasUnread = true
                    } else {
                        if (hasUnread && !post.isNew) {
                            showUnreadBar = true
                            hasUnread = false
                            if (i === forums.length - 1) {
                                showUnreadBarBefore
                            }
                        }
                    }
                    return <div key={post.id}>
                        {(showUnreadBar && showUnreadBarBefore) && <p className="alert alert-info text-centered">UNREAD ACTIVITY ABOVE <i className="fa fa-caret-up" style={{ marginLeft: 5 }} /></p>}
                        <Card>
                            <div className="post">
                                <div className="vote-wrapper">
                                    <div className="vote">
                                        {post.type === 'activity' ? <i style={{ fontStyle: 'normal' }} className="fa fa-bookmark-o"></i> : post.voteCount}
                                        {post.type !== 'activity' && <span>likes</span>}
                                    </div>
                                </div>
                                <div className="content">
                                    <a className="title" onClick={this._handleOpenView.bind(this, post)}>{post.title} {post.isForInstructors && ` (For instructors)`}</a>
                                    <p className="text">{post.text}</p>
                                    <p className="details">{post.comments.length} comment{post.comments.length > 1 ? "s" : ""} - from: {author} - Posted: {moment(post.created).fromNow()}</p>
                                </div>
                            </div>
                        </Card>
                        {(showUnreadBar && !showUnreadBarBefore) && <p className="alert alert-info text-centered">UNREAD ACTIVITY ABOVE <i className="fa fa-caret-up" style={{ marginLeft: 5 }} /></p>}
                    </div>
                })}
                {(forums.length > 0 && forums[0].isNew) && <p className="alert alert-info text-centered">UNREAD ACTIVITY ABOVE <i className="fa fa-caret-up" style={{ marginLeft: 5 }} /></p>}
            </div>

            {state.showCount < totalNumberOfForums && <div style={{ textAlign: 'center', margin: '20px 0 40px' }}>
                <a onClick={() => this.setState({ showCount: state.showCount + 30 })}
                    className="btn btn-secondary"><i className="fa fa-chevron-down" /> Show more</a>
                &nbsp;of {totalNumberOfForums - state.showCount} left
            </div>}

            <ForumModalNew show={state.showNew}
                courseId={course.id}
                actions={props.actions}
                updateUnread={this._onPost.bind(this)}
                handleClose={this._handleCloseNew.bind(this)}
                participantId={state.participant.id}
                participant={state.participant} />

            <ForumModalView show={state.showView}
                actions={props.actions}
                handleClose={this._handleCloseView.bind(this)}
                updateUnread={this._onPost.bind(this)}
                participantId={state.participant.id}
                post={state.post}
                course={course}
                lastReadLog={state.lastReadLog} />
        </div>
    }


    _handleCloseNew() {
        this.setState({ showNew: false })
        if (!IS_DEBUGING_UNREAD) {
            this._updateUnRead(() => {
                this.setState({ showNew: false })
            })
        } else {
            this.setState({ showNew: false })
        }
    }

    _handleOpenView(post) {
        this.setState({ post: post, showView: true })
    }

    _handleCloseView() {
        this.setState({ post: null, showView: false })
    }
}

export default ReduxBinder(ForumPage, { state: ['course', 'forum', 'user'] })
