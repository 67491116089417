import Forum from './Forum'
import Checklist from './Checklist'
import Messaging from './Messaging'
import Module from './Module'
import Overview from './Overview'
import Participants from './Participants'
import RolePlay from './RolePlay'
import Scoring from './Scoring'
import Teams from './Teams'
import Scheduling from './Scheduling'
import Now from './Now'
import Settings from './Settings'
import Duplicates from './Duplicates'

export default {
    Module,
    Participants,
    Teams,
    RolePlay,
    Messaging,
    Forum,
    Checklist,
    Overview,
    Scoring,
    Scheduling,
    Now,
    Settings,
    Duplicates
}