import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Card, Avatar } from 'alias-components'
import scripts from 'alias-tools/scripts'
import csv from 'alias-tools/csv'
import { ScoringMatrix } from '../../RolePlay'

const rolePlaySettingsDefault = {
    duration: 15,
    formDisplayOffset: 5,
    parties: [
        {
            roleStaticId: '',
            formStaticId: '',
            id: scripts.randomString()
        }
    ],
    rolePlay: [],
    scoringMatrix: {}
}

const cols = ['Participant ID (Do not change)', 'First name', 'Last name', 'Email', 'Team', 'Role', 'Party (Do not change)', 'Group', 'Location', 'Time offset (Number 0...9999)']

export default class ActivityRolePlay extends Component {
    static propTypes = {
        activity: PropTypes.object,
        onActivityUpdate: PropTypes.func.isRequired,
        state: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            ignoreFirstRow: true,
            errorsFromCSV: []
        }
    }

    render() {
        const { ignoreFirstRow, errorsFromCSV } = this.state
        let { activity, state } = this.props
        const course = state.course.model
        let settings = { ...rolePlaySettingsDefault, ...activity.data.settings }
        activity.data = activity.data || {}
        activity.data.rolePlay = activity.data.rolePlay || []
        const rolesSelect = course.roles.map(role => {
            return {
                label: role.name,
                value: role.staticId
            }
        })

        const formSelect = [
            { label: "None", value: '' },
            ...course.forms.map(form => {
                return {
                    label: form.name,
                    value: form.staticId
                }
            })
        ]



        return (
            <div>
                <Card title='Step 1: Settings'>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <Input label='Duration (mins)'
                                description='Duration in minutes of each individual role play that will be showed to the user'
                                value={settings.duration}
                                name='duration'
                                type='number'
                                onChange={this._onChange.bind(this)} />
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Input label='Form display offset (mins)'
                                description='How long before the end of the role play do you want to display the forms?'
                                value={settings.formDisplayOffset}
                                name='formDisplayOffset'
                                type='number'
                                onChange={this._onChange.bind(this)} />
                        </div>
                    </div>
                    {false && <div><hr />
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <Input label='Global form'
                                    description='Form that will score themselves to impact variables/score'
                                    placeholder='(*Optional)'
                                    value={settings.globalFormStaticId}
                                    name='globalFormStaticId'
                                    type='select'
                                    options={formSelect}
                                    onChange={this._onChange.bind(this)} />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                {settings.globalFormStaticId && <Input type="bool"
                                    value={settings.globalFormUseScoringMatrix}
                                    name="globalFormUseScoringMatrix"
                                    label="Would you like to use a scoring matrix?"
                                    description="Enables you to give different scores and impact on variable depending on which party the user is in. Scoring matrix will show up on the bottom of this page."
                                    onChange={this._onChange.bind(this)} />}
                            </div>

                            {(false && settings.globalFormUseScoringMatrix) && <div>
                                <ScoringMatrix course={course}
                                    settings={settings}
                                    onChangeSettings={this._onChangeSettings.bind(this)} />
                            </div>}
                        </div>
                    </div>}

                    <hr />
                    <h4>Parties</h4>
                    {settings.parties.map((party, i) => <div className='row' key={i}>
                        <div className='col-md-3' style={{ paddingTop: '32px', textAlign: 'right' }}>
                            <strong style={{ display: 'block' }}>Party {i + 1}</strong>
                            <a onClick={this._removeParty.bind(this, party.id)} className="red">Remove party</a>
                        </div>
                        <div className='col-md-9'>
                            <Input label='Role'
                                placeholder='Select Role...'
                                description={party.roleStaticId ? `Found ${course.participants.filter(p => p.roles.indexOf(party.roleStaticId) !== -1).length} participants(s)` : 'Select role for party'}
                                name='roleStaticId'
                                options={rolesSelect}
                                type='select'
                                value={party.roleStaticId}
                                onChange={(name, value) => this._onChangeParty(i, name, value)} />
                            <Input label='Party confidential information'
                                type='textarea'
                                name='description'
                                value={party.description}
                                placeholder='Ex: Bring your whole team with you.'
                                onChange={(name, value) => this._onChangeParty(i, name, value)} />
                            <Input label='Form'
                                description='Form that the other parties will use to grade this party'
                                placeholder='(*Optional)'
                                value={party.formStaticId}
                                name='formStaticId'
                                type='select'
                                options={formSelect}
                                onChange={(name, value) => this._onChangeParty(i, name, value)} />
                            <br />
                        </div>
                    </div>)}
                    <div className='row'>
                        <div className='col-md-offset-3 col-md-9'><a onClick={this._addParty.bind(this)} className='btn btn-default'><i className='fa fa-plus' /> Add party</a></div>
                    </div>
                </Card>

                {!course.isTemplate && <div>
                    <Card title="Step 2: Download CSV">
                        <div>
                            <p>Download the below CSV, fill in the <strong>groups, time, location</strong> in your editor of choice (Excel, Numbers)</p>
                            <p className="text-center"><a onClick={this._downloadCSV.bind(this)} className="btn btn-secondary btn-lg">Download CSV</a></p>
                        </div>
                    </Card>

                    <Card title="Step 3: Upload CSV">
                        <div>
                            <p>Upload the CSV file you just filled in.</p>
                            <p className="text-center">
                                <input type="file" ref="picker" onChange={this._uploadCSV.bind(this)} />

                                <a onClick={() => {
                                    this.refs.picker.click()
                                }} className="btn btn-secondary btn-lg">Upload csv</a><br />
                                <label><input type="checkbox" ref="ignore" checked={ignoreFirstRow} onChange={() => {
                                    this.setState({ ignoreFirstRow: !ignoreFirstRow })
                                }} /> Ignore first row</label>
                            </p>
                        </div>
                    </Card>

                    <Card title="Review">
                        <div>
                            {errorsFromCSV.length > 0 && <div className="alert alert-warning">
                                <strong>Errors in csv:</strong>
                                <ul>
                                    {errorsFromCSV.map((error, i) => <li key={i}>{error.message}</li>)}
                                </ul>
                            </div>}
                        </div>
                        <table className="table table-hover table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th colSpan={3}>Participants</th>
                                    <th>Group</th>
                                    <th>Location</th>
                                    <th>Offset</th>
                                    <th width={120}>Party</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {activity.data.rolePlay.map((rolePlayer, i) => <ParticipantRolePlayer key={i}
                                    index={i}
                                    parties={settings.parties}
                                    rolePlayer={rolePlayer}
                                    course={course}
                                    onChangeRolePlayer={this._onChangeRolePlayer.bind(this)}
                                    onRemoveRolePlayer={this._onRemoveRolePlayer.bind(this)} />)}
                                {activity.data.rolePlay.length === 0 && <tr className="info"><td colSpan={8}>None</td></tr>}
                                <tr>
                                    <td colSpan={8}>
                                        <Input type="select"
                                            value={''}
                                            options={course.participants.map(p => {
                                                return {
                                                    label: `${p.displayName.short} ${(p.teamId && course.byId.teams[p.teamId]) ? ` - Team: ${course.byId.teams[p.teamId].name}` : ''} ${!p.isAdmin ? ` (Roles: ${p.roles.map(roleId => { return course.byStaticId.roles[roleId].name }).join(', ')})` : '(Author)'}`, value: p.id
                                                }
                                            })}
                                            placeholder="Add a participant..."
                                            onChange={this._onAddRolePlayer.bind(this)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>}
            </div>
        )
    }

    _onChange(name, value) {
        let { activity } = this.props
        activity.data = { settings: {}, ...activity.data }
        activity.data.settings[name] = value
        this.props.onActivityUpdate(activity)
    }

    _onChangeSettings(settings) {
        let { activity } = this.props
        activity.data = { settings: {}, ...activity.data }
        activity.data.settings = settings
        this.props.onActivityUpdate(activity)
    }

    _addParty() {
        let { activity } = this.props
        activity.data.settings = { ...rolePlaySettingsDefault, ...activity.data.settings }
        activity.data.settings.parties.push({
            roleStaticId: '',
            formStaticId: '',
            id: scripts.randomString()
        })
        this.props.onActivityUpdate(activity)
    }
    _onChangeParty(index, name, value) {
        let { activity } = this.props
        activity.data.settings = { ...rolePlaySettingsDefault, ...activity.data.settings }
        activity.data.settings.parties = activity.data.settings.parties.map((party, i) => {
            if (i === index) {
                party[name] = value
            }
            return party
        })
        this.props.onActivityUpdate(activity)
    }

    _removeParty(id) {
        if (confirm("Are you sure you want to remove this part?")) {
            let { activity } = this.props
            activity.data.settings.parties = activity.data.settings.parties.filter(party => party.id !== id)
            this.props.onActivityUpdate(activity)
        }
    }


    _downloadCSV(e) {
        // e.preventDefault()
        const { activity, state } = this.props
        const course = state.course.model
        const settings = activity.data.settings || {}

        let participants = []
        settings.parties.map((party, partyIndex) => {
            course.participants.map(participant => {
                if (!party.roleStaticId) { return }
                if (participant.roles.indexOf(party.roleStaticId) !== -1) {
                    participants.push({
                        id: participant.id,
                        first: participant.userz.first,
                        last: participant.userz.last,
                        email: participant.userz.email,
                        team: participant.teamId && course.byId.teams[participant.teamId] ? course.byId.teams[participant.teamId].name : 'None',
                        role: course.byStaticId.roles[party.roleStaticId] ? course.byStaticId.roles[party.roleStaticId].name : 'Not found',
                        party: partyIndex + 1,
                        group: '',
                        location: '',
                        offset: 0
                    })
                }
            })
        })

        let csvData = csv.arrayOfObjectsToCsv(participants, cols)

        let btn = e.target
        btn.setAttribute('href', csvData)
        btn.setAttribute('download', activity.name.replace(' ', '') + '.csv')
    }

    _uploadCSV(e) {
        const { ignoreFirstRow } = this.state
        let { activity, onActivityUpdate, state } = this.props
        const course = state.course.model
        activity.data.rolePlay = []
        let file = e.target.files[0]
        if (file.type !== 'text/csv') {
            return alert("Please select a csv file.")
        }
        let reader = new FileReader()
        reader.onload = (e) => {
            let content = e.target.result
            let rows = content.split(/\r?\n|\r/)
            let startCounter = (ignoreFirstRow) ? 1 : 0
            let errors = []

            for (let i = startCounter; i < rows.length; i++) {
                let row = rows[i].split(',')
                if (row.length === 1) { continue }
                if (!row) {
                    errors.push({ message: 'Row ${i}: Does NOT exist' })
                    continue
                }

                if (!row[0] || !row[0].match(/^\w+$/g) || !course.byId.participants[row[0]]) {
                    errors.push({ message: `Row ${i}: Participant ID not recognised.` })
                    continue
                }
                if (!row[6] || !row[6].match(/^\d+$/g)) {
                    errors.push({ message: `Row ${i}: Party not recognized` })
                    continue
                }
                if (!row[9] || !row[9].match(/^\d+$/g)) {
                    errors.push({ message: `Row ${i}: Time offset not in the right format` })
                    continue
                }
                activity.data.rolePlay.push({
                    participantId: row[0],
                    partyIndex: row[6] * 1,
                    group: row[7],
                    location: row[8],
                    offset: row[9] * 1
                })
            }
            this.setState({ errorsFromCSV: errors })
            onActivityUpdate(activity)
        }
        reader.readAsText(file)
    }

    _onChangeRolePlayer(key, rolePlayer) {
        let { activity, onActivityUpdate } = this.props
        activity.data.rolePlay = activity.data.rolePlay || []
        activity.data.rolePlay = activity.data.rolePlay.map((rolePlayerOne, i) => i === key ? rolePlayer : rolePlayerOne)
        onActivityUpdate(activity)
    }

    _onAddRolePlayer(name, value) {
        let { activity, onActivityUpdate } = this.props
        activity.data.rolePlay = activity.data.rolePlay || []
        activity.data.rolePlay.push({
            participantId: value,
            group: '',
            location: '',
            partyIndex: 1,
            offset: 0
        })
        onActivityUpdate(activity)
    }

    _onRemoveRolePlayer(index) {
        let { activity, onActivityUpdate } = this.props
        activity.data.rolePlay = activity.data.rolePlay || []
        activity.data.rolePlay = activity.data.rolePlay.filter((rp, i) => i !== index)
        onActivityUpdate(activity)
    }
}


class ParticipantRolePlayer extends Component {
    static propTypes = {
        course: PropTypes.object.isRequired,
        rolePlayer: PropTypes.object.isRequired,
        onChangeRolePlayer: PropTypes.func.isRequired,
        onRemoveRolePlayer: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        parties: PropTypes.array.isRequired
    }
    render() {
        const { course, rolePlayer, parties } = this.props
        let participant = course.byId.participants[rolePlayer.participantId]
        if (!participant) {
            return <tr className="danger">
                <td colSpan={6}>Participant not found</td>
                <td><a className="btn btn-link btn-danger" onClick={this._onRemove.bind(this)}><i className="fa fa-trash" /></a></td>
            </tr>
        }
        return <tr>
            <td><Avatar user={participant} /></td>
            <td>
                <strong>{participant.displayName.short}</strong><br />
                {participant.roles.map(r => course.byStaticId.roles[r] ? course.byStaticId.roles[r].name : 'Not found').join(', ')}
            </td>
            <td>{participant.teamName}</td>
            <td><input type="text" className="form-control" style={{ width: 80 }} value={rolePlayer.group} onChange={e => this._changeRolePlayer('group', e.target.value)} /></td>
            <td><input type="text" className="form-control" style={{ width: 80 }} value={rolePlayer.location} onChange={e => this._changeRolePlayer('location', e.target.value)} /></td>
            <td><input type="number" className="form-control" style={{ width: 80 }} value={rolePlayer.offset} onChange={e => this._changeRolePlayer('offset', e.target.value)} /></td>
            <td>
                <Input type="select"
                    options={parties.map((party, i) => { return { label: `Party ${i + 1}`, value: i + 1 } })}
                    value={rolePlayer.partyIndex}
                    onChange={(name, value) => this._changeRolePlayer('partyIndex', value)} />
            </td>
            <td><a className="btn btn-link btn-danger" onClick={this._onRemove.bind(this)}><i className="fa fa-trash" /></a></td>
        </tr>
    }

    _changeRolePlayer(attribute, value) {
        let { rolePlayer, onChangeRolePlayer, index } = this.props
        rolePlayer[attribute] = value
        onChangeRolePlayer(index, rolePlayer)
    }
    _onRemove(e) {
        e.preventDefault()
        const { onRemoveRolePlayer, index } = this.props
        onRemoveRolePlayer(index)
    }
}