import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Loading, Avatar } from 'alias-components'
import ReduxBinder from 'alias-store/ReduxBinder'
import { Switch, Route } from 'react-router-dom'
import liveUpdate from 'alias-tools/LiveUpdate'
import courseSubscriptions from 'alias-tools/courseSubscriptions'
import PickerPages from './picker'

// http://localhost:3000/course/5959c4728e16ac04008a02ac/pick

class PageCoursePickRole extends Component {

    static propTypes = {
        params: PropTypes.object,
        state: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props)
        const courseId = props.match.params.courseId
        // Connecting server live updates
        this._liveUpdateListen(courseId)


        this.state = this._getState(props, {})
    }

    _getState(props, state) {
        const course = props.state.course.model

        // Get participant
        let participantId = null
        let participant = null

        if (props.state.local.game.participantId) {
            const hisParticipant = course.participants.filter(part => part.id === this.props.state.local.game.participantId)
            if (hisParticipant.length > 0) {
                participant = hisParticipant[0]
                participantId = participant.id
            }
        } else {
            if (course && course.participants) {
                const participants = course.participants.filter(item => {
                    return !item.isAdmin && item.userzId === this.props.state.user.model.id
                })
                if (participants.length > 0) {
                    participant = participants[0]
                    participantId = participant.id
                }
            }
        }

        let availableRoles = course.roles.filter(r => r.isSelectableByParticipant === true && r.isVisibleByParticipant === true)


        let chosenRoles = state.chosenRoles || []
        // Add to chosen the roles he already has
        if (participant && participant.roles && participant.roles.length > 0) {
            participant.roles.map(roleStaticId => {
                let role = course.byStaticId.roles[roleStaticId]
                if (role && role.isVisibleByParticipant) {
                    chosenRoles.push(role)
                }
            })
        }
        // Filter chosen roles in case another user chooses them at same time
        if (participant && participant.teamId) {
            course.participants.map(p => {
                if (p.teamId === participant.teamId && participant.id !== p.id) {
                    chosenRoles = chosenRoles.filter(r => p.roles.indexOf(r.staticId) === -1)
                    availableRoles = availableRoles.filter(r => p.roles.indexOf(r.staticId) === -1)
                }
            })
        }

        return {
            courseId: props.match.params.courseId,
            participantId: participantId,
            participant: participant,
            chosenRoles: chosenRoles,
            availableRoles: availableRoles
        }
    }


    UNSAFE_componentWillMount() {

        const course = this.props.state.user.model.participants.filter(participant => {
            return participant.courseId === this.props.match.params.courseId && !participant.isAdmin
        })
        if (course.length > 0) {
            if (this.isFetching !== true) {
                this._loadCourse(this.props)
            }
        } else {
            this.props.history.push('/')
        }
    }


    UNSAFE_componentWillReceiveProps(newProps) {
        let stateAttributesToUpdate = {}
        if (this.state.courseId !== newProps.match.params.courseId) {
            stateAttributesToUpdate.courseId = newProps.match.params.courseId
            this._loadCourse(newProps)

            this._liveUpdateListen(this.props.match.params.courseId)
        }
        this.setState({
            ...this._getState(newProps, this.state)
        })
    }

    _loadCourse(props) {
        this.isFetching = true
        const courseId = props.match.params.courseId
        const user = props.state.user.model
        let participants = user.participants.filter(p => (p.courseId === courseId && !p.isAdmin))
        if (participants.length > 0) {
            let participant = participants[0]
            props.actions.course.load(courseId, () => {
                this.isFetching = false
            }, true, participant.id)
        }
    }

    componentWillUnmount() {
        liveUpdate.leaveCourse()
    }

    _liveUpdateListen(courseId) {
        const { props } = this
        const participants = props.state.user.model.participants.filter(participant => {
            return participant.course && participant.course.id === props.match.params.courseId && participant.isAdmin
        })
        this.liveUpdate = liveUpdate.joinCourse(
            `course/${courseId}`,
            courseSubscriptions(props.actions),
            () => {
                if (participants.length > 0) {
                    props.actions.course.load(courseId, null, true, participants[0].id)
                } else {
                    console.warn("No participants found for this user in the course") // eslint-disable-line
                }
            },
            props.actions.notification.add,
            props.actions.notification.remove)
    }

    render() {
        const { props, state } = this
        // COURSE LOADING
        if (!props.state || !props.actions) {
            return <Loading />
        }

        const courseState = props.state.course
        const course = courseState.model

        if (courseState.isFetching && !courseState.isLoaded) {
            return <Loading />
        }

        if (!state.participantId) {
            return <Loading />
        }

        return (
            <div className='course-wrapper' style={{ overflow: 'auto' }}>
                <Helmet title={`${course.name}`} />
                <div className="container">
                    <br />
                    <br />
                    <div className="text-center">
                        <Avatar user={course} size={150} />

                        <h3>Hello {state.participant.displayName.short}</h3>
                        <p>Welcome to {course.name}. We're excited to have you here.</p>

                    </div>
                    <Switch>
                        <Route exact path='/course/picker/:courseId/' component={PickerPages.Teams} />
                        <Route path='/course/picker/:courseId/teams' component={PickerPages.Teams} />
                        <Route path='/course/picker/:courseId/roles' component={PickerPages.Roles} />
                    </Switch>
                </div>
            </div>
        )
    }


}
export default ReduxBinder(PageCoursePickRole, {
    state: ['course', 'user', 'local', 'participant', 'team']
})