import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button, FormErrors } from 'alias-components'
import IsValid from 'alias-tools/validation'


export default class CourseModalTemplate extends Component {
    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        isShown: PropTypes.bool,
        close: PropTypes.func
    }
    static defaultProps = {
        isShown: false,
        close: function () {
            alert("You haven't coded the close function for the modal!")
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            template: {
                isTemplate: true,
                name: '',
                description: ''
            },
            errorFields: []
        }
    }

    render() {

        const template = this.state.template

        return (
            <Modal show={this.props.isShown} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new template</Modal.Title>
                </Modal.Header>
                <form onSubmit={this._create.bind(this)}>
                    <Modal.Body>
                        <div>
                            <FormErrors fields={this.state.errorFields} />

                            <Input
                                name="name"
                                label="Name"
                                placeholder="Ex: Startup course..."
                                value={template.name}
                                onChange={this._onChangeNew.bind(this)}
                                attributes={{ 'autoFocus': true }} />

                            <Input
                                type="textarea"
                                name="description"
                                value={template.description}
                                onChange={this._onChangeNew.bind(this)}
                                label="Quick description"
                                placeholder="What is it about?..." />

                            <Input
                                type="bool"
                                name="isTemplate"
                                value={template.isTemplate}
                                onChange={this._onChangeNew.bind(this)}
                                label="Is template?" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a onClick={this.props.close} className="btn btn-default">Cancel</a>
                        <Button text="Create" isLoading={this.props.state.course.isSaving} />
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

    _onChangeNew(name, value) {
        var template = this.state.template
        template[name] = value
        this.setState({ template: template })
    }

    _isFormValid() {
        var course = this.state.template
        var formIsValid = true
        var errorFields = []
        var errors = null
        // Name
        errors = IsValid(course.name, { required: null, maxLength: 40 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Template name",
                errors: errors
            })
        }
        // Name
        errors = IsValid(course.description, { maxLength: 500 })
        if (errors.length > 0) {
            formIsValid = false
            errorFields.push({
                name: "Template description",
                errors: errors
            })
        }
        this.setState({
            errorFields: errorFields
        })
        return formIsValid
    }

    _create(e) {
        e.preventDefault()
        if (!this._isFormValid()) {
            return false
        }
        this.props.actions.course.create(this.state.template, (template) => {
            this.props.close()
            window.location = "/course/configuration/" + template.id
        })
    }
}