import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Scoring from '../../components/Scoring'
import ReduxBinder from 'alias-store/ReduxBinder'

class CourseConfigurationScoring extends Component {

    static propTypes = {
        actions: PropTypes.object,
        state: PropTypes.object
    }

    render() {
        return <Scoring.Configuration {...this.props} />
    }
}

export default ReduxBinder(CourseConfigurationScoring, {
    state: ['course', 'scoringTag']
})