import api from '../../tools/api'
import errorHandling from '../../tools/errorHandling'
import structure from '../structures.json'

const BASE_PATH = '/activityLogs'
//
// Action types
//
const CLEAR = 'si/activityLog/CLEAR'
const SAVING = 'si/activityLog/SAVING'
const SAVED = 'si/activityLog/SAVED'
const REMOVE = 'si/activityLog/REMOVE'
const ERROR = 'si/activityLog/ERROR'

const PARTICIPANT_READ = 'si/activityLog/ACTIVITY_LOG_PARTICIPANT_READ'
const PARTICIPANT_READ_COUNT = 'si/activityLog/ACTIVITY_LOG_PARTICIPANT_READ_COUNT'

const initialState = {
    isSaving: false, // When it's getting data from the server (usually to show a loader)
    messages: {
        error: null, // String
        success: null // String
    },
    model: structure.activityLog
}

//
// Reducer
//
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CLEAR:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case SAVING:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: true
            }
        case SAVED:
            return {
                ...state,
                messages: initialState.messages,
                isSaving: false
            }
        case REMOVE:
            return { ...initialState }
        case ERROR:
            return {
                ...state,
                messages: {
                    success: null,
                    error: action.error
                },
                isSaving: false
            }
        default:
            return state
    }
}

//
// ACTIONS
//

export function clearMessages() {
    return { type: CLEAR }
}

export function create(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        var state = getState()
        delete model.id
        api('/courses/' + model.courseId + BASE_PATH, 'POST', model, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: SAVED, model: response.data })
                if (model.type === "message") {
                    dispatch({
                        type: 'si/notification/ADD',
                        notification: {
                            type: 'success',
                            title: 'Message sent'
                        }
                    })
                }
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function update(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        var state = getState()
        api(BASE_PATH + '/' + model.id, 'PATCH', model, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: SAVED, model: response.data })

                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function remove(model, callback) {
    return (dispatch, getState) => {
        dispatch({ type: SAVING, model: model })
        // Add default data
        var state = getState()
        api('/courses/' + model.courseId + BASE_PATH + '/' + model.id, 'DELETE', model, state, {}, dispatch) //
            .then(function (response) {
                dispatch({ type: REMOVE, model: model })
                dispatch({
                    type: 'si/notification/ADD',
                    notification: {
                        type: 'success',
                        title: 'Activity removed'
                    }
                })
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function fetchParticipantReadActivity(participant, callback) {
    return (dispatch, getState) => {
        // Add default data
        var state = getState()
        api('/courses/' + BASE_PATH + '/get-read/' + participant.id, 'GET', {}, state, {}, dispatch) //
            .then(function (response) {
                dispatch({
                    type: PARTICIPANT_READ,
                    activityLogs: response.data,
                    participantId: participant.id
                })

                if (callback) {
                    callback({
                        success: true,
                        data: response.data
                    })
                }
            })
            .catch(function (response) {
                if (callback) {
                    callback({
                        success: false,
                        data: response.data
                    })
                }
                errorHandling(dispatch, ERROR, response)
            })
    }
}

export function fetchAllReadActivityForCourse(courseId, callback) {
    return (dispatch, getState) => {
        // Add default data
        var state = getState()
        api('/courses/' + BASE_PATH + '/get-read-counts-all/' + courseId, 'GET', {}, state, {}, dispatch) //
            .then(function (response) {
                dispatch({
                    type: PARTICIPANT_READ_COUNT,
                    counts: response.data
                })

                if (callback) {
                    callback(response.data)
                }
            })
            .catch(function (response) {
                errorHandling(dispatch, ERROR, response)
            })
    }
}


export function liveAdd(model) {
    return { type: SAVED, model: model }
}
export function liveUpdate(model) {
    return { type: SAVED, model: model }
}
export function liveRemove(model) {
    return { type: REMOVE, model: model }
}
