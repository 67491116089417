import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { Input, Button } from 'alias-components'
import structures from 'alias-store/structures'



export default class ScoringModal extends Component {

    static propTypes = {
        state: PropTypes.object,
        actions: PropTypes.object,
        handleClose: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool,
        scoringTagId: PropTypes.any
    }

    static defaultProps = {
        handleClose: () => { },
        isOpen: false
    }


    constructor(props) {
        super(props)
        const course = this.props.state.course.model
        let scoringTag = { ...structures.scoringTag }

        if (props.scoringTagId && props.scoringTagId !== 'new' && course.byId.scoringTags[props.scoringTagId]) {
            scoringTag = {
                ...scoringTag,
                ...course.byId.scoringTags[props.scoringTagId]
            }
        }
        this.state = {
            scoringTag: scoringTag,
            errorFields: [],
            isEdited: false
        }
    }

    UNSAFE_componentWillMount() {
        this.props.actions.scoringTag.clearMessages()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scoringTagId != this.state.scoringTag.id && this.state.isEdited === false) {
            const course = nextProps.state.course.model
            if (nextProps.scoringTagId && course.byId.scoringTags[nextProps.scoringTagId]) {
                this.setState({
                    scoringTag: course.byId.scoringTags[nextProps.scoringTagId]
                })
            } else {
                this.setState({ scoringTag: { ...structures.scoringTag } })
            }
        }
    }

    render() {
        const { isOpen } = this.props
        const { scoringTag } = this.state

        let title = "New scoring tags"
        let btnText = "Create"
        if (this.props.scoringTagId !== 'new') {
            title = scoringTag.name
            btnText = "Update"
        }

        return (
            <Modal show={isOpen} onHide={this._close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {isOpen && <form onSubmit={this._onSubmit.bind(this)}>
                    <Modal.Body>
                        <div className="form-horizontal">
                            <Input value={scoringTag.name}
                                onChange={this._onChange.bind(this)}
                                name="name"
                                label="Name"
                                placeholder="Ex: Communication..." />
                            <Input value={scoringTag.description}
                                type="textarea"
                                name="description"
                                label="Description"
                                placeholder="Type a description here..."
                                onChange={this._onChange.bind(this)} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button text={btnText} />
                        <a onClick={this._close.bind(this)} className="btn btn-default">Close</a>
                        {(this.props.scoringTagId && this.props.scoringTagId !== 'new') && (<a onClick={this._delete.bind(this)} className="red" style={{ marginLeft: '4px' }}>Delete</a>)}
                    </Modal.Footer>
                </form>}
            </Modal>
        )
    }

    _onChange(name, value) {
        let { scoringTag } = this.state
        scoringTag[name] = value
        this.setState({
            scoringTag: scoringTag,
            isEdited: true
        })
    }

    _onSubmit(e) {
        e.preventDefault()
        let { scoringTag } = this.state

        if (!scoringTag.id) {
            const course = this.props.state.course.model
            scoringTag.courseId = course.id
            this.props.actions.scoringTag.create(scoringTag, (scoringTag) => {
                this.setState({ isEdited: false })
                this.props.openModal('tag', scoringTag.id)
            })
        } else {
            this.props.actions.scoringTag.update(scoringTag, () => {
                // this.props.handleClose()
                this.setState({ isEdited: false })
            })
        }
    }

    _delete() {
        let { scoringTag } = this.state
        if (confirm(`Are you sure you want to remove ${scoringTag.name}?`)) {
            this.props.actions.scoringTag.remove(scoringTag, () => {
                this.setState({ isEdited: false })
                this.props.handleClose()
            })
        }
    }

    _close() {
        if (this.state.isEdited) {
            if (confirm("You have some unsaved changes, are you sure you want to exit?")) {
                this.setState({ isEdited: false })
                this.props.handleClose()
            }
        } else {
            this.setState({ isEdited: false })
            this.props.handleClose()
        }
    }

}