import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chat from './views/MessagingConversationChat'
import Email from './views/MessagingConversationEmail'
import { Avatar } from 'alias-components'
import Character from '../../Character'

export default class MessagingConversation extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['email', 'chat']),
        course: PropTypes.object.isRequired,
        participant: PropTypes.object,
        threadId: PropTypes.string,
        threads: PropTypes.array,
        actions: PropTypes.object,
        isAdmin: PropTypes.bool,
        back: PropTypes.func
    }
    constructor(props) {
        super(props)

        this.state = {
            threadId: props.threadId,
            showCharacterModal: false
        }

    }

    componentDidMount() {
        this._scrollToBottom()
    }

    componentDidUpdate() {
        this._scrollToBottom()
        // const { threads, actions, threadId, course } = this.props
        // const potentialConversations = threads.filter(c => c.id === threadId)

        // let thread = potentialConversations[0]
        // if (thread && thread.isUnread) {
        //     if (!thread.unreadLog) {
        //         actions.activityLog.create({
        //             type: 'read',
        //             courseId: course.id,
        //             threadId: thread.id,
        //             activityStaticId: thread.activityStaticId
        //         })
        //     } else {
        //         actions.activityLog.update(thread.unreadLog)
        //     }
        // }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.threadId !== this.state.threadId) {
            this._scrollToBottom()
        }
    }

    _scrollToBottom() {
        let list = this.refs.messageView
        if (list) {
            list.scrollTop = list.offsetHeight
        }
    }

    render() {
        const { props, state } = this
        const { threadId, threads, type, participant, back, course, actions } = this.props

        if (!threadId) {
            if (!participant) {
                return <div className="column mails">
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <h2>Select a participant</h2>
                        <p>Please select a participant</p>
                    </div>
                </div>
            } else {
                return <div className="column mails">
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                        <h2>Select a conversation</h2>
                        <p>Please select a conversation</p>
                    </div>
                </div>
            }
        }
        const potentialConversations = threads.filter(c => c.id === threadId)
        if (potentialConversations.length === 0) {
            return <p>Conversation not found</p>
        }
        let thread = potentialConversations[0]

        thread.participantsInfo = thread.participants.map(pId => {
            let participant = course.byId.participants[pId]
            if (participant) { return participant }
            let character = course.byStaticId.characters[pId]
            if (character) { return { userz: character, id: character.staticId, displayName: { short: `${character.first} ${character.last}` } } }
        })

        const lastMessage = (thread && thread.messages.length > 0) ? thread.messages[thread.messages.length - 1] : null

        let inputPlaceholderText = "Type your message here..."
        if (!props.isAdmin) {
            if (!thread.isOnline && props.type === 'chat') {
                inputPlaceholderText = `User is offline and cannot be reached at the moment ...`
            } else {
                if (lastMessage && lastMessage.type === 'answer' && !lastMessage.answer) {
                    inputPlaceholderText = "Please answer: " + lastMessage.question.question
                    if (['range', 'number', 'scoring'].indexOf(lastMessage.question.type) !== -1) {
                        inputPlaceholderText = "(NUMBER) Please answer with a number: " + lastMessage.question.question
                    }
                }
            }
        }

        let character = null
        if (thread.participants) {
            thread.participants.map(participantId => {
                if (course.byStaticId.characters[participantId]) {
                    character = course.byStaticId.characters[participantId]
                }
            })
        }

        // mails chats
        return <div className={`column mails ${type === 'chat' ? 'chats' : ''} ${(participant.id && threadId) ? 'active' : ''}`}>
            <div className="header">
                {back && <a onClick={() => back(true)} className="left-link hidden-sm hidden-md hidden-lg"><i className="fa fa-chevron-left" /></a>}
                {thread.participantsInfo.map(participantInfo => participantInfo ? <span key={participantInfo.id} style={{ position: 'relative', marginRight: 15, top: 4 }}>
                    <Avatar
                        user={participantInfo.userz}
                        size={40}
                        tooltip={true}
                        tooltipPosition='bottom' />
                </span> : null)}
                {(!props.isAdmin && character !== null) && <a onClick={() => this.setState({ showCharacterModal: true })} className="pull-right" style={{ color: 'white', marginRight: 10 }}><i className="fa fa-eye"></i> Who is this?</a>}
                {(!props.isAdmin && character !== null) && <Character.ModalInfo
                    character={character}
                    course={course}
                    handleClose={() => this.setState({ showCharacterModal: false })}
                    isOpen={state.showCharacterModal} />}
            </div>
            <div className="list-wrapper" ref="messageView">
                {type === 'chat' && <Chat {...this.props}
                    thread={thread}
                    inputPlaceholderText={inputPlaceholderText}
                    questionAnswered={this._questionAnswered.bind(this)}
                    addMessage={this._addMessage.bind(this)} />}
                {type === 'email' && <Email {...this.props}
                    thread={thread}
                    inputPlaceholderText={inputPlaceholderText}
                    questionAnswered={this._questionAnswered.bind(this)}
                    actions={actions}
                    addMessage={this._addMessage.bind(this)} />}
            </div>
        </div>
    }

    _questionAnswered(message, answerValue, participantId, callback) {

        this.setState({ text: '' })

        if (message.log) {
            // Update the form
            message.log.data.answers.push({
                answer: answerValue,
                id: message.question.id,
                created: new Date()
            })
            this.props.actions.activityLog.update(message.log, () => {
                this._notification(message, answerValue)
                this._updateRead()
                if (callback) {
                    callback()
                }
            })
            return
        }
        let activityLog = Object.assign({}, {
            courseId: message.form.courseId,
            participantId: participantId,
            type: 'form',
            formStaticId: message.form.staticId,
            fromParticipantId: participantId,
            activityId: message.activity ? message.activity.id : "",
            data: {
                answers: [{
                    answer: answerValue,
                    id: message.question.id,
                    created: new Date()
                }]
            }
        })
        this.props.actions.activityLog.create(activityLog, () => {
            // If end of form, add
            this._scrollToBottom()
            this._notification(message, answerValue)
            this._updateRead()
            if (callback) {
                callback()
            }
        })

    }

    _notification(message, answerValue) {
        if (["multiple-choice"].indexOf(message.question.type) !== -1) {
            let pointsAdded = false
            message.question.options.map(option => {
                if (option.id === answerValue) {
                    if (option.score) {
                        pointsAdded = option.score * 1
                    }
                    if (option.badgeStaticId) {
                        // pointsAdded = option.score * 1
                    }
                }
            })
            if (pointsAdded && pointsAdded != "NaN" && pointsAdded > 0) {
                // if(message.question.variableStaticId && this.props.state.course.model.byStaticId.varaibles)
                this.props.actions.notification.add({
                    type: 'info',
                    title: <span><i className="fa fa-trophy"></i> {pointsAdded > 0 ? `+ ${pointsAdded}` : pointsAdded} points</span>,
                    body: "Check your dashboard to view."
                })
            }
        }
    }

    _addMessage(text, callback, characterStaticId, videoUrl) {
        const { props } = this
        if (!text) { return }

        const { participant, actions, isAdmin, threadId, threads } = this.props
        const thread = threads.filter(t => t.id === threadId)[0]

        // Activity log
        actions.activityLog.create({
            courseId: participant.courseId,
            type: props.type,
            participantId: isAdmin ? characterStaticId : participant.id,
            threadId: !thread.activityId ? props.threadId : null,
            activityStaticId: (thread.activityId && props.course.byId.activities[thread.activityId]) ? props.course.byId.activities[thread.activityId].staticId : null,
            data: {
                message: text,
                characterStaticId: characterStaticId,
                type: 'character',
                videoUrl: videoUrl
            },
            withParticipantId: participant.id
        }, (activityLog) => {
            this._scrollToBottom()
            if (callback) {
                callback(activityLog)
            }
            this._updateRead()
        })
    }

    _updateRead() {
        const { threads, actions, threadId, course, isAdmin } = this.props
        const potentialConversations = threads.filter(c => c.id === threadId)
        let thread = potentialConversations[0]
        if (thread && thread.isUnread && !isAdmin) {
            if (!thread.unreadLog) {
                actions.activityLog.create({
                    type: 'read',
                    courseId: course.id,
                    threadId: thread.id,
                    activityStaticId: thread.activityStaticId
                })
            } else {
                actions.activityLog.update(thread.unreadLog)
            }
        }
    }
}
